import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Dimmer, Loader } from "semantic-ui-react";
import {
  HubContext,
  ToasterContext,
} from "scenes/Hubly/components/Workspace/Provider";
import { ConfirmationModal } from "components/ConfirmationModal";

export function BulkAddClientConfirmationModal({
  workflowId,
  fetchClientByType,
  fetchCount,
  type,
  obj,
  onAddClients,
  onClose,
}) {
  const { bulkAddClientsToWorkflow } = useContext(HubContext);
  const { fireError, fireWarning } = useContext(ToasterContext);

  const [clients, setClients] = useState([]);
  const [householdCount, setHouseholdCount] = useState({});
  const [loadingText, setLoadingText] = useState("Loading");

  const bulkAddClient = async (bulkClients) => {
    if (loadingText !== "Adding") setLoadingText("Adding");
    try {
      await bulkAddClientsToWorkflow({
        clients: bulkClients,
        workflow: { id: workflowId },
      });
      onAddClients(bulkClients);
    } catch (error) {
      fireError(`Failed to add client by ${type}`, error);
    } finally {
      setLoadingText("");
      onClose(null);
    }
  };

  const getHouseholdHeads = async () => {
    setLoadingText("Adding");
    try {
      const { data: heads } = await fetchClientByType({
        iDs: [obj.id],
        householdTitle: "Head",
      });
      bulkAddClient(heads);
    } catch (error) {
      fireError(`Failed to add client by ${type}`, error);
      onClose(null);
    }
  };

  useEffect(() => {
    let mount = true;

    const loadData = async () => {
      try {
        const { data: clientList } = await fetchClientByType({
          iDs: [obj.id],
        });
        if (clientList.length === 0) {
          fireWarning(
            `There are no clients associated with the selected ${type}.`
          );
          onClose(null);
          return;
        }

        const houseCount = await fetchCount(obj.id, workflowId);
        if (houseCount.numHouseholdHead === 0 && houseCount.numClient === 0) {
          fireWarning(
            `All clients in ${type} have been added to the workflow.`
          );
          onClose(null);
          return;
        }

        if (mount) {
          setHouseholdCount(houseCount);
          setClients(clientList);
          setLoadingText("");
        }
      } catch (error) {
        fireError(`Failed to load clients in ${type}.`, error);
        onClose(null);
      }
    };

    loadData();

    return () => {
      mount = false;
    };
  }, [
    workflowId,
    obj.id,
    type,
    fetchClientByType,
    fetchCount,
    fireError,
    fireWarning,
    onClose,
  ]);

  if (loadingText) {
    return (
      <Dimmer active page>
        <Loader>{loadingText}</Loader>
      </Dimmer>
    );
  }

  const buttons = [
    {
      text: "Cancel",
      callBack: () => {
        onClose(null);
      },
    },
    {
      text: `Add all ${householdCount.numClient} clients to workflow`,
      callBack: () => {
        bulkAddClient(clients);
      },
    },
  ];
  if (householdCount.numHouseholdHead > 0) {
    buttons.push({
      text: `Add ${householdCount.numHouseholdHead} household heads to workflow`,
      callBack: () => {
        getHouseholdHeads();
      },
    });
  }

  return (
    <ConfirmationModal
      title={`Add Clients by ${type.charAt(0).toUpperCase()}${type.slice(1)}`}
      message={
        `Are you sure you wish to add all clients with ${type} ${obj.name}?` +
        ` This will add ${householdCount.numHouseholdHead} household head${
          householdCount.numHouseholdHead !== 1 ? "s" : ""
        }` +
        ` or ${householdCount.numClient} client${
          householdCount.numClient !== 1 ? "s" : ""
        } into this workflow.` +
        ` This process will take a few moments to complete.`
      }
      icon="tasks"
      buttons={buttons}
    />
  );
}

BulkAddClientConfirmationModal.propTypes = {
  fetchClientByType: PropTypes.func.isRequired,
  fetchCount: PropTypes.func.isRequired,
  workflowId: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  obj: PropTypes.object.isRequired,
  onAddClients: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default BulkAddClientConfirmationModal;
