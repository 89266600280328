import { getAPIReq } from "data/libs/authLib";
import { API } from "aws-amplify";

export const getDateTypes = async ({ hubId }) => {
  const req = await getAPIReq(
    {
      query: `query getDateTypes {
        dateTypes {
          edges {
            node {
              uuid
              name
              icon
            }
          }
        }
      }`,
    },
    { hub: hubId }
  );

  const {
    data: { dateTypes },
  } = await API.post("HublyAPI", `/graphql`, req);
  return dateTypes.edges.map(({ node }) => {
    return {
      ...node,
      id: node.uuid,
    };
  });
};

export default getDateTypes;
