import React, { useContext } from "react";
import PropTypes from "prop-types";
import MeetingDateTypeDropdown, {
  MeetingDateTypeContext,
} from "components/MeetingDateTypeDropdown";

const ImportantDateMeetingTypeDropdown = ({
  editWorkflowRule,
  selectedMeetingDateType,
  setSelectedMeetingDateType,
}) => {
  const { getMeetingDateTypeFromId } = useContext(MeetingDateTypeContext);

  const defaultMeetingDateType = getMeetingDateTypeFromId(
    editWorkflowRule?.meetingDateTypeId
  );
  if (!selectedMeetingDateType && defaultMeetingDateType) {
    setSelectedMeetingDateType(defaultMeetingDateType);
  }

  return (
    <div
      style={{ marginTop: "1em" }}
      data-test="ruleModal-dropdown-meetingDateType"
    >
      <MeetingDateTypeDropdown
        allowCreateNewMeetingDateType={false}
        setSelectedMeetingDateType={setSelectedMeetingDateType}
        selectedMeetingDateType={selectedMeetingDateType}
        useDefaultValue={!defaultMeetingDateType}
      />
    </div>
  );
};

ImportantDateMeetingTypeDropdown.propTypes = {
  editWorkflowRule: PropTypes.shape({
    meetingDateTypeId: PropTypes.string.isRequired,
  }).isRequired,
  selectedMeetingDateType: PropTypes.object,
  setSelectedMeetingDateType: PropTypes.func.isRequired,
};

ImportantDateMeetingTypeDropdown.defaultProps = {
  selectedMeetingDateType: undefined,
};

export default ImportantDateMeetingTypeDropdown;
