import React from "react";
import PropTypes from "prop-types";

import useScript from "react-script-hook";

function ScriptLoader({ scriptSrc, children }) {
  const [loading, error] = useScript({
    src: scriptSrc,
    checkForExisting: true,
  });

  if (error) {
    if (process.env.REACT_APP_WARN_ENABLED) {
      console.warn(`Error occurred while trying to load ${scriptSrc}`, error);
    }
  }

  if (loading) {
    if (process.env.REACT_APP_LOG_ENABLED) {
      // eslint-disable-next-line no-console
      console.log(`Loading ${scriptSrc}`);
    }

    return null;
  }

  if (process.env.REACT_APP_LOG_ENABLED) {
    // eslint-disable-next-line no-console
    console.log(`${scriptSrc} has been loaded`);
  }

  return <React.Fragment>{children}</React.Fragment>;
}

ScriptLoader.propTypes = {
  scriptSrc: PropTypes.string.isRequired,
  children: PropTypes.node,
};

ScriptLoader.defaultProps = {
  children: null,
};

export default ScriptLoader;
