import { first, sortBy, keyBy, filter } from "lodash";
import { createSelector } from "reselect";

import { makeAssignee } from "data/filters/actions";
import { byAssignee } from "data/tasks/selector";

const getFilters = (state) => {
  return Object.entries(state?.hubly?.data?.filters || []);
};
const getClientWorkflows = (state) => {
  return state?.hubly?.data?.hub?.clientWorkflows;
};
const getCurrentAdvisor = (state) => {
  return state?.hubly?.data?.advisor;
};
const getAssignee = (state) => {
  return state?.hubly?.data?.filters?.assignee;
};

export function nextTaskToComplete(tasks = []) {
  return first(
    sortBy(
      tasks.filter(({ completed }) => {
        return !completed;
      }),
      "order"
    )
  );
}

export function assigneeNextTaskPredicate(assigneeId) {
  return ({ tasks }) => {
    return nextTaskToComplete(tasks)?.assignedAdvisorId === assigneeId;
  };
}

export function waitingOnClientPredicate({ isFilterOn, waitingOnClient }) {
  return isFilterOn
    ? ({ tasks }) => {
        return nextTaskToComplete(tasks)?.isClientTask === waitingOnClient;
      }
    : (_) => {
        return true;
      };
}

export function filterByClientPredicate(clientsIdToFilterBy) {
  return clientsIdToFilterBy.length > 0
    ? ({ clientId }) => {
        return clientsIdToFilterBy.includes(clientId);
      }
    : (_) => {
        return true;
      };
}

export function assignedTaskPredicate(assignee) {
  const all = () => {
    return true;
  };

  const makeFilterByTask = () => {
    const assigneeFilter = byAssignee(assignee);
    return ({ tasks = [] }) => {
      return tasks.some(assigneeFilter);
    };
  };

  return assignee?.state === "ALL" ? all : makeFilterByTask();
}

function createPredicates(assignee) {
  return new Map([
    [
      "filterNextTaskForAssignee",
      () => {
        return assigneeNextTaskPredicate(assignee.id);
      },
    ],
    ["filterWaitingOnClient", waitingOnClientPredicate],
    ["clientFilter", filterByClientPredicate],
    [
      "assignee",
      () => {
        return assignedTaskPredicate(assignee);
      },
    ],
  ]);
}

function predicateSelector(predicatesPerFilter) {
  return ([filterName, filterValue]) => {
    const all = () => {
      return () => {
        return true;
      };
    };
    const selector = predicatesPerFilter.get(filterName) || all;

    return filterValue ? selector(filterValue) : all();
  };
}

export function makeGetFilteredClientWorkflows() {
  return createSelector(
    [getFilters, getClientWorkflows, getAssignee, getCurrentAdvisor],
    (filters, clientWorkflows, assignee, currentUser) => {
      const predicates = predicateSelector(
        createPredicates(makeAssignee(assignee, currentUser))
      );

      return keyBy(
        filters.map(predicates).reduce(filter, clientWorkflows),
        "id"
      );
    }
  );
}

export default makeGetFilteredClientWorkflows;
