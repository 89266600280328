import React, { Component } from "react";
import { Modal, Button } from "semantic-ui-react";
import PropTypes from "prop-types";

class AgreementArea extends Component {
  static propTypes = {
    agree: PropTypes.func.isRequired,
    policy: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    numAgreed: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      scrolled: props.policy.text.length < 100,
      agreeing: false,
    };
  }

  agree = () => {
    const { policy, agree } = this.props;
    this.setState({ agreeing: true });
    agree(policy, () => {
      this.setState({ agreeing: false });
    });
  };

  scrollListener = (e) => {
    const { target } = e;
    if ((target.scrollHeight - target.clientHeight) * 0.98 < target.scrollTop) {
      this.setState({ scrolled: true });
    }
  };

  render() {
    const { agreeing, scrolled } = this.state;
    const { policy, index, numAgreed } = this.props;
    // don't render ones that are not of the step (but we still map so that the constructor is recalled)
    if (index !== numAgreed) return <span />;
    return (
      <React.Fragment>
        <div
          onScroll={this.scrollListener}
          style={{ maxHeight: "50vh", overflowY: "scroll" }}
          className="scrolling_policy_container"
        >
          <div style={{ padding: "1em" }}>
            <div
              dangerouslySetInnerHTML={policy.markup}
              className="policy_content"
              data-test="policy-div"
            />
          </div>
        </div>
        <Modal.Actions style={{ overflow: "auto" }}>
          <div style={{ float: "right" }}>
            <div style={{ display: "flex" }}>
              <div
                style={{
                  color: "grey",
                  fontSize: "9pt",
                  marginRight: "1em",
                  paddingTop: "0.5em",
                }}
              >
                By clicking the “Agree” button, you confirm that you have read
                and agree to be bound by this agreement. You also represent and
                warrant that you have legal authority to bind your organization
                to these terms, and that you are of the legal age of majority in
                the jurisdiction in which you reside. You can download a copy of
                this agreement&nbsp;
                {policy.blob && (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={policy.blob}
                  >
                    here
                  </a>
                )}
                .
              </div>
              <Button
                data-test="agree-policy-button"
                color="green"
                content="Agree"
                onClick={this.agree}
                loading={agreeing}
                disabled={!scrolled}
                style={{ width: "max-content" }}
                className="policy_agreement_button"
              />
            </div>
          </div>
        </Modal.Actions>
      </React.Fragment>
    );
  }
}

export default AgreementArea;
