import React from "react";
import { Dimmer, Loader } from "semantic-ui-react";
import PropTypes from "prop-types";

export class Loading extends React.Component {
  static defaultProps = {
    inverted: false,
    messages: ["Loading HUBLY"],
    size: "large",
    showLogo: false,
  };

  static propTypes = {
    active: PropTypes.bool.isRequired,
    messages: PropTypes.array,
    inverted: PropTypes.bool,
    showLogo: PropTypes.bool,
    size: PropTypes.string,
  };

  state = {
    index: 0,
  };

  componentDidMount() {
    const { index } = this.state;
    this.interval = setInterval(() => {
      this.setState({ index: index + 1 });
    }, 400);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const { active, messages, inverted, size, showLogo = false } = this.props;
    const { index } = this.state;
    return (
      <Dimmer
        style={{ backgroundColor: "#262626" }}
        active={active}
        inverted={inverted}
      >
        {showLogo && (
          <img
            src="/images/hubly.png"
            width="150"
            alt="Hubly logo"
            style={{
              filter: !inverted ? "invert(100%)" : "none",
              zIndex: 999,
              marginBottom: "110px",
              animation: "fadeIn 3s",
            }}
          />
        )}
        <Loader size={size} style={{ marginTop: "1em" }}>
          {messages[Math.min(index, messages.length)]}
        </Loader>
      </Dimmer>
    );
  }
}

export default Loading;
