import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  Popup,
  Button,
  Icon,
  Responsive,
  Header,
  Divider,
} from "semantic-ui-react";
import { ClearFilters, IsFilterOn } from "data/filters/actions";
import { UpdateAdvisor } from "data/advisor/actions";
import { AddClientToWorkflow } from "../ClientTile/actions";
import StreamFilters from "./StreamFilters";
import WorkflowFilters from "./WorkflowFilters";
import NewWorkflow from "./NewWorkflow";
import ManageStreams from "./ManageStreams";
import WorkflowLibraryModal from "./WorkflowLibrary";
import TagFilters from "./TagFilters";
import MoreFilters from "./MoreFilters";
import Views from "./Views";
import SaveView from "./Views/components/SaveView";
import ManageTags from "./ManageTags";
import AssigneeFilters from "./AssigneeFilters";
import ReminderFilters from "./ReminderFilter";
import ClientFilter from "./Filters/ClientFilter";

class HubMenu extends React.Component {
  static propTypes = {
    advisor: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
    clearFilters: PropTypes.func.isRequired,
    currentView: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
    filters: PropTypes.object.isRequired,
    scrollRight: PropTypes.func.isRequired,
    updateAdvisor: PropTypes.func.isRequired,
    workflows: PropTypes.object.isRequired,
  };

  state = {
    expanded: false,
  };

  clearAll = () => {
    const { advisor, clearFilters, updateAdvisor, workflows } = this.props;
    clearFilters(Object.values(workflows));
    updateAdvisor(advisor.id, { defaultView: null });
  };

  toggleExpanded = () => {
    this.setState((state) => {
      return { expanded: !state.expanded };
    });
  };

  render() {
    const { expanded } = this.state;
    const { currentView, filters, scrollRight } = this.props;
    const { streams, tags, assignee, filterEmptyWorkflows, reminderTypes } =
      filters;
    const isFilterOn = IsFilterOn(filters);
    return (
      <div
        data-test="hub-menu"
        style={{
          width: "calc(100vw - 40px - 1.5em)",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          position: "absolute",
          flexWrap: "nowrap",
          height: "50px",
        }}
      >
        <Views />
        <WorkflowFilters />
        {isFilterOn && (
          <Popup
            header="Filters"
            on="click"
            trigger={
              <Button
                data-test="filter-menu-button"
                basic
                primary={
                  streams.length > 0 ||
                  tags.length > 0 ||
                  filterEmptyWorkflows ||
                  assignee !== "ALL" ||
                  reminderTypes.length > 0
                }
                style={{
                  marginRight: "0.5em",
                  minWidth: "110px",
                  whiteSpace: "nowrap",
                }}
                onClick={this.toggleExpanded}
              >
                <Icon name="filter" />
                Filters
              </Button>
            }
            open={expanded}
            onOpen={() => {
              this.setState({ expanded: true });
            }}
            onClose={() => {
              this.setState({ expanded: false });
            }}
            className="dont_padd"
          >
            <Header
              as="h1"
              style={{ padding: "10px 15px", fontSize: "14pt", margin: "0px" }}
            >
              Filters
            </Header>
            <Divider style={{ margin: "0px" }} />
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "auto auto auto",
                alignItems: "center",
                padding: "15px",
                gridRowGap: "1em",
              }}
            >
              <AssigneeFilters />
              <ClientFilter />
              <ReminderFilters />
              <StreamFilters />
              <TagFilters />
            </div>
            <MoreFilters />
          </Popup>
        )}
        {isFilterOn && (
          <Responsive
            maxWidth={1050}
            as={Button}
            basic
            disabled={!isFilterOn}
            icon="remove"
            onClick={this.clearAll}
            style={{ marginRight: "0.5em", minWidth: "max-content" }}
          />
        )}
        {isFilterOn && (
          <Responsive
            data-test="hub-menu-clear-all"
            minWidth={1051}
            as={Button}
            basic
            compact
            content="Clear All"
            disabled={!isFilterOn}
            onClick={this.clearAll}
            style={{ marginRight: "0.5em", minWidth: "max-content" }}
          />
        )}

        {(isFilterOn || (filters.isFilterUpdated && currentView.id)) && (
          <SaveView />
        )}
        <div
          style={{
            display: "flex",
            padding: "0.25em 0.5em",
            marginLeft: "auto",
          }}
        >
          <ManageTags />
          <ManageStreams />
          <WorkflowLibraryModal />
          <NewWorkflow scrollRight={scrollRight} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    advisor: state.hubly.data.advisor,
    currentView: state.hubly.data.views.currentView,
    filters: state.hubly.data.filters,
    workflows: state.hubly.data.hub.workflows,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addClientToWorkflow: (clientWorkflow) => {
      dispatch(AddClientToWorkflow(clientWorkflow));
    },
    clearFilters: (workflows) => {
      dispatch(ClearFilters(workflows));
    },
    updateAdvisor: (id, request, callback) => {
      dispatch(UpdateAdvisor(id, request, callback));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HubMenu);
