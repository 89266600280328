import { useContext } from "react";

import useFetch from "hooks/useFetch";
import { HubContext } from "scenes/Hubly/components/Workspace/Provider";

const options = [
  { key: "SHOW_ALL", value: "SHOW_ALL", text: "Any User" },
  { key: "CURRENT_USER", value: "CURRENT_USER", text: "Current User" },
];

export function useAssignees() {
  const { fetchAssignees } = useContext(HubContext);
  const { loading, data } = useFetch(fetchAssignees);

  if (loading) {
    return {
      loading: true,
      defaultAssignee: "CURRENT_USER",
      assignees: [],
    };
  }

  const all = options.concat(
    data.map(({ id, firstName, lastName }) => {
      return {
        key: id,
        value: id,
        text: `${firstName} ${lastName}`,
      };
    })
  );

  return { loading: false, defaultAssignee: "CURRENT_USER", assignees: all };
}

export default useAssignees;
