import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { Dropdown } from "semantic-ui-react";
import { MeetingDateTypeContext } from "./Context";
import { getDefaultMeetingDateType, filterMeetingDateTypes } from "./helpers";

const MeetingDateTypeDropdown = ({
  setSelectedMeetingDateType,
  selectedMeetingDateType,
  useDefaultValue,
  allowCreateNewMeetingDateType,
}) => {
  const { meetingDateTypes, createMeetingDateType } = useContext(
    MeetingDateTypeContext
  );

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchValue, setSearchValue] = useState();
  const [loading, setLoading] = useState(false);

  const filteredMeetingDateTypes = filterMeetingDateTypes(
    meetingDateTypes,
    searchValue
  );
  const handleAddNewMeetingType = async () => {
    setLoading(true);
    const newMeetingType = await createMeetingDateType(searchValue);
    setLoading(false);
    setSelectedMeetingDateType(newMeetingType);
    setDropdownOpen(false);
  };

  if (useDefaultValue && !selectedMeetingDateType) {
    setSelectedMeetingDateType(getDefaultMeetingDateType(meetingDateTypes));
  }

  return (
    <Dropdown
      placeholder="Select Meeting Type"
      search
      selection
      fluid
      loading={loading}
      style={{ marginBottom: "1rem" }}
      open={dropdownOpen}
      onClose={() => {
        setDropdownOpen(false);
      }}
      onOpen={() => {
        setDropdownOpen(true);
        setSearchValue(undefined);
      }}
      openOnFocus={false}
      options={filteredMeetingDateTypes.map((m) => {
        return { ...m, text: m.name, key: m.id, value: m.name };
      })}
      value={selectedMeetingDateType?.name}
      onSearchChange={(e) => {
        setSearchValue(e.target.value || "");
      }}
      searchQuery={searchValue}
      data-test="meetingDateType-dropdown"
    >
      <Dropdown.Menu>
        {filteredMeetingDateTypes?.length === 0 &&
          searchValue?.length > 0 &&
          allowCreateNewMeetingDateType && (
            <Dropdown.Item
              onClick={handleAddNewMeetingType}
              disabled={loading}
              data-test="meetingDateType-dropdown-item"
            >
              <b>+ Create </b>
              {searchValue}
            </Dropdown.Item>
          )}
        {filteredMeetingDateTypes.map((meetingDateType) => {
          const { id, name } = meetingDateType;
          return (
            <Dropdown.Item
              key={id}
              value={name}
              onClick={() => {
                setSelectedMeetingDateType(meetingDateType);
                setSearchValue(name);
                setDropdownOpen(false);
              }}
              data-test="meetingDateType-dropdown-item"
            >
              {name}
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};

MeetingDateTypeDropdown.propTypes = {
  selectedMeetingDateType: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    hubId: PropTypes.string.isRequired,
  }),
  setSelectedMeetingDateType: PropTypes.func.isRequired,
  useDefaultValue: PropTypes.bool,
  allowCreateNewMeetingDateType: PropTypes.bool,
};

MeetingDateTypeDropdown.defaultProps = {
  selectedMeetingDateType: undefined,
  useDefaultValue: true,
  allowCreateNewMeetingDateType: true,
};

export default MeetingDateTypeDropdown;
