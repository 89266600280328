import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { publishUpdate } from "./actions";

export function useSubscribe(channel, onNotify) {
  const update = useSelector(({ hubly }) => {
    return hubly.data.appMessages[channel];
  });

  React.useEffect(() => {
    onNotify(update);
  }, [update, onNotify]);
}

export function useNotify(events) {
  const dispatch = useDispatch();

  return (value) => {
    dispatch(publishUpdate(events, value));
  };
}
