import React from "react";
import PropTypes from "prop-types";
import { Dropdown } from "semantic-ui-react";
import ClientSyncButtonWithRedux from "../ClientSyncButton/ClientSyncButtonWithRedux";

export const SyncOption = ({
  disabled,
  integration,
  onSyncProgressUpdated,
}) => {
  return (
    <Dropdown.Item
      key="sync"
      value="sync"
      disabled={disabled}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <ClientSyncButtonWithRedux
        integrationType={integration.type}
        onSyncProgressUpdated={onSyncProgressUpdated}
      />
    </Dropdown.Item>
  );
};

SyncOption.defaultProps = {
  onSyncProgressUpdated: () => {},
  disabled: false,
};

SyncOption.propTypes = {
  integration: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  onSyncProgressUpdated: PropTypes.func,
};

export default SyncOption;
