import React from "react";
import PropTypes from "prop-types";

import FilterLayout from "components/FilterLayout";
import { MultipleClientDropdown } from "components/ClientDropdown";

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export function ManyToManyFilters({
  type,
  filterType,
  selected,
  onUpdateFilter,
}) {
  const withType = (t) => {
    return (obj) => {
      return { ...obj, type: t };
    };
  };

  return (
    <FilterLayout
      title={`${capitalizeFirstLetter(type)}s`}
      onClearFilter={() => {
        onUpdateFilter({ [`${type}FilterType`]: "ANY", [`${type}s`]: [] });
      }}
      showFilterTypeControl
      filterType={filterType}
      onFilterTypeUpdated={(ft) => {
        onUpdateFilter({ [`${type}FilterType`]: ft });
      }}
    >
      <MultipleClientDropdown
        data-test={`filter-menu-${type}s`}
        name={`${capitalizeFirstLetter(type)}s`}
        defaultSection={`${capitalizeFirstLetter(type)}s`}
        placeholder={`Select ${type}s`}
        showStreams={type === "stream"}
        showTags={type === "tag"}
        value={selected.map(withType(type))}
        dropdownStyle={{ width: "100%", marginBottom: 0 }}
        closeOnClick={false}
        onChange={(_, { value }) => {
          onUpdateFilter({ [`${type}s`]: value });
        }}
      />
    </FilterLayout>
  );
}

ManyToManyFilters.defaultProps = {
  filterType: "ANY",
  selected: [],
};

ManyToManyFilters.propTypes = {
  type: PropTypes.string.isRequired,
  filterType: PropTypes.string,
  selected: PropTypes.arrayOf(PropTypes.object),
  onUpdateFilter: PropTypes.func.isRequired,
};

export default ManyToManyFilters;
