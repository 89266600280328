import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Button } from "semantic-ui-react";

import config from "config";

import DuplicateWorkflows from "../DuplicateWorkflows";

function Menu({ isSupportTeamUser }) {
  const [open, setOpen] = React.useState(false);

  if (!isSupportTeamUser) {
    return null;
  }

  return (
    <React.Fragment>
      <Button onClick={setOpen}>
        <span role="img" aria-label="supervillan">
          🦹‍♂️
        </span>
        Magic Box
        <span role="img" aria-label="supervillan">
          🦹‍♂️
        </span>
      </Button>
      <DuplicateWorkflows
        open={open}
        onClose={() => {
          return setOpen(false);
        }}
      />
    </React.Fragment>
  );
}

Menu.propTypes = {
  isSupportTeamUser: PropTypes.bool,
};

Menu.defaultProps = {
  isSupportTeamUser: false,
};

const mapStateToProps = (state) => {
  const isSupportTeamUser = (advisor, supportUser) => {
    const { email = "" } = advisor;
    const { emailPrefix = "support", domain = "myhubly.com" } =
      supportUser || {};

    return email.includes(`${emailPrefix}@${domain}`);
  };

  return {
    isSupportTeamUser: isSupportTeamUser(
      state.hubly.data.advisor,
      config.supportUser
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
