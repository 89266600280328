import { compact } from "lodash";
import { combineReducers } from "redux";

function createSetReducer(eventName) {
  return (s = new Set(), action) => {
    switch (action.type) {
      case eventName:
        return new Set(compact([...action.value]));
      default:
        return s;
    }
  };
}

export const reducer = combineReducers({
  workflows: createSetReducer("NOTIFY_WORKFLOWS"),
});

export default reducer;
