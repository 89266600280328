import React from "react";
import PropTypes from "prop-types";

import { Grid, Placeholder } from "semantic-ui-react";

import EmptyWorkflowToggle from "./components/EmptyWorkflowToggle";
import ShowNextTaskFilter from "./components/ShowNextTaskFilter";

export function MoreFilters({
  loading,
  assigneeDisplayName,
  filterEmptyWorkflows,
  filterNextTaskForAssignee,
  onUpdateFilter,
}) {
  if (loading) {
    return (
      <div style={{ padding: "2em 1em 0em 0em" }}>
        <Placeholder>
          <Placeholder.Header>
            <Placeholder.Line />
          </Placeholder.Header>
          <Placeholder.Paragraph>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Paragraph>
        </Placeholder>
      </div>
    );
  }

  return (
    <div style={{ padding: "1.5em 1em 0em 0em" }}>
      <div style={{ margin: "0 0 1.5em 0", fontWeight: "bold" }}>
        Display Options
      </div>
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <EmptyWorkflowToggle
              filterEmptyWorkflows={filterEmptyWorkflows}
              onChange={onUpdateFilter}
            />
          </Grid.Column>
        </Grid.Row>
        {assigneeDisplayName && assigneeDisplayName !== "ALL" && (
          <Grid.Row>
            <Grid.Column>
              <ShowNextTaskFilter
                assigneeDisplayName={assigneeDisplayName}
                filterNextTaskForAssignee={filterNextTaskForAssignee}
                onChange={onUpdateFilter}
              />
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    </div>
  );
}

MoreFilters.defaultProps = {
  filterEmptyWorkflows: false,
  filterNextTaskForAssignee: false,
  assigneeDisplayName: null,
  loading: false,
};

MoreFilters.propTypes = {
  loading: PropTypes.bool,
  filterEmptyWorkflows: PropTypes.bool,
  filterNextTaskForAssignee: PropTypes.bool,
  assigneeDisplayName: PropTypes.string,
  onUpdateFilter: PropTypes.func.isRequired,
};

export default MoreFilters;
