import React from "react";
import { Placeholder, Grid } from "semantic-ui-react";
import { v4 } from "uuid";
import { range } from "lodash";

export function MemberItemPlaceholder() {
  return (
    <React.Fragment>
      {range(2).map(() => {
        return (
          <React.Fragment key={v4()}>
            <Grid.Column width={6}>
              <Placeholder>
                <Placeholder.Header>
                  <Placeholder.Line length="very long" />
                </Placeholder.Header>
              </Placeholder>
            </Grid.Column>
            <Grid.Column width={7}>
              <Placeholder>
                <Placeholder.Header>
                  <Placeholder.Line length="very long" />
                </Placeholder.Header>
              </Placeholder>
            </Grid.Column>
            <Grid.Column textAlign="right" width={3}>
              <Placeholder>
                <Placeholder.Header>
                  <Placeholder.Line length="very long" />
                </Placeholder.Header>
              </Placeholder>
            </Grid.Column>
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
}

export default MemberItemPlaceholder;
