import React from "react";
import PropTypes from "prop-types";

import { Container, Placeholder } from "semantic-ui-react";

import { useDraggable } from "components/DragDrop";

import SimpleLinePlaceholder from "components/SimpleLinePlaceholder/SimpleLinePlaceholder";

export function EditorPlaceholder({ numLines }) {
  const { draggableProps, dragHandleProps } = useDraggable();
  return (
    <div {...draggableProps}>
      <div
        className="ui segment workflow"
        style={{
          marginBottom: "0",
          maxHeight: "calc(100vh - 125px)",
        }}
      >
        <div className="workflow_transition" style={{ width: "500px" }}>
          <Container
            {...dragHandleProps}
            style={{
              borderBottom: "1px #e0e1e2 solid",
              backgroundColor: "white",
              transition: "backgroundColor 0.2s",
              margin: "0.25em",
              paddingBottom: "1em",
              padding: "0.8em",
            }}
          >
            <Placeholder>
              <Placeholder.Header image>
                <Placeholder.Line length="very long" />
                <Placeholder.Line />
              </Placeholder.Header>
            </Placeholder>
          </Container>
          <Container style={{ padding: "0.8em" }}>
            <Container>
              <SimpleLinePlaceholder numLines={numLines} />
            </Container>
          </Container>
        </div>
      </div>
    </div>
  );
}

EditorPlaceholder.propTypes = {
  numLines: PropTypes.number,
};

EditorPlaceholder.defaultProps = {
  numLines: 5,
};

export default EditorPlaceholder;
