import React from "react";
import PropTypes from "prop-types";

import { Segment, Placeholder } from "semantic-ui-react";

export function TilePlaceholder({ style }) {
  return (
    <Segment style={style}>
      <Placeholder fluid>
        <Placeholder.Header image>
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder.Header>
        <Placeholder.Paragraph>
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder.Paragraph>
      </Placeholder>
    </Segment>
  );
}

TilePlaceholder.propTypes = {
  style: PropTypes.object,
};

TilePlaceholder.defaultProps = {
  style: { margin: "0.8em 0" },
};

export default TilePlaceholder;
