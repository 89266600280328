import React from "react";
import PropTypes from "prop-types";
import { Header, Button, Icon, Message } from "semantic-ui-react";

export function Layout({
  loading,
  header,
  hourInput,
  minuteInput,
  children,
  showActionButtons,
  disabled,
  error,
  onClose,
  onSave,
}) {
  return (
    <div
      style={{
        maxHeight: "500px",
        minWidth: "500px",
        overflowY: "auto",
        alignContent: "stretch",
      }}
    >
      {header && (
        <div style={{ display: "flex" }}>
          <Header as="h4" style={{ marginBottom: "1em" }}>
            {header}
          </Header>
        </div>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          paddingTop: "1em",
        }}
      >
        <Message negative hidden={!error}>
          <Message.Header>Error</Message.Header>
          <p>{error}</p>
        </Message>
        <div
          style={{
            marginBottom: "0.25em",
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <div style={{ maxWidth: "49%", color: "#9c9c9c", flexGrow: 1 }}>
            {hourInput} Hours
          </div>
          <div style={{ maxWidth: "49%", color: "#9c9c9c", flexGrow: 1 }}>
            {minuteInput} Minutes
          </div>
        </div>
        <div
          style={{
            marginBottom: "0.25em",
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          {children}
        </div>
      </div>
      <div
        style={{
          marginTop: "1em",
          display: "flex",
          alignItems: "center",
        }}
      >
        {showActionButtons && (
          <React.Fragment>
            <Button
              loading={loading}
              disabled={disabled || loading}
              onClick={onSave}
              color="green"
            >
              <Icon name="plus" />
              Add
            </Button>
            <Icon
              disabled={loading}
              link
              name="close"
              size="large"
              color="grey"
              style={{ marginLeft: "0.5em" }}
              onClick={onClose}
            />
          </React.Fragment>
        )}
      </div>
    </div>
  );
}
Layout.propTypes = {
  header: PropTypes.string,
  hourInput: PropTypes.node,
  minuteInput: PropTypes.node,
  children: PropTypes.node,
  showActionButtons: PropTypes.bool,
  loading: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  disabled: PropTypes.bool,
  error: PropTypes.array,
};
Layout.defaultProps = {
  header: "",
  disabled: false,
  hourInput: null,
  minuteInput: null,
  children: null,
  error: null,
  showActionButtons: false,
  loading: false,
  onClose: () => {},
  onSave: () => {},
};

export default Layout;
