import React from "react";
import { Label, Icon } from "semantic-ui-react";
import PropTypes from "prop-types";
import PopupMenu from "components/PopupMenu";
import { connect } from "react-redux";
import { SetConfirmationModal } from "components/ConfirmationModal/actions";
import {
  RemoveWorkflowRule,
  SetEditWorkflowRule,
  UpdateWorkflowRule,
} from "../actions";

class RepeatingWorkflowRule extends React.Component {
  static propTypes = {
    removeWorkflowRule: PropTypes.func.isRequired,
    rule: PropTypes.object.isRequired,
    setConfirmationModal: PropTypes.func.isRequired,
    setEditWorkflowRule: PropTypes.func.isRequired,
    setWorkflowRuleModal: PropTypes.func.isRequired,
    tags: PropTypes.object.isRequired,
    workflow: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
    updateWorkflowRule: PropTypes.func.isRequired,
  };

  state = {
    popupOpen: false,
  };

  confirmDeleteRule = () => {
    const { removeWorkflowRule, rule, setConfirmationModal, workflow } =
      this.props;
    const remove = () => {
      removeWorkflowRule(workflow, rule.id);
    };
    const params = {
      title: "Delete Rule",
      message: `Are you sure you wish to delete this rule and its configurations? This cannot be undone.`,
      icon: "delete",
      buttons: [
        {
          text: "Cancel",
        },
        {
          text: "Delete Rule",
          callBack: remove,
          color: "red",
        },
      ],
    };
    setConfirmationModal(params);
  };

  toggleActive = () => {
    const { rule, workflow, updateWorkflowRule } = this.props;
    updateWorkflowRule(
      workflow.id,
      { active: !rule.active, id: rule.id },
      () => {}
    );
  };

  openEditRuleModal = () => {
    const { rule, setEditWorkflowRule, setWorkflowRuleModal } = this.props;
    setEditWorkflowRule(rule);
    setWorkflowRuleModal(true, "stream");
  };

  render() {
    const { popupOpen } = this.state;
    const { rule, tags } = this.props;
    let addRemoveText;
    let ruleActionText;

    if (rule.type === "ADD_ATTRIBUTES") {
      addRemoveText = "added";
    } else {
      addRemoveText = "removed";
    }

    if (rule.action === "ADDED_TO_WORKFLOW") {
      ruleActionText = "is added to";
    } else if (rule.action === "ARCHIVED_FROM_WORKFLOW") {
      ruleActionText = "is archived from";
    } else if (rule.action === "COMPLETED_WORKFLOW") {
      ruleActionText = "completes";
    } else {
      ruleActionText = "is removed from";
    }
    const text = `will be ${addRemoveText} when a client ${ruleActionText} this workflow`;

    return (
      <div
        className="grey_on_hover rounded p25"
        style={{ display: "flex", marginBottom: "0.5em", cursor: "pointer" }}
        data-test="automation-rule-item-stream"
        onDoubleClick={this.openEditRuleModal}
      >
        <div style={{ opacity: rule.active ? "1" : "0.4" }}>
          {rule.streams.length <= 0 && rule.tags.length <= 0 && (
            <span
              style={{ color: "grey" }}
              title="No stream(s)/tag(s) found. Please specify new stream(s)/tag(s) to resume rule."
            >
              N/A
            </span>
          )}
          {rule.streams.map((stream) => {
            return (
              <Label
                style={{
                  backgroundColor: stream.color,
                  color: "#fff",
                  padding: "0.3em 0.4em",
                  margin: "0.1em",
                }}
              >
                {stream.name}
              </Label>
            );
          })}
          {rule.tags.map((tag) => {
            return (
              <Label
                style={{
                  backgroundColor: "grey",
                  color: "#fff",
                  padding: "0.3em 0.4em",
                  margin: "0.1em",
                }}
              >
                <Icon fitted name="hashtag" />
                &nbsp;
                {tags[tag].name}
              </Label>
            );
          })}
          &nbsp;
          {text}
          {!rule.active && " (Paused)"}
        </div>
        <div style={{ display: "flex", marginLeft: "0.25em" }}>
          <PopupMenu
            buttons={[
              {
                content: "Edit Rule",
                icon: "edit",
                onClick: () => {
                  this.openEditRuleModal();
                  this.setState({ popupOpen: false });
                },
              },
              {
                content: rule.active ? "Pause Rule" : "Resume Rule",
                icon: rule.active ? "pause" : "play",
                onClick: () => {
                  this.toggleActive();
                  this.setState({ popupOpen: false });
                },
              },
              {
                content: "Delete Rule",
                icon: "delete",
                onClick: () => {
                  this.confirmDeleteRule();
                  this.setState({ popupOpen: false });
                },
              },
            ]}
            onOpen={() => {
              this.setState({ popupOpen: true });
            }}
            onClose={() => {
              this.setState({ popupOpen: false });
            }}
            open={popupOpen}
            popperModifiers={{
              preventOverflow: { boundariesElement: "window", enabled: false },
            }}
            trigger={<Icon name="ellipsis horizontal" link color="grey" />}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tags: state.hubly.data.hub.tags,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    removeWorkflowRule: (workflow, workflowRule, callback) => {
      dispatch(RemoveWorkflowRule(workflow, workflowRule, callback));
    },
    setConfirmationModal: (id) => {
      dispatch(SetConfirmationModal(id));
    },
    setEditWorkflowRule: (workflowRule) => {
      dispatch(SetEditWorkflowRule(workflowRule));
    },
    updateWorkflowRule: (workflow, workflowRule, callback) => {
      dispatch(UpdateWorkflowRule(workflow, workflowRule, callback));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RepeatingWorkflowRule);
