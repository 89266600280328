import WebsocketManager from "data/libs/websockets";
import { combineReducers, createStore, compose, applyMiddleware } from "redux";
import { createLogger } from "redux-logger";
import thunk from "redux-thunk";
import analyticsMiddleware from "./analytics/middleware";
import appReducer from "./reducer";

const logger = createLogger({
  predicate: () => {
    return (
      process.env.NODE_ENV === "development" || process.env.NODE_ENV === "local"
    );
  },
  collapsed: (getState, action, logEntry) => {
    return !logEntry.error;
  },
});

const webSocketMiddleware = ({ getState }) => {
  const websocketManager = new WebsocketManager();
  return (dispatch) => {
    // eslint-disable-next-line consistent-return
    return (action) => {
      switch (action.type) {
        case "WEBSOCKET_CONNECT":
          websocketManager.connect(dispatch, getState);
          break;
        case "WEBSOCKET_DISCONNECT":
          websocketManager.disconnect();
          break;
        case "WEBSOCKET_SEND":
          websocketManager.send(action.message);
          break;
        case "SET_WEBSOCKET_BOARD":
          websocketManager.setWebsocketHubId(action.id);
          break;
        case "SEND_WEBSOCKET_SYNC": // something needs syncing
          websocketManager.sendSync(action.sync, action.response);
          break;
        default:
          return dispatch(action);
      }
    };
  };
};

const rootReducer = combineReducers({
  hubly: appReducer,
});

const Store = createStore(
  rootReducer,
  {},
  compose(
    applyMiddleware(thunk, logger, analyticsMiddleware),
    applyMiddleware(thunk, webSocketMiddleware)
  )
);

export default Store;
