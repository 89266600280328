import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { Popup, Loader } from "semantic-ui-react";

import UserSelector from "components/UserSelector";
import {
  EmptyCircle,
  InitialsCircle,
  withHoverEffect,
} from "components/InitialsCircle";

const InitialsCircleWithHover = withHoverEffect(InitialsCircle);

export function Assigner({
  loading,
  currentAssignee,
  iconProperties,
  fetchAssignees,
  style,
  onAssign,
}) {
  const [loadingAssignees, setLoadingAssignees] = useState(false);
  const [assignees, setAssignees] = useState([]);
  const [openSelector, setSelectorOpen] = useState(false);
  const [waitingOnSubmit, setWaitingOnSubmit] = useState(false);

  useEffect(() => {
    const fetchAndSetAssignees = async () => {
      setLoadingAssignees(true);
      try {
        setAssignees(await fetchAssignees());
      } finally {
        setLoadingAssignees(false);
      }
    };

    if (openSelector) {
      fetchAndSetAssignees();
    }
  }, [openSelector, fetchAssignees]);

  // eslint-disable-next-line react/prop-types
  const Trigger = ({ onClick, ...props }) => {
    if (currentAssignee) {
      const { firstName, lastName } = currentAssignee || {};
      return (
        <InitialsCircleWithHover
          first={firstName}
          last={lastName}
          properties={iconProperties}
          onClick={(e) => {
            e.stopPropagation();
            onClick(e);
          }}
          {...props}
        >
          {`${firstName} ${lastName}`}
        </InitialsCircleWithHover>
      );
    }

    return (
      <EmptyCircle
        clicked={openSelector}
        onClick={(e) => {
          e.stopPropagation();
          onClick(e);
        }}
        // eslint-disable-next-line react/prop-types
        iconSize={iconProperties.addIconSize}
        {...props}
      />
    );
  };

  if (loadingAssignees || loading) {
    return (
      <div style={{ ...style }}>
        <Loader active inline />
      </div>
    );
  }

  return (
    <div
      style={{ ...style }}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Popup
        onClose={() => {
          setSelectorOpen(false);
        }}
        onOpen={() => {
          setSelectorOpen(true);
        }}
        open={openSelector}
        data-test="assign-advisor-popup"
        offset="-10px, 0"
        on="click"
        popperModifiers={{
          preventOverflow: { boundariesElement: "window", enabled: false },
        }}
        position="bottom left"
        trigger={<Trigger />}
      >
        <UserSelector
          loading={waitingOnSubmit}
          initSelectedUserId={currentAssignee?.id || "unassigned"}
          users={assignees}
          onSelect={async (userId) => {
            setWaitingOnSubmit(true);
            try {
              const assignee = assignees.find(({ id }) => {
                return id === userId;
              });
              await onAssign(userId, assignee?.firstName, assignee?.lastName);
              setSelectorOpen(false);
            } finally {
              setWaitingOnSubmit(false);
            }
          }}
        />
      </Popup>
    </div>
  );
}

Assigner.propTypes = {
  onAssign: PropTypes.func.isRequired,
  fetchAssignees: PropTypes.func.isRequired,
  currentAssignee: PropTypes.shape({
    id: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
  }),
  iconProperties: PropTypes.shape({
    addIconSize: PropTypes.string.isRequired,
    circleFontSize: PropTypes.string.isRequired,
    circleSize: PropTypes.string.isRequired,
  }),
  style: PropTypes.object,
  loading: PropTypes.bool,
};

Assigner.defaultProps = {
  currentAssignee: undefined,
  loading: false,
  iconProperties: {
    addIconSize: "25px",
    circleFontSize: "13px",
    circleSize: "20px",
  },
  style: {},
};

export default Assigner;
