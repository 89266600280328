import React from "react";
import {
  Button,
  Header,
  Modal,
  Checkbox,
  Popup,
  Icon,
} from "semantic-ui-react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { v4 } from "uuid";
import { PrivacyModeContext } from "components/PrivacyMode/Context";
import { SetConfirmationModal } from "./actions";

class ConfirmationModal extends React.Component {
  static contextType = PrivacyModeContext;

  static defaultProps = {
    params: null,
  };

  static propTypes = {
    params: PropTypes.shape({
      icon: PropTypes.string,
      title: PropTypes.string.isRequired,
      message: PropTypes.string,
      children: PropTypes.node,
      buttons: PropTypes.arrayOf(
        PropTypes.shape({
          color: PropTypes.string,
          icon: PropTypes.string,
          text: PropTypes.string.isRequired,
          callBack: PropTypes.func,
        })
      ).isRequired,
      checks: PropTypes.array,
    }),
    confirmation: PropTypes.func.isRequired,
  };

  state = {};

  render() {
    const { params, confirmation } = this.props;
    const { piiMask } = this.context;

    if (!params) {
      return null;
    }
    return (
      <Modal open basic size="small" data-test="confirmation-modal">
        <Header
          icon={params.icon}
          className={piiMask("fs-block dd-privacy-mask")}
          content={params.title}
        />
        <Modal.Content className={piiMask("fs-block dd-privacy-mask")}>
          {params.message ? ( // show a message or children
            <p>{params.message}</p>
          ) : (
            params.children
          )}
        </Modal.Content>
        <Modal.Actions>
          {params.buttons.map((b) => {
            return (
              <Button
                className={piiMask("fs-block dd-privacy-mask")}
                key={v4()}
                inverted
                color={b.color}
                icon={b.icon}
                data-test="confirmation-modal-button"
                onClick={() => {
                  const checks = {};
                  if (params.checks) {
                    params.checks.forEach((c) => {
                      checks[c.name] = !!this.state[c.name];
                    });
                  }
                  if (b.callBack) {
                    b.callBack(checks);
                  }
                  confirmation(null);
                }}
                content={b.text}
              />
            );
          })}
        </Modal.Actions>
        {params.checks && (
          <Modal.Actions style={{ textAlign: "left" }}>
            {params.checks.map((check) => {
              return (
                <div
                  key={check.name}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "0.5em",
                  }}
                >
                  <Checkbox
                    name={check.name}
                    onChange={(e, { name, checked }) => {
                      this.setState({ [name]: checked });
                    }}
                  />
                  <div style={{ color: "white", marginLeft: "0.5em" }}>
                    {check.text}
                  </div>
                  {check.info && (
                    <Popup
                      trigger={
                        <Icon
                          style={{ marginLeft: "0.5em" }}
                          name="question circle"
                          color="white"
                        />
                      }
                      on="hover"
                      position="bottom center"
                      content={check.info}
                    />
                  )}
                </div>
              );
            })}
          </Modal.Actions>
        )}
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    params: state.hubly.confirmation.confirmationParams,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    confirmation: (id) => {
      dispatch(SetConfirmationModal(id));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationModal);
