import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

import {
  HubContext,
  ToasterContext,
} from "scenes/Hubly/components/Workspace/Provider";

import FilterLayout from "components/FilterLayout";
import Selector from "components/Selector";

const defaultAssigneeOptions = [
  { key: "ALL", value: "ALL", text: "Any User" },
  { key: "UNASSIGNED", value: "UNASSIGNED", text: "Unassigned" },
  { key: "CURRENT", value: "CURRENT", text: "Current User" },
];

export function AssigneeFilters({
  assignee,
  assigneeFilterType,
  onUpdateFilter,
}) {
  const { fireError } = useContext(ToasterContext);
  const { fetchAssignees } = useContext(HubContext);

  const [loading, setLoading] = useState(false);
  const [advisors, setAdvisors] = useState([]);

  useEffect(() => {
    let mounted = true;

    setLoading(true);
    fetchAssignees()
      .then((data) => {
        if (mounted) {
          setAdvisors(data);
        }
      })
      .catch((error) => {
        fireError("Failed to load list of assignees", error);
      })
      .finally(() => {
        if (mounted) {
          setLoading(false);
        }
      });

    return () => {
      mounted = false;
    };
  }, [fetchAssignees, fireError]);

  const createOptions = () => {
    const advisorOptions = advisors.map(({ id, firstName, lastName }) => {
      return {
        key: id,
        value: id,
        text: `${firstName} ${lastName}`,
      };
    });

    if (assignee && assigneeFilterType === "ASSIGNEE") {
      if (
        !advisors.some(({ id }) => {
          return assignee === id;
        })
      ) {
        advisorOptions.push({
          key: assignee,
          value: assignee,
          text: "Non Hub Advisor",
        });
      }
    }
    return [...defaultAssigneeOptions, ...advisorOptions];
  };

  if (!loading && advisors.length < 2) {
    return null;
  }

  return (
    <FilterLayout
      title="Assignee"
      onClearFilter={() => {
        onUpdateFilter({
          assigneeFilterType: AssigneeFilters.defaultProps.assigneeFilterType,
          assignee: null,
        });
      }}
    >
      <Selector
        name="Assignee"
        dropdownProps={{
          placeholder: "Select assignee",
          loading: loading,
          search: false,
        }}
        selection={createOptions()}
        selected={
          assigneeFilterType === "ASSIGNEE" ? assignee : assigneeFilterType
        }
        multiple={false}
        onSelect={(value) => {
          const isDefaultOptionsSelected = defaultAssigneeOptions.some(
            (option) => {
              return option.value === value;
            }
          );
          onUpdateFilter({
            assigneeFilterType: isDefaultOptionsSelected ? value : "ASSIGNEE",
            assignee: isDefaultOptionsSelected ? null : value,
          });
        }}
      />
    </FilterLayout>
  );
}

AssigneeFilters.propTypes = {
  assigneeFilterType: PropTypes.string,
  assignee: PropTypes.string,
  onUpdateFilter: PropTypes.func.isRequired,
};

AssigneeFilters.defaultProps = {
  assignee: "",
  assigneeFilterType: "ALL",
};

export default AssigneeFilters;
