import React, { useState } from "react";
import PropTypes from "prop-types";

import { DragDropContext } from "react-beautiful-dnd";

import { createDroppable, DraggableFragment } from "components/DragDrop";

import Task from "../Task";
import {
  TaskCondition,
  TaskDetails,
  TaskEmailTemplate,
  TaskFutureReminder,
  TaskPopup,
  TaskTimeEstimate,
} from "../Task/components";
import { DetailsModal } from "../Task/components/TaskDetails/components";
import { hasDetails } from "../Task/components/TaskDetails/helper";

const DroppableTaskList = createDroppable("TaskList");
export function TaskList({
  workflowId,
  tasks,
  onTaskUpdated,
  onTaskDeleted,
  onTaskReOrdered,
  ...otherProps
}) {
  const [detailsModalProps, showDetailsProps] = useState({});
  const [detailsContent, setDetailsContent] = useState({});

  if (tasks.length === 0) {
    return (
      <div style={{ display: "block", color: "grey", paddingBottom: "0em" }}>
        No tasks yet
      </div>
    );
  }

  return (
    <DragDropContext
      onDragEnd={({ destination, source }) => {
        if (!destination || destination.index === source.index) {
          return;
        }
        onTaskReOrdered({ dst: destination.index, src: source.index });
      }}
    >
      <DroppableTaskList id="dnd-task-editor">
        {tasks.map((task, i) => {
          return (
            <DraggableFragment id={task.id} key={task.id} index={i}>
              <Task
                key={task.id}
                task={task}
                onUpdated={onTaskUpdated}
                taskDetailsContent={detailsContent[task.id]}
                {...otherProps}
              >
                <TaskFutureReminder
                  task={task}
                  index={i}
                  numTasks={tasks.length}
                  onTaskConfigChange={onTaskUpdated}
                />
                <TaskEmailTemplate
                  task={task}
                  onTaskConfigChange={onTaskUpdated}
                />
                <TaskTimeEstimate
                  task={task}
                  onTaskConfigChange={onTaskUpdated}
                />
                <TaskDetails
                  task={task}
                  expanded={
                    detailsContent[task.id] !== undefined &&
                    detailsContent[task.id] !== null
                  }
                  onTaskConfigChange={(updatedTask) => {
                    setDetailsContent({});
                    showDetailsProps((prevState) => {
                      return {
                        ...prevState,
                        [task.id]: null,
                      };
                    });
                    onTaskUpdated(updatedTask);
                  }}
                  onShowTaskDetailContents={(details) => {
                    setDetailsContent((prevState) => {
                      return {
                        ...prevState,
                        [task.id]: details,
                      };
                    });
                  }}
                  onShowDetailProps={(props) => {
                    showDetailsProps((prevState) => {
                      return {
                        ...prevState,
                        [task.id]: props,
                      };
                    });
                  }}
                >
                  {(props) => {
                    return (
                      detailsModalProps[task.id] && (
                        <DetailsModal
                          {...detailsModalProps[task.id]}
                          {...props}
                        />
                      )
                    );
                  }}
                </TaskDetails>
                <TaskCondition task={task} onTaskConfigChange={onTaskUpdated} />
                <TaskPopup
                  id={task.id}
                  title={task.title}
                  workflowId={workflowId}
                  onTaskDeleted={onTaskDeleted}
                  onEditTask={() => {
                    showDetailsProps((prevState) => {
                      return {
                        ...prevState,
                        [task.id]: {
                          task: task,
                          isCreating: !hasDetails(task),
                        },
                      };
                    });
                  }}
                  onCommentTask={() => {
                    // TODO HUB-2441: onCommentTask Callback when integrating with client card
                  }}
                />
              </Task>
            </DraggableFragment>
          );
        })}
      </DroppableTaskList>
    </DragDropContext>
  );
}

TaskList.propTypes = {
  tasks: PropTypes.array,
  workflowId: PropTypes.string,
  onTaskUpdated: PropTypes.func.isRequired,
  onTaskReOrdered: PropTypes.func.isRequired,
  onTaskDeleted: PropTypes.func.isRequired,
};

TaskList.defaultProps = {
  workflowId: "",
  tasks: [],
};

export default TaskList;
