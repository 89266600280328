import { getAPIReq } from "data/libs/authLib";

import { API } from "aws-amplify";

export const getAdvisors = async ({ hubId }) => {
  const req = await getAPIReq(
    {
      query: `query getAdvisors {
      advisors {
        edges {
          node {
            id
            uuid
            email
            firstName
            lastName
          }
        }
      }
    }
    `,
    },
    { hub: hubId }
  );

  const {
    data: { advisors },
  } = await API.post("HublyAPI", `/graphql`, req);
  return advisors.edges.map(({ node }) => {
    return {
      ...node,
      id: node.uuid,
    };
  });
};

export default getAdvisors;
