import ReactGA from "react-ga";
import config from "../../../config";

const currentUser = (state) => {
  return state?.hubly?.data?.advisor;
};
const userEmail = (user) => {
  return user?.email || "N/A";
};

const allowedEvents = {
  SET_ADVISOR: (action, store) => {
    return {
      category: "App",
      action: "Set Advisor",
      label: userEmail(action?.advisor),
      nonInteraction: true,
    };
  },

  SET_LOGIN_SIGNING_IN: (action, store) => {
    return {
      category: "Authentication",
      action: "User Login",
      label: userEmail(currentUser(store.getState())),
    };
  },

  USER_LOGOUT: (action, store) => {
    return {
      category: "Authentication",
      action: "User Logout",
      label: userEmail(currentUser(store.getState())),
    };
  },
};

function create(
  { gaTrackingCode, ...options } = { gaTrackingCode: config.gaTrackingCode }
) {
  ReactGA.initialize(gaTrackingCode, options);

  return {
    predicate: (action) => {
      return allowedEvents[action.type];
    },
    metric: {
      emit: (action, store) => {
        ReactGA.set({
          userId: userEmail(action?.advisor || currentUser(store.getState())),
        });
        ReactGA.event(allowedEvents[action.type](action, store));
      },
    },
  };
}

export default create;
