import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { Input, Button } from "semantic-ui-react";
import { useForm } from "hooks";
import {
  HubContext,
  ToasterContext,
} from "scenes/Hubly/components/Workspace/Provider";
import { ButtonAddTime, TaskTimeAddLayout } from "./components";
import { getDuration } from "../../helper";

export function TaskTimeAdd({
  taskId,
  hideAddButton,
  header,
  value: initialDuration,
  onChange,
  onAddTime,
}) {
  const { addTimeEstimate } = useContext(HubContext);
  const { fireError } = useContext(ToasterContext);
  const { disableButton, register, errorList } = useForm({
    initialValue: { duration: initialDuration || 0 },
    fieldValidator: ({ name, value, errortext }) => {
      if (name === "duration" && parseInt(value) <= 0) {
        return errortext;
      }
      return "";
    },
    formValidator: ({ duration }) => {
      return parseInt(duration) > 0;
    },
  });

  const {
    value: duration,
    onChange: onChangeLocal,
    error,
  } = register({
    name: "duration",
    required: true,
  });

  const { hours, minutes } = getDuration(duration);
  const [adding, setAdding] = useState(hideAddButton);
  const [loading, setLoading] = useState(false);

  const updateDuration = (params) => {
    onChangeLocal(null, params);
    onChange(null, params);
  };

  const addTime = (min) => {
    updateDuration({
      name: "duration",
      value: duration + parseInt(min),
      errortext: "Time must be larger than 0",
    });
  };

  const createTimeEstimate = () => {
    const hourMinuteParam = { hours: hours, minutes: minutes };
    if (!taskId) {
      onAddTime(hourMinuteParam);
      return;
    }
    setLoading(true);
    addTimeEstimate(taskId, hourMinuteParam)
      .then(({ id }) => {
        onAddTime({ id: id, ...hourMinuteParam });
      })
      .catch((e) => {
        fireError("Failed to create time estimate", e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (!adding) {
    return (
      <Button
        basic
        onClick={() => {
          setAdding(true);
        }}
      >
        Add Time
      </Button>
    );
  }
  return (
    <TaskTimeAddLayout
      loading={loading}
      disabled={disableButton}
      header={header}
      error={error ? errorList : null}
      hourInput={
        <Input
          display="inline"
          error={error}
          fluid
          maxLength="2"
          min="0"
          name="hours"
          onChange={(_, { value: h }) => {
            updateDuration({
              name: "duration",
              value: (parseInt(h) ? parseInt(h) : 0) * 60 + minutes,
              errortext: "Time must be larger than 0",
            });
          }}
          type="number"
          value={hours}
        />
      }
      minuteInput={
        <Input
          display="inline"
          error={error}
          fluid
          maxLength="2"
          min="0"
          name="minutes"
          onChange={(_, { value: m }) => {
            updateDuration({
              name: "duration",
              value: hours * 60 + (parseInt(m) ? parseInt(m) : 0),
              errortext: "Time must be larger than 0",
            });
          }}
          type="number"
          value={minutes}
        />
      }
      showActionButtons={!hideAddButton}
      onClose={() => {
        setAdding(false);
        updateDuration({
          name: "duration",
          value: 0,
        });
      }}
      onSave={createTimeEstimate}
    >
      <ButtonAddTime valueInMin={15} text="15m" onClick={addTime} />
      <ButtonAddTime valueInMin={30} text="30m" onClick={addTime} />
      <ButtonAddTime valueInMin={60} text="1h" onClick={addTime} />
      <ButtonAddTime valueInMin={180} text="3h" onClick={addTime} />
    </TaskTimeAddLayout>
  );
}

TaskTimeAdd.propTypes = {
  header: PropTypes.string,
  value: PropTypes.number,
  taskId: PropTypes.string,
  hideAddButton: PropTypes.bool,
  onChange: PropTypes.func,
  onAddTime: PropTypes.func,
};

TaskTimeAdd.defaultProps = {
  taskId: null,
  header: "",
  value: 0,
  hideAddButton: true,
  onAddTime: () => {},
  onChange: () => {},
};

export default TaskTimeAdd;
