import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { Modal, Form, Icon, Button, Message } from "semantic-ui-react";
import { ToasterContext } from "scenes/Hubly/components/Workspace/Provider";
import getSearchName, { isEmailValid, isPhoneValid } from "./helper";
import CreateButtonWithIntegration from "./components/CreateButtonWithIntegration";
import { useClientAction } from "./hooks";

export function CreateClientModal({
  clientName,
  integrationButtonText,
  onClientCreated,
  onClose,
}) {
  const { fireError } = useContext(ToasterContext);
  const { searchFirstName, searchLastName } = getSearchName(clientName);
  const [formErrors, setFormErrors] = useState({
    email: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
  });
  const [formData, setFormData] = useState({
    firstName: searchFirstName,
    lastName: searchLastName,
    email: "",
    middleName: "",
    nickname: "",
    phoneNumber: "",
  });
  const { isRunningAction: loading, createClient } = useClientAction();

  const onCreateClient = ({ editInCrm = false } = {}) => {
    createClient(formData)
      .then((resp) => {
        if (resp) onClientCreated(resp, editInCrm);
      })
      .catch((error) => {
        fireError("Failed to create client", error);
      });
  };

  const disableCreateButton = () => {
    return loading || !formData.firstName || !formData.lastName;
  };

  const getErrorList = () => {
    return Object.values(formErrors).filter((error) => {
      return error !== "";
    });
  };

  const setFormDataField = (_, { name, required, value, errortext = "" }) => {
    const error =
      (required && value === "") ||
      (name === "email" && !isEmailValid(value)) ||
      (name === "phoneNumber" && !isPhoneValid(value))
        ? errortext
        : "";
    setFormErrors((prevState) => {
      return { ...prevState, [name]: error };
    });
    setFormData((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  return (
    <Modal id="create_new_client_modal" open size="mini" centered={false}>
      <Modal.Header>
        <Icon name="user plus" style={{ marginRight: "1em" }} />
        Create a New Client
      </Modal.Header>
      <Modal.Content>
        <Form error>
          <Message
            error
            negative
            header="Error"
            hidden={getErrorList().length === 0}
            list={getErrorList()}
          />
          <Form.Input
            name="firstName"
            label="First Name"
            errortext="First name is required."
            required
            error={formErrors.firstName !== ""}
            value={formData.firstName}
            onChange={setFormDataField}
          />
          <Form.Input
            name="lastName"
            label="Last Name"
            errortext="Last name is required."
            error={formErrors.lastName !== ""}
            required
            value={formData.lastName}
            onChange={setFormDataField}
          />
          <Form.Input
            name="middleName"
            label="Middle Name"
            value={formData.middleName}
            onChange={setFormDataField}
          />
          <Form.Input
            name="nickname"
            label="Nickname"
            value={formData.nickname}
            onChange={setFormDataField}
          />
          <Form.Input
            name="email"
            label="Email address"
            errortext="Email is invalid."
            error={formErrors.email !== ""}
            value={formData.email}
            onChange={setFormDataField}
          />
          <Form.Input
            name="phoneNumber"
            label="Phone Number"
            errortext="Phone number is invalid."
            value={formData.phoneNumber}
            error={formErrors.phoneNumber !== ""}
            onChange={setFormDataField}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          basic
          icon="close"
          onClick={() => {
            onClose(null);
          }}
        />
        {integrationButtonText ? (
          <CreateButtonWithIntegration
            loading={loading}
            disabled={disableCreateButton()}
            onClick={onCreateClient}
          />
        ) : (
          <Button
            content="Create"
            disabled={disableCreateButton()}
            floated="right"
            loading={loading}
            onClick={() => {
              onCreateClient({ editInCrm: false });
            }}
            primary
          />
        )}
      </Modal.Actions>
    </Modal>
  );
}

CreateClientModal.defaultProps = {
  clientName: "",
  integrationButtonText: "",
};

CreateClientModal.propTypes = {
  clientName: PropTypes.string,
  integrationButtonText: PropTypes.string,
  onClientCreated: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CreateClientModal;
