import React, { createRef } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import history from "data/history";
import { Menu, Ref, Sticky } from "semantic-ui-react";
import "./settings.css";
import NotificationsFeatureFlag from "./NotificationsFeatureFlag";
import AccountSettings from "./components/AccountSettings";
import IntegrationSettings from "./components/IntegrationSettings";
import NotificationSettings from "./components/NotificationSettings";

class Settings extends React.Component {
  static propTypes = {
    advisor: PropTypes.shape({
      id: PropTypes.string.isRequired,
      integrations: PropTypes.array.isRequired,
      policies: PropTypes.array.isRequired,
    }).isRequired,
    match: PropTypes.shape({
      url: PropTypes.string.isRequired,
      params: PropTypes.object.isRequired,
    }).isRequired,
    inProgress: PropTypes.bool.isRequired,
  };

  contextRef = createRef();

  goTo = (location) => {
    const { match } = this.props;
    const path = `/settings/${location}`;
    if (path === match.url) return;
    // console.log("Settings/index.jsx:30", path);
    history.push(path);
  };

  render() {
    const { advisor, inProgress } = this.props;

    if (advisor.policies.length === 0 && !inProgress) {
      // console.log("Settings/index.jsx:38", "/");
      history.push("/");
    }

    const { match } = this.props;
    let page = "account";
    if (match.params.settingsPage) {
      page = match.params.settingsPage;
    }

    return (
      <div className="settings-container">
        <Ref innerRef={this.contextRef}>
          <div style={{ display: "flex", marginTop: "2em" }}>
            <div style={{ width: "300px" }}>
              <Sticky context={this.contextRef} offset={75}>
                <Menu
                  floated="right"
                  secondary
                  size="large"
                  vertical
                  data-test="settings-menu"
                >
                  <Menu.Item
                    name="account"
                    active={page === "account"}
                    onClick={() => {
                      this.goTo("account");
                    }}
                    style={{ textAlign: "right" }}
                  >
                    <span
                      className={
                        page === "account"
                          ? "active-header-font"
                          : "inactive-header-font"
                      }
                    >
                      General Settings
                    </span>
                  </Menu.Item>
                  <Menu.Item
                    name="integrations"
                    active={page === "integrations"}
                    onClick={() => {
                      this.goTo("integrations");
                    }}
                    style={{ textAlign: "right" }}
                  >
                    <span
                      className={
                        page === "integrations"
                          ? "active-header-font"
                          : "inactive-header-font"
                      }
                    >
                      Integrations
                    </span>
                  </Menu.Item>
                  <NotificationsFeatureFlag>
                    <Menu.Item
                      name="notifications"
                      active={page === "notifications"}
                      onClick={() => {
                        this.goTo("notifications");
                      }}
                      style={{ textAlign: "right" }}
                    >
                      <span
                        className={
                          page === "notifications"
                            ? "active-header-font"
                            : "inactive-header-font"
                        }
                      >
                        Notifications
                      </span>
                    </Menu.Item>
                  </NotificationsFeatureFlag>
                </Menu>
              </Sticky>
            </div>
            <div style={{ marginLeft: "3em", maxWidth: "600px", width: "60%" }}>
              {page === "account" && <AccountSettings />}
              {page === "integrations" && <IntegrationSettings />}
              <NotificationsFeatureFlag>
                {page === "notifications" && <NotificationSettings />}
              </NotificationsFeatureFlag>
            </div>
          </div>
        </Ref>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    advisor: state.hubly.data.advisor,
    inProgress: state.hubly.data.hub.selected.inProgress,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
