import React from "react";
import PropTypes from "prop-types";

import config from "config";

import { GetAllClientNames, GetClientCardOpened } from "data/libs/clients";
import { CreateSync, GetSync } from "data/libs/sync";

import {
  createView,
  editView,
  deleteView,
  reOrderView,
  editFilter,
  updateDefaultView,
} from "scenes/Hubly/components/Workspace/components/Views/helper";

import {
  CreateWorkflow,
  DeleteWorkflow,
  DuplicateWorkflow,
  EditWorkflow,
} from "data/libs/workflows";
import history from "data/history";
import { FetchMeetingDateTypes } from "data/libs/meetingDateTypes";
import { EditReminder } from "data/libs/reminders";

import {
  getClientWorkflows,
  getAdvisors,
  getView,
  getViews,
  getWorkflowsWithNoProcess,
  getProcessesAndWorkflowsWithNoProcess,
  getRecentClients,
  getAllClientames,
  getTags,
  getStreams,
  getHousehold,
  getStream,
  getTag,
  getClientsInStream,
  getClientsInTag,
  getClientWorkflowCount,
  getStreamClientHouseholdCount,
  getTagCountHouseholdClient,
  getWorkflowTasks,
  getWorkflowEditorData,
  getWorkflowsWithExclude,
  getWorkflowNextWorkflows,
  getWorkflowRules,
  getReminders,
  getRemindersCount,
  getAdvisorDefaultView,
} from "./api";

import addProcess, {
  deleteProcess,
  editProcess,
  reOrderProcess,
  reOrderWorkflow,
} from "./components/WorkflowMenu/helper";

import {
  addClientToWorkflow,
  moveClientWorkflow,
  bulkAddClientsToWorkflow,
  createClient,
  updateClientCardOpened,
} from "./components/AddClientDropdown/helper";
import {
  addStream,
  addTag,
  editTag,
  deleteTag,
  editStream,
  deleteStream,
  addClientToStream,
  removeClientFromStream,
  removeClientFromTag,
  addClientToTag,
} from "./components/ManyToManyManager/helper";
import {
  removeClientWorkflow,
  moveClientTileToTop,
  markNextTaskAsCompleted,
  archiveClientWorkflow,
  submitSortWorkflows,
} from "./components/WorkflowContainer/helper";
import {
  addEmailTemplate,
  addLink,
  addReminderToWorklfow,
  addTask,
  addTaskCondition,
  addTimeEstimate,
  deleteAllTimeEstimate,
  deleteEmailTemplate,
  deleteLink,
  deleteByReminderType,
  deleteTask,
  deleteTaskCondition,
  editEmailTemplate,
  editLink,
  editReminder as editTaskReminder,
  editTask,
  editTaskCondition,
  editTimeEstimate,
  reOrderTask,
  convertTaskToFutureReminder,
  convertTaskToSequentialReminder,
} from "./components/WorkflowContainer/components/WorkflowEditor/components/TasksEditor/components/Task/helper";
import addNextWorkflow, {
  deleteNextWorkflow,
} from "./components/WorkflowContainer/components/WorkflowEditor/components/NextWorkflowsEditor/helper";
import {
  bulkAddAttachment,
  deleteAttachment,
  fetchAttachments,
} from "./components/WorkflowContainer/components/WorkflowEditor/components/AttachmentsEditor/helper";
import {
  addRule,
  deleteRule,
  editRule,
} from "./components/WorkflowContainer/components/WorkflowEditor/components/RulesEditor/helper";
import getDateTypes from "./api/date_type";

export const HubContext = React.createContext();

export function HubContextProvider({
  hubId,
  hubShortId,
  hubClientPoolId,
  sortByLastName,
  hubAdvisor,
  advisors,
  integration,
  children,
}) {
  return (
    <HubContext.Provider
      value={{
        integration: integration,
        advisorId: hubAdvisor.id,
        advisors: advisors,
        isMultiAdvisorsHub: () => {
          return advisors.length > 1;
        },
        isCRMType: (type) => {
          return integration?.type === type;
        },
        fetchDateTypes: () => {
          return getDateTypes({ hubId: hubShortId });
        },
        fetchMeetingDateTypes: () => {
          return FetchMeetingDateTypes(hubId);
        },
        createView: (f, req) => {
          return createView(f, { hubId: hubId, ...req });
        },
        editView: editView,
        deleteView: deleteView,
        fetchViews: () => {
          return getViews(hubShortId);
        },
        fetchView: async (viewId) => {
          return getView(hubShortId, viewId);
        },
        getDefaultViewId: () => {
          return hubAdvisor.defaultView;
        },
        reOrderView: reOrderView,
        editFilter: editFilter,
        updateDefaultView: (viewId) => {
          return updateDefaultView(hubAdvisor.id, viewId);
        },
        fetchProcesses: () => {
          return getProcessesAndWorkflowsWithNoProcess(hubShortId);
        },
        fetchReminders: (params) => {
          return getReminders({ hubId: hubShortId, ...params });
        },
        fetchRemindersCount: (params) => {
          return getRemindersCount({ hubId: hubShortId, ...params });
        },
        editAReminder: EditReminder,
        fetchWorkflowsWithNoProcess: (params) => {
          return getWorkflowsWithNoProcess({
            ...params,
            hubId: hubShortId,
          });
        },
        fetchWorkflowEditorData: (workflowId) => {
          return getWorkflowEditorData(hubShortId, workflowId);
        },
        fetchWorkflows: (params) => {
          return getWorkflowsWithExclude({
            ...params,
            hubId: hubShortId,
          });
        },
        fetchTasks: (workflowId) => {
          return getWorkflowTasks(hubShortId, workflowId);
        },
        fetchNextWorkflows: (workflowId) => {
          return getWorkflowNextWorkflows(hubShortId, workflowId);
        },
        fetchRules: (workflowId) => {
          return getWorkflowRules(hubShortId, workflowId);
        },
        reOrderProcess: reOrderProcess,
        reOrderWorkflow: reOrderWorkflow,
        addProcess: (process) => {
          return addProcess({ ...process, hubId: hubId });
        },
        editProcess: editProcess,
        deleteProcess: deleteProcess,
        fetchAllClientNames: () => {
          return GetAllClientNames({ id: hubId });
        },
        searchClients: (params) => {
          return getAllClientames({ ...params, hubId: hubShortId });
        },
        fetchHousehold: (clientId) => {
          return getHousehold({ hubId: hubShortId, clientId: clientId });
        },
        fetchRecentClients: () => {
          return getRecentClients({ hubId: hubShortId });
        },
        fetchClientsWithStreamsInStream: (params) => {
          return getClientsInStream({ hubId: hubShortId, ...params });
        },
        fetchClientsWithTagsInTag: (params) => {
          return getClientsInTag({ hubId: hubShortId, ...params });
        },
        addClientToWorkflow: addClientToWorkflow,
        moveClientWorkflow: moveClientWorkflow,
        bulkAddClientsToWorkflow: bulkAddClientsToWorkflow,
        createClient: (data) => {
          return createClient({ ...data, clientPoolId: hubClientPoolId });
        },
        updateClientCardOpened: updateClientCardOpened,
        fetchClientCardOpened: GetClientCardOpened,
        fetchClientWorkflowCount: (workflowId, clientId) => {
          return getClientWorkflowCount(hubShortId, workflowId, clientId);
        },
        fetchClientWorkflows: async ({ workflowId, filters, ...page }) => {
          const clientWorkflows = await getClientWorkflows({
            hubId: hubShortId,
            workflowId: workflowId,
            filters: filters,
            ...page,
          });

          const tasksStatsMapper = ({
            filteredTasks = [],
            numFilteredTasks = 0,
            numFilteredTasksCompleted = 0,
            clientWorkflowStatus: { numReminders = 0, oldestReminderTime },
          }) => {
            return {
              filteredTasks,
              numFilteredTasks,
              numFilteredTasksCompleted,
              numReminders,
              oldestReminderTime,
            };
          };

          // TODO HUB-2255 BS logic on top of ClientWorkflows
          const data = clientWorkflows.data.map((cw) => {
            return { ...cw, tasksStats: tasksStatsMapper(cw) };
          });

          return { ...clientWorkflows, data };
        },
        removeClientWorkflow: removeClientWorkflow,
        moveClientTileToTop: moveClientTileToTop,
        markNextTaskAsCompleted: markNextTaskAsCompleted,
        archiveClientWorkflow: archiveClientWorkflow,
        fetchAssignees: () => {
          // TODO HUB-2255 caching mechanism
          return getAdvisors({ hubId: hubShortId });
        },
        fetchStreams: (params = {}) => {
          return getStreams({ ...params, hubId: hubShortId });
        },
        fetchTags: (params = {}) => {
          return getTags({ ...params, hubId: hubShortId });
        },
        fetchStream: (streamId) => {
          return getStream({ hubId: hubShortId, streamId: streamId });
        },
        fetchTag: (tagId) => {
          return getTag({ hubId: hubShortId, tagId: tagId });
        },
        fetchStreamClientHouseholdCount: (streamId, excludeWorkflowId) => {
          return getStreamClientHouseholdCount({
            hubId: hubShortId,
            streamId: streamId,
            excludeWorkflowId: excludeWorkflowId,
          });
        },
        fetchTagclientHouseholdCount: (tagId, excludeWorkflowId) => {
          return getTagCountHouseholdClient({
            hubId: hubShortId,
            tagId: tagId,
            excludeWorkflowId: excludeWorkflowId,
          });
        },
        addTag: (name) => {
          return addTag(hubId, name);
        },
        editTag: editTag,
        deleteTag: deleteTag,
        addClientToTag: (clientId, tagName) => {
          return addClientToTag(hubId, clientId, tagName);
        },
        removeClientFromTag: removeClientFromTag,
        addStream: (name, existingStreamColors) => {
          return addStream(hubId, name, existingStreamColors);
        },
        editStream: editStream,
        deleteStream: deleteStream,
        addClientToStream: addClientToStream,
        removeClientFromStream: removeClientFromStream,
        sync: () => {
          const request = {
            type: "contacts",
            advisor_id: hubAdvisor.id,
            hub_id: hubId,
          };
          return CreateSync(request);
        },
        checkSync: GetSync,
        createWorkflow: (request) => {
          return CreateWorkflow({ ...request, hubId: hubId });
        },
        editWorkflow: EditWorkflow,
        deleteWorkflow: DeleteWorkflow,
        duplicateWorkflow: (wfId) => {
          return DuplicateWorkflow(wfId, hubId);
        },
        viewClientCard: (clientId, clientWorkflowId = null) => {
          history.push(
            `/hub/${hubShortId}/clients/${clientId}${
              clientWorkflowId ? `/workflows/${clientWorkflowId}` : ""
            }`
          );
        },
        viewClientInCrm: (clientExternalId) => {
          if (integration.type === "redtail") {
            window.open(
              `${config.redtailUrl}/contacts/${clientExternalId}`,
              "_blank"
            );
          } else if (integration.type === "wealthbox") {
            window.open(
              `https://app.crmworkspace.com/contacts/${clientExternalId}/edit`,
              "_blank"
            );
          }
        },
        submitSortWorkflows: (workflowId, type) => {
          return submitSortWorkflows(workflowId, type, sortByLastName);
        },
        fetchDefaultView: () => {
          return getAdvisorDefaultView(hubShortId, hubAdvisor.id);
        },
        addEmailTemplate: addEmailTemplate,
        editEmailTemplate: editEmailTemplate,
        deleteEmailTemplate: deleteEmailTemplate,
        addTask: addTask,
        editTask: editTask,
        deleteTask: deleteTask,
        reOrderTask: reOrderTask,
        addLink: addLink,
        editLink: editLink,
        deleteLink: deleteLink,
        addTaskCondition: addTaskCondition,
        editTaskCondition: editTaskCondition,
        deleteTaskCondition: deleteTaskCondition,
        addTimeEstimate: addTimeEstimate,
        editTimeEstimate: editTimeEstimate,
        deleteAllTimeEstimate: deleteAllTimeEstimate,
        addReminderToWorklfow: addReminderToWorklfow,
        editReminder: editTaskReminder,
        convertTaskToFutureReminder: convertTaskToFutureReminder,
        convertTaskToSequentialReminder: convertTaskToSequentialReminder,
        deleteByReminderType: deleteByReminderType,
        addNextWorkflow: addNextWorkflow,
        deleteNextWorkflow: deleteNextWorkflow,
        fetchAttachments: fetchAttachments,
        bulkAddAttachment: bulkAddAttachment,
        deleteAttachment: deleteAttachment,
        addRule: addRule,
        editRule: editRule,
        deleteRule: deleteRule,
      }}
    >
      {children}
    </HubContext.Provider>
  );
}

HubContextProvider.propTypes = {
  hubId: PropTypes.string.isRequired,
  hubShortId: PropTypes.string.isRequired,
  hubClientPoolId: PropTypes.string.isRequired,
  sortByLastName: PropTypes.bool.isRequired,
  hubAdvisor: PropTypes.object.isRequired,
  advisors: PropTypes.array.isRequired,
  integration: PropTypes.object,
  children: PropTypes.node,
};

HubContextProvider.defaultProps = {
  children: null,
  integration: null,
};

export const ToasterContext = React.createContext({
  fireError: () => {},
  fireSuccess: () => {},
  fireWarning: () => {},
});
export function ToasterContextProvider({ fireToaster, children }) {
  return (
    <ToasterContext.Provider
      value={{
        fireError: (message, error) => {
          console.error(error);
          fireToaster({ type: "warning", text: message });
        },
        fireSuccess: (message) => {
          fireToaster({ type: "success", text: message });
        },
        fireWarning: (message) => {
          fireToaster({ type: "warning", text: message });
        },
      }}
    >
      {children}
    </ToasterContext.Provider>
  );
}

ToasterContextProvider.propTypes = {
  children: PropTypes.node,
  fireToaster: PropTypes.func.isRequired,
};

ToasterContextProvider.defaultProps = {
  children: null,
};

export default HubContextProvider;
