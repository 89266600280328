import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { SetAlert } from "components/Alerts/actions";
import { Header, Icon, Popup } from "semantic-ui-react";
import EventField from "../EventField";

class EventPopup extends React.Component {
  static propTypes = {
    client: PropTypes.shape({
      id: PropTypes.string.isRequired,
      events: PropTypes.array.isRequired,
    }).isRequired,
    event: PropTypes.shape({
      id: PropTypes.string.isRequired,
      clientId: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      nestedComponentOpen: false,
      popupOpen: false,
    };
  }

  setNestedComponentOpen = (open) => {
    this.setState({ nestedComponentOpen: open });
  };

  render() {
    const { nestedComponentOpen, popupOpen } = this.state;
    const { client } = this.props;
    const { events = [] } = client;
    const sortedEvents = events.sort((a, b) => {
      return (a.date < b.date) - (a.date > b.date);
    });
    return (
      <Popup
        on="click"
        onClose={() => {
          // Don't close if the nested popup/modal is still open
          // Bug in semantic with nested onClose events
          if (!nestedComponentOpen) {
            this.setState({ popupOpen: false });
          }
        }}
        open={popupOpen}
        popperModifiers={{
          preventOverflow: { boundariesElement: "window", enabled: false },
        }}
        position="bottom center"
        style={{ paddingRight: 0 }}
        data-test="event-popup"
        trigger={
          <div
            className="grey_on_hover clickable rounded"
            onClick={() => {
              this.setState({ popupOpen: true });
            }}
            style={{
              color: "grey",
              maxWidth: "fit-content",
              padding: "0 0.5em",
            }}
          >
            {`See more (${events.length})`}
          </div>
        }
      >
        <div
          style={{
            maxHeight: "500px",
            minWidth: "300px",
            overflowY: "auto",
            alignContent: "stretch",
            padding: "1px 1em",
          }}
        >
          <div style={{ display: "flex" }}>
            <Header as="h4" style={{ marginBottom: "1em" }}>
              All Meetings
            </Header>
            <Popup
              content={
                <span>
                  Meeting dates&nbsp;
                  <strong>after</strong>
                  &nbsp;the present day will appear in the&nbsp;
                  <strong>Next</strong>
                  &nbsp;field on the client card while dates&nbsp;
                  <strong>before</strong>
                  &nbsp;the present day will appear in the&nbsp;
                  <strong>Last</strong>
                  &nbsp;field on the client card.
                </span>
              }
              popperModifiers={{
                preventOverflow: {
                  boundariesElement: "window",
                  enabled: false,
                },
              }}
              position="right center"
              trigger={
                <Icon
                  name="question circle outline"
                  style={{
                    fontSize: "12pt",
                    margin: "2px 0 0px .25em",
                    transform: "translateY(-4px)",
                    color: "darkgrey",
                  }}
                />
              }
              wide
            />
          </div>
          <div>
            {sortedEvents.length <= 0 && (
              <span style={{ color: "grey" }}>No meetings have been added</span>
            )}
            {sortedEvents.map((event) => {
              return (
                <EventField
                  client={client}
                  event={event}
                  fieldType="EDITABLE_INLINE"
                  setNestedComponentOpen={this.setNestedComponentOpen}
                />
              );
            })}
            <EventField client={client} fieldType="ADDABLE_BUTTON" />
          </div>
        </div>
      </Popup>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAlert: (alert) => {
      dispatch(SetAlert(alert));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EventPopup);
