import React from "react";
import PropTypes from "prop-types";

import "./Sidebar.css";

import { Sidebar as SidebarComponent } from "semantic-ui-react";

import { Tab, Chevron } from "./components";

export function Sidebar({ title, tab, children, ...callbacks }) {
  const [visible, setVisible] = React.useState(false);
  const [hidden, setHidden] = React.useState(true);

  return (
    <div data-test="sidebar">
      <Tab
        title={title}
        icon={<Chevron pointRight={visible} />}
        visible={hidden}
        onClick={() => {
          setVisible(true);
        }}
      >
        {tab}
      </Tab>
      <SidebarComponent
        animation="overlay"
        className="hub-feed-sidebar"
        direction="right"
        width="wide"
        style={{ backgroundColor: "white", top: "47px" }}
        visible={visible}
        onShow={() => {
          callbacks.onOpened();
        }}
        onVisible={() => {
          callbacks.onOpening();
          setHidden(false);
        }}
        onHidden={() => {
          setHidden(true);
          callbacks.onClosed();
        }}
      >
        {children}

        <Tab
          open
          title={title}
          icon={<Chevron pointRight={visible} />}
          visible={!hidden}
          onClick={() => {
            setVisible(false);
          }}
        >
          {tab}
        </Tab>
      </SidebarComponent>
    </div>
  );
}

Sidebar.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  tab: PropTypes.node,
  onOpening: PropTypes.func,
  onOpened: PropTypes.func,
  onClosed: PropTypes.func,
};

Sidebar.defaultProps = {
  title: "",
  children: null,
  tab: null,
  onOpening: () => {},
  onOpened: () => {},
  onClosed: () => {},
};

export default Sidebar;
