import React, { useState } from "react";
import PropTypes from "prop-types";

import { Header, Input, Button, Icon } from "semantic-ui-react";
import validator from "validator";

import { useForm } from "hooks";

export function LinkAdd({ loading, link, onSave, onClose }) {
  const { disableButton, register, data, clearForm } = useForm({
    initialValue: {
      url: link?.url ? link.url : "",
      title: link?.title ? link.title : "",
    },
    fieldValidator: ({ name, value, errortext }) => {
      if (name === "url" && (!value || !validator.isURL(value))) {
        return errortext;
      }
      return "";
    },
    formValidator: ({ url: linkUrl }) => {
      return linkUrl && validator.isURL(linkUrl);
    },
  });
  const [addingButton, showAddingButton] = useState(link === null);

  const saveData = () => {
    onSave({ ...data, id: link?.id ? link.id : null });
    if (!link) {
      showAddingButton(true);
      clearForm();
    }
  };

  if (addingButton) {
    return (
      <Button
        basic
        loading={loading}
        content="Add Link"
        onClick={() => {
          showAddingButton(false);
        }}
      />
    );
  }
  return (
    <React.Fragment>
      {!link && (
        <Header as="h4" style={{ marginBottom: "0.5em" }}>
          Add New Link
        </Header>
      )}
      <Input
        {...register({ name: "title" })}
        fluid
        placeholder="Title (optional)"
        style={{ marginBottom: "0.5em" }}
      />
      <Input
        {...register({
          name: "url",
          required: true,
          errortext: "Please enter correct link",
        })}
        fluid
        placeholder="Link"
        autoFocus
        style={{ marginBottom: "0.5em" }}
        onKeyUp={(e) => {
          if (e.key === "Enter") {
            saveData();
          }
        }}
      />
      <Button
        positive
        onClick={saveData}
        content={!link ? "Add" : "Save"}
        disabled={disableButton || loading}
        loading={loading}
        style={{ marginRight: "0.5em" }}
      />
      <Icon
        link
        color="grey"
        size="large"
        name="delete"
        onClick={() => {
          if (!link) {
            showAddingButton(true);
          } else {
            onClose(null);
          }
        }}
      />
    </React.Fragment>
  );
}

LinkAdd.propTypes = {
  link: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    url: PropTypes.string,
  }),
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  loading: PropTypes.bool,
};

LinkAdd.defaultProps = {
  loading: false,
  link: null,
  onClose: () => {},
};

export default LinkAdd;
