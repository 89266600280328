import React from "react";
import { Dimmer } from "semantic-ui-react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Loading } from "components/Loading";
import { SetAlert } from "components/Alerts/actions";
import { SetConfirmationModal } from "components/ConfirmationModal/actions";
import colors from "components/StreamModal/colors";
import { SetHub } from "data/hub/actions";
import { CreateClientStream } from "data/libs/clients";
import { CreateStream } from "data/libs/streams";

import { publishClientWorkflowsUpdate } from "../../actions";

import Stream from "./components/Stream";
import StreamPopup from "./components/StreamPopup";
import HubContextConnector from "../HubContextConnector";

class CardStreams extends React.Component {
  static propTypes = {
    hub: PropTypes.shape({
      id: PropTypes.string.isRequired,
      streams: PropTypes.array.isRequired,
    }).isRequired,
    client: PropTypes.shape({
      id: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      householdId: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      streams: PropTypes.array.isRequired,
    }).isRequired,
    households: PropTypes.object.isRequired,
    setAlert: PropTypes.func.isRequired,
    setHub: PropTypes.func.isRequired,
    setConfirmationModal: PropTypes.func.isRequired,
    publish: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    // Set the color set to any color that hasn't been chosen
    const usedColors = props.hub.streams.map((stream) => {
      return stream.color;
    });
    const colorSet = colors.filter((color) => {
      return !usedColors.includes(color);
    });
    this.state = {
      loading: false,
      streamSearch: "",
      colorSet: colorSet.length === 0 ? [...colors] : colorSet,
    };
  }

  isHouseholdMember = (streamId = null) => {
    const { client, households, setConfirmationModal } = this.props;
    if (client.householdId) {
      const name = client.lastName
        ? `${client.firstName} ${client.lastName}`
        : client.firstName;
      const params = {
        title: `Apply Stream to Household`,
        message: `Would you like to apply this stream to all household members or only to ${name}?`,
        icon: "users",
        buttons: [
          {
            text: "Cancel",
            callBack: () => {
              this.setState({ streamSearch: "" });
            },
          },
          {
            text: `Apply to ${name}`,
            callBack: () => {
              if (streamId) {
                this.addClientStream(streamId);
              } else {
                this.addStream();
              }
            },
          },
          {
            color: "green",
            text: `Apply to All`,
            callBack: () => {
              if (streamId) {
                const householdMembers =
                  client.householdId in households
                    ? households[client.householdId].householdMembers
                    : [];
                householdMembers.forEach((member) => {
                  this.addClientStream(streamId, member.client.id);
                });
              } else {
                this.addStream(true);
              }
            },
          },
        ],
      };
      setConfirmationModal(params);
    } else if (streamId) {
      this.addClientStream(streamId);
    } else {
      this.addStream();
    }
  };

  addStream = (applyToAll = false) => {
    const { colorSet, streamSearch } = this.state;
    const { client, households, hub, setHub, setAlert } = this.props;
    if (!streamSearch) {
      setAlert({
        type: "warning",
        text: "Unable to save stream. Please verify stream name and try again.",
      });
      return;
    }

    // Check if stream already exists, if so, just add client to stream
    const streamExists = hub.streams.find((stream) => {
      return stream.name === streamSearch;
    });

    if (streamExists) {
      if (applyToAll) {
        const householdMembers =
          client.householdId in households
            ? households[client.householdId].householdMembers
            : [];
        householdMembers.forEach((member) => {
          this.addClientStream(streamExists.id, member.client.id);
        });
      } else {
        this.addClientStream(streamExists.id);
      }
      return;
    }

    const hubCopy = { ...hub };

    // Pick a random color that hasn't been chosen yet
    let currentColorSet = colorSet;
    const randomIndex = Math.floor(Math.random() * currentColorSet.length);
    const nextColor = currentColorSet[randomIndex];
    currentColorSet.splice(randomIndex, 1);
    // If we used up all the colors, allow any color to be chosen
    if (currentColorSet.length <= 0) {
      currentColorSet = [...colors];
    }

    const request = {
      hubId: hub.id,
      name: streamSearch,
      color: nextColor,
    };
    this.setState({ loading: true, colorSet: currentColorSet });
    CreateStream(request)
      .then((response) => {
        hubCopy.streams.push(response);
        setHub(hubCopy);
        if (applyToAll) {
          const householdMembers =
            client.householdId in households
              ? households[client.householdId].householdMembers
              : [];
          householdMembers.forEach((member) => {
            this.addClientStream(response.id, member.client.id);
          });
        } else {
          this.addClientStream(response.id);
        }
        this.setState({ streamSearch: "" });
      })
      .catch((error) => {
        setAlert({ type: "error", text: `Failed to add stream.` });
      });
  };

  addClientStream = (streamId, householdMember = null) => {
    const { client, setAlert } = this.props;
    const clientCopy = { ...client };
    const request = {
      id: streamId,
    };
    const selectedClient = householdMember || client.id;

    this.setState({ loading: true });
    CreateClientStream(selectedClient, request)
      .then((response) => {
        this.props.publish(client.id);
        const streamExists = clientCopy.streams.find((stream) => {
          return stream.id === response.id;
        });
        if (!streamExists) {
          clientCopy.streams.push(response);
        } else if (!householdMember) {
          setAlert({
            type: "warning",
            text: `Unable to add client to stream. Client already in stream.`,
          });
        }
        setAlert({ type: "success", text: "Successfully added stream" });
        this.setState({ loading: false, streamSearch: "" });
      })
      .catch((error) => {
        console.error(error);
        setAlert({ type: "error", text: `Failed to add client to stream.` });
      });
  };

  validateAddStream = () => {
    const { streamSearch } = this.state;
    const { setAlert } = this.props;
    if (!streamSearch.trim()) {
      setAlert({ type: "warning", text: "Stream cannot be empty" });
      return;
    }
    this.isHouseholdMember();
  };

  render() {
    const { loading } = this.state;
    const { client } = this.props;
    client.streams.sort((a, b) => {
      return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
    });
    return (
      <Dimmer.Dimmable dimmed={loading}>
        <Loading active={loading} inverted message="" size="mini" />
        <div
          data-test="client-card-streams"
          className="client-card-streams"
          style={{ marginTop: "1em" }}
        >
          <span style={{ fontSize: "1rem", marginBottom: "0.25em" }}>
            Streams
          </span>
          {client.streams.map((stream) => {
            return <Stream client={client} stream={stream} key={stream.id} />;
          })}
          <HubContextConnector>
            <StreamPopup
              excludeIds={client.streams.map(({ id }) => {
                return id;
              })}
              onSelect={(id) => {
                this.isHouseholdMember(id);
              }}
              onAdd={(name) => {
                this.setState({ streamSearch: name }, () => {
                  this.validateAddStream();
                });
              }}
            />
          </HubContextConnector>
        </div>
      </Dimmer.Dimmable>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    households: state.hubly.data.hub.households,
    hub: state.hubly.data.hub.selected.hub,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAlert: (alert) => {
      dispatch(SetAlert(alert));
    },
    setHub: (hub) => {
      dispatch(SetHub(hub));
    },
    setConfirmationModal: (id) => {
      dispatch(SetConfirmationModal(id));
    },
    publish: (clientId) => {
      dispatch(publishClientWorkflowsUpdate([clientId]));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CardStreams);
