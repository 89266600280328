import React from "react";
import {
  Button,
  Dropdown,
  Icon,
  Message,
  Placeholder,
} from "semantic-ui-react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { datadogRum } from "@datadog/browser-rum";
import { PrivacyModeContext } from "components/PrivacyMode/Context";
import { SetAlert } from "components/Alerts/actions";
import { SetConfirmationModal } from "components/ConfirmationModal/actions";
import { OpenManageHouseholdModal } from "components/ManageHouseholdModal/actions";
import PopupMenu from "components/PopupMenu";
import {
  ClearClientHousehold,
  SetActiveClient,
  SetClientHousehold,
} from "data/hub/clients/actions";
import { SetAttachments } from "data/hub/clientCard/actions";
import getMiliSecondDuration from "analytics/helper";
import {
  AddHousehold,
  AddHouseholdMember,
  RemoveHousehold,
  SetHousehold,
} from "data/hub/households/actions";
import {
  CreateClientHousehold,
  GetAttachments,
  GetClientCardOpened,
  getOrderedClientName,
} from "data/libs/clients";
import { DeleteHousehold } from "data/libs/households";
import { getHousehold } from "scenes/Hubly/components/Workspace/api";
import { CreateHouseholdMember } from "data/libs/householdMembers";
import history from "data/history";
import config from "config";

class HouseholdInfo extends React.Component {
  static contextType = PrivacyModeContext;

  static defaultProps = {};

  static propTypes = {
    addHousehold: PropTypes.func.isRequired,
    addHouseholdMember: PropTypes.func.isRequired,
    advisor: PropTypes.shape({
      integrations: PropTypes.array.isRequired,
    }).isRequired,
    clearClientHousehold: PropTypes.func.isRequired,
    client: PropTypes.object.isRequired,
    householdId: PropTypes.object.isRequired,
    households: PropTypes.object.isRequired,
    fetchingHouseholds: PropTypes.bool.isRequired,
    hub: PropTypes.object.isRequired,
    openManageHouseholdModal: PropTypes.func.isRequired,
    removeHousehold: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired,
    setAttachments: PropTypes.func.isRequired,
    setClientHousehold: PropTypes.func.isRequired,
    setConfirmationModal: PropTypes.func.isRequired,
    setSwitchingClients: PropTypes.func.isRequired,
    fetchHousehold: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      addRole: false,
      addToHousehold: false,
      expanded: false,
      initialFetching: false,
      loading: false,
      popupOpen: false,
      role: "",
      selectedHouseholdId: "",
      validHousehold: true,
      validRole: true,
      timeSpentCreateHousehold: 0,
    };
  }

  componentDidMount() {
    const { client, hub, households, fetchHousehold } = this.props;

    const loadHousehold = () => {
      if (!(client.householdId in households)) {
        this.setState({ initialFetching: true });
      }

      return fetchHousehold(hub, client).then(() => {
        this.setState({ initialFetching: false });
      });
    };

    if (client.householdId) loadHousehold();
  }

  openHouseholdInRedtail = (redtailId) => {
    window.open(`${config.redtailUrl}/contacts/${redtailId}/family`, "_blank");
  };

  addToHousehold = (addToHousehold) => {
    const { advisor, client, hub } = this.props;
    const isRedtailIntegrated =
      advisor.integrations.find((integration) => {
        return integration.hubId === hub.id && integration.type === "redtail";
      }) !== undefined;

    if (isRedtailIntegrated) {
      window.open(
        `${config.redtailUrl}/contacts/${client.externalId}`,
        "_blank"
      );
    } else {
      this.setState({
        addToHousehold: addToHousehold,
      });
    }
  };

  confirmDelete = () => {
    const { setConfirmationModal, householdId, households } = this.props;
    const params = {
      title: "Delete Household",
      message: `Are you sure you wish to delete "${households[householdId].name}"? This cannot be undone.`,
      icon: "delete",
      buttons: [
        { text: "Cancel" },
        {
          text: "Delete Household",
          callBack: this.deleteHousehold,
          color: "red",
        },
      ],
    };
    setConfirmationModal(params);
  };

  createHousehold = (e, { value }) => {
    const {
      addHousehold,
      hub,
      client,
      openManageHouseholdModal,
      setAlert,
      setClientHousehold,
    } = this.props;
    const request = {
      hubId: hub.id,
      name: value,
    };
    this.setState({
      loading: true,
      timeSpentCreateHousehold: new Date().getTime(),
    });
    CreateClientHousehold(client.id, request)
      .then((response) => {
        this.resetState();
        setClientHousehold(client.id, response.id);
        addHousehold(response);
        openManageHouseholdModal(response.id);
        setAlert({
          type: "success",
          text: `Successfully created household ${response.name}`,
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.warn(error);
        setAlert({ type: "error", text: "Failed to create household" });
      })
      .finally(() => {
        datadogRum.addAction("hubly_timespent_householdinfo_create", {
          duration: getMiliSecondDuration(this.state.timeSpentCreateHousehold),
        });
        this.setState({ timeSpentCreateHousehold: 0 });
      });
  };

  createHouseholdMember = () => {
    const { addHouseholdMember, client, setAlert, setClientHousehold } =
      this.props;
    const { role, selectedHouseholdId } = this.state;
    if (!this.isValidHouseholdMember()) {
      return;
    }
    this.setState({ loading: true });

    const request = {
      clientId: client.id,
      householdId: selectedHouseholdId,
      title: role,
      type: "Person",
    };
    CreateHouseholdMember(request)
      .then((response) => {
        setClientHousehold(client.id, response.householdId);
        addHouseholdMember(response);
        this.resetState();
        setAlert({
          type: "success",
          text: `Successfully created household member ${response.client.name}`,
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.warn(error);
        setAlert({ type: "error", text: "Failed to create household member" });
      });
  };

  deleteHousehold = () => {
    const {
      clearClientHousehold,
      client,
      householdId,
      households,
      removeHousehold,
      setAlert,
    } = this.props;
    DeleteHousehold(householdId)
      .then(() => {
        clearClientHousehold(client.id, householdId);
        removeHousehold(householdId);
        setAlert({
          type: "success",
          text: `Successfully deleted household ${households[householdId].name}`,
        });
      })
      .catch((error) => {
        console.warn("Failed to delete household", householdId);
        console.warn(error);
        setAlert({ type: "error", text: "Failed to delete household" });
      });
  };

  getHouseholdOptions = () => {
    const { households } = this.props;

    if (households.isEmpty) {
      return [];
    }

    return Object.values(households)
      .sort((a, b) => {
        return a.name > b.name ? 1 : -1;
      })
      .map((household) => {
        return {
          key: household.id,
          text: household.name,
          value: household.id,
          icon: "home",
        };
      });
  };

  getRoleOptions = () => {
    const { householdId, households } = this.props;
    const householdMembers =
      householdId in households ? households[householdId] : [];
    const roles = [
      { key: "Spouse", text: "Spouse", value: "Spouse" },
      { key: "Partner", text: "Partner", value: "Partner" },
      { key: "Child", text: "Child", value: "Child" },
      { key: "Grandchild", text: "Grandchild", value: "Grandchild" },
      { key: "Parent", text: "Parent", value: "Parent" },
      { key: "Grandparent", text: "Grandparent", value: "Grandparent" },
      { key: "Sibling", text: "Sibling", value: "Sibling" },
      {
        key: "Other Dependent",
        text: "Other Dependent",
        value: "Other Dependent",
      },
    ];
    const currentRoles = householdMembers.map((householdMember) => {
      return householdMember.title;
    });
    if (householdMembers.length > 0 && !currentRoles.includes("Head")) {
      roles.unshift({ key: "Head", text: "Head", value: "Head" });
    }
    return roles;
  };

  roleSelected = (e, { name, value }) => {
    this.setState({ validRole: true });
    this.setState({ [name]: value });
  };

  householdSelected = (e, { value }) => {
    this.setState({ validHousehold: true });
    if (e.type === "click" || (e.type === "keydown" && e.key === "Enter")) {
      this.setState({ addRole: true, selectedHouseholdId: value });
    }
  };

  isValidHouseholdMember = () => {
    const { role, selectedHouseholdId } = this.state;
    let valid = true;
    if (!selectedHouseholdId) {
      this.setState({ validHousehold: false });
      valid = false;
    }
    if (!role) {
      this.setState({ validRole: false });
      valid = false;
    }
    return valid;
  };

  renderAddRole = () => {
    const { loading, role, validRole } = this.state;
    const roleOptions = this.getRoleOptions();
    return (
      <React.Fragment>
        <div style={{ fontSize: "10pt", marginTop: "0.5em" }}>
          <Dropdown
            data-test="household-client-card-role-dropdown"
            disabled={loading}
            error={!validRole}
            fluid
            loading={loading}
            name="role"
            onChange={this.roleSelected}
            onSearchChange={this.roleSelected}
            options={roleOptions}
            placeholder="Household Role"
            search
            searchInput={{ autoComplete: "no-autofill" }}
            selection
            selectOnBlur={false}
            value={role}
          />
        </div>
      </React.Fragment>
    );
  };

  renderAddToHousehold = () => {
    const { addRole, addToHousehold, loading, validHousehold, validRole } =
      this.state;
    const { fetchingHouseholds } = this.props;
    const householdOptions = this.getHouseholdOptions();
    const errorList = [];
    if (!validHousehold) {
      errorList.push("Please select a household.");
    }
    if (!validRole && validHousehold) {
      errorList.push("Please select a role.");
    }
    return addToHousehold ? (
      <React.Fragment>
        <div style={{ fontSize: "10pt", marginTop: "0.5em" }}>
          <Dropdown
            additionLabel="+ Create "
            allowAdditions
            deburr
            defaultOpen={!fetchingHouseholds}
            disabled={loading || fetchingHouseholds}
            error={!validHousehold}
            fluid
            loading={loading || fetchingHouseholds}
            noResultsMessage="No Households Found"
            options={householdOptions}
            onAddItem={this.createHousehold}
            onChange={this.householdSelected}
            onSearchChange={this.householdSelected}
            placeholder={
              fetchingHouseholds
                ? "Loading Households..."
                : "Search for Household"
            }
            search={!fetchingHouseholds}
            searchInput={{ autoComplete: "no-autofill", autoFocus: true }}
            selection
            selectOnBlur={false}
            selectOnNavigation={false}
          />
        </div>
        {addRole && this.renderAddRole()}
        <div style={{ fontSize: "10pt", marginTop: "0.5em" }}>
          <Message
            error
            header="Required Fields Missing"
            hidden={validHousehold && validRole}
            list={errorList}
          />
        </div>
        <div style={{ fontSize: "8pt", marginTop: "1em" }}>
          <Button
            content="Add"
            disabled={loading || fetchingHouseholds}
            loading={loading || fetchingHouseholds}
            onClick={this.createHouseholdMember}
            positive
          />
          <Icon
            color="grey"
            link
            name="delete"
            onClick={this.resetState}
            size="large"
          />
        </div>
      </React.Fragment>
    ) : (
      <div
        className="clickable grey_on_hover rounded"
        onClick={() => {
          this.addToHousehold(true);
        }}
        style={{
          color: "grey",
          fontSize: "10pt",
          marginTop: "0.5em",
          maxWidth: "150px",
          paddingLeft: "0.5em",
        }}
      >
        <Icon name="home" />
        <span>Add to Household</span>
      </div>
    );
  };

  resolveWorkflowUrlPath = async (clientId) => {
    try {
      const { clientWorkflowId = null } = await GetClientCardOpened(clientId);
      return clientWorkflowId ? `/workflows/${clientWorkflowId}` : "";
    } catch {
      return "";
    }
  };

  renderMember = (member) => {
    const { piiMask } = this.context;
    const { hub, client, setAttachments, setSwitchingClients } = this.props;
    return (
      <div
        className="clickable grey_on_hover"
        key={member.id}
        style={{ display: "flex", padding: "0.25em", borderRadius: "5px" }}
        onClick={() => {
          if (member.client.id !== client.id) {
            setSwitchingClients(true);
            this.resolveWorkflowUrlPath(member.client.id)
              .then((path) => {
                history.push(
                  `/hub/${hub.hubId}/clients/${member.client.id}${path}`
                );
              })
              .finally(() => {
                setSwitchingClients(false);
              });

            GetAttachments(member.client.id).then((r) => {
              setAttachments(r.data.result.attachments);
            });
          }
          this.setState({ expanded: false });
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Icon
            size="large"
            name={member.title === "Head" ? "user" : "user outline"}
            color={member.title === "Head" ? "blue" : "grey"}
          />
        </div>
        <div data-test="member-section">
          <span className={piiMask("fs-block dd-privacy-mask")}>
            {getOrderedClientName(member.client, hub)}
          </span>
          <br />
          <span style={{ color: "grey", fontSize: "10pt" }}>
            {member.title}
          </span>
        </div>
      </div>
    );
  };

  resetState = () => {
    this.setState({
      addRole: false,
      addToHousehold: false,
      loading: false,
      popupOpen: false,
      role: "",
      selectedHouseholdId: "",
      validHousehold: true,
      validRole: true,
    });
  };

  toggleExpand = () => {
    this.setState((state) => {
      return { expanded: !state.expanded };
    });
  };

  render() {
    const { piiMask } = this.context;
    const { expanded, popupOpen, initialFetching } = this.state;
    const {
      advisor,
      client,
      hub,
      householdId,
      households,
      openManageHouseholdModal,
    } = this.props;

    if (householdId && initialFetching) {
      return (
        <div style={{ marginTop: "1em" }}>
          <Placeholder>
            <Placeholder.Header>
              <Placeholder.Line />
            </Placeholder.Header>
          </Placeholder>
        </div>
      );
    }

    const householdMembers =
      householdId in households ? households[householdId].householdMembers : [];
    const head = householdMembers.find((m) => {
      return m.title === "Head";
    });
    const isHead = head ? head.client.id === client.id : false;
    const members = householdMembers.sort((a, b) => {
      if (a.title === "Head") return -1;
      if (b.title === "Head") return 1;
      if (a.client.name < b.client.name) return -1;
      if (a.client.name > b.client.name) return 1;
      return 0;
    });

    const isRedtailIntegrated =
      advisor.integrations.find((integration) => {
        return integration.hubId === hub.id && integration.type === "redtail";
      }) !== undefined;

    let editHouseholdButtons = [];

    if (isRedtailIntegrated) {
      editHouseholdButtons = [
        {
          content: "Edit in Redtail",
          icon: "edit",
          onClick: () => {
            this.openHouseholdInRedtail(client.externalId);
            this.setState({ popupOpen: false });
          },
        },
      ];
    } else {
      editHouseholdButtons = [
        {
          content: "Edit Household",
          icon: "edit",
          onClick: () => {
            openManageHouseholdModal(householdId);
            this.setState({ popupOpen: false });
          },
        },
        {
          content: "Delete Household",
          icon: "delete",
          onClick: () => {
            this.confirmDelete();
            this.setState({ popupOpen: false });
          },
        },
      ];
    }

    return householdId in households ? (
      <React.Fragment>
        <div
          style={{
            color: "grey",
            fontSize: "10pt",
            lineHeight: "initial",
            margin: "1em 0 0.5em 0",
            maxWidth: "225px",
            display: "flex",
            alignItems: "center",
            userSelect: "none",
          }}
        >
          <div
            className={piiMask("grey_on_hover rounded clickable")}
            title="Click to view household members"
            onClick={this.toggleExpand}
            style={{ marginRight: "1em", padding: "0.5em" }}
          >
            <Icon name="home" />
            {households[householdId].name}
          </div>
          <div>
            <PopupMenu
              data-test="household-popup"
              buttons={editHouseholdButtons}
              onOpen={(e) => {
                this.setState({ popupOpen: true });
                e.preventDefault();
              }}
              onClose={() => {
                this.setState({ popupOpen: false });
              }}
              open={popupOpen}
              popperModifiers={{
                preventOverflow: {
                  boundariesElement: "window",
                  enabled: false,
                },
              }}
              trigger={
                <Icon
                  data-test="household-popup-trigger"
                  name="ellipsis horizontal"
                  link
                  color="grey"
                />
              }
            />
          </div>
        </div>
        <div
          data-test="household-members"
          style={{ fontSize: "12pt", lineHeight: "initial" }}
        >
          {expanded &&
            members.map((member) => {
              return this.renderMember(member);
            })}
          {!expanded && !isHead && head && this.renderMember(head)}
        </div>
      </React.Fragment>
    ) : (
      this.renderAddToHousehold()
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    advisor: state.hubly.data.advisor,
    client: state.hubly.data.hub.clients.activeClients[ownProps.clientId],
    clientWorkflows: state.hubly.data.hub.clientWorkflows,
    households: state.hubly.data.hub.households,
    fetchingHouseholds: state.hubly.data.hub.householdLoader.fetching,
    hub: state.hubly.data.hub.selected.hub,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addHousehold: (household) => {
      dispatch(AddHousehold(household));
    },
    addHouseholdMember: (householdMember) => {
      dispatch(AddHouseholdMember(householdMember));
    },
    clearClientHousehold: (clientId, householdId) => {
      dispatch(ClearClientHousehold(clientId, householdId));
    },
    openManageHouseholdModal: (householdId) => {
      dispatch(OpenManageHouseholdModal(householdId));
    },
    removeHousehold: (householdId) => {
      dispatch(RemoveHousehold(householdId));
    },
    setActiveClient: (clients) => {
      dispatch(SetActiveClient(clients));
    },
    setAlert: (alert) => {
      dispatch(SetAlert(alert));
    },
    setAttachments: (attachments) => {
      dispatch(SetAttachments(attachments));
    },
    setClientHousehold: (clientId, householdId) => {
      dispatch(SetClientHousehold(clientId, householdId));
    },
    setConfirmationModal: (params) => {
      dispatch(SetConfirmationModal(params));
    },
    fetchHousehold: (hub, client) => {
      return getHousehold({ hubId: hub.hubId, clientId: client.id }).then(
        (household) => {
          dispatch(SetHousehold(household));
        }
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HouseholdInfo);
