import { EditAdvisor } from "data/libs/advisor";
import { CreateFilter, EditFilter } from "data/libs/filters";
import {
  DeleteView,
  EditView,
  BulkUpdateViews,
  CreateView,
} from "data/libs/views";

export function createView(filters, request) {
  return CreateFilter(filters).then((resp) => {
    return CreateView({ filterId: resp.id, ...request });
  });
}

export const editView = EditView;
export const deleteView = DeleteView;
export const editFilter = EditFilter;

export const updateDefaultView = (advisorId, viewId) => {
  return EditAdvisor(advisorId, { defaultView: viewId });
};

export const mapViewToDraggableTileProps = (view = {}) => {
  const {
    name,
    color,
    description,
    numActiveClientWorkflows,
    numActiveClients,
    numRed,
    numYellow,
    numGreen,
  } = view;

  return {
    type: "view",
    title: name,
    color: color,
    description: description,
    totalWorkflow: numActiveClientWorkflows,
    totalClients: numActiveClients,
    totalUpcoming: numGreen,
    totalComingDue: numYellow,
    totalOverdue: numRed,
    workflowLabel: "Active Workflow",
    clientLabel: "Active Client",
  };
};

export function reOrderView({ items }) {
  const maxOrder = items.reduce((max, currentView) => {
    return currentView.order > max ? currentView.order : max;
  }, 0);
  return BulkUpdateViews({
    views: items.map(({ id }, i) => {
      return {
        id: id,
        order: maxOrder + i + 1,
      };
    }),
  });
}

function arraysEqual(a, b) {
  return (
    a.length === b.length &&
    a.every((val, i) => {
      return val === b[i];
    })
  );
}

const isFilterUpdated = (
  { filters: viewFilter = {}, workflows = [] },
  filters,
  selectedWorkflows
) => {
  const filterKeys = [
    "streamFilterType",
    "streams",
    "tagFilterType",
    "tags",
    "filterEmptyWorkflows",
    "filterNextTaskForAssignee",
    "assignee",
    "assigneeFilterType",
    "clientFilter",
    "reminderTypes",
  ];

  if (
    !arraysEqual(
      workflows.map(({ id }) => {
        return id;
      }),
      selectedWorkflows.map(({ id }) => {
        return id;
      })
    )
  ) {
    return true;
  }

  return filterKeys.some((key) => {
    if (Array.isArray(viewFilter[key]) && Array.isArray(filters[key])) {
      return !arraysEqual(viewFilter[key], filters[key]);
    } else {
      return viewFilter[key] !== filters[key];
    }
  });
};

export function shouldShowSaveView(
  currentView,
  filters,
  selectedWorkflows = []
) {
  return (
    (!currentView && selectedWorkflows.length > 0) ||
    (currentView && isFilterUpdated(currentView, filters, selectedWorkflows))
  );
}

export default editView;
