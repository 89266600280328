import React from "react";
import PropTypes from "prop-types";
import useNewHublyApp from "./useNewHublyApp";

export function NewAppIndicator({ indicator }) {
  const { newApp } = useNewHublyApp();

  if (!newApp) return null;

  return <span>{indicator}</span>;
}

NewAppIndicator.propTypes = { indicator: PropTypes.string };
NewAppIndicator.defaultProps = { indicator: "Beta" };

export default NewAppIndicator;
