import { LayoutIcon } from "./LayoutIcon";
import { LayoutBody } from "./LayoutBody";
import { LayoutHeader } from "./LayoutHeader";
import { Layout } from "./Layout";

Layout.Icon = LayoutIcon;
Layout.Body = LayoutBody;
Layout.Header = LayoutHeader;

export { Layout };
export default Layout;
