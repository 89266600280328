import { API } from "aws-amplify";
import { getAPIReq } from "./authLib";

export const GetPolicies = async () => {
  const req = await getAPIReq();
  return API.get("HublyAPI", `/policies/`, req);
};

export const GetPolicy = async (policyId) => {
  const req = await getAPIReq();
  return API.get("HublyAPI", `/policies/${policyId}/`, req);
};

export const AcceptPolicy = async (policyId) => {
  const req = await getAPIReq();
  return API.post("HublyAPI", `/policies/${policyId}/accept/`, req);
};
