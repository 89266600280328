import React, { useState, useContext } from "react";
import PropTypes from "prop-types";

import { useDropdownData } from "hooks";

import { HubContext } from "scenes/Hubly/components/Workspace/Provider";

import { ClientDropdown } from "components/ClientDropdown";
import { excludeDropdownData } from "components/ClientDropdown/helper";

export function HouseholdClientDropdown({
  client,
  excludeMemberIds,
  onClientSelected,
}) {
  const { searchClients } = useContext(HubContext);
  const [searchText, setSearchText] = useState(client?.name || "");

  const clientsDropdownData = useDropdownData({
    searchText: searchText,
    type: "client",
    fetchFunction: searchClients,
  });
  const clientsData = excludeDropdownData(
    excludeMemberIds,
    clientsDropdownData
  );

  return (
    <React.Fragment>
      <div
        data-test="household-client-dropdown"
        style={{ display: "flex", width: "100%" }}
      >
        <ClientDropdown
          opened={false}
          defaultSection="All Contacts"
          initialSearchInputValue={client?.name || ""}
          clientsData={clientsData}
          showSelectionValueOnClick
          onSearchChange={(text) => {
            setSearchText(text);
            if (text === "") clientsData.loadData();
          }}
          dropdownProps={{
            placeholder: "Search for a client",
          }}
          onClientClick={onClientSelected}
        />
      </div>
    </React.Fragment>
  );
}

HouseholdClientDropdown.defaultProps = {
  client: {},
  excludeMemberIds: [],
};

HouseholdClientDropdown.propTypes = {
  client: PropTypes.object,
  excludeMemberIds: PropTypes.array,
  onClientSelected: PropTypes.func.isRequired,
};

export default HouseholdClientDropdown;
