import React, { useState } from "react";
import PropTypes from "prop-types";
import { Icon, Segment, Popup } from "semantic-ui-react";
import MarkedText from "components/MarkedText";
import { faGripVertical } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { datadogRum } from "@datadog/browser-rum";
import AddClientPopUp from "./AddClientPopUp";

export function HideShowTile({
  workflowName,
  totalClient,
  totalTask,
  hidden,
  processColor,
  filteredKeyword,
  onClick,
  showRuleIndicator,
  dragHandleProps,
  children,
}) {
  const [showAddclientDropwn, setShowAddClientDropdow] = useState(false);
  const [addClientTooltipProps, setAddClientTooltipProps] = useState({});
  const toggleWorkflow = () => {
    const emitSuccessSearchMetric = () => {
      if (hidden && filteredKeyword?.length > 0) {
        datadogRum.addAction("hubly_count_successful_workflow_search");
      }
    };

    emitSuccessSearchMetric();
    onClick(hidden);
  };

  return (
    <Segment.Group
      horizontal
      style={{ width: "100%", color: "grey", margin: "0.5em 0em" }}
      className={`clickable grey_on_hover no_select ${
        hidden && "disabled_segment"
      }`}
      data-test={`workflows-list-${workflowName}`}
      onClick={toggleWorkflow}
    >
      <Segment style={{ width: "55%", padding: "0.75em 1em" }}>
        <div style={{ display: "flex" }}>
          {Object.keys(dragHandleProps).length !== 0 && (
            <div
              data-test={`workflow-draggable-${workflowName}`}
              {...dragHandleProps}
            >
              <FontAwesomeIcon
                className="dark_grey_on_hover"
                color="rgb(218, 218, 218)"
                icon={faGripVertical}
                style={{
                  marginRight: "1em",
                }}
              />
            </div>
          )}
          {processColor && (
            <div
              data-test="process-color"
              style={{
                height: "19px",
                width: "8px",
                marginRight: "0.5em",
                backgroundColor: processColor,
              }}
            />
          )}
          <div
            className="overflow_ellipsis"
            style={{
              margin: 0,
              color: "rgba(0, 0, 0, 0.8)",
              flexGrow: 1,
            }}
          >
            <MarkedText text={workflowName} keyword={filteredKeyword} />
          </div>
          {showRuleIndicator && (
            <Popup
              content={
                "This workflow has active rules applied to it." +
                " View rules by selecting 'Edit Workflow' in the workflow header menu."
              }
              popperModifiers={{
                preventOverflow: {
                  boundariesElement: "window",
                  enabled: false,
                },
              }}
              position="right center"
              wide
              trigger={
                <Icon
                  name="settings"
                  style={{
                    float: "right",
                    fontSize: "12pt",
                    margin: "0 0 0px 1em",
                    transform: "scaleX(-1)",
                    color: "darkgrey",
                  }}
                />
              }
            />
          )}
        </div>
      </Segment>
      <Segment style={{ width: "20%", textAlign: "center", padding: "0.75em" }}>
        <Icon color="grey" name="check square" />
        {`${totalTask} Task${totalTask > 1 ? "s" : ""}`}
      </Segment>
      <Popup
        content="Add Client(s)"
        position="top center"
        on="hover"
        {...addClientTooltipProps}
        trigger={
          <Segment
            style={{ width: "25%", textAlign: "center", padding: "0.75em" }}
          >
            <div
              onClick={(e) => {
                e.stopPropagation();
                setShowAddClientDropdow(true);
                setAddClientTooltipProps({ open: false });
              }}
            >
              <Icon color="grey" name="user" />
              {`${totalClient} Client${totalClient >= 1 ? "s" : ""}`}
              <AddClientPopUp
                open={showAddclientDropwn}
                onPopupClosed={() => {
                  setShowAddClientDropdow(false);
                  setAddClientTooltipProps({});
                }}
              >
                {children}
              </AddClientPopUp>
            </div>
          </Segment>
        }
      />
    </Segment.Group>
  );
}

HideShowTile.defaultProps = {
  processColor: "",
  workflowName: "",
  filteredKeyword: "",
  onClick: () => {},
  dragHandleProps: {},
  totalClient: 0,
  totalTask: 0,
  showRuleIndicator: false,
  hidden: false,
  children: null,
};

HideShowTile.propTypes = {
  hidden: PropTypes.bool,
  totalClient: PropTypes.number,
  totalTask: PropTypes.number,
  processColor: PropTypes.string,
  workflowName: PropTypes.string,
  filteredKeyword: PropTypes.string,
  onClick: PropTypes.func,
  showRuleIndicator: PropTypes.bool,
  dragHandleProps: PropTypes.object,
  children: PropTypes.element,
};

export default HideShowTile;
