import React from "react";
import { Button, Header, Modal, Dropdown, Icon } from "semantic-ui-react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { CreateDuplicateWorkflow } from "../../scenes/Hubly/components/Workflows/actions";
import { SetAlert } from "../Alerts/actions";

class DuplicateModal extends React.Component {
  static propTypes = {
    advisor: PropTypes.object.isRequired,
    selectedHub: PropTypes.object.isRequired,
    workflowId: PropTypes.string.isRequired,
    closeModal: PropTypes.func.isRequired,
    createDuplicateWorkflow: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const { advisor, selectedHub } = props;
    this.hubOptions = [];
    advisor.practices.forEach((prac) => {
      prac.hubs.forEach((hub) => {
        this.hubOptions.push({
          key: hub.id,
          value: hub.id,
          text: hub.name,
        });
      });
    });
    this.state = {
      hubSelection: selectedHub.id,
      loading: false,
    };
  }

  copy = () => {
    const { workflowId, createDuplicateWorkflow, setAlert, closeModal } =
      this.props;
    const { hubSelection } = this.state;
    this.setState({ loading: true });
    createDuplicateWorkflow(workflowId, hubSelection, (success) => {
      if (success)
        setAlert({ type: "success", text: "Workflow was duplicated" });
      else setAlert({ type: "error", text: "Unable to duplicate workflow" });
      this.setState({ loading: false });
      closeModal();
    });
  };

  render() {
    const { closeModal, selectedHub } = this.props;
    const { hubSelection, loading } = this.state;
    return (
      <Modal open size="tiny" onClose={closeModal}>
        <Modal.Header>
          Duplicate Workflow
          <Icon
            name="delete"
            color="grey"
            style={{
              position: "relative",
              top: "-10px",
              right: "-10px",
              float: "right",
            }}
            link
            onClick={closeModal}
          />
        </Modal.Header>
        <Modal.Content>
          <Header as="h3">Add Workflow To</Header>
          <Dropdown
            selection
            style={{ minWidth: "300px" }}
            options={this.hubOptions}
            value={hubSelection}
            onChange={(e, d) => {
              this.setState({ hubSelection: d.value });
            }}
          />
          {hubSelection !== selectedHub.id && (
            <div style={{ color: "grey", fontSize: "9pt", marginTop: "0.5em" }}>
              Note. Next Workflows, Rules, and Conditions will not be
              duplicated, and must be configured manualy.
            </div>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            loading={loading}
            primary
            content="Create Copy"
            onClick={this.copy}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    advisor: state.hubly.data.advisor,
    selectedHub: state.hubly.data.hub.selected.hub,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createDuplicateWorkflow: (workflow, hubId, callback) => {
      dispatch(CreateDuplicateWorkflow(workflow, hubId, callback));
    },
    setAlert: (alert) => {
      dispatch(SetAlert(alert));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DuplicateModal);
