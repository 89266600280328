import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Form, Header, Input } from "semantic-ui-react";
import moment from "moment";
import { SetAdvisor } from "data/advisor/actions";
import { GetAdvisor } from "data/libs/advisor";
import { PostCheckoutExisting, PostPortalSession } from "data/libs/chargebee";

class SubscriptionInformationForm extends Component {
  static propTypes = {
    advisor: PropTypes.shape({
      id: PropTypes.string.isRequired,
      billing: PropTypes.object,
    }).isRequired,
    setAdvisor: PropTypes.func.isRequired,
  };

  componentDidMount() {
    // Initialize a Chargebee instance
    if (window.Chargebee && !window.Chargebee?.inited) {
      window.Chargebee.init({
        site: process.env.REACT_APP_STAGE === "prod" ? "hubly" : "hubly-test",
      });
    }
  }

  manage = () => {
    if (window.Chargebee) {
      const chargebeeInstance = window.Chargebee.getInstance();

      chargebeeInstance.setPortalSession(() => {
        return PostPortalSession().then((response) => {
          return response;
        });
      });

      const cbPortal = chargebeeInstance.createChargebeePortal();
      cbPortal.open({
        close() {},
      });
    }
  };

  upgrade = () => {
    const { setAdvisor } = this.props;
    if (window.Chargebee) {
      window.Chargebee.getInstance().openCheckout({
        hostedPage: () => {
          return PostCheckoutExisting().then((response) => {
            return response;
          });
        },
        success: (hostedPageId) => {
          // Update the advisor, as new billing status due to checkout
          GetAdvisor().then((resp) => {
            setAdvisor(resp);
          });
        },
      });
    }
  };

  render() {
    const { advisor } = this.props;
    const billing = advisor.billing || {
      coupons: [],
      next_billing_at: new Date(),
      status: "none",
      subscribed: false,
      trialEnd: new Date(),
    };
    const inTrial = billing.status === "in_trial";
    // Chargebee using hours and rounding for days
    const hoursLeft = moment(billing.trialEnd).diff(moment(), "hours") + 1;
    let daysLeft = Math.round(hoursLeft / 24);
    daysLeft = daysLeft > 0 ? daysLeft : 1; // Set to 1 day left when less than 12 hours
    const daysLeftText = daysLeft > 1 ? `${daysLeft} days` : `${daysLeft} day`;
    const renewalDate = moment(billing.nextBillingAt).format("MMM D, YYYY");
    let coupon = null;
    if (
      billing.coupons &&
      billing.coupons.length > 0 &&
      billing.coupons[0].appliedCount <= 0
    ) {
      [coupon] = billing.coupons;
    }
    return (
      <div style={{ marginTop: "1em" }}>
        <div style={{ display: "flex" }}>
          <Header as="h1" style={{ marginBottom: "0" }}>
            Subscription Information
          </Header>
          <Button
            content="Manage"
            onClick={this.upgrade}
            primary
            style={{ float: "right", marginLeft: "1em" }}
          />
        </div>
        <Form style={{ marginTop: "1em" }}>
          <Form.Group>
            <Form.Field key="current-plan" style={{ flexGrow: 1 }}>
              <Header as="h3">Current Plan</Header>
              <Input
                disabled
                fluid
                name="currentPlan"
                placeholder="Plan name"
                value={
                  inTrial && !billing.subscribed
                    ? "Free Trial"
                    : "Standard Plan"
                }
              />
            </Form.Field>
            <Form.Field key="renews-ow" style={{ flexGrow: 1 }}>
              <Header as="h3">
                {inTrial && !billing.subscribed ? "Expires In" : "Renews On"}
              </Header>
              <Input
                disabled
                fluid
                name="renews-on"
                onChange={this.handleChange}
                placeholder="Renews on"
                value={
                  inTrial && !billing.subscribed ? daysLeftText : renewalDate
                }
              />
            </Form.Field>
          </Form.Group>
        </Form>
        {coupon && !billing.subscribed && (
          <div style={{ marginTop: "1em", width: "100%" }}>
            {coupon.message}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    advisor: state.hubly.data.advisor,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAdvisor: (advisor) => {
      dispatch(SetAdvisor(advisor));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscriptionInformationForm);
