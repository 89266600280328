import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Header, Icon, Popup, Segment } from "semantic-ui-react";
import MarkedText from "components/MarkedText";
import { faGripVertical } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SummaryRow from "./components/SummaryRow";

export function DraggableTile({
  type,
  color,
  title,
  collapsible,
  subTitle,
  description,
  disabled,
  filteredKeyword,
  dragHandleProps,
  expandSummaryRow,
  children,
  onClick,
  onEditClick,
  onDeleteClick,
  onSummaryRowClick,
  ...summaryRowProps
}) {
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <Segment.Group
      data-test={`${type}-${title}`}
      className={collapsible ? "" : "clickable grey_on_hover no_select"}
      style={{
        flexGrow: "1",
        boxSizing: "border-container",
        margin: "0px",
        marginBottom: "1em",
        minWidth: "100%",
      }}
    >
      <Segment
        style={{ background: "none", opacity: disabled ? 0.5 : 1 }}
        className="clickable grey_on_hover no_select"
        onClick={onClick}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ display: "flex" }}>
            <div data-test={`${type}-draggable-${title}`} {...dragHandleProps}>
              <FontAwesomeIcon
                className="dark_grey_on_hover"
                color="rgb(218, 218, 218)"
                icon={faGripVertical}
                style={{
                  marginRight: "1em",
                }}
              />
            </div>
            <div
              style={{
                height: "19px",
                width: "8px",
                marginRight: "0.5em",
                backgroundColor: color,
              }}
            />
            <div>
              {filteredKeyword === "" ? (
                <Header as="h3">{title}</Header>
              ) : (
                <div style={{ fontSize: "14pt" }}>
                  <MarkedText text={title} keyword={filteredKeyword} />
                </div>
              )}
            </div>
          </div>
          <div>
            <Popup
              data-test="tile-popup"
              className="hubly_bars_menu"
              hideOnScroll
              id="hide-show-popup"
              on="click"
              onClose={() => {
                setMenuOpen(false);
              }}
              open={menuOpen}
              popperModifiers={{
                preventOverflow: {
                  boundariesElement: "window",
                  enabled: false,
                },
              }}
              position="right center"
              trigger={
                <Icon
                  link
                  name="ellipsis horizontal"
                  color="grey"
                  style={{ float: "right", fontSize: "12pt" }}
                  onClick={(event) => {
                    event.stopPropagation();
                    setMenuOpen(true);
                  }}
                />
              }
            >
              <Button.Group
                basic
                vertical
                labeled
                icon
                style={{ border: "none" }}
              >
                <Button
                  icon="edit"
                  content={`Edit ${type}`}
                  onClick={(event) => {
                    event.stopPropagation();
                    onEditClick();
                    setMenuOpen(false);
                  }}
                />
                <Button
                  icon="delete"
                  content={`Delete ${type}`}
                  onClick={(event) => {
                    event.stopPropagation();
                    onDeleteClick();
                    setMenuOpen(false);
                  }}
                />
              </Button.Group>
            </Popup>
          </div>
        </div>
        {subTitle}
        {description && (
          <div style={{ color: "rgba(0, 0, 0, 0.9)", marginTop: "0.5em" }}>
            {description}
          </div>
        )}
      </Segment>
      <Segment className="clickable" style={{ background: "none" }}>
        <div style={{ color: "grey" }}>
          <SummaryRow
            active={!disabled}
            collapsible={collapsible}
            opened={expandSummaryRow}
            onClick={() => {
              if (collapsible) {
                onSummaryRowClick();
              } else {
                onClick();
              }
            }}
            {...summaryRowProps}
          />
          {expandSummaryRow && (
            <div style={{ paddingTop: "0.5em" }}>{children}</div>
          )}
        </div>
      </Segment>
    </Segment.Group>
  );
}

DraggableTile.defaultProps = {
  subTitle: null,
  description: "",
  disabled: false,
  filteredKeyword: "",
  dragHandleProps: {},
  expandSummaryRow: false,
  children: null,
  collapsible: false,
  color: null,
  onSummaryRowClick: () => {},
};

DraggableTile.propTypes = {
  color: PropTypes.string,
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  onSummaryRowClick: PropTypes.func,
  subTitle: PropTypes.node,
  description: PropTypes.string,
  disabled: PropTypes.bool,
  filteredKeyword: PropTypes.string,
  dragHandleProps: PropTypes.object,
  expandSummaryRow: PropTypes.bool,
  collapsible: PropTypes.bool,
  children: PropTypes.node,
};

export default DraggableTile;
