import React from "react";
import { Button, Popup, Icon } from "semantic-ui-react";
import PropTypes from "prop-types";

/* Can use the buttons prop and/or children */

class PopupMenu extends React.Component {
  static defaultProps = {
    buttons: [],
    children: null,
    context: null,
    onClose: () => {},
    onOpen: () => {},
    open: false,
    popperModifiers: {
      preventOverflow: { boundariesElement: "window", enabled: false },
    },
    position: "right center",
    trigger: <Icon color="grey" link name="ellipsis horizontal" />,
    disabled: false,
    id: null,
  };

  static propTypes = {
    buttons: PropTypes.array,
    children: PropTypes.object,
    context: PropTypes.object,
    onClose: PropTypes.func,
    onOpen: PropTypes.func,
    open: PropTypes.bool,
    popperModifiers: PropTypes.object,
    position: PropTypes.string,
    trigger: PropTypes.object,
    disabled: PropTypes.bool,
    id: PropTypes.string,
  };

  render() {
    const {
      buttons,
      children,
      context,
      disabled,
      onClose,
      onOpen,
      open,
      popperModifiers,
      position,
      trigger,
      id,
      ...attributes
    } = this.props;

    return (
      <Popup
        className="hubly_bars_menu"
        context={context}
        hideOnScroll
        on="click"
        onClose={onClose}
        onOpen={onOpen}
        open={open}
        popperModifiers={popperModifiers}
        position={position}
        trigger={trigger}
        disabled={disabled}
        id={id}
        {...attributes}
      >
        <Button.Group basic vertical labeled icon style={{ border: "none" }}>
          {buttons.map(({ content, icon, onClick, ...otherProps }) => {
            return (
              <Button
                key={content}
                icon={icon}
                content={content}
                onClick={onClick}
                {...otherProps}
              />
            );
          })}
          {children}
        </Button.Group>
      </Popup>
    );
  }
}

export default PopupMenu;
