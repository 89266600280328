import React from "react";
import { Loading } from "aws-amplify-react";
import LoadingComponent from "components/Loading";

class HublyLoading extends Loading {
  showComponent() {
    const { authState } = this.props;
    if (authState !== "loading") {
      return null;
    }
    return <LoadingComponent active message="Signing you in..." />;
  }
}

export default HublyLoading;
