import React from "react";
import PropTypes from "prop-types";

import { Header, Divider } from "semantic-ui-react";

import Options from "components/OptionList";
import AssigneeFilter from "./AssigneeFilter";

export default function FeedContentFilter({ onDone }) {
  return (
    <React.Fragment>
      <Header as="h1" style={{ fontSize: "14pt", margin: "0px" }}>
        Hub Feed Settings
      </Header>
      <Divider style={{ margin: "0px" }} />
      <Options>
        <AssigneeFilter onSelected={onDone} />
      </Options>
    </React.Fragment>
  );
}

FeedContentFilter.propTypes = {
  onDone: PropTypes.func,
};

FeedContentFilter.defaultProps = {
  onDone: () => {},
};
