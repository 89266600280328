import { API } from "aws-amplify";
import SyncedAPI from "data/libs/syncedAPI";
import { getAPIReq } from "./authLib";

export const AddTask = async (request, clientId = null) => {
  const req = await getAPIReq(request);
  return SyncedAPI.post("HublyAPI", `/tasks/`, req, (response) => {
    if (clientId) {
      return { type: "ADD_CLIENT_TASK", task: response };
    }
    return { type: "ADD_WORKFLOW_TASK", task: response };
  });
};

export const BulkUpdateTasks = async (
  request,
  workflowId = null,
  clientId = null
) => {
  const req = await getAPIReq(request);
  return SyncedAPI.put("HublyAPI", `/tasks/bulkUpdate/`, req, (response) => {
    if (clientId) return { type: "BULK_UPDATE_CLIENT_TASKS", tasks: response };
    return {
      type: "BULK_UPDATE_WORKFLOW_TASKS",
      workflowId: workflowId,
      tasks: response,
    };
  });
};

export const EditTask = async (
  id,
  request,
  clientId = null,
  isNextWorkflow = false
) => {
  const req = await getAPIReq(request);
  return SyncedAPI.put("HublyAPI", `/tasks/${id}/`, req, (response) => {
    if (clientId) {
      return { type: "EDIT_CLIENT_TASK", task: response };
    } else if (isNextWorkflow) {
      return response;
    }
    return { type: "EDIT_WORKFLOW_TASK", task: response };
  });
};

export const AddSequentialReminder = async (id, reminderData) => {
  const req = await getAPIReq({
    key: "sequentialReminder",
    value: { ...reminderData },
  });
  return SyncedAPI.put(
    "HublyAPI",
    `/tasks/${id}/set_options/`,
    req,
    (response) => {
      return response;
    }
  );
};

export const ClearSequentialReminder = async (id) => {
  const req = await getAPIReq({ key: "sequentialReminder", value: {} });
  return SyncedAPI.put(
    "HublyAPI",
    `/tasks/${id}/set_options/`,
    req,
    (response) => {
      return response;
    }
  );
};

export const DeleteTask = async (
  id,
  workflowId = null,
  clientWorkflowId = null
) => {
  const req = await getAPIReq();
  return SyncedAPI.del("HublyAPI", `/tasks/${id}/`, req, () => {
    if (clientWorkflowId) {
      return {
        type: "DELETE_CLIENT_TASK",
        taskId: id,
        clientWorkflowId: clientWorkflowId,
      };
    }
    return { type: "DELETE_WORKFLOW_TASK", taskId: id, workflowId: workflowId };
  });
};

export const GetTaskHouseholdMembers = async (taskId) => {
  const req = await getAPIReq();
  return API.get("HublyAPI", `/tasks/${taskId}/household_members/`, req);
};

export const CreateTaskHouseholdMember = async (taskId, request) => {
  const req = await getAPIReq(request);
  return SyncedAPI.post("HublyAPI", `/tasks/${taskId}/household_members/`, req);
};

export const DeleteTaskHouseholdMember = async (taskId, householdMemberId) => {
  const req = await getAPIReq();
  return SyncedAPI.del(
    "HublyAPI",
    `/tasks/${taskId}/household_members/${householdMemberId}/`,
    req
  );
};

export const GetTaskTimeEstimates = async (taskId) => {
  const req = await getAPIReq();
  return API.get("HublyAPI", `/tasks/${taskId}/time_estimates/`, req);
};

export const CreateTaskTimeEstimate = async (taskId, request) => {
  const req = await getAPIReq(request);
  return SyncedAPI.post("HublyAPI", `/tasks/${taskId}/time_estimates/`, req);
};

export const EditTaskTimeEstimate = async (taskId, timeEstimateId, request) => {
  const req = await getAPIReq(request);
  return SyncedAPI.put(
    "HublyAPI",
    `/tasks/${taskId}/time_estimates/${timeEstimateId}/`,
    req
  );
};

export const DeleteTaskTimeEstimate = async (taskId, timeEstimateId) => {
  const req = await getAPIReq();
  return SyncedAPI.del(
    "HublyAPI",
    `/tasks/${taskId}/time_estimates/${timeEstimateId}/`,
    req
  );
};
