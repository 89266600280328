import React from "react";
import PropTypes from "prop-types";

import { Modal, Icon, Checkbox } from "semantic-ui-react";

import { useForm } from "hooks";

import AddRemoveButton from "components/AddRemoveButton";
import { ExcludeItems } from "..";

import { formatRuleModalRequest, generateModalProp } from "./helper";

export function RuleModal({
  loading,
  type,
  workflowRule,
  dateTypes,
  meetingDateTypes,
  onClose,
  onDelete,
  onSave,
  children,
}) {
  const {
    initialValue,
    title,
    testId,
    showHouseholdHead,
    showExcludeItems,
    formValidator = ({ selectedStreamsTags }) => {
      return selectedStreamsTags.length > 0;
    },
  } = generateModalProp(type, workflowRule);

  const { register, data, disableButton } = useForm({
    initialValue: initialValue,
    formValidator: formValidator,
  });

  const householdHeadProps = register({ name: "onlyHouseholdHead" });
  return (
    <Modal data-test={testId} size="tiny" on="click" open>
      <Modal.Header as="h4">
        <Icon
          color="grey"
          link
          name="delete"
          onClick={onClose}
          style={{ float: "right" }}
        />
        {title}
      </Modal.Header>
      <Modal.Content>
        {children({ register, data, dateTypes, meetingDateTypes })}
        <div style={{ display: "flex", flexDirection: "column" }}>
          {showHouseholdHead && (
            <Checkbox
              data-test="ruleModal-checkbox-onlyHouseholdHead"
              name={householdHeadProps.name}
              defaultChecked={householdHeadProps.value}
              onChange={() => {
                householdHeadProps.onChange(null, {
                  name: householdHeadProps.name,
                  value: !householdHeadProps.value,
                });
              }}
              label="Only apply to household head"
              style={{ marginBottom: "1em" }}
            />
          )}
          {showExcludeItems && (
            <ExcludeItems
              {...register({ name: "excludeItems" })}
              exclude={data.selectedStreamsTags.map(({ id }) => {
                return id;
              })}
            />
          )}
        </div>
      </Modal.Content>
      <Modal.Actions>
        <AddRemoveButton
          loading={loading}
          addText="Add Rule"
          saveText="Edit Rule"
          disabled={disableButton || loading}
          isCreating={workflowRule === null}
          onRemove={() => {
            onDelete(workflowRule.id);
          }}
          onAdd={() => {
            onSave(
              workflowRule?.id,
              formatRuleModalRequest(type, {
                ...data,
                workflowRule,
                meetingDateTypes,
              })
            );
          }}
        />
      </Modal.Actions>
    </Modal>
  );
}

RuleModal.propTypes = {
  loading: PropTypes.bool,
  workflowRule: PropTypes.object,
  dateTypes: PropTypes.array,
  meetingDateTypes: PropTypes.array,
  type: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  children: PropTypes.func,
};

RuleModal.defaultProps = {
  loading: false,
  workflowRule: null,
  meetingDateTypes: [],
  dateTypes: [],
  children: () => {},
};

export default RuleModal;
