import { SetAlert } from "components/Alerts/actions";
import { conditionallyUpdateAllReminders } from "components/Task/actions";
import { GetWorkflows } from "../libs/workflows";
import { EditTask } from "../libs/tasks";
import { GetAllClientNames, GetRecentClientNames } from "../libs/clients";
import { GetHouseholds } from "../libs/households";
import { GetHub, SetHubPreferences } from "../libs/hubs";
import {
  GetAllClients,
  SetActiveClients,
  SetAllClientNames,
  SetRecentClientNames,
  SetActiveClient,
} from "./clients/actions";
import { FetchHubHouseholds, SetHouseholds } from "./households/actions";
import {
  InsertReminder,
  RemoveReminder,
  SetReminders,
} from "../reminders/actions";
import { SetWebsocketHubId } from "../websockets/actions";
import { ClearFilters } from "../filters/actions";
import {
  GetAllClientWorkflows,
  GetAllClientWorkflowsByClientId,
  SetClientWorkflow,
  SetClientWorkflows,
  UpdateClientWorkFlows,
} from "./clientWorkflows/actions";
import { GetTags } from "../libs/tags";
import { SetTags } from "./tags/actions";
import { GetViews } from "../libs/views";
import { SetViews } from "../views/actions";
import { SetWorkflows } from "./workflows/actions";
import { GetReminders } from "../libs/reminders";

export const SetHubLoading = (loading) => {
  return {
    type: "SET_HUB_LOADING",
    loading: loading,
  };
};

export const SetInProgress = (inProgress, percent) => {
  return {
    type: "SET_IN_PROGRESS",
    inProgress: inProgress,
    percent: percent,
  };
};

export function CompleteTaskByReminder(taskId, reminderId, clientId) {
  return (dispatch, getState) => {
    const { hub } = getState().hubly.data.hub.selected;
    const client = getState().hubly.data.hub.clients.activeClients[clientId];
    const { clientWorkflows } = getState().hubly.data.hub;

    const request = {
      completed: true,
    };

    EditTask(taskId, request)
      .then((response) => {
        const clientCopy = { ...client };
        const foundReminder = clientCopy.reminders.find((reminder) => {
          return reminder.id === reminderId;
        });
        if (!foundReminder) {
          console.warn(
            "CompleteTaskByReminder, Reminder ID ",
            reminderId,
            " does not exist"
          );
          return;
        }

        // Update the task on the client object
        clientCopy.workflows.find((workflowId) => {
          const workflow = { ...clientWorkflows[workflowId] };
          const foundTask = workflow.tasks.find((task) => {
            return task.id === foundReminder.taskId;
          });
          if (foundTask) {
            foundTask.completed = true;
            foundTask.completedAt = response.completedAt;
            dispatch(SetClientWorkflow(workflow));
            return true;
          }
          return false;
        });

        conditionallyUpdateAllReminders(
          response,
          { clientId, dispatch, getState },
          () => {
            dispatch(SetActiveClient(clientCopy));

            const taskReminders = response.reminders;
            const { reminders } = getState().hubly.data;
            const remindersCopy = reminders.slice();
            taskReminders.forEach((reminder) => {
              RemoveReminder(reminder.id, remindersCopy);
              InsertReminder(reminder, remindersCopy);
            });

            dispatch(SetReminders(remindersCopy));
          }
        );

        GetAllClientWorkflowsByClientId(hub, clientId).then((cws) => {
          dispatch(UpdateClientWorkFlows(cws));
        });

        dispatch(
          SetAlert({
            type: "success",
            text: `Successfully completed task by reminder`,
          })
        );
      })
      .catch((error) => {
        console.error(error);
        dispatch(
          SetAlert({
            type: "error",
            text: `Failed to mark task as completed.`,
          })
        );
      });
  };
}

export const SetHub = (hub) => {
  return {
    type: "SET_HUB",
    hub: hub,
  };
};

export const SetHubToLoad = (hub) => {
  return (dispatch) => {
    dispatch(SetHub(hub));
    dispatch(SetWebsocketHubId(hub.hubId));
    dispatch({ type: "SET_PENDING_LOAD_RESOURCES", set: true });
  };
};

export const LoadHubResources = () => {
  return (dispatch, getState) => {
    const { hub } = getState().hubly.data.hub.selected;

    dispatch(SetReminders([]));
    dispatch(SetHubLoading(true));
    dispatch(SetHub(hub));
    const getWorkflowsPromise = GetWorkflows(hub).then((resp) => {
      dispatch(SetWorkflows(resp));
      // Initial state has no workflows set for filters, if that's the case, set them here

      const { filters } = getState().hubly.data;
      if (
        !filters.workflows ||
        filters.workflows.find((w) => {
          return w.id !== resp[0].id;
        })
      ) {
        dispatch(ClearFilters(resp));
      }
    });

    const { loadAllClientsOnStartup = true } = hub.preferences || {};

    const getClientsPromise = loadAllClientsOnStartup
      ? GetAllClients(hub).then((resp) => {
          dispatch(SetActiveClients(resp));
        })
      : Promise.resolve();

    const getTagsPromise = GetTags(hub).then((resp) => {
      const tags = {};
      resp.forEach((tag) => {
        tags[tag.id] = tag;
      });
      dispatch(SetTags(tags));
    });
    const getClientNamesPromise = GetAllClientNames(hub).then((resp) => {
      dispatch(SetAllClientNames(resp));
    });
    const getRecentClientNamesPromise = GetRecentClientNames(hub).then(
      (resp) => {
        dispatch(SetRecentClientNames(resp));
      }
    );
    const getClientWorkflowsPromise = GetAllClientWorkflows(hub).then(
      (response) => {
        dispatch(SetClientWorkflows(response));
      }
    );
    const getHouseholdsPromise = GetHouseholds(hub).then((response) => {
      dispatch(SetHouseholds(response));
    });
    const getViewsPromise = GetViews(hub).then((response) => {
      dispatch(SetViews(response));
    });
    const getRemindersPromise = GetReminders(hub, {
      client_id__isnull: false,
      limit: 999999999,
      ordering: "time",
      active: true,
    }).then((response) => {
      SetReminders(response.results);
    });

    const promises = [
      getWorkflowsPromise,
      getClientsPromise,
      getTagsPromise,
      getClientNamesPromise,
      getRecentClientNamesPromise,
      getClientWorkflowsPromise,
      getHouseholdsPromise,
      getViewsPromise,
      getRemindersPromise,
    ];

    Promise.all(promises).then(() => {
      dispatch(SetHubLoading(false));
      dispatch(SetWebsocketHubId(hub.hubId));
      dispatch({ type: "SET_PENDING_LOAD_RESOURCES", set: false });
    });
  };
};

const SetNewHubPreferences = (hub) => {
  return {
    type: "SET_HUB_PREFERENCES",
    preferences: hub.preferences,
  };
};

export const SetSelectedHubPreferences = (newSettings) => {
  return async (dispatch, getState) => {
    const hubId = getState().hubly.data.hub.selected.hub.id;

    try {
      await SetHubPreferences(hubId, newSettings);
      dispatch(SetNewHubPreferences(await GetHub(hubId)));
    } catch (error) {
      dispatch(SetAlert({ type: "error", text: "Failed save your settings." }));
    }
  };
};

/*
 * THIS IS A TEMPORARY SOLUTION FOR LOADING UNPAGINATED RESOURCES
 *
 * ANY CALL WITHIN THIS FUNCTION SHOULD BE CONVERTED TO A PAGINATED CALL
 * THAT THE GRAPHICAL COMPOENENT WOULD KNOW TO HANDLE
 *
 */
export const LoadMinimizeHubResources = () => {
  return (dispatch, getState) => {
    const { households, householdLoader } = getState().hubly.data.hub;
    const { fetching } = householdLoader;

    if (households.isEmpty && !fetching) dispatch(FetchHubHouseholds());
  };
};
