import React, { useEffect } from "react";
import connect from "react-redux/es/connect/connect";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { Responsive, Button } from "semantic-ui-react";
import moment from "moment";
import { SetAdvisor } from "data/advisor/actions";
import Digest from "components/Digest";
import { GetAdvisor } from "data/libs/advisor";
import { PostCheckoutExisting } from "data/libs/chargebee";
import { SetAlert } from "components/Alerts/actions";
import useNewHublyApp from "scenes/Hubly/useNewHublyApp";
import NavigationDropdown from "./NavigationDropdown";
import ContactSearch from "./ContactSearch";
import CannyChangelogButton from "./CannyChangelogButton";
import { ContactSearch as NewContactSearch } from "../../Workspace/components";
import {
  HubContextProvider,
  ToasterContextProvider,
} from "../../Workspace/Provider";

export function WorkFlowsMenu({
  advisor,
  hub,
  loading,
  setAdvisor,
  fireToaster,
}) {
  const { loadingConfig, newApp } = useNewHublyApp();

  const upgrade = () => {
    if (window.Chargebee) {
      window.Chargebee.getInstance().openCheckout({
        hostedPage: () => {
          return PostCheckoutExisting().then((response) => {
            return response;
          });
        },
        success: (hostedPageId) => {
          // Update the advisor, as new billing status due to checkout
          GetAdvisor().then((resp) => {
            setAdvisor(resp);
          });
        },
      });
    }
  };

  useEffect(() => {
    // Initialize a Chargebee instance
    if (window.Chargebee && !window.Chargebee?.inited) {
      window.Chargebee.init({
        site: process.env.REACT_APP_STAGE === "prod" ? "hubly" : "hubly-test",
      });
    }
  }, []);

  if (loadingConfig) {
    return null;
  }

  const integration = !hub
    ? null
    : advisor.integrations.find((i) => {
        return i.hubId === hub.id;
      });

  const billing = advisor.billing || {
    status: "none",
    subscribed: false,
    trialEnd: new Date(),
  };
  const inTrial = billing.status === "in_trial";
  // Chargebee using hours and rounding for days
  const hoursLeft = moment(billing.trialEnd).diff(moment(), "hours") + 1;
  let daysLeft = Math.round(hoursLeft / 24);
  daysLeft = daysLeft > 0 ? daysLeft : 1; // Set to 1 day left when less than 12 hours
  let trialColor = "green";
  if (daysLeft <= 7 && daysLeft >= 4) {
    trialColor = "yellow";
  } else if (daysLeft < 4) {
    trialColor = "red";
  }

  const showAllClientDropdopwn = !loading && hub && hub.id;
  return (
    <div
      style={{
        marginLeft: "auto",
        display: "flex",
        alignItems: "center",
        flexDirection: "row-reverse",
      }}
    >
      <NavigationDropdown />
      <CannyChangelogButton />
      <div>
        <Digest hub={hub} />
      </div>
      {showAllClientDropdopwn && (
        <div>
          {newApp ? (
            <HubContextProvider
              hubId={hub.id}
              hubShortId={hub.hubId}
              hubClientPoolId={hub.clientPool.id}
              sortByLastName={hub.sortByLastName}
              hubAdvisor={advisor}
              advisors={hub.advisors}
              integration={advisor.integrations.find((i) => {
                return i.hubId === hub.id;
              })}
            >
              <ToasterContextProvider fireToaster={fireToaster}>
                <NewContactSearch integration={integration} />
              </ToasterContextProvider>
            </HubContextProvider>
          ) : (
            <ContactSearch integration={integration} />
          )}
        </div>
      )}
      {inTrial && !billing.subscribed && (
        <div>
          <Responsive
            as={Button}
            maxWidth={1350}
            animated="vertical"
            compact
            color={trialColor}
            data-cb-type="portal"
            onClick={upgrade}
          >
            <Button.Content hidden>
              {daysLeft !== 1 ? `${daysLeft} Days` : `${daysLeft} Day`}
            </Button.Content>
            <Button.Content visible>Trial</Button.Content>
          </Responsive>
          <Responsive
            as={Button}
            minWidth={1351}
            animated="vertical"
            compact
            color={trialColor}
            data-cb-type="portal"
            onClick={upgrade}
          >
            <Button.Content hidden>Upgrade Now</Button.Content>
            <Button.Content visible>
              {daysLeft !== 1
                ? `${daysLeft} Trial Days Left`
                : `${daysLeft} Trial Day Left`}
            </Button.Content>
          </Responsive>
        </div>
      )}
    </div>
  );
}

WorkFlowsMenu.defaultProps = {
  hub: {},
};

WorkFlowsMenu.propTypes = {
  advisor: PropTypes.shape({
    id: PropTypes.string.isRequired,
    billing: PropTypes.object,
    integrations: PropTypes.array.isRequired,
  }).isRequired,
  hub: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  setAdvisor: PropTypes.func.isRequired,
  fireToaster: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    advisor: state.hubly.data.advisor,
    hub: state.hubly.data.hub.selected.hub,
    loading: state.hubly.data.hub.selected.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAdvisor: (advisor) => {
      dispatch(SetAdvisor(advisor));
    },
    fireToaster: (alert) => {
      dispatch(SetAlert(alert));
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(WorkFlowsMenu)
);
