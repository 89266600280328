import { API } from "aws-amplify";
import SyncedAPI from "data/libs/syncedAPI";
import { getAPIReq, getAPIQuery } from "./authLib";

export const AddReminder = async (request) => {
  const req = await getAPIReq(request);
  return SyncedAPI.post("HublyAPI", `/reminders/`, req);
};

export const EditReminder = async (id, request) => {
  const req = await getAPIReq(request);
  return SyncedAPI.put("HublyAPI", `/reminders/${id}/`, req);
};

export const GetReminders = async (hub, query) => {
  const req = await getAPIQuery(query);
  return API.get("HublyAPI", `/hubs/${hub.id}/reminders/`, req);
};

export const GetReminder = async (id) => {
  const req = await getAPIReq();
  return API.get("HublyAPI", `/reminders/${id}/`, req);
};

export const DeleteReminder = async (id) => {
  const req = await getAPIReq();
  return SyncedAPI.del("HublyAPI", `/reminders/${id}/`, req);
};
