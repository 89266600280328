import React from "react";
import PropTypes from "prop-types";

import { HubContext } from "scenes/Hubly/components/Workspace/Provider";

import { useFilterProcessor } from "./hooks";
import { Popup, Layout, AssigneeFilter } from "./components";

export function FeedSettings({ onClear, onChange, filters }) {
  const { isMultiAdvisorsHub } = React.useContext(HubContext);
  const { toFilter, fromFilter } = useFilterProcessor();

  if (!isMultiAdvisorsHub()) {
    return null;
  }

  return (
    <div style={{ display: "flex" }}>
      <div style={{ margin: "7px 5px auto auto" }}>
        <Popup>
          {({ closeMenu }) => {
            return (
              <Layout>
                <AssigneeFilter
                  selected={fromFilter(filters)}
                  onClear={() => {
                    onClear();
                    closeMenu();
                  }}
                  onSelect={(assigneeOptions) => {
                    onChange(toFilter(assigneeOptions));
                    closeMenu();
                  }}
                />
              </Layout>
            );
          }}
        </Popup>
      </div>
    </div>
  );
}

FeedSettings.propTypes = {
  onClear: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
};

export default FeedSettings;
