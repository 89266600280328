import React from "react";
import PropTypes from "prop-types";

import { Modal, Button } from "semantic-ui-react";

export function Dialog({ header, title, children, open, onSave, onCancel }) {
  return (
    <Modal
      data-test="dialog"
      size="mini"
      on="click"
      open={open}
      onClose={onCancel}
      style={{ minWidth: "24em" }}
    >
      <Modal.Header style={{ marginBottom: "0em" }}>{header}</Modal.Header>
      <Modal.Content>
        <div
          style={{
            maxWidth: "100%",
            maxHeight: "1.3em",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          title={title}
        >
          {title}
        </div>

        {children}
      </Modal.Content>
      <Modal.Actions>
        <Button basic floated="left" onClick={onCancel}>
          Cancel
        </Button>
        <Button primary basic onClick={onSave}>
          Save Changes
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

Dialog.propTypes = {
  children: PropTypes.node,
  open: PropTypes.bool,
  header: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

Dialog.defaultProps = {
  children: null,
  open: false,
};

export default Dialog;
