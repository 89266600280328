import SyncedAPI from "data/libs/syncedAPI";
import { API } from "aws-amplify";
import { getAPIQuery, getAPIReq } from "./authLib";

export const GetClientWorkflows = async (hub, limit = 100, offset = 0) => {
  const req = await getAPIQuery();
  return API.get(
    "HublyAPI",
    `/hubs/${hub.id}/clientWorkflows/?limit=${limit}&offset=${offset}`,
    req
  );
};

export const CreateClientWorkflow = async (request) => {
  const req = await getAPIReq(request);
  return SyncedAPI.post("HublyAPI", `/clientWorkflows/`, req, (response) => {
    return [
      { type: "SET_CLIENT_WORKFLOW", clientWorkflow: response },
      {
        type: "ADD_WORKFLOW_CLIENT_WORKFLOW",
        workflowId: response.workflowId,
        clientWorkflowId: response.id,
      },
    ];
  });
};

export const UpdateClientWorkflowSortOrder = async (
  clientWorkflowId,
  request
) => {
  const req = await getAPIReq(request);
  return SyncedAPI.put(
    "HublyAPI",
    `/clientWorkflows/${clientWorkflowId}/updateSortOrder/`,
    req
  );
};

export const UpdateClientWorkflowBulkSortOrder = async (request) => {
  const req = await getAPIReq(request);
  return SyncedAPI.put(
    "HublyAPI",
    `/clientWorkflows/updateBulkSortOrder/`,
    req
  );
};

export const DeleteClientWorkflow = async (clientWorkflowId, workflowId) => {
  const req = await getAPIReq();
  return SyncedAPI.del(
    "HublyAPI",
    `/clientWorkflows/${clientWorkflowId}/`,
    req,
    () => {
      return [
        { type: "DELETE_CLIENT_WORKFLOW", clientWorkflowId: clientWorkflowId },
        {
          type: "DELETE_WORKFLOW_CLIENT_WORKFLOW",
          workflowId: workflowId,
          clientWorkflowId: clientWorkflowId,
        },
      ];
    }
  );
};

export const BulkAddClientWorkflows = async (request) => {
  const req = await getAPIReq(request);
  return SyncedAPI.post("HublyAPI", `/clientWorkflows/bulkAdd/`, req);
};

export const BulkUpdateClientWorkflows = async (request) => {
  const req = await getAPIReq(request);
  return SyncedAPI.put(
    "HublyAPI",
    `/clientWorkflows/bulkUpdate/`,
    req,
    (response) => {
      return {
        type: "BULK_UPDATE_CLIENT_WORKFLOWS",
        clientWorkflows: response,
      };
    }
  );
};

export const EditClientWorkflow = async (id, request) => {
  const req = await getAPIReq(request);
  return SyncedAPI.put(
    "HublyAPI",
    `/clientWorkflows/${id}/`,
    req,
    (response) => {
      return { type: "SET_CLIENT_WORKFLOW", clientWorkflow: response };
    }
  );
};

export const MoveClientWorkflow = async (id, request) => {
  const req = await getAPIReq(request);
  return SyncedAPI.post("HublyAPI", `/clientWorkflows/${id}/move/`, req);
};

export const GetClientWorkflowsByClientID = async (
  hub,
  clientId,
  limit = 100,
  offset = 0
) => {
  const req = await getAPIQuery();
  return API.get(
    "HublyAPI",
    `/hubs/${hub.id}/clients/${clientId}/workflows/?limit=${limit}&offset=${offset}`,
    req
  );
};
