import React from "react";
import PropTypes from "prop-types";

import connect from "react-redux/es/connect/connect";

import { withRouter } from "react-router";

import { SetAlert } from "components/Alerts/actions";

import {
  HubContextProvider,
  ToasterContextProvider,
} from "scenes/Hubly/components/Workspace/Provider";

export function Connector({ hub, advisor, fireToaster, children }) {
  return (
    <HubContextProvider
      hubId={hub.id}
      hubShortId={hub.hubId}
      hubClientPoolId={hub.clientPool.id}
      sortByLastName={hub.sortByLastName}
      hubAdvisor={advisor}
      advisors={hub.advisors}
    >
      <ToasterContextProvider fireToaster={fireToaster}>
        {children}
      </ToasterContextProvider>
    </HubContextProvider>
  );
}

Connector.propTypes = {
  advisor: PropTypes.object.isRequired,
  hub: PropTypes.object.isRequired,
  fireToaster: PropTypes.func.isRequired,
  children: PropTypes.node,
};

Connector.defaultProps = {
  children: null,
};

const mapStateToProps = (state) => {
  return {
    advisor: state.hubly.data.advisor,
    hub: state.hubly.data.hub.selected.hub,
    loading: state.hubly.data.hub.selected.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fireToaster: (alert) => {
      dispatch(SetAlert(alert));
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Connector)
);
