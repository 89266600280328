import React, { useState } from "react";
import PropTypes from "prop-types";
import { Input } from "semantic-ui-react";
import Selector from "components/Selector";
import {
  getFrequencyOptions,
  getIntervalMultiplierFromBeforeAfter,
  getOnBeforeAfterValue,
  onBeforeAfterOptions,
} from "../../../../helper";

export function AgeSelector({ name, value, onChange }) {
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  const { age, interval, frequency } = value || {};

  const [onBeforeAfter, setOnBeforeAfter] = useState(
    getOnBeforeAfterValue(interval)
  );

  const updateValue = (val) => {
    onChange(null, {
      name: name,
      value: {
        ...value,
        ...val,
      },
    });
  };

  return (
    <React.Fragment>
      <div
        style={{ display: "flex", alignItems: "center", marginBottom: "1em" }}
      >
        {onBeforeAfter !== "ON" && (
          <React.Fragment>
            <div style={{ marginRight: "1em" }}>
              <Input
                name="interval"
                value={Math.abs(interval) || ""}
                placeholder="#"
                style={{ width: "5em" }}
                onChange={(_, { value: val }) => {
                  updateValue({
                    interval:
                      val !== ""
                        ? parseInt(val) *
                          getIntervalMultiplierFromBeforeAfter(onBeforeAfter)
                        : val,
                  });
                }}
              />
            </div>
            <div style={{ marginRight: "1em" }}>
              <Selector
                name="frequency"
                dropdownProps={{
                  search: false,
                  compact: true,
                }}
                selection={getFrequencyOptions(interval, {
                  withYear: false,
                  withDay: true,
                })}
                multiple={false}
                selected={frequency}
                onSelect={(val) => {
                  updateValue({ frequency: val });
                }}
              />
            </div>
          </React.Fragment>
        )}
        <div style={{ marginRight: "1em" }}>
          <Selector
            name="onBeforeAfter"
            dropdownProps={{
              search: false,
              compact: true,
              style: { width: "7em" },
            }}
            selection={onBeforeAfterOptions}
            multiple={false}
            selected={onBeforeAfter}
            onSelect={(val) => {
              setOnBeforeAfter(val);
              const updatedInterval =
                getIntervalMultiplierFromBeforeAfter(val) *
                Math.abs(interval || 1);
              if (val === "ON") {
                updateValue({
                  interval: updatedInterval,
                  frequency: undefined,
                });
              } else {
                updateValue({
                  interval: updatedInterval,
                  frequency: !frequency ? "MONTHLY" : frequency,
                });
              }
            }}
          />
        </div>

        <div style={{ marginRight: "1em" }}>their</div>
      </div>
      <div
        style={{ display: "flex", alignItems: "center", marginBottom: "1em" }}
      >
        <div style={{ marginRight: "1em" }}>
          <Input
            style={{ width: "5em" }}
            name="age"
            value={age}
            placeholder="age"
            onChange={(_, { value: val }) => {
              updateValue({ age: val !== "" ? parseInt(val) : val });
            }}
          />
        </div>
        <div>birthday.</div>
      </div>
    </React.Fragment>
  );
}

AgeSelector.propTypes = {
  name: PropTypes.string,
  value: PropTypes.object,
  onChange: PropTypes.func,
};
AgeSelector.defaultProps = {
  name: "",
  value: null,
  onChange: () => {},
};

export default AgeSelector;
