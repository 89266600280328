import React from "react";
import PropTypes from "prop-types";

import { Icon } from "semantic-ui-react";

import PureNewTaskReminder from "./PureNewTaskReminder";
import PureExistingTaskReminder from "./PureExistingTaskReminder";
import "scenes/Hubly/Hubly.css";

const PureTaskReminder = ({
  taskTitle,
  hasReminder,
  overdueInfo,
  setSelectedDate,
  selectedDate,
  saveReminder,
  addingTask,
  openReminderRescheduleModal,
  dismissReminder,
  hasSequentialReminder,
}) => {
  const taskTitleAlt = taskTitle.length > 0 ? taskTitle : "emptyTask";

  if (hasReminder && !addingTask) {
    return (
      <PureExistingTaskReminder
        taskTitle={taskTitleAlt}
        overdueInfo={overdueInfo}
        dismissReminder={dismissReminder}
        openReminderRescheduleModal={openReminderRescheduleModal}
      />
    );
  }

  if (hasSequentialReminder) {
    return (
      <Icon.Group title="This task has a sequential reminder saved">
        <Icon link color="grey" name="bell" />
        <Icon
          link
          corner
          name="clock"
          style={{ color: "#aaaaaa", textShadow: "none" }}
        />
      </Icon.Group>
    );
  }

  return (
    <PureNewTaskReminder
      taskTitle={taskTitleAlt}
      overdueInfo={overdueInfo}
      hasReminder={hasReminder}
      selectedDate={selectedDate}
      setSelectedDate={setSelectedDate}
      saveReminder={saveReminder}
    />
  );
};

PureTaskReminder.propTypes = {
  taskTitle: PropTypes.string,
  hasReminder: PropTypes.bool.isRequired,
  hasSequentialReminder: PropTypes.bool,
  overdueInfo: PropTypes.object,
  setSelectedDate: PropTypes.func.isRequired,
  selectedDate: PropTypes.object.isRequired,
  saveReminder: PropTypes.func.isRequired,
  addingTask: PropTypes.bool,
  openReminderRescheduleModal: PropTypes.func.isRequired,
  dismissReminder: PropTypes.func.isRequired,
};

PureTaskReminder.defaultProps = {
  taskTitle: "",
  overdueInfo: {},
  addingTask: false,
  hasSequentialReminder: false,
};

export default PureTaskReminder;
