import React from "react";
import PropTypes from "prop-types";

import nth from "./helper.";
import { getOnBeforeAfterValue } from "../../../../../../helper";

export function AgeRuleText({ workflowRule }) {
  const { age } = workflowRule;

  const beforeOrAfter = getOnBeforeAfterValue(workflowRule.interval);

  const renderFrequency = ({ frequency }) => {
    if (!frequency) return "";

    const frequencyTypes = {
      daily: { text: "day", plural: "days" },
      weekly: { text: "week", plural: "weeks" },
      monthly: { text: "month", plural: "months" },
    };

    return frequencyTypes[frequency.toLowerCase()];
  };

  const renderText = (interval, frequency) => {
    const intervalVal = Math.abs(interval);
    if (interval === 0) {
      return ` will next be added on their ${age}${nth(age)} birthday`;
    }
    const fKey = interval >= 2 ? "plural" : "text";
    return ` will next be added ${intervalVal} ${
      frequency[fKey]
    } ${beforeOrAfter} their ${age}${nth(age)} birthday`;
  };

  return (
    <React.Fragment>
      {renderText(workflowRule.interval, renderFrequency(workflowRule))}
    </React.Fragment>
  );
}
AgeRuleText.propTypes = {
  workflowRule: PropTypes.object,
};

AgeRuleText.defaultProps = {
  workflowRule: {},
};
export default AgeRuleText;
