import {
  Button,
  Dropdown,
  Input,
  Modal,
  Icon,
  Checkbox,
} from "semantic-ui-react";
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker/es";
import { SetEditWorkflowRule } from "../actions";
import RuleClientsDropdown from "./RuleClientsDropdown";
import ExcludeItems from "./ExcludeItems";
import {
  getStreamsFromSelectedItems,
  getTagsFromSelectedItems,
  getClientsFromSelectedItems,
  parseEditWorkflowClients,
  parseBackendStreams,
  parseEditWorkflowTags,
  parseBackendTags,
  filterOptionsFromSelectedItems,
} from "../helpers";
import IncludeInfoPopup from "./IncludeInfoPopup";

class RepeatingRulesModal extends React.Component {
  static propTypes = {
    hub: PropTypes.shape({
      streams: PropTypes.array.isRequired,
    }).isRequired,
    editWorkflowRule: PropTypes.shape({
      id: PropTypes.string,
      dateStart: PropTypes.string,
      interval: PropTypes.number,
      frequency: PropTypes.string,
      onlyHouseholdHead: PropTypes.bool,
      repeat: PropTypes.string,
      streams: PropTypes.array,
      tags: PropTypes.array,
      streamsExclude: PropTypes.array,
      tagsExclude: PropTypes.array,
      clientsExclude: PropTypes.array,
      weekStartDay: PropTypes.string,
    }).isRequired,
    save: PropTypes.func.isRequired,
    setEditWorkflowRule: PropTypes.func.isRequired,
    setWorkflowRuleModal: PropTypes.func.isRequired,
    tags: PropTypes.object.isRequired,
    update: PropTypes.func.isRequired,
    allClients: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);

    const { editWorkflowRule, allClients } = props;
    const {
      streams = [],
      tags = [],
      streamsExclude = [],
      tagsExclude = [],
      clientsExclude = [],
    } = editWorkflowRule;

    this.days = [
      { value: "MO", key: "MONDAY", text: "Mondays" },
      { value: "TU", key: "TUESDAY", text: "Tuesdays" },
      { value: "WE", key: "WEDNESDAY", text: "Wednesdays" },
      { value: "TH", key: "THURSDAY", text: "Thursdays" },
      { value: "FR", key: "FRIDAY", text: "Fridays" },
      { value: "SA", key: "SATURDAY", text: "Saturdays" },
      { value: "SU", key: "SUNDAY", text: "Sundays" },
    ];
    this.tomorrow = new Date();
    this.tomorrow.setDate(this.tomorrow.getDate() + 1);

    const defaultStreams = parseBackendStreams(streams);
    const defaultTags = parseEditWorkflowTags(tags, props.tags);

    const defaultExcludeStreams = parseBackendStreams(streamsExclude);
    const defaultExcludeTags = parseEditWorkflowTags(tagsExclude, props.tags);
    const defaultExcludeClients = parseEditWorkflowClients(
      clientsExclude,
      allClients
    );

    this.state = {
      dateStart: null,
      editedFrequency: false,
      editedInterval: false,
      editedOnlyHouseholdHead: false,
      editedRepeat: false,
      editedWeekStartDay: false,
      frequency: null,
      interval: 1,
      loading: false,
      onlyHouseholdHead: false,
      repeat: null,
      selectedItems: [...defaultStreams, ...defaultTags],
      selectedExcludeItems: [
        ...defaultExcludeClients,
        ...defaultExcludeStreams,
        ...defaultExcludeTags,
      ],
      weekStartDay: null,
    };
  }

  getStreamOptions = () => {
    const { hub } = this.props;
    return parseBackendStreams(
      hub?.streams?.sort((a, b) => {
        return a.name > b.name ? 1 : -1;
      })
    );
  };

  getTagOptions = () => {
    const { tags } = this.props;
    return parseBackendTags(
      Object.values(tags)?.sort((a, b) => {
        return a.name > b.name ? 1 : -1;
      })
    );
  };

  handleDateStartChange = (date) => {
    this.setState({
      dateStart: date,
    });
  };

  handleChange = (e, { name, value }) => {
    this.setState((state) => {
      return {
        [name]: value,
        editedFrequency: state.editedFrequency || name === "frequency",
        editedInterval: state.editedInterval || name === "interval",
        editedStreams: state.editedStream || name === "streams",
        editedRepeat: state.editedRepeat || name === "repeat",
        editedWeekStartDay: state.editedWeekStartDay || name === "weekStartDay",
      };
    });
    // Set default values for EVERY to WEEKLY and MO
    if (name === "repeat" && value === "EVERY") {
      this.setState({
        editedFrequency: true,
        frequency: "YEARLY",
        editedWeekStartDay: true,
        weekStartDay: null,
      });
    }
    // Unset for ONCE
    if (name === "repeat" && value === "ONCE") {
      this.setState({
        editedFrequency: true,
        frequency: null,
        editedWeekStartDay: true,
        weekStartDay: null,
      });
    }
    // Set default values for WEEKLY to MO
    if (name === "frequency" && value === "WEEKLY") {
      this.setState({ editedWeekStartDay: true, weekStartDay: "MO" });
    }
    // Unset weekStartDay for MONTHLY or YEARLY
    if (name === "frequency" && (value === "MONTHLY" || value === "YEARLY")) {
      this.setState({ editedWeekStartDay: true, weekStartDay: null });
    }
  };

  isValidInput = () => {
    const { selectedItems } = this.state;

    // Differing valid input if editing or adding
    return selectedItems && selectedItems.length > 0;
  };

  toggleOnlyHouseholdHead = () => {
    const { editWorkflowRule } = this.props;
    this.setState((state) => {
      // Editing the rule and checkbox has not be clicked, need to set the initial state based on editWorkflowRule.onlyHouseholdHead
      if (
        "onlyHouseholdHead" in editWorkflowRule &&
        !state.editedOnlyHouseholdHead
      ) {
        return {
          onlyHouseholdHead: !editWorkflowRule.onlyHouseholdHead,
          editedOnlyHouseholdHead: true,
        };
      } else {
        // Adding a rule or checkbox already edited, just use the state value
        return {
          onlyHouseholdHead: !state.onlyHouseholdHead,
          editedOnlyHouseholdHead: true,
        };
      }
    });
  };

  addRule = () => {
    const { save, setEditWorkflowRule, setWorkflowRuleModal } = this.props;
    const {
      dateStart,
      frequency,
      interval,
      onlyHouseholdHead,
      repeat,
      selectedItems,
      weekStartDay,
      selectedExcludeItems,
    } = this.state;
    this.setState({ loading: true });

    // Set the time to be before the lambda cron, so it will run that morning
    const dateBeforeLambda = dateStart || this.tomorrow;
    dateBeforeLambda.setHours(7, 15, 0, 0);
    const request = {
      dateStart: dateBeforeLambda.toISOString(),
      frequency: repeat || frequency ? frequency : "YEARLY",
      interval: interval,
      onlyHouseholdHead: onlyHouseholdHead,
      repeat: repeat || "EVERY",
      streams: getStreamsFromSelectedItems(selectedItems),
      tags: getTagsFromSelectedItems(selectedItems),
      streamsExclude: getStreamsFromSelectedItems(selectedExcludeItems),
      tagsExclude: getTagsFromSelectedItems(selectedExcludeItems),
      clientsExclude: getClientsFromSelectedItems(selectedExcludeItems),
      weekStartDay: weekStartDay,
    };
    save(request, (success) => {
      if (success) {
        setWorkflowRuleModal(false);
        setEditWorkflowRule({});
      }
      this.setState({ loading: false });
    });
  };

  updateRule = () => {
    const {
      editWorkflowRule,
      setEditWorkflowRule,
      setWorkflowRuleModal,
      update,
    } = this.props;
    const {
      dateStart,
      editedFrequency,
      editedInterval,
      editedOnlyHouseholdHead,
      editedRepeat,
      editedWeekStartDay,
      frequency,
      interval,
      onlyHouseholdHead,
      repeat,
      selectedItems,
      weekStartDay,
      selectedExcludeItems,
    } = this.state;
    this.setState({ loading: true });

    // Set the time to be before the lambda cron, so it will run that morning
    const dateBeforeLambda = dateStart || new Date(editWorkflowRule.dateStart);
    dateBeforeLambda.setHours(7, 15, 0, 0);
    const workflowRule = {
      id: editWorkflowRule.id,
      dateStart: dateBeforeLambda.toISOString(),
      frequency: editedFrequency ? frequency : editWorkflowRule.frequency,
      interval: editedInterval ? interval : editWorkflowRule.interval,
      onlyHouseholdHead: editedOnlyHouseholdHead
        ? onlyHouseholdHead
        : editWorkflowRule.onlyHouseholdHead,
      repeat: editedRepeat ? repeat : editWorkflowRule.repeat,
      streams: getStreamsFromSelectedItems(selectedItems),
      tags: getTagsFromSelectedItems(selectedItems),
      streamsExclude: getStreamsFromSelectedItems(selectedExcludeItems),
      tagsExclude: getTagsFromSelectedItems(selectedExcludeItems),
      clientsExclude: getClientsFromSelectedItems(selectedExcludeItems),
      type: "REPEATING_RULE",
      weekStartDay: editedWeekStartDay
        ? weekStartDay
        : editWorkflowRule.weekStartDay,
    };
    update(workflowRule, (success) => {
      if (success) {
        setWorkflowRuleModal(false);
        setEditWorkflowRule({});
      }
      this.setState({ loading: false });
    });
  };

  render() {
    const { days, tomorrow } = this;
    const { editWorkflowRule, setEditWorkflowRule, setWorkflowRuleModal } =
      this.props;
    const {
      dateStart,
      editedInterval,
      frequency,
      interval,
      loading,
      onlyHouseholdHead,
      repeat,
      selectedItems,
      selectedExcludeItems,
    } = this.state;
    const selectedItemValues = [];
    selectedItems.forEach((item) => {
      selectedItemValues.push(item.key);
    });

    let defaultDate = tomorrow;
    if (editWorkflowRule.id) {
      defaultDate = new Date(editWorkflowRule.dateStart);
    }

    const intervalOptions = [];
    for (let i = 1; i <= 12; i += 1) {
      intervalOptions.push({ key: i, text: i, value: i });
    }

    // We do the filtering separately because the Dropdown options attributes requires all options to be included
    // Otherwise, when searching the already selected streams would no longer display in the input box
    const streamOptions = this.getStreamOptions();
    const tagOptions = this.getTagOptions();
    const isRepeatEvery =
      repeat === "EVERY" || (!repeat && editWorkflowRule.repeat !== "ONCE");
    return (
      <Modal open size="tiny" data-test="repeatingRule-modal">
        <Modal.Header>
          <Icon
            name="delete"
            link
            color="grey"
            style={{ float: "right", position: "relative" }}
            onClick={() => {
              setWorkflowRuleModal(false);
              setEditWorkflowRule({});
            }}
          />
          Repeating Rule
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ marginRight: "0.5em", width: "150px" }}>
                  Add clients with
                </div>
                <RuleClientsDropdown
                  tagOptions={filterOptionsFromSelectedItems(
                    tagOptions,
                    selectedExcludeItems,
                    "tag"
                  )}
                  streamOptions={filterOptionsFromSelectedItems(
                    streamOptions,
                    selectedExcludeItems,
                    "stream"
                  )}
                  selectedItems={selectedItems}
                  setSelectedItems={(newSelectedItems) => {
                    this.setState({ selectedItems: newSelectedItems });
                  }}
                  data-test="ruleModal-dropdown-condition"
                  fluid
                />
                <div style={{ marginLeft: "0.5em" }}>
                  <IncludeInfoPopup />
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  <div style={{ marginTop: "1em" }}>
                    <Dropdown
                      placeholder="Repeat"
                      selection
                      compact
                      options={[
                        { key: "once", text: "Once", value: "ONCE" },
                        { key: "every", text: "Every", value: "EVERY" },
                      ]}
                      style={{ marginRight: "1em" }}
                      onChange={this.handleChange}
                      name="repeat"
                      defaultValue={editWorkflowRule.repeat || "EVERY"}
                    />
                  </div>
                  {isRepeatEvery && (
                    <React.Fragment>
                      <div style={{ marginTop: "1em" }}>
                        <Dropdown
                          placeholder="Interval"
                          selection
                          options={intervalOptions}
                          onChange={this.handleChange}
                          name="interval"
                          compact
                          style={{ marginRight: "1em" }}
                          defaultValue={editWorkflowRule.interval || 1}
                        />
                      </div>
                      <div style={{ marginTop: "1em" }}>
                        <Dropdown
                          placeholder="Week or Month"
                          selection
                          options={[
                            {
                              value: "MONTHLY",
                              key: "MONTHLY",
                              text:
                                interval >= 2 ||
                                (!editedInterval &&
                                  editWorkflowRule.interval >= 2)
                                  ? "Months"
                                  : "Month",
                            },
                            {
                              value: "WEEKLY",
                              key: "WEEKLY",
                              text:
                                interval >= 2 ||
                                (!editedInterval &&
                                  editWorkflowRule.interval >= 2)
                                  ? "Weeks"
                                  : "Week",
                            },
                            {
                              value: "YEARLY",
                              key: "YEARLY",
                              text:
                                interval >= 2 ||
                                (!editedInterval &&
                                  editWorkflowRule.interval >= 2)
                                  ? "Years"
                                  : "Year",
                            },
                          ]}
                          onChange={this.handleChange}
                          name="frequency"
                          compact
                          style={{ marginRight: "1em" }}
                          defaultValue={editWorkflowRule.frequency || "YEARLY"}
                        />
                      </div>
                      {(frequency === "WEEKLY" ||
                        (!frequency &&
                          editWorkflowRule.frequency === "WEEKLY")) && (
                        <div style={{ marginTop: "1em" }}>
                          on
                          <Dropdown
                            placeholder="Day"
                            selection
                            options={days}
                            style={{ marginLeft: "1em" }}
                            onChange={this.handleChange}
                            name="weekStartDay"
                            defaultValue={editWorkflowRule.weekStartDay || "MO"}
                          />
                        </div>
                      )}
                    </React.Fragment>
                  )}
                  <div style={{ marginBottom: "1em", marginTop: "1em" }}>
                    <span style={{ marginRight: "1em" }}>
                      {isRepeatEvery ? "starting" : "on"}
                    </span>
                    <DatePicker
                      selected={dateStart || defaultDate}
                      onChange={this.handleDateStartChange}
                      customInput={<Input />}
                      minDate={tomorrow}
                    />
                  </div>
                </div>
              </div>
              <Checkbox
                defaultChecked={
                  "onlyHouseholdHead" in editWorkflowRule
                    ? editWorkflowRule.onlyHouseholdHead
                    : onlyHouseholdHead
                }
                label="Only apply to household head"
                name="onlyHouseholdHead"
                onChange={this.toggleOnlyHouseholdHead}
                style={{ marginBottom: "1em" }}
              />
              <ExcludeItems
                tagOptions={filterOptionsFromSelectedItems(
                  tagOptions,
                  selectedItems,
                  "tag"
                )}
                streamOptions={filterOptionsFromSelectedItems(
                  streamOptions,
                  selectedItems,
                  "stream"
                )}
                selectedItems={selectedExcludeItems}
                setSelectedItems={(newSelectedItems) => {
                  this.setState({ selectedExcludeItems: newSelectedItems });
                }}
                data-test="ruleModal-dropdown-exclude"
              />
            </div>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            disabled={!this.isValidInput() || loading}
            loading={loading}
            content={editWorkflowRule.id ? "Edit Rule" : "Add Rule"}
            onClick={editWorkflowRule.id ? this.updateRule : this.addRule}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    hub: state.hubly.data.hub.selected.hub,
    editWorkflowRule: state.hubly.scenes.hubly.workflowRules.editWorkflowRule,
    tags: state.hubly.data.hub.tags,
    workflows: state.hubly.data.hub.workflows,
    allClients: state.hubly.data.hub.clients.allClientNames,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setEditWorkflowRule: (workflowRule) => {
      dispatch(SetEditWorkflowRule(workflowRule));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RepeatingRulesModal);
