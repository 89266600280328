import React from "react";
import PropTypes from "prop-types";
import { Icon } from "semantic-ui-react";
import ReminderSection from "./ReminderSection";
import TotalClientSection from "./TotalClientSection";
import TotalWorkflowSection from "./TotalWorkflowSection";

export const SummaryRow = ({
  active,
  opened,
  onClick,
  totalClients,
  totalWorkflows,
  filteredReminderCounts,
}) => {
  const ChevronIcon = () => {
    return (
      <div
        style={{
          color: "black",
          fontWeight: "bold",
        }}
      >
        <Icon
          data-test={`workflow-process-chevron-${opened ? "down" : "right"}`}
          name={opened ? "chevron down" : "chevron right"}
          style={{ fontSize: "15pt" }}
        />
      </div>
    );
  };
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        alignItems: "center",
        opacity: active ? "1" : "0.5",
      }}
      onClick={onClick}
    >
      {totalWorkflows > 0 && <ChevronIcon opened={opened} />}
      <TotalWorkflowSection
        data-test="process-workflows-total"
        totalWorkflows={totalWorkflows}
      />
      <TotalClientSection
        data-test="process-clients-total"
        totalClients={totalClients}
        style={{ justifyContent: "center" }}
      />
      <ReminderSection
        style={{ justifyContent: "flex-end" }}
        {...filteredReminderCounts}
      />
    </div>
  );
};

SummaryRow.defaultProps = {
  active: true,
  opened: false,
  onClick: () => {},
  totalClients: 0,
  totalWorkflows: 0,
  filteredReminderCounts: {
    totalOverdue: 0,
    totalComingDue: 0,
    totalUpcoming: 0,
  },
};

SummaryRow.propTypes = {
  active: PropTypes.bool,
  opened: PropTypes.bool,
  onClick: PropTypes.func,
  totalClients: PropTypes.number,
  totalWorkflows: PropTypes.number,
  filteredReminderCounts: PropTypes.shape({
    totalOverdue: PropTypes.number,
    totalComingDue: PropTypes.number,
    totalUpcoming: PropTypes.number,
  }),
};

export default SummaryRow;
