import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import SetConfirmationModal from "components/ConfirmationModal/actions";
import { renderIntegration } from "./integrations";

class ZapierIntegration extends React.Component {
  static propTypes = {
    setConfirmationModal: PropTypes.func.isRequired,
  };

  state = {
    loading: false,
  };

  disconnect = () => {
    this.setState({ loading: true });
    // TODO disconnect from zapier!
    // Invalidate key or something..
    this.setState({ loading: false });
  };

  confirmDisconnect = () => {
    const { setConfirmationModal } = this.props;
    const params = {
      title: "Disconnect Zapier",
      message:
        "Are you sure you wish to disconnect from Zapier? This will remove all its features and settings.",
      icon: "delete",
      buttons: [
        { text: "Cancel" },
        {
          text: "Disconnect",
          callBack: this.disconnect,
          color: "red",
        },
      ],
    };
    setConfirmationModal(params);
  };

  goToDocumentation = () => {
    const win = window.open(
      "https://zapier.com/learn/getting-started-guide/",
      "_blank"
    );
    win.focus();
  };

  goToConnections = () => {
    const win = window.open("https://zapier.com/app/connections", "_blank");
    win.focus();
  };

  goToIntegrate = () => {
    let url =
      "https://zapier.com/developer/public-invite/48312/dd72a5d7f56e9b01ca67343cb95333b0/"; // zapier prod URL
    if (
      process.env.REACT_APP_STAGE === "dev" ||
      process.env.REACT_APP_STAGE === "local"
    ) {
      url =
        "https://zapier.com/developer/public-invite/48309/a9ae0e5754ff20d5fbd1d22a9bee0d03/"; // zapier dev URL
    } else if (process.env.REACT_APP_STAGE === "pull-request") {
      url =
        "https://zapier.com/developer/public-invite/48311/60b4935c462adcd99dcf8877c0df6ca8/"; // zapier pull-request URL
    } else if (process.env.REACT_APP_STAGE === "staging") {
      url =
        "https://zapier.com/developer/public-invite/48308/0338b4eeecefa05fc8cf1f9aee465442/"; // zapier staging URL
    }
    const win = window.open(url, "_blank");
    win.focus();
  };

  render() {
    const { loading } = this.state;
    const options = [
      {
        content: "Documentation",
        icon: "external alternate",
        onClick: this.goToDocumentation,
      },
      {
        content: "Manage Connection",
        icon: "external alternate",
        onClick: this.goToConnections,
      },
      {
        content: "Integrate",
        icon: "external alternate",
        onClick: this.goToIntegrate,
      },
    ];
    return renderIntegration(
      "Zapier",
      "/images/logos/zapier-logo.png",
      options,
      loading
    );
  }
}

const mapStateToProps = (state) => {
  return {
    advisor: state.hubly.data.advisor,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setConfirmationModal: (params) => {
      dispatch(SetConfirmationModal(params));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ZapierIntegration);
