import { combineReducers } from "redux";

import { reducer as clientsReducer } from "./clients/reducer";
import {
  clientWorkflowLoader,
  reducer as clientWorkflowsReducer,
} from "./clientWorkflows/reducer";
import { reducer as clientCardReducer } from "./clientCard/reducer";
import {
  reducer as householdsReducer,
  householdLoader,
} from "./households/reducer";
import { reducer as tagsReducer } from "./tags/reducer";
import { reducer as workflowsReducer } from "./workflows/reducer";

export const initialState = {
  inProgress: false,
  loading: false,
  percent: 0,
  hub: null,
  pendingLoadResources: false,
};

const hubReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_PENDING_LOAD_RESOURCES":
      return {
        ...state,
        pendingLoadResources: action.set,
      };
    case "SET_HUB_LOADING":
      return {
        ...state,
        loading: action.loading,
      };
    case "SET_IN_PROGRESS":
      return {
        ...state,
        inProgress: action.inProgress,
        percent: action.percent,
      };
    case "SET_HUB":
      return {
        ...state,
        hub: action.hub,
      };
    case "SET_HUB_PREFERENCES":
      return {
        ...state,
        hub: {
          ...state.hub,
          preferences: { ...state.hub.preferences, ...action.preferences },
        },
      };
    case "ADD_STREAM":
      try {
        const streams = [...state.hub.streams];
        streams.push(action.stream);
        const nextState = { ...state };
        nextState.hub.streams = streams;
        return nextState;
      } catch (e) {
        console.error(e);
        return state;
      }
    case "EDIT_STREAM":
      try {
        const streams = [...state.hub.streams];
        const index = streams.findIndex((s) => {
          return s.id === action.stream.id;
        });
        streams[index] = action.stream;
        const nextState = { ...state };
        nextState.hub.streams = streams;
        return nextState;
      } catch (e) {
        console.error(e);
        return state;
      }
    case "REMOVE_STREAM":
      try {
        const streams = [...state.hub.streams].filter((s) => {
          return s.id !== action.streamId;
        });
        const nextState = { ...state };
        nextState.hub.streams = streams;
        return nextState;
      } catch (e) {
        console.error(e);
        return state;
      }
    default:
      return state;
  }
};

export const reducer = combineReducers({
  clients: clientsReducer,
  clientWorkflowLoader: clientWorkflowLoader,
  clientCard: clientCardReducer,
  clientWorkflows: clientWorkflowsReducer,
  households: householdsReducer,
  householdLoader: householdLoader,
  selected: hubReducer,
  tags: tagsReducer,
  workflows: workflowsReducer,
});

export default reducer;
