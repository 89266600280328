import React from "react";
import PropTypes from "prop-types";

import { Icon, Popup } from "semantic-ui-react";

export function TileIcon({ dotColor, icon, text, popup, color, ...props }) {
  const TheIcon = () => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginRight: "1.5em",
        }}
        {...props}
      >
        <div>
          <Icon
            name={icon}
            color={color === "green" ? "grey" : color}
            style={{ margin: 0, fontSize: "14pt" }}
            fitted
          />
        </div>
        <div style={{ color: "grey", fontSize: "10pt", marginLeft: "0.25em" }}>
          <span data-test={`tile-icon-text-${icon}`}>{text}</span>
          {dotColor && (
            <Icon
              color={dotColor}
              name="circle"
              size="tiny"
              style={{ transform: "translate(2px, -2px)" }}
            />
          )}
        </div>
      </div>
    );
  };

  if (!popup) {
    return <TheIcon />;
  }

  return (
    <Popup
      data-test="tile-icon-tooltip"
      basic
      hoverable
      on="hover"
      position="bottom center"
      popperModifiers={{ preventOverflow: { boundariesElement: "window" } }}
      content={popup}
      trigger={TheIcon()}
    />
  );
}

TileIcon.propTypes = {
  dotColor: PropTypes.string,
  icon: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  popup: PropTypes.node,
  color: PropTypes.string,
};

TileIcon.defaultProps = {
  dotColor: undefined,
  popup: null,
  color: "grey",
};

export default TileIcon;
