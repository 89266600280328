import React from "react";
import { connect } from "react-redux";
import { Auth } from "aws-amplify";
import { isEmail } from "validator";
import Anchor from "components/Anchor";
import PropTypes from "prop-types";
import { Button, Form, Input, Message } from "semantic-ui-react";
import { ForgotPassword } from "aws-amplify-react";
import LoginContainer from "./LoginContainer";
import {
  isPasswordValid,
  SetLoginErrorMessage,
  SetLoginInfoMessage,
} from "../actions";

class HublyForgotPassword extends ForgotPassword {
  static propTypes = {
    setLoginErrorMessage: PropTypes.func.isRequired,
  };

  state = {
    emailLabel: false,
    passwordLabel: false,
    codeLabel: false,
    loading: false,
  };

  send = () => {
    const { authData = {}, setLoginInfoMessage } = this.props;
    this.inputs.username = (this.inputs.username || "").toLowerCase();
    const username = this.inputs.username || authData.username;
    if (!Auth || typeof Auth.forgotPassword !== "function") {
      throw new Error(
        "No Auth module found, please ensure @aws-amplify/auth is imported"
      );
    }

    Auth.forgotPassword(username)
      .then((data) => {
        this.setState({
          delivery: data.CodeDeliveryDetails,
          loading: false,
        });
        setLoginInfoMessage(
          <React.Fragment>
            <Message.Header>Verification Code Sent</Message.Header>
            <p>{`We’ve sent a new six-digit verification code to ${username.toLowerCase()}.`}</p>
          </React.Fragment>
        );
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });

        return this.error(err);
      });
  };

  submit() {
    const { authData = {} } = this.props;
    const { code, password } = this.inputs;
    const username = this.inputs.username || authData.username;

    if (!Auth || typeof Auth.forgotPasswordSubmit !== "function") {
      throw new Error(
        "No Auth module found, please ensure @aws-amplify/auth is imported"
      );
    }

    Auth.forgotPasswordSubmit(username, code, password)
      .then((data) => {
        this.changeState("signIn");
        this.setState({
          delivery: null,
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });

        return this.error(err);
      });
  }

  submitPassword = () => {
    const { setLoginErrorMessage, setLoginInfoMessage } = this.props;
    setLoginErrorMessage("");
    setLoginInfoMessage(null);
    this.setState({
      loading: true,
    });

    const { code, password } = this.inputs;
    let valid = true;
    let message = "";
    if (!code || code.length < 1) {
      message = message.concat("Invalid code. ");
      valid = false;
    }
    if (!password || password.length < 1 || !isPasswordValid(password)) {
      message = message.concat(
        "Invalid password, must be 8 or more characters long," +
          " contain at least one Uppercase letter, one Lowercase letter, one Special Character and one Number. "
      );
      valid = false;
    }
    if (!valid) {
      setLoginErrorMessage(message);

      this.setState({
        loading: false,
      });
      return;
    }

    try {
      this.submit();
    } catch (e) {
      console.warn(e.message);
    }
  };

  sendCode = () => {
    const { loading } = this.state;
    const { setLoginErrorMessage, setLoginInfoMessage } = this.props;
    if (loading) {
      return;
    }

    setLoginErrorMessage("");
    setLoginInfoMessage(null);
    const { username } = this.inputs;
    if (!username || username.length < 1 || !isEmail(username.toLowerCase())) {
      setLoginErrorMessage("Invalid email address");
      return;
    }

    this.setState({
      emailLabel: false,
      loading: true,
    });

    try {
      this.send();
    } catch (e) {
      console.warn(e.message);

      this.setState({
        loading: false,
      });
    }
  };

  resend = () => {
    const { setLoginErrorMessage } = this.props;
    const { loading } = this.state;
    setLoginErrorMessage("");

    if (loading) {
      return;
    }

    this.setState({
      loading: true,
    });

    try {
      this.send();
    } catch (e) {
      console.warn(e.message);
    }
  };

  sendView = () => {
    const { setLoginErrorMessage, setLoginInfoMessage } = this.props;
    const { loading } = this.state;
    return (
      <Form style={{ overflow: "visible" }}>
        <Form.Field>
          <label>Email</label>
          <Input
            id="username"
            key="username"
            name="username"
            placeholder="Email"
            onChange={this.handleInputChange}
          />
        </Form.Field>
        <Button
          floated="right"
          primary
          onClick={this.sendCode}
          disabled={loading}
          loading={loading}
          data-test="reset-password-button"
        >
          Reset
        </Button>
        <Button
          basic
          onClick={() => {
            setLoginErrorMessage("");
            setLoginInfoMessage(null);
            this.changeState("signIn");
          }}
        >
          Cancel
        </Button>
      </Form>
    );
  };

  submitView = () => {
    const { setLoginErrorMessage, setLoginInfoMessage } = this.props;
    const { loading } = this.state;
    return (
      <Form style={{ overflow: "visible" }}>
        <Form.Field>
          <label>Reset Code</label>
          <Input
            id="code"
            key="code"
            name="code"
            placeholder="Code"
            autoComplete="off"
            onChange={this.handleInputChange}
          />
        </Form.Field>
        <Form.Field>
          <label>New Password</label>
          <Input
            id="password"
            key="password"
            name="password"
            type="password"
            placeholder="New Password"
            onChange={this.handleInputChange}
          />
          <span style={{ fontSize: "9pt" }}>
            Passwords must be 8 or more characters long. Have at least one
            uppercase letter, one lowercase letter and one special character
          </span>
        </Form.Field>
        <Button
          floated="right"
          primary
          onClick={this.submitPassword}
          disabled={loading}
          loading={loading}
        >
          Reset
        </Button>
        <Button
          basic
          onClick={() => {
            setLoginErrorMessage("");
            setLoginInfoMessage(null);
            this.changeState("signIn");
          }}
        >
          Cancel
        </Button>
        <Anchor disabled={loading} onClick={this.resend}>
          Resend code
        </Anchor>
      </Form>
    );
  };

  showComponent() {
    const { authData = {} } = this.props;
    return (
      <LoginContainer title="Reset Your Password">
        {this.state.delivery || authData.username
          ? this.submitView()
          : this.sendView()}
      </LoginContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginErrorMessage: (message) => {
      dispatch(SetLoginErrorMessage(message));
    },
    setLoginInfoMessage: (message) => {
      dispatch(SetLoginInfoMessage(message));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HublyForgotPassword);
