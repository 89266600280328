import { useFlags } from "launchdarkly-react-client-sdk";

const NotificationsFeatureFlag = ({ children }) => {
  const showNotificationsSettings =
    useFlags()["email-notification-settings"] || false;
  if (!showNotificationsSettings) {
    return null;
  }

  return children;
};

export default NotificationsFeatureFlag;
