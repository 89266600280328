import React from "react";
import PropTypes from "prop-types";
import { Button, Icon, Responsive } from "semantic-ui-react";

function ButtonGroup({ icon, iconCorner, text, ...props }) {
  return (
    <Button basic icon={text === ""} {...props}>
      <Icon.Group>
        <Icon name={icon} />
        <Icon corner name={iconCorner} />
      </Icon.Group>
      {text && <span style={{ marginLeft: "0.428571em" }}>{text}</span>}
    </Button>
  );
}
export function TriggerButton({ icon, onClick, ...props }) {
  return (
    <React.Fragment>
      <Responsive maxWidth={1350}>
        <ButtonGroup icon={icon} onClick={onClick} />
      </Responsive>
      <Responsive minWidth={1351} style={{ whiteSpace: "nowrap" }}>
        <ButtonGroup icon={icon} onClick={onClick} {...props} />
      </Responsive>
    </React.Fragment>
  );
}

ButtonGroup.defaultProps = {
  iconCorner: "cog",
  text: "",
};

ButtonGroup.propTypes = {
  icon: PropTypes.string.isRequired,
  iconCorner: PropTypes.string,
  text: PropTypes.string,
};

TriggerButton.propTypes = {
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default TriggerButton;
