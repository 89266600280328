import { SetActiveClient } from "data/hub/clients/actions";
import { SetHub } from "data/hub/actions";
import { SetTag } from "data/hub/tags/actions";
import { SetWorkflows } from "data/hub/workflows/actions";
import { DeleteTag, EditTag } from "data/libs/tags";
import { SetAlert } from "../Alerts/actions";

export const ToggleTagModal = (tag) => {
  return {
    type: "TOGGLE_TAG_MODAL",
    tag: tag,
  };
};

export const DisableConfirmationModal = () => {
  return {
    type: "DISABLE_TAG_CONFIRMATION",
  };
};

export function EditTagAction(tag, { tagName }, callback) {
  return (dispatch, getState) => {
    const { hub } = getState().hubly.data.hub.selected;
    const { workflows } = getState().hubly.data.hub;

    const workflowsCopy = Object.values(workflows);
    const tagNameChanged = tag.name !== tagName;
    // Make sure name does not already exist (except the tag that we're editing)
    const foundTagName = hub.tags.find((s) => {
      return s.name === tagName && tagNameChanged;
    });
    if (foundTagName) {
      dispatch(
        SetAlert({
          type: "warning",
          text: `Unable to create tag. Tag name already exists.`,
        })
      );
      if (callback) callback(false);
      return;
    }

    // Need to update tags for all workflow rules
    workflowsCopy.forEach((workflow) => {
      workflow.workflowRules.forEach((workflowRule) => {
        workflowRule.tags.forEach((s, index, array) => {
          if (s.id === tag.id) {
            array[index].name = tagName;
          }
        });
      });
    });
    dispatch(SetWorkflows(workflowsCopy));

    const request = {
      name: tagName,
    };
    if (tagNameChanged) {
      EditTag(tag.id, request)
        .then((response) => {
          dispatch(SetTag(response));
          // Set state for selected hub for edit tag changes
          const hubCopy = { ...hub };
          const foundTag = hubCopy.tags.find((t) => {
            return t.id === tag.id;
          });
          foundTag.name = tagName;
          dispatch(SetHub(hubCopy));
          if (callback) callback(true);
        })
        .catch((error) => {
          dispatch(
            SetAlert({ type: "error", text: `Failed to edit tag ${tag.name}.` })
          );
          if (callback) callback(false);
        });
    } else if (callback) callback(true);
  };
}
export function DeleteTagAction(tag, callback) {
  return (dispatch, getState) => {
    const { hub } = getState().hubly.data.hub.selected;
    const { workflows } = getState().hubly.data.hub;
    const { activeClients } = getState().hubly.data.hub.clients;

    DeleteTag(tag.id)
      .then(() => {
        const hubCopy = { ...hub };
        const workflowsCopy = Object.values(workflows);
        hubCopy.tags = hubCopy.tags.filter((s) => {
          return s.id !== tag.id;
        });
        dispatch(SetHub(hubCopy));
        Object.values(activeClients).forEach((client) => {
          const clientCopy = { ...client };
          clientCopy.tags = (clientCopy.tags || []).filter((t) => {
            return t.id !== tag.id;
          });
          dispatch(SetActiveClient(clientCopy));
        });

        // Need to update tags for all workflow rules
        workflowsCopy.forEach((workflow) => {
          workflow.workflowRules.forEach((workflowRule, index, array) => {
            array[index].tags = workflowRule.tags.filter((s) => {
              return s.id !== tag.id;
            });
          });
        });
        dispatch(SetWorkflows(workflowsCopy));
        if (callback) callback(true);
      })
      .catch((error) => {
        console.error(error);
        dispatch(
          SetAlert({ type: "error", text: `Failed to delete tag ${tag.name}.` })
        );
        if (callback) callback(false);
      });
  };
}

export default ToggleTagModal;
