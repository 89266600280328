import React from "react";
import PropTypes from "prop-types";

import { Header, Dropdown } from "semantic-ui-react";

export function SelectHub({ hubs, hubIdSelection, onSelected }) {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div style={{ display: "flex" }}>
        <div style={{ flexGrow: 1 }}>
          <Header as="h5">Add Workflow To</Header>
        </div>
        <Dropdown
          selection
          search
          style={{ minWidth: "300px" }}
          options={hubs.map((h) => {
            return { key: h.id, value: h.id, text: h.name };
          })}
          value={hubIdSelection}
          onChange={(_, { value }) => {
            if (onSelected) onSelected(value);
          }}
        />
      </div>
    </div>
  );
}

SelectHub.propTypes = {
  hubs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  hubIdSelection: PropTypes.string,
  onSelected: PropTypes.func.isRequired,
};

SelectHub.defaultProps = {
  hubs: [],
  hubIdSelection: undefined,
};

export default SelectHub;
