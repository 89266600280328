import { getAPIReq } from "data/libs/authLib";
import { API } from "aws-amplify";
import toGlobalId from "./helper";

export const getViews = async (hubId) => {
  const req = await getAPIReq(
    {
      query: `query getViews {
        views {
          edges {
            node {
              id
              uuid
              name
              description
              order
              color
              numActiveClients
              numActiveClientWorkflows
              numRed
              numYellow
              numGreen
            }
          }
        }
      }      
        `,
    },
    { hub: hubId }
  );
  const {
    data: { views },
  } = await API.post("HublyAPI", `/graphql`, req);

  return views.edges.map(({ node }) => {
    return {
      ...node,
      id: node.uuid,
    };
  });
};

export const getView = async (hubId, viewId) => {
  const req = await getAPIReq(
    {
      query: `query getView( $viewId: ID!) {
          view(id: $viewId) {
            uuid
            name
            color
            filter {
              uuid
              assigneeFilterType
              assigneeFilter
              filterEmptyWorkflows
              filterNextTaskForAssignee
              tagFilterType
              tags {
                edges {
                  node {
                    uuid
                    name
                  }
                }
              }
              reminderTypes
              streamFilterType
              streams {
                edges {
                  node {
                    uuid
                    name
                    color
                  }
                }
              }
              clientFilter
              filterWorkflows {
                edges {
                  node {
                    workflow {
                      uuid
                      name
                      isNewTemplate
                      numWorkflowRules
                      numActiveClients,
                      numActiveClientWorkflows,
                      numTasks
                      process {
                        uuid
                        name
                        color
                      }
                      clientTileOptions {
                        showProgress
                        showIcons
                        showStreams
                        numberOfTasks
                        presentWorkflowName
                      }
                    }
                  }
                }
              }
            }
          }
        }
        `,
      variables: {
        viewId: toGlobalId("View", viewId),
      },
    },
    { hub: hubId }
  );
  const {
    data: { view },
  } = await API.post("HublyAPI", `/graphql`, req);

  if (view === null) {
    throw new Error(
      "Failed to load the view, it does not exist within this hub."
    );
  }

  return [
    {
      ...view,
      id: view.uuid,
      filters: {
        ...view.filter,
        id: view.filter.uuid,
        assignee: view.filter.assigneeFilter,
        tags: view.filter.tags.edges.map(({ node: tNode }) => {
          return {
            ...tNode,
            id: tNode.uuid,
          };
        }),
        streams: view.filter.streams.edges.map(({ node: sNode }) => {
          return {
            ...sNode,
            id: sNode.uuid,
          };
        }),
      },
      workflows: view.filter.filterWorkflows.edges.map(({ node: fwNode }) => {
        return {
          ...fwNode.workflow,
          id: fwNode.workflow.uuid,
          process: fwNode.workflow.process
            ? { ...fwNode.workflow.process, id: fwNode.workflow.process }
            : null,
        };
      }),
    },
  ];
};

export default getViews;
