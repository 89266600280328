import React, { useState, useContext } from "react";
import PropTypes from "prop-types";

import { Icon, Modal } from "semantic-ui-react";

import { HubContext } from "scenes/Hubly/components/Workspace/Provider";

import ExportStreamOrTagButton from "components/ExportStreamOrTagButton/ExportStreamOrTagButton";
import { ConfirmationModal } from "components/ConfirmationModal";

import {
  MembersSection,
  ModalActions,
  ModalInput,
  StreamColorSelector,
} from "./components";
import capitalizeFirstLetter from "../../helper";

export function ManyToManyModal({
  obj,
  type,
  updateData,
  deleteData,
  onViewClick,
  onClose,
  exportProps,
  ...otherProps
}) {
  const { integration } = useContext(HubContext);

  const [color, setColor] = useState(obj.color);
  const [name, setName] = useState(obj.name);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [updating, setUpdating] = useState(false);
  const isWealthboxUser = integration && integration.type === "wealthbox";

  const update = () => {
    const updateSelector = () => {
      return name !== obj.name || (color && color !== obj.color)
        ? updateData
        : () => {
            return Promise.resolve();
          };
    };

    const processUpdate = updateSelector();
    setUpdating(true);
    processUpdate(obj.id, {
      name: name,
      color: color,
      failedIfExists: true,
    }).finally(() => {
      onClose();
    });
  };

  return (
    <React.Fragment>
      <Modal data-test={`${type}-modal`} onClose={onClose} open>
        <Modal.Header>
          <Icon
            name="delete"
            link
            color="grey"
            style={{ float: "right", position: "relative" }}
            onClick={() => {
              onClose(null);
            }}
          />
          Manage {capitalizeFirstLetter(type)}
        </Modal.Header>
        <Modal.Content>
          <React.Fragment>
            <ModalInput
              isWealthboxUser={isWealthboxUser}
              isExternalIdNull={isWealthboxUser && !obj.externalId}
              defaultValue={name}
              onChange={setName}
              onEdit={update}
            />
            {type === "stream" && (
              <StreamColorSelector streamColor={color} onChange={setColor} />
            )}
          </React.Fragment>
          <MembersSection
            obj={obj}
            type={type}
            onViewClick={(clientId) => {
              onViewClick(clientId);
              onClose(null);
            }}
            {...otherProps}
          />
        </Modal.Content>
        <Modal.Actions>
          <ModalActions
            isWealthboxUser={isWealthboxUser}
            loading={updating}
            disabled={updating || name === ""}
            onDeleteClick={() => {
              setShowConfirmationModal(true);
            }}
            onEditClick={update}
          />
          <ExportStreamOrTagButton
            disabled={updating}
            style={{ float: "left" }}
            {...exportProps}
          />
        </Modal.Actions>
      </Modal>
      {showConfirmationModal && (
        <ConfirmationModal
          title={`Delete "${obj.name}" ${capitalizeFirstLetter(type)}`}
          message={`This will delete "${obj.name}" and remove this ${type} from all associated clients. This cannot be undone.`}
          icon="delete"
          buttons={[
            {
              text: "Cancel",
              callBack: () => {
                setShowConfirmationModal(false);
              },
            },
            {
              text: `Delete ${capitalizeFirstLetter(type)}`,
              color: "red",
              callBack: () => {
                setShowConfirmationModal(null);
                setUpdating(true);
                deleteData(obj.id).finally(() => {
                  onClose();
                });
              },
            },
          ]}
        />
      )}
    </React.Fragment>
  );
}

ManyToManyModal.propTypes = {
  obj: PropTypes.object.isRequired,
  exportProps: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  updateData: PropTypes.func.isRequired,
  deleteData: PropTypes.func.isRequired,
  onViewClick: PropTypes.func.isRequired,
};

export default ManyToManyModal;
