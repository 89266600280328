import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Button, Responsive } from "semantic-ui-react";
import { CreateNewWorkflow } from "../../actions";

class NewWorkflow extends React.Component {
  static propTypes = {
    createWorkflow: PropTypes.func.isRequired,
    scrollRight: PropTypes.func.isRequired,
  };

  render() {
    const { createWorkflow, scrollRight } = this.props;
    return (
      <div>
        <Responsive
          as={Button}
          basic
          icon="plus"
          id="filter-workflow-button"
          maxWidth={1350}
          onClick={() => {
            createWorkflow(scrollRight);
          }}
          style={{ marginRight: 0 }}
        />
        <Responsive
          as={Button}
          basic
          content="Create Workflow"
          icon="plus"
          id="filter-workflow-button"
          minWidth={1351}
          onClick={() => {
            createWorkflow(scrollRight);
          }}
          style={{ marginRight: 0, whiteSpace: "nowrap" }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    createWorkflow: (callback) => {
      dispatch(CreateNewWorkflow(callback));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewWorkflow);
