import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Dropdown } from "semantic-ui-react";
import { SortClientWorkflows } from "data/hub/clientWorkflows/actions";
import ClientWorkflowDropdownItem from "./ClientWorkflowDropdownItem";
import { shorterName, generateCWFContent, displayName } from "../helpers";

const DropdownClientWorkflows = ({
  workflows,
  clientWorkflowId,
  clientWorkflows,
  hubAdvisors,
  handleChange,
}) => {
  const clientWorkflow = clientWorkflows[clientWorkflowId];
  const sortedClientWorkflows = SortClientWorkflows(workflows, clientWorkflows);

  const dropdownClientWorkflows = sortedClientWorkflows
    .map((cwId) => {
      return clientWorkflows[cwId];
    })
    .filter((cwid) => {
      return cwid !== undefined;
    })
    .map((cwf) => {
      return {
        text: shorterName(cwf.name),
        key: cwf.id,
        value: cwf.id,
        completed: cwf.completed,
        content: generateCWFContent(cwf),
      };
    });

  let dividerIndex = Number.MAX_SAFE_INTEGER;

  return (
    <Dropdown
      text={displayName(shorterName(clientWorkflow?.name), clientWorkflow)}
      data-test="card-workflows-title-dropdown"
      style={{
        color: clientWorkflow?.completed ? "green" : "",
        display: "inline",
        margin: "0px 0.5em 0px 0.5em",
        fontSize: "13.5pt",
        fontWeight: "bold",
        padding: "0.25em 0.5em",
        transform: "translateX(-0.3em)",
        border: "none",
        zIndex: 1,
      }}
      className="grey_on_hover rounded"
      upward={false}
    >
      <Dropdown.Menu style={{ maxHeight: "400px", overflowY: "scroll" }}>
        {dropdownClientWorkflows.map((dw, index) => {
          if (dw.completed) {
            dividerIndex = Math.min(index, dividerIndex);
          }
          return (
            <React.Fragment key={dw.value}>
              {dividerIndex === index && (
                <Dropdown.Divider key={`divider-${dw.key}`} />
              )}
              <ClientWorkflowDropdownItem
                clientWorkflow={dw}
                handleChange={handleChange}
                isCurrentClientWorkflow={clientWorkflowId === dw?.value}
              />
            </React.Fragment>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};

DropdownClientWorkflows.propTypes = {
  workflows: PropTypes.array,
  clientWorkflowId: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  clientWorkflows: PropTypes.array,
  hubAdvisors: PropTypes.array,
};

DropdownClientWorkflows.defaultProps = {
  clientWorkflows: [],
  hubAdvisors: [],
  workflows: [],
};

const mapState = (state) => {
  return {
    clientWorkflows: state.hubly.data.hub.clientWorkflows,
    hubAdvisors: state.hubly.data.hub.selected.hub.advisors,
  };
};

const mapDispatch = {};

export default connect(mapState, mapDispatch)(DropdownClientWorkflows);
