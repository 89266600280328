import React, { useEffect } from "react";
import config from "config";
import { CannyInitChangelog } from "components/Canny/components/CannyComponent";

export function CannyChangelogButton() {
  useEffect(() => {
    // If the button is re-rendered after page load, we need to call Canny SDK again,
    // otherwise, the button will not show Canny changelog.
    // https://developers.canny.io/install/changelog
    CannyInitChangelog({ appId: config.canny.appId });
  }, []);

  // The action for this button is initialized when Canny js component is loaded.
  // It is being loaded at frontend/src/scenes/Hubly/Layout.jsx.
  return (
    <div data-canny-changelog style={{ marginLeft: "0.5em" }}>
      <i aria-hidden="true" className="bolt large link icon" />
    </div>
  );
}

export default CannyChangelogButton;
