import React, { useContext, useState } from "react";
import PropTypes from "prop-types";

import {
  HubContext,
  ToasterContext,
} from "scenes/Hubly/components/Workspace/Provider";

import { ConfirmationModal } from "components/ConfirmationModal";
import { ProcessModal } from "..";

export function ProcessSettingModal({
  showDelete,
  process,
  onCreateProcess,
  onUpdateProcess,
  onDeleteProcess,
  onClose,
}) {
  const { addProcess, editProcess, deleteProcess } = useContext(HubContext);
  const { fireError } = useContext(ToasterContext);
  const [loading, setLoading] = useState(false);

  const removeProcess = ({ id }) => {
    setLoading(true);
    deleteProcess(id)
      .then(() => {
        onClose();
        onDeleteProcess(id);
      })
      .catch((error) => {
        fireError("Failed to delete process.", error);
        onClose();
      });
  };

  const createProcess = (createdProcess) => {
    setLoading(true);
    addProcess(createdProcess)
      .then(({ id }) => {
        onClose();
        onCreateProcess({ id: id, ...createdProcess });
      })
      .catch((error) => {
        fireError("Failed to create process.", error);
      });
  };

  const updateProcess = ({ savedProcess }) => {
    setLoading(true);
    editProcess(savedProcess.id, savedProcess)
      .then(() => {
        onClose();
        onUpdateProcess(savedProcess);
      })
      .catch((error) => {
        fireError("Failed to configure process.", error);
        onClose();
      });
  };

  return (
    <React.Fragment>
      {!showDelete && (
        <ProcessModal
          loading={loading}
          process={process}
          onClosed={() => {
            onClose();
          }}
          onSaved={({ created, savedProcess, initialProcess }) => {
            if (created) {
              createProcess(savedProcess);
            } else {
              updateProcess({ savedProcess, initialProcess });
            }
          }}
        />
      )}
      {showDelete && process && (
        <ConfirmationModal
          title="Delete Process"
          message={`This will delete "${process.name}". This cannot be undone.`}
          icon="delete"
          buttons={[
            {
              text: "Cancel",
              callBack: () => {
                onClose();
              },
            },
            {
              text: "Delete",
              callBack: () => {
                removeProcess(process);
              },
              color: "red",
            },
          ]}
        />
      )}
    </React.Fragment>
  );
}

ProcessSettingModal.defaultProps = {
  process: null,
};

ProcessSettingModal.propTypes = {
  showDelete: PropTypes.bool.isRequired,
  process: PropTypes.object,
  onUpdateProcess: PropTypes.func.isRequired,
  onDeleteProcess: PropTypes.func.isRequired,
  onCreateProcess: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ProcessSettingModal;
