import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, Icon, Modal } from "semantic-ui-react";
import { SetAlert } from "components/Alerts/actions";
import DatePickerContainer from "components/DatePickerContainer";
import Moment from "moment";
import {
  AddReminderToClient,
  ChangeDateOfReminder,
} from "data/reminders/actions";
import { CloseReminderRescheduleModal } from "./actions";

const defaultDate = new Date();
defaultDate.setDate(defaultDate.getDate() + 1);
defaultDate.setHours(8);
defaultDate.setMinutes(0);
defaultDate.setSeconds(0);
defaultDate.setMilliseconds(0);

class ReminderRescheduleModal extends React.Component {
  static propTypes = {
    changeDateOfReminder: PropTypes.func.isRequired,
    closeReminderRescheduleModal: PropTypes.func.isRequired,
    reminder: PropTypes.shape({
      time: PropTypes.string.isRequired,
      taskId: PropTypes.string,
      title: PropTypes.string,
      id: PropTypes.string.isRequired,
      clientId: PropTypes.string.isRequired,
    }).isRequired,
    reminderRescheduleModalOpen: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      pickerDate: props.reminder.time,
    };
  }

  handleChange = (date) => {
    if (date.toString() === this.state.pickerDate.toString()) {
      return;
    }
    this.setState({
      pickerDate: date.toISOString(),
    });
  };

  todaySnoozeTime = () => {
    const time = Moment();
    time.set("hour", 15);
    time.set("minute", 0);
    time.set("second", 0);
    return time;
  };

  tomorrowSnoozeTime = () => {
    const time = Moment();
    time.add(1, "d");
    time.set("hour", 9);
    time.set("minute", 0);
    time.set("second", 0);
    return time;
  };

  nextWeekSnoozeTime = () => {
    const time = Moment().startOf("isoWeek");
    time.add(7, "d");
    time.set("hour", 9);
    time.set("minute", 0);
    time.set("second", 0);
    return time;
  };

  snoozeOption = (left, right, snooze) => {
    const leftStyle = { fontWeight: "bold", color: "#2185d0" };
    const rightStyle = { float: "right", color: "grey", fontSize: "9pt" };
    return (
      <div
        className="snooze_option"
        onClick={() => {
          this.handleChange(snooze);
        }}
        style={{ padding: "0.25em 0.5em" }}
      >
        <span style={leftStyle}>{left}</span>
        <span style={rightStyle}>{right}</span>
      </div>
    );
  };

  reminderHasPassed = () => {
    const { time } = this.props.reminder;
    return new Date() > new Date(time);
  };

  saveChange = () => {
    const { pickerDate } = this.state;
    const { changeDateOfReminder, closeReminderRescheduleModal, reminder } =
      this.props;
    const text = this.reminderHasPassed()
      ? "Successfully snoozed reminder"
      : "Successfully rescheduled reminder";
    changeDateOfReminder(reminder, pickerDate, text);
    closeReminderRescheduleModal();
  };

  render() {
    const {
      closeReminderRescheduleModal,
      reminder,
      reminderRescheduleModalOpen,
    } = this.props;
    const { pickerDate } = this.state;

    const today = this.todaySnoozeTime();
    const tomorrow = this.tomorrowSnoozeTime();
    const nextWeek = this.nextWeekSnoozeTime();

    const overdue = this.reminderHasPassed();

    return (
      <Modal
        size="mini"
        on="click"
        open={reminderRescheduleModalOpen}
        onClose={closeReminderRescheduleModal}
        style={{ minWidth: "24em" }}
        data-test="reminder-reschedule-modal"
      >
        <Modal.Header style={{ marginBottom: "0em" }}>
          {overdue ? "Snooze " : "Reschedule"}
        </Modal.Header>
        <Modal.Content>
          <div
            style={{
              maxWidth: "100%",
              maxHeight: "1.3em",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            title={reminder.title}
          >
            {reminder.title}
          </div>

          <span
            style={{
              color: overdue ? "#db2828" : "grey",
              fontSize: "9pt",
              marginBottom: "0.5em",
              display: "block",
            }}
          >
            <Icon name="bell outline" color={overdue ? "red" : "grey"} />
            {Moment(reminder.time).format("dddd MMMM Do h:mm a")}
          </span>
          <div style={{ marginBottom: "2em" }}>
            {Moment().hours() < 15
              ? this.snoozeOption(
                  "Later Today",
                  `Today, ${today.format("h:mm a")}`,
                  today
                )
              : null}
            {this.snoozeOption(
              "Tomorrow",
              tomorrow.format("dddd, h:mm a").toString(),
              tomorrow
            )}
            {this.snoozeOption(
              "Next Week",
              nextWeek.format("dddd, h:mm a").toString(),
              nextWeek
            )}
          </div>
          <DatePickerContainer
            setDate={new Date(pickerDate)}
            handleChange={(date) => {
              this.handleChange(date);
            }}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button basic floated="left" onClick={closeReminderRescheduleModal}>
            Cancel
          </Button>
          <Button primary basic onClick={this.saveChange}>
            Save Changes
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    reminderRescheduleModalOpen:
      state.hubly.reminderReschedule.reminderRescheduleModalOpen,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addReminderToClient: (client, title, reminderTime) => {
      dispatch(AddReminderToClient(client, title, reminderTime));
    },
    changeDateOfReminder: (reminder, date, text) => {
      dispatch(ChangeDateOfReminder(reminder, date, text));
    },
    closeReminderRescheduleModal: () => {
      dispatch(CloseReminderRescheduleModal());
    },
    setAlert: (alert) => {
      dispatch(SetAlert(alert));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReminderRescheduleModal);
