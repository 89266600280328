import React from "react";
import PropTypes from "prop-types";

import { Icon } from "semantic-ui-react";

function EmptyCircle({ title, clicked, iconSize, ...buttonProps }) {
  return (
    <div style={{ margin: "0 0.25em" }}>
      <Icon.Group
        className={!clicked ? "dark_grey_on_hover" : ""}
        style={{ fontSize: iconSize }}
        title={title}
        {...buttonProps}
      >
        <Icon
          link
          name="user circle"
          style={clicked ? { color: "#aaaaaa", margin: 0 } : { margin: 0 }}
        />
        <Icon
          link
          corner
          name="add"
          style={
            clicked
              ? { color: "#aaaaaa" }
              : { color: "#aaaaaa", textShadow: "none" }
          }
        />
      </Icon.Group>
    </div>
  );
}

EmptyCircle.propTypes = {
  title: PropTypes.string,
  clicked: PropTypes.bool,
  iconSize: PropTypes.string,
};

EmptyCircle.defaultProps = {
  title: "Assigned Advisor",
  clicked: false,
  iconSize: "25px",
};

export default EmptyCircle;
