import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { SetAlert } from "components/Alerts/actions";

import HubFeed from "./components/HubFeed";
import WorkspaceContainer from "./Container";
import { HubContextProvider, ToasterContextProvider } from "./Provider";

export function Workspace({ hub, advisor, fireToaster }) {
  return (
    <HubContextProvider
      hubId={hub.id}
      hubShortId={hub.hubId}
      hubClientPoolId={hub.clientPool.id}
      sortByLastName={hub.sortByLastName}
      hubAdvisor={advisor}
      advisors={hub.advisors}
      integration={advisor.integrations.find((i) => {
        return i.hubId === hub.id;
      })}
    >
      <ToasterContextProvider fireToaster={fireToaster}>
        <HubFeed />
        <WorkspaceContainer />
      </ToasterContextProvider>
    </HubContextProvider>
  );
}

Workspace.propTypes = {
  hub: PropTypes.object.isRequired,
  advisor: PropTypes.object.isRequired,
  fireToaster: PropTypes.func.isRequired,
};

Workspace.defaultProps = {};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    fireToaster: (alert) => {
      dispatch(SetAlert(alert));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Workspace);
