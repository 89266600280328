import React from "react";
import { swap } from "utils";

export const isEmptyFilter = (f, ignoreFilterEmptyWorkflows = false) => {
  return (
    f.streams.length === 0 &&
    f.tags.length === 0 &&
    (f.filterEmptyWorkflows === false || ignoreFilterEmptyWorkflows) &&
    f.filterNextTaskForAssignee === false &&
    f.assigneeFilterType === "ALL" &&
    f.reminderTypes.length === 0 &&
    f.clientFilter.length === 0
  );
};

const initState = {
  workflows: [],
  filters: {
    streams: [],
    streamFilterType: "ANY",
    tags: [],
    tagFilterType: "ANY",
    filterEmptyWorkflows: false,
    filterNextTaskForAssignee: false,
    assigneeFilterType: "ALL",
    assignee: null,
    reminderTypes: [],
    clientFilter: [],
  },
};

const filterEmptyWorkflows = (isPrevFilterClear, isCurFilterClear) => {
  return isPrevFilterClear === isCurFilterClear
    ? {}
    : { filterEmptyWorkflows: Boolean(isPrevFilterClear) };
};

function reducer(state, action) {
  switch (action.type) {
    case "workflows":
      return { ...state, [action.type]: action.value };
    case "filters":
      return {
        ...state,
        [action.type]: {
          ...action.value,
          ...filterEmptyWorkflows(
            isEmptyFilter(state[action.type], true),
            isEmptyFilter(action.value, true)
          ),
        },
      };
    case "add-workflow":
      return { ...state, workflows: [...state.workflows, action.value] };
    case "remove-workflow":
      return {
        ...state,
        workflows: state.workflows.filter((w) => {
          return w.id !== action.value.id;
        }),
      };
    case "swap-workflows":
      return {
        ...state,
        workflows: swap(state.workflows, action.value.src, action.value.des),
      };
    case "cleanAll":
    default:
      return initState;
  }
}

export const useFilterState = () => {
  return React.useReducer(reducer, initState);
};

export default useFilterState;
