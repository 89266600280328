import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { datadogRum } from "@datadog/browser-rum";

import { LoadHubResources } from "data/hub/actions";
import getMiliSecondDuration from "analytics/helper";

import { Container } from "semantic-ui-react";

import "./Hubly.css";

import Workflows from "./components/Workflows";
import HubModals from "./components/HubModals";
import { Menu as SupportTools } from "./components/Support";
import HubFeed from "./components/HubFeed";
import Loading from "../../components/Loading";
import RefreshRequiredOverlay from "./components/RefreshRequiredOverlay";

class Hubly extends React.Component {
  static defaultProps = {
    hub: {},
  };

  static propTypes = {
    hub: PropTypes.shape({
      id: PropTypes.string.isRequired,
      hubId: PropTypes.string.isRequired,
    }),
    loading: PropTypes.bool.isRequired,
    match: PropTypes.shape({
      params: PropTypes.object.isRequired,
      path: PropTypes.string.isRequired,
    }).isRequired,
    loadResources: PropTypes.func.isRequired,
    unsyncedUpdates: PropTypes.number.isRequired,
  };

  constructor(props) {
    const { loading } = props;

    super(props);
    this.startTime = new Date().getTime();

    if (loading) {
      props.loadResources();
    }
  }

  componentDidUpdate(prevProps) {
    const { hub, loading } = this.props;
    if (!loading && hub) {
      datadogRum.addAction("hubly_timespent_load_app", {
        duration: getMiliSecondDuration(this.startTime),
      });
    }
  }

  render() {
    const { hub, loading, match, unsyncedUpdates } = this.props;
    const style = {
      display: "flex",
      flexFlow: "column",
      height: "calc(100vh - 47px)",
      justifyContent: "flex-start",
    };

    return (
      <React.Fragment>
        {!hub || loading ? (
          <div style={style}>
            <Loading
              showLogo
              active
              messages={["Loading your clients", "Cleaning up"]}
            />
          </div>
        ) : (
          <div style={style}>
            {unsyncedUpdates > 0 && (
              <RefreshRequiredOverlay unsyncedUpdates={unsyncedUpdates} />
            )}
            <HubModals match={match} />
            {match.path.includes("tools") && <SupportTools />}
            <HubFeed />
            <Container fluid basic="true" className="workflow-container">
              <Workflows match={match} />
            </Container>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    hub: state.hubly.data.hub.selected.hub,
    loading: state.hubly.data.hub.selected.pendingLoadResources,
    unsyncedUpdates: state.hubly.data.websockets.unsyncedUpdates,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadResources: () => {
      dispatch(LoadHubResources());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Hubly);
