import React from "react";
import { Button, Container } from "semantic-ui-react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Droppable } from "react-beautiful-dnd";
import Task from "components/Task";
import TaskAdd from "components/Task/components/TaskAdd";
import Spinner from "components/Spinner";

class WorkflowEditTasks extends React.Component {
  static propTypes = {
    workflow: PropTypes.shape({
      tasks: PropTypes.array.isRequired,
      id: PropTypes.string.isRequired,
    }).isRequired,
    advisor: PropTypes.object.isRequired,
    createWorkflowTask: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      addingTask: false,
      saving: false,
    };
  }

  setSavingTask = (saving) => {
    this.setState({ saving: saving });
  };

  render() {
    const { workflow } = this.props;
    const { addingTask, saving } = this.state;
    const tasks = (workflow.tasks || []).sort((a, b) => {
      return a.order <= b.order ? -1 : 1;
    });
    return (
      <Container className="task_list">
        {tasks.length > 0 ? (
          <Droppable
            droppableId={`tasks_${workflow.id}`}
            direction="vertical"
            type="TASKS"
          >
            {(provided, snapshot) => {
              return (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className="ui list"
                  style={{ marginBottom: "0em" }}
                >
                  {tasks.map((task, index) => {
                    return (
                      <Task
                        task={task}
                        workflow={workflow}
                        taskIndex={index}
                        key={task.id}
                      />
                    );
                  })}
                  {provided.placeholder}
                </div>
              );
            }}
          </Droppable>
        ) : (
          <div
            style={{ display: "block", color: "grey", paddingBottom: "0em" }}
          >
            No tasks yet
          </div>
        )}

        {addingTask && !saving && (
          <TaskAdd
            closeAddTask={() => {
              this.setState({ addingTask: false });
            }}
            currentWorkflow={workflow}
            setSavingTask={this.setSavingTask}
          />
        )}
        {addingTask && saving && (
          <div
            style={{
              color: "grey",
              marginTop: "0.5em",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div style={{ marginRight: "1em" }}>Adding Task</div>
            <Spinner active size="small" />
          </div>
        )}
        {!addingTask && !saving && (
          <div style={{ overflow: "auto" }}>
            <Button
              compact
              basic
              content="Add Task"
              onClick={() => {
                this.setState({ addingTask: true });
              }}
            />
          </div>
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    advisor: state.hubly.data.advisor,
    workflow: state.hubly.data.hub.workflows[ownProps.workflowId],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowEditTasks);
