import React from "react";
import { Dropdown, Header, Icon, List } from "semantic-ui-react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { AddClientTag, RefreshTags } from "data/hub/tags/actions";
import { GetClientTags } from "data/libs/clients";
import { SetActiveClient } from "data/hub/clients/actions";
import { CreateSync, GetSync } from "data/libs/sync";
import { SetAlert } from "components/Alerts/actions";

import { publishClientWorkflowsUpdate } from "../actions";

import CardTag from "./CardTag";

class CardTags extends React.Component {
  static propTypes = {
    addClientTag: PropTypes.func.isRequired,
    allTags: PropTypes.object.isRequired,
    advisor: PropTypes.shape({
      id: PropTypes.string.isRequired,
      integrations: PropTypes.array.isRequired,
    }).isRequired,
    hub: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
    client: PropTypes.shape({
      id: PropTypes.string.isRequired,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      tags: PropTypes.array.isRequired,
    }).isRequired,
    refreshTags: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired,
    setActiveClient: PropTypes.func.isRequired,
    publish: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    const request = {
      type: "contact",
      advisor_id: props.advisor.id,
      client_id: props.client.id,
      hub_id: props.hub.id,
    };

    if (
      props.advisor.integrations.find((i) => {
        return i.hubId === props.hub.id;
      })
    ) {
      CreateSync(request).then((response) => {
        const syncId = response.id;
        setTimeout(() => {
          this.checkSyncComplete(syncId);
        }, 10);
      });
    }

    const { refreshTags, hub } = this.props;
    refreshTags(hub);

    this.state = {
      adding: false,
      loading: true,
      error: false,
      tagInput: "",
    };
  }

  checkSyncComplete = (syncId) => {
    GetSync(syncId).then((response) => {
      if (response.status === "failed") {
        this.syncTagsFailed();
      } else if (response.status === "completed") {
        this.syncTagsComplete();
      } else {
        setTimeout(() => {
          this.checkSyncComplete(syncId);
        }, 1000);
      }
    });
  };

  createTagList = () => {
    const { client, allTags } = this.props;
    const { tags } = client;
    let options = [];

    // Check if tag already applied
    const clientTagIds = tags.map((tag) => {
      return tag.id;
    });
    const filteredTags = Object.values(allTags).filter((tag) => {
      return !clientTagIds.includes(tag.id);
    });
    filteredTags.forEach((tag) => {
      options.push({
        text: tag.name,
        key: tag.id,
        value: tag.name,
      });
    });
    // Sort alphabetically
    options = options.sort((a, b) => {
      return a.text.localeCompare(b.text);
    });
    return options;
  };

  syncTagsComplete = () => {
    const { client, setActiveClient, refreshTags, hub } = this.props;
    GetClientTags(client.id).then((response) => {
      const clientCopy = { ...client };
      clientCopy.tags = response.map((tag) => {
        return tag.id;
      });
      setActiveClient(clientCopy);
    });
    refreshTags(hub);
  };

  syncTagsFailed = () => {
    const { setAlert } = this.props;
    setAlert({ type: "error", text: "Failed to sync client tags." });
  };

  tagSelected = (e, { value }) => {
    const { client, addClientTag } = this.props;
    if (e.type === "click" || (e.type === "keydown" && e.key === "Enter")) {
      this.setState({ loading: true });
      addClientTag(client.id, value, (success) => {
        if (success) {
          this.props.publish(client.id);
          this.setState({
            loading: false,
            error: false,
            tagInput: "",
          });
        } else {
          this.setState({
            loading: false,
          });
        }
      });
    }
  };

  toggleAdding = () => {
    this.setState((state) => {
      return {
        adding: !state.adding,
        loading: false,
        tagInput: "",
        error: false,
      };
    });
  };

  addTagInput = () => {
    const { error, loading, tagInput } = this.state;
    const tagOptions = this.createTagList();
    return (
      <div style={{ marginLeft: "1em", marginBottom: "1em" }}>
        <Dropdown
          data-test="client-card-tags-options"
          additionLabel="+ Create "
          allowAdditions
          deburr
          disabled={loading}
          error={error}
          loading={loading}
          noResultsMessage="No Tags Found"
          options={tagOptions}
          onBlur={() => {
            this.setState({ tagInput: "" });
          }}
          onChange={this.tagSelected}
          placeholder="Search for Tag"
          search
          searchInput={{ autoFocus: true }}
          selection
          value={tagInput}
        />
        <Icon
          color="grey"
          disabled={loading}
          link
          name="delete"
          onClick={this.toggleAdding}
          style={{ fontSize: "12pt", transform: "translate(3px, 0px)" }}
        />
      </div>
    );
  };

  render() {
    const { client, allTags } = this.props;
    const { tags } = client;
    const { adding } = this.state;
    tags.sort((a, b) => {
      try {
        return allTags[a].name.toUpperCase() >= allTags[b].name.toUpperCase()
          ? 1
          : -1;
      } catch {
        return 0;
      }
    });
    return (
      <div
        data-test="client-card-tags"
        className="client-card-tags"
        style={{ marginTop: "2em", width: "100%" }}
      >
        <div style={{ display: "flex" }}>
          <Header as="h5" style={{ marginBottom: "0.5em" }}>
            Tags
          </Header>
          {!adding && (
            <Icon
              title="Append Tags"
              link
              name="plus"
              className="grey_on_hover_circle"
              onClick={this.toggleAdding}
              style={{ marginLeft: "auto" }}
            />
          )}
        </div>
        <div>
          {adding && this.addTagInput()}
          {tags.length === 0 && (
            <span style={{ fontSize: "1em", color: "grey", marginLeft: "1em" }}>
              {`${
                client.firstName || client.lastName || "The client"
              } has no tags.`}
            </span>
          )}
          <List
            size="large"
            style={{ width: "225px", margin: 0, marginLeft: "0.5em" }}
          >
            {tags.map((tagId) => {
              return <CardTag client={client} tagId={tagId} />;
            })}
          </List>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    hub: state.hubly.data.hub.selected.hub,
    allTags: state.hubly.data.hub.tags,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addClientTag: (clientId, tagName, callback) => {
      dispatch(AddClientTag(clientId, tagName, callback));
    },
    setAlert: (alert) => {
      dispatch(SetAlert(alert));
    },
    setActiveClient: (client) => {
      dispatch(SetActiveClient(client));
    },
    refreshTags: (hub) => {
      dispatch(RefreshTags(hub));
    },
    publish: (clientId) => {
      dispatch(publishClientWorkflowsUpdate([clientId]));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CardTags);
