import React, { useState, useContext } from "react";
import PropTypes from "prop-types";

import { Icon } from "semantic-ui-react";

import { useDropdownData } from "hooks";
import { useRecentClients } from "components/ClientDropdown/hooks";

import { ClientDropdown } from "components/ClientDropdown";

import {
  HubContext,
  ToasterContext,
} from "scenes/Hubly/components/Workspace/Provider";

import {
  AddClientButton,
  AddClientConfirmationModal,
  BulkAddClientConfirmationModal,
} from "./components";
import { getHouseholdHead } from "./helper";
import "./AddClientDropdown.css";

export function AddClientDropdown({
  workflowId,
  style,
  onSyncProgressUpdated,
  onClientsAdded,
  onClearIconClicked,
  opened,
  ...otherProps
}) {
  const {
    addClientToWorkflow,
    updateClientCardOpened,
    fetchHousehold,
    fetchTags,
    fetchStreams,
    searchClients,
    fetchClientWorkflowCount,
    integration,
    viewClientInCrm,
  } = useContext(HubContext);
  const { fireError, fireWarning } = useContext(ToasterContext);

  const [addingClient, setAddingClient] = useState(false);
  const [searchText, setSearchText] = useState("");

  const [showAddClientConfirmationModal, setShowAddClientConfirmationModal] =
    useState(null);
  const [
    showBulkAddClientConfirmationModal,
    setShowBulkAddClientConfirmationModal,
  ] = useState(null);

  const [dropdownOpened, setDropdownOpened] = useState(opened);

  const recentClientData = useRecentClients();
  const clientsData = useDropdownData({
    searchText: searchText,
    type: "client",
    fetchFunction: searchClients,
  });
  const tagsData = useDropdownData({
    searchText: searchText,
    type: "tag",
    fetchFunction: fetchTags,
  });
  const streamsData = useDropdownData({
    searchText: searchText,
    type: "stream",
    fetchFunction: fetchStreams,
  });

  const onCompleteAddingClients = (clients, cwfId = null) => {
    setDropdownOpened(false);
    setAddingClient(false);
    setSearchText("");
    onClientsAdded(clients);
    if (clients.length === 1) {
      updateClientCardOpened(
        clients[0].id,
        cwfId ? { clientWorkflow: cwfId } : null
      );
    }
  };

  const addClient = (client) => {
    if (!addingClient) setAddingClient(true);
    addClientToWorkflow({ id: workflowId }, client)
      .then(({ id: cwfId }) => {
        setShowAddClientConfirmationModal(null);
        onCompleteAddingClients([client], cwfId);
      })
      .catch((error) => {
        setShowAddClientConfirmationModal(null);
        setAddingClient(false);
        fireError(`Failed to add ${client.name} to the workflow.`, error);
      });
  };

  const onAddClient = async (client, editInCrm = false) => {
    setAddingClient(true);
    try {
      const { activeClientWorkflowCount, workflowAllowsMultiple } =
        await fetchClientWorkflowCount(workflowId, client.id);
      if (activeClientWorkflowCount === 1 && !workflowAllowsMultiple) {
        fireWarning("Client already exists in the workflow.");
        setAddingClient(false);
        return;
      }

      if (!client.household?.id) {
        addClient(client);
        return;
      }

      const household = await fetchHousehold(client.id);
      const familyHead = getHouseholdHead({ client, household });
      if (familyHead) {
        setShowAddClientConfirmationModal({ client, familyHead });
        setAddingClient(false);
      } else {
        addClient(client);
      }
    } catch (error) {
      fireError("Failed to add client to the workflow.", error);
      setAddingClient(false);
    }
  };

  if (addingClient || !dropdownOpened) {
    return (
      <AddClientButton
        loading={addingClient}
        style={style}
        onClick={() => {
          setDropdownOpened(true);
        }}
      />
    );
  }

  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          ...style,
        }}
        {...otherProps}
      >
        <ClientDropdown
          integration={integration}
          integrationButtonText={integration ? "Save and Add" : ""}
          dropdownOpened={dropdownOpened}
          recentClientData={recentClientData}
          clientsData={clientsData}
          streamsData={streamsData}
          tagsData={tagsData}
          onSearchChange={setSearchText}
          dropdownProps={{ searchInput: { autoFocus: true } }}
          onSyncProgressUpdated={onSyncProgressUpdated}
          onClientClick={(client, editInCrm) => {
            if (editInCrm) viewClientInCrm(client.externalId);
            onAddClient(client);
          }}
          onStreamOrTagClick={setShowBulkAddClientConfirmationModal}
        />
        <Icon
          name="delete"
          color="grey"
          link
          style={{
            transform: "translate(3px, -1px)",
            fontSize: "12pt",
          }}
          onClick={(e) => {
            e.stopPropagation();
            onClearIconClicked(e);
            setDropdownOpened(false);
          }}
        />
      </div>
      {showAddClientConfirmationModal && (
        <AddClientConfirmationModal
          onAddClient={addClient}
          onClose={setShowAddClientConfirmationModal}
          workflowId={workflowId}
          {...showAddClientConfirmationModal}
        />
      )}
      {showBulkAddClientConfirmationModal && (
        <BulkAddClientConfirmationModal
          onAddClients={onCompleteAddingClients}
          onClose={setShowBulkAddClientConfirmationModal}
          workflowId={workflowId}
          {...showBulkAddClientConfirmationModal}
        />
      )}
    </React.Fragment>
  );
}

AddClientDropdown.defaultProps = {
  style: {},
  opened: false,
  onClearIconClicked: () => {},
  onSyncProgressUpdated: () => {},
};

AddClientDropdown.propTypes = {
  workflowId: PropTypes.string.isRequired,
  onClientsAdded: PropTypes.func.isRequired,
  style: PropTypes.object,
  opened: PropTypes.bool,
  onClearIconClicked: PropTypes.func,
  onSyncProgressUpdated: PropTypes.func,
};

export default AddClientDropdown;
