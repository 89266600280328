import React from "react";
import { Button, Header } from "semantic-ui-react";
import PropTypes from "prop-types";

export function ClientSyncOption({ onClick, inProgress, integrationType }) {
  return (
    <React.Fragment>
      {integrationType && (
        <Button
          id="client-sync-button"
          data-test="client-sync-button"
          onClick={onClick}
          disabled={inProgress}
          style={{
            background: "#ffffff00",
            padding: 0,
            margin: 0,
            width: "100%",
            textAlign: "left",
          }}
        >
          <Header as="h5">
            <img
              style={{ filter: "brightness(0%)", width: 20 }}
              src={`/images/logos/${integrationType}-logo.png`}
              alt={`${integrationType} Logo`}
            />
            <span
              style={{
                marginLeft: "0.7em",
                position: "relative",
                top: "0.2em",
              }}
            >
              Sync with CRM
            </span>
          </Header>
        </Button>
      )}
    </React.Fragment>
  );
}

ClientSyncOption.defaultProps = {
  onClick: () => {},
  inProgress: false,
  integrationType: "",
};

ClientSyncOption.propTypes = {
  onClick: PropTypes.func,
  inProgress: PropTypes.bool,
  integrationType: PropTypes.string,
};

export default ClientSyncOption;
