import React from "react";
import { Button, Popup } from "semantic-ui-react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { SetAlert } from "components/Alerts/actions";
import { ToggleStreamModal } from "components/StreamModal/actions";
import { SetActiveClient } from "data/hub/clients/actions";
import { DeleteClientStream } from "data/libs/clients";

import { publishClientWorkflowsUpdate } from "../../../actions";

class Stream extends React.Component {
  static propTypes = {
    setAlert: PropTypes.func.isRequired,
    setActiveClient: PropTypes.func.isRequired,
    stream: PropTypes.object.isRequired,
    toggleStreamModal: PropTypes.func.isRequired,
    client: PropTypes.object.isRequired,
    publish: PropTypes.func.isRequired,
  };

  state = {
    popupOpen: false,
  };

  togglePopup = () => {
    this.setState((state) => {
      return { popupOpen: !state.popupOpen };
    });
  };

  editStream = () => {
    const { stream, toggleStreamModal } = this.props;
    toggleStreamModal(stream);
    this.togglePopup();
  };

  removeFromStream = () => {
    const { setAlert, setActiveClient, client, stream } = this.props;
    const clientCopy = { ...client };
    this.togglePopup();
    DeleteClientStream(clientCopy.id, stream.id)
      .then((response) => {
        this.props.publish(client.id);
        const streams = clientCopy.streams.filter((s) => {
          return s.id !== stream.id;
        });
        clientCopy.streams = streams;
        setActiveClient(clientCopy);
        setAlert({ type: "success", text: "Successfully removed stream" });
      })
      .catch((error) => {
        setAlert({
          type: "error",
          text: `Failed to remove client from stream.`,
        });
      });
  };

  render() {
    const { popupOpen } = this.state;
    const { stream } = this.props;
    return (
      <Popup
        className="hubly_bars_menu"
        hideOnScroll
        on="click"
        onClose={this.togglePopup}
        open={popupOpen}
        popperModifiers={{
          preventOverflow: { boundariesElement: "window", enabled: false },
        }}
        position="right center"
        trigger={
          <Button
            style={{
              backgroundColor: `${stream.color}`,
              color: "white",
              marginTop: "0.25em",
              marginLeft: ".5em",
            }}
            content={stream.name}
            onClick={this.togglePopup}
            size="mini"
          />
        }
      >
        <Button.Group basic vertical labeled icon style={{ border: "none" }}>
          <Button icon="edit" content="Edit Stream" onClick={this.editStream} />
          <Button
            icon="remove"
            content="Remove From Stream"
            onClick={this.removeFromStream}
          />
        </Button.Group>
      </Popup>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAlert: (alert) => {
      dispatch(SetAlert(alert));
    },
    setActiveClient: (client) => {
      dispatch(SetActiveClient(client));
    },
    toggleStreamModal: (stream) => {
      dispatch(ToggleStreamModal(stream));
    },
    publish: (clientId) => {
      dispatch(publishClientWorkflowsUpdate([clientId]));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Stream);
