import React from "react";
import PropTypes from "prop-types";
import { Radio } from "semantic-ui-react";

const SettingsToggleRow = ({ renderData, checked, loading, setPreference }) => {
  const { title, description, key } = renderData;
  const handleChange = (e, { checked: radioChecked }) => {
    setPreference(key, radioChecked);
  };

  return (
    <div
      style={{
        display: "flex",
        marginBottom: "1em",
        width: "100%",
        justifyContent: "space-between",
      }}
      data-test={`settings-toggle-row-${key}`}
    >
      <Radio
        toggle
        onChange={handleChange}
        checked={checked}
        disabled={loading}
      />
      <div style={{ width: "85%" }}>
        <span
          style={{ display: "block", fontSize: "1.1em", marginBottom: ".5em" }}
        >
          {title}
        </span>
        {checked && (
          <div
            style={{
              padding: "4px 8px",
              background: "#f0f0f0",
              borderRadius: "4px",
            }}
          >
            <span
              style={{
                fontStyle: "italic",
                color: "#707070",
                lineHeight: "1.25",
              }}
            >
              {description}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

SettingsToggleRow.propTypes = {
  renderData: PropTypes.shape({
    key: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.node.isRequired,
  }).isRequired,
  checked: PropTypes.bool,
  loading: PropTypes.bool,
  setPreference: PropTypes.func.isRequired,
};

SettingsToggleRow.defaultProps = {
  checked: false,
  loading: false,
};

export default SettingsToggleRow;
