import { getAPIReq } from "data/libs/authLib";
import { API } from "aws-amplify";
import toGlobalId from "./helper";

export const getHousehold = async ({ hubId, clientId }) => {
  const req = await getAPIReq(
    {
      query: `query getHousehold($clientId: ID!) {
        household(clientId: $clientId) {
          uuid
          name
          externalId
          householdMembers {
            edges {
              node {
                uuid
                title
                type
                client {
                    uuid
                    firstName
                    lastName
                    name
                }
              }
            }
          }
        }
      }`,
      variables: {
        clientId: toGlobalId("Client", clientId),
      },
    },
    { hub: hubId }
  );

  const {
    data: { household },
  } = await API.post("HublyAPI", `/graphql`, req);
  return {
    ...household,
    id: household.uuid,
    householdMembers: household.householdMembers.edges.map(({ node }) => {
      return {
        ...node,
        id: node.uuid,
        clientId: node.client.uuid,
        client: {
          ...node.client,
          name: node.client.name
            ? node.client.name
            : `${node.client.firstName} ${node.client.lastName}`,
          id: node.client.uuid,
        },
      };
    }),
  };
};

export default getHousehold;
