import React from "react";
import { connect } from "react-redux";
import {
  Button,
  Header,
  Icon,
  Input,
  Modal,
  Placeholder,
  Popup,
  Segment,
} from "semantic-ui-react";
import copy from "copy-to-clipboard";
import { PrivacyModeContext } from "components/PrivacyMode/Context";
import { SetAlert } from "components/Alerts/actions";
import LocalAlert from "components/LocalAlert";
import { SetAttachments } from "data/hub/clientCard/actions";
import {
  CreateAttachment,
  DeleteAttachment,
  GetAttachments,
} from "data/libs/clients";
import { CreateClientUploadToken } from "data/libs/clientUploadTokens";
import Moment from "moment";
import PropTypes from "prop-types";
import config from "config";
import Attachment from "./Attachment";
import ClientLinkExpiryDaysSelector from "./ClientLinkExpiryDaysSelector";

class CardAttachments extends React.Component {
  static contextType = PrivacyModeContext;

  static propTypes = {
    attachments: PropTypes.array.isRequired,
    attachmentsLoading: PropTypes.bool.isRequired,
    client: PropTypes.object.isRequired,
    currentHub: PropTypes.object.isRequired,
    setAlert: PropTypes.func.isRequired,
    setAttachments: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      attachmentListModified: false,
      clientUploadLink: "",
      copyModalOpen: false,
      generating: false,
      deletingAttachments: [],
      uploads: [],
      viewAll: false,
      localAlert: null,
    };
    this.linkRef = React.createRef();
  }

  handleChange = (selectorFiles) => {
    const { client, setAlert, setAttachments } = this.props;
    const uploads = [];
    Array.from(selectorFiles).forEach((file) => {
      uploads.push({
        name: file.name,
        uploadTime: file.lastModifiedDate,
        size: file.size,
      });
      this.setState({ uploads: uploads });
      if (file.size > 26214400) {
        setAlert({
          type: "warning",
          text: "Unable to upload file sizes 25MB or greater. Please verify file size before retrying.",
        });
        this.setState({ uploads: [] });
      } else {
        CreateAttachment(client.id, { fileName: file.name })
          .then((response) => {
            fetch(response.data.url, {
              method: "PUT",
              headers: { "Content-Type": file.type },
              body: file,
            }).then(() => {
              GetAttachments(client.id).then((getResponse) => {
                setAttachments(getResponse.data.result.attachments);
                this.setState({
                  attachmentListModified: true,
                  uploads: [],
                  viewAll: true,
                });
              });
            });
          })
          .catch((error) => {
            console.warn(error);
            setAlert({ type: "error", text: "Failed to upload attachment." });
          });
      }
    });
  };

  deleteAttachment = (attachmentName, uploadedBy) => {
    // Handling delete here, rather than in Attachment, as we need to get the updated list after deleting with the
    //  proper number of attachments
    const { deletingAttachments } = this.state;
    const { client, setAlert, setAttachments } = this.props;
    // Handling delete attachments as an array, as more than one can be in the process of being deleted
    let deleteAttachmentsTmp = deletingAttachments;
    deleteAttachmentsTmp.push(attachmentName);
    this.setState((state) => {
      return { deletingAttachments: deleteAttachmentsTmp };
    });
    DeleteAttachment(client.id, { attachmentName, uploadedBy })
      .then(() => {
        GetAttachments(client.id).then((response) => {
          deleteAttachmentsTmp = deletingAttachments;
          deleteAttachmentsTmp.splice(
            deleteAttachmentsTmp.indexOf(attachmentName),
            1
          );
          setAttachments(response.data.result.attachments);
          this.setState((state) => {
            return {
              attachmentListModified: true,
              deletingAttachments: deleteAttachmentsTmp,
            };
          });
        });
      })
      .catch((error) => {
        deleteAttachmentsTmp = deletingAttachments;
        deleteAttachmentsTmp.splice(
          deleteAttachmentsTmp.indexOf(attachmentName),
          1
        );
        this.setState((state) => {
          return {
            deletingAttachments: deleteAttachmentsTmp,
          };
        });
        console.warn(error);
        setAlert({ type: "error", text: "Failed to delete attachment." });
      });
  };

  generateLink = (expiryDays) => {
    const { client, setAlert, currentHub } = this.props;
    this.setState({ generating: true });

    const days = expiryDays || currentHub.preferences?.clientLink?.days;
    const uploadTokenSettings = days
      ? { expiry: Moment().add(Number(days), "days").toDate() }
      : {};

    CreateClientUploadToken({ ...uploadTokenSettings, clientId: client.id })
      .then((response) => {
        const expiryFormatted = Moment(response.expiry).format("MMMM_Do_YYYY");
        const domain = config.clientPortal;
        const link = `${domain}/uploads/${response.token}/Expires_on_${expiryFormatted}`;
        this.setState({ clientUploadLink: link, generating: false });
      })
      .catch((error) => {
        console.warn(error);
        setAlert({
          type: "error",
          text: "Failed to generate client upload link.",
        });
      });
  };

  copyShareURL = () => {
    const { clientUploadLink } = this.state;
    const success = copy(clientUploadLink);
    if (success) {
      this.setState({
        localAlert: {
          message: "Link Copied to Clipboard",
          color: "green",
          icon: "clipboard outline",
        },
      });
    } else {
      this.setState({
        localAlert: {
          message: "There was an error copying the share URL.",
          color: "red",
          icon: "delete",
        },
      });
    }
  };

  render() {
    const {
      attachmentListModified,
      clientUploadLink,
      copyModalOpen,
      deletingAttachments,
      generating,
      uploads,
      viewAll,
      localAlert,
      isClientLinkExpiryPopUpOpen,
    } = this.state;
    const { piiMask } = this.context;

    const { attachments, attachmentsLoading, client, currentHub } = this.props;
    const sortedAttachments = attachments.sort((a, b) => {
      return a.uploadTime <= b.uploadTime ? 1 : -1;
    });
    const attachmentsList = viewAll
      ? sortedAttachments
      : sortedAttachments.slice(0, 2);
    if (!generating && !clientUploadLink && copyModalOpen) this.generateLink();

    const nameOf = (aDefault, { firstName, lastName }) => {
      const presentedName = firstName || lastName;
      return presentedName ? `${presentedName}'s` : aDefault;
    };

    return (
      <div style={{ marginTop: "1em", marginLeft: "0em" }}>
        <Header style={{ display: "inline" }}>
          <Icon name="paperclip" />
          Attachments
          {!attachmentsLoading && ` (${attachments.length})`}
        </Header>
        <Icon
          className="grey_on_hover_circle"
          link
          name="plus"
          onClick={() => {
            this.fileInput.click();
          }}
          style={{ float: "right" }}
          title="Add Attachment"
        />
        {
          // Displaying the anchor only if the copy command exists
          document.queryCommandSupported("copy") && (
            <Icon
              className="grey_on_hover_circle"
              link
              name="linkify"
              onClick={() => {
                this.setState({ copyModalOpen: true });
              }}
              style={{ float: "right" }}
              title="Generate Upload Link"
              data-test="generate-link-button"
            />
          )
        }
        <input
          type="file"
          multiple
          ref={(r) => {
            this.fileInput = r;
          }}
          onChange={(e) => {
            this.handleChange(e.target.files);
            this.fileInput.value = null;
          }}
          hidden
        />
        {!attachmentsLoading &&
          attachmentsList.length === 0 &&
          uploads.length === 0 && (
            <div style={{ color: "grey", marginLeft: "3em" }}>
              {`${
                client.firstName || client.lastName || "The client"
              } has no attachments.`}
            </div>
          )}
        {attachmentsLoading ? (
          <Placeholder style={{ marginLeft: "3em", marginTop: "1em" }}>
            <Placeholder.Header image>
              <Placeholder.Line />
              <Placeholder.Line />
            </Placeholder.Header>
          </Placeholder>
        ) : (
          <div style={{ marginLeft: "3em", marginTop: "1em" }}>
            {uploads.map((attachment) => {
              return (
                <Attachment
                  attachment={attachment}
                  client={client}
                  deleteInProgress={false}
                  deleteAttachment={this.deleteAttachment}
                  uploadInProgress
                  key={attachment.name}
                />
              );
            })}
            {attachmentsList.map((attachment, i) => {
              return (
                <Attachment
                  attachment={attachment}
                  client={client}
                  deleteInProgress={deletingAttachments.includes(
                    attachment.name
                  )}
                  deleteAttachment={this.deleteAttachment}
                  uploadInProgress={false}
                  key={attachment.name}
                  newFile={
                    i < client.newUploadedFileCount && !attachmentListModified
                  }
                />
              );
            })}
            {attachments.length > 2 && (
              <Segment
                basic
                style={{ margin: "0", marginTop: ".25em", padding: "0" }}
                textAlign="center"
              >
                <Button
                  content={viewAll ? "Minimize" : "View All"}
                  onClick={() => {
                    this.setState((state) => {
                      return { viewAll: !state.viewAll };
                    });
                  }}
                  size="mini"
                  style={{ padding: ".5em" }}
                />
              </Segment>
            )}
          </div>
        )}
        <Modal
          open={copyModalOpen}
          size="small"
          onClose={() => {
            this.setState({ copyModalOpen: false });
          }}
        >
          <LocalAlert
            alert={localAlert}
            resetLocalAlert={() => {
              this.setState({ localAlert: null });
            }}
          />
          <Modal.Header style={{ paddingRight: "1.5rem" }}>
            Client Upload Link
            <Icon
              data-test="client-upload-link-close-button"
              fitted
              link
              name="close"
              onClick={() => {
                this.setState({ copyModalOpen: false });
              }}
              style={{ float: "right" }}
            />
          </Modal.Header>
          <Modal.Content image>
            {clientUploadLink ? (
              <div style={{ width: "100%" }}>
                <Input
                  data-test="client-upload-link-value"
                  action={{
                    color: "green",
                    labelPosition: "right",
                    icon: "copy",
                    content: "Copy",
                    onClick: this.copyShareURL,
                  }}
                  className={piiMask("fs-block dd-privacy-mask")}
                  value={clientUploadLink}
                  fluid
                  ref={(ref) => {
                    this.linkRef = ref;
                  }}
                  onFocus={(e) => {
                    e.currentTarget.select();
                  }}
                />
                <div
                  data-test="client-upload-link-desc"
                  style={{ maxWidth: "100%", color: "grey", marginTop: "1em" }}
                >
                  Files uploaded to this URL will be uploaded directly to
                  {` ${nameOf("the", client)} `}
                  {`client card. Please note this link will expire `}
                  <Popup
                    open={isClientLinkExpiryPopUpOpen}
                    onClose={() => {
                      return this.setState({
                        isClientLinkExpiryPopUpOpen: false,
                      });
                    }}
                    onOpen={() => {
                      return this.setState({
                        isClientLinkExpiryPopUpOpen: true,
                      });
                    }}
                    flowing
                    on="click"
                    position="bottom center"
                    trigger={
                      <strong
                        data-test="client-upload-link-expiry-options"
                        className="grey_on_hover clickable"
                      >
                        {`${
                          currentHub.preferences?.clientLink?.days || "30"
                        } days`}
                      </strong>
                    }
                  >
                    <ClientLinkExpiryDaysSelector
                      onClose={() => {
                        return this.setState({
                          isClientLinkExpiryPopUpOpen: false,
                        });
                      }}
                      onUpdated={(days) => {
                        this.generateLink(days);
                        return this.setState({
                          isClientLinkExpiryPopUpOpen: false,
                        });
                      }}
                    />
                  </Popup>
                  {` after creation.`}
                </div>
              </div>
            ) : (
              <div
                style={{
                  display: "block",
                  width: "100%",
                  textAlign: "center",
                  color: "grey",
                }}
              >
                Generating client sharable link...
              </div>
            )}
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentHub: state.hubly.data.hub.selected.hub,
    attachments: state.hubly.data.hub.clientCard.attachments,
    attachmentsLoading: state.hubly.data.hub.clientCard.attachmentsLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAlert: (alert) => {
      dispatch(SetAlert(alert));
    },
    setAttachments: (attachments) => {
      dispatch(SetAttachments(attachments));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CardAttachments);
