import React from "react";
import { Icon } from "semantic-ui-react";
import PropTypes from "prop-types";

const types = {
  TRUST: { name: "Trust", icon: "certificate" },
  BUSINESS: { name: "Business", icon: "suitcase" },
  ORGANIZATION: { name: "Organization", icon: "building" },
};

class ClientTypeInfo extends React.Component {
  static propTypes = {
    client: PropTypes.object.isRequired,
  };

  render() {
    const { client } = this.props;
    const { clientType } = client;
    if (clientType === "PERSON") return null;
    const type = types[clientType];
    return (
      <div
        style={{
          fontSize: "11pt",
          color: "grey",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div>
          <Icon name={type.icon} color="grey" />
        </div>
        <div>{type.name}</div>
      </div>
    );
  }
}

export default ClientTypeInfo;
