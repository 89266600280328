import React, { Component } from "react";
import { Auth } from "aws-amplify";
import { connect } from "react-redux";
import {
  Table,
  Icon,
  Header,
  Modal,
  Form,
  Input,
  Button,
  Message,
} from "semantic-ui-react";
import PropTypes from "prop-types";
import { SetAlert } from "components/Alerts/actions";
import { SetAdvisor, UpdateAdvisor } from "data/advisor/actions";
import { GetAdvisor } from "data/libs/advisor";
import PopupMenu from "components/PopupMenu";
import Anchor from "components/Anchor";
import EditEmailModal from "./EditAdvisorModals";

class UserInformationForm extends Component {
  static propTypes = {
    advisor: PropTypes.shape({
      id: PropTypes.string.isRequired,
      practices: PropTypes.array.isRequired,
    }).isRequired,
    setAdvisor: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired,
    updateAdvisor: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      currentPassword: "",
      email: "",
      errorContent: "",
      errorTitle: "",
      firstName: "",
      infoModalAdvisorId: "",
      infoModalOpen: false,
      lastName: "",
      loading: false,
      newPassword: "",
      newPassword2: "",
      passwordChangeSuccess: false,
      passwordInvalid: false,
      passwordModalOpen: false,
      emailModalOpen: false,
      popupOpenId: null,
      role: "",
      showPassword: false,
    };
  }

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  };

  saveInfoChanges = () => {
    const { email, firstName, infoModalAdvisorId, lastName } = this.state;
    const { setAdvisor, setAlert, updateAdvisor } = this.props;
    this.setState({ loading: true });
    updateAdvisor(
      infoModalAdvisorId,
      { firstName, lastName, email },
      (success) => {
        if (success) {
          GetAdvisor()
            .then((advisor) => {
              setAdvisor(advisor);
              this.setState({ infoModalOpen: false, loading: false });
            })
            .catch((error) => {
              console.error(error);
              this.setState({ loading: false });
              setAlert({ type: "error", text: "Failed to update Hub users." });
            });
        } else {
          setAlert({
            type: "error",
            text: "Failed to update User Information.",
          });
          this.setState({ loading: false });
        }
      }
    );
  };

  savePasswordChanges = async () => {
    this.setState({ errorTitle: "", errorContent: "", loading: true });
    const { currentPassword, newPassword, newPassword2 } = this.state;
    if (newPassword !== newPassword2) {
      this.setState({
        errorTitle: "New Passwords do not Match",
        loading: false,
      });
      return;
    }

    Auth.currentAuthenticatedUser()
      .then((user) => {
        Auth.changePassword(user, currentPassword, newPassword)
          .then((response) => {
            this.setState({ loading: false, passwordChangeSuccess: true });
          })
          .catch((error) => {
            console.error(error);
            if (error.code === "LimitExceededException") {
              this.setState({
                errorTitle: "Error: Trying to change your password too often",
              });
            } else if (
              error.code === "InvalidPasswordException" ||
              error.code === "NotAuthorizedException"
            ) {
              this.setState({
                errorTitle: "Invalid Password",
                errorContent:
                  "Forgot your password? Please Sign Out and select ‘Reset it’ from the log-in page.",
              });
            } else if (error.code === "InvalidParameterException") {
              this.setState({ passwordInvalid: true });
            }
          })
          .finally(() => {
            this.setState({ loading: false });
          });
      })
      .catch((err) => {
        console.error(err);
        this.setState({ loading: false });
      });
  };

  openInfoModal = (advisor) => {
    this.setState({
      email: advisor.email,
      firstName: advisor.firstName,
      infoModalAdvisorId: advisor.id,
      infoModalOpen: true,
      lastName: advisor.lastName,
      popupOpenId: null,
      role: advisor.role.title,
    });
  };

  openPasswordModal = (advisor) => {
    this.setState({
      currentPassword: "",
      errorContent: "",
      errorTitle: "",
      newPassword: "",
      newPassword2: "",
      passwordChangeSuccess: false,
      passwordInvalid: false,
      passwordModalOpen: true,
      popupOpenId: null,
      showPassword: false,
    });
  };

  openEmailModal = (advisor) => {
    this.setState({
      email: advisor.email,
      emailModalOpen: true,
      popupOpenId: null,
    });
  };

  closeEmailModal = () => {
    this.setState({
      emailModalOpen: false,
    });
  };

  toggleShowPassword = () => {
    this.setState((state) => {
      return { showPassword: !state.showPassword };
    });
  };

  render() {
    const {
      currentPassword,
      email,
      errorContent,
      errorTitle,
      firstName,
      infoModalOpen,
      lastName,
      loading,
      newPassword,
      newPassword2,
      passwordChangeSuccess,
      passwordInvalid,
      passwordModalOpen,
      emailModalOpen,
      popupOpenId,
      role,
      showPassword,
    } = this.state;

    const { advisor } = this.props;
    const advisors = [];

    advisor.practices.forEach((practice) => {
      practice.advisors.forEach((hubAdvisor) => {
        if (
          !advisors.find((a) => {
            return a.id === hubAdvisor.id;
          })
        ) {
          advisors.push(hubAdvisor);
        }
      });
    });

    return (
      <div id="UserInformationForm" style={{ marginTop: "1em" }}>
        <Header as="h1">User Information</Header>
        <div>
          <Table selectable stackable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Email</Table.HeaderCell>
                <Table.HeaderCell>Role</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {advisors
                .sort((a, b) => {
                  return a.createdAt < b.createdAt;
                })
                .map((loopAdvisor) => {
                  return (
                    <Table.Row key={loopAdvisor.id}>
                      <Table.Cell>{`${loopAdvisor.firstName} ${loopAdvisor.lastName}`}</Table.Cell>
                      <Table.Cell>{loopAdvisor.email}</Table.Cell>
                      <Table.Cell>
                        {loopAdvisor.role.title}
                        <PopupMenu
                          id="UserInformationPopup"
                          trigger={
                            <Icon
                              color="grey"
                              style={{ float: "right" }}
                              name="ellipsis horizontal"
                              link
                              onClick={() => {
                                this.setState({ popupOpenId: loopAdvisor.id });
                              }}
                            />
                          }
                          buttons={[
                            ...(loopAdvisor.id === advisor.id
                              ? [
                                  {
                                    content: "Edit Email Address",
                                    icon: "envelope",
                                    onClick: () => {
                                      this.openEmailModal(loopAdvisor);
                                    },
                                  },
                                ]
                              : []),
                            {
                              content: "Edit User Information",
                              icon: "edit",
                              onClick: () => {
                                this.openInfoModal(loopAdvisor);
                              },
                            },
                            /* I still have absolutely zero idea how this works */
                            ...(loopAdvisor.id === advisor.id
                              ? [
                                  {
                                    content: "Change Password",
                                    icon: "key",
                                    onClick: () => {
                                      this.openPasswordModal(loopAdvisor);
                                    },
                                  },
                                ]
                              : []),
                          ]}
                          open={popupOpenId === loopAdvisor.id}
                          onClose={() => {
                            this.setState({ popupOpenId: null });
                          }}
                        />
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
            </Table.Body>
          </Table>
        </div>
        <EditEmailModal
          closeModal={this.closeEmailModal}
          isModalOpen={emailModalOpen}
          advisorEmail={email}
        />
        <Modal
          onClose={() => {
            this.setState({ passwordModalOpen: false });
          }}
          size="mini"
          open={passwordModalOpen}
          closeOnDimmerClick={false}
          style={{ minWidth: "360px" }}
        >
          <Modal.Header>Change Password</Modal.Header>
          <Modal.Content style={{ paddingTop: "0px" }}>
            {!passwordChangeSuccess ? (
              <Modal.Description style={{ paddingTop: "1em" }}>
                <Form>
                  <Form.Field>
                    <label>Current Password</label>
                    <Input
                      name="currentPassword"
                      type="password"
                      value={currentPassword}
                      onChange={this.handleChange}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>
                      New Password&nbsp;&nbsp;&nbsp;
                      <Anchor onClick={this.toggleShowPassword}>
                        {showPassword ? "Hide" : "Show"}
                      </Anchor>
                    </label>
                    <Input
                      name="newPassword"
                      type={showPassword ? "text" : "password"}
                      value={newPassword}
                      onChange={this.handleChange}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Confirm New Password</label>
                    <Input
                      name="newPassword2"
                      type={showPassword ? "text" : "password"}
                      value={newPassword2}
                      onChange={this.handleChange}
                    />
                  </Form.Field>
                </Form>
                <Message error hidden={!errorTitle.length}>
                  <Message.Header>{errorTitle}</Message.Header>
                  <p>{errorContent}</p>
                </Message>
                {passwordInvalid ? (
                  <Message error hidden={!passwordInvalid}>
                    <Message.Header>Invalid Password</Message.Header>
                    <div style={{ fontSize: "10pt", marginTop: "1em" }}>
                      Passwords must contain:
                      <ul style={{ margin: "0px", paddingLeft: "1em" }}>
                        <li>8 or more characters long</li>
                        <li>Have at least one uppercase letter</li>
                        <li>One lowercase letter and one special character</li>
                      </ul>
                    </div>
                  </Message>
                ) : (
                  <div
                    style={{
                      fontSize: "10pt",
                      color: "grey",
                      marginTop: "1em",
                    }}
                  >
                    Passwords must contain:
                    <ul style={{ margin: "0px", paddingLeft: "1em" }}>
                      <li>8 or more characters long</li>
                      <li>Have at least one uppercase letter</li>
                      <li>One lowercase letter and one special character</li>
                    </ul>
                  </div>
                )}
              </Modal.Description>
            ) : (
              <Modal.Description>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    padding: "3em 0px",
                  }}
                >
                  <div>
                    <Icon name="check circle" size="huge" />
                  </div>
                  <div style={{ padding: "2em 0px", color: "grey" }}>
                    Your password has been reset successfully
                  </div>
                  <div>
                    <Button
                      primary
                      content="Continue"
                      onClick={() => {
                        this.setState({ passwordModalOpen: false });
                      }}
                    />
                  </div>
                </div>
              </Modal.Description>
            )}
          </Modal.Content>
          {!passwordChangeSuccess && (
            <Modal.Actions>
              <Button
                content="Discard Changes"
                basic
                disabled={loading}
                onClick={() => {
                  this.setState({ passwordModalOpen: false });
                }}
              />
              <Button
                positive
                content="Save Changes"
                onClick={this.savePasswordChanges}
                disabled={loading}
                loading={loading}
              />
            </Modal.Actions>
          )}
        </Modal>
        <Modal
          onClose={() => {
            this.setState({ infoModalAdvisorId: "", infoModalOpen: false });
          }}
          size="mini"
          open={infoModalOpen}
          closeOnDimmerClick={false}
          style={{ minWidth: "360px" }}
        >
          <Modal.Header>Edit User Information</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <Form>
                <Form.Field>
                  <label>First Name</label>
                  <Input
                    name="firstName"
                    value={firstName}
                    onChange={this.handleChange}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Last Name</label>
                  <Input
                    name="lastName"
                    value={lastName}
                    onChange={this.handleChange}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Email</label>
                  <Input disabled name="email" value={email} />
                  <div
                    style={{
                      color: "grey",
                      margin: "auto",
                      width: "max-content",
                      marginTop: "0.5em",
                    }}
                  >
                    Please contact support to change email address.
                  </div>
                </Form.Field>
                <Form.Field>
                  <label>Role</label>
                  <Input disabled name="role" value={role} />
                  <div
                    style={{
                      color: "grey",
                      margin: "auto",
                      width: "max-content",
                      marginTop: "0.5em",
                    }}
                  >
                    Please contact support to change roles.
                  </div>
                </Form.Field>
              </Form>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              content="Discard Changes"
              basic
              disabled={loading}
              onClick={() => {
                this.setState({ infoModalOpen: false });
              }}
            />
            <Button
              positive
              content="Save Changes"
              onClick={this.saveInfoChanges}
              disabled={loading}
              loading={loading}
            />
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateAdvisor: (id, request, callback) => {
      dispatch(UpdateAdvisor(id, request, callback));
    },
    setAdvisor: (advisor) => {
      dispatch(SetAdvisor(advisor));
    },
    setAlert: (alert) => {
      dispatch(SetAlert(alert));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserInformationForm);
