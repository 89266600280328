import PropTypes from "prop-types";
import React, { Component } from "react";
import connect from "react-redux/es/connect/connect";
import { withRouter } from "react-router";
import { Button, Icon, Progress } from "semantic-ui-react";
import history from "data/history";
import NewAppIndicator from "scenes/Hubly/NewAppIndicator";
import WorkFlowsMenu from "./components/WorkFlowsMenu";
import "./TopMenu.css";

class TopMenu extends Component {
  static defaultProps = {
    selectedHub: {},
  };

  static propTypes = {
    advisor: PropTypes.shape({
      hubs: PropTypes.array.isRequired,
    }).isRequired,
    inProgress: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    percent: PropTypes.number.isRequired,
    selectedHub: PropTypes.object,
  };

  goToMyHub = () => {
    const { advisor, selectedHub } = this.props;
    const inSettings = history.location.pathname.startsWith("/settings");
    if ((selectedHub && inSettings) || advisor.hubs.length === 0) {
      // console.log("TopMenu/index.jsx:28", `/b/${advisor.hubs[0].hubId}`);
      history.push(`/hub/${selectedHub.hubId}`);
    } else {
      // console.log("TopMenu/index.jsx:31", "/");
      history.push("/");
    }
  };

  render() {
    const { selectedHub, inProgress, loading, percent } = this.props;

    // Quick return to prevent top menu loading while hub being loaded
    if (loading) {
      return null;
    }

    return (
      <div>
        <div
          style={{
            display: "flex",
            border: "none",
            borderBottom: "2px solid #e0e1e2",
            height: "47px",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100vw",
          }}
        >
          <div style={{ width: "calc(45%)" }}>
            <img
              alt="hubly logo"
              style={{
                height: "23px",
                marginLeft: "1em",
                display: "inline-block",
              }}
              src="/images/hubly.png"
            />
            <NewAppIndicator indicator="β" />
          </div>
          <div className="ui secondary menu">
            <Button
              active
              className="item"
              onClick={this.goToMyHub}
              style={{ marginRight: "1em", maxWidth: "200px" }}
            >
              <Icon name="align left" style={{ transform: "rotate(90deg)" }} />
              <span
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {selectedHub ? selectedHub.name : "My Hubs"}
              </span>
            </Button>
          </div>
          <div style={{ marginRight: "1em", width: "calc(45%)" }}>
            <WorkFlowsMenu />
          </div>
        </div>
        {inProgress && (
          <Progress
            active
            attached="bottom"
            className="sync-loader-progress"
            color="blue"
            percent={percent}
            size="tiny"
            style={{
              marginLeft: "-2px",
              marginBottom: 0,
              position: "absolute",
              width: "100vw",
              zIndex: 103,
            }}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    advisor: state.hubly.data.advisor,
    loading: state.hubly.data.hub.selected.loading,
    inProgress: state.hubly.data.hub.selected.inProgress,
    percent: state.hubly.data.hub.selected.percent,
    selectedHub: state.hubly.data.hub.selected.hub,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TopMenu)
);
