import React from "react";
import PropTypes from "prop-types";
import { Checkbox } from "semantic-ui-react";

export function EmptyWorkflowToggle({ filterEmptyWorkflows, onChange }) {
  return (
    <Checkbox
      data-test="filter-menu-hide-empty-workflow-toggle"
      toggle
      checked={filterEmptyWorkflows}
      label="Hide workflows with no clients"
      onChange={(e, { checked }) => {
        onChange({ filterEmptyWorkflows: checked });
      }}
    />
  );
}

EmptyWorkflowToggle.defaultProps = {
  filterEmptyWorkflows: false,
};

EmptyWorkflowToggle.propTypes = {
  filterEmptyWorkflows: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default EmptyWorkflowToggle;
