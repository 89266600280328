import React from "react";
import PropTypes from "prop-types";

import { Header, Divider } from "semantic-ui-react";
import Options from "components/OptionList";

export function FeedSettingsLayout({ header, children }) {
  return (
    <React.Fragment>
      <Header as="h1" style={{ fontSize: "14pt", margin: "0px" }}>
        {header}
      </Header>
      <Divider style={{ margin: "0px" }} />
      <Options>{children}</Options>
    </React.Fragment>
  );
}

FeedSettingsLayout.propTypes = {
  header: PropTypes.string,
  children: PropTypes.node,
};

FeedSettingsLayout.defaultProps = {
  header: "Hub Feed Settings",
  children: null,
};

export default FeedSettingsLayout;
