import React, { createRef } from "react";
import validator from "validator";
import ReactQuill from "react-quill";
import {
  Button,
  Checkbox,
  Modal,
  Input,
  Header,
  List,
  Dropdown,
} from "semantic-ui-react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { SetConfirmationModal } from "components/ConfirmationModal/actions";
import { SetAlert } from "components/Alerts/actions";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import { ToggleTaskDetailsModal } from "components/Task/actions";
import {
  CreateTaskHouseholdMember,
  DeleteTaskHouseholdMember,
} from "data/libs/tasks";
import { SetClientWorkflow } from "data/hub/clientWorkflows/actions";
import TaskLinkItem from "./components/TaskLinkItem";
import {
  AddLinkToTask,
  DeleteLinkFromTask,
  EditLinkInTask,
  EditTaskDetails,
} from "./clientTaskDetailsActions";
import {
  AddLinkToWorkflowTask,
  DeleteLinkFromWorkflowTask,
  EditLinkInWorkflowTask,
  EditWorkflowTaskDetails,
} from "./workflowTaskDetailsActions";

class TaskDetails extends React.Component {
  menuRef = createRef();

  static defaultProps = {
    addingTask: false,
    client: null,
    currentWorkflow: null,
    editTaskState: () => {},
    modalTaskId: "",
  };

  static propTypes = {
    addingTask: PropTypes.bool,
    addLinkToTask: PropTypes.func.isRequired,
    addLinkToWorkflowTask: PropTypes.func.isRequired,
    currentWorkflow: PropTypes.object,
    client: PropTypes.object,
    deleteLinkFromTask: PropTypes.func.isRequired,
    deleteLinkFromWorkflowTask: PropTypes.func.isRequired,
    editLinkInTask: PropTypes.func.isRequired,
    editLinkInWorkflowTask: PropTypes.func.isRequired,
    editTaskDetails: PropTypes.func.isRequired,
    editTaskState: PropTypes.func,
    editWorkflowTaskDetails: PropTypes.func.isRequired,
    expanded: PropTypes.bool.isRequired,
    households: PropTypes.func.isRequired,
    isClientWorkflow: PropTypes.bool.isRequired,
    isTaskDetailsOpen: PropTypes.bool.isRequired,
    modalTaskId: PropTypes.string,
    setAlert: PropTypes.func.isRequired,
    setClientWorkflow: PropTypes.func.isRequired,
    task: PropTypes.object.isRequired,
    toggleExpand: PropTypes.func.isRequired,
    toggleTaskDetailsModal: PropTypes.func.isRequired,
    setConfirmationModal: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const { task } = props;
    this.state = {
      addingLink: false,
      linkTitle: "",
      linkURL: "",
      isClientTask: task.isClientTask,
      title: task.title,
      body: task.description,
      loading: false,
      assignedMemberLoading: false,
      assignedMember: task.householdMembers.length
        ? task.householdMembers[0].id
        : null,
    };
    this.initialAssignedMember = this.state.assignedMember;
  }

  // Added in as this causes spacing issues when using HTML rather than the Quill Delta system
  modules = {
    clipboard: {
      matchVisual: false,
    },
  };

  toggleAddLink = () => {
    this.setState((state) => {
      if (state.addingLink) {
        return {
          addingLink: !state.addingLink,
          linkTitle: "",
          linkURL: "",
        };
      } else {
        return {
          addingLink: !state.addingLink,
        };
      }
    });
  };

  saveTaskDetails = () => {
    const {
      addingTask,
      client,
      editTaskDetails,
      editTaskState,
      editWorkflowTaskDetails,
      isClientWorkflow,
      setAlert,
      task,
      toggleTaskDetailsModal,
    } = this.props;
    if (client) this.addTaskToHouseholdMember();
    const { body, title, isClientTask } = this.state;
    const bodyElement = document.createElement("div");
    bodyElement.innerHTML = body;
    const description = bodyElement.textContent.length > 0 ? body : null;
    if (title.length < 1 && !addingTask) {
      setAlert({
        type: "warning",
        text: "Title cannot be empty. Please verify task name and try again.",
      });
      return;
    }
    if (
      body !== task.description ||
      title !== task.title ||
      isClientTask !== task.isClientTask
    ) {
      this.setState({ loading: true });
      if (!addingTask && isClientWorkflow) {
        editTaskDetails(
          task.id,
          { description, title, isClientTask },
          (success) => {
            this.setState({ loading: false });
            if (success) {
              toggleTaskDetailsModal();
            }
          }
        );
      } else if (!addingTask && !isClientWorkflow) {
        editWorkflowTaskDetails(
          task.id,
          { description, title, isClientTask },
          (success) => {
            this.setState({ loading: false });
            if (success) {
              toggleTaskDetailsModal();
            }
          }
        );
      } else {
        editTaskState("description", description, "edit");
        editTaskState("isClientTask", isClientTask, "edit");
        this.setState({ loading: false });
        toggleTaskDetailsModal();
      }
    } else {
      this.setState({ loading: false });
      toggleTaskDetailsModal();
    }
  };

  confirmRemoveTaskDetails = () => {
    const { setConfirmationModal } = this.props;

    const params = {
      title: "Are you sure?",
      message: `Are you sure you want to remove all the details on this task? This cannot be undone`,
      icon: "delete",
      buttons: [
        {
          text: "Cancel",
        },
        {
          text: "Remove",
          callBack: this.removeTaskDetails,
          color: "red",
        },
      ],
    };
    setConfirmationModal(params);
  };

  removeTaskDetails = () => {
    const {
      addingTask,
      editTaskDetails,
      editWorkflowTaskDetails,
      editTaskState,
      isClientWorkflow,
      setAlert,
      task,
      toggleTaskDetailsModal,
    } = this.props;

    this.setState({ loading: true });

    if (!addingTask && isClientWorkflow) {
      editTaskDetails(
        task.id,
        {
          description: null,
          title: task.title,
          isClientTask: task.isClientTask,
        },
        (success) => {
          this.setState({ loading: false });
          if (success) {
            toggleTaskDetailsModal();
          } else {
            setAlert({
              type: "error",
              text: "Failed to remove all task details.",
            });
          }
        }
      );
    } else if (!addingTask && !isClientWorkflow) {
      editWorkflowTaskDetails(
        task.id,
        {
          description: null,
          title: task.title,
          isClientTask: task.isClientTask,
        },
        (success) => {
          this.setState({ loading: false });
          if (success) {
            toggleTaskDetailsModal();
          }
        }
      );
    } else {
      editTaskState("description", "", "edit");
      this.setState({ loading: false });
      toggleTaskDetailsModal();
    }

    task.links
      .map((link) => {
        return link.id;
      })
      .forEach((id) => {
        this.deleteLink(task.id, id);
      });
  };

  toggleIsClientTask = () => {
    this.setState((prevState) => {
      return { isClientTask: !prevState.isClientTask };
    });
  };

  addLink = () => {
    const { linkTitle, linkURL } = this.state;
    const {
      addingTask,
      addLinkToTask,
      addLinkToWorkflowTask,
      editTaskState,
      isClientWorkflow,
      setAlert,
      task,
    } = this.props;
    if (!validator.isURL(linkURL)) {
      setAlert({
        type: "warning",
        text: `Invalid URL entered. Please verify inputted format and try again.`,
      });
      return;
    }

    if (!addingTask && isClientWorkflow) {
      addLinkToTask(task.id, linkTitle, linkURL);
    } else if (!addingTask && !isClientWorkflow) {
      addLinkToWorkflowTask(task.id, linkTitle, linkURL);
    } else {
      editTaskState(
        "links",
        { id: task.links.length + 1, title: linkTitle, url: linkURL },
        "add"
      );
    }
    this.setState({ linkTitle: "", linkURL: "" });
  };

  deleteLink = (taskId, linkId, callback) => {
    const {
      addingTask,
      editTaskState,
      deleteLinkFromTask,
      deleteLinkFromWorkflowTask,
      isClientWorkflow,
    } = this.props;
    if (!addingTask && isClientWorkflow) {
      deleteLinkFromTask(taskId, linkId, callback);
    } else if (!addingTask && !isClientWorkflow) {
      deleteLinkFromWorkflowTask(taskId, linkId, callback);
    } else {
      editTaskState("links", linkId, "delete");
      callback(true);
    }
  };

  editLink = (taskId, linkId, title, url) => {
    const {
      addingTask,
      editLinkInTask,
      editLinkInWorkflowTask,
      editTaskState,
      isClientWorkflow,
      task,
    } = this.props;

    if (!addingTask && isClientWorkflow) {
      editLinkInTask(taskId, linkId, title, url);
    } else if (!addingTask && !isClientWorkflow) {
      editLinkInWorkflowTask(taskId, linkId, title, url);
    } else {
      const linksCopy = [...task.links];
      const foundLink = linksCopy.find((link) => {
        return link.id === linkId;
      });
      if (!foundLink) {
        console.warn("editLink, Link ID ", linkId, " does not exist");
        return;
      }
      foundLink.title = title;
      foundLink.url = url;
      editTaskState("links", linksCopy, "edit");
    }
  };

  handleChange = (e, { name, value }) => {
    if (name === "title" && value.length > 1023) return;
    this.setState({ [name]: value });
  };

  titleMarkup = () => {
    const { title } = this.state;
    return (
      <div style={{ marginBottom: "1em" }}>
        <Header as="h3" style={{ marginBottom: "0.5em" }}>
          Task Name
        </Header>
        <div>
          <Input
            defaultValue={title}
            name="title"
            fluid
            onChange={this.handleChange}
          />
        </div>
      </div>
    );
  };

  descriptionMarkup = () => {
    const { body } = this.state;
    return (
      <div style={{ marginBottom: "1em" }}>
        <Header as="h3" style={{ marginBottom: "0.5em" }}>
          Description
        </Header>
        <div>
          <React.Fragment>
            <ReactQuill
              placeholder="Add a description... (optional)"
              name="body"
              value={body}
              onChange={(content) => {
                this.setState({ body: content });
              }}
              modules={this.modules}
              style={{ marginBottom: "0.5em" }}
            />
          </React.Fragment>
        </div>
      </div>
    );
  };

  isOpen = () => {
    const { isTaskDetailsOpen, modalTaskId, task } = this.props;
    return task.id === modalTaskId && isTaskDetailsOpen;
  };

  linkMarkup = () => {
    const { addingLink, linkTitle, linkURL } = this.state;
    const { client, task } = this.props;
    const { links } = task;
    return (
      <div style={{ marginBottom: "2em" }}>
        <div>
          <Header as="h3" style={{ display: "inline" }}>
            Links
          </Header>
          {links.length > 0 ? (
            <React.Fragment>
              <List bulleted style={{ marginBottom: "1em" }}>
                {links.map((link) => {
                  return (
                    <TaskLinkItem
                      key={link.id}
                      link={link}
                      task={task}
                      client={client}
                      editLink={this.editLink}
                      deleteLink={this.deleteLink}
                    />
                  );
                })}
              </List>
            </React.Fragment>
          ) : (
            <span
              style={{ color: "grey", marginBottom: "0.5em", display: "block" }}
            >
              This task has no links
            </span>
          )}
        </div>
        {addingLink ? (
          <div>
            <Header as="h4" style={{ marginBottom: "0.5em" }}>
              Add New Link
            </Header>
            <Input
              fluid
              placeholder="Title (optional)"
              style={{ marginBottom: "0.5em" }}
              value={linkTitle}
              onChange={(e) => {
                this.setState({ linkTitle: e.target.value });
              }}
            />
            <Input
              fluid
              placeholder="Link"
              autoFocus
              style={{ marginBottom: "0.5em" }}
              value={linkURL}
              onChange={(e) => {
                this.setState({ linkURL: e.target.value });
              }}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  this.addLink();
                }
              }}
            />
            <Button
              positive
              onClick={this.addLink}
              content="Add"
              style={{ marginRight: "0.5em" }}
            />
            <Icon
              link
              color="grey"
              size="large"
              name="delete"
              onClick={this.toggleAddLink}
            />
          </div>
        ) : (
          <Button basic content="Add Link" onClick={this.toggleAddLink} />
        )}
      </div>
    );
  };

  addTaskToHouseholdMember = () => {
    const { assignedMember } = this.state;
    if (assignedMember === this.initialAssignedMember) return; // no-op
    const {
      addingTask,
      client,
      editTaskState,
      households,
      setClientWorkflow,
      currentWorkflow,
      task,
    } = this.props;
    this.setState({ assignedMemberLoading: true });
    if (!addingTask && !assignedMember) {
      DeleteTaskHouseholdMember(task.id, this.initialAssignedMember)
        .then((response) => {
          this.initialAssignedMember = assignedMember;
          const clientWorkflowCopy = { ...currentWorkflow };
          clientWorkflowCopy.tasks.find((t) => {
            return t.id === task.id;
          }).householdMembers = [];
          setClientWorkflow(clientWorkflowCopy);
          this.setState({ assignedMemberLoading: false });
        })
        .catch((error) => {
          console.error(error);
          this.setState({ assignedMemberLoading: false });
        });
    } else if (!addingTask && assignedMember) {
      const request = {
        householdMemberId: assignedMember,
      };
      CreateTaskHouseholdMember(task.id, request)
        .then((response) => {
          const clientWorkflowCopy = { ...currentWorkflow };
          clientWorkflowCopy.tasks.find((t) => {
            return t.id === task.id;
          }).householdMembers = [response];
          setClientWorkflow(clientWorkflowCopy);
          this.setState({ assignedMemberLoading: false });
        })
        .catch((error) => {
          console.error(error);
          this.setState({ assignedMemberLoading: false });
        });
    } else if (addingTask && !assignedMember) {
      editTaskState("householdMembers", [], "edit");
    } else {
      const foundHouseholdMember = households[
        client.householdId
      ].householdMembers.find((householdMember) => {
        return householdMember.id === assignedMember;
      });
      editTaskState("householdMembers", foundHouseholdMember, "add");
    }
  };

  selectMember = (event, data) => {
    this.setState({ assignedMember: data.value });
  };

  detailsMarkup = () => {
    const { isClientTask, assignedMember, assignedMemberLoading } = this.state;
    const { client, households } = this.props;
    let assignMemberMarkup = null;
    if (client && households[client.household]) {
      const household = households[client.household];
      const options = household.householdMembers.map((member) => {
        return {
          icon:
            member.title === "Head"
              ? { name: "user", color: "blue" }
              : { name: "user", color: "grey" },
          key: member.id,
          text: `${member.client.name} (${member.title})`,
          value: member.id,
        };
      });
      options.unshift({
        icon: { name: "user x", color: "grey" },
        key: "unassigned",
        text: "Unassigned",
        value: null,
      });
      assignMemberMarkup = household && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          This task is for the following household member
          <Dropdown
            selection
            placeholder="Household member"
            style={{ minWidth: " 250px" }}
            options={options}
            value={assignedMember}
            onChange={this.selectMember}
            loading={assignedMemberLoading}
          />
        </div>
      );
    }

    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Header as="h3" style={{ display: "inline" }}>
          Client Details
        </Header>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "1em",
          }}
        >
          The client is responsible for completing this task.
          <Checkbox
            toggle
            checked={isClientTask}
            onChange={this.toggleIsClientTask}
          />
        </div>
        {assignMemberMarkup}
      </div>
    );
  };

  taskHasNonClientHouseholdMember = () => {
    const { client, task } = this.props;
    return task.householdMembers.find((householdMember) => {
      return householdMember.client.id !== client.id;
    });
  };

  taskHasDescription = () => {
    const { task } = this.props;
    return task.description && task.description.length > 0;
  };

  taskHasLink = () => {
    const { task } = this.props;
    return task.links && task.links.length > 0;
  };

  taskHasDetails = () => {
    const { task } = this.props;
    return (
      this.taskHasDescription() ||
      this.taskHasLink() ||
      task.isClientTask ||
      this.taskHasNonClientHouseholdMember()
    );
  };

  renderTrigger = () => {
    const { expanded, task, toggleExpand, toggleTaskDetailsModal } = this.props;
    if (this.taskHasDetails()) {
      return (
        <Icon
          color={expanded ? "blue" : "grey"}
          link
          name="align left"
          onClick={toggleExpand}
          title="Click to show details"
        />
      );
    } else {
      return (
        <Icon.Group
          className="show_on_hover dark_grey_on_hover"
          style={{ fontSize: "13pt", paddingLeft: "0.1em" }}
          onClick={() => {
            toggleTaskDetailsModal(task.id, true);
          }}
          title="Task Details"
        >
          <Icon link name="align left" style={{ margin: 0 }} />
          <Icon
            link
            corner
            name="add"
            style={{ color: "#aaaaaa", textShadow: "none" }}
          />
        </Icon.Group>
      );
    }
  };

  render() {
    const { addingTask, task, toggleTaskDetailsModal } = this.props;
    const { loading } = this.state;
    if (!this.isOpen()) {
      return this.renderTrigger();
    } else {
      return (
        <React.Fragment>
          {this.renderTrigger()}
          <Modal
            on="click"
            open
            onClose={() => {
              toggleTaskDetailsModal();
            }}
            onOpen={() => {
              toggleTaskDetailsModal(task.id, true);
            }}
            context={this.menuRef}
          >
            <Modal.Header>
              <Header>
                <Icon
                  color="grey"
                  link
                  name="close"
                  onClick={() => {
                    toggleTaskDetailsModal();
                  }}
                  style={{ float: "right", fontSize: "14pt" }}
                />
                Edit Details
              </Header>
            </Modal.Header>
            <Modal.Content scrolling>
              {!addingTask && this.titleMarkup()}
              {this.descriptionMarkup()}
              {this.detailsMarkup()}
              {this.linkMarkup()}
            </Modal.Content>
            <Modal.Actions>
              <Button
                loading={loading}
                disabled={loading}
                positive
                content="Save"
                onClick={this.saveTaskDetails}
              />
              {(task.links.length > 0 || task.description) && (
                <Button
                  content="Remove"
                  disabled={loading}
                  loading={loading}
                  negative
                  onClick={this.confirmRemoveTaskDetails}
                  style={{ float: "left" }}
                />
              )}
            </Modal.Actions>
          </Modal>
        </React.Fragment>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    households: state.hubly.data.hub.households,
    isTaskDetailsOpen: state.hubly.task.isTaskDetailsOpen,
    modalTaskId: state.hubly.task.modalTaskId,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    addLinkToTask: (taskId, title, url) => {
      dispatch(AddLinkToTask(props.currentWorkflow.id, taskId, title, url));
    },
    addLinkToWorkflowTask: (taskId, title, url) => {
      dispatch(
        AddLinkToWorkflowTask(props.currentWorkflow.id, taskId, title, url)
      );
    },
    deleteLinkFromTask: (taskId, linkId, callback) => {
      dispatch(
        DeleteLinkFromTask(props.currentWorkflow.id, taskId, linkId, callback)
      );
    },
    deleteLinkFromWorkflowTask: (taskId, linkId, callback) => {
      dispatch(
        DeleteLinkFromWorkflowTask(
          props.currentWorkflow.id,
          taskId,
          linkId,
          callback
        )
      );
    },
    editLinkInWorkflowTask: (taskId, linkId, title, url) => {
      dispatch(
        EditLinkInWorkflowTask(
          props.currentWorkflow.id,
          taskId,
          linkId,
          title,
          url
        )
      );
    },
    editLinkInTask: (taskId, linkId, title, url) => {
      dispatch(
        EditLinkInTask(props.currentWorkflow.id, taskId, linkId, title, url)
      );
    },
    editTaskDetails: (taskId, taskUpdates, callback) => {
      dispatch(
        EditTaskDetails(props.currentWorkflow, taskId, taskUpdates, callback)
      );
    },
    editWorkflowTaskDetails: (taskId, taskUpdates, callback) => {
      dispatch(
        EditWorkflowTaskDetails(
          props.currentWorkflow,
          taskId,
          taskUpdates,
          callback
        )
      );
    },
    setAlert: (alert) => {
      dispatch(SetAlert(alert));
    },
    setClientWorkflow: (clientWorkflow) => {
      dispatch(SetClientWorkflow(clientWorkflow));
    },
    toggleTaskDetailsModal: (taskId, isOpen) => {
      dispatch(ToggleTaskDetailsModal(taskId, isOpen));
    },
    setConfirmationModal: (params) => {
      dispatch(SetConfirmationModal(params));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskDetails);
