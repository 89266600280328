import React, { useContext, useState } from "react";

import { Popup } from "semantic-ui-react";

import { useDropdownData } from "hooks";

import {
  HubContext,
  ToasterContext,
} from "scenes/Hubly/components/Workspace/Provider";

import {
  InputControl,
  ManyToManyList,
  ManyToManyModal,
  PopupLayout,
  TriggerButton,
} from "../ManyToManyManager/components";

export function StreamManager() {
  const {
    fetchHousehold,
    fetchStreams,
    addStream,
    editStream,
    deleteStream,
    fetchClientsWithStreamsInStream,
    addClientToStream,
    removeClientFromStream,
    viewClientCard,
  } = useContext(HubContext);
  const { fireError, fireWarning } = useContext(ToasterContext);
  const [showHouseholdConfirm, setShowHouseholdConfirm] = useState(true);
  const [selectedItem, setSelectedItem] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [popupOpen, setPopupOpen] = useState(false);
  const [adding, setAdding] = useState(false);

  const {
    loading,
    data: streamData,
    loadData,
    loadMoreData,
    hasMoreData,
  } = useDropdownData({
    searchText: searchText,
    type: "stream",
    fetchFunction: fetchStreams,
  });

  return (
    <React.Fragment>
      <div style={{ flex: "0 1 auto", margin: "0 0.5em 0 0" }}>
        <Popup
          data-test="stream-popup"
          position="top right"
          style={{ paddingLeft: 0, paddingRight: 0 }}
          open={popupOpen}
          onClose={(e) => {
            if (e.type !== "mouseleave") {
              setPopupOpen(false);
              setSearchText("");
            }
          }}
          trigger={
            <TriggerButton
              data-test="hub-menu-streams-button"
              icon="align justify"
              text="Streams"
              onClick={() => {
                setPopupOpen(!popupOpen);
                loadData();
              }}
            />
          }
        >
          <PopupLayout
            title="Stream"
            loading={loading || adding}
            inputControl={
              <InputControl
                placeholder="Add a Stream"
                loading={adding}
                onChange={(text) => {
                  setSearchText(text);
                  if (text === "") loadData();
                }}
                onAdd={(stream) => {
                  if (stream === "") {
                    fireWarning(
                      "Input field cannot be empty. Please verify stream name and try again."
                    );
                    return;
                  }
                  setAdding(true);
                  addStream(stream)
                    .then(() => {
                      setSearchText("");
                      loadData();
                      setAdding(false);
                    })
                    .catch((error) => {
                      const { response: { data = {} } = {} } = error;
                      fireError(
                        data.stream ? data.stream : "Failed to add stream",
                        error
                      );
                      setAdding(false);
                    });
                }}
              />
            }
            list={
              <ManyToManyList
                loading={loading}
                showLoadMore={hasMoreData}
                loadMoreData={loadMoreData}
                type="stream"
                items={streamData}
                onClick={(item) => {
                  setSelectedItem(item);
                  setPopupOpen(false);
                  setSearchText("");
                }}
              />
            }
          />
        </Popup>
      </div>

      {selectedItem && (
        <ManyToManyModal
          obj={selectedItem}
          type="stream"
          onClose={() => {
            setSelectedItem(null);
          }}
          updateData={(id, request) => {
            return editStream(id, request)
              .then(loadData)
              .catch((error) => {
                const { response: { data = {} } = {} } = error;
                fireError(
                  data?.stream ? data.stream : "Failed to update stream",
                  error
                );
              });
          }}
          deleteData={(id) => {
            return deleteStream(id)
              .then(loadData)
              .catch((error) => {
                fireError("Failed to delete stream", error);
              });
          }}
          fetchMembers={fetchClientsWithStreamsInStream}
          addMember={(clientId, stream) => {
            return addClientToStream(clientId, stream.id).catch((error) => {
              fireError("Failed to add client to stream.", error);
            });
          }}
          bulkAddMember={(clientId, stream) => {
            return fetchHousehold(clientId)
              .then((household) => {
                return household.householdMembers.reduce((p, member) => {
                  return p.then(() => {
                    return addClientToStream(member.client.id, stream.id);
                  });
                }, Promise.resolve());
              })
              .catch((error) => {
                fireError("Failed to add all clients to stream.", error);
              });
          }}
          removeMember={(clientId, streamId) => {
            return removeClientFromStream(clientId, streamId).catch((error) => {
              fireError("Failed to remove client from stream.", error);
            });
          }}
          onViewClick={viewClientCard}
          showHouseholdConfirm={showHouseholdConfirm}
          onDisableHouseholdConfirm={() => {
            setShowHouseholdConfirm(false);
          }}
          exportProps={{ stream: selectedItem }}
        />
      )}
    </React.Fragment>
  );
}
export default StreamManager;
