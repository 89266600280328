import React from "react";
import PropTypes from "prop-types";
import { ruleActionOptions } from "../../../../../../helper";

export function AddOrRemoveStreamRuleText({ workflowRule: { type, action } }) {
  const byAction = (a) => {
    const { text } = ruleActionOptions.find(({ key }) => {
      return key === a;
    });
    return text;
  };

  const byType = (t) => {
    return t === "ADD_ATTRIBUTES" ? "added" : "removed";
  };

  return (
    <React.Fragment>
      {` will be ${byType(type)} when a client ${byAction(
        action
      )} this workflow`}
    </React.Fragment>
  );
}
AddOrRemoveStreamRuleText.propTypes = {
  workflowRule: PropTypes.object,
};

AddOrRemoveStreamRuleText.defaultProps = {
  workflowRule: {},
};
export default AddOrRemoveStreamRuleText;
