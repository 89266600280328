import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Header, Icon, Input, Modal } from "semantic-ui-react";
import ColorPicker from "components/ColorPicker";

export function SimpleFormModal({
  loading,
  title,
  value,
  children,
  allowEmptyColor,
  allowEmptyName,
  allowEmptyDesc,
  enableNoChangesRule,
  onClosed,
  onClickSave,
}) {
  const { id = "", name = "", color = null, description = "" } = value || {};
  const [inputName, setInputName] = useState(name);
  const [inputColor, setInputColor] = useState(color);
  const [inputDescription, setInputDescription] = useState(description);

  const noChanges = () => {
    return (
      inputName === name &&
      inputColor === color &&
      inputDescription === description
    );
  };

  const handleSave = () => {
    onClickSave({
      id: id,
      name: inputName,
      color: inputColor,
      description: inputDescription,
    });
  };

  return (
    <Modal
      data-test={`create-${title.toLowerCase()}-modal`}
      open
      onClose={() => {
        onClosed();
      }}
      size="small"
    >
      <Modal.Header>
        {id ? "Edit" : "Create"} {title}
        <Icon
          link
          color="grey"
          name="delete"
          style={{ float: "right" }}
          onClick={() => {
            onClosed();
          }}
        />
      </Modal.Header>
      <Modal.Content>
        <div style={{ display: "flex", width: "100%" }}>
          <div style={{ width: "95%" }}>
            <Header as="h5">Name</Header>
            <Input
              fluid
              autoComplete="off"
              name="name"
              value={inputName}
              placeholder={`${title} Name`}
              onChange={(_, { value: val }) => {
                return setInputName(val);
              }}
            />
          </div>
          <div style={{ marginLeft: "1em" }}>
            <Header as="h5">Color</Header>
            <ColorPicker
              allowNone={allowEmptyColor}
              color={inputColor}
              setColor={(c) => {
                setInputColor(c);
              }}
            />
          </div>
        </div>
        <div style={{ marginTop: "1em" }}>
          <Header as="h5">Description</Header>
          <Input
            autoComplete="off"
            fluid
            name="description"
            value={inputDescription}
            placeholder={`${title} Description`}
            onChange={(_, { value: val }) => {
              return setInputDescription(val);
            }}
          />
        </div>
        <div style={{ marginTop: "1em" }}>{children}</div>
      </Modal.Content>
      <Modal.Actions>
        <Button
          positive
          loading={loading}
          content="Save"
          disabled={
            loading ||
            (!allowEmptyColor && inputColor === null) ||
            (!allowEmptyName && inputName.trim().length === 0) ||
            (!allowEmptyDesc && inputDescription.trim().length === 0) ||
            (noChanges() && enableNoChangesRule)
          }
          onClick={handleSave}
        />
      </Modal.Actions>
    </Modal>
  );
}

SimpleFormModal.defaultProps = {
  value: {},
  loading: false,
  children: null,
  allowEmptyColor: true,
  allowEmptyName: false,
  allowEmptyDesc: true,
  enableNoChangesRule: true,
};

SimpleFormModal.propTypes = {
  title: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  value: PropTypes.shape({
    id: PropTypes.string,
    description: PropTypes.string,
    color: PropTypes.string,
    name: PropTypes.string,
  }),
  children: PropTypes.node,
  allowEmptyColor: PropTypes.bool,
  allowEmptyName: PropTypes.bool,
  allowEmptyDesc: PropTypes.bool,
  enableNoChangesRule: PropTypes.bool,
  onClickSave: PropTypes.func.isRequired,
  onClosed: PropTypes.func.isRequired,
};

export default SimpleFormModal;
