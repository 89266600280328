import { API } from "aws-amplify";
import { getAPIReq } from "./authLib";

export const PostCheckoutNew = async () => {
  const req = await getAPIReq();
  return API.post("HublyAPI", `/chargebee/checkoutNew/`, req);
};
export const PostCheckoutExisting = async () => {
  const req = await getAPIReq();
  return API.post("HublyAPI", `/chargebee/checkoutExisting/`, req);
};
export const PostPortalSession = async () => {
  const req = await getAPIReq();
  return API.post("HublyAPI", `/chargebee/portalSession/`, req);
};
