import React from "react";
import PropTypes from "prop-types";

import { Popup, Icon, Button } from "semantic-ui-react";

export function PopupMenu({
  open,
  onClose,
  onOpen,
  onBack,
  hasBackButton,
  children,
  ...props
}) {
  return (
    <Popup
      className="hubly_bars_menu"
      hideOnScroll
      on="click"
      onClose={onClose}
      open={open}
      popperModifiers={{
        preventOverflow: {
          boundariesElement: "window",
          enabled: false,
        },
      }}
      offset="10px 10px"
      position="bottom right"
      data-test="workflow-menu-popup"
      trigger={
        <Icon
          link
          name="ellipsis vertical"
          color="grey"
          style={{ float: "right", fontSize: "12pt" }}
          onClick={onOpen}
        />
      }
      {...props}
    >
      <Button.Group basic vertical labeled icon style={{ border: "none" }}>
        {hasBackButton && (
          <Button
            icon="long arrow alternate left"
            content="Back to Main Menu"
            onClick={onBack}
          />
        )}
        {children}
      </Button.Group>
    </Popup>
  );
}

PopupMenu.propTypes = {
  onOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onBack: PropTypes.func,
  hasBackButton: PropTypes.bool,
  open: PropTypes.bool,
  children: PropTypes.node,
};

PopupMenu.defaultProps = {
  children: null,
  open: false,
  hasBackButton: false,
  onBack: () => {},
};

export default PopupMenu;
