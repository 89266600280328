import React, { createRef } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  Button,
  Container,
  Dimmer,
  Icon,
  Loader,
  Popup,
} from "semantic-ui-react";
import Moment from "moment";
import { PrivacyModeContext } from "components/PrivacyMode/Context";
import { SetAlert } from "components/Alerts/actions";
import { SetConfirmationModal } from "components/ConfirmationModal/actions";
import Loading from "components/Loading";
import filesize from "filesize";
import fileDownload from "js-file-download";
import { GetAttachmentDownload } from "data/libs/clients";
import extensionIcons from "data/libs/extensionIcons";
import Anchor from "components/Anchor";

class Attachment extends React.Component {
  static contextType = PrivacyModeContext;

  menuRef = createRef();

  static defaultProps = {
    newFile: true,
  };

  static propTypes = {
    attachment: PropTypes.object.isRequired,
    client: PropTypes.object.isRequired,
    deleteAttachment: PropTypes.func.isRequired,
    deleteInProgress: PropTypes.bool.isRequired,
    newFile: PropTypes.bool,
    setAlert: PropTypes.func.isRequired,
    setConfirmationModal: PropTypes.func.isRequired,
    uploadInProgress: PropTypes.bool.isRequired,
  };

  state = {
    downloadInProgress: false,
    menuOpen: false,
  };

  getIcon = (name) => {
    const index = name.lastIndexOf(".");
    if (index < 0) return "file outline";
    const extension = name.substring(index + 1);
    const fileType = extensionIcons.find((type) => {
      return type.extensions.find((ext) => {
        return ext === extension;
      });
    });
    if (!fileType) return "file outline";
    return fileType.icon;
  };

  download = (inHubly) => {
    const { attachment, client, setAlert } = this.props;
    this.setState({ downloadInProgress: true });
    GetAttachmentDownload(client.id, {
      name: attachment.name,
      uploadedBy: attachment.uploadedBy,
    })
      .then((response) => {
        if (inHubly) {
          fetch(response.data.url, {
            method: "GET",
          })
            .then((fileResponse) => {
              return fileResponse.blob();
            })
            .then((blob) => {
              fileDownload(blob, attachment.name);
              this.setState({ downloadInProgress: false });
            });
        } else {
          window.open(response.data.url);
          this.setState({ downloadInProgress: false });
        }
      })
      .catch((error) => {
        this.setState({ downloadInProgress: false });
        console.warn(error);
        setAlert({ type: "error", text: "Failed to download attachment. " });
      });
  };

  confirmDelete = () => {
    const { attachment, deleteAttachment, setConfirmationModal } = this.props;
    const params = {
      title: "Delete Attachment",
      message: `Are you sure you wish to delete "${attachment.name}"? This cannot be undone.`,
      icon: "delete",
      buttons: [
        { text: "Cancel" },
        {
          text: "Delete Attachment",
          callBack: () => {
            deleteAttachment(attachment.name, attachment.uploadedBy);
          },
          color: "red",
        },
      ],
    };
    setConfirmationModal(params);
  };

  toggleMenu = () => {
    this.setState((state) => {
      return { menuOpen: !state.menuOpen };
    });
  };

  render() {
    const { piiMask } = this.context;
    const { downloadInProgress, menuOpen } = this.state;
    const { attachment, deleteInProgress, newFile, uploadInProgress } =
      this.props;
    return (
      <Dimmer.Dimmable as={Container} dimmed={deleteInProgress}>
        <Loading active={deleteInProgress} inverted />
        <div style={{ display: "flex", marginBottom: "0.75em" }}>
          <div
            style={{
              width: "50px",
              height: "55px",
              display: "flex",
              alignItems: "center",
            }}
          >
            {uploadInProgress || downloadInProgress ? (
              <div>
                <Loader size="small" inline className="invertedLoader" />
              </div>
            ) : (
              <Icon
                style={{ opacity: "0.6" }}
                size="big"
                name={this.getIcon(attachment.name)}
              />
            )}
          </div>
          <div style={{ width: "calc(100% - 70px)" }}>
            <div
              className={piiMask("fs-block dd-privacy-mask")}
              style={{
                width: "100%",
                fontSize: "12pt",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              title={attachment.name}
            >
              {!uploadInProgress ? (
                <Anchor
                  onClick={() => {
                    this.download(false);
                  }}
                >
                  {attachment.name}
                </Anchor>
              ) : (
                attachment.name
              )}
            </div>
            <p
              style={{
                color: "darkgrey",
                fontSize: "10pt",
                lineHeight: "1.2em",
              }}
            >
              {!uploadInProgress ? (
                <React.Fragment>
                  {newFile ? (
                    <Icon
                      color="blue"
                      name="circle"
                      size="tiny"
                      style={{ transform: "translate(0px, -2px)" }}
                    />
                  ) : null}
                  {attachment.uploadedBy === "client"
                    ? "Received at"
                    : "Uploaded at"}
                  {Moment(attachment.uploadTime).format(" h:mma, MMM Do YYYY")}
                </React.Fragment>
              ) : (
                <span>Uploading...</span>
              )}
              <br />
              {filesize(attachment.size)}
            </p>
          </div>
          {!uploadInProgress && (
            <div
              style={{
                marginLeft: "auto",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Popup
                className="hubly_bars_menu"
                flowing
                hideOnScroll
                on="click"
                onClose={this.toggleMenu}
                onOpen={this.toggleMenu}
                open={menuOpen}
                popperModifiers={{
                  preventOverflow: {
                    boundariesElement: "window",
                    enabled: false,
                  },
                }}
                position="right center"
                trigger={<Icon color="grey" name="ellipsis horizontal" link />}
              >
                <Button.Group
                  basic
                  vertical
                  labeled
                  icon
                  style={{ border: "none" }}
                >
                  <Button
                    icon="cloud download"
                    content="Download Attachment"
                    onClick={() => {
                      this.toggleMenu();
                      this.download(true);
                    }}
                  />
                  <Button
                    icon="delete"
                    content="Delete Attachment"
                    onClick={() => {
                      this.toggleMenu();
                      this.confirmDelete();
                    }}
                  />
                </Button.Group>
              </Popup>
            </div>
          )}
          <span ref={this.menuRef} />
        </div>
      </Dimmer.Dimmable>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAlert: (alert) => {
      dispatch(SetAlert(alert));
    },
    setConfirmationModal: (id) => {
      dispatch(SetConfirmationModal(id));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Attachment);
