import { SetWorkflow } from "data/hub/workflows/actions";
import { EditTask } from "data/libs/tasks";
import { AddLink, DeleteLink, EditLink } from "data/libs/links";
import { SetAlert } from "components/Alerts/actions";

export function EditWorkflowTaskDetails(
  workflow,
  taskId,
  taskUpdates,
  callback
) {
  return (dispatch, getState) => {
    const { title, description, isClientTask } = taskUpdates;
    EditTask(taskId, { title, description, isClientTask })
      .then((response) => {
        const workflowCopy = {
          ...getState().hubly.data.hub.workflows[workflow.id],
        };
        if (!workflowCopy) {
          console.warn(
            "Error editing workflow task details, could not find current workflow."
          );
          if (callback) callback(false);
          return;
        }

        const foundTaskIndex = workflowCopy.tasks.findIndex((task) => {
          return task.id === taskId;
        });
        if (foundTaskIndex < 0) {
          console.warn(
            "EditWorkflowTaskDetails, Task ID ",
            taskId,
            " does not exist"
          );
          return;
        }

        workflowCopy.tasks[foundTaskIndex].description = description;
        workflowCopy.tasks[foundTaskIndex].title = title;
        workflowCopy.tasks[foundTaskIndex].isClientTask = isClientTask;
        dispatch(SetWorkflow(workflowCopy));
        if (callback) callback(true);
      })
      .catch((error) => {
        console.error(error);
        if (callback) callback(false);
      });
  };
}

export function AddLinkToWorkflowTask(workflowId, taskId, title, url) {
  return (dispatch, getState) => {
    const workflowCopy = { ...getState().hubly.data.hub.workflows[workflowId] };
    if (!workflowCopy) {
      console.warn(
        "Error adding link to workflow task, could not find current workflow."
      );
      return;
    }

    const foundTaskIndex = workflowCopy.tasks.findIndex((task) => {
      return task.id === taskId;
    });
    if (foundTaskIndex < 0) {
      console.warn(
        "AddLinkToWorkflowTask, Task ID ",
        taskId,
        " does not exist"
      );
      return;
    }

    const request = {
      title: title,
      url: url,
      taskId: taskId,
    };

    AddLink(request)
      .then((response) => {
        workflowCopy.tasks[foundTaskIndex].links.unshift(response);
        dispatch(SetWorkflow(workflowCopy));
      })
      .catch((error) => {
        console.error(error);
        dispatch(SetAlert({ type: "error", text: `Failed to create link.` }));
      });
  };
}

export function DeleteLinkFromWorkflowTask(
  workflowId,
  taskId,
  linkId,
  callback
) {
  return (dispatch, getState) => {
    const workflowCopy = { ...getState().hubly.data.hub.workflows[workflowId] };
    if (!workflowCopy) {
      console.warn(
        "Error deleting workflow task link, could not find current workflow."
      );
      return;
    }

    const foundTask = workflowCopy.tasks.find((task) => {
      return task.id === taskId;
    });
    if (!foundTask) {
      console.warn(
        "DeleteLinkFromWorkflowTask, Task ID ",
        taskId,
        " does not exist"
      );
      return;
    }
    const foundLinkIndex = foundTask.links.findIndex((link) => {
      return link.id === linkId;
    });
    if (foundLinkIndex < 0) {
      console.warn(
        "DeleteLinkFDeleteLinkFromWorkflowTaskromTask, Link ID ",
        linkId,
        " does not exist"
      );
      return;
    }
    foundTask.links.splice(foundLinkIndex, 1);
    dispatch(SetWorkflow(workflowCopy));
    DeleteLink(linkId)
      .then((response) => {
        if (callback) callback(true);
      })
      .catch((error) => {
        if (callback) callback(false);
        console.error(error);
        dispatch(SetAlert({ type: "error", text: `Failed to delete link.` }));
      });
  };
}

export function EditLinkInWorkflowTask(workflowId, taskId, linkId, title, url) {
  return (dispatch, getState) => {
    const workflowCopy = { ...getState().hubly.data.hub.workflows[workflowId] };
    if (!workflowCopy) {
      console.warn(
        "Error editing workflow task link, could not find current workflow."
      );
      return;
    }

    const foundTask = workflowCopy.tasks.find((task) => {
      return task.id === taskId;
    });
    if (!foundTask) {
      console.warn(
        "EditLinkInWorkflowTask, Task ID ",
        taskId,
        " does not exist"
      );
      return;
    }
    const foundLink = foundTask.links.find((link) => {
      return link.id === linkId;
    });
    if (!foundLink) {
      console.warn(
        "EditLinkInWorkflowTask, Link ID ",
        linkId,
        " does not exist"
      );
      return;
    }
    foundLink.title = title;
    foundLink.url = url;
    dispatch(SetWorkflow(workflowCopy));

    const request = {
      title: title,
      url: url,
      taskId: taskId,
    };
    EditLink(linkId, request)
      .then((response) => {
        dispatch(
          SetAlert({ type: "success", text: `Successfully edited link` })
        );
      })
      .catch((error) => {
        console.error(error);
        dispatch(SetAlert({ type: "error", text: `Failed to edit link.` }));
      });
  };
}
