import { useState, useContext, useEffect, useCallback } from "react";

import { useFetch } from "hooks";

import { HubContext } from "scenes/Hubly/components/Workspace/Provider";

export function useView({ updateFilter = () => {} } = {}) {
  const { fetchView, fetchDefaultView } = useContext(HubContext);

  const [currentView, setCurrentView] = useState(undefined);
  const [viewId, setViewId] = useState(undefined);

  const {
    loading: loadingDefaultView,
    data: [defaultViewId],
  } = useFetch(fetchDefaultView);

  const {
    loading,
    reloadData,
    data: [view],
  } = useFetch(fetchView, {
    variables: viewId,
    options: { fetchOnInit: false },
  });

  const callFetchView = useCallback(
    (id) => {
      setViewId(id);
      reloadData();
    },
    [reloadData]
  );

  useEffect(() => {
    if (defaultViewId) callFetchView(defaultViewId);
  }, [defaultViewId, callFetchView]);

  useEffect(() => {
    if (view) {
      setCurrentView(view);
      updateFilter({ type: "filters", value: view.filters });
      updateFilter({ type: "workflows", value: view.workflows });
    }
  }, [view, updateFilter]);

  return {
    loading: loading || loadingDefaultView,
    currentView: currentView,
    updateCurrentView: setCurrentView,
    clearView: () => {
      updateFilter({ type: "clearAll" });
      setCurrentView(undefined);
    },
    fetchView: callFetchView,
  };
}

export default useView;
