import SyncedAPI from "data/libs/syncedAPI";
import { getAPIReq } from "./authLib";

export const CreateClientDate = async (request) => {
  const req = await getAPIReq(request);
  return SyncedAPI.post("HublyAPI", `/clientDates/`, req);
};

export const EditClientDate = async (id, request) => {
  const req = await getAPIReq(request);
  return SyncedAPI.put("HublyAPI", `/clientDates/${id}/`, req);
};

export const DeleteClientDate = async (id) => {
  const req = await getAPIReq();
  return SyncedAPI.del("HublyAPI", `/clientDates/${id}/`, req);
};
