import { API } from "aws-amplify";
import SyncedAPI from "data/libs/syncedAPI";
import { getAPIReq } from "./authLib";

export const FetchMeetingDateTypes = async (hubId) => {
  const req = await getAPIReq();
  return API.get("HublyAPI", `/hubs/${hubId}/meetingDateTypes/`, req);
};

export const CreateMeetingDateType = async (request, hubId) => {
  const req = await getAPIReq(request);
  return SyncedAPI.post("HublyAPI", `/hubs/${hubId}/meetingDateTypes/`, req);
};

export default {};
