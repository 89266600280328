import React from "react";
import { Grid, Icon, Popup } from "semantic-ui-react";
import PropTypes from "prop-types";

export function ClientInStream({
  client,
  viewClientCard,
  onClientStreamRemoved,
  ...props
}) {
  const { id, fullName, streams } = client;

  return (
    <React.Fragment>
      <Grid.Row
        data-test={`stream-member-${fullName}`}
        className="grey_on_hover rounded p25"
        style={{
          paddingTop: "0.25em",
          paddingBottom: "0.25em",
          marginLeft: "1em",
          marginRight: "1em",
        }}
      >
        <Grid.Column width={6}>
          <div {...props}>{fullName}</div>
        </Grid.Column>
        <Grid.Column width={7}>
          <div>
            {streams.map((clientStream) => {
              return (
                <Popup
                  basic
                  inverted
                  content={clientStream.name}
                  key={clientStream.name}
                  popperModifiers={{
                    preventOverflow: {
                      boundariesElement: "window",
                      enabled: false,
                    },
                  }}
                  size="mini"
                  style={{
                    padding: "0.5em",
                  }}
                  trigger={
                    <Icon name="circle" style={{ color: clientStream.color }} />
                  }
                />
              );
            })}
          </div>
        </Grid.Column>
        <Grid.Column textAlign="right" width={3}>
          <Popup
            basic
            inverted
            content="View Client Card"
            popperModifiers={{
              preventOverflow: {
                boundariesElement: "window",
                enabled: false,
              },
            }}
            size="mini"
            style={{
              padding: "0.5em",
            }}
            trigger={
              <Icon
                link
                name="user"
                onClick={() => {
                  viewClientCard(id);
                }}
                style={{ marginRight: "0.5em" }}
              />
            }
          />
          <Popup
            basic
            inverted
            content="Remove from Stream"
            popperModifiers={{
              preventOverflow: {
                boundariesElement: "window",
                enabled: false,
              },
            }}
            size="mini"
            style={{
              padding: "0.5em",
            }}
            trigger={
              <Icon
                link
                name="delete"
                onClick={() => {
                  onClientStreamRemoved(client);
                }}
              />
            }
          />
        </Grid.Column>
      </Grid.Row>
    </React.Fragment>
  );
}

ClientInStream.defaultProps = {
  onClientStreamRemoved: () => {},
  viewClientCard: () => {},
};

ClientInStream.propTypes = {
  client: PropTypes.shape({
    id: PropTypes.string.isRequired,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    fullName: PropTypes.string,
    streams: PropTypes.array,
  }).isRequired,
  viewClientCard: PropTypes.func,
  onClientStreamRemoved: PropTypes.func,
};

export default ClientInStream;
