import React from "react";
import PropTypes from "prop-types";

import { ClientMenu, Assigner } from "./components";

function WithMenu(TileComponent) {
  const withMenu = ({
    data,
    onAssignWorkflow,
    onTopTaskComplete,
    onDelete,
    onMoveUp,
    onArchive,
    context,
    ...props
  }) => {
    const { id, assignedAdvisor, tasksStats } = data;
    const { fetchAssignees } = context;

    return (
      <TileComponent
        context={context}
        data={data}
        menuSlot={
          <ClientMenu
            onClickCompleteTopTask={
              tasksStats?.filteredTasks?.length > 0
                ? () => {
                    return onTopTaskComplete(tasksStats.filteredTasks[0]);
                  }
                : undefined
            }
            onClickDelete={() => {
              return onDelete(id);
            }}
            onClickMoveTop={() => {
              return onMoveUp(id);
            }}
            onClickArchive={() => {
              return onArchive(id);
            }}
          />
        }
        bottomRightSlot={
          <Assigner
            currentAssignee={assignedAdvisor}
            fetchAssignees={fetchAssignees}
            onAssign={(newAssignedId) => {
              return onAssignWorkflow({ assignee: newAssignedId, cwId: id });
            }}
          />
        }
        {...props}
      />
    );
  };

  withMenu.propTypes = {
    context: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    onAssignWorkflow: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onTopTaskComplete: PropTypes.func.isRequired,
    onMoveUp: PropTypes.func.isRequired,
    onArchive: PropTypes.func.isRequired,
  };

  withMenu.defaultProps = {};

  return withMenu;
}

export default WithMenu;
