import React, { useContext, useState } from "react";
import PropTypes from "prop-types";

import { Checkbox } from "semantic-ui-react";

import {
  HubContext,
  ToasterContext,
} from "scenes/Hubly/components/Workspace/Provider";

import getLeftSideMinWidth from "./helper";
import { TaskTitle, TaskLayout } from "./components";
import { Assigner } from "../../../../../ClientTile/components";

export function Task({
  task,
  isClientWorkflow,
  showAssignedAdvisor,
  taskDetailsContent,
  children,
  onTaskCheckboxClick,
  onUpdated,
}) {
  // TODO HUB-2441 - Handle case when called inside client card
  const { fetchAssignees, editTask } = useContext(HubContext);
  const { fireError } = useContext(ToasterContext);
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const leftSideMinWidth = getLeftSideMinWidth({
    isClientWorkflow,
    showAssignedAdvisor,
  });
  const [title, setTitle] = useState("");

  const updateTask = (request) => {
    setLoading(true);
    editTask(task.id, request)
      .then((resp) => {
        setLoading(false);
        setTitle(resp.title);
        setEditMode(false);
        onUpdated(resp);
      })
      .catch((error) => {
        fireError("Failed to update task", error);
        setLoading(false);
      });
  };

  return (
    <TaskLayout
      data-test={`workflow-test-${task.title}`}
      title={task.title}
      editMode={editMode}
      leftSideMinWidth={leftSideMinWidth}
      taskDetailsContent={taskDetailsContent}
      taskCheckbox={
        isClientWorkflow && (
          <Checkbox
            onClick={onTaskCheckboxClick}
            checked={task.completed}
            data-test={`workflow-task-checkbox-${task.title}`}
          />
        )
      }
      assignedAdvisor={
        showAssignedAdvisor && (
          <Assigner
            loading={loading}
            currentAssignee={task?.assignedAdvisor}
            fetchAssignees={fetchAssignees}
            onAssign={(newAssignedId) => {
              if (task?.assignedAdvisorId !== newAssignedId) {
                updateTask({
                  assignedAdvisorId:
                    newAssignedId === "unassigned" ? "" : newAssignedId,
                });
              }
            }}
          />
        )
      }
      taskTitle={
        <TaskTitle
          loading={loading}
          taskId={task.id}
          editMode={editMode}
          title={title || task.title}
          completed={task.completed}
          onTaskNameChange={setTitle}
          onSave={() => {
            if (title === task.title) {
              setEditMode(false);
            } else {
              updateTask({ title: title });
            }
          }}
          onClick={() => {
            setTitle(task.title);
            setEditMode(true);
          }}
          onClose={() => {
            setTitle(task.title);
            setEditMode(false);
          }}
        />
      }
    >
      {children}
    </TaskLayout>
  );
}

Task.propTypes = {
  task: PropTypes.object.isRequired,
  isClientWorkflow: PropTypes.bool,
  showAssignedAdvisor: PropTypes.bool,
  onTaskCheckboxClick: PropTypes.func,
  taskDetailsContent: PropTypes.node,
  children: PropTypes.node,
  onUpdated: PropTypes.func.isRequired,
};

Task.defaultProps = {
  isClientWorkflow: false,
  showAssignedAdvisor: false,
  children: null,
  taskDetailsContent: null,
  onTaskCheckboxClick: () => {},
};
export default Task;
