import { CreateClient, UpdateClientCardOpened } from "data/libs/clients";
import clone from "rfdc";
import {
  BulkAddClientWorkflows,
  CreateClientWorkflow,
  MoveClientWorkflow,
} from "data/libs/clientWorkflows";

export function getHouseholdHead({ client, household }) {
  if (client.household?.id) {
    return household.householdMembers.reduce((currentObj, member) => {
      // Finding household head who isn't the selected client
      if (member.client.id !== client.id && member.title === "Head") {
        return member.client;
      } else {
        return currentObj;
      }
    }, null);
  }
  return null;
}

export function createClient(data) {
  return CreateClient(data);
}

export function updateClientCardOpened(clientId, request) {
  return UpdateClientCardOpened(clientId, request).catch((error) => {
    console.error(error);
  });
}

export function addClientToWorkflow(workflow, client) {
  const request = {
    workflowId: workflow.id,
    clientId: client.id,
  };
  return CreateClientWorkflow(request);
}

export function moveClientWorkflow(clientWorkflowId, workflowId) {
  const req = { startNextOrCreate: true };
  return MoveClientWorkflow(clientWorkflowId, { workflowId, ...req });
}

export async function bulkAddClientsToWorkflow({ workflow, clients }) {
  const deepClone = clone();

  const req = {
    client_workflows: [],
  };
  const requests = [];
  let count = 0;

  clients.forEach((client) => {
    // Batch clients into groups of 25
    if (count > 0 && count % 25 === 0 && req.client_workflows.length > 0) {
      requests.push(deepClone(req));
      req.client_workflows = [];
    }

    req.client_workflows.push({
      workflow_id: workflow.id,
      client_id: client.id,
    });

    count += 1;
  });

  // Push the leftover in
  requests.push(deepClone(req));
  const responses = [];

  for (let i = 0; i < requests.length; i += 1) {
    try {
      // eslint-disable-next-line no-await-in-loop
      await BulkAddClientWorkflows(requests[i]);
    } catch (e) {
      console.error(e);
      responses.push(e);
    }
  }

  if (responses.length >= 1) {
    throw Error("Error bulk adding clients to workflow");
  } else {
    return true;
  }
}

export default addClientToWorkflow;
