import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { SetSelectedHubPreferences } from "data/hub/actions";

import { Header } from "semantic-ui-react";

import NumberSelector from "components/NumberSelector";

export function ClientLinkExpiryDaysSelector({
  currentSettings,
  saveSettings,
  onUpdated,
  onClose,
}) {
  const expiryDays = currentSettings?.clientLink?.days || 30;

  const handleClose = (days, hasUpdated) => {
    if (hasUpdated) {
      saveSettings({
        key: "clientLink",
        value: { days: days },
      });
      onUpdated(days);
    } else {
      onClose();
    }
  };

  return (
    <React.Fragment>
      <Header as="h3">Select Expiry Days</Header>
      <NumberSelector
        initValue={expiryDays}
        label="Days"
        onClose={handleClose}
      />
    </React.Fragment>
  );
}

ClientLinkExpiryDaysSelector.propTypes = {
  currentSettings: PropTypes.shape({
    clientLink: PropTypes.shape({
      days: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  }),
  saveSettings: PropTypes.func.isRequired,
  onUpdated: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

ClientLinkExpiryDaysSelector.defaultProps = {
  currentSettings: undefined,
};

const mapStateToProps = (state) => {
  return {
    currentSettings: state.hubly.data.hub.selected.hub.preferences,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveSettings: (newSettings) => {
      dispatch(SetSelectedHubPreferences(newSettings));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientLinkExpiryDaysSelector);
