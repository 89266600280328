import React from "react";
import PropTypes from "prop-types";

import TileIcon from "components/TileIcon";
import InfoDot from "components/InfoDot";

import { useReminderStatus } from "./hooks";

import { getWaitingOnClientStatus, makeDisplaySettings } from "./helper";

import {
  Layout as ClientTileLayout,
  ClientName,
  TileProgress,
  TileList,
  TileReminders,
} from "./components";

import WithMenu from "./WithMenu";

export function ClientTile({ data, clientTileAppearance, onClick, ...props }) {
  const {
    id,
    name,
    client,
    isNew,
    tasksStats,
    clientWorkflowReminders,
    clientWorkflowStatus,
  } = data;

  const { streams, newUploadedFileCount, firstName, lastName } = client;

  const {
    numReminders,
    oldestReminderTime,
    filteredTasks,
    numFilteredTasks,
    numFilteredTasksCompleted,
  } = tasksStats;

  const { statusText, statusBar } = useReminderStatus(oldestReminderTime);
  const waitingOnClientText = getWaitingOnClientStatus(clientWorkflowStatus);
  const displaySettings = makeDisplaySettings(clientTileAppearance);

  return (
    <ClientTileLayout
      data-test={`client-tile-${firstName}-${lastName}`}
      name={
        <ClientName
          topName={client.topName}
          bottomName={client.bottomName}
          hasTopNamePii={client.hasTopNamePii}
          hasBottomNamePii={client.hasBottomNamePii}
        />
      }
      title={displaySettings.presentWorkflowName(name)}
      borderColor={isNew ? "#82d0ef" : undefined}
      infoSlot={displaySettings
        .showStreams(streams)
        .map(({ id: sId, name: sName, color: sColor }) => {
          return (
            <InfoDot
              key={sId}
              description={sName}
              color={sColor}
              size={streams.length > 9 ? "0.7em" : "1em"}
            />
          );
        })}
      onClick={() => {
        return onClick(id);
      }}
      progressSlot={displaySettings.showProgress(
        <TileProgress
          header={waitingOnClientText || statusText}
          color={statusBar}
          percentage={(numFilteredTasksCompleted / numFilteredTasks) * 100}
        />
      )}
      itemSlot={
        <TileList
          items={filteredTasks
            .filter(displaySettings.displayTasks())
            .map(({ id: taskId, title }) => {
              return { id: taskId, text: title };
            })}
        />
      }
      iconSlot={displaySettings.showIcons(
        <React.Fragment>
          {numReminders > 0 && (
            <TileReminders
              numOfTotalReminders={numReminders}
              statusColor={statusBar}
              displayedReminders={clientWorkflowReminders.map(
                ({ reminder }) => {
                  return reminder;
                }
              )}
            />
          )}
          <TileIcon
            icon="check square outline"
            text={`${numFilteredTasksCompleted}/${numFilteredTasks}`}
          />
          {newUploadedFileCount > 0 && (
            <TileIcon
              icon="attach"
              dotColor="blue"
              text={String(newUploadedFileCount)}
            />
          )}
        </React.Fragment>
      )}
      {...props}
    />
  );
}

ClientTile.propTypes = {
  data: PropTypes.object.isRequired,
  clientTileAppearance: PropTypes.shape({
    showStreams: PropTypes.bool,
    showProgress: PropTypes.bool,
    showIcons: PropTypes.bool,
    numberOfTasks: PropTypes.number,
    presentWorkflowName: PropTypes.bool,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
};

ClientTile.defaultProps = {};

export default WithMenu(ClientTile);
