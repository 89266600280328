import React from "react";
import { connect } from "react-redux";
import { Button, Icon, Popup, Input } from "semantic-ui-react";
import PropTypes from "prop-types";
import { PrivacyModeContext } from "components/PrivacyMode/Context";
import DateEditableField from "components/DateEditableField";
import { UpdateClientAttribute } from "../actions";

class ContactEditableField extends React.Component {
  static contextType = PrivacyModeContext;

  static propTypes = {
    client: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
    icon: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    showTitle: PropTypes.bool,
    text: PropTypes.string.isRequired,
    title: PropTypes.string,
    type: PropTypes.string.isRequired,
    updateClientAttribute: PropTypes.func.isRequired,
    editable: PropTypes.bool,
    highlightOnDateColor: PropTypes.string,
    dateObject: PropTypes.object,
    dateType: PropTypes.object,
  };

  static defaultProps = {
    showTitle: true,
    title: "",
    editable: true,
    highlightOnDateColor: null,
    dateObject: null,
    dateType: null,
  };

  state = {
    editing: false,
    loading: false,
    menuOpen: false,
    inputValue: "",
  };

  updatedClient = () => {
    this.setState({ editing: false, loading: false, menuOpen: false });
  };

  getMailToLink = (address) => {
    if (!address) return null;
    return <a href={`mailto:${address}`}>{address}</a>;
  };

  renderStringInfo = () => {
    const { piiMask } = this.context;
    const { icon, showTitle, text, title, editable } = this.props;
    const { loading } = this.state;
    const value = title === "Email" ? this.getMailToLink(text) : text;
    return (
      <div
        className={editable ? "grey_on_hover clickable" : ""}
        onClick={() => {
          this.setState({ menuOpen: true, inputValue: text });
        }}
        style={{
          marginBottom: "0.1em",
          paddingLeft: "1em",
          opacity: loading ? 0.4 : 1.0,
        }}
      >
        <div
          className={piiMask("fs-block dd-privacy-mask")}
          style={{ display: "flex" }}
        >
          <div>
            <Icon color="grey" style={{ marginRight: "0.5em" }} name={icon} />
          </div>
          {showTitle && (
            <div>
              <strong style={{ marginRight: "0.5em" }}>{`${title}:`}</strong>
            </div>
          )}
          {editable
            ? value || <span style={{ color: "grey" }}>{`Add ${title}`}</span>
            : value || (
                <span
                  style={{ color: "grey" }}
                  title="Not currently supported for Redtail CRM"
                >
                  Not set
                </span>
              )}
        </div>
      </div>
    );
  };

  render() {
    const {
      client,
      icon,
      name,
      showTitle,
      text,
      title,
      type,
      updateClientAttribute,
      editable,
      highlightOnDateColor,
      dateObject,
      dateType,
      ...restProps
    } = this.props;
    const { editing, menuOpen, inputValue } = this.state;
    const { piiMask } = this.context;

    if (type === "string") {
      if (editing) {
        return (
          <div style={{ display: "flex", marginLeft: "0.75em" }}>
            <Icon
              color="grey"
              style={{ margin: "0.22em 0.5em 0 0.23em" }}
              name={icon}
            />
            <div style={{ flexGrow: 1 }}>
              <Input
                className={piiMask("fs-block dd-privacy-mask")}
                onChange={(e, { value }) => {
                  this.setState({ inputValue: value });
                }}
                placeholder={title}
                size="mini"
                value={inputValue}
                style={{ marginBottom: "0.5em" }}
                fluid
              />
              <Button
                color="green"
                compact
                content="Save"
                onClick={() => {
                  this.setState({ loading: true });
                  updateClientAttribute(
                    client,
                    name,
                    inputValue,
                    this.updatedClient
                  );
                }}
                size="mini"
                style={{ marginBottom: "0.5em" }}
              />
              <Icon
                color="grey"
                link
                name="delete"
                onClick={() => {
                  this.setState({ editing: false });
                }}
              />
            </div>
          </div>
        );
      } else if (editable) {
        return (
          <Popup
            className="hubly_bars_menu"
            on="click"
            onClose={() => {
              this.setState({ menuOpen: false });
            }}
            open={menuOpen}
            popperModifiers={{
              preventOverflow: { boundariesElement: "window", enabled: false },
            }}
            position="right center"
            trigger={this.renderStringInfo()}
          >
            <Button.Group
              basic
              vertical
              labeled
              icon
              style={{ border: "none" }}
            >
              <Button
                icon="edit"
                content={`Edit ${title}`}
                onClick={() => {
                  this.setState({ editing: true, menuOpen: false });
                }}
              />
              {!!inputValue && (
                <Button
                  icon="close"
                  content={`Clear ${title}`}
                  onClick={() => {
                    this.setState({ loading: true, menuOpen: false });
                    updateClientAttribute(
                      client,
                      name,
                      null,
                      this.updatedClient
                    );
                  }}
                />
              )}
            </Button.Group>
          </Popup>
        );
      } else {
        return this.renderStringInfo();
      }
    } else if (type === "date") {
      if (editable) {
        return (
          <DateEditableField
            highlightOnDateColor={highlightOnDateColor}
            icon={icon}
            name={name}
            showTitle={showTitle}
            text={text}
            title={title}
            dateObject={dateObject}
            dateType={dateType}
            client={client}
            {...restProps}
          />
        );
      } else {
        return this.renderStringInfo();
      }
    }
    return null;
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateClientAttribute: (client, attribute, value, callback) => {
      dispatch(UpdateClientAttribute(client, attribute, value, callback));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactEditableField);
