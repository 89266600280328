import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import { SetHubToLoad } from "data/hub/actions";

function HubLinker({ hub, advisor, currentHubId, setHubToLoad, children }) {
  const history = useHistory();

  if (!hub || hub.hubId !== currentHubId) {
    const advisorHub = advisor.hubs.filter((b) => {
      return b.hubId === currentHubId;
    });

    if (advisorHub && advisorHub.length === 1) {
      setHubToLoad(advisorHub[0]);
    } else {
      history.push("/");
    }

    return null;
  }

  return children;
}

HubLinker.propTypes = {
  children: PropTypes.node,
  setHubToLoad: PropTypes.func.isRequired,
  hub: PropTypes.object,
  advisor: PropTypes.object.isRequired,
  currentHubId: PropTypes.string.isRequired,
};

HubLinker.defaultProps = {
  children: null,
  hub: undefined,
};

const mapStateToProps = (state) => {
  return {
    advisor: state.hubly.data.advisor,
    hub: state.hubly.data.hub.selected.hub,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setHubToLoad: (hub, deepLink) => {
      dispatch(SetHubToLoad(hub, deepLink));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HubLinker);
