import useFetch from "hooks/useFetch";
import { useEffect } from "react";

export function useEditorData(fetch, { id = null, dispatch } = {}) {
  const { loading, data, numOfFetches, reloadData } = useFetch(fetch, {
    variables: id,
  });

  useEffect(() => {
    if (!loading && data.length > 0) {
      dispatch({ type: "set", value: data });
    }
  }, [loading, data, dispatch]);

  return {
    loading: loading,
    numOfFetches: numOfFetches,
    reloadData: reloadData,
  };
}

export default useEditorData;
