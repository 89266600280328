import { API } from "aws-amplify";
import SyncedAPI from "data/libs/syncedAPI";
import { getAPIReq } from "./authLib";

export const GetHouseholds = async (hub) => {
  const req = await getAPIReq();
  return API.get("HublyAPI", `/hubs/${hub.id}/households/`, req);
};

export const UpdateHousehold = async (id, request) => {
  const req = await getAPIReq(request);
  return SyncedAPI.put("HublyAPI", `/households/${id}/`, req, (response) => {
    return { type: "EDIT_HOUSEHOLD", household: response };
  });
};

export const DeleteHousehold = async (id) => {
  const req = await getAPIReq();
  return SyncedAPI.del("HublyAPI", `/households/${id}/`, req, () => {
    return { type: "REMOVE_HOUSEHOLD", householdId: id };
  });
};
