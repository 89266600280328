/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { values, merge, keyBy, keys, omit } from "lodash";
import PropTypes from "prop-types";
import {
  Container,
  Header,
  Icon,
  Placeholder,
  Button,
} from "semantic-ui-react";
import { useFlags } from "launchdarkly-react-client-sdk";

import { GetClientComments, GetClientCommentsPage } from "data/libs/clients";
import { CreateSync, GetSync } from "data/libs/sync";
import { SetAlert } from "components/Alerts/actions";

import CardComment from "./CardComment";
import NewComment from "./NewComment";

function CardCommentsList({ client }) {
  const dispatch = useDispatch();
  const advisor = useSelector((state) => {
    return state.hubly.data.advisor;
  });
  const hub = useSelector((state) => {
    return state.hubly.data.hub.selected.hub;
  });
  const clientWorkflows = useSelector((state) => {
    return state.hubly.data.hub.clientWorkflows;
  });
  const lazyLoadComments = useFlags()["lazy-load-comments"] || false;

  const [lastPage, setLastPage] = useState(0);
  const [isMorePages, setMorePages] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [isLoadingNextPage, setLoadingNextPage] = useState(true);
  const [comments, setComments] = useState([]);
  const pageSizes = [20, 30, 100, 200, 500];

  const removeComments = (commentsToRemove = []) => {
    return values(
      omit(keyBy(comments, "id"), keys(keyBy(commentsToRemove, "id")))
    );
  };

  const removeComment = (aDeletedComment) => {
    setComments(removeComments([{ ...aDeletedComment }]));
  };

  const concatComments = (commentsToAdd = []) => {
    return values(merge(keyBy(comments, "id"), keyBy(commentsToAdd, "id")));
  };

  const addComment = (aNewComment) => {
    setComments(concatComments([{ ...aNewComment }]));
  };

  const getDate = (comment) => {
    return Date.parse(
      comment.externalCreatedAt ? comment.externalCreatedAt : comment.createdAt
    );
  };

  const loadCommentsPage = () => {
    setLoadingNextPage(true);
    const getMinDate = () => {
      return comments.reduce((minDate, comment) => {
        return minDate < getDate(comment) ? minDate : getDate(comment);
      }, new Date());
    };
    const pageSize =
      lastPage >= pageSizes.length
        ? pageSizes[pageSizes.length - 1]
        : pageSizes[lastPage];
    GetClientCommentsPage(client.id, pageSize, getMinDate()).then(
      (response) => {
        setLastPage(lastPage + 1);
        setLoading(false);
        setMorePages(response?.next);
        setComments(concatComments(response.results));
        setLoadingNextPage(false);
      }
    );
  };

  const loadAllComments = () => {
    GetClientComments(client.id).then((response) => {
      setLoading(false);
      setComments(
        concatComments(response.results ? response.results : response)
      );
    });
  };

  const loadComments = () => {
    if (lazyLoadComments) {
      loadCommentsPage();
    } else {
      loadAllComments();
    }
  };

  const checkSyncComplete = (syncId) => {
    GetSync(syncId).then((response) => {
      if (response.status === "failed") {
        dispatch(SetAlert({ type: "error", text: "Failed to sync comments." }));
      } else if (response.status === "completed") {
        loadComments();
      } else {
        setTimeout(() => {
          checkSyncComplete(syncId);
        }, 1000);
      }
    });
  };

  const createSync = () => {
    const request = {
      type: "comments",
      advisor_id: advisor.id,
      client_id: client.id,
      hub_id: hub.id,
    };

    if (
      advisor.integrations.find((i) => {
        return i.hubId === hub.id;
      })
    ) {
      CreateSync(request).then((response) => {
        const syncId = response.id;
        setTimeout(() => {
          checkSyncComplete(syncId);
        }, 10);
      });
    }
  };

  useEffect(() => {
    createSync();
    loadComments();
  }, []);

  if (isLoading) {
    return (
      <div style={{ marginTop: "2em", marginLeft: "0em" }}>
        <Header style={{ display: "inline" }}>
          <Icon name="comment outline" />
          Comments
        </Header>
        <Container style={{ padding: "1em 0em 1em 3em" }}>
          <Placeholder fluid>
            <Placeholder.Header image>
              <Placeholder.Line />
              <Placeholder.Line />
            </Placeholder.Header>
            <Placeholder.Paragraph>
              <Placeholder.Line />
              <Placeholder.Line />
              <Placeholder.Line />
            </Placeholder.Paragraph>
          </Placeholder>
        </Container>
      </div>
    );
  }

  const whereNotHidden = (comment) => {
    return !comment.isHidden;
  };
  const sortByCreatedAt = (a, b) => {
    return getDate(a) <= getDate(b) ? 1 : -1;
  };
  const sortedComments = comments.filter(whereNotHidden).sort(sortByCreatedAt);

  return (
    <div
      data-test="client-card-comments"
      style={{ marginTop: "1em", marginLeft: "0em" }}
    >
      <Header style={{ display: "inline" }}>
        <Icon name="comment outline" />
        Comments
      </Header>
      <NewComment
        clientId={client.id}
        externalId={client.externalId}
        hub={hub}
        onSubmitComment={addComment}
      />
      {sortedComments.length === 0 && (
        <div
          style={{ color: "grey", marginLeft: "3em" }}
          data-test="no-comments-message"
        >
          {`${
            client.firstName || client.lastName || "The client"
          } has no comments.`}
        </div>
      )}
      <Container style={{ padding: "1em 0em 1em 3em" }}>
        {sortedComments.map((comment) => {
          const key = `${comment.id}_${comment.createdAt}`;
          if (comment.taskId) {
            // Hack until we also do separate tasks from client workflows, need to pass in client workflow and task object
            const clientWorkflow = Object.values(clientWorkflows).find((cw) => {
              return cw.tasks.find((task) => {
                return task.id === comment.taskId;
              });
            });
            const task = clientWorkflow?.tasks?.find((t) => {
              return t.id === comment.taskId;
            });
            return (
              <CardComment
                externalId={client.externalId}
                clientWorkflow={clientWorkflow}
                comment={comment}
                task={task}
                key={key}
                onEditClientComment={addComment}
                onDeleteClientComment={removeComment}
              />
            );
          } else {
            return (
              <CardComment
                onEditClientComment={addComment}
                onDeleteClientComment={removeComment}
                externalId={client.externalId}
                comment={comment}
                key={key}
              />
            );
          }
        })}
        {isMorePages && (
          <Button
            loading={isLoadingNextPage}
            onClick={() => {
              loadCommentsPage();
            }}
            data-test="show-more-button"
          >
            Show more...
          </Button>
        )}
      </Container>
    </div>
  );
}

CardCommentsList.propTypes = {
  client: PropTypes.shape({
    externalId: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    clientPoolId: PropTypes.string.isRequired,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    comments: PropTypes.array,
  }).isRequired,
};

export default CardCommentsList;
