import { groupByKeys } from "utils";
import { add, parseISO } from "date-fns";

import { getCombinedStreamsTagsAndClient } from "../../helper";

export function getRepeatingRuleInitialValue(workflowRule) {
  return {
    selectedStreamsTags: getCombinedStreamsTagsAndClient(
      workflowRule?.streams,
      workflowRule?.tags
    ),
    excludeItems: getCombinedStreamsTagsAndClient(
      workflowRule?.streamsExclude,
      workflowRule?.tagsExclude,
      workflowRule?.clientsExclude
    ),
    onlyHouseholdHead:
      workflowRule && "onlyHouseholdHead" in workflowRule
        ? workflowRule.onlyHouseholdHead
        : false,
    repeatSelector: {
      repeat: workflowRule?.repeat || "EVERY",
      interval: workflowRule?.interval ? `${workflowRule?.interval}` : "1",
      frequency: workflowRule?.frequency || "YEARLY",
      weekStartDay: workflowRule?.weekStartDay || "MO",
      dateStart: workflowRule?.dateStart
        ? parseISO(workflowRule?.dateStart)
        : add(new Date(), { days: 1 }),
    },
  };
}

export const getRepeatingRuleModalProp = (workflowRule) => {
  return {
    initialValue: getRepeatingRuleInitialValue(workflowRule),
    title: "Repeating Rule",
    testId: "repeatingRule-modal",
    showExcludeItems: true,
    showHouseholdHead: true,
  };
};

export const formatRepeatingRuleRequest = ({
  excludeItems,
  repeatSelector,
  selectedStreamsTags,
  onlyHouseholdHead,
}) => {
  const {
    streams: streamsExclude,
    tags: tagsExclude,
    clients: clientsExclude,
  } = groupByKeys(excludeItems, ["stream", "tag", "client"]);
  return {
    ...repeatSelector,
    ...groupByKeys(selectedStreamsTags, ["stream", "tag"]),
    type: "REPEATING_RULE",
    interval: parseInt(repeatSelector.interval),
    streamsExclude: streamsExclude,
    tagsExclude: tagsExclude,
    clientsExclude: clientsExclude,
    onlyHouseholdHead: onlyHouseholdHead,
  };
};

export default getRepeatingRuleInitialValue;
