import React from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import PropTypes from "prop-types";
import config from "config";
import history from "data/history";
import Loading from "components/Loading";
import ErrorPage from "components/ErrorPage";
import Hubly from "./scenes/Hubly";
import Layout from "./scenes/Hubly/Layout";
import Settings from "./scenes/Settings";
import "./scenes/Login/Login.css";
import Modals from "./scenes/Modals";
import HubSelector from "./scenes/Hubly/components/Workflows/components/HubSelector";

class AuthenticatedApp extends React.Component {
  static defaultProps = {
    advisor: {},
  };

  static propTypes = {
    advisor: PropTypes.shape({
      id: PropTypes.string,
    }),
    authState: PropTypes.string.isRequired,
  };

  render() {
    const { authState } = this.props;
    if (authState !== "signedIn") {
      return null;
    }
    const { advisor } = this.props;

    // layout includes top menu and this is
    // the most time efficient way to show the 500 page
    const isError = history.location.pathname.startsWith("/error");

    if (isError) {
      return <ErrorPage />;
    }

    // The GET request hasn't succeeded yet
    const { id } = advisor || {};
    if (!id) {
      return (
        <Loading
          active
          messages={["Loading your workspace", "Loading your workflows"]}
        />
      );
    }

    return (
      <React.Fragment>
        <Layout>
          <Switch>
            <Route component={HubSelector} exact key="index" path={["/"]} />
            <Route
              component={Hubly}
              exact
              key="login"
              path={[
                "/",
                "/hub/:hubId",
                "/hub/:hubId/tools",
                `/hub/:hubId/${config.privacyMode?.enableKeyword || "privacy"}`,
                "/hub/:hubId/clients/:clientId",
                "/hub/:hubId/clients/:clientId/workflows/:workflowId",
              ]}
            />
            <Route
              path="/settings/:settingsPage"
              key="settingsPage"
              component={Settings}
            />
            <Route path="/settings" key="settings" component={Settings} />
            <Redirect to="/" />
          </Switch>
        </Layout>
        <Modals />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    advisor: state.hubly.data.advisor,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticatedApp);
