import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Button,
  Dropdown,
  Form,
  Icon,
  Input,
  Header,
  Label,
  Modal,
  Table,
} from "semantic-ui-react";
import PropTypes from "prop-types";
import { SetAlert } from "components/Alerts/actions";
import { SetAdvisor, UpdateAdvisor } from "data/advisor/actions";
import { SetHub } from "data/hub/actions";
import { GetAdvisor } from "data/libs/advisor";
import PopupMenu from "components/PopupMenu";
import { EditHub, CreateHubAdvisor, DeletehubAdvisor } from "data/libs/hubs";

class HubInformationForm extends Component {
  static defaultProps = {
    selectedHub: {},
  };

  static propTypes = {
    advisor: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      hubs: PropTypes.array.isRequired,
      id: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      practices: PropTypes.array.isRequired,
    }).isRequired,
    selectedHub: PropTypes.object,
    setAdvisor: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired,
    setHub: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const allAdvisors = [];

    props.advisor.practices.forEach((practice) => {
      practice.advisors.forEach((advisor) => {
        if (
          !allAdvisors.find((a) => {
            return a.id === advisor.id;
          })
        ) {
          allAdvisors.push(advisor);
        }
      });
    });

    this.state = {
      allAdvisors: allAdvisors,
      initialHubAdvisorIds: [],
      hubAdvisors: [],
      hubModalOpen: false,
      loading: false,
      name: "",
      popupOpenId: null,
    };
  }

  changeSortByLastName = (value, hub) => {
    const { selectedHub, setHub, setAdvisor, advisor } = this.props;
    const newState = {};
    const key = `${hub.id}_sort_loading`;
    newState[key] = true;
    this.setState(newState);
    EditHub(hub.id, { sortByLastName: value })
      .then((response) => {
        if (selectedHub && response.id === selectedHub.id) {
          setHub(response);
        }
        // set the hub inside the advisor object too
        const advisorCopy = { ...advisor };
        const index = advisorCopy.hubs.findIndex((h) => {
          return h.id === response.id;
        });
        if (index >= 0) {
          advisorCopy.hubs[index] = response;
          setAdvisor(advisorCopy);
        }
      })
      .finally(() => {
        newState[key] = false;
        this.setState(newState);
      });
  };

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  };

  save = () => {
    const { selectedHub, setAdvisor, setAlert, setHub, advisor } = this.props;
    const { id, initialHubAdvisorIds, hubAdvisors, name } = this.state;

    const advisorIdsToRemove = initialHubAdvisorIds.filter((i) => {
      return !hubAdvisors.includes(i);
    });
    const advisorIdsToAdd = hubAdvisors.filter((i) => {
      return !initialHubAdvisorIds.includes(i);
    });

    this.setState({ loading: true });
    EditHub(id, { name: name })
      .then((response) => {
        if (selectedHub && response.id === selectedHub.id) {
          setHub(response, advisor);
        }

        const promises = [];

        advisorIdsToRemove.forEach((advisorId) => {
          promises.push(DeletehubAdvisor(id, advisorId));
        });

        advisorIdsToAdd.forEach((advisorId) => {
          promises.push(CreateHubAdvisor(id, { id: advisorId }));
        });

        if (promises.length > 0) {
          Promise.all(promises)
            .then(() => {
              GetAdvisor()
                .then((advisorResponse) => {
                  setAdvisor(advisorResponse);
                  this.setState({ hubModalOpen: false, loading: false });
                })
                .catch((error) => {
                  // This state shouldn't really be possible... return them to settings without updating?
                  console.error(error);
                  this.setState({ hubModalOpen: false, loading: false });
                });
            })
            .catch((error) => {
              console.error(error);
              this.setState({ loading: false });
              setAlert({ type: "error", text: "Failed to update Hub users." });
            });
        } else {
          GetAdvisor()
            .then((advisorResponse) => {
              setAdvisor(advisorResponse);
              this.setState({ hubModalOpen: false, loading: false });
            })
            .catch((error) => {
              // This state shouldn't really be possible... return them to settings without updating?
              console.error(error);
              this.setState({ hubModalOpen: false, loading: false });
            });
        }
      })
      .catch((error) => {
        console.error(error);
        setAlert({ type: "error", text: "Failed to update Hub information." });
        this.setState({ loading: false });
      });
  };

  openHubModal = (hub) => {
    const hubAdvisors = hub.advisors.map((advisor) => {
      return advisor.id;
    });

    this.setState({
      hubAdvisors: hubAdvisors,
      hubModalOpen: true,
      initialHubAdvisorIds: [...hubAdvisors],
      id: hub.id,
      name: hub.name,
      popupOpenId: null,
    });
  };

  handleDropdownChange = (e, { value }) => {
    this.setState({
      hubAdvisors: value,
    });
  };

  render() {
    const {
      allAdvisors,
      hubAdvisors,
      hubModalOpen,
      loading,
      name,
      popupOpenId,
    } = this.state;

    const { advisor } = this.props;

    const advisorOptions = allAdvisors.map((a) => {
      return {
        key: a.id,
        text: `${a.firstName} ${a.lastName}`,
        value: a.id,
      };
    });

    return (
      <div style={{ marginTop: "1em" }}>
        <Header as="h1">Hub Information</Header>
        <div>
          <Table selectable stackable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Members</Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {advisor.hubs
                .sort((a, b) => {
                  return a.createdAt < b.createdAt;
                })
                .map((hub) => {
                  return (
                    <Table.Row key={hub.id}>
                      <Table.Cell>{hub.name}</Table.Cell>
                      <Table.Cell>
                        {hub.advisors.map((hubAdvisor) => {
                          return (
                            <Label
                              content={hubAdvisor.firstName}
                              icon="user circle"
                              key={hubAdvisor.id}
                              style={{ margin: "1px 2px 1px 0" }}
                            />
                          );
                        })}
                      </Table.Cell>
                      <Table.Cell>
                        <PopupMenu
                          trigger={
                            <Icon
                              color="grey"
                              link
                              name="ellipsis horizontal"
                              onClick={() => {
                                this.setState({ popupOpenId: hub.id });
                              }}
                              style={{ float: "right" }}
                            />
                          }
                          buttons={[
                            {
                              content: "Edit Hub",
                              icon: "edit",
                              onClick: () => {
                                this.openHubModal(hub);
                              },
                            },
                          ]}
                          open={popupOpenId === hub.id}
                          onClose={() => {
                            this.setState({ popupOpenId: null });
                          }}
                        />
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
            </Table.Body>
          </Table>
        </div>
        <Header as="h1">Client Settings</Header>
        <div>
          <Table selectable stackable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Ordered By</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {advisor.hubs
                .sort((a, b) => {
                  return a.createdAt < b.createdAt;
                })
                .map((hub) => {
                  return (
                    <Table.Row key={hub.id}>
                      <Table.Cell>{hub.name}</Table.Cell>
                      <Table.Cell>
                        <Dropdown
                          selection
                          options={[
                            { text: "First Name", value: false, key: "first" },
                            { text: "Last Name", value: true, key: "last" },
                          ]}
                          value={hub.sortByLastName}
                          onChange={(e, { value }) => {
                            this.changeSortByLastName(value, hub);
                          }}
                          style={{ minWidth: "150px" }}
                          loading={this.state[`${hub.id}_sort_loading`]}
                          disabled={this.state[`${hub.id}_sort_loading`]}
                        />
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
            </Table.Body>
          </Table>
        </div>
        <Modal
          onClose={() => {
            this.setState({ id: null, hubAdvisors: [], hubModalOpen: false });
          }}
          size="mini"
          open={hubModalOpen}
          closeOnDimmerClick={false}
          style={{ minWidth: "360px" }}
        >
          <Modal.Header>Edit Hub Information</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <Form>
                <Form.Field>
                  <label>Hub Name</label>
                  <Input
                    name="name"
                    value={name}
                    onChange={this.handleChange}
                    autoComplete="off"
                  />
                </Form.Field>
                <Form.Field>
                  <label>Advisors</label>
                  <Dropdown
                    fluid
                    multiple
                    onChange={this.handleDropdownChange}
                    options={advisorOptions}
                    placeholder="Advisors"
                    selection
                    value={hubAdvisors}
                  />
                </Form.Field>
              </Form>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              content="Discard Changes"
              basic
              disabled={loading}
              onClick={() => {
                this.setState({ hubModalOpen: false });
              }}
            />
            <Button
              positive
              content="Save Changes"
              onClick={this.save}
              disabled={loading}
              loading={loading}
            />
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedHub: state.hubly.data.hub.selected.hub,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateAdvisor: (id, request, callback) => {
      dispatch(UpdateAdvisor(id, request, callback));
    },
    setAdvisor: (advisor) => {
      dispatch(SetAdvisor(advisor));
    },
    setAlert: (alert) => {
      dispatch(SetAlert(alert));
    },
    setHub: (hub) => {
      dispatch(SetHub(hub));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HubInformationForm);
