import React, { Component } from "react";
import connect from "react-redux/es/connect/connect";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { Auth } from "aws-amplify/lib/index";
import { datadogRum } from "@datadog/browser-rum";
import { Dropdown, Icon, Responsive } from "semantic-ui-react";
import { SetAlert } from "components/Alerts/actions";
import { SetAdvisor } from "data/advisor/actions";
import history from "data/history";
import config from "config";

class WorkFlowsMenu extends Component {
  static defaultProps = {};

  static propTypes = {
    advisor: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      hubs: PropTypes.array.isRequired,
    }).isRequired,
    userLogout: PropTypes.func.isRequired,
  };

  goToSettings = () => {
    history.push("/settings/account");
  };

  goToFeedback = () => {
    window.open("https://feedback.myhubly.com", "_blank");
  };

  signOut = () => {
    const { userLogout } = this.props;
    Auth.signOut().then(() => {
      if (config.datadog) {
        datadogRum.removeUser();
      }
      userLogout();
    });
  };

  goToRoot = () => {
    history.push("/");
  };

  generateMenu = () => {
    const { advisor } = this.props;
    const options = [];

    if (advisor.hubs.length > 1)
      options.push({
        key: "hubs",
        text: "My Hubs",
        icon: "sitemap",
        onClick: this.goToRoot,
      });
    const settingOption = {
      key: "settings",
      text: "Settings",
      icon: "settings",
      onClick: this.goToSettings,
    };

    // Workaround to disable eslint prettier/prettier error
    options.push({
      ...settingOption,
      "data-test": "top-menu-settings",
    });
    options.push({
      key: "feedback",
      text: "Feature Request",
      icon: "bullhorn",
      onClick: this.goToFeedback,
    });
    options.push({
      key: "sign-out",
      text: "Sign Out",
      icon: "sign-out",
      onClick: this.signOut,
    });

    return options;
  };

  render() {
    const { advisor } = this.props;
    const options = this.generateMenu();
    // TODO: Change to trigger Icon to Image when we allow advisor image uploads in HUB-767
    return (
      <div
        className="grey_on_hover rounded"
        style={{ padding: ".25em .5em 0em .5em" }}
      >
        <Responsive
          as={Dropdown}
          icon={null}
          maxWidth={1350}
          options={options}
          pointing="top right"
          style={{ zIndex: 1000 }}
          trigger={
            <span data-test="top-menu">
              <Icon color="grey" name="user circle" size="large" />
            </span>
          }
        />
        <Responsive
          as={Dropdown}
          icon={null}
          minWidth={1351}
          options={options}
          pointing="top right"
          style={{ zIndex: 1000 }}
          trigger={
            <div
              style={{
                maxWidth: "200px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              <span data-test="top-menu">
                <Icon color="grey" name="user circle" size="large" />
                {advisor.name || "Navigation"}
              </span>
            </div>
          }
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    advisor: state.hubly.data.advisor,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAdvisor: (advisor) => {
      dispatch(SetAdvisor(advisor));
    },
    setAlert: (alert) => {
      dispatch(SetAlert(alert));
    },
    userLogout: () => {
      dispatch({
        type: "USER_LOGOUT",
      });
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(WorkFlowsMenu)
);
