import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Form,
  Grid,
  Header,
  Icon,
  Message,
  Modal,
  Segment,
} from "semantic-ui-react";
import { connect } from "react-redux";
import { SetAlert } from "components/Alerts/actions";
import { SetConfirmationModal } from "components/ConfirmationModal/actions";
import { PrivacyModeContext } from "components/PrivacyMode/Context";
import PopupMenu from "components/PopupMenu";
import {
  ClearClientHousehold,
  SetActiveClient,
  SetClientHousehold,
} from "data/hub/clients/actions";
import {
  AddHouseholdMember,
  EditHousehold,
  EditHouseholdMember,
  RemoveHousehold,
  RemoveHouseholdMember,
} from "data/hub/households/actions";
import { DeleteHousehold, UpdateHousehold } from "data/libs/households";
import {
  CreateHouseholdMember,
  DeleteHouseholdMember,
  UpdateHouseholdMember,
} from "data/libs/householdMembers";
import config from "config";
import HubContextConnector from "scenes/Hubly/components/HubModals/components/ClientCard/components/HubContextConnector";
import { CloseManageHouseholdModal } from "./actions";
import { getOrderedClientName } from "../../data/libs/clients";
import HouseholdClientDropdown from "./components/HouseholdClientDropdown";

class ManageHouseholdModal extends React.Component {
  static contextType = PrivacyModeContext;

  static propTypes = {
    addHouseholdMember: PropTypes.func.isRequired,
    advisor: PropTypes.shape({
      integrations: PropTypes.array.isRequired,
    }).isRequired,
    clearClientHousehold: PropTypes.object.isRequired,
    client: PropTypes.shape({
      id: PropTypes.string.isRequired,
      externalId: PropTypes.string.isRequired,
      householdId: PropTypes.string,
      name: PropTypes.string,
    }).isRequired,
    closeManageHouseholdModal: PropTypes.func.isRequired,
    createHouseholdModalOpen: PropTypes.bool.isRequired,
    editHousehold: PropTypes.func.isRequired,
    editHouseholdMember: PropTypes.func.isRequired,
    household: PropTypes.shape({
      id: PropTypes.string.isRequired,
      householdMembers: PropTypes.array.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    hub: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
    removeHousehold: PropTypes.func.isRequired,
    removeHouseholdMember: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired,
    setClientHousehold: PropTypes.object.isRequired,
    setConfirmationModal: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    // Pre-populate household members list with current client household members or if new, the selected client as Head in edit mode
    let sortedHouseholdMembers = [];
    let editing = false;
    let clientId = "";
    let title = "";
    const householdMembersCopy = [...props.household.householdMembers];
    if (householdMembersCopy.length > 0) {
      sortedHouseholdMembers = householdMembersCopy.sort((a, b) => {
        if (a.title === "Head") return -1;
        if (b.title === "Head") return 1;
        if (a.client.name < b.client.name) return -1;
        if (a.client.name > b.client.name) return 1;
        return 0;
      });
      sortedHouseholdMembers.forEach((householdMember) => {
        householdMember.popupOpen = false;
        householdMember.status = "display";
      });
      if (!props.client || !props.client.householdId) {
        editing = true;
        clientId = props.client.id;
        sortedHouseholdMembers.push({
          clientId: props.client.id,
          client: props.client,
          popupOpen: false,
          title: title,
          status: "add",
        });
      }
    } else {
      editing = true;
      clientId = props.client.id;
      title = "Head";
      sortedHouseholdMembers.push({
        clientId: clientId,
        client: props.client,
        popupOpen: false,
        title: title,
        status: "add",
      });
    }
    this.state = {
      clientId: clientId,
      clientName: clientId ? props.client.name : "",
      editing: editing,
      householdMembers: sortedHouseholdMembers,
      householdName: props.household.name || "",
      loading: false,
      title: title,
      validClient: true,
      validName: true,
      validRole: true,
      warnEdit: false,
    };
  }

  addHouseholdMemberRow = () => {
    this.setState((state) => {
      state.householdMembers.push({
        client: { id: "", name: "" },
        clientId: "",
        popupOpen: false,
        title: "",
        status: "add",
      });
      return {
        clientId: "",
        editing: true,
        householdMembers: state.householdMembers,
        title: "",
        searchValue: "",
      };
    });
  };

  close = () => {
    const { closeManageHouseholdModal } = this.props;
    this.setState({ loading: true });
    closeManageHouseholdModal();
  };

  confirmDelete = () => {
    const { household, setConfirmationModal } = this.props;
    const params = {
      title: "Delete Household",
      message: `Are you sure you wish to delete "${household.name}"? This cannot be undone.`,
      icon: "delete",
      buttons: [
        { text: "Cancel" },
        {
          text: "Delete Household",
          callBack: this.deleteHousehold,
          color: "red",
        },
      ],
    };
    setConfirmationModal(params);
  };

  createHouseholdMember = (index) => {
    const { addHouseholdMember, household, setAlert, setClientHousehold } =
      this.props;
    const { clientId, title } = this.state;
    if (!this.isValidHouseholdMember()) {
      return;
    }
    this.setState({ loading: true });

    const request = {
      clientId: clientId,
      householdId: household.id,
      title: title,
      type: "Person",
    };
    CreateHouseholdMember(request)
      .then((response) => {
        setClientHousehold(clientId, response.householdId);
        addHouseholdMember(response);
        this.setState((state) => {
          const newMember = { ...response };
          newMember.status = "display";
          state.householdMembers.splice(index, 1);
          state.householdMembers.push(newMember);
          return {
            clientId: "",
            clientName: "",
            editing: false,
            loading: false,
            householdMembers: state.householdMembers,
            title: "",
            warnEdit: false,
          };
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.warn(error);
        setAlert({ type: "error", text: "Failed to create household member" });
      });
  };

  deleteHousehold = () => {
    const {
      client,
      clearClientHousehold,
      closeManageHouseholdModal,
      household,
      setAlert,
      removeHousehold,
    } = this.props;
    DeleteHousehold(household.id)
      .then(() => {
        if (client?.id && household?.id) {
          clearClientHousehold(client.id, household.id);
        }
        removeHousehold(household.id);
        setAlert({
          type: "success",
          text: `Successfully deleted household ${household.name}`,
        });
        closeManageHouseholdModal();
      })
      .catch((error) => {
        console.warn("Failed to delete household", household.id);
        console.warn(error);
        setAlert({ type: "error", text: "Failed to delete household" });
      });
  };

  deleteHouseholdMember = (householdMember, index) => {
    const { clearClientHousehold, removeHouseholdMember, setAlert } =
      this.props;
    this.setState({ loading: true });
    this.setPopupOpen(false, index);
    DeleteHouseholdMember(
      householdMember.id,
      householdMember.client.id,
      householdMember.householdId
    )
      .then(() => {
        clearClientHousehold(
          householdMember.clientId,
          householdMember.householdId
        );
        removeHouseholdMember(householdMember.householdId, householdMember.id);
        this.removeHouseholdMemberRow(index);
        this.setState({ loading: false });
        setAlert({
          type: "success",
          text: `Successfully removed household member ${householdMember.client.name}`,
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.warn(error);
        setAlert({ type: "error", text: "Failed to remove household member" });
      });
  };

  updateHousehold = () => {
    const { editing, householdName } = this.state;
    const { closeManageHouseholdModal, editHousehold, household, setAlert } =
      this.props;
    if (editing) {
      this.setState({ warnEdit: true });
      return;
    }
    if (householdName.trim() === "") {
      this.setState({ validName: false });
      return;
    }
    const request = {
      name: householdName,
    };
    UpdateHousehold(household.id, request)
      .then((response) => {
        editHousehold(response);
        closeManageHouseholdModal();
      })
      .catch((error) => {
        console.warn("Failed to update household", household.id);
        console.warn(error);
        setAlert({ type: "error", text: "Failed to update household" });
      });
  };

  updateHouseholdMember = (householdMember, index) => {
    const { editHouseholdMember, household, setAlert } = this.props;
    const { clientId, title } = this.state;
    if (!this.isValidHouseholdMember()) {
      return;
    }
    this.setState({ loading: true });
    const request = {
      clientId: clientId,
      householdId: household.id,
      title: title,
    };
    UpdateHouseholdMember(householdMember.id, request)
      .then((response) => {
        editHouseholdMember(response);
        this.setState((state) => {
          const newMember = { ...response };
          newMember.status = "display";
          state.householdMembers.splice(index, 1, newMember);
          return {
            clientId: "",
            clientName: "",
            editing: false,
            loading: false,
            householdMembers: state.householdMembers,
            title: "",
            warnEdit: false,
          };
        });
        setAlert({
          type: "success",
          text: `Successfully edited household member ${response.client.name}`,
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.warn(error);
        setAlert({ type: "error", text: "Failed to edit household member" });
      });
  };

  updateHouseholdMemberRow = (householdMember, index, editing) => {
    this.setPopupOpen(false, index);
    this.setState((state) => {
      // Remove any rows in the process of being added
      const householdMembers = state.householdMembers.filter((hm) => {
        return hm.status !== "add";
      });
      // Only allow one row to be edited at a time
      householdMembers.forEach((hm) => {
        hm.status = "display";
      });
      householdMembers[index].status = editing ? "edit" : "display";
      return {
        clientId: editing ? householdMember.client.id : "",
        clientName: editing ? householdMember.client.name : "",
        editing: editing,
        householdMembers: householdMembers,
        title: editing ? householdMember.title : "",
        warnEdit: false,
      };
    });
  };

  getRoleOptions = (currentRole) => {
    const { householdMembers } = this.state;
    const currentRoles = householdMembers.map((householdMember) => {
      return householdMember.title;
    });
    const roles = [
      { key: "Spouse", text: "Spouse", value: "Spouse" },
      { key: "Partner", text: "Partner", value: "Partner" },
      { key: "Child", text: "Child", value: "Child" },
      { key: "Grandchild", text: "Grandchild", value: "Grandchild" },
      { key: "Parent", text: "Parent", value: "Parent" },
      { key: "Grandparent", text: "Grandparent", value: "Grandparent" },
      { key: "Sibling", text: "Sibling", value: "Sibling" },
      {
        key: "Other Dependent",
        text: "Other Dependent",
        value: "Other Dependent",
      },
    ];
    if (
      (householdMembers.length > 0 && !currentRoles.includes("Head")) ||
      currentRole === "Head"
    ) {
      roles.unshift({ key: "Head", text: "Head", value: "Head" });
    }
    return roles;
  };

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  };

  isValidHouseholdMember = () => {
    const { clientId, title } = this.state;
    let valid = true;
    if (!clientId) {
      this.setState({ validClient: false });
      valid = false;
    }
    if (!title) {
      this.setState({ validRole: false });
      valid = false;
    }
    return valid;
  };

  removeHouseholdMemberRow = (index) => {
    this.setState((state) => {
      state.householdMembers.splice(index, 1);
      return {
        editing: false,
        householdMembers: state.householdMembers,
        validClient: true,
        validRole: true,
        warnEdit: false,
      };
    });
  };

  setClient = (index, { id, name }) => {
    this.setState((state) => {
      state.householdMembers[index].clientId = id;
      return {
        searchValue: name,
        clientName: name,
        clientId: id,
        householdMembers: state.householdMembers,
        validClient: true,
      };
    });
  };

  setPopupOpen = (popupOpen, index) => {
    this.setState((state) => {
      state.householdMembers[index].popupOpen = popupOpen;
      return { householdMembers: state.householdMembers };
    });
  };

  setRole = (index, e, { value }) => {
    this.setState((state) => {
      state.householdMembers[index].title = value;
      return {
        householdMembers: state.householdMembers,
        title: value,
        validRole: true,
      };
    });
  };

  render() {
    const { piiMask } = this.context;
    const { advisor, client, createHouseholdModalOpen, hub } = this.props;
    const {
      clientId,
      clientName,
      editing,
      householdMembers,
      householdName,
      loading,
      title,
      validClient,
      validName,
      validRole,
      warnEdit,
    } = this.state;

    const errorList = [];
    if (!validClient) {
      errorList.push("Please select a client.");
    }
    if (!validRole) {
      errorList.push("Please select a role.");
    }

    const isRedtailIntegrated =
      advisor.integrations.find((integration) => {
        return integration.hubId === hub.id && integration.type === "redtail";
      }) !== undefined;

    return (
      <Modal
        data-test="manage-household-modal"
        open={createHouseholdModalOpen}
        size="small"
      >
        <Modal.Header>
          <Icon
            link
            style={{ float: "right" }}
            color="grey"
            name="delete"
            onClick={this.close}
          />
          Manage Household
        </Modal.Header>
        <Modal.Content scrolling>
          <Form error warning>
            <Form.Input
              error={!validName}
              fluid
              label={<Header as="h5">Household Name</Header>}
              name="householdName"
              onChange={(e, v) => {
                this.handleChange(e, v);
                this.setState({ validName: true });
              }}
              placeholder="Household Name"
              required
              value={householdName}
            />
            <Message
              error
              header="Missing Household Name"
              hidden={validName}
              list={["Household Name cannot be blank."]}
              negative
            />
            <Header as="h5">Household Members</Header>
            {householdMembers.map((householdMember, index) => {
              const roleOptions = this.getRoleOptions(householdMember.title);
              if (householdMember.status === "display") {
                let buttons = [];
                if (isRedtailIntegrated) {
                  buttons = [
                    {
                      content: "Edit Member in Redtail",
                      icon: "edit",
                      onClick: () => {
                        window.open(
                          `${config.redtailUrl}/contacts/${client.externalId}/family`,
                          "_blank"
                        );
                      },
                    },
                    {
                      content: "Remove Member",
                      icon: "remove",
                      onClick: () => {
                        this.deleteHouseholdMember(householdMember, index);
                      },
                    },
                  ];
                } else {
                  buttons = [
                    {
                      content: "Edit Member",
                      icon: "edit",
                      onClick: () => {
                        this.updateHouseholdMemberRow(
                          householdMember,
                          index,
                          true
                        );
                      },
                    },
                    {
                      content: "Remove Member",
                      icon: "remove",
                      onClick: () => {
                        this.deleteHouseholdMember(householdMember, index);
                      },
                    },
                  ];
                }
                return (
                  <Segment>
                    <Grid divided>
                      <Grid.Row>
                        <Grid.Column
                          className={piiMask("fs-block dd-privacy-mask")}
                          width={8}
                        >
                          {getOrderedClientName(householdMember.client, hub)}
                        </Grid.Column>
                        <Grid.Column width={6}>
                          {householdMember.title}
                        </Grid.Column>
                        <Grid.Column textAlign="center" width={2}>
                          <PopupMenu
                            buttons={buttons}
                            onOpen={() => {
                              this.setPopupOpen(true, index);
                            }}
                            onClose={() => {
                              this.setPopupOpen(false, index);
                            }}
                            open={householdMember.popupOpen}
                            popperModifiers={{
                              preventOverflow: {
                                boundariesElement: "window",
                                enabled: false,
                              },
                            }}
                            trigger={
                              <Icon
                                name="ellipsis horizontal"
                                link
                                color="grey"
                              />
                            }
                          />
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Segment>
                );
              } else {
                return (
                  <Segment>
                    <Grid divided>
                      <Grid.Row>
                        <Grid.Column width={8}>
                          <HubContextConnector>
                            <HouseholdClientDropdown
                              client={{
                                id: clientId,
                                name: clientName,
                              }}
                              excludeMemberIds={householdMembers.map(
                                ({ clientId: cId }) => {
                                  return cId;
                                }
                              )}
                              onClientSelected={(c) => {
                                this.setClient(index, c);
                              }}
                            />
                          </HubContextConnector>
                        </Grid.Column>
                        <Grid.Column width={8}>
                          <Form.Dropdown
                            data-test="household-role-dropdown"
                            fluid
                            error={!validRole}
                            onChange={(e, value) => {
                              this.setRole(index, e, value);
                            }}
                            onSearchChange={(e, value) => {
                              this.setRole(index, e, value);
                            }}
                            options={roleOptions}
                            placeholder="Household Role"
                            search
                            selection
                            selectOnBlur={false}
                            value={title}
                          />
                        </Grid.Column>
                      </Grid.Row>
                      {(!validClient || !validRole) && (
                        <Grid.Row>
                          <Grid.Column width={16}>
                            <Message
                              error
                              header="Required Fields Missing"
                              list={errorList}
                            />
                          </Grid.Column>
                        </Grid.Row>
                      )}
                      {warnEdit && (
                        <Grid.Row>
                          <Grid.Column width={16}>
                            <Message
                              warning
                              header="Unsaved Changes"
                              list={
                                householdMembers.length > 1 ||
                                householdMember.status !== "add"
                                  ? [
                                      "Please save changes to the household member or press the X below to quit editing.",
                                    ]
                                  : [
                                      "Please save changes to the household member.",
                                    ]
                              }
                            />
                          </Grid.Column>
                        </Grid.Row>
                      )}
                    </Grid>
                    {householdMember.status !== "display" && (
                      <div style={{ marginTop: "1em" }}>
                        <Button
                          data-test="assign-button"
                          content={
                            householdMember.status === "add" ? "Add" : "Save"
                          }
                          disabled={loading}
                          loading={loading}
                          onClick={
                            householdMember.status === "add"
                              ? () => {
                                  this.createHouseholdMember(index);
                                }
                              : () => {
                                  this.updateHouseholdMember(
                                    householdMember,
                                    index
                                  );
                                }
                          }
                          positive
                        />
                        {(householdMembers.length > 1 ||
                          householdMember.status !== "add") && (
                          <Icon
                            color="grey"
                            link
                            name="delete"
                            onClick={
                              householdMember.status === "add"
                                ? () => {
                                    this.removeHouseholdMemberRow(index);
                                  }
                                : () => {
                                    this.updateHouseholdMemberRow(
                                      householdMember,
                                      index,
                                      false
                                    );
                                  }
                            }
                            size="large"
                          />
                        )}
                      </div>
                    )}
                  </Segment>
                );
              }
            })}
            {editing ? (
              <div
                style={{ height: "5em" }}
              /> /* Placeholder so the modal content doesn't resize when opening a dropdown */
            ) : (
              <Form.Button
                data-test="start-assign-button"
                basic
                content="Add"
                disabled={loading}
                loading={loading}
                onClick={this.addHouseholdMemberRow}
              />
            )}
          </Form>
        </Modal.Content>
        <Modal.Actions style={{ marginTop: "1em" }}>
          <Button
            data-test="delete-household-button"
            compact
            disabled={loading}
            loading={loading}
            negative
            onClick={() => {
              this.confirmDelete();
            }}
          >
            Delete
          </Button>
          <Button
            data-test="save-household-button"
            compact
            disabled={loading || !validClient || !validRole}
            loading={loading}
            onClick={this.updateHousehold}
            positive
          >
            Save
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    activeClients: state.hubly.data.hub.clients.activeClients,
    allClientNames: state.hubly.data.hub.clients.allClientNames,
    advisor: state.hubly.data.advisor,
    client: state.hubly.data.hub.clients.activeClients[ownProps.clientId],
    createHouseholdModalOpen:
      state.hubly.createHousehold.createHouseholdModalOpen,
    household: state.hubly.data.hub.households[ownProps.householdId],
    hub: state.hubly.data.hub.selected.hub,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addHouseholdMember: (householdMember) => {
      dispatch(AddHouseholdMember(householdMember));
    },
    clearClientHousehold: (clientId, householdId) => {
      dispatch(ClearClientHousehold(clientId, householdId));
    },
    closeManageHouseholdModal: () => {
      dispatch(CloseManageHouseholdModal());
    },
    editHousehold: (household) => {
      dispatch(EditHousehold(household));
    },
    editHouseholdMember: (householdMember) => {
      dispatch(EditHouseholdMember(householdMember));
    },
    removeHousehold: (householdId) => {
      dispatch(RemoveHousehold(householdId));
    },
    removeHouseholdMember: (householdId, householdMemberId) => {
      dispatch(RemoveHouseholdMember(householdId, householdMemberId));
    },
    setActiveClient: (client) => {
      dispatch(SetActiveClient(client));
    },
    setAlert: (alert) => {
      dispatch(SetAlert(alert));
    },
    setClientHousehold: (clientId, householdId) => {
      dispatch(SetClientHousehold(clientId, householdId));
    },
    setConfirmationModal: (params) => {
      dispatch(SetConfirmationModal(params));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageHouseholdModal);
