import React, { useState, useContext } from "react";

import { Button, Header } from "semantic-ui-react";

import PropTypes from "prop-types";

import { ToasterContext } from "scenes/Hubly/components/Workspace/Provider";

import { useDropdownData } from "hooks";

import { MembersList } from "..";
import AddClientSelector from "../AddClientSelector";
import HouseholdConfirmationModal from "../HouseholdConfirmationModal";

export function MembersSection({
  obj,
  addMember,
  bulkAddMember,
  removeMember,
  fetchMembers,
  type,
  showHouseholdConfirm,
  onDisableHouseholdConfirm,
  onViewClick,
}) {
  const { fireSuccess } = useContext(ToasterContext);
  const [adding, setAdding] = useState(false);

  const [loading, setLoading] = useState(false);
  const [clientWithHouseHold, setClientWithHousehold] = useState(null);

  const {
    data: members,
    loading: loadMember,
    hasMoreData,
    loadData,
    loadMoreData,
  } = useDropdownData({
    manyToManyId: obj.id,
    initialLoad: true,
    fetchFunction: fetchMembers,
  });

  const addClient = (clientId) => {
    setClientWithHousehold(null);
    setLoading(true);
    addMember(clientId, obj).then(() => {
      fireSuccess(`Successfully added ${type}`);
      setLoading(false);
      loadData();
    });
  };

  return (
    <React.Fragment>
      <Header as="h5" style={{ marginBottom: "0.5em" }}>
        Members ({members.length})
      </Header>
      <React.Fragment>
        <div style={{ paddingBottom: "2em" }}>
          {!adding ? (
            <Button
              basic
              loading={loading}
              disabled={loading}
              compact
              content="Add"
              onClick={() => {
                setAdding(true);
              }}
            />
          ) : (
            <AddClientSelector
              loading={loading || loadMember}
              members={members}
              onAdd={(client) => {
                if (client.householdId && showHouseholdConfirm) {
                  setClientWithHousehold(client);
                } else {
                  addClient(client.id);
                }
              }}
              onCancel={setAdding}
            />
          )}
        </div>
        <MembersList
          members={members}
          loading={loadMember}
          showLoadMore={hasMoreData}
          loadMoreData={loadMoreData}
          type={type}
          onViewClick={onViewClick}
          onDeleteClick={(clientId) => {
            setLoading(true);
            removeMember(clientId, obj.id).then(() => {
              fireSuccess(`Successfully removed ${type}`);
              setLoading(false);
              loadData();
            });
          }}
        />
      </React.Fragment>

      {clientWithHouseHold && (
        <HouseholdConfirmationModal
          type={type}
          onDisableHouseholdConfirm={onDisableHouseholdConfirm}
          client={clientWithHouseHold}
          addMember={addMember}
          onAddClient={addClient}
          onBulkAddClients={(clientId) => {
            setClientWithHousehold(null);
            setLoading(true);
            bulkAddMember(clientId, obj).then(() => {
              fireSuccess(`Successfully added all clients to ${type}`);
              loadData();
            });
          }}
          onClose={setClientWithHousehold}
        />
      )}
    </React.Fragment>
  );
}

MembersSection.defaultProps = {
  showHouseholdConfirm: true,
  onDisableHouseholdConfirm: () => {},
};

MembersSection.propTypes = {
  obj: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  fetchMembers: PropTypes.func.isRequired,
  addMember: PropTypes.func.isRequired,
  bulkAddMember: PropTypes.func.isRequired,
  removeMember: PropTypes.func.isRequired,
  onViewClick: PropTypes.func.isRequired,
  showHouseholdConfirm: PropTypes.bool,
  onDisableHouseholdConfirm: PropTypes.func,
};

export default MembersSection;
