import React from "react";
import { Container, Header, Icon, Modal, Placeholder } from "semantic-ui-react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { datadogRum } from "@datadog/browser-rum";
import { SetCommentTaskId } from "components/Task/actions";
import { SetActiveClient } from "data/hub/clients/actions";
import { publishUpdate } from "data/messages/actions";
import getMiliSecondDuration from "analytics/helper";
import {
  SetAttachments,
  SetAttachmentsLoading,
} from "data/hub/clientCard/actions";
import {
  GetAttachments,
  GetClient,
  EditClient,
  UpdateClientCardOpened,
  formatFirstNameWithMiddleNameAndNickname,
} from "data/libs/clients";
import history from "data/history";
import {
  FindLastClientWorkflowId,
  SetClientWorkflowLoader,
  UpdateClientWorkFlows,
} from "data/hub/clientWorkflows/actions";
import { makeGetReminders } from "data/reminders/selector";
import { PrivacyModeContext } from "components/PrivacyMode/Context";
import ClickToScroll from "components/ClickToScroll";
import CardWorkflows from "./components/CardWorkflows";
import CardNotes from "./components/CardNotes";
import CardNextWorkflows from "./components/CardNextWorkflows";
import CardViewIntegrations from "./components/CardViewIntegrations";
import CardReminders from "./components/ReminderSection";
import CardCommentsList from "./components/CardCommentsList";
import ContactInformation from "./components/ContactInformation";
import CardAttachments from "./components/CardAttachments";
import CardMeetings from "./components/CardMeetings";
import CardStreams from "./components/CardStreams";
import CardTags from "./components/CardTags";
import "./css/ClientCardModal.css";
import HouseholdInfo from "./components/HouseholdInfo";
import EditClientModal from "./EditClientModal";
import ClientTypeInfo from "./components/ClientTypeInfo";
import withClientWorkflowResolver from "./WithClientWorkflowResolver";

class ClientCardModal extends React.Component {
  static contextType = PrivacyModeContext;

  newCommentRef = React.createRef();

  static defaultProps = {
    workflowId: "",
  };

  static propTypes = {
    advisor: PropTypes.shape({
      id: PropTypes.string.isRequired,
      integrations: PropTypes.array.isRequired,
    }).isRequired,
    hub: PropTypes.object.isRequired,
    client: PropTypes.object.isRequired,
    clientId: PropTypes.string.isRequired,
    clientWorkflows: PropTypes.object.isRequired,
    reminders: PropTypes.array.isRequired,
    setActiveClient: PropTypes.func.isRequired,
    setAttachments: PropTypes.func.isRequired,
    setAttachmentsLoading: PropTypes.func.isRequired,
    setCommentTaskId: PropTypes.func.isRequired,
    publish: PropTypes.func.isRequired,
    workflowId: PropTypes.string,
    fetchingCws: PropTypes.object.isRequired,
    getClientWorkflowByClientId: PropTypes.func.isRequired,
    setClientWorkflowLoaderOn: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.startTime = new Date().getTime();
    const {
      hub,
      clientId,
      workflowId,
      client,
      clientWorkflows,
      getClientWorkflowByClientId,
      setClientWorkflowLoaderOn,
    } = props;

    if (!workflowId && client && client.workflows) {
      const id = FindLastClientWorkflowId(client, null, clientWorkflows);
      if (client && client.workflows && client.workflows.length > 0) {
        history.push(`/hub/${hub.hubId}/clients/${clientId}/workflows/${id}`);
      }
    }

    this.state = {
      switchingClients: false,
      visitWorkflows: new Set(),
    };

    if (props.clientId) {
      setClientWorkflowLoaderOn();
      GetClient(props.clientId)
        .then((response) => {
          props.setActiveClient(response);
        })
        .then(() => {
          getClientWorkflowByClientId({ hub, clientId });
        });

      UpdateClientCardOpened(
        clientId,
        workflowId ? { clientWorkflow: workflowId } : null
      ).catch((error) => {
        console.error(error);
      });
      props.setAttachmentsLoading(true);
      GetAttachments(props.clientId).then((r) => {
        props.setAttachments(r.data.result.attachments);
      });
    }
  }

  componentDidMount() {
    datadogRum.addAction("hubly_timespent_clientmodal_load", {
      duration: getMiliSecondDuration(this.startTime),
    });
  }

  componentDidUpdate(prevProps) {
    const {
      hub,
      client,
      clientId,
      clientWorkflows = {},
      setAttachments,
      setAttachmentsLoading,
      setActiveClient,
      workflowId,
      getClientWorkflowByClientId,
    } = this.props;
    if (clientId !== prevProps.clientId) {
      GetClient(clientId).then((response) => {
        setActiveClient(response);
      });
      getClientWorkflowByClientId({
        hub: hub,
        clientId: clientId,
        skippedLegacy: true,
      });
      setAttachmentsLoading(true);
      GetAttachments(clientId).then((r) => {
        setAttachments(r.data.result.attachments);
      });
    }
    if (!workflowId && client && client.workflows) {
      const id = FindLastClientWorkflowId(client, null, clientWorkflows);
      if (client && client.workflows && client.workflows.length > 0) {
        history.push(`/hub/${hub.hubId}/clients/${clientId}/workflows/${id}`);
      }
    }

    if (
      workflowId !== prevProps.workflowId ||
      clientId !== prevProps.clientId
    ) {
      UpdateClientCardOpened(
        clientId,
        workflowId ? { clientWorkflow: workflowId } : null
      ).catch((error) => {
        console.error(error);
      });
    }

    if (
      workflowId !== prevProps.workflowId ||
      clientId !== prevProps.clientId ||
      clientWorkflows !== prevProps.clientWorkflows
    ) {
      this.addVisitWorkflows(clientWorkflows[workflowId]);
    }
  }

  closeModal = () => {
    const { hub, clientId, setActiveClient, setCommentTaskId } = this.props;
    history.push(`/hub/${hub.hubId}`);

    this.props.publish(this.state.visitWorkflows);
    EditClient(clientId, { newUploadedFileCount: 0 }).then((response) => {
      setActiveClient(response);
    });
    setCommentTaskId(""); // unsets the comment task id so that it doesn't persist on other client cards
  };

  setSwitchingClients = (value) => {
    this.setState({
      switchingClients: value,
    });
  };

  showCardWorkflows = () => {
    const { workflowId, client, reminders, fetchingCws } = this.props;
    if (fetchingCws.error) {
      return (
        <div style={{ marginTop: "2em", marginLeft: "0em" }}>
          <Header as="h3" style={{ display: "inline" }}>
            <Icon name="tasks" />
            Workflows
          </Header>
          <div style={{ marginLeft: "3em" }}>
            <span style={{ color: "red" }}>
              Sorry, we could not load the workflows. Please refresh your
              browser.
            </span>
          </div>
        </div>
      );
    }

    return (
      <CardWorkflows
        client={client}
        workflowId={workflowId}
        reminders={reminders}
        newCommentRef={this.newCommentRef}
      />
    );
  };

  placeholder = () => {
    return (
      <Placeholder fluid>
        <Placeholder.Header image>
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder.Header>
        <Placeholder.Paragraph>
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder.Paragraph>
      </Placeholder>
    );
  };

  addVisitWorkflows(cwf) {
    this.setState(({ visitWorkflows }) => {
      return {
        visitWorkflows: visitWorkflows.add(cwf?.workflowId),
      };
    });
  }

  render() {
    const { advisor, clientId, workflowId, client, hub, fetchingCws } =
      this.props;
    const { switchingClients } = this.state;
    const { piiMask } = this.context;

    const integration = advisor.integrations.find((i) => {
      return i.hubId === hub.id;
    });

    const name = (n, suffix = "") => {
      return (n ? `${n}${suffix}` : "").trimEnd();
    };

    const formattedFirstName = formatFirstNameWithMiddleNameAndNickname(client);

    const topName = hub.sortByLastName
      ? name(client?.lastName, ",")
      : formattedFirstName;
    const bottomName = hub.sortByLastName
      ? formattedFirstName
      : name(client?.lastName);

    const piiMaskTheLastName = (isLastName) => {
      return isLastName ? piiMask("fs-block dd-privacy-mask") : undefined;
    };

    return (
      // onClose needed for close on escape and close when clicking outside the modal
      <Modal
        size="large"
        open
        id="client_card"
        centered={false}
        closeOnEscape
        onClose={() => {
          this.closeModal();
        }}
        data-test="client-card-modal"
      >
        <Modal.Header>
          {client && !switchingClients && client.id === clientId ? (
            <React.Fragment>
              <div
                style={{
                  display: "flex",
                  flexFlow: "row",
                  float: "right",
                  transform: "translateX(-0.25em)",
                  width: 0,
                }}
              >
                <Icon
                  data-test="close-modal-icon"
                  name="close"
                  size="small"
                  onClick={() => {
                    this.closeModal();
                  }}
                  link
                />
              </div>
              <div style={{ display: "flex", flexFlow: "row" }}>
                <div style={{ minWidth: "250px" }}>
                  <div style={{ display: "inline-block" }}>
                    <Header
                      as="h1"
                      className={piiMaskTheLastName(hub.sortByLastName)}
                      style={{ marginBottom: "0px", display: "inline" }}
                    >
                      {client.prefix}
                      {client.prefix && " "}
                      {topName}
                    </Header>
                    {bottomName && (
                      <React.Fragment>
                        <br />
                        <Header
                          as="h3"
                          className={piiMaskTheLastName(!hub.sortByLastName)}
                          style={{
                            marginTop: "0px",
                            fontWeight: "lighter",
                            display: "inline-block",
                          }}
                        >
                          {bottomName}
                          {client.suffix && " "}
                          {client.suffix}
                        </Header>
                      </React.Fragment>
                    )}
                  </div>
                  {client.clientType === "PERSON" ? (
                    <HouseholdInfo
                      clientId={client.id}
                      householdId={client.householdId}
                      setSwitchingClients={(e) => {
                        this.setSwitchingClients(e);
                      }}
                      workflowId={workflowId}
                    />
                  ) : (
                    <ClientTypeInfo client={client} />
                  )}
                </div>
                {!integration && <EditClientModal client={client} />}
                <div style={{ flexGrow: 1, marginLeft: "0.5em" }}>
                  <CardNotes client={client} />
                  <CardStreams client={client} />
                </div>
                <div
                  style={{
                    marginLeft: "1em",
                    fontSize: "10pt",
                    minWidth: "250px",
                  }}
                >
                  <CardMeetings client={client} />
                </div>
              </div>
            </React.Fragment>
          ) : (
            <div style={{ display: "flex", flexFlow: "row" }}>
              <div style={{ flewGrow: "2", flexBasis: "250px" }}>
                <div>
                  <Placeholder>
                    <Placeholder.Line length="long" />
                    <Placeholder.Line length="short" />
                  </Placeholder>
                </div>
              </div>
              <div style={{ flexGrow: 1, marginLeft: "0.5em" }}>
                <Placeholder>
                  <Placeholder.Line />
                  <Placeholder.Line />
                  <Placeholder.Line />
                  <Placeholder.Line />
                </Placeholder>
              </div>
            </div>
          )}
        </Modal.Header>
        <Modal.Content scrolling data-test="client-card-modal-content">
          <ClickToScroll>
            <div style={{ display: "flex", maxWidth: "100%" }}>
              <div
                style={{
                  width: "calc(100% - 250px)",
                  paddingRight: "1em",
                  paddingTop: "2px",
                  flexGrow: 5,
                  paddingBottom: "100px",
                }}
              >
                {client && !switchingClients && client.id === clientId ? (
                  <CardReminders client={client} hub={hub} />
                ) : (
                  <div style={{ marginTop: "2em", marginLeft: "0em" }}>
                    <Header style={{ display: "inline" }}>
                      <Icon name="clock outline" />
                      Upcoming Reminders
                    </Header>
                    <Container style={{ padding: "1em 0em 1em 3em" }}>
                      {this.placeholder()}
                    </Container>
                  </div>
                )}
                {client &&
                !switchingClients &&
                client.id === clientId &&
                !fetchingCws.loading ? (
                  this.showCardWorkflows()
                ) : (
                  <div style={{ marginTop: "2em", marginLeft: "0em" }}>
                    <Header style={{ display: "inline" }}>
                      <Icon name="tasks" />
                      Workflows
                    </Header>
                    <Container style={{ padding: "1em 0em 1em 3em" }}>
                      {this.placeholder()}
                    </Container>
                  </div>
                )}
                {client && !switchingClients && client.id === clientId ? (
                  <CardAttachments client={client} />
                ) : (
                  <div style={{ marginTop: "2em", marginLeft: "0em" }}>
                    <Header style={{ display: "inline" }}>
                      <Icon name="paperclip" />
                      Attachments
                    </Header>
                    <Container style={{ padding: "1em 0em 1em 3em" }}>
                      {this.placeholder()}
                    </Container>
                  </div>
                )}
                <div ref={this.newCommentRef}>
                  {client && !switchingClients && client.id === clientId ? (
                    <CardCommentsList client={client} />
                  ) : (
                    <div style={{ marginTop: "2em", marginLeft: "0em" }}>
                      <Header style={{ display: "inline" }}>
                        <Icon name="comment outline" />
                        Comments
                      </Header>
                      <Container style={{ padding: "1em 0em 1em 3em" }}>
                        {this.placeholder()}
                      </Container>
                    </div>
                  )}
                </div>
              </div>
              {client && !switchingClients && client.id === clientId && (
                <div
                  style={{
                    marginLeft: "1em",
                    width: "250px",
                    minWidth: "250px",
                  }}
                >
                  {integration && (
                    <CardViewIntegrations
                      client={client}
                      integration={integration}
                    />
                  )}
                  <ContactInformation client={client} />
                  <CardNextWorkflows client={client} workflowId={workflowId} />
                  <CardTags client={client} advisor={advisor} />
                </div>
              )}
            </div>
          </ClickToScroll>
        </Modal.Content>
      </Modal>
    );
  }
}

const getClient = (state, props) => {
  return { id: props.clientId };
};

const mapStateToProps = () => {
  const selectReminders = makeGetReminders(getClient);
  return (state, ownProps) => {
    return {
      hub: state.hubly.data.hub.selected.hub,
      advisor: state.hubly.data.advisor,
      client: state.hubly.data.hub.clients.activeClients[ownProps.clientId],
      clientWorkflows: state.hubly.data.hub.clientWorkflows,
      reminders: selectReminders(state, ownProps),
      fetchingCws: state.hubly.data.hub.clientWorkflowLoader,
    };
  };
};

const mapDispatchToProps = (
  dispatch,
  { resolveClientWorkflow, showCwfLoader }
) => {
  return {
    setAttachments: (attachments) => {
      dispatch(SetAttachments(attachments));
    },
    setAttachmentsLoading: (loading) => {
      dispatch(SetAttachmentsLoading(loading));
    },
    setActiveClient: (client) => {
      dispatch(SetActiveClient(client));
    },
    setClientWorkflowLoaderOn: () => {
      if (showCwfLoader) dispatch(SetClientWorkflowLoader({ loading: true }));
    },
    setCommentTaskId: (taskId, clientWorkflowId) => {
      dispatch(SetCommentTaskId(taskId, clientWorkflowId));
    },
    updateClientWorkFlows: (cws) => {
      dispatch(UpdateClientWorkFlows(cws));
    },
    publish: (wfsToUpdate) => {
      dispatch(publishUpdate("WORKFLOWS", wfsToUpdate));
    },
    getClientWorkflowByClientId: (params) => {
      if (showCwfLoader) dispatch(SetClientWorkflowLoader({ loading: true }));
      return resolveClientWorkflow(params)
        .then(([clientWorkflows, shouldDispatch]) => {
          if (shouldDispatch) dispatch(UpdateClientWorkFlows(clientWorkflows));
        })
        .then(() => {
          dispatch(SetClientWorkflowLoader({ loading: false }));
        })
        .catch((error) => {
          console.error(error);
          dispatch(SetClientWorkflowLoader({ loading: false, error: error }));
        });
    },
  };
};

export default withClientWorkflowResolver(
  connect(mapStateToProps, mapDispatchToProps)(ClientCardModal)
);
