import React, { useContext, useState } from "react";
import PropTypes from "prop-types";

import { Container, Header, Button } from "semantic-ui-react";

import SimpleList from "components/SimpleList";
import SimpleLinePlaceholder from "components/SimpleLinePlaceholder";

import {
  HubContext,
  ToasterContext,
} from "scenes/Hubly/components/Workspace/Provider";

import {
  useEditorState,
  useEditorData,
} from "scenes/Hubly/components/Workspace/components/hooks";

import { NextWorkflowAdd } from "./components";

export function NextWorkflowsEditor({ workflow }) {
  const { addNextWorkflow, deleteNextWorkflow, fetchNextWorkflows } =
    useContext(HubContext);
  const { fireError } = useContext(ToasterContext);
  const [updateLoader, setUpdateLoader] = useState(false);
  const [addingNextWf, setAddingNextWf] = useState(false);

  const [nextWorkflows, dispatch] = useEditorState();
  const { loading, reloadData } = useEditorData(fetchNextWorkflows, {
    id: workflow.id,
    dispatch: dispatch,
  });

  const createNextWorkflow = async ({
    nextWorkflowId,
    applyNextWorkflowsToAll,
  }) => {
    try {
      setUpdateLoader(true);
      const { id, nextWorkflowName } = await addNextWorkflow({
        workflowId: workflow.id,
        nextWorkflowId: nextWorkflowId,
        applyToAllClients: applyNextWorkflowsToAll,
      });
      dispatch({
        type: "add",
        value: {
          id: id,
          nextWorkflow: { id: nextWorkflowId, name: nextWorkflowName },
        },
      });
      setUpdateLoader(false);
      reloadData();
    } catch (error) {
      fireError("Failed to add next worklow", error);
      setUpdateLoader(false);
    }
  };

  const removeNextWorkflow = async (id) => {
    return deleteNextWorkflow(id).catch((error) => {
      fireError("Failed to delete next workflow", error);
      setUpdateLoader(false);
    });
  };

  if (loading && nextWorkflows.length === 0) {
    return (
      <Container style={{ marginTop: "1em" }}>
        <Header as="h5">Next Workflows</Header>
        <SimpleLinePlaceholder />
      </Container>
    );
  }

  return (
    <Container className="next_workflows" style={{ marginTop: "1em" }}>
      <Header as="h5" style={{ marginBottom: "0.5em" }}>
        Next Workflows
      </Header>
      <SimpleList.Container>
        {nextWorkflows.length === 0 && (
          <SimpleList.Empty>No workflows queued</SimpleList.Empty>
        )}
        {nextWorkflows.map((nextWf) => {
          const { id, nextWorkflow, beingDeleted = false } = nextWf;
          return (
            <SimpleList.Item key={id}>
              <div>{nextWorkflow.name}</div>
              {beingDeleted ? (
                <SimpleList.Spinner />
              ) : (
                <SimpleList.DeleteButton
                  onClick={() => {
                    setUpdateLoader(true);
                    dispatch({
                      type: "edit",
                      value: { ...nextWf, beingDeleted: true },
                    });
                    removeNextWorkflow(id).then(() => {
                      dispatch({ type: "remove", value: id });
                      setUpdateLoader(false);
                      reloadData();
                    });
                  }}
                />
              )}
            </SimpleList.Item>
          );
        })}
      </SimpleList.Container>
      {!addingNextWf && (
        <Button
          disabled={updateLoader}
          style={{ marginTop: "0.5em" }}
          basic
          onClick={() => {
            setAddingNextWf(true);
          }}
        >
          Add Workflow
        </Button>
      )}
      {addingNextWf && (
        <NextWorkflowAdd
          disabled={updateLoader}
          workflow={workflow}
          onAdd={createNextWorkflow}
          onClose={() => {
            setAddingNextWf(false);
          }}
        />
      )}
    </Container>
  );
}

NextWorkflowsEditor.propTypes = {
  workflow: PropTypes.object,
};

NextWorkflowsEditor.defaultProps = {
  workflow: null,
};

export default NextWorkflowsEditor;
