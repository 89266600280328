import { useContext, useMemo, useEffect, useCallback } from "react";

import { usePagination } from "hooks/usePagination";
import { useSubscribe, useNotify } from "data/messages/hooks";

import { HubContext } from "scenes/Hubly/components/Workspace/Provider";
import { useClientWorkflows } from "../../hooks/useClientWorkflows";

export function useWorkflow(workflowId, filters, isVisible = true) {
  const { fetchClientWorkflows } = useContext(HubContext);

  const [clientWorkflows, dispatch, fetch, refetch] =
    useClientWorkflows(workflowId);

  const notify = useNotify("WORKFLOWS");

  const page = usePagination(
    fetchClientWorkflows,
    {
      variables: useMemo(() => {
        return { workflowId, filters };
      }, [workflowId, filters]),
      options: useMemo(() => {
        return { pageSizes: [7, 10, 20, 50], disabled: !isVisible };
      }, [isVisible]),
    },
    {
      onAddPageData: useCallback(
        (data, isInitLoad) => {
          dispatch({
            type: isInitLoad ? "set-cw" : "add-cw",
            value: { workflowId, data },
          });
        },
        [dispatch, workflowId]
      ),
      onSetData: useCallback(
        (data) => {
          dispatch({ type: "set-cw", value: { workflowId, data } });
        },
        [dispatch, workflowId]
      ),
      onClearData: useCallback(() => {}, []),
    }
  );

  useSubscribe(
    "workflows",
    useCallback(
      (wfsToUpdate) => {
        if (isVisible && wfsToUpdate.has(workflowId)) refetch();
      },
      [workflowId, isVisible, refetch]
    )
  );

  useEffect(() => {
    if (fetch) {
      page.refetch();
      refetch(false);
    }
  }, [page, refetch, fetch]);

  return {
    loading: page.refetching || page.loading,
    clientWorkflows: clientWorkflows,
    empty: clientWorkflows.length === 0,
    loadData: refetch,
    page: page,
    notify: () => {
      return notify([workflowId]);
    },
  };
}

export default useWorkflow;
