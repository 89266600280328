import React from "react";
import PropTypes from "prop-types";
import { Grid, Icon, Header } from "semantic-ui-react";
import colors from "../../../../colors";

export function StreamColorSelector({ streamColor, onChange }) {
  return (
    <React.Fragment>
      <Header as="h5" content="Color" style={{ marginBottom: "0.5em" }} />
      <Grid data-test="stream-color-list" columns={10}>
        {colors.map((color) => {
          return (
            <Grid.Column key={color}>
              {color === streamColor ? (
                <Icon
                  bordered
                  link
                  name="check"
                  style={{
                    color: "#FFFFFF",
                    backgroundColor: color,
                    borderRadius: "0.5em",
                  }}
                />
              ) : (
                <Icon
                  bordered
                  link
                  style={{ backgroundColor: color, borderRadius: "0.5em" }}
                  onClick={() => {
                    onChange(color);
                  }}
                />
              )}
            </Grid.Column>
          );
        })}
      </Grid>
    </React.Fragment>
  );
}

StreamColorSelector.propTypes = {
  streamColor: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
export default StreamColorSelector;
