import React from "react";
import { Grid, Icon, Label, List, Popup } from "semantic-ui-react";
import PropTypes from "prop-types";

export function ClientInTag({
  client,
  viewClientCard,
  onClientTagRemoved,
  ...props
}) {
  const { id, fullName, tags } = client;
  return (
    <React.Fragment>
      <Grid.Row
        className="grey_on_hover rounded p25"
        style={{
          paddingTop: "0.25em",
          paddingBottom: "0.25em",
          marginLeft: "1em",
          marginRight: "1em",
        }}
      >
        <Grid.Column width={6}>
          <div {...props}>{fullName}</div>
        </Grid.Column>
        <Grid.Column width={7}>
          <div>
            <Popup
              basic
              popperModifiers={{
                preventOverflow: {
                  boundariesElement: "window",
                  enabled: false,
                },
              }}
              trigger={
                <Label className="clickable">
                  {tags.length === 1
                    ? `${tags.length} Tag`
                    : `${tags.length} Tags`}
                </Label>
              }
            >
              <List>
                {tags.map((t) => {
                  return (
                    <List.Item key={`${client.id}-${t.id}`}>{t.name}</List.Item>
                  );
                })}
              </List>
            </Popup>
          </div>
        </Grid.Column>
        <Grid.Column textAlign="right" width={3}>
          <Popup
            basic
            inverted
            content="View Client Card"
            popperModifiers={{
              preventOverflow: {
                boundariesElement: "window",
                enabled: false,
              },
            }}
            size="mini"
            style={{
              padding: "0.5em",
            }}
            trigger={
              <Icon
                link
                name="user"
                onClick={() => {
                  viewClientCard(id);
                }}
                style={{ marginRight: "0.5em" }}
              />
            }
          />
          <Popup
            basic
            inverted
            content="Remove from Tag"
            popperModifiers={{
              preventOverflow: {
                boundariesElement: "window",
                enabled: false,
              },
            }}
            size="mini"
            style={{
              padding: "0.5em",
            }}
            trigger={
              <Icon
                link
                name="delete"
                onClick={() => {
                  onClientTagRemoved(client);
                }}
              />
            }
          />
        </Grid.Column>
      </Grid.Row>
    </React.Fragment>
  );
}

ClientInTag.defaultProps = {
  onClientTagRemoved: () => {},
  viewClientCard: () => {},
};

ClientInTag.propTypes = {
  client: PropTypes.shape({
    id: PropTypes.string.isRequired,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    fullName: PropTypes.string,
    tags: PropTypes.array,
  }).isRequired,
  viewClientCard: PropTypes.func,
  onClientTagRemoved: PropTypes.func,
};

export default ClientInTag;
