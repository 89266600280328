import React from "react";
import { Grid } from "semantic-ui-react";
import PropTypes from "prop-types";
import WithScrollToLoad from "components/WithScrollToLoad";
import { MemberItem, MemberItemPlaceholder } from "..";

export function MembersList({
  members,
  showLoadMore,
  loading,
  type,
  onViewClick,
  onDeleteClick,
  loadMoreData,
}) {
  const GridWithScrollToLoad = WithScrollToLoad(Grid);
  return (
    <GridWithScrollToLoad
      columns="equal"
      style={{
        maxHeight: "30vh",
        overflowY: "auto",
        paddingBottom: "0.5em",
      }}
      loadMoreData={loadMoreData}
      showLoadMore={showLoadMore}
      loading={loading}
    >
      {members.map((member) => {
        return (
          <MemberItem
            key={member.id}
            member={member}
            type={type}
            onViewClick={() => {
              onViewClick(member.id);
            }}
            onDeleteClick={() => {
              onDeleteClick(member.id);
            }}
          />
        );
      })}
      {loading && <MemberItemPlaceholder />}
    </GridWithScrollToLoad>
  );
}

MembersList.defaultProps = {
  showLoadMore: false,
  loading: false,
  loadMoreData: false,
};

MembersList.propTypes = {
  showLoadMore: PropTypes.bool,
  loading: PropTypes.bool,
  loadMoreData: PropTypes.func,
  type: PropTypes.string.isRequired,
  members: PropTypes.array.isRequired,
  onViewClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
};

export default MembersList;
