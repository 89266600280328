import React, { useState } from "react";
import PropTypes from "prop-types";

import { v4 } from "uuid";

import { Checkbox } from "semantic-ui-react";

import { MultipleClientDropdown } from "components/ClientDropdown";

export function ExcludeItems({ value, name, onChange, exclude }) {
  const [showDropdown, setShowDropdown] = useState(value.length > 0);
  const checkboxLabel = ["Exclude specific clients"];
  if (showDropdown) {
    checkboxLabel.push(<span key={v4()}> where </span>);
    checkboxLabel.push(<b key={v4()}>any</b>);
    checkboxLabel.push(<span key={v4()}> of these criteria apply:</span>);
  }

  const handleCheckboxChange = () => {
    if (showDropdown) {
      onChange(null, { name: name, value: [] });
    }
    setShowDropdown(!showDropdown);
  };

  return (
    <React.Fragment>
      <Checkbox
        checked={showDropdown}
        label={checkboxLabel}
        name="excludeItems"
        onChange={handleCheckboxChange}
        style={{ marginBottom: "1em" }}
      />
      {showDropdown && (
        <MultipleClientDropdown
          data-test="ruleModal-dropdown-exclude"
          placeholder="Select streams or tags or clients"
          showStreams
          showTags
          showClients
          exclude={exclude}
          name={name}
          value={value}
          onChange={onChange}
          dropdownStyle={{ marginBottom: 0 }}
        />
      )}
    </React.Fragment>
  );
}

ExcludeItems.propTypes = {
  value: PropTypes.array,
  exclude: PropTypes.array,
  name: PropTypes.string,
  onChange: PropTypes.func,
};

ExcludeItems.defaultProps = {
  value: [],
  exclude: [],
  name: "",
  onChange: () => {},
};

export default ExcludeItems;
