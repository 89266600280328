import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Button, Icon, Input, Popup } from "semantic-ui-react";
import DatePickerContainer from "components/DatePickerContainer";

const todaySnoozeTime = () => {
  const time = moment();
  time.set("hour", 15);
  time.set("minute", 0);
  time.set("second", 0);
  return time;
};

const tomorrowSnoozeTime = () => {
  const time = moment();
  time.add(1, "d");
  time.set("hour", 9);
  time.set("minute", 0);
  time.set("second", 0);
  return time;
};

const nextWeekSnoozeTime = () => {
  const time = moment().startOf("isoWeek");
  time.add(7, "d");
  time.set("hour", 9);
  time.set("minute", 0);
  time.set("second", 0);
  return time;
};

const PureNewTaskReminder = ({
  taskTitle,
  overdueInfo,
  hasReminder,
  selectedDate,
  setSelectedDate,
  saveReminder,
}) => {
  const [reminderPopupOpen, setReminderPopupOpen] = useState(false);

  const snoozeOption = (left, right, snooze) => {
    const leftStyle = { fontWeight: "bold", color: "#2185d0" };
    const rightStyle = { float: "right", color: "grey", fontSize: "9pt" };
    return (
      <div
        className="snooze_option"
        onClick={() => {
          setSelectedDate(snooze.toDate());
        }}
        style={{ padding: "0.25em 0.5em" }}
      >
        <span style={leftStyle}>{left}</span>
        <span style={rightStyle}>{right}</span>
      </div>
    );
  };

  const formattedDateInput = moment(selectedDate).format(
    "MMMM Do YYYY, h:mm A"
  );
  const today = todaySnoozeTime();
  const tomorrow = tomorrowSnoozeTime();
  const nextWeek = nextWeekSnoozeTime();
  return (
    <Popup
      on="click"
      open={reminderPopupOpen}
      onClose={() => {
        setReminderPopupOpen(false);
      }}
      onOpen={() => {
        setReminderPopupOpen(true);
      }}
      popperModifiers={{
        preventOverflow: { boundariesElement: "offsetParent" },
      }}
      offset="-12"
      trigger={
        hasReminder ? (
          <Icon
            color={overdueInfo.iconColor}
            link
            name="bell"
            onClick={() => {
              setReminderPopupOpen(true);
            }}
            title="This task has a reminder saved"
            data-test={`task-reminder-trigger-${taskTitle}`}
          />
        ) : (
          <Icon.Group
            className={
              !reminderPopupOpen ? "show_on_hover dark_grey_on_hover" : ""
            }
            onClick={() => {
              setReminderPopupOpen(true);
            }}
            style={{ fontSize: "13pt" }}
            title="Reminder"
            data-test={`task-reminder-trigger-${taskTitle}`}
          >
            <Icon
              link
              name="bell outline"
              style={
                reminderPopupOpen
                  ? { color: "#aaaaaa", margin: 0 }
                  : { margin: 0 }
              }
            />
            <Icon
              link
              corner
              name="add"
              style={
                reminderPopupOpen
                  ? { color: "#aaaaaa" }
                  : { color: "#aaaaaa", textShadow: "none" }
              }
            />
          </Icon.Group>
        )
      }
    >
      <div style={{ width: "max-content" }} data-test="schedule-reminder-popup">
        <div style={{ marginBottom: "0.5em" }}>
          {moment().hours() < 15 &&
            snoozeOption(
              "Later Today",
              `Today, ${today.format("h:mm a")}`,
              today
            )}
          {snoozeOption(
            "Tomorrow",
            tomorrow.format("dddd, h:mm a").toString(),
            tomorrow
          )}
          {snoozeOption(
            "Next Week",
            nextWeek.format("dddd, h:mm a").toString(),
            nextWeek
          )}
        </div>
        <Input
          disabled
          fluid
          style={{ marginBottom: "1em" }}
          value={formattedDateInput}
        />
        <DatePickerContainer
          setDate={selectedDate}
          handleChange={(date) => {
            setSelectedDate(date);
          }}
        />
        <Button
          fluid
          positive
          onClick={() => {
            saveReminder();
            setReminderPopupOpen(false);
          }}
          style={{ marginTop: "1em" }}
        >
          {hasReminder ? "Save" : "Add"}
        </Button>
      </div>
    </Popup>
  );
};

PureNewTaskReminder.propTypes = {
  taskTitle: PropTypes.string.isRequired,
  overdueInfo: PropTypes.object.isRequired,
  hasReminder: PropTypes.bool.isRequired,
  selectedDate: PropTypes.object.isRequired,
  setSelectedDate: PropTypes.func.isRequired,
  saveReminder: PropTypes.func.isRequired,
};

export default PureNewTaskReminder;
