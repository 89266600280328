import React from "react";
import PropTypes from "prop-types";

import { Input, Button, Icon } from "semantic-ui-react";
import Truncate from "react-truncated-component";

export function TaskTitle({
  loading,
  taskId,
  title,
  completed,
  editMode,
  onClose,
  existingClientCheckbox,
  children,
  onClick,
  onSave,
  onTaskNameChange,
}) {
  if (editMode) {
    return (
      <React.Fragment>
        <Input
          name="taskTitle"
          fluid
          disabled={loading}
          value={title}
          placeholder={taskId ? "Edit Task" : "Add Task"}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              if (title === "") return;
              onSave(taskId);
            }
          }}
          onChange={(_, { value }) => {
            onTaskNameChange(value);
          }}
          autoFocus
        />
        <div
          style={{
            display: "flex",
            marginTop: "0.5em",
            justifyContent: existingClientCheckbox
              ? "space-between"
              : "flex-end",
          }}
        >
          {existingClientCheckbox && (
            <div style={{ display: "flex", alignItems: "center" }}>
              {existingClientCheckbox}
              <div
                style={{
                  color: "grey",
                  marginLeft: "0.5em",
                  fontSize: "10pt",
                }}
              >
                Add to existing clients
              </div>
            </div>
          )}
          <div style={{ display: "flex", minWidth: 100 }}>{children}</div>
        </div>
        <div
          style={{
            marginBottom: "0.5em",
            display: "flex",
            alignItems: "center",
            marginTop: "0.5em",
          }}
        >
          <Button
            loading={loading}
            disabled={loading || title === ""}
            content={taskId ? "Save" : "Add"}
            color="green"
            onClick={() => {
              onSave(taskId);
            }}
          />
          <Icon
            link
            disabled={loading}
            name="delete"
            color="grey"
            size="large"
            onClick={() => {
              onClose(false);
            }}
          />
        </div>
      </React.Fragment>
    );
  }
  return (
    <div
      onClick={() => {
        onClick(true);
      }}
      style={
        completed
          ? {
              textDecorationLine: "line-through",
              textDecorationStyle: "solid",
              color: "grey",
            }
          : {}
      }
      className="editable"
    >
      <Truncate ellipsis="..." numberOfLines={1} lineHeight={22}>
        <p>{title}</p>
      </Truncate>
    </div>
  );
}

TaskTitle.propTypes = {
  loading: PropTypes.bool,
  taskId: PropTypes.string,
  title: PropTypes.string,
  completed: PropTypes.bool,
  existingClientCheckbox: PropTypes.node,
  editMode: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  onTaskNameChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  children: PropTypes.node,
};

TaskTitle.defaultProps = {
  loading: false,
  taskId: null,
  children: null,
  title: "",
  editMode: false,
  completed: false,
  existingClientCheckbox: null,
  onClick: () => {},
};

export default TaskTitle;
