import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Icon, Input } from "semantic-ui-react";
import DatePicker from "react-datepicker";
import MeetingDateTypeDropdown from "components/MeetingDateTypeDropdown";
import { getFormattedDateForInputField } from "./helpers";

export const AddEventButton = ({ currentDate, setCurrentDate, addEvent }) => {
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedMeetingDateType, setSelectedMeetingDateType] = useState();

  const formattedDateInput = getFormattedDateForInputField(currentDate);

  const callAddEvent = async (meetingDateTypeId) => {
    setLoading(true);
    await addEvent(meetingDateTypeId);
    setLoading(false);
    setEditing(false);
  };

  return editing ? (
    <div
      style={{
        marginBottom: "1em",
        width: "max-content",
      }}
      data-test="event-field-add-meeting-form"
    >
      <MeetingDateTypeDropdown
        setSelectedMeetingDateType={setSelectedMeetingDateType}
        selectedMeetingDateType={selectedMeetingDateType}
      />
      <Input
        disabled
        fluid
        style={{ margin: "1em 0" }}
        value={formattedDateInput}
      />
      <DatePicker
        dateFormat="MMMM d, yyyy h:mm aa"
        inline
        onChange={setCurrentDate}
        selected={currentDate}
        showTimeSelect
        timeFormat="h:mm aa"
        timeIntervals={15}
      />
      <div>
        <Button
          content="Add"
          disabled={loading}
          loading={loading}
          onClick={() => {
            callAddEvent(selectedMeetingDateType?.id);
          }}
          positive
        />
        <Icon
          color="grey"
          link
          name="delete"
          onClick={() => {
            setEditing(false);
          }}
          size="large"
        />
      </div>
    </div>
  ) : (
    <Button
      basic
      content="Add Meeting"
      onClick={() => {
        setEditing(true);
      }}
      style={{ marginTop: "1em" }}
    />
  );
};

AddEventButton.propTypes = {
  addEvent: PropTypes.func.isRequired,
  currentDate: PropTypes.object.isRequired,
  setCurrentDate: PropTypes.func.isRequired,
};

export default AddEventButton;
