import { useContext, useState, useMemo, useCallback } from "react";
import { usePagination, useFetch, useBooleanState } from "hooks";

import { HubContext } from "scenes/Hubly/components/Workspace/Provider";

import {
  useReminderAction,
  useNotifyWorkflowUpdate,
  useSubscribeToWorkflowUpdates,
} from ".";

export function useReminderCount(filters) {
  const { fetchRemindersCount } = useContext(HubContext);

  const {
    data: [{ numOverdue = 0, numDueToday = 0 } = {}],
    reloadData,
  } = useFetch(fetchRemindersCount, {
    variables: filters,
    options: { refetchOnVarChange: true },
  });

  return { numOverdue, numDueToday, reloadData };
}

export function useReminderItems(filters) {
  const [enabledFetch, setEnabledFetch] = useState(false);
  const [shown, setShown, setNotShown] = useBooleanState(false);

  const { fetchReminders } = useContext(HubContext);
  const page = usePagination(fetchReminders, {
    variables: filters,
    options: useMemo(() => {
      return { pageSizes: [30, 50], disabled: !enabledFetch };
    }, [enabledFetch]),
  });

  const fetch = () => {
    if (enabledFetch) page.refetch();
    else setEnabledFetch(true);
  };

  const refetch = () => {
    if (shown) page.refetch();
  };

  return { ...page, refetch, fetch, setShown, setNotShown };
}

export function useReminders(filters) {
  const counts = useReminderCount(filters);
  const items = useReminderItems(filters);

  const notify = useNotifyWorkflowUpdate();
  const actionable = useReminderAction(notify);

  useSubscribeToWorkflowUpdates(
    useCallback(() => {
      counts.reloadData();
      items.refetch();
    }, [items, counts])
  );

  const data = () => {
    return items.data.map(actionable);
  };

  return [counts, { ...items, data }];
}

export default useReminders;
