import { useContext, useState, useEffect } from "react";

import {
  HubContext,
  ToasterContext,
} from "scenes/Hubly/components/Workspace/Provider";

export function useRecentClients(initialLoad = false) {
  const { fetchRecentClients } = useContext(HubContext);
  const { fireError } = useContext(ToasterContext);

  const [loading, setLoading] = useState(false);
  const [loadData, setLoadData] = useState(initialLoad);
  const [recentClients, setRecentClients] = useState({
    recentNames: [],
    teamRecentNames: [],
  });

  useEffect(() => {
    let mount = true;

    if (loadData) {
      setLoading(true);
      fetchRecentClients()
        .then((resp) => {
          if (mount) {
            setLoadData(false);
            setRecentClients(resp);
          }
        })
        .catch((error) => {
          fireError(`Failed to load recent clients.`, error);
        })
        .finally(() => {
          setLoading(false);
          setLoadData(false);
        });
    }

    return () => {
      mount = false;
    };
  }, [loadData, fetchRecentClients, fireError]);

  return {
    recentClients: recentClients,
    loading: loading,
    loadData: () => {
      setLoadData(true);
    },
  };
}

export default useRecentClients;
