import React, { useContext } from "react";
import PropTypes from "prop-types";

import { v4 } from "uuid";

import SimpleList from "components/SimpleList";

import PrivacyModeContext from "components/PrivacyMode/Context";

import "./AttachmentList.css";

export function AttachmentList({ attachments, emptyMessage, onDelete }) {
  const { piiMask } = useContext(PrivacyModeContext);

  if (attachments.length === 0) {
    return <SimpleList.Empty>{emptyMessage}</SimpleList.Empty>;
  }

  return (
    <SimpleList.Container>
      {attachments.map(({ name, inProgress = false }) => {
        return (
          <SimpleList.Item key={`${v4()}`}>
            <div
              className={`attachment_list ${piiMask(
                "fs-block dd-privacy-mask"
              )}`}
              style={{
                color: inProgress ? "grey" : "black",
              }}
            >
              {name}
            </div>
            {inProgress ? (
              <SimpleList.Spinner />
            ) : (
              <SimpleList.DeleteButton
                onClick={() => {
                  return onDelete(name);
                }}
              />
            )}
          </SimpleList.Item>
        );
      })}
    </SimpleList.Container>
  );
}

AttachmentList.propTypes = {
  attachments: PropTypes.array,
  emptyMessage: PropTypes.string,
  onDelete: PropTypes.func.isRequired,
};

AttachmentList.defaultProps = {
  attachments: [],
  emptyMessage: "No attachments",
};

export default AttachmentList;
