import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import InfiniteScroller from "components/InfiniteScroller";
import { SetAlert } from "components/Alerts/actions";
import { LoadMoreReminders } from "data/reminders/actions";
import FeedItem from "./FeedItem";

class FeedContent extends Component {
  static defaultProps = {
    reminders: [],
    setRef: () => {},
    setNeutral: () => {},
  };

  static propTypes = {
    loadMoreReminders: PropTypes.func.isRequired,
    sidebarOpen: PropTypes.bool.isRequired,
    setAlert: PropTypes.func.isRequired,
    reminders: PropTypes.array,
    setRef: PropTypes.func,
    setNeutral: PropTypes.func,
    clientWorkflows: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.pageSize = 10;
    this.upwardOffset = 0;
    this.initialTime = new Date().toISOString();
    this.state = {
      loadingUpDone: false,
      requestingMoreTop: false,
      requestingMoreBottom: false,
      initialRequest: true,
    };
    this.bottomRef = React.createRef();
    this.topRef = React.createRef();
    this.underlinedRef = React.createRef();
  }

  // TODO Remove at some point if not used
  getUpwardOffset = () => {
    const { reminders } = this.props;
    const indexOfInitial = reminders.findIndex((reminder) => {
      return (
        reminder.time >= this.initialTime &&
        reminder.completedAt === null &&
        !reminder.dismissed
      );
    });
    let numOfUpwardReminders = reminders.length;
    if (indexOfInitial >= 0) {
      numOfUpwardReminders = indexOfInitial;
    }
    return numOfUpwardReminders;
  };

  getDownwardOffset = () => {
    const { reminders } = this.props;
    const indexOfInitial = reminders.findIndex((reminder) => {
      return (
        reminder.time >= this.initialTime &&
        reminder.completedAt === null &&
        !reminder.dismissed
      );
    });
    let numOfDownwardReminders = 0;
    if (indexOfInitial >= 0) {
      numOfDownwardReminders = reminders.length - indexOfInitial;
    }
    return numOfDownwardReminders;
  };

  loadMoreBottom = () => {
    const { loadMoreReminders } = this.props;
    this.setState({ requestingMoreBottom: true });
    const query = {
      // loading upcoming reminders
      time__gte: this.initialTime,
      limit: this.pageSize,
      reversed: false,
      offset: this.getDownwardOffset(),
    };
    loadMoreReminders(query, this.loadBottomCallback);
  };

  loadBottomCallback = (success, results) => {
    const { sidebarOpen } = this.props;
    this.setState({ requestingMoreBottom: false });
    if (sidebarOpen && this.bottomRef && this.bottomRef.scrollIntoView) {
      // scroll away from the load more area
      this.bottomRef.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  };

  loadMoreTop = () => {
    const { loadMoreReminders } = this.props;
    this.setState({ requestingMoreTop: true });
    const query = {
      limit: this.pageSize,
      offset: this.upwardOffset,
      client_id__isnull: false,
      ordering: "-completed_at",
      active: false,
    };
    loadMoreReminders(query, this.loadTopCallback);
  };

  loadTopCallback = (success, results) => {
    const { setAlert, sidebarOpen } = this.props;
    if (!success) {
      // api call failed
      setAlert({ type: "error", text: "Failed to load more reminders." });
      this.setState({ requestingMoreTop: false });
    } else {
      this.upwardOffset += results.length;
      this.setState({
        requestingMoreTop: false,
        loadingUpDone: results.length < this.pageSize,
      });
    }
    if (sidebarOpen && this.topRef && this.topRef.scrollIntoView) {
      // scroll away from the load more area
      this.topRef.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  componentDidMount = () => {
    const { loadMoreReminders } = this.props;
    const { initialRequest } = this.state;

    if (initialRequest) {
      const query = {
        client_id__isnull: false,
        limit: 999999999,
        ordering: "time",
        active: true,
      };
      loadMoreReminders(query, (success, results) => {
        this.setState({ initialRequest: false });
      });
    }
  };

  setTimer = (index) => {
    const { reminders } = this.props;
    clearTimeout(this.updateTimer);
    if (index < reminders.length - 1) {
      const duration =
        new Date(reminders[index + 1].time).getTime() - Date.now() + 500; // add an extra 1/2 second to make sure we're past
      this.updateTimer = setTimeout(() => {
        this.forceUpdate();
      }, duration);
    }
  };

  renderFeed = () => {
    const { reminders, setNeutral, clientWorkflows } = this.props;
    const now = new Date().toISOString();
    let alreadyUnderlined = false;
    const overline =
      reminders.length && reminders[0].time > now && !reminders[0].completedAt;
    // Filter out any dismissed reminders
    const filteredReminders = reminders.filter((reminder) => {
      if (reminder.dismissed) return false;
      if (reminder.clientWorkflowId) {
        const cw = clientWorkflows[reminder.clientWorkflowId];
        return !cw?.completed && !cw?.archived;
      }
      return true;
    });
    const feedItems = filteredReminders.map((reminder, index) => {
      const underline =
        !alreadyUnderlined &&
        ((index <= reminders.length - 2 &&
          reminder.time < now &&
          reminders[index + 1].time >= now &&
          !reminders[index + 1].completedAt &&
          !reminders[index + 1].dismissed) || // underline should be at the very bottom
          (index === reminders.length - 1 && reminder.time < now) || // between completed and not completed
          ((reminder.dismissed || reminder.completedAt != null) &&
            index <= reminders.length - 2 &&
            reminders[index + 1].time >= now &&
            !reminders[index + 1].dismissed &&
            reminders[index + 1].completedAt === null));
      alreadyUnderlined = alreadyUnderlined || underline;
      if (underline) {
        this.setTimer(index);
      }
      if (index === 0) {
        // first reminder
        return (
          <FeedItem
            setRef={(r) => {
              this.topRef = r;
            }}
            key={reminder.id}
            item={reminder}
            underline={underline}
            overline={overline}
          />
        );
      }
      if (index === reminders.length - 1) {
        // last reminder
        return (
          <FeedItem
            setRef={(r) => {
              this.bottomRef = r;
            }}
            key={reminder.id}
            item={reminder}
            underline={underline}
          />
        );
      }
      if (underline) {
        // the item that is one above the underlined
        return (
          <FeedItem
            setRef={(r) => {
              this.underlinedRef = r;
              setNeutral(r);
            }}
            key={reminder.id}
            item={reminder}
            underline
          />
        );
      }
      return <FeedItem key={reminder.id} item={reminder} />;
    });
    return feedItems;
  };

  render() {
    const { setRef } = this.props;
    const {
      initialRequest,
      loadingUpDone,
      requestingMoreTop,
      requestingMoreBottom,
    } = this.state;
    const feedItems = this.renderFeed();
    return (
      <div
        style={{
          backgroundColor: "white",
          height: "calc(100% + 13px)",
          overflowX: "hidden",
          overflowY: "hidden",
          width: "100%",
        }}
      >
        <InfiniteScroller
          loadUp={this.loadMoreTop}
          loadDown={this.loadMoreBottom}
          loadingUp={requestingMoreTop}
          loadingDown={requestingMoreBottom}
          loadingUpDone={loadingUpDone}
          fullScreenLoading={initialRequest}
          setRef={setRef}
        >
          {feedItems}
        </InfiniteScroller>
      </div>
    );
  }
}

const makeMapStateToProps = () => {
  return (state, props) => {
    return {
      clientWorkflows: state.hubly.data.hub.clientWorkflows,
    };
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAlert: (alert) => {
      dispatch(SetAlert(alert));
    },
    loadMoreReminders: (query, callback) => {
      dispatch(LoadMoreReminders(query, callback));
    },
  };
};

export default connect(makeMapStateToProps, mapDispatchToProps)(FeedContent);
