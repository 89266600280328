import { Authenticator } from "aws-amplify-react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { SetLoginErrorMessage, SetLoginSigningIn } from "../actions";

class HublyAuthenticator extends Authenticator {
  static propTypes = {
    setLoginErrorMessage: PropTypes.func.isRequired,
  };

  // overrides an Authenticator function
  handleAuthEvent(state, event, showToast = true) {
    if (event.type === "error") {
      console.warn("Auth Error:", event.data);
      this.props.setLoginErrorMessage(event.data);
    }
    this.props.setLoginSigningIn(false);
    super.handleAuthEvent(state, event, false); // set toast to not show
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginErrorMessage: (message) => {
      dispatch(SetLoginErrorMessage(message));
    },
    setLoginSigningIn: (value) => {
      dispatch(SetLoginSigningIn(value));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HublyAuthenticator);
