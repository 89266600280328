import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Draggable } from "react-beautiful-dnd";
import * as ReactDOM from "@hot-loader/react-dom";
import { SetAlert } from "components/Alerts/actions";
import {
  RenameClientTask,
  SetClientWorkflow,
} from "data/hub/clientWorkflows/actions";
import { RenameWorkflowTask } from "data/hub/workflows/actions";
import TaskEdit from "./components/TaskEdit";
import TaskView from "./components/TaskView";

const portal = document.createElement("div");
portal.classList.add("portal");

if (!document.body) {
  throw new Error("Body not ready for portal creation!");
}
document.body.appendChild(portal);

const TASK_STATES = {
  TASK_EDIT: "0",
  TASK_VIEW: "1",
  TASK_HIDE: "2",
};

class Task extends React.Component {
  static defaultProps = {
    comments: [],
    client: {
      id: "",
      comments: [],
      householdId: "",
      reminders: [],
    },
    clientWorkflow: {
      id: "",
      tasks: [],
      clientId: "",
    },
    newCommentRef: {},
    reminders: [],
    workflow: {
      id: "",
    },
    isHighlighted: false,
  };

  static propTypes = {
    clientWorkflow: PropTypes.shape({
      id: PropTypes.string,
      tasks: PropTypes.array,
    }),
    client: PropTypes.shape({
      id: PropTypes.string,
      comments: PropTypes.array,
    }),
    comments: PropTypes.array,
    newCommentRef: PropTypes.object,
    reminders: PropTypes.array,
    renameClientTask: PropTypes.func.isRequired,
    renameWorkflowTask: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired,
    setClientWorkflow: PropTypes.func.isRequired,
    task: PropTypes.object.isRequired,
    taskIndex: PropTypes.number.isRequired,
    updateTask: PropTypes.func.isRequired,
    workflow: PropTypes.shape({
      id: PropTypes.string,
    }),
    isHighlighted: PropTypes.bool,
  };

  state = {
    editing: false,
  };

  handleKeyDown = (e, taskId, textContent) => {
    const { keyCode } = e;
    if (keyCode === 13 || keyCode === 27 || keyCode === 9) {
      // Enter or ESC or Tab pressed
      this.updateTask(taskId, textContent);
    }
  };

  updateTask = (taskId, taskTitle) => {
    const {
      clientWorkflow,
      renameClientTask,
      renameWorkflowTask,
      setAlert,
      task,
    } = this.props;
    if (taskTitle === "") {
      setAlert({
        type: "warning",
        text: `Input field cannot be empty. Please verify task name and try again.`,
      });
      return;
    }

    const isClientWorkflow = !!clientWorkflow.id;
    if (taskTitle === "") {
      setAlert({
        type: "warning",
        text: `Input field cannot be empty. Please verify task name and try again.`,
      });
      return;
    }

    if (isClientWorkflow) {
      renameClientTask(taskId, clientWorkflow.id, taskTitle);
    } else {
      renameWorkflowTask(task, taskTitle);
    }
    this.setState({ editing: false });
  };

  cancelEdit = () => {
    this.setState({ editing: false });
  };

  editingStart = () => {
    this.setState({ editing: true });
  };

  render() {
    const { editing } = this.state;
    const {
      client,
      clientWorkflow,
      newCommentRef,
      task,
      taskIndex,
      workflow,
      isHighlighted,
    } = this.props;

    const isClientWorkflow = !!clientWorkflow.id;
    const currentWorkflow = isClientWorkflow ? clientWorkflow : workflow;
    let taskDisplay;
    if (editing) {
      taskDisplay = TASK_STATES.TASK_EDIT;
    } else if (
      !editing &&
      !(currentWorkflow.hideCompletedTasks && task.completed)
    ) {
      taskDisplay = TASK_STATES.TASK_VIEW;
    } else {
      taskDisplay = TASK_STATES.TASK_HIDE;
    }
    return (
      <Draggable
        key={`${task.id}_draggable_key`}
        draggableId={`${task.id}_draggable_id`}
        index={taskIndex}
      >
        {(provided, snapshot) => {
          const { isDragging } = snapshot;
          const child = (
            <div
              key={`${task.id}_list_item`}
              className="tasks_list_item"
              ref={provided.innerRef}
              style={provided.draggableProps.style}
              {...provided.draggableProps}
            >
              <div
                style={{
                  backgroundColor: isHighlighted
                    ? "#f2f2f2"
                    : "rgba(255,255,255,0.6)",
                  borderRadius: "5px",
                }}
                data-test="task-container"
              >
                {
                  {
                    [TASK_STATES.TASK_EDIT]: (
                      <TaskEdit
                        task={task}
                        cancelEdit={this.cancelEdit}
                        dragHandleProps={provided.dragHandleProps}
                        handleKeyDown={this.handleKeyDown}
                        isDragging={isDragging}
                        updateTask={this.updateTask}
                      />
                    ),
                    [TASK_STATES.TASK_VIEW]: (
                      <TaskView
                        task={task}
                        client={client}
                        currentWorkflow={currentWorkflow}
                        dragHandleProps={provided.dragHandleProps}
                        editingStart={this.editingStart}
                        isClientWorkflow={isClientWorkflow}
                        newCommentRef={newCommentRef}
                      />
                    ),
                    [TASK_STATES.TASK_HIDE]: null,
                  }[taskDisplay]
                }
              </div>
            </div>
          );
          if (!isDragging) {
            return child;
          }
          // If dragging - put the item in a portal
          return ReactDOM.createPortal(child, portal);
        }}
      </Draggable>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    reminders: state.hubly.data.reminders,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    renameClientTask: (taskId, clientWorkflowId, content) => {
      dispatch(RenameClientTask(taskId, clientWorkflowId, content));
    },
    renameWorkflowTask: (task, content) => {
      dispatch(RenameWorkflowTask(task, content));
    },
    setAlert: (alert) => {
      dispatch(SetAlert(alert));
    },
    setClientWorkflow: (clientWorkflow) => {
      dispatch(SetClientWorkflow(clientWorkflow));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Task);
