import React from "react";
import { Icon, Popup } from "semantic-ui-react";
import PropTypes from "prop-types";

export function StreamPopup({ streams }) {
  return (
    <React.Fragment>
      {streams.map(({ name, color }) => {
        return (
          <Popup
            basic
            inverted
            content={name}
            key={name}
            popperModifiers={{
              preventOverflow: {
                boundariesElement: "window",
                enabled: false,
              },
            }}
            size="mini"
            style={{
              padding: "0.5em",
            }}
            trigger={<Icon name="circle" style={{ color: color }} />}
          />
        );
      })}
    </React.Fragment>
  );
}

StreamPopup.propTypes = {
  streams: PropTypes.array.isRequired,
};

export default StreamPopup;
