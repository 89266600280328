import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dimmer,
  Form,
  Icon,
  Input,
  List,
  Loader,
  Message,
  Modal,
  Segment,
} from "semantic-ui-react";
import { connect } from "react-redux";
import axios from "axios";
import { PrivacyModeContext } from "components/PrivacyMode/Context";
import { SetAlert } from "components/Alerts/actions";
import SetAdvisor from "data/advisor/actions";
import { GetAdvisor } from "data/libs/advisor";
import { GetAllClientNames } from "data/libs/clients";
import { GetHouseholds } from "data/libs/households";
import { GetWorkflows } from "data/libs/workflows";
import { CreateIntegration } from "data/libs/integrations";
import { CreateSync, GetSync } from "data/libs/sync";
import { SetInProgress } from "data/hub/actions";
import {
  GetAllClients,
  AddClientToAllClientNames,
  SetAllClientNames,
  SetActiveClients,
} from "data/hub/clients/actions";
import { SetHouseholds } from "data/hub/households/actions";
import { SetWorkflows } from "data/hub/workflows/actions";
import { CloseWealthboxIntegrationModal } from "./actions";

class WealthboxIntegrationModal extends React.Component {
  static contextType = PrivacyModeContext;

  static propTypes = {
    advisor: PropTypes.shape({
      id: PropTypes.string.isRequired,
      integrations: PropTypes.array.isRequired,
    }).isRequired,
    hub: PropTypes.object.isRequired,
    closeWealthboxIntegrationModal: PropTypes.func.isRequired,
    setActiveClients: PropTypes.func.isRequired,
    setAdvisor: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired,
    setAllClientNames: PropTypes.func.isRequired,
    setHouseholds: PropTypes.func.isRequired,
    setInProgress: PropTypes.func.isRequired,
    setWorkflows: PropTypes.func.isRequired,
    wealthboxIntegrationModalOpen: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      activeTab: "integration",
      loading: false,
      wealthboxApiKey: "",
      wealthboxErrorMessage: "",
    };
  }

  checkSyncComplete = () => {
    const { pollInterval, syncId } = this;
    const { setInProgress } = this.props;

    GetSync(syncId).then((response) => {
      let percent = 10; // Set to a default value until we know the number of total pages
      if (response.totalPages) {
        percent = (response.currentPage / response.totalPages) * 100;
      }
      setInProgress(true, percent);
      if (response.status === "failed") {
        clearInterval(pollInterval);
        this.syncClientsFailed();
      } else if (response.status === "completed") {
        clearInterval(pollInterval);
        this.completeClientSync();
      }
    });
  };

  completeClientSync = () => {
    const {
      hub,
      setAdvisor,
      setAllClientNames,
      setActiveClients,
      setAlert,
      setHouseholds,
      setInProgress,
      setWorkflows,
    } = this.props;
    GetAdvisor().then((resp) => {
      setAdvisor(resp);
    });
    GetWorkflows(hub).then((resp) => {
      setWorkflows(resp);
      setInProgress(false, 0);
    });
    GetAllClients(hub).then((resp) => {
      setActiveClients(resp);
    });
    GetAllClientNames(hub).then((resp) => {
      setAllClientNames(resp);
    });
    GetHouseholds(hub).then((resp) => {
      setHouseholds(resp);
    });
    this.setState({
      activeTab: "complete",
      loading: false,
    });
    setAlert({
      type: "success",
      text: `Client Sync Complete.  Successfully integrated with Wealthbox.`,
    });
  };

  close = () => {
    const { closeWealthboxIntegrationModal } = this.props;
    this.setState({
      activeTab: "integration",
      loading: true,
      wealthboxApiKey: "",
      wealthboxErrorMessage: "",
    });
    closeWealthboxIntegrationModal();
  };

  createIntegration = () => {
    const { wealthboxApiKey } = this.state;
    const { advisor, setAdvisor, setAlert } = this.props;
    const request = {
      type: "wealthbox",
      status: "connected",
      token: wealthboxApiKey,
      advisorId: advisor.id,
    };
    CreateIntegration(request)
      .then((response) => {
        const advisorCopy = { ...advisor };
        advisorCopy.integrations.push(response);
        setAdvisor(advisorCopy);
        this.startSync();
      })
      .catch((error) => {
        setAlert({
          type: "error",
          text: "Failed to add Wealthbox integration.",
        });
        console.warn(error);
        this.setState({
          loading: false,
        });
      });
  };

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  };

  syncClientsFailed = () => {
    const { setInProgress } = this.props;
    this.setState({
      activeTab: "failed",
      loading: false,
    });
    setInProgress(false, 0);
  };

  startSync = () => {
    const { advisor, hub, setInProgress } = this.props;
    const request = {
      type: "contacts",
      advisor_id: advisor.id,
      hub_id: hub.id,
    };
    setInProgress(true, 0);

    this.setState({
      activeTab: "sync",
      loading: true,
    });
    CreateSync(request)
      .then((response) => {
        const interval = setInterval(this.checkSyncComplete, 1000);
        this.syncId = response.id;
        this.pollInterval = interval;
      })
      .catch((error) => {
        console.error(error);
        this.syncClientsFailed();
      });
  };

  testApiKey = () => {
    this.setState({
      loading: true,
    });

    const instance = axios.create({
      headers: {
        get: {
          ACCESS_TOKEN: this.state.wealthboxApiKey,
        },
      },
    });

    instance
      .get("https://api.crmworkspace.com/v1/contacts?per_page=1")
      .then((resp) => {
        this.createIntegration();
      })
      .catch((error) => {
        this.showErrorMessage();
        console.error(error);
        this.setState({
          loading: false,
        });
      });
  };

  wealthboxFieldsAreValid = () => {
    const { wealthboxApiKey } = this.state;
    return wealthboxApiKey && wealthboxApiKey.trim().length === 32;
  };

  render() {
    const { wealthboxIntegrationModalOpen } = this.props;
    const { activeTab, loading, wealthboxApiKey, wealthboxErrorMessage } =
      this.state;
    const { piiMask } = this.context;

    return (
      <Modal open={wealthboxIntegrationModalOpen}>
        <Modal.Header>
          <Icon
            link
            style={{ float: "right" }}
            color="grey"
            name="delete"
            onClick={this.close}
          />
          Wealthbox Integration
        </Modal.Header>
        <Modal.Content>
          {activeTab === "integration" && (
            <React.Fragment>
              <Message
                onDismiss={() => {
                  this.setState({ wealthboxErrorMessage: "" });
                }}
                hidden={wealthboxErrorMessage === ""}
                error
                header="Error!"
                content="Something went wrong accessing Wealthbox with this API key"
              />
              <Form>
                <div style={{ paddingBottom: "1em" }}>
                  We keep your client data up-to-date by integrating securely
                  with Wealthbox CRM. Please enter or retrieve your API key to
                  get started.
                </div>
                <Form.Group inline>
                  <Form.Field inline>
                    <label>API key</label>
                    <Input
                      className={piiMask("fs-block dd-privacy-mask")}
                      name="wealthboxApiKey"
                      value={wealthboxApiKey}
                      style={{ width: "300px" }}
                      onChange={this.handleChange}
                      placeholder="ex. a6804e94f64042eaa8362ce31d89453f"
                    />
                  </Form.Field>
                  <a
                    href="https://app.crmworkspace.com/settings/access_tokens"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Click here to login to Wealthbox and retrieve your access
                    token.
                  </a>
                </Form.Group>
              </Form>
              <p>
                Providing this API key will grant Hubly access to the following
                Wealthbox information:
              </p>
              <List relaxed>
                <List.Item>
                  <Icon color="green" name="checkmark" />
                  <List.Content>Personal account information</List.Content>
                </List.Item>
                <List.Item>
                  <Icon color="green" name="checkmark" />
                  <List.Content>All contact records and details</List.Content>
                </List.Item>
                <List.Item>
                  <Icon color="green" name="checkmark" />
                  <List.Content>All comments and tags</List.Content>
                </List.Item>
              </List>
            </React.Fragment>
          )}
          {activeTab === "sync" && (
            <Segment basic style={{ minHeight: "200px" }}>
              <Dimmer active inverted>
                <Loader inverted size="huge">
                  <div style={{ padding: "1em 0" }}>
                    Integration Successful.
                  </div>
                  <div>Your Wealthbox clients are syncing.</div>
                </Loader>
              </Dimmer>
            </Segment>
          )}
          {activeTab === "complete" && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                padding: "3em 0px",
              }}
            >
              <div>
                <Icon name="check circle" size="huge" />
              </div>
              <div style={{ padding: "1em 0", fontSize: "1.42857143em" }}>
                Successfully imported clients from Wealthbox.
              </div>
            </div>
          )}
          {activeTab === "failed" && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                padding: "3em 0px",
              }}
            >
              <div>
                <Icon name="warning circle" size="huge" />
              </div>
              <div style={{ padding: "1em 0", fontSize: "1.42857143em" }}>
                Something went wrong.
              </div>
              <div style={{ fontSize: "1.42857143em" }}>Please try again.</div>
            </div>
          )}
        </Modal.Content>
        <Modal.Actions>
          {activeTab === "integration" && (
            <div>
              <span
                style={{ color: "grey", paddingRight: "1em", fontSize: "10pt" }}
              >
                By clicking “Next” you agree to grant Hubly access to the
                Wealthbox details outlined above.
              </span>
              <Button
                content="Next"
                disabled={!this.wealthboxFieldsAreValid() || loading}
                loading={loading}
                onClick={this.testApiKey}
                primary
              />
            </div>
          )}
          {(activeTab === "sync" || activeTab === "complete") && (
            <div>
              {activeTab === "sync" && (
                <span
                  style={{
                    color: "grey",
                    paddingRight: "1em",
                    fontSize: "10pt",
                  }}
                >
                  You will not be able to see your clients until the sync is
                  complete.
                </span>
              )}
              <Button
                data-test="enter-hub-button"
                content="Enter Hub"
                primary
                onClick={this.close}
              />
            </div>
          )}
          {activeTab === "failed" && (
            <React.Fragment>
              <Button
                content="Try Again"
                floated="right"
                onClick={this.startSync}
                primary
              />
              <Button content="Skip Sync" onClick={this.close} />
            </React.Fragment>
          )}
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    advisor: state.hubly.data.advisor,
    hub: state.hubly.data.hub.selected.hub,
    wealthboxIntegrationModalOpen:
      state.hubly.wealthboxIntegration.wealthboxIntegrationModalOpen,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addClientToAllNames: (client) => {
      dispatch(AddClientToAllClientNames(client));
    },
    closeWealthboxIntegrationModal: () => {
      dispatch(CloseWealthboxIntegrationModal());
    },
    setActiveClients: (clients) => {
      dispatch(SetActiveClients(clients));
    },
    setAdvisor: (advisor) => {
      dispatch(SetAdvisor(advisor));
    },
    setAlert: (alert) => {
      dispatch(SetAlert(alert));
    },
    setAllClientNames: (clients) => {
      dispatch(SetAllClientNames(clients));
    },
    setHouseholds: (households) => {
      dispatch(SetHouseholds(households));
    },
    setWorkflows: (workflows) => {
      dispatch(SetWorkflows(workflows));
    },
    setInProgress: (inProgress, percent) => {
      dispatch(SetInProgress(inProgress, percent));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WealthboxIntegrationModal);
