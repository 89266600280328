import React from "react";
import PropTypes from "prop-types";

import { RuleItem } from "./components";
import {
  AddOrRemoveStreamRuleText,
  AgeRuleText,
  RepeatingRuleText,
  ImportantClientDatesRuleText,
} from "./components/RuleItem/components";

export function RuleList({
  workflowRules,
  onSave,
  onDelete,
  onEdit,
  dateTypes,
  meetingDateTypes,
}) {
  if (workflowRules.length === 0) {
    return (
      <div style={{ color: "grey", marginBottom: "1em", marginTop: "1em" }}>
        No rules applied
      </div>
    );
  }

  return (
    <div style={{ marginBottom: "0.5em" }}>
      {workflowRules.map((workflowRule) => {
        return (
          <RuleItem
            key={workflowRule.id}
            workflowRule={workflowRule}
            onEdit={() => {
              onEdit(workflowRule.type, workflowRule);
            }}
            onDelete={() => {
              onDelete(workflowRule.id);
            }}
            onPause={() => {
              onSave(workflowRule.id, { active: !workflowRule.active });
            }}
            data-test="automation-rule-item"
          >
            {workflowRule.type === "REPEATING_RULE" && (
              <RepeatingRuleText workflowRule={workflowRule} />
            )}
            {(workflowRule.type === "ADD_ATTRIBUTES" ||
              workflowRule.type === "REMOVE_ATTRIBUTES") && (
              <AddOrRemoveStreamRuleText workflowRule={workflowRule} />
            )}
            {workflowRule.type === "IMPORTANT_DATE_RULE" && (
              <ImportantClientDatesRuleText
                dateTypes={dateTypes}
                meetingDateTypes={meetingDateTypes}
                workflowRule={workflowRule}
              />
            )}
            {workflowRule.type === "AGE_RULE" && (
              <AgeRuleText workflowRule={workflowRule} />
            )}
          </RuleItem>
        );
      })}
    </div>
  );
}

RuleList.propTypes = {
  workflowRules: PropTypes.array,
  dateTypes: PropTypes.array,
  meetingDateTypes: PropTypes.array,
  onSave: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

RuleList.defaultProps = {
  workflowRules: [],
  dateTypes: [],
  meetingDateTypes: [],
};

export default RuleList;
