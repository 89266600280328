import React from "react";
import { connect } from "react-redux";
import { OpenWealthboxIntegrationModal } from "components/WealthboxIntegrationModal/actions";
import PropTypes from "prop-types";
import { SetAlert } from "components/Alerts/actions";
import { SetConfirmationModal } from "components/ConfirmationModal/actions";
import SetAdvisor from "data/advisor/actions";
import { DeleteIntegration } from "data/libs/integrations";
import { renderIntegration } from "./integrations";

class WealthboxIntegration extends React.Component {
  static propTypes = {
    advisor: PropTypes.shape({
      id: PropTypes.string.isRequired,
      integrations: PropTypes.array.isRequired,
    }).isRequired,
    // connected: PropTypes.bool.isRequired,
    // openWealthboxIntegrationModal: PropTypes.func.isRequired,
    setAdvisor: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired,
    setConfirmationModal: PropTypes.func.isRequired,
  };

  deleteIntegration = () => {
    const { advisor, setAdvisor, setAlert, setConfirmationModal } = this.props;
    const params = {
      title: "Disconnect from Wealthbox",
      message:
        "Are you sure that you want to disconnect from Wealthbox? Hubly will no longer be kept in sync with Wealthbox.",
      icon: "delete",
      buttons: [
        { text: "Cancel" },
        {
          text: "Disconnect",
          callBack: () => {
            const integration = advisor.integrations.find((i) => {
              return i.type === "wealthbox";
            });
            if (!integration) {
              setAlert({
                type: "error",
                text: "Failed to retrieve your integration.",
              });
              return;
            }
            DeleteIntegration(integration.id)
              .then(() => {
                const advisorCopy = { ...advisor };
                advisorCopy.integrations = advisor.integrations.filter((i) => {
                  return i.type !== "wealthbox";
                });
                setAdvisor(advisorCopy);
              })
              .catch((error) => {
                setAlert({
                  type: "error",
                  text: "Failed to delete your integration.",
                });
                console.warn(error);
              });
          },
          color: "red",
        },
      ],
    };
    setConfirmationModal(params);
  };

  goToProductTours = () => {
    const win = window.open(
      "https://www.wealthbox.com/product-tours/",
      "_blank"
    );
    win.focus();
  };

  render() {
    // const { connected, openWealthboxIntegrationModal } = this.props;
    const options = [
      {
        content: "Product Tours",
        icon: "external alternate",
        onClick: this.goToProductTours,
      },
    ];
    /*
     This block specifically commented out until we figure out how to handle Wealthbox disconnected for multi-advisor Hubs
    */
    /*
    if (connected) {
      options.push({ content: "Disconnect", onClick: this.deleteIntegration });
    } else {
      options.push({ content: "Integrate", onClick: openWealthboxIntegrationModal });
    }
    */
    return renderIntegration(
      "Wealthbox",
      "/images/logos/wealthbox-logo.png",
      options
    );
  }
}

const mapStateToProps = (state) => {
  return {
    advisor: state.hubly.data.advisor,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    openWealthboxIntegrationModal: () => {
      dispatch(OpenWealthboxIntegrationModal());
    },
    setAdvisor: (advisor) => {
      dispatch(SetAdvisor(advisor));
    },
    setAlert: (alert) => {
      dispatch(SetAlert(alert));
    },
    setConfirmationModal: (id) => {
      dispatch(SetConfirmationModal(id));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WealthboxIntegration);
