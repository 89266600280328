import React from "react";

import PropTypes from "prop-types";

import { Header, Input } from "semantic-ui-react";

export function TitleMarkup({ label, ...props }) {
  return (
    <div style={{ marginBottom: "1em" }}>
      <Header as="h3" style={{ marginBottom: "0.5em" }}>
        {label}
      </Header>
      <div>
        <Input {...props} fluid />
      </div>
    </div>
  );
}

TitleMarkup.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  error: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

TitleMarkup.defaultProps = {
  value: null,
  error: false,
};

export default TitleMarkup;
