import SyncedAPI from "data/libs/syncedAPI";
import { getAPIReq } from "./authLib";

export const CreateHouseholdMember = async (request) => {
  const req = await getAPIReq(request);
  return SyncedAPI.post("HublyAPI", `/householdMembers/`, req, (response) => {
    return [
      { type: "ADD_HOUSEHOLD_MEMBER", householdMember: response },
      {
        type: "SET_CLIENT_HOUSEHOLD",
        clientId: response.client.id,
        householdId: response.householdId,
      },
    ];
  });
};

export const UpdateHouseholdMember = async (id, request) => {
  const req = await getAPIReq(request);
  return SyncedAPI.put(
    "HublyAPI",
    `/householdMembers/${id}/`,
    req,
    (response) => {
      return { type: "EDIT_HOUSEHOLD_MEMBER", householdMember: response };
    }
  );
};

export const DeleteHouseholdMember = async (id, clientId, householdId) => {
  const req = await getAPIReq();
  return SyncedAPI.del("HublyAPI", `/householdMembers/${id}/`, req, () => {
    return [
      {
        type: "REMOVE_HOUSEHOLD_MEMBER",
        householdId: householdId,
        householdMemberId: id,
      },
      {
        type: "CLEAR_CLIENT_HOUSEHOLD",
        clientId: clientId,
        householdId: householdId,
      },
    ];
  });
};
