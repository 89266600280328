import { groupBy } from "lodash";
import Moment from "moment";

export function groupReminders(reminders) {
  const date = ({ time }) => {
    return Moment(time).format("YYYYMMDD");
  };

  return groupBy(
    reminders.map((r) => {
      return { ...r, date: date(r) };
    }),
    "date"
  );
}

export function formatGroupName(date) {
  const today = Moment();
  const reminderDate = Moment(date);

  return reminderDate.format("YYYY") === today.format("YYYY")
    ? reminderDate.format("MMMM D")
    : reminderDate.format("MMMM D, YYYY");
}

export const split = (numOfgroups = 2, perGroup = 2) => {
  const sliceGroup = (items) => {
    return [items.slice(0, perGroup), items.slice(perGroup)];
  };

  const pickIfNotEmpty = (key, part) => {
    return part.length > 0 ? { [key]: part } : {};
  };

  return (res, [k, v], i) => {
    const partitions = i < numOfgroups ? sliceGroup(v) : [[], v];

    return [
      { ...res[0], ...pickIfNotEmpty(k, partitions[0]) },
      { ...res[1], ...pickIfNotEmpty(k, partitions[1]) },
    ];
  };
};

export function size(groups) {
  return Object.values(groups).reduce((count, group) => {
    return count + group.length;
  }, 0);
}

export default groupReminders;
