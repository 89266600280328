import React from "react";
import { Label, List, Popup } from "semantic-ui-react";
import { v4 } from "uuid";
import PropTypes from "prop-types";

export function TagPopup({ tags }) {
  return (
    <React.Fragment>
      <Popup
        basic
        popperModifiers={{
          preventOverflow: {
            boundariesElement: "window",
            enabled: false,
          },
        }}
        trigger={
          <Label className="clickable">
            {tags.length === 1 ? `${tags.length} Tag` : `${tags.length} Tags`}
          </Label>
        }
      >
        <List>
          {tags.map(({ name }) => {
            return <List.Item key={`${v4()}-${name}`}>{name}</List.Item>;
          })}
        </List>
      </Popup>
    </React.Fragment>
  );
}

TagPopup.propTypes = {
  tags: PropTypes.array.isRequired,
};

export default TagPopup;
