import { SetAlert } from "components/Alerts/actions";
import { SetActiveClient } from "data/hub/clients/actions";
import { AddReminder, EditReminder, GetReminders } from "data/libs/reminders";
import moment from "moment";
import { UpdateTaskReminder } from "../hub/clientWorkflows/actions";

export const SetReminders = (reminders) => {
  return {
    type: "SET_REMINDERS",
    reminders: reminders,
  };
};

export const CompareReminders = (a, b) => {
  if (a.completedAt) {
    if (b.completedAt) {
      // Both reminders completed
      return a.completedAt < b.completedAt ? -1 : 1;
    } else {
      // A completed, B not completed
      return -1;
    }
  } else if (b.completedAt) {
    // A not completed, B completed
    return 1;
  } else {
    // B not completed, B not completed
    return a.time < b.time ? -1 : 1;
  }
};

export const dismissRemindersFromTasks = (tasks = [], allReminders) => {
  (tasks || []).forEach((task) => {
    (allReminders || []).forEach((reminder) => {
      if (task.id === reminder.taskId) {
        reminder.dismissed = true;
        reminder.completedAt = new Date().toISOString();
      }
    });
  });

  return allReminders;
};

// inserts a reminder into an already correctly sorted list of reminders, only if its within the range of already loaded reminders
export const InsertReminder = (reminder, reminders) => {
  const reminderCopy = { ...reminder };
  const time = reminder.completedAt || reminder.time;
  const completed = reminders.filter((r) => {
    return r.completedAt;
  });
  if (reminder.completedAt && reminders.length > 0) {
    if (completed.length === 0) return; // none loaded
    if ((completed[0].completedAt || completed[0].time) > time) return; // too early
  }

  try {
    for (let i = 0; i < reminders.length; i += 1) {
      const comp = CompareReminders(reminderCopy, reminders[i]);
      if (comp <= 0) {
        reminders.splice(i, 0, reminderCopy);
        return;
      }
    }
    // either the list is empty or it needs to go at the end of the list
    reminders.push(reminderCopy);
  } catch (error) {
    console.error(error);
  }
};

// removes a reminder
export const RemoveReminder = (id, reminders) => {
  try {
    for (let i = 0; i < reminders.length; i += 1) {
      if (reminders[i].id === id) {
        reminders.splice(i, 1);
        return;
      }
    }
  } catch (error) {
    console.error(error);
  }
};

const reminderHasPassed = (reminderTime) => {
  return new Date() > new Date(reminderTime);
};

// returns the number of upcoming (non complete) reminders, and a message regarding when the next reminder is
export const getNextReminderInfo = (client, clientWorkflow, reminders) => {
  let shortest = null;
  let num = 0;
  const clientReminders = reminders?.length > 0 ? reminders : client.reminders;

  // Need to count all client reminders, plus any reminders in the current workflow
  clientReminders.forEach((reminder) => {
    if (!reminder.dismissed) {
      if (reminder.taskId) {
        // If we can find the task in the current workflow, add to the number
        const foundWorkflow = (clientWorkflow.tasks || []).find((task) => {
          return task.id === reminder.taskId && !task.completed;
        });
        if (foundWorkflow) {
          num += 1;
          if (!shortest || shortest.time > reminder.time) {
            shortest = reminder;
          }
        }
      } else {
        // A reminder associated with the client only, count for all tiles
        num += 1;
        if (!shortest || shortest.time > reminder.time) {
          shortest = reminder;
        }
      }
    }
  });

  const response = {
    number: num,
    message: null,
    statusBar: "green",
    statusText: "In Progress",
    shortest: shortest || null,
  };

  if (shortest) {
    const date = moment(shortest.time);
    const days = date.diff(moment(), "days");
    const duration = moment.duration(date.diff());

    if (reminderHasPassed(date)) {
      response.statusText = "Overdue Reminder";
      response.statusBar = "red";
    }
    if (duration.days() === 0 && duration.hours() <= 12 && duration > 0) {
      response.statusText = "Upcoming Reminder";
      response.statusBar = "yellow";
      response.message = date.format("h:mm a");
    } else if (days < 5) {
      response.message = date.format("ddd");
    } else if (days < 10) {
      response.message = `${days} day${days > 1 ? "s" : ""}`;
    } else {
      response.message = date.format("MMM Do");
    }
  }
  return response;
};

// insert=true will also insert reminder into global list
export function AddExistingReminderToClientObject(
  client,
  reminder,
  insert = false
) {
  return (dispatch, getState) => {
    const clientCopy = { ...client };

    if (clientCopy.reminders && clientCopy.reminders.length > 0) {
      clientCopy.reminders.push(reminder);
    } else {
      clientCopy.reminders = [reminder];
    }

    if (insert) {
      // add the new reminder to the state
      const { reminders } = getState().hubly.data;
      const remindersCopy = Object.assign([], reminders);
      InsertReminder(reminder, remindersCopy);
      dispatch(SetReminders(remindersCopy));
    }
    dispatch(SetActiveClient(clientCopy));
  };
}

export function AddReminderToClient(client, reminderTitle, reminderTime) {
  return (dispatch, getState) => {
    const request = {
      time: reminderTime,
      title: reminderTitle,
      clientId: client.id,
    };

    AddReminder(request)
      .then((response) => {
        dispatch(AddExistingReminderToClientObject(client, response, true));
        dispatch(
          SetAlert({ type: "success", text: `Successfully added reminder` })
        );
      })
      .catch((error) => {
        console.error(error);
        dispatch(SetActiveClient(client));
        dispatch(
          SetAlert({ type: "error", text: `Failed to create reminder.` })
        );
      });
  };
}

// load more reminders according to the query provided
// results are saved to state after duplicates are removed and it is sorted
// sorting is chronologic but with dismissed reminders at the top
export const LoadMoreReminders = (query, callback) => {
  return (dispatch, getState) => {
    const { hub } = getState().hubly.data.hub.selected;
    GetReminders(hub, query)
      .then((response) => {
        let reminders = Object.assign([], getState().hubly.data.reminders);
        const newReminders = response.results;
        reminders = reminders.concat(newReminders);
        // remove duplicates
        reminders = reminders.filter((reminder, index, self) => {
          return (
            index ===
            self.findIndex((r) => {
              return r.id === reminder.id;
            })
          );
        });
        // sort reminders
        const sortedReminders = reminders.sort(CompareReminders);
        // set the reminders to state
        dispatch(SetReminders(sortedReminders));
        // callback the callback to callback. true means it was successful
        callback(true, newReminders);
      })
      .catch((error) => {
        console.warn("Failed to load more reminders");
        console.warn(error);
        callback(false);
      });
  };
};

export function DismissReminder({
  id,
  clientId,
  clientWorkflowId = null,
  taskId = null,
}) {
  return (dispatch, getState) => {
    const { activeClients } = getState().hubly.data.hub.clients;

    const client = activeClients[clientId];

    const clientCopy = { ...client };
    const oldClient = { ...client };
    const request = {
      dismissed: true,
    };

    // Set the reminder as dismissed from the client to prevent UI delays
    const foundReminder = clientCopy.reminders.find((reminder) => {
      return reminder.id === id;
    });
    if (foundReminder) foundReminder.dismissed = true;
    if (clientId) {
      dispatch(SetActiveClient(clientCopy));
    }

    EditReminder(id, request)
      .then((response) => {
        if (clientWorkflowId && taskId) {
          dispatch(UpdateTaskReminder(response));
        }

        if (!foundReminder) {
          console.warn("DismissReminder, Reminder ID ", id, " does not exist");
          return;
        }
        foundReminder.dismissed = true;
        foundReminder.completedAt = new Date().toISOString();

        const { reminders } = getState().hubly.data;
        const remindersCopy = reminders.slice();
        RemoveReminder(foundReminder.id, remindersCopy);
        InsertReminder(foundReminder, remindersCopy);
        dispatch(SetReminders(remindersCopy));

        if (clientId) {
          dispatch(SetActiveClient(clientCopy));
          dispatch(SetActiveClient(clientCopy));
        }

        dispatch(
          SetAlert({
            type: "success",
            text: `Successfully dismissed reminder`,
          })
        );
      })
      .catch((error) => {
        console.error(error);
        // We have to actually go in and set this, since ... operator doesn't do deep copies of object
        const oldFoundReminder = oldClient.reminders.find((reminder) => {
          return reminder.id === id;
        });
        oldFoundReminder.dismissed = false;

        if (clientId) {
          dispatch(SetActiveClient(oldClient));
          dispatch(SetActiveClient(oldClient));
        }
        dispatch(
          SetAlert({ type: "error", text: `Failed to dismiss reminder.` })
        );
      });
  };
}

export function ChangeDateOfReminder(reminder, date) {
  return (dispatch, getState) => {
    const {
      id: reminderId,
      clientId = null,
      taskId = null,
      clientWorkflowId = null,
    } = reminder;

    const { activeClients } = getState().hubly.data.hub.clients;

    const client = activeClients[clientId];
    const clientCopy = { ...client };
    const oldClient = { ...client };
    const request = {
      time: date,
    };
    EditReminder(reminderId, request)
      .then((response) => {
        const { reminders } = getState().hubly.data;
        const remindersCopy = reminders.slice();

        if (clientWorkflowId && taskId) {
          dispatch(UpdateTaskReminder(response));
        }

        RemoveReminder(reminderId, remindersCopy);
        InsertReminder(response, remindersCopy);
        dispatch(SetReminders(remindersCopy));
        dispatch(SetActiveClient(clientCopy));
        dispatch(
          SetAlert({
            type: "success",
            text: "Successfully changed date of reminder",
          })
        );
      })
      .catch((error) => {
        console.error(error);
        dispatch(SetActiveClient(oldClient));
        dispatch(
          SetAlert({
            type: "error",
            text: `Failed to update date of reminder.`,
          })
        );
      });
  };
}
