import React from "react";
import DatePicker from "react-datepicker/es";
import PropTypes from "prop-types";
import { connect } from "react-redux";

class DatePickerContainer extends React.Component {
  static propTypes = {
    handleChange: PropTypes.func.isRequired,
    setDate: PropTypes.instanceOf(Date).isRequired,
  };

  render() {
    const { handleChange, setDate } = this.props;

    return (
      <DatePicker
        selected={setDate}
        onChange={(date) => {
          handleChange(date);
        }}
        showTimeSelect
        dateFormat="Pp"
        inline
        fixedHeight
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DatePickerContainer);
