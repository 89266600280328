import React from "react";
import {
  Button,
  Dropdown,
  Header,
  Icon,
  Segment,
  Progress,
} from "semantic-ui-react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import history from "data/history";
import { SetAlert } from "components/Alerts/actions";
import TreeList from "components/TreeList";
import PopupMenu from "components/PopupMenu";
import { SetConfirmationModal } from "components/ConfirmationModal/actions";
import { RemoveClientWorkflow } from "data/hub/clientWorkflows/actions";
import { DeleteClientWorkflow } from "data/libs/clientWorkflows";
import moment from "moment";
import { getNextReminderInfo } from "data/reminders/actions";
import DateEditableField from "components/DateEditableField";
import NextWorkflowTile from "./NextWorkflowTile";
import { AddNextWorkflow } from "../../actions";
import NextWorkflowTask from "./NextWorkflowTask";
import { CreateNewWorkflow } from "../../../../../Workflows/actions";

class WorkflowTile extends React.Component {
  static propTypes = {
    addNextWorkflow: PropTypes.func.isRequired,
    hub: PropTypes.shape({
      hubId: PropTypes.string.isRequired,
    }).isRequired,
    bold: PropTypes.bool.isRequired,
    client: PropTypes.object.isRequired,
    clientWorkflow: PropTypes.object.isRequired,
    removeClientWorkflow: PropTypes.func.isRequired,
    setConfirmationModal: PropTypes.func.isRequired,
    createNewWorkflow: PropTypes.func.isRequired,
    workflows: PropTypes.object.isRequired,
  };

  state = {
    currentDate: null,
    loading: false,
    nextWorkflowId: null,
    popupOpen: false,
    showAdd: false,
    searchValue: "",
    dropdownOpen: false,
  };

  deleteClientWorkflow = () => {
    this.setState({ loading: true });
    const { hub, client, clientWorkflow } = this.props;
    const { removeClientWorkflow, setConfirmationModal } = this.props;
    const remove = () => {
      DeleteClientWorkflow(clientWorkflow.id, clientWorkflow.workflowId)
        .then(() => {
          removeClientWorkflow(clientWorkflow);
          history.push(`/hub/${hub.hubId}/clients/${client.id}`);
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    };
    const params = {
      title: "Delete Client Workflow",
      message: "Do you want to delete this workflow?  This cannot be undone.",
      icon: "delete",
      buttons: [
        { text: "Cancel" },
        {
          text: "Delete Client Workflow",
          callBack: remove,
          color: "red",
        },
      ],
    };
    setConfirmationModal(params);
    this.setState({ popupOpen: false });
  };

  enableAdding = () => {
    this.setState({ currentDate: null, popupOpen: false, showAdd: true });
  };

  createNextWorkflowsList = (clientWorkflow) => {
    const { workflows } = this.props;
    const { searchValue } = this.state;

    const options = [];
    Object.values(workflows).forEach((workflow) => {
      // Ignore the current workflow and any workflows that already exist in the next workflows list
      const nextWorkflows = clientWorkflow.nextWorkflows || [];
      const nextWorkflowMatch = nextWorkflows.find((nextWorkflow) => {
        return nextWorkflow.nextWorkflowId === workflow.id;
      });
      const clientIsNotAlreadyInWf =
        workflow.id !== clientWorkflow.workflowId && !nextWorkflowMatch;

      if (
        (clientIsNotAlreadyInWf || workflow.options?.multipleTimePerClient) &&
        workflow.name
          .toLocaleLowerCase()
          .includes(searchValue?.toLocaleLowerCase())
      ) {
        options.push({
          text: workflow.name,
          key: workflow.id,
          value: workflow.id,
        });
      }
    });
    return options;
  };

  handleChange = (e, { value }) => {
    this.setState({
      nextWorkflowId: value,
    });
  };

  handleDateChange = (attribute, date, callback) => {
    this.setState({ currentDate: date });
    if (callback) callback(true);
  };

  enterTyped = (e) => {
    // handles enter key press for adding tasks
    if (e.keyCode === 13) {
      // Enter pressed
      this.addNextWorkflows();
      this.setState({ nextWorkflowId: "" });
    }
  };

  addNextWorkflows = () => {
    const { currentDate, nextWorkflowId } = this.state;
    const { addNextWorkflow, clientWorkflow } = this.props;
    const formattedDate = currentDate
      ? moment(currentDate).format("YYYY-MM-DD")
      : null;
    addNextWorkflow(
      clientWorkflow,
      nextWorkflowId,
      formattedDate,
      this.taskConfiguration
    );
    this.setState({ nextWorkflowId: null, showAdd: false });
  };

  switchWorkflow = () => {
    const { hub, bold, client, clientWorkflow } = this.props;
    if (bold) return; // workflow already selected
    history.push(
      `/hub/${hub.hubId}/clients/${client.id}/workflows/${clientWorkflow.id}`
    );
  };

  createNewWorkflow = () => {
    const { searchValue } = this.state;
    const { createNewWorkflow } = this.props;
    this.setState({ loading: true });

    const callback = (newWorkflow) => {
      this.setState({ loading: false, searchValue: "" });
      if (!newWorkflow) return;
      this.setState({ nextWorkflowId: newWorkflow.id }, () => {
        this.addNextWorkflows();
      });
    };
    createNewWorkflow(callback, searchValue);
  };

  render() {
    const {
      currentDate,
      showAdd,
      popupOpen,
      nextWorkflowId,
      loading,
      searchValue,
      dropdownOpen,
    } = this.state;
    const { clientWorkflow, bold, client, hub } = this.props;
    if (!clientWorkflow || clientWorkflow.archived || clientWorkflow.completed)
      return null;

    const nextWorkflowsOptions = this.createNextWorkflowsList(clientWorkflow);
    const create = nextWorkflowsOptions.length === 0 && searchValue?.length > 0;

    const reminderInfo = getNextReminderInfo(client, clientWorkflow);

    let completed = 0;
    clientWorkflow.tasks.forEach((task) => {
      completed += task.completed;
    });
    const percent = (completed / clientWorkflow.tasks.length) * 100;
    return (
      <div>
        <Segment
          onClick={this.switchWorkflow}
          className={!bold && "clickable grey_on_hover"}
          style={{
            margin: "0 0 0.5em 0",
            display: "flex",
            flexDirection: "column",
            padding: "0.5em 1em",
            borderColor: bold && "#aaa",
            opacity: loading ? "0.3" : "1",
          }}
          data-test="workflow-tile-currentWorkflow"
        >
          <Progress
            color={reminderInfo.statusBar}
            percent={percent}
            attached="top"
            style={{ transform: "translateY(2px)" }}
          />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Header
              title={clientWorkflow.name}
              as="h5"
              className="overflow_ellipsis"
              style={{ display: "inline", marginBottom: "0.5em" }}
            >
              {clientWorkflow.name}
            </Header>
            <PopupMenu
              disabled={loading}
              trigger={
                <Icon
                  link
                  name="ellipsis horizontal"
                  color="grey"
                  icon
                  style={{ float: "right", marginLeft: "0.5em" }}
                  onClick={(event) => {
                    event.stopPropagation();
                    this.setState({ popupOpen: true });
                  }}
                />
              }
              onClose={() => {
                this.setState({ popupOpen: false });
              }}
              open={popupOpen}
              buttons={[
                {
                  content: "Delete",
                  icon: "delete",
                  onClick: this.deleteClientWorkflow,
                },
              ]}
            />
          </div>
          <div>
            <div style={{ color: "grey" }}>
              <Icon name="check square" color="grey" />
              {`${completed}/${clientWorkflow.tasks.length} Tasks`}
            </div>
          </div>
        </Segment>
        <TreeList style={{ marginLeft: "3px", marginBottom: "1em" }}>
          {clientWorkflow.nextWorkflows.map((w) => {
            return <NextWorkflowTile client={client} nextWorkflow={w} />;
          })}
          {showAdd ? (
            <Segment
              style={{
                margin: "0.25em 0em",
                width: "calc(100% - 1em)",
                padding: "0.5em 1em",
                opacity: loading ? "0.3" : "1",
              }}
            >
              <Dropdown
                placeholder="Select a Workflow"
                search
                selection
                closeOnChange
                className="next-workflows-search-dropdown"
                value={nextWorkflowId}
                fluid
                style={{ margin: "0.25em 0 0 0" }}
                noResultsMessage="No Next Workflows"
                onSearchChange={(e) => {
                  this.setState({ searchValue: e.target.value || "" });
                }}
                searchQuery={searchValue}
                searchInput={{ autoFocus: true }}
                open={dropdownOpen}
                onClose={() => {
                  this.setState({ dropdownOpen: false });
                }}
                onOpen={() => {
                  this.setState({ dropdownOpen: true });
                }}
              >
                <Dropdown.Menu>
                  {create && (
                    <Dropdown.Item onClick={this.createNewWorkflow}>
                      <b>+ Create</b>
                      &nbsp;
                      {searchValue}
                    </Dropdown.Item>
                  )}
                  {nextWorkflowsOptions.map((option) => {
                    return (
                      <Dropdown.Item
                        onClick={() => {
                          this.setState({
                            dropdownOpen: false,
                            nextWorkflowId: option.value,
                            searchValue: option.text,
                          });
                        }}
                      >
                        {option.text}
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
              <DateEditableField
                icon="calendar alternate"
                iconCorner="clock"
                minDate={moment().add(1, "days").toDate()}
                name="startDate"
                showAddTitle={false}
                style={{
                  color: "grey",
                  fontSize: "small",
                  margin: "0.5em 0 0 0",
                  padding: "0.2em 0.2em 0.2em 0.4em",
                }}
                text={currentDate && moment(currentDate).format("MMM D, YYYY")}
                title="Start Date"
                updateAttribute={this.handleDateChange}
                year={false}
                isForNextWorkflow
              />
              <NextWorkflowTask
                hub={hub}
                client={client}
                delayCreation
                setTaskConfiguration={(task) => {
                  this.taskConfiguration = task;
                }}
              />
              <div style={{ marginTop: "0.5em" }}>
                <Button
                  positive
                  compact
                  content="Add"
                  disabled={!nextWorkflowId || create}
                  onClick={this.addNextWorkflows}
                />
                <Icon
                  name="delete"
                  color="grey"
                  link
                  onClick={() => {
                    this.setState({ showAdd: false, searchValue: "" });
                  }}
                />
              </div>
            </Segment>
          ) : (
            <Button
              icon="plus"
              content="Add Next Workflow"
              style={{
                padding: 0,
                margin: "0.5em",
                border: "none",
                background: "none",
                opacity: "0.3",
              }}
              onClick={this.enableAdding}
            />
          )}
        </TreeList>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    hub: state.hubly.data.hub.selected.hub,
    clientWorkflow:
      state.hubly.data.hub.clientWorkflows[ownProps.clientWorkflowId],
    workflows: state.hubly.data.hub.workflows,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addNextWorkflow: (clientWorkflowId, addWorkflowId, startDate, task) => {
      dispatch(
        AddNextWorkflow(clientWorkflowId, addWorkflowId, startDate, task)
      );
    },
    setAlert: (alert) => {
      dispatch(SetAlert(alert));
    },
    removeClientWorkflow: (clientWorkflow) => {
      dispatch(RemoveClientWorkflow(clientWorkflow));
    },
    setConfirmationModal: (id) => {
      dispatch(SetConfirmationModal(id));
    },
    createNewWorkflow: (callback, name) => {
      dispatch(CreateNewWorkflow(callback, name));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowTile);
