import React from "react";
import PropTypes from "prop-types";
import { Card, Icon } from "semantic-ui-react";

export class LocalAlert extends React.Component {
  static defaultProps = {
    alert: null,
  };

  static propTypes = {
    alert: PropTypes.object,
    resetLocalAlert: PropTypes.func.isRequired,
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { alert, resetLocalAlert } = this.props;
    if (!prevProps.alert && alert) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        if (resetLocalAlert) {
          resetLocalAlert();
        } else {
          console.warn(
            "You must pass LocalAlert a function to reset the prop to null in the parent"
          );
        }
      }, alert.duration || 2000);
    }
  }

  getToasterStyle = () => {
    const alert = this.props.alert || {};
    return {
      backgroundColor: "white",
      height: "max-content",
      marginTop: "1em",
      opacity: alert.message ? 1 : 0,
      padding: "0.5em",
      transform: alert.message ? "" : "translate(0, -10px)",
      transition: "opacity 0.4s ease-in-out, transform 0.5s ease",
      zIndex: "99999",
    };
  };

  render() {
    const alert = this.props.alert || this.prevAlert || {};
    // this is used once alert is set back to null so that the text doesnt disappear
    this.prevAlert = alert || this.prevAlert;
    const message = alert.message || "";
    const color = alert.color || "blue";
    const icon = alert.icon || "";
    return (
      <div
        style={{
          width: "100%",
          height: "0px",
          display: "flex",
          justifyContent: "center",
          color: "grey",
        }}
      >
        <Card color={color} style={this.getToasterStyle()}>
          <div style={{ fontWeight: "bold", margin: "auto" }}>
            <Icon name={icon} style={{ display: "inline" }} />
            {message}
          </div>
        </Card>
      </div>
    );
  }
}

export default LocalAlert;
