import React from "react";
import PropTypes from "prop-types";

import {
  Layout,
  ShowMoreButton,
  ReminderGroup,
  Skeleton,
  Reminder,
} from "./components";

import { groupReminders, formatGroupName, split, size } from "./helper";

export function Container({
  loading,
  numOfFetches,
  data,
  firstName,
  lastName,
  showAll,
  onShowMore,
  onNavigate,
  onReschedule,
  onCompleteTask,
  onDismiss,
}) {
  const splitByShowAll = (d) => {
    return showAll ? [d, {}] : Object.entries(d).reduce(split(2, 2), [{}, {}]);
  };
  const [reminderGroups, hiddenGroups] = splitByShowAll(groupReminders(data));

  return (
    <Layout data-test="card-reminders">
      <Layout.Header>
        <Layout.Icon spinning={loading}>
          Upcoming Reminders
          {numOfFetches > 0 && ` (${data.length})`}
        </Layout.Icon>
      </Layout.Header>
      <Layout.Body>
        {loading && numOfFetches === 0 && <Skeleton />}

        {numOfFetches > 0 && data.length === 0 && (
          <div
            style={{ color: "grey", marginLeft: "1em", paddingBottom: "1em" }}
          >
            {firstName || lastName || "The client"}
            &nbsp;has no upcoming reminders.
          </div>
        )}

        {numOfFetches > 0 && data.length > 0 && (
          <React.Fragment>
            <div
              style={showAll ? {} : { display: "flex", flexDirection: "row" }}
            >
              {Object.entries(reminderGroups).map(
                ([dateGroup, reminderGroup]) => {
                  return (
                    <ReminderGroup
                      key={dateGroup}
                      collapsed={!showAll}
                      title={formatGroupName(dateGroup)}
                    >
                      {reminderGroup.map((r) => {
                        return (
                          <Reminder
                            key={r.id}
                            data={r}
                            onNavigate={onNavigate}
                            onReschedule={onReschedule}
                            onCompleteTask={onCompleteTask}
                            onDismiss={onDismiss}
                          />
                        );
                      })}
                    </ReminderGroup>
                  );
                }
              )}
            </div>
            <ShowMoreButton
              onClick={onShowMore}
              visiable={showAll || size(hiddenGroups) > 0}
            >
              {showAll
                ? "Minimize"
                : `Show more (${size(hiddenGroups)} hidden)`}
            </ShowMoreButton>
          </React.Fragment>
        )}
      </Layout.Body>
    </Layout>
  );
}

Container.propTypes = {
  loading: PropTypes.bool.isRequired,
  numOfFetches: PropTypes.number.isRequired,
  data: PropTypes.array.isRequired,
  showAll: PropTypes.bool.isRequired,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  onShowMore: PropTypes.func.isRequired,
  onNavigate: PropTypes.func.isRequired,
  onReschedule: PropTypes.func.isRequired,
  onCompleteTask: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

Container.defaultProps = {
  firstName: undefined,
  lastName: undefined,
};

export default Container;
