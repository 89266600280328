import React from "react";
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";
import { SetInProgress } from "data/hub/actions";
import ClientSyncButton from "./ClientSyncButton";

export function ClientSyncButtonWithRedux({
  inProgress,
  onSyncProgressUpdated,
  setInProgress,
  ...props
}) {
  return (
    <ClientSyncButton
      {...props}
      inProgress={inProgress}
      onSyncProgressUpdated={(syncParams) => {
        onSyncProgressUpdated(syncParams);
        const { inProgress: iProgress, progress } = syncParams;
        setInProgress(iProgress, progress);
      }}
    />
  );
}

ClientSyncButtonWithRedux.propTypes = {
  inProgress: PropTypes.bool.isRequired,
  onSyncProgressUpdated: PropTypes.func.isRequired,
  setInProgress: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    inProgress: state.hubly.data.hub.selected.inProgress,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setInProgress: (inProgress, percent) => {
      dispatch(SetInProgress(inProgress, percent));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientSyncButtonWithRedux);
