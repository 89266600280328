import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Header, Icon, Popup, Segment } from "semantic-ui-react";
import MarkedText from "components/MarkedText";
import { faGripVertical } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProcessWorkflowList from "./ProcessWorkflowList";

export function ProcessTile({
  editProcess,
  process,
  processWorkflows,
  active,
  dragHandleProps,
  children,
  ...props
}) {
  const [menuOpen, setMenuOpen] = useState(false);

  const onToggleProcess = () => {
    const { toggleProcess } = props;
    if (processWorkflows.length === 0) return;
    toggleProcess(process, active);
  };

  const confirmDelete = () => {
    setMenuOpen(false);
    const { deleteProcess } = props;
    deleteProcess(process);
  };

  const { filteredKeyword } = props;
  return (
    <Segment.Group
      data-test={`process-${process?.name}`}
      style={{
        flexGrow: "1",
        boxSizing: "border-container",
        margin: "0px",
        marginBottom: "1em",
        minWidth: "100%",
      }}
    >
      <Segment
        style={{ background: "none" }}
        className={`clickable grey_on_hover no_select ${
          !active && "disabled_segment"
        }`}
        onClick={onToggleProcess}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ display: "flex" }}>
            <div
              data-test={`process-draggable-${process?.name}`}
              {...dragHandleProps}
            >
              <FontAwesomeIcon
                className="dark_grey_on_hover"
                color="rgb(218, 218, 218)"
                icon={faGripVertical}
                style={{
                  marginRight: "1em",
                }}
              />
            </div>
            <div
              style={{
                height: "19px",
                width: "8px",
                marginRight: "0.5em",
                backgroundColor: process.color,
              }}
            />
            <div>
              {filteredKeyword === "" ? (
                <Header as="h3">{process.name}</Header>
              ) : (
                <div style={{ fontSize: "14pt" }}>
                  <MarkedText text={process.name} keyword={filteredKeyword} />
                </div>
              )}
            </div>
          </div>
          <div>
            <Popup
              className="hubly_bars_menu"
              hideOnScroll
              id="hide-show-popup"
              on="click"
              onClose={() => {
                setMenuOpen(false);
              }}
              open={menuOpen}
              popperModifiers={{
                preventOverflow: {
                  boundariesElement: "window",
                  enabled: false,
                },
              }}
              position="right center"
              trigger={
                <Icon
                  link
                  name="ellipsis horizontal"
                  color="grey"
                  style={{ float: "right", fontSize: "12pt" }}
                  onClick={(event) => {
                    event.stopPropagation();
                    setMenuOpen(true);
                  }}
                />
              }
            >
              <Button.Group
                basic
                vertical
                labeled
                icon
                style={{ border: "none" }}
              >
                <Button
                  icon="edit"
                  content="Edit"
                  onClick={(event) => {
                    event.stopPropagation();
                    editProcess(process);
                  }}
                />
                <Button
                  icon="delete"
                  content="Delete"
                  onClick={(event) => {
                    event.stopPropagation();
                    confirmDelete();
                  }}
                />
              </Button.Group>
            </Popup>
          </div>
        </div>
        {processWorkflows.length > 0 && (
          <div
            data-test="process-workflow-name-text"
            style={{
              display: "inline-flex",
              flexWrap: "wrap",
              color: "rgba(0, 0, 0, 0.8)",
              fontSize: "10pt",
              marginTop: "1em",
            }}
          >
            {processWorkflows.map((workflow, index) => {
              return (
                <div
                  key={workflow.id}
                  style={{
                    display: "inline-flex",
                    flexWrap: "wrap",
                    marginRight: "0.2em",
                  }}
                >
                  <MarkedText text={workflow.name} keyword={filteredKeyword} />
                  {index < processWorkflows.length - 1 && ", "}
                </div>
              );
            })}
          </div>
        )}
        {process.description && (
          <div style={{ color: "rgba(0, 0, 0, 0.9)", marginTop: "0.5em" }}>
            {process.description}
          </div>
        )}
      </Segment>
      <Segment className="clickable" style={{ background: "none" }}>
        <ProcessWorkflowList
          active={active}
          processWorkflows={processWorkflows}
          {...props}
        >
          {children}
        </ProcessWorkflowList>
      </Segment>
    </Segment.Group>
  );
}

ProcessTile.defaultProps = {
  active: false,
  filteredKeyword: "",
  processWorkflows: [],
  dragHandleProps: {},
  toggleProcess: () => {},
  editProcess: () => {},
  deleteProcess: () => {},

  children: () => {},
};

ProcessTile.propTypes = {
  process: PropTypes.object.isRequired,
  dragHandleProps: PropTypes.object,
  active: PropTypes.bool,
  filteredKeyword: PropTypes.string,
  processWorkflows: PropTypes.array,
  editProcess: PropTypes.func,
  toggleProcess: PropTypes.func,
  deleteProcess: PropTypes.func,
  children: PropTypes.func,
};

export default ProcessTile;
