import React, { useContext, useState } from "react";
import { Button, Header } from "semantic-ui-react";
import PropTypes from "prop-types";
import {
  HubContext,
  ToasterContext,
} from "scenes/Hubly/components/Workspace/Provider";

const SYNC_CHECK_INTERVAL = 1000;

export function ClientSyncButton({
  inProgress,
  integrationType,
  onSyncProgressUpdated,
}) {
  const { sync, checkSync } = useContext(HubContext);
  const { fireError, fireWarning } = useContext(ToasterContext);
  const [syncTimeout, setSyncTimeout] = useState(null);

  const onSyncFailed = (error) => {
    if (error && error.response && error.response.status === 422) {
      fireWarning("Client sync already in progress.");
    } else {
      fireError("Failed to sync clients from CRM.", error);
    }
    clearTimeout(syncTimeout);
    onSyncProgressUpdated({ inProgress: false, progress: 0 });
  };

  const onCheckSyncStatus = (syncId) => {
    checkSync(syncId)
      .then(({ status, currentPage, totalPages = null }) => {
        if (status === "failed") {
          onSyncFailed();
        } else if (status === "completed") {
          clearTimeout(syncTimeout);
          onSyncProgressUpdated({ inProgress: false, progress: 100 });
        } else {
          // Set to a default value until we know the number of total pages
          const percent = totalPages ? (currentPage / totalPages) * 100 : 10;
          onSyncProgressUpdated({ inProgress: true, progress: percent });
          setSyncTimeout(
            setTimeout(() => {
              onCheckSyncStatus(syncId);
            }, SYNC_CHECK_INTERVAL)
          );
        }
      })
      .catch((error) => {
        onSyncFailed(error);
      });
  };

  const onSync = () => {
    onSyncProgressUpdated({ inProgress: true, progress: 0 });
    sync()
      .then(({ id }) => {
        setSyncTimeout(
          setTimeout(() => {
            onCheckSyncStatus(id);
          }, SYNC_CHECK_INTERVAL)
        );
      })
      .catch((error) => {
        onSyncFailed(error);
      });
  };

  return (
    <React.Fragment>
      <Button
        id="client-sync-button"
        data-test="client-sync-button"
        onClick={onSync}
        disabled={inProgress}
        style={{
          background: "#ffffff00",
          padding: 0,
          margin: 0,
          width: "100%",
          textAlign: "left",
        }}
      >
        <Header as="h5">
          <img
            style={{ filter: "brightness(0%)", width: 20 }}
            src={`/images/logos/${integrationType}-logo.png`}
            alt={`${integrationType} Logo`}
          />
          <span
            style={{
              marginLeft: "0.7em",
              position: "relative",
              top: "0.2em",
            }}
          >
            Sync with CRM
          </span>
        </Header>
      </Button>
    </React.Fragment>
  );
}

ClientSyncButton.propTypes = {
  inProgress: PropTypes.bool,
  integrationType: PropTypes.string.isRequired,
  onSyncProgressUpdated: PropTypes.func.isRequired,
};

ClientSyncButton.defaultProps = {
  inProgress: false,
};

export default ClientSyncButton;
