import React from "react";
import PropTypes from "prop-types";

import { PrivacyModeContext, privacyMode } from "./Context";
import withDecider from "./PrivacyModeDecider";

export function PrivacyMode({ children, privacyModeDecider }) {
  return (
    <PrivacyModeContext.Provider value={privacyMode(privacyModeDecider.isOn())}>
      {children}
    </PrivacyModeContext.Provider>
  );
}

PrivacyMode.propTypes = {
  children: PropTypes.node.isRequired,
  privacyModeDecider: PropTypes.shape({
    isOn: PropTypes.func,
  }).isRequired,
};

export default withDecider(PrivacyMode);
