import React from "react";
import PropTypes from "prop-types";

import { RuleItemLayout, ExcludeItemsLabels } from "./components";
import { RulePopupMenu } from "..";

export function RuleItem({
  workflowRule,
  children,
  onDelete,
  onEdit,
  onPause,
  ...props
}) {
  return (
    <RuleItemLayout
      active={workflowRule?.active}
      streams={workflowRule?.streams || []}
      tags={workflowRule?.tags || []}
      onEdit={onEdit}
      excludeItemLabels={
        <ExcludeItemsLabels
          streamsExclude={workflowRule?.streamsExclude}
          tagsExclude={workflowRule?.tagsExclude}
          clientsExclude={workflowRule?.clientsExclude}
        />
      }
      ruleMenu={
        <RulePopupMenu
          active={workflowRule?.active}
          onEdit={onEdit}
          onDelete={onDelete}
          onPause={onPause}
        />
      }
      {...props}
    >
      {children}
    </RuleItemLayout>
  );
}

RuleItem.propTypes = {
  workflowRule: PropTypes.object,
  children: PropTypes.node,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onPause: PropTypes.func.isRequired,
};

RuleItem.defaultProps = {
  workflowRule: null,
  children: null,
};

export default RuleItem;
