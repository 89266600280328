import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Grid, Header } from "semantic-ui-react";
import { connect } from "react-redux";

class PrivacyPolicyList extends Component {
  static propTypes = {
    advisor: PropTypes.shape({
      policies: PropTypes.array.isRequired,
    }).isRequired,
  };

  makeBlob = (policy) => {
    const blob = new Blob([policy.text], { type: "text/html" });

    return URL.createObjectURL(blob);
  };

  render() {
    const { advisor } = this.props;

    return (
      <div style={{ marginTop: "1em" }}>
        <div>
          <Header as="h1">Privacy Policy</Header>
        </div>
        <Grid columns={2} style={{ marginTop: "1em" }}>
          <Grid.Row style={{ padding: ".25em 0" }}>
            <Grid.Column>
              <Header as="h3">Version</Header>
            </Grid.Column>
            <Grid.Column>
              <Header as="h3">Accepted On</Header>
            </Grid.Column>
          </Grid.Row>
          {advisor.policies.map((policy) => {
            const blobLink = this.makeBlob(policy);
            return (
              <Grid.Row key={policy.id} style={{ padding: ".25em 0" }}>
                <Grid.Column>
                  <div
                    style={{
                      color: "grey",
                      fontWeight: "bold",
                      textDecoration: "underline",
                    }}
                  >
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={blobLink}
                    >
                      {policy.name}
                    </a>
                  </div>
                </Grid.Column>
                <Grid.Column>
                  {moment(policy.createdAt).format("MMMM D, YYYY")}
                </Grid.Column>
              </Grid.Row>
            );
          })}
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    advisor: state.hubly.data.advisor,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicyList);
