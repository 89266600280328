import React from "react";
import { connect } from "react-redux";
import { Divider } from "semantic-ui-react";
import PropTypes from "prop-types";
import PracticeInformationForm from "./components/PracticeInformationForm";
import SubscriptionInformationForm from "./components/SubscriptionInformationForm";
import UserInformationForm from "./components/UserInformationForm";
import PrivacyPolicyList from "./components/PrivacyPolicyList";
import CancelAccount from "./components/CancelAccount";
import HubInformationForm from "./components/HubInformationForm";

class AccountSettings extends React.Component {
  static propTypes = {
    advisor: PropTypes.shape({
      id: PropTypes.string.isRequired,
      billing: PropTypes.object,
    }).isRequired,
  };

  render() {
    const { advisor } = this.props;
    const billing = advisor.billing || { status: "none", subscribed: false };
    return (
      <React.Fragment>
        <PracticeInformationForm advisor={advisor} />
        <HubInformationForm advisor={advisor} />
        <UserInformationForm advisor={advisor} />
        {
          (billing.status === "in_trial" || billing.subscribed) && (
            <SubscriptionInformationForm />
          ) /* Don't show subscription info if they don't have a subscription */
        }
        <PrivacyPolicyList advisor={advisor} />
        <Divider style={{ marginTop: "2em", marginBottom: "1em" }} />
        <CancelAccount />
        <div style={{ height: "100px" }} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    advisor: state.hubly.data.advisor,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountSettings);
