import React, { useEffect, useState, cloneElement, useContext } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ScrollContext } from "components/ClickToScroll";
import { makeGetTaskRefs } from "../selector";

export const ScrollToTaskWrapper = ({ taskRefs, workflowId, children }) => {
  const [shouldHighlightFocussedReminder, setShouldHighlightFocussedReminder] =
    useState(false);
  const { scrollToItem, setScrollToItem, setRefs } = useContext(ScrollContext);
  const temporarilyHighlightTask = () => {
    setShouldHighlightFocussedReminder(true);
    setTimeout(() => {
      setShouldHighlightFocussedReminder(false);
      if (scrollToItem) setScrollToItem(null);
    }, 3000);
  };

  const isTaskHighlighted = (taskId) => {
    if (!taskId || !scrollToItem) return false;
    return scrollToItem === taskId && shouldHighlightFocussedReminder;
  };

  useEffect(() => {
    setRefs(taskRefs);
  }, [taskRefs, setRefs]);

  useEffect(temporarilyHighlightTask, [scrollToItem]);

  if (!children) return null;
  return (
    <React.Fragment>
      {children.map(({ taskId, renderComponent }) => {
        return (
          <div key={taskId} ref={taskRefs[taskId]}>
            {cloneElement(renderComponent, {
              isHighlighted: isTaskHighlighted(taskId),
            })}
          </div>
        );
      })}
    </React.Fragment>
  );
};

ScrollToTaskWrapper.propTypes = {
  taskRefs: PropTypes.object,
  workflowId: PropTypes.string,
  children: PropTypes.arrayOf(
    PropTypes.shape({
      taskId: PropTypes.string.isRequired,
      renderComponent: PropTypes.node.isRequired,
    })
  ),
};

ScrollToTaskWrapper.defaultProps = {
  taskRefs: {},
  workflowId: null,
  children: null,
};

const mapStateToProps = () => {
  const getTaskRefs = makeGetTaskRefs();
  return (state, ownProps) => {
    return {
      taskRefs: getTaskRefs(state, ownProps),
    };
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(ScrollToTaskWrapper);
