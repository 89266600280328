import React from "react";

import { Droppable } from "react-beautiful-dnd";

export function WithDroppable(WrappedComponent, name) {
  // eslint-disable-next-line react/prop-types
  return ({ id, direction = "vertical", children, style, ...props }) => {
    return (
      <WrappedComponent {...props}>
        <Droppable droppableId={id} direction={direction} type={name}>
          {(p) => {
            return (
              <div
                ref={p.innerRef}
                {...p.droppableProps}
                style={{ ...style, ...p.droppableProps.style }}
              >
                {children}
                {p.placeholder}
              </div>
            );
          }}
        </Droppable>
      </WrappedComponent>
    );
  };
}

export function createDroppable(name) {
  return WithDroppable(React.Fragment, name);
}

export default WithDroppable;
