import React from "react";
import { HubContext } from "scenes/Hubly/components/Workspace//Provider";

import Tile from "./ClientTile";

export function WithHubContext(props) {
  const hubContext = React.useContext(HubContext);

  return <Tile {...props} context={hubContext} />;
}

export default WithHubContext;
