import React from "react";
import PropTypes from "prop-types";

import { Segment, Button } from "semantic-ui-react";

function MiniButton({ children, ...buttonProps }) {
  return (
    <Segment
      basic
      style={{ margin: "0", marginTop: ".25em", padding: "0" }}
      textAlign="center"
    >
      <Button
        content={children}
        size="mini"
        style={{ padding: ".5em" }}
        {...buttonProps}
      />
    </Segment>
  );
}

MiniButton.propTypes = { children: PropTypes.node };
MiniButton.defaultProps = { children: undefined };

export default MiniButton;
