import React, { useState } from "react";
import PropTypes from "prop-types";

import { SortingMenu, MainMenu, PopupMenu } from "./components";

export function WorkflowMenu({
  onClickSort,
  onClickEditWorkflow,
  onClickModifyTitle,
  onClickDuplicateWorkflow,
  onClickHideWorkflow,
}) {
  const [isSortingMenuOpen, setOpenSortingMenu] = useState(false);
  const [isSortingType, setIsSortingType] = useState("");
  const [isMainMenuOpen, setOpenMainMenu] = useState(false);

  if (isSortingMenuOpen) {
    return (
      <PopupMenu
        data-test="workflow-menu-sorting-popup"
        hasBackButton
        open={isSortingMenuOpen}
        onOpen={() => {
          return setOpenSortingMenu(true);
        }}
        onClose={() => {
          return setOpenSortingMenu(false);
        }}
        onBack={() => {
          setOpenSortingMenu(false);
          setOpenMainMenu(true);
        }}
      >
        <SortingMenu
          isSorting={isSortingType}
          onClickSort={(type) => {
            setIsSortingType(type);
            onClickSort(type)
              .then(() => {
                setOpenSortingMenu(false);
              })
              .finally(() => {
                setIsSortingType("");
              });
          }}
        />
      </PopupMenu>
    );
  }

  return (
    <PopupMenu
      open={isMainMenuOpen}
      onOpen={() => {
        return setOpenMainMenu(true);
      }}
      onClose={() => {
        return setOpenMainMenu(false);
      }}
    >
      <React.Fragment>
        <MainMenu
          onClickEditWorkflow={() => {
            onClickEditWorkflow();
            setOpenMainMenu(false);
          }}
          onClickSortingBy={() => {
            setOpenMainMenu(false);
            setOpenSortingMenu(true);
          }}
          onClickModifyTitle={() => {
            onClickModifyTitle();
            setOpenMainMenu(false);
          }}
          onClickDuplicateWorkflow={() => {
            onClickDuplicateWorkflow();
            setOpenMainMenu(false);
          }}
          onClickHideWorkflow={() => {
            onClickHideWorkflow();
            setOpenMainMenu(false);
          }}
        />
      </React.Fragment>
    </PopupMenu>
  );
}

WorkflowMenu.propTypes = {
  onClickSort: PropTypes.func.isRequired,
  onClickEditWorkflow: PropTypes.func.isRequired,
  onClickModifyTitle: PropTypes.func.isRequired,
  onClickDuplicateWorkflow: PropTypes.func.isRequired,
  onClickHideWorkflow: PropTypes.func.isRequired,
};

WorkflowMenu.defaultProps = {};

export default WorkflowMenu;
