import React from "react";
import PropTypes from "prop-types";
import Highlighter from "react-highlight-words";
import latinize from "latinize";

const escapeRegExp = (textToReplace) => {
  return textToReplace.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
};

export const wordsStartWith = (words, keyword) => {
  const filterKeyword = escapeRegExp(keyword);
  return words.match(RegExp(`(^${filterKeyword}|\\s+${filterKeyword})`, "ig"));
};

export const MarkedText = ({ text, keyword }) => {
  const processText = () => {
    if (!text) {
      return "";
    } else if (!keyword) {
      return text;
    } else {
      const searchWords = keyword.split(" ").map((w) => {
        const word = escapeRegExp(w);
        return RegExp(`(${word}|^"${word}|\\s+${word}|\\s+"${word})`, "ig");
      });
      return (
        <Highlighter
          searchWords={searchWords}
          textToHighlight={text}
          sanitize={latinize}
          highlightTag={({ children }) => {
            return <b>{children}</b>;
          }}
        />
      );
    }
  };

  return <div data-test="marked-text">{processText()}</div>;
};

MarkedText.defaultProps = {
  text: null,
  keyword: null,
};

MarkedText.propTypes = {
  text: PropTypes.string,
  keyword: PropTypes.string,
};

export default MarkedText;
