import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { useFetch } from "hooks";

import getUpcomingReminders from "scenes/Hubly/components/Workspace/api/reminders";

import { OpenReminderRescheduleModal } from "components/ReminderRescheduleModal/actions";
import { CompleteTaskByReminder } from "data/hub/actions";
import { DismissReminder } from "data/reminders/actions";

import Container from "./Container";

export function Connector({ reminders, client, hub, ...callbacks }) {
  const { reloadData, ...fetch } = useFetch(() => {
    return getUpcomingReminders({ hubId: hub.hubId, clientId: client.id });
  });

  const [showAll, setShowAll] = React.useState(false);

  useEffect(() => {
    reloadData();
  }, [reminders, client, reloadData]);

  return (
    <Container
      {...fetch}
      {...client}
      showAll={showAll}
      onShowMore={() => {
        setShowAll(!showAll);
      }}
      {...callbacks}
    />
  );
}

Connector.propTypes = {
  reminders: PropTypes.number.isRequired,
  client: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  hub: PropTypes.shape({
    hubId: PropTypes.string.isRequired,
  }).isRequired,
};

const mapStateToProps = (state) => {
  return { reminders: state.hubly.data.reminderVersion };
};

const mapDispatchToProps = (dispatch, { location, history }) => {
  return {
    onNavigate: (clientWorkflowId) => {
      const locationParts = location?.pathname?.split?.("/");
      if (
        locationParts &&
        locationParts[locationParts.length - 1] !== clientWorkflowId
      ) {
        history.push(`./${clientWorkflowId}`);
      }
    },
    onCompleteTask: ({ id, client, task }) => {
      dispatch(CompleteTaskByReminder(task.id, id, client.id));
    },
    onDismiss: ({ id, client, task }) => {
      dispatch(
        DismissReminder({
          id: id,
          clientId: client.id,
          clientWorkflowId: task?.clientWorkflow.id,
          taskId: task?.id,
        })
      );
    },
    onReschedule: ({ client, task, ...otherProps }) => {
      dispatch(
        OpenReminderRescheduleModal({
          ...otherProps,
          clientId: client.id,
          clientWorkflowId: task?.clientWorkflow.id,
          taskId: task?.id,
        })
      );
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Connector)
);
