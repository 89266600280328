import React, { useState } from "react";
import PropTypes from "prop-types";
import { Grid, Icon, Popup, Segment } from "semantic-ui-react";
import colors, { bonusColor } from "./colors";

export function ColorPicker({ color, setColor, allowNone }) {
  const [popupOpen, setPopupOpen] = useState(color === null);
  const [componentColor, setComponentColor] = useState(color);

  return (
    <Popup
      id="color-picker-popup"
      data-test="color-picker-popup"
      on="click"
      open={popupOpen}
      onOpen={() => {
        setPopupOpen(true);
      }}
      onClose={() => {
        setPopupOpen(false);
      }}
      position="right center"
      trigger={
        <Segment
          className="clickable"
          style={{
            width: "38px",
            height: "38px",
            color: "grey",
            padding: "0em",
            display: "flex",
            boxSizing: "border-box",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: componentColor || "white",
          }}
        >
          {!componentColor && (
            <div>
              <Icon
                name="question"
                color="grey"
                style={{ width: "auto", height: "auto", margin: "0px" }}
              />
            </div>
          )}
        </Segment>
      }
    >
      <Grid
        columns={6 + allowNone}
        style={{ padding: "0.5em 0.5em 0em 0.25em" }}
      >
        {Object.keys(colors).map((c) => {
          return (
            <Grid.Column key={c} style={{ padding: "0.5em" }}>
              {c === componentColor ? (
                <Icon
                  bordered
                  link
                  name="check"
                  onClick={() => {
                    setPopupOpen(false);
                  }}
                  style={{
                    color: "#FFFFFF",
                    backgroundColor: c,
                    borderRadius: "0.5em",
                  }}
                />
              ) : (
                <Icon
                  bordered
                  link
                  style={{ backgroundColor: c, borderRadius: "0.5em" }}
                  onClick={() => {
                    setPopupOpen(false);
                    setComponentColor(c);
                    setColor(c);
                  }}
                />
              )}
            </Grid.Column>
          );
        })}
        {allowNone && (
          <React.Fragment>
            <Grid.Column style={{ padding: "0.5em" }}>
              <Icon
                bordered
                link
                name={componentColor === bonusColor ? "check" : null}
                onClick={() => {
                  setPopupOpen(false);
                  setComponentColor(bonusColor);
                  setColor(bonusColor);
                }}
                style={{ backgroundColor: bonusColor, borderRadius: "0.5em" }}
              />
            </Grid.Column>
            <Grid.Column style={{ padding: "0.5em" }}>
              <Icon
                bordered
                link
                name="delete"
                onClick={() => {
                  setPopupOpen(false);
                  setComponentColor("");
                  setColor(null);
                }}
                style={{ color: "grey", borderRadius: "0.5em" }}
              />
            </Grid.Column>
          </React.Fragment>
        )}
      </Grid>
    </Popup>
  );
}

ColorPicker.defaultProps = {
  allowNone: false,
  setColor: () => {},
  color: "",
};

ColorPicker.propTypes = {
  color: PropTypes.string,
  setColor: PropTypes.func,
  allowNone: PropTypes.bool,
};

export default ColorPicker;
