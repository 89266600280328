import React from "react";
import { Header, Icon, Segment, Progress } from "semantic-ui-react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import history from "data/history";
import moment from "moment";
import { MoveClientWorkflow } from "data/libs/clientWorkflows";
import { SetAlert } from "components/Alerts/actions";
import DateEditableField from "components/DateEditableField";
import PopupMenu from "components/PopupMenu";
import { SetActiveClient } from "data/hub/clients/actions";
import { SetClientWorkflow } from "data/hub/clientWorkflows/actions";
import { AddClientToWorkflow } from "data/hub/workflows/actions";
import { CompareReminders, SetReminders } from "data/reminders/actions";
import { RemoveNextWorkflow, UpdateNextWorkflowAttribute } from "../../actions";
import NextWorkflowTask from "./NextWorkflowTask";

class NextWorkflowTile extends React.Component {
  static defaultProps = {
    reminders: [],
    style: {},
  };

  static propTypes = {
    addClientToWorkflow: PropTypes.func.isRequired,
    hub: PropTypes.shape({
      hubId: PropTypes.string.isRequired,
    }).isRequired,
    client: PropTypes.object.isRequired,
    clientWorkflows: PropTypes.object.isRequired,
    nextWorkflow: PropTypes.object.isRequired,
    reminders: PropTypes.array,
    setActiveClient: PropTypes.object.isRequired,
    setAlert: PropTypes.func.isRequired,
    setClientWorkflow: PropTypes.func.isRequired,
    setReminders: PropTypes.func.isRequired,
    removeNextWorkflow: PropTypes.func.isRequired,
    updateNextWorkflowAttribute: PropTypes.func.isRequired,
    style: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      popupOpen: false,
      loading: false,
    };
  }

  removeNextWorkflow = () => {
    const { removeNextWorkflow, nextWorkflow } = this.props;
    removeNextWorkflow(
      nextWorkflow.clientWorkflowId,
      nextWorkflow.id,
      nextWorkflow.nextWorkflowName
    );
    this.setState({ popupOpen: false });
  };

  startNextWorkflow = () => {
    const {
      addClientToWorkflow,
      hub,
      client,
      clientWorkflows,
      nextWorkflow,
      reminders,
      setActiveClient,
      setAlert,
      setClientWorkflow,
      setReminders,
    } = this.props;

    if (
      client.workflows.find((id) => {
        const cw = clientWorkflows[id];
        return (
          !cw.completed &&
          !cw.archived &&
          cw.workflowId === nextWorkflow.nextWorkflowId
        );
      })
    ) {
      setAlert({
        type: "warning",
        text: `${client.firstName} is already in that workflow.`,
      });
      this.setState({ popupOpen: false });
      return;
    }
    this.setState({ popupOpen: false, loading: true });

    const request = {
      nextWorkflowId: nextWorkflow.id,
      completed: false,
      isHidden: false,
      previousWorkflowId: nextWorkflow.clientWorkflowId,
    };
    MoveClientWorkflow(nextWorkflow.clientWorkflowId, request)
      .then((response) => {
        const clientWorkflowCopy = {
          ...clientWorkflows[nextWorkflow.clientWorkflowId],
        };
        const nextWorkflowIndex = clientWorkflowCopy.nextWorkflows.findIndex(
          (nw) => {
            return nw.id === nextWorkflow.id;
          }
        );
        clientWorkflowCopy.nextWorkflows.splice(nextWorkflowIndex, 1);
        setClientWorkflow(clientWorkflowCopy);
        setClientWorkflow(response);
        const clientCopy = { ...client };
        clientCopy.workflows.push(response.id);

        // Need to set reminders for the Hub Feed as well
        const remindersCopy = reminders.slice();
        (response.tasks || []).forEach((task) => {
          task.reminders.forEach((reminder) => {
            clientCopy.reminders.push(reminder);
            remindersCopy.push(reminder);
          });
        });
        const sortedReminders = remindersCopy.sort(CompareReminders);
        setReminders(sortedReminders);
        setActiveClient(clientCopy);

        addClientToWorkflow(response.workflowId, response.id);
        history.push(
          `/hub/${hub.hubId}/clients/${clientCopy.id}/workflows/${response.id}`
        );
      })
      .catch((error) => {
        console.error(error);
        setAlert({
          type: "error",
          text: `Failed to start ${nextWorkflow.nextWorkflowName}.`,
        });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  updateAttribute = (attribute, value, callback) => {
    const { nextWorkflow, updateNextWorkflowAttribute } = this.props;
    updateNextWorkflowAttribute(nextWorkflow, attribute, value, callback);
  };

  render() {
    const { popupOpen, loading } = this.state;
    const { nextWorkflow, client, hub, style } = this.props;
    return (
      <Segment
        style={{
          margin: "0.25em 0em",
          display: "flex",
          flexDirection: "column",
          width: "calc(100% - 1em)",
          padding: "0.5em 1em",
          opacity: loading ? "0.3" : "1",
          ...style,
        }}
        data-test="workflow-tile-nextWorkflow"
      >
        <Progress
          color="grey"
          percent={0}
          attached="top"
          style={{ transform: "translateY(2px)" }}
        />
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Header
            title={nextWorkflow.nextWorkflowName}
            as="h5"
            className="overflow_ellipsis"
            style={{ display: "inline", color: "grey", marginBottom: 0 }}
          >
            {nextWorkflow.nextWorkflowName}
          </Header>
          <PopupMenu
            disabled={loading}
            trigger={
              <Icon
                data-test="popup-menu-button"
                link
                name="ellipsis horizontal"
                color="grey"
                icon
                style={{ float: "right", marginLeft: "0.5em" }}
              />
            }
            onOpen={() => {
              this.setState({ popupOpen: true });
            }}
            onClose={() => {
              this.setState({ popupOpen: false });
            }}
            open={popupOpen}
            buttons={[
              {
                content: "Start Workflow",
                icon: "play",
                onClick: this.startNextWorkflow,
              },
              {
                content: "Remove Workflow",
                icon: "delete",
                onClick: this.removeNextWorkflow,
              },
            ]}
          />
        </div>
        <DateEditableField
          icon="calendar alternate"
          iconCorner="clock"
          minDate={moment().add(1, "days").toDate()}
          name="startDate"
          showAddTitle={false}
          style={{
            color: "grey",
            fontSize: "small",
            margin: "0.5em 0 0 0",
            padding: "0.2em 0.2em 0.2em 0.4em",
          }}
          text={
            nextWorkflow.startDate &&
            moment(nextWorkflow.startDate).format("MMM D, YYYY")
          }
          title="Start Date"
          updateAttribute={this.updateAttribute}
          year={false}
          isForNextWorkflow
        />
        <NextWorkflowTask
          hub={hub}
          nextWorkflow={nextWorkflow}
          client={client}
        />
      </Segment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    hub: state.hubly.data.hub.selected.hub,
    clientWorkflows: state.hubly.data.hub.clientWorkflows,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addClientToWorkflow: (workflowId, clientWorkflowId) => {
      dispatch(AddClientToWorkflow(workflowId, clientWorkflowId));
    },
    removeNextWorkflow: (
      clientWorkflowId,
      removeWorkflowId,
      nextWorkflowName
    ) => {
      dispatch(
        RemoveNextWorkflow(clientWorkflowId, removeWorkflowId, nextWorkflowName)
      );
    },
    setActiveClient: (client) => {
      dispatch(SetActiveClient(client));
    },
    setAlert: (alert) => {
      dispatch(SetAlert(alert));
    },
    setClientWorkflow: (clientWorkflow) => {
      dispatch(SetClientWorkflow(clientWorkflow));
    },
    setReminders: (reminders) => {
      dispatch(SetReminders(reminders));
    },
    updateNextWorkflowAttribute: (nextWorkflow, attribute, value, callback) => {
      dispatch(
        UpdateNextWorkflowAttribute(nextWorkflow, attribute, value, callback)
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NextWorkflowTile);
