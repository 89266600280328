import React, { useContext, useState } from "react";
import PropTypes from "prop-types";

import { Select, Icon, Popup } from "semantic-ui-react";

import { useForm } from "hooks";

import AddRemoveButton from "components/AddRemoveButton";

import {
  HubContext,
  ToasterContext,
} from "scenes/Hubly/components/Workspace/Provider";

import { TaskIcon } from "..";
import { FutureReminderControl, TaskFutureReminderLayout } from "./components";
import {
  delayDropdownOptions,
  formatReminderData,
  formatReminderDataForTaskCreation,
  initLegacy,
  initSequentialReminderNode,
  isSequentialReminder,
  reminderTypeOptions,
} from "./helper";

export function TaskFutureReminder({
  task,
  numTasks,
  index,
  onTaskConfigChange,
}) {
  const { addReminderToWorklfow, editReminder, deleteByReminderType } =
    useContext(HubContext);
  const { fireError } = useContext(ToasterContext);
  const [popupOpen, setPopupOpen] = useState(false);

  // TODO HUB-2441 handle case with nextWorkflow when integrating with client card
  const [loading, setLoading] = useState(false);
  const hasReminders = () => {
    return task?.reminders?.length > 0;
  };

  const isCreating = !hasReminders() && !isSequentialReminder(task);
  const initialValue = isSequentialReminder(task)
    ? initSequentialReminderNode(task?.options?.sequentialReminder)
    : initLegacy(task);

  const { register, data } = useForm({ initialValue });

  const saveReminder = async () => {
    const { futureReminderControl, ...otherData } = data;
    const request = formatReminderData({
      ...futureReminderControl,
      ...otherData,
      title: task?.title,
    });
    if (!task?.id) {
      onTaskConfigChange(formatReminderDataForTaskCreation(request));
      setPopupOpen(false);
    } else {
      try {
        setLoading(true);
        const resp = isCreating
          ? await addReminderToWorklfow(task.id, request)
          : await editReminder(task, request);
        const params =
          "sequentialReminder" in resp
            ? { options: resp, reminders: [] }
            : { options: null, reminders: [resp] };
        setPopupOpen(false);
        setLoading(false);
        onTaskConfigChange({ ...task, ...params });
      } catch (error) {
        fireError("Failed to save reminder", error);
        setLoading(false);
      }
    }
  };

  const deleteTaskReminder = deleteByReminderType(
    initialValue.reminderTypeSelection
  );
  const removeReminder = () => {
    if (!task?.id) {
      onTaskConfigChange({ reminders: [] });
      setPopupOpen(false);
    } else {
      setLoading(true);
      deleteTaskReminder(task)
        .then(() => {
          setPopupOpen(false);
          setLoading(false);
          onTaskConfigChange({ ...task, reminders: [] });
        })
        .catch((error) => {
          fireError("Failed to delete reminder", error);
          setLoading(false);
        });
    }
  };

  return (
    <Popup
      on="click"
      popperModifiers={{
        preventOverflow: { boundariesElement: "window", enabled: false },
      }}
      position="bottom center"
      open={popupOpen}
      onOpen={() => {
        setPopupOpen(true);
      }}
      onClose={() => {
        setPopupOpen(false);
      }}
      trigger={
        <TaskIcon
          hasValue={!isCreating}
          iconName="bell"
          title="Reminder"
          expanded={popupOpen}
        >
          {isSequentialReminder(task) && (
            <Icon
              link
              corner
              name="clock"
              style={{ color: "#aaaaaa", textShadow: "none" }}
            />
          )}
        </TaskIcon>
      }
    >
      <TaskFutureReminderLayout
        hasReminder={!isCreating}
        reminderTypeSelection={
          <Select
            data-test="reminder-settings-type-selector"
            {...register({ name: "reminderTypeSelection" })}
            options={reminderTypeOptions}
            style={{ marginRight: "0.5em", minWidth: "120px" }}
            disabled={loading || index === 0 || numTasks === 0}
          />
        }
        futureReminderDelaySelection={
          <Select
            {...register({ name: "futureReminderDelay" })}
            compact
            options={delayDropdownOptions}
            style={{
              marginLeft: "0.5em",
              marginRight: "0.5em",
              minWidth: "120px",
            }}
            disabled={loading}
          />
        }
        futureReminderControl={
          <FutureReminderControl
            loading={loading}
            {...register({ name: "futureReminderControl" })}
            futureReminderDelay={data?.futureReminderDelay}
          />
        }
      />
      <AddRemoveButton
        loading={loading}
        disabled={loading}
        isCreating={isCreating}
        onRemove={removeReminder}
        onAdd={saveReminder}
        saveButtonTestId="reminder-settings-button-save"
      />
    </Popup>
  );
}

TaskFutureReminder.propTypes = {
  task: PropTypes.object,
  index: PropTypes.number,
  numTasks: PropTypes.number,
  onTaskConfigChange: PropTypes.func.isRequired,
};
TaskFutureReminder.defaultProps = {
  task: null,
  numTasks: 0,
  index: -1,
};

export default TaskFutureReminder;
