import { groupByKeys } from "utils";
import { getCombinedStreamsTagsAndClient } from "../../helper";

export function getAddOrRemoveStreamRulesInitialValue(workflowRule) {
  return {
    selectedStreamsTags: getCombinedStreamsTagsAndClient(
      workflowRule?.streams,
      workflowRule?.tags
    ),
    onlyHouseholdHead:
      workflowRule && "onlyHouseholdHead" in workflowRule
        ? workflowRule.onlyHouseholdHead
        : false,
    type: workflowRule?.type || "ADD_ATTRIBUTES",
    action: workflowRule?.action || "COMPLETED_WORKFLOW",
  };
}

export const getAddRemoveStreamModalProp = (workflowRule) => {
  return {
    initialValue: getAddOrRemoveStreamRulesInitialValue(workflowRule),
    title: "Add or Remove Stream/Tag Rule",
    testId: "addRemoveStreamOrTag-modal",
    showExcludeItems: false,
    showHouseholdHead: true,
  };
};

export const formatAddRemoveStreamRuleRequest = ({
  onlyHouseholdHead,
  type,
  action,
  selectedStreamsTags,
}) => {
  return {
    ...groupByKeys(selectedStreamsTags, ["stream", "tag"]),
    type,
    action,
    onlyHouseholdHead,
  };
};

export default getAddOrRemoveStreamRulesInitialValue;
