import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Popup, Icon, Segment } from "semantic-ui-react";
import "./AddClientPopUp.css";

export function AddClientPopUp({ totalClient, children }) {
  const [popupOpen, setPopupOpen] = useState(false);
  const [addClientTooltipProps, setAddClientTooltipProps] = useState({});

  const onClearIconClicked = () => {
    setPopupOpen(false);
    setAddClientTooltipProps({});
  };

  return (
    <Popup
      content="Add Client(s)"
      position="top center"
      on="hover"
      {...addClientTooltipProps}
      trigger={
        <Segment
          style={{ width: "25%", textAlign: "center", padding: "0.75em" }}
        >
          <div
            onClick={(e) => {
              e.stopPropagation();
              setPopupOpen(true);
              setAddClientTooltipProps({ open: false });
            }}
          >
            <Icon color="grey" name="user" />
            {`${totalClient > 1000 ? "999+" : totalClient} Client${
              totalClient >= 1 ? "s" : ""
            }`}
            <Popup
              on="click"
              open={popupOpen}
              flowing
              position="right center"
              onClose={(event) => {
                // Clicking button in the confirmation modal should not close the popup
                if (
                  event.target.closest("div.ui.small.modal.visible.active") ||
                  event.target.closest("#create_new_client_modal")
                ) {
                  return;
                }
                setPopupOpen(false);
                setAddClientTooltipProps({});
              }}
              style={{ zIndex: "1000" }}
              trigger={
                <Button id="add-client-button" icon>
                  <Icon name="add" style={{ fontSize: "12pt" }} />
                </Button>
              }
            >
              <div id="add-client-popup" data-test="add-client-dropdown-popup">
                {children(onClearIconClicked)}
              </div>
            </Popup>
          </div>
        </Segment>
      }
    />
  );
}

AddClientPopUp.defaultProps = {
  children: () => {},
};

AddClientPopUp.propTypes = {
  children: PropTypes.func,
  totalClient: PropTypes.number.isRequired,
};

export default AddClientPopUp;
