import React, { useContext } from "react";
import PropTypes from "prop-types";

import copy from "copy-to-clipboard";

import { Icon, Divider, Header, List } from "semantic-ui-react";

import { ToasterContext } from "scenes/Hubly/components/Workspace/Provider";

export function DetailsContainer({
  description,
  isClientTask,
  hasOtherHouseholdMember,
  links,
}) {
  const { fireSuccess, fireError } = useContext(ToasterContext);
  const copyDescToClipboard = () => {
    const copyPlainText = (clipboardData) => {
      const node = document.createElement("div");
      node.innerHTML = description;
      clipboardData.setData("text/plain", node.innerText);
    };
    const success = copy(description, {
      format: "text/html",
      onCopy: copyPlainText,
    });
    if (success) {
      fireSuccess("Description Copied to Clipboard");
    } else {
      fireError("Failed to copy description.", null);
    }
  };

  const copyURL = (url) => {
    const success = copy(url);
    if (success) {
      fireSuccess("Link Copied to Clipboard");
    } else {
      fireError("Failed to copy client upload link.", null);
    }
  };
  return (
    <React.Fragment>
      {description && (
        <div style={{ display: "flex" }}>
          <div
            className="ql-editor display_description"
            style={{ color: "grey", fontSize: "10pt" }}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: description }}
          />
          <div
            style={{
              marginLeft: "auto",
              marginTop: "0.5em",
              marginRight: "0.75em",
            }}
          >
            <Icon
              color="grey"
              link
              name="copy outline"
              title="Copy Description"
              onClick={copyDescToClipboard}
            />
          </div>
        </div>
      )}
      {(isClientTask || hasOtherHouseholdMember) && (
        <React.Fragment>
          {description && <Divider style={{ margin: "0.5em 0em 0.5em 0em" }} />}
          <Header as="h5" style={{ margin: "0.25em 0" }}>
            Client Details
          </Header>
          <List bulleted style={{ padding: 0 }}>
            {isClientTask && (
              <List.Item>
                The client is responsible for completing this task.
              </List.Item>
            )}
            {hasOtherHouseholdMember && (
              <List.Item>
                {/* TODO HUB-2441 To be implemented when integrating with client card */}
                {/* {this.taskHouseholdMemberMarkup(nonClientHouseholdMember)} */}
              </List.Item>
            )}
          </List>
        </React.Fragment>
      )}
      {links.length > 0 && (
        <React.Fragment>
          {(description || isClientTask || hasOtherHouseholdMember) && (
            <Divider style={{ margin: "0.5em 0em 0.5em 0em" }} />
          )}
          <List bulleted style={{ marginBottom: "1em" }}>
            {links.map((link) => {
              const isLinkProtocol = link.url.includes("://");
              return (
                <List.Item key={link.id} style={{ display: "flex" }}>
                  <div
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    <a
                      href={isLinkProtocol ? link.url : `//${link.url}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {link.title || link.url}
                    </a>
                    {link.title && (
                      <span style={{ color: "grey" }}>
                        ,&nbsp;
                        {link.url}
                      </span>
                    )}
                  </div>
                  <Icon
                    color="grey"
                    link
                    name="copy outline"
                    title="Copy Link"
                    style={{ marginRight: "0.5em", marginLeft: "0.5em" }}
                    onClick={() => {
                      copyURL(link.url);
                    }}
                  />
                </List.Item>
              );
            })}
          </List>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

DetailsContainer.propTypes = {
  description: PropTypes.string,
  isClientTask: PropTypes.bool,
  hasOtherHouseholdMember: PropTypes.bool,
  links: PropTypes.array,
};

DetailsContainer.defaultProps = {
  description: null,
  isClientTask: false,
  hasOtherHouseholdMember: false,
  links: [],
};

export default DetailsContainer;
