import React from "react";
import PropTypes from "prop-types";
import { Loader, Button, Icon } from "semantic-ui-react";

export function AddClientButton({ loading, style, onClick }) {
  return (
    <div style={{ display: "flex", flexFlow: "row", ...style }}>
      <Button
        style={{
          backgroundColor: "rgb(249, 249, 249)",
          textAlign: "center",
          marginTop: 0,
        }}
        fluid
        onClick={(e) => {
          if (!loading) {
            onClick(e);
          }
        }}
        className="add_client_button"
      >
        {loading ? (
          <React.Fragment>
            <Loader
              active
              inline
              size="tiny"
              style={{
                marginRight: "6px",
                transform: "translate(0, -2px)",
              }}
            />
            <span style={{ color: "rgba(0,0,0,0.4)" }}>Adding</span>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Icon name="plus" />
            Add Client(s)
          </React.Fragment>
        )}
      </Button>
    </div>
  );
}

AddClientButton.defaultProps = {
  loading: false,
  style: {},
};

AddClientButton.propTypes = {
  loading: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  style: PropTypes.object,
};

export default AddClientButton;
