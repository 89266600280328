import {
  getAddRemoveStreamModalProp,
  formatAddRemoveStreamRuleRequest,
} from "../AddOrRemoveStreamRuleForm/helper";
import {
  formatAgeRuleRuleRequest,
  getAgeRuleModalProp,
} from "../AgeRuleForm/helper";
import {
  formatImportantClientDateRuleRequest,
  getImportantClientDatesModalProp,
} from "../ImportantClientDatesRuleForm/helper";
import {
  formatRepeatingRuleRequest,
  getRepeatingRuleModalProp,
} from "../RepeatingRuleForm/helper";

export const generateModalProp = (type, workflowRule) => {
  const modalProps = {
    REPEATING_RULE: getRepeatingRuleModalProp,
    IMPORTANT_DATE_RULE: getImportantClientDatesModalProp,
    ADD_ATTRIBUTES: getAddRemoveStreamModalProp,
    REMOVE_ATTRIBUTES: getAddRemoveStreamModalProp,
    AGE_RULE: getAgeRuleModalProp,
  };
  return modalProps[type](workflowRule);
};

export const formatRuleModalRequest = (type, data) => {
  const requestProps = {
    REPEATING_RULE: formatRepeatingRuleRequest,
    IMPORTANT_DATE_RULE: formatImportantClientDateRuleRequest,
    ADD_ATTRIBUTES: formatAddRemoveStreamRuleRequest,
    REMOVE_ATTRIBUTES: formatAddRemoveStreamRuleRequest,
    AGE_RULE: formatAgeRuleRuleRequest,
  };
  return requestProps[type](data);
};
