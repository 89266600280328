import React, { useContext, useState } from "react";
import PropTypes from "prop-types";

import { useInView } from "react-intersection-observer";
import { range } from "lodash";

import { useFetch } from "hooks";

import { Icon } from "semantic-ui-react";
import InfiniteScroll from "components/InfiniteScroll";
import { DraggableContainer } from "components/DragDrop";

import { useWorkflow } from "./hooks";
import { HubContext, ToasterContext } from "../../Provider";

import {
  TextualTile,
  ClientTile,
  TilePlaceholder,
  ArchiveClientModal,
  DeleteClientModal,
  WorkflowColumn,
  WorkflowMenu,
  WorkflowEditor,
  HiddenContainer,
  ClientTileEditor,
} from "./components";

import {
  moveClientTileToTop,
  markNextTaskAsCompleted,
  assignClientWorkflow,
} from "./helper";

import { AddClientDropdown } from "..";

export function WorkflowContainer({ workflow, filters, onHide, onDuplicated }) {
  const { ref: visibilityTracker, inView } = useInView({ triggerOnce: true });

  const {
    viewClientCard,
    duplicateWorkflow,
    submitSortWorkflows,
    fetchWorkflowEditorData,
  } = useContext(HubContext);
  const { fireSuccess, fireError } = useContext(ToasterContext);

  const { page, loading, empty, clientWorkflows, loadData, notify } =
    useWorkflow(workflow.id, filters, inView);

  const [clientWfToDelete, openDeleteModal] = useState(false);
  const [clientWfToArchive, openArchiveModal] = useState(false);
  const [isClientTileEditorOpen, openClientTileEditor] = useState(false);

  const [editMode, setEditMode] = useState(Boolean(workflow.editMode));

  const {
    loading: loadingWorkflowForEditor,
    data: [workflowWithEditorData],
    reloadData: loadWorkflowDataForEditor,
  } = useFetch(fetchWorkflowEditorData, {
    variables: workflow.id,
    options: { fetchOnInit: editMode },
  });

  if (inView && !loading && empty && filters.filterEmptyWorkflows) {
    return <HiddenContainer />;
  }

  if (editMode) {
    return (
      <WorkflowEditor
        loading={loadingWorkflowForEditor || !workflowWithEditorData}
        workflow={workflowWithEditorData}
        reloadData={loadWorkflowDataForEditor}
        onDeleted={onHide}
        onClose={() => {
          setEditMode(false);
        }}
      />
    );
  }

  const workflowName = () => {
    return workflowWithEditorData?.name || workflow.name;
  };

  const tileOption = () => {
    return (
      workflowWithEditorData?.clientTileOptions || workflow.clientTileOptions
    );
  };

  return (
    <div ref={visibilityTracker}>
      <WorkflowColumn
        id={workflow.id}
        testId={workflowName()}
        title={
          <WorkflowColumn.Loader active={loading || loadingWorkflowForEditor}>
            <WorkflowColumn.Header>{workflowName()}</WorkflowColumn.Header>
          </WorkflowColumn.Loader>
        }
        description={workflow.process?.name}
        color={workflow.process?.color}
        showNewTempInfo={workflow.isNewTemplate}
        showRuleInfo={Boolean(
          workflow.numWorkflowRules > 0 ||
            workflowWithEditorData?.numWorkflowRules > 0
        )}
        onDoubleClickTitle={() => {
          loadWorkflowDataForEditor();
          setEditMode(true);
        }}
        style={{ minHeight: "3em" }}
        menuSlot={
          <WorkflowMenu
            onClickEditWorkflow={() => {
              loadWorkflowDataForEditor();
              setEditMode(true);
            }}
            onClickSort={(type) => {
              return submitSortWorkflows(workflow.id, type)
                .then(() => {
                  loadData();
                  fireSuccess(`Workflow ${workflow.name} has been sorted`);
                })
                .catch(() => {
                  loadData();
                  fireError(`Failed to sort Workflow ${workflow.name}`);
                });
            }}
            onClickHideWorkflow={onHide}
            onClickDuplicateWorkflow={() => {
              duplicateWorkflow(workflow.id)
                .then((newWf) => {
                  fireSuccess(`Duplicated ${workflow.name} successfully`);
                  onDuplicated(newWf);
                })
                .catch(() => {
                  fireError(`Failed to duplicate workflow ${workflow.name}`);
                });
            }}
            onClickModifyTitle={() => {
              openClientTileEditor(true);
            }}
          />
        }
        topChildren={
          <AddClientDropdown
            workflowId={workflow.id}
            onClientsAdded={loadData}
            style={{ paddingBottom: 0 }}
            data-test={`workflow-add-clients-${workflow.name}`}
          />
        }
      >
        {loading &&
          clientWorkflows.length === 0 &&
          (workflow.numActiveClientWorkflows === 0 ? (
            <TextualTile text="Drag a client tile here" />
          ) : (
            range(workflow.numActiveClientWorkflows).map((idx) => {
              return <TilePlaceholder key={idx} />;
            })
          ))}

        {!loading && clientWorkflows.length === 0 && (
          <TextualTile text="Drag a client tile here" />
        )}

        <InfiniteScroll
          loadingPage={page.loading}
          hasMorePages={page.hasMorePages}
          onLoadMore={page.readNext}
          loadingElement={<TilePlaceholder />}
          pullNextPageElement={
            <div style={{ color: "grey", textAlign: "center" }}>
              Scroll down to load more
              <Icon name="angle down" />
            </div>
          }
        >
          {clientWorkflows.map((clientWf, i) => {
            const draggableId = `${clientWf.client.id}:${clientWf.id}`;
            return (
              <DraggableContainer index={i} key={clientWf.id} id={draggableId}>
                <ClientTile
                  clientTileAppearance={tileOption()}
                  data={clientWf}
                  onClick={() => {
                    const { id, client } = clientWf;
                    viewClientCard(client.id, id);
                  }}
                  onDelete={openDeleteModal}
                  onArchive={openArchiveModal}
                  onAssignWorkflow={async ({ assignee, cwId }) => {
                    try {
                      await assignClientWorkflow(cwId, assignee);
                      loadData();
                      fireSuccess("Assigned Client Workflow Successfully");
                    } catch (e) {
                      fireError("Failed to assign Client Workflow");
                    }
                  }}
                  onTopTaskComplete={(task) => {
                    markNextTaskAsCompleted(task)
                      .then(() => {
                        notify();
                        fireSuccess(
                          `Successfully completed task ${task.title}`
                        );
                      })
                      .catch(() => {
                        fireError("Failed to mark Client's Task as completed");
                      });
                  }}
                  onMoveUp={() => {
                    moveClientTileToTop(clientWf.id, clientWorkflows[0].id)
                      .then(() => {
                        loadData();
                        fireSuccess("Client has moved to the top");
                      })
                      .catch(() => {
                        fireError("Failed to moved client to the top");
                      });
                  }}
                />
              </DraggableContainer>
            );
          })}
        </InfiniteScroll>
      </WorkflowColumn>

      {clientWfToDelete && (
        <DeleteClientModal
          clientWfId={clientWfToDelete}
          onComplete={loadData}
          onClose={() => {
            openDeleteModal(false);
          }}
        />
      )}

      {clientWfToArchive && (
        <ArchiveClientModal
          clientWfId={clientWfToArchive}
          onComplete={loadData}
          onClose={() => {
            openArchiveModal(false);
          }}
        />
      )}

      {isClientTileEditorOpen && (
        <ClientTileEditor
          id={workflow.id}
          name={workflowName()}
          clientTileOptions={tileOption()}
          onClose={({ numberOfTasks }) => {
            if (numberOfTasks !== tileOption().numberOfTasks) loadData();
            loadWorkflowDataForEditor();
            openClientTileEditor(false);
          }}
        />
      )}
    </div>
  );
}

WorkflowContainer.propTypes = {
  workflow: PropTypes.object.isRequired,
  filters: PropTypes.object.isRequired,
  onHide: PropTypes.func.isRequired,
  onDuplicated: PropTypes.func.isRequired,
};

WorkflowContainer.defaultProps = {};

export default WorkflowContainer;
