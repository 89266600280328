import React from "react";
import { Header, Icon } from "semantic-ui-react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ImportantDatesModal from "components/ImportantDatesModal";
import ContactEditableField from "./ContactEditableField";
import { makeGetImportantClientDates } from "./selector";

class ContactInformation extends React.Component {
  static propTypes = {
    client: PropTypes.object.isRequired,
    advisor: PropTypes.object.isRequired,
    hub: PropTypes.object.isRequired,
    importantClientDates: PropTypes.array,
  };

  static defaultProps = {
    importantClientDates: [],
  };

  state = {
    manageDatesOpen: false,
    showMoreDates: false,
  };

  render() {
    const { client, advisor, hub, importantClientDates } = this.props;
    const { manageDatesOpen, showMoreDates } = this.state;
    const integration = advisor.integrations.find((i) => {
      return i.hubId === hub.id;
    });
    const integrationType = integration ? integration.type : "";
    const isCRMIntegrated = integration !== undefined;
    return (
      <div style={{ marginTop: "2em" }}>
        {(client.email || client.phoneNumber || !isCRMIntegrated) && (
          <React.Fragment>
            <Header as="h5" style={{ marginBottom: "0.5em" }}>
              Contact Information
            </Header>
            <div style={{ display: "flex", flexDirection: "column" }}>
              {(client.email || !isCRMIntegrated) && (
                <ContactEditableField
                  client={client}
                  icon="mail"
                  key="email"
                  name="email"
                  showTitle={false}
                  text={client.email}
                  title="Email"
                  type="string"
                  editable={!isCRMIntegrated}
                />
              )}
              {(client.phoneNumber || !isCRMIntegrated) && (
                <ContactEditableField
                  client={client}
                  icon="phone"
                  key="phoneNumber"
                  name="phoneNumber"
                  showTitle={false}
                  text={client.phoneNumber}
                  title="Phone Number"
                  type="string"
                  editable={!isCRMIntegrated}
                />
              )}
            </div>
          </React.Fragment>
        )}
        {client.clientType === "PERSON" && (
          <div data-test="contact-information-important-dates-container">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "0.5em",
                marginTop: "1em",
              }}
            >
              <Header as="h5" style={{ margin: 0 }}>
                Important Client Dates
              </Header>
              <div
                className="clickable grey_on_hover rounded p25"
                style={{ marginLeft: "auto" }}
                onClick={() => {
                  this.setState({ manageDatesOpen: true });
                }}
              >
                <Icon color="grey" name="setting" style={{ margin: "0px" }} />
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              {importantClientDates
                .filter((clientDate) => {
                  return !clientDate.showMore;
                })
                .map((clientDate) => {
                  const { name } = clientDate;
                  return (
                    <ContactEditableField
                      client={client}
                      title={name}
                      key={name}
                      type="date"
                      dateType={clientDate}
                      integrationType={integrationType}
                      data-test={`contact-field-${name}`}
                      {...clientDate}
                    />
                  );
                })}
              {showMoreDates &&
                importantClientDates
                  .filter((clientDate) => {
                    return clientDate.showMore;
                  })
                  .map((clientDate) => {
                    const { name } = clientDate;
                    return (
                      <ContactEditableField
                        client={client}
                        title={name}
                        key={name}
                        type="date"
                        integrationType={integrationType}
                        dateType={clientDate}
                        data-test={`contact-field-${name}`}
                        {...clientDate}
                      />
                    );
                  })}
              <div
                className="clickable rounded grey_on_hover"
                onClick={() => {
                  this.setState({ showMoreDates: !showMoreDates });
                }}
                style={{
                  color: "grey",
                  fontSize: "10pt",
                  margin: "auto",
                  padding: "0.10em 0.25em",
                  marginTop: "5px",
                }}
              >
                {showMoreDates ? "Show Fewer" : "Show More"}
              </div>
            </div>
            {manageDatesOpen && (
              <ImportantDatesModal
                closeModal={() => {
                  this.setState({ manageDatesOpen: false });
                }}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = () => {
  const getImportantClientDates = makeGetImportantClientDates();
  return (state, ownProps) => {
    return {
      advisor: state.hubly.data.advisor,
      hub: state.hubly.data.hub.selected.hub,
      importantClientDates: getImportantClientDates(state, ownProps),
    };
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactInformation);
