import { API } from "aws-amplify";
import { getAPIReq, getCognitoId } from "./authLib";

export const GetAdvisor = async () => {
  const req = await getAPIReq();
  const id = await getCognitoId();
  return API.get("HublyAPI", `/advisors/${id}/`, req);
};

export const EditAdvisor = async (id, request) => {
  const req = await getAPIReq(request);
  return API.put("HublyAPI", `/advisors/${id}/`, req);
};

export const SetAdvisorPreferences = async (id, request) => {
  const req = await getAPIReq(request);
  return API.put("HublyAPI", `/advisors/${id}/set_preferences/`, req);
};
