import React from "react";
import PropTypes from "prop-types";
import ReactQuill from "react-quill";
import {
  Button,
  Form,
  Icon,
  Label,
  Message,
  Modal,
  TextArea,
} from "semantic-ui-react";
import { isEmail } from "validator";
import { connect } from "react-redux";
import { SetAlert } from "components/Alerts/actions";
import { ToggleTaskEmailTemplateModal } from "components/Task/actions";
import SendEmail from "./components/SendEmail";
import {
  AddEmailTemplate,
  RemoveEmailTemplate,
  EditEmailTemplate,
} from "./actions";
import "react-quill/dist/quill.snow.css";
import "./styles.css"; // Bug in ReactQuill passing in children div with custom styling, so need separate CSS file

// Pass in emailInHubly in component to convert to HTML email with validation
// Built for future functionality with an email service within app, rather than mailto
class TaskEmailTemplate extends React.Component {
  static defaultProps = {
    client: {},
    addingTask: false,
    editTaskState: () => {},
    emailToField: false,
    emailInHubly: false,
    emailTemplate: {},
    modalTaskId: "",
  };

  static propTypes = {
    addingTask: PropTypes.bool,
    addEmailTemplate: PropTypes.func.isRequired,
    client: PropTypes.object,
    emailToField: PropTypes.bool,
    emailInHubly: PropTypes.bool,
    emailTemplate: PropTypes.object,
    editEmailTemplate: PropTypes.func.isRequired,
    editTaskState: PropTypes.func,
    isTaskEmailTemplateOpen: PropTypes.bool.isRequired,
    modalTaskId: PropTypes.string,
    removeEmailTemplate: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired,
    task: PropTypes.shape({
      id: PropTypes.string.isRequired,
      emailTemplate: PropTypes.object,
      title: PropTypes.string.isRequired,
    }).isRequired,
    toggleTaskEmailTemplateModal: PropTypes.func.isRequired,
    workflow: PropTypes.shape({
      tasks: PropTypes.array.isRequired,
      id: PropTypes.string.isRequired,
    }).isRequired,
  };

  // Required either a trigger or a contex
  // if provided a context you must use the parentOpen prop to open and a callback to close
  static defaultProps = {};

  // Added in as this causes spacing issues when using HTML rather than the Quill Delta system
  modules = {
    clipboard: {
      matchVisual: false,
    },
  };

  constructor(props) {
    super(props);
    const { client } = this.props;
    const { emailTemplate } = props.task;
    let defaultEmail = "";
    if (client && client.email) {
      defaultEmail = client.email;
    }

    this.state = {
      to:
        emailTemplate && emailTemplate.to !== ""
          ? emailTemplate.to
          : defaultEmail,
      subject: emailTemplate ? emailTemplate.subject : "",
      body: emailTemplate ? emailTemplate.body : "",
      errorMessage: "",
      toError: false,
      subjectError: false,
      bodyError: false,
      loading: false,
    };
  }

  initializeEmailTemplate = () => {
    const { client } = this.props;
    const { emailTemplate } = this.props.task;
    let defaultEmail = "";
    if (client && client.email) {
      defaultEmail = client.email;
    }
    this.setState({
      to:
        emailTemplate && emailTemplate.to !== ""
          ? emailTemplate.to
          : defaultEmail,
      subject: emailTemplate ? emailTemplate.subject : "",
      body: emailTemplate ? emailTemplate.body : "",
      errorMessage: "",
      toError: false,
      subjectError: false,
      bodyError: false,
      loading: false,
    });
  };

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  };

  handleBody = (event, data) => {
    this.setState({ body: data.value });
  };

  handleBodyHTML = (content) => {
    this.setState({ body: content });
  };

  isOpen = () => {
    const { isTaskEmailTemplateOpen, modalTaskId, task } = this.props;
    return task.id === modalTaskId && isTaskEmailTemplateOpen;
  };

  saveCallBack = (success) => {
    const { setAlert, toggleTaskEmailTemplateModal } = this.props;
    this.setState({ loading: false });
    if (success) {
      toggleTaskEmailTemplateModal();
    } else {
      setAlert({ type: "error", text: "Failed to save email template" });
    }
  };

  saveEmailTemplate = () => {
    const {
      addEmailTemplate,
      addingTask,
      client,
      editEmailTemplate,
      editTaskState,
      emailInHubly,
      emailTemplate,
      emailToField,
      task,
      workflow,
    } = this.props;
    const { to, subject, body } = this.state;
    let errorMessage = "";
    let valid = true;
    let toError = false;
    let subjectError = false;
    let bodyError = false;
    // If this is a full-blown email client, where we're sending email, need validation
    // With mailto links, we do not need it
    if (emailInHubly) {
      if (emailToField && !isEmail(to.toLowerCase())) {
        // Check if email is required and is valid
        errorMessage = errorMessage.concat("To email address is invalid. ");
        valid = false;
      }

      toError = !valid;
      if (!subject) {
        errorMessage = errorMessage.concat("Subject cannot be empty. ");
        valid = false;
        subjectError = true;
      }

      // ReactQuill injects the <p><br></p>, so can't just check if it's empty
      if (!body || body === "<p><br></p>") {
        errorMessage = errorMessage.concat("Body cannot be empty. ");
        valid = false;
        bodyError = true;
      }
    }

    if (valid) {
      if (emailTemplate && emailTemplate.id) {
        editEmailTemplate(
          client.id,
          workflow.id,
          task.id,
          emailTemplate.id,
          to,
          subject,
          body,
          this.saveCallBack
        );
      } else if (!addingTask) {
        addEmailTemplate(
          client.id,
          workflow.id,
          task.id,
          to,
          subject,
          body,
          this.saveCallBack
        );
      } else {
        editTaskState(
          "emailTemplate",
          { to: to, subject: subject, body: body },
          "edit"
        );
        this.saveCallBack(true);
      }
      this.setState({ loading: true });
    }

    this.setState({
      toError: toError,
      subjectError: subjectError,
      bodyError: bodyError,
      errorMessage: errorMessage,
    });
  };

  removeCallBack = (success) => {
    const { toggleTaskEmailTemplateModal } = this.props;
    this.setState({ loading: false });
    if (success) {
      this.setState({
        to: "",
        subject: "",
        body: "",
        toError: false,
        subjectError: false,
        errorMessage: "",
      });
      toggleTaskEmailTemplateModal();
    } else {
      this.props.setAlert({
        type: "error",
        text: "Failed to delete email template",
      });
    }
  };

  removeEmailTemplate = () => {
    const {
      addingTask,
      client,
      editTaskState,
      removeEmailTemplate,
      task,
      workflow,
    } = this.props;
    if (addingTask) {
      editTaskState("emailTemplate", null, "edit");
      this.removeCallBack(true);
    } else {
      removeEmailTemplate(
        client.id,
        workflow.id,
        task.id,
        task.emailTemplate.id,
        this.removeCallBack
      );
    }
    this.setState({ loading: true });
  };

  taskHasEmailTemplate = () => {
    const { task } = this.props;
    return (
      task.emailTemplate &&
      (task.emailTemplate.to ||
        task.emailTemplate.subject ||
        task.emailTemplate.body)
    );
  };

  renderTrigger = () => {
    const { task, toggleTaskEmailTemplateModal } = this.props;
    if (this.taskHasEmailTemplate()) {
      return (
        <Icon
          color="grey"
          name="mail"
          onClick={() => {
            toggleTaskEmailTemplateModal(task.id, true);
            this.initializeEmailTemplate();
          }}
          link
          title="This task has an email template saved"
        />
      );
    } else {
      return (
        <Icon.Group
          className="show_on_hover dark_grey_on_hover"
          style={{ fontSize: "13pt" }}
          onClick={() => {
            toggleTaskEmailTemplateModal(task.id, true);
            this.initializeEmailTemplate();
          }}
          title="Email Template"
        >
          <Icon link name="mail outline" style={{ margin: 0 }} />
          <Icon
            link
            corner
            name="add"
            style={{ color: "#aaaaaa", textShadow: "none" }}
          />
        </Icon.Group>
      );
    }
  };

  render() {
    // If emailInHubly is set to true, will use ReactQuill for full HTML editor and adds validation
    // Otherwise, uses a textarea and no validation for mailto service
    const {
      client,
      emailToField,
      emailInHubly,
      task,
      toggleTaskEmailTemplateModal,
    } = this.props;
    const {
      to,
      subject,
      body,
      toError,
      subjectError,
      bodyError,
      errorMessage,
      loading,
    } = this.state;
    // Need to check for the template exist and due to defaults in the advisor model, will need to check that one of the
    // fields is not empty
    const emailTemplateExists =
      task.emailTemplate &&
      (task.emailTemplate.to ||
        task.emailTemplate.subject ||
        task.emailTemplate.body);

    if (!this.isOpen()) {
      return this.renderTrigger();
    } else {
      return (
        <React.Fragment>
          {this.renderTrigger()}
          <Modal
            size="small"
            on="click"
            open
            onOpen={() => {
              toggleTaskEmailTemplateModal(task.id, true);
              this.initializeEmailTemplate();
            }}
            onClose={() => {
              toggleTaskEmailTemplateModal();
            }}
          >
            <Modal.Header as="h4">
              <Icon
                color="grey"
                link
                name="close"
                onClick={() => {
                  toggleTaskEmailTemplateModal();
                }}
                style={{ float: "right" }}
              />
              Email Template
            </Modal.Header>
            <Modal.Content>
              <Form error>
                <Message negative hidden={errorMessage.length === 0}>
                  <Message.Header>Error</Message.Header>
                  <p>{errorMessage}</p>
                </Message>
                {emailToField ? (
                  <Form.Input
                    required={emailInHubly}
                    name="to"
                    label="To"
                    autoComplete="off"
                    placeholder="example@email.com"
                    value={to}
                    onChange={this.handleChange}
                    error={toError}
                    disabled={loading}
                  />
                ) : (
                  <Form.Field>
                    <label>To</label>
                    <Label>Client Email</Label>
                  </Form.Field>
                )}
                <Form.Input
                  required={emailInHubly}
                  name="subject"
                  label="Subject"
                  autoComplete="off"
                  placeholder="Subject"
                  value={subject}
                  onChange={this.handleChange}
                  error={subjectError}
                  disabled={loading}
                />
                <Form.Field required={emailInHubly} error={bodyError}>
                  <label>Body</label>
                  {emailInHubly ? (
                    <ReactQuill
                      name="body"
                      value={body}
                      onChange={(content) => {
                        this.handleBodyHTML(content);
                      }}
                      modules={this.modules}
                      disabled={loading}
                    />
                  ) : (
                    <TextArea
                      name="body"
                      value={body}
                      onChange={(event, data) => {
                        this.handleBody(event, data);
                      }}
                      style={{
                        minHeight: "10em",
                      }}
                      disabled={loading}
                    />
                  )}
                </Form.Field>
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button
                color="green"
                type="submit"
                onClick={() => {
                  this.saveEmailTemplate(true);
                }}
                loading={loading}
              >
                Save
              </Button>
              {client.id && <SendEmail to={to} subject={subject} body={body} />}
              {emailTemplateExists && (
                <Button
                  negative
                  floated="left"
                  disabled={loading}
                  onClick={this.removeEmailTemplate}
                >
                  Remove
                </Button>
              )}
            </Modal.Actions>
          </Modal>
        </React.Fragment>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    isTaskEmailTemplateOpen: state.hubly.task.isTaskEmailTemplateOpen,
    modalTaskId: state.hubly.task.modalTaskId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    editEmailTemplate: (
      clientId,
      workflowId,
      taskId,
      reminderId,
      to,
      subject,
      body,
      callback
    ) => {
      dispatch(
        EditEmailTemplate(
          clientId,
          workflowId,
          taskId,
          reminderId,
          to,
          subject,
          body,
          callback
        )
      );
    },
    addEmailTemplate: (
      clientId,
      workflowId,
      taskId,
      to,
      subject,
      body,
      callback
    ) => {
      dispatch(
        AddEmailTemplate(
          clientId,
          workflowId,
          taskId,
          to,
          subject,
          body,
          callback
        )
      );
    },
    removeEmailTemplate: (
      clientId,
      workflowId,
      taskId,
      templateId,
      callback
    ) => {
      dispatch(
        RemoveEmailTemplate(clientId, workflowId, taskId, templateId, callback)
      );
    },
    setAlert: (alert) => {
      dispatch(SetAlert(alert));
    },
    toggleTaskEmailTemplateModal: (taskId, isOpen) => {
      dispatch(ToggleTaskEmailTemplateModal(taskId, isOpen));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(TaskEmailTemplate);
