import React, { useState } from "react";
import PropTypes from "prop-types";
import { Checkbox } from "semantic-ui-react";
import RuleClientsDropdown from "./RuleClientsDropdown";

const ExcludeItems = ({ setSelectedItems, selectedItems, ...restProps }) => {
  const [showDropdown, setShowDropdown] = useState(selectedItems?.length > 0);
  const checkboxLabel = ["Exclude specific clients"];
  if (showDropdown) {
    checkboxLabel.push(" where ");
    checkboxLabel.push(<b>any</b>);
    checkboxLabel.push(" of these criteria apply:");
  }

  const handleCheckboxChange = () => {
    if (showDropdown) {
      setSelectedItems([]);
    }
    setShowDropdown(!showDropdown);
  };

  return (
    <React.Fragment>
      <Checkbox
        checked={showDropdown}
        label={checkboxLabel}
        name="excludeItems"
        onChange={handleCheckboxChange}
        style={{ marginBottom: "1em" }}
      />
      {showDropdown && (
        <RuleClientsDropdown
          setSelectedItems={setSelectedItems}
          selectedItems={selectedItems}
          showClients
          {...restProps}
        />
      )}
    </React.Fragment>
  );
};

ExcludeItems.propTypes = {
  selectedItems: PropTypes.array,
  setSelectedItems: PropTypes.func,
};

ExcludeItems.defaultProps = {
  selectedItems: [],
  setSelectedItems: () => {},
};

export default ExcludeItems;
