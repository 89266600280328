import React from "react";
import PropTypes from "prop-types";

import { Header, Dropdown, Popup, Icon } from "semantic-ui-react";

export default function OptionItem({
  title,
  description,
  onClear,
  options,
  currentValue,
  onSelection,
  dropDownProps,
}) {
  return (
    <React.Fragment>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div>
          <Header as="h4" style={{ display: "inline" }}>
            {title}
          </Header>
        </div>
      </div>
      <div style={{ margin: "0em 1em 0em 1em" }}>
        <Dropdown
          data-test="hubfeed-settings-assignee-input"
          search
          selection
          name={title.toLowerCase()}
          placeholder={description}
          onChange={onSelection}
          options={options}
          value={currentValue}
          {...dropDownProps}
        />
      </div>
      <div>
        <Popup
          content="Clear"
          on="hover"
          position="top center"
          trigger={<Icon name="delete" link color="grey" onClick={onClear} />}
        />
      </div>
    </React.Fragment>
  );
}

OptionItem.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    })
  ),
  currentValue: PropTypes.string.isRequired,
  onClear: PropTypes.func.isRequired,
  onSelection: PropTypes.func.isRequired,
  dropDownProps: PropTypes.object,
};

OptionItem.defaultProps = {
  options: [],
  dropDownProps: {},
};
