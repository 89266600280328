import React from "react";
import { Header } from "semantic-ui-react";

class ErrorPage extends React.Component {
  render() {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "5em",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          <div>
            <Header size="huge" style={{ fontSize: "180px" }}>
              500
            </Header>
          </div>
          <Header size="large">
            Oops, something went wrong on our end.
            <br />
            in a few minutes.
          </Header>
          <div>
            If the error persists,
            <strong>
              <a href="mailto:support@myhubly.com?subject=Can't log in">
                {" "}
                contact us{" "}
              </a>
            </strong>
            for support
            <br />
            or
            <strong>
              <a
                href="https://hubly.statuspage.io/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                check{" "}
              </a>
            </strong>
            our current server status.
          </div>
          <div style={{ marginTop: "5em" }}>
            <img
              alt="hubly logo"
              style={{
                height: "35px",
                marginLeft: "1em",
                display: "inline-block",
              }}
              src="/images/hubly.png"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default ErrorPage;
