import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { ScrollContext } from "./Context";

const ClickToScroll = ({ children }) => {
  const [scrollToItem, setScrollToItem] = useState(null);
  const [refs, setRefs] = useState({});

  useEffect(() => {
    if (!scrollToItem || !refs || !(scrollToItem in refs)) return;

    if (refs[scrollToItem]?.current) {
      refs[scrollToItem].current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [scrollToItem, refs]);

  return (
    <ScrollContext.Provider
      value={{
        scrollToItem: scrollToItem,
        setScrollToItem: setScrollToItem,
        refs: refs,
        setRefs: setRefs,
      }}
    >
      {children}
    </ScrollContext.Provider>
  );
};

ClickToScroll.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default ClickToScroll;
