import React from "react";
import PropTypes from "prop-types";

import Moment from "moment";

import { Icon } from "semantic-ui-react";

import getOverdueInfo from "data/libs/overdueReminders";

export function ReminderInfo({ title, time, onClick, children }) {
  const { textColor, iconColor, overdue } = getOverdueInfo(time);

  return (
    <div
      data-test={`reminder-item-${title}`}
      className="grey_on_hover"
      onClick={onClick}
      style={{
        padding: "0.4em 0.5em",
        display: "flex",
        cursor: "pointer",
        borderRadius: "5px",
      }}
    >
      <div>
        <Icon
          name={overdue ? "bell outline" : "bell outline"}
          color={iconColor}
        />
      </div>
      <div className="truncate-text" style={{ flex: "1", width: "0px" }}>
        <span
          style={{
            color: textColor,
            fontWeight: overdue ? "150%" : "normal",
          }}
        >
          {Moment(time).format("h:mm a")}
        </span>
        &nbsp;&nbsp;-&nbsp;&nbsp;
        <div style={{ display: "inline" }} data-test="reminder-item-title">
          {title}
        </div>
      </div>
      <div style={{ paddingLeft: "0.25em", marginLeft: "auto" }}>
        {children}
      </div>
    </div>
  );
}

ReminderInfo.propTypes = {
  title: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node,
};

ReminderInfo.defaultProps = {
  children: null,
};

export default ReminderInfo;
