import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { Button, Responsive } from "semantic-ui-react";
import { HubContext, ToasterContext } from "../../Provider";

export function NewWorkflow({ onCreate }) {
  const { createWorkflow } = useContext(HubContext);
  const { fireError, fireSuccess } = useContext(ToasterContext);

  const [loading, setLoading] = useState(false);

  const newWorkflow = () => {
    setLoading(true);
    createWorkflow({
      name: "Untitled",
    })
      .then((resp) => {
        onCreate(resp);
        fireSuccess("Successfully created workflow.");
      })
      .catch((error) => {
        fireError("Failed to create workflow.", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      <Responsive
        as={Button}
        basic
        icon="plus"
        id="filter-workflow-button"
        maxWidth={1350}
        loading={loading}
        onClick={() => {
          newWorkflow();
        }}
        style={{ marginRight: 0 }}
      />
      <Responsive
        as={Button}
        basic
        content="Create Workflow"
        icon="plus"
        loading={loading}
        id="filter-workflow-button"
        minWidth={1351}
        onClick={() => {
          newWorkflow();
        }}
        style={{ marginRight: 0, whiteSpace: "nowrap" }}
      />
    </div>
  );
}

NewWorkflow.propTypes = {
  onCreate: PropTypes.func.isRequired,
};

export default NewWorkflow;
