import { createSelector } from "reselect";
import { sortWorkflow } from "data/hub/workflows/actions";
import {
  getFilteredReminderCounts,
  getProcessWorkflowsAndTotalClient,
  sortProcess,
} from "./helpers";

const selectWorkflows = (state) => {
  return state.hubly.data.hub.workflows;
};

const selectFilters = (state) => {
  return state.hubly.data.filters;
};

const selectReminders = (_, { reminders = [] } = {}) => {
  return reminders;
};

const selectProcesses = (state) => {
  return state.hubly.data.hub.selected.hub.processes;
};

const selectClientWorkflows = (state) => {
  return state.hubly.data.hub.clientWorkflows;
};

export const selectProcessTileProps = () => {
  return createSelector(
    [
      selectWorkflows,
      selectClientWorkflows,
      selectFilters,
      selectReminders,
      selectProcesses,
    ],
    (workflows, clientWorkflows, filters, reminders, processes) => {
      return processes
        .map((process) => {
          const [processWorkflows, totalClient, processActive] =
            getProcessWorkflowsAndTotalClient(workflows, filters, process.id);

          const filteredReminderCounts = getFilteredReminderCounts(
            reminders,
            workflows,
            clientWorkflows,
            process.id
          );

          return {
            id: process.id,
            process: process,
            processWorkflows: processWorkflows.sort(sortWorkflow),
            totalClient: totalClient,
            active: processActive,
            filteredReminderCounts: filteredReminderCounts,
          };
        })
        .sort(sortProcess);
    }
  );
};

export default selectProcessTileProps;
