import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { makeGetVisibleClient } from "data/hub/clients/selector";
import { SetMenuFilters } from "data/filters/actions";

import withPrivacyMode from "components/PrivacyMode/WithPrivacyMode";
import Selector from "components/Selector";
import FilterLayout from "../components/FilterLayout";

export function ClientFilter({
  clientsToFilterBy,
  selectedClients,
  loadingClients,
  onUpdateFilter,
  className,
}) {
  return (
    <FilterLayout
      title="Clients"
      onClearFilter={() => {
        onUpdateFilter([]);
      }}
    >
      <Selector
        name="Clients"
        dropdownProps={{
          placeholder: "Select client",
          noResultsMessage: "No more clients",
          loading: loadingClients,
          className: `fs-block dd-privacy-mask ${className}`,
        }}
        selection={clientsToFilterBy.map(({ id, name }) => {
          return { key: id, value: name };
        })}
        selected={selectedClients}
        onSelect={onUpdateFilter}
      />
    </FilterLayout>
  );
}

ClientFilter.propTypes = {
  className: PropTypes.string,
  loadingClients: PropTypes.bool,
  clientsToFilterBy: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  selectedClients: PropTypes.arrayOf(PropTypes.string),
  onUpdateFilter: PropTypes.func.isRequired,
};

ClientFilter.defaultProps = {
  className: undefined,
  loadingClients: false,
  clientsToFilterBy: [],
  selectedClients: [],
};

const makeMapStateToProps = () => {
  const getVisibleClients = makeGetVisibleClient();

  return (state, ownProps) => {
    const { clientFilter } = state.hubly.data.filters;
    const { allClientNamesLoaded } = state.hubly.data.hub.clients;

    return {
      clientsToFilterBy: getVisibleClients(state, ownProps),
      loadingClients: !allClientNamesLoaded,
      selectedClients: clientFilter,
    };
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateFilter: (newClientsSelection) => {
      dispatch(
        SetMenuFilters("ClientFilter", (allFilters) => {
          return { ...allFilters, clientFilter: newClientsSelection };
        })
      );
    },
  };
};

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(withPrivacyMode(ClientFilter));
