import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Dimmer, Loader } from "semantic-ui-react";
import { ConfirmationModal } from "components/ConfirmationModal";

import {
  HubContext,
  ToasterContext,
} from "scenes/Hubly/components/Workspace/Provider";

export function AddClientConfirmationModal({
  workflowId,
  client,
  familyHead,
  confirmMultipleClientAdd,
  onAddClient,
  onClose,
}) {
  const { fetchClientWorkflowCount } = useContext(HubContext);
  const { fireError } = useContext(ToasterContext);

  const [loading, setLoading] = useState(!confirmMultipleClientAdd);
  const [familyHeadExisted, setFamilyHeadExisted] = useState(false);

  useEffect(() => {
    let mount = true;

    if (loading) {
      fetchClientWorkflowCount(workflowId, familyHead.id)
        .then(({ activeClientWorkflowCount }) => {
          if (mount) {
            setFamilyHeadExisted(activeClientWorkflowCount === 1);
          }
        })
        .catch((error) => {
          fireError(`Failed to load clients data.`, error);
        })
        .finally(() => {
          setLoading(false);
        });
    }

    return () => {
      mount = false;
    };
  }, [loading, familyHead.id, fetchClientWorkflowCount, workflowId, fireError]);

  if (loading) {
    return (
      <Dimmer active page>
        <Loader>Loading</Loader>
      </Dimmer>
    );
  }

  const buttons = [
    {
      text: "Cancel",
      callBack: () => {
        onClose(null);
      },
    },
    {
      text: `Add ${client.name}`,
      callBack: (e) => {
        onAddClient(client);
      },
    },
  ];

  if (!familyHeadExisted && !confirmMultipleClientAdd) {
    buttons.push({
      text: `Add ${familyHead.name}`,
      callBack: () => {
        onAddClient(familyHead);
      },
      color: "green",
    });
  }
  let title = "";
  let message = "";
  if (confirmMultipleClientAdd) {
    title = `${client.name} is already in this workflow. Do you want to add the client again?`;
  } else {
    title = familyHeadExisted
      ? `${familyHead.name} is the head of this household and already in this workflow`
      : `${familyHead.name} is currently the head of this household`;
    message = `Are you sure that you want to add ${client.name} ${
      familyHeadExisted ? "as well" : "instead"
    }?`;
  }

  return (
    <ConfirmationModal
      title={title}
      message={message}
      icon="tasks"
      buttons={buttons}
    />
  );
}
AddClientConfirmationModal.defaultProps = {
  familyHead: {},
  confirmMultipleClientAdd: false,
};

AddClientConfirmationModal.propTypes = {
  client: PropTypes.object.isRequired,
  confirmMultipleClientAdd: PropTypes.bool,
  workflowId: PropTypes.string.isRequired,
  familyHead: PropTypes.object,
  onAddClient: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AddClientConfirmationModal;
