import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import { range } from "lodash";
import { Header } from "semantic-ui-react";
import { HubContext } from "scenes/Hubly/components/Workspace/Provider";
import SimpleFormModal from "components/SimpleFormModal";
import { useDropdownData } from "hooks";

import WorkflowCheckbox from "./components/WorkflowCheckbox/WorkflowCheckbox";
import { WorkflowTilePlaceholder } from "..";
import WorkflowListWithScrollToLoad from "./components/WorkflowListWithScrollToLoad";

export function ProcessModal({ process, loading, onSaved, onClosed }) {
  const { fetchWorkflowsWithNoProcess } = useContext(HubContext);

  const [workflows, setWorkflows] = useState(process?.workflows || []);
  const [workflowListChanged, setWorkflowListChanged] = useState(false);
  const [selectedWorkflows, setSelectedWorkflows] = useState(
    process?.workflows.map(({ id }) => {
      return id;
    }) || []
  );
  const {
    loading: loadingWorkflows,
    data: workflowWithNoProcess,
    hasMoreData,
    loadMoreData,
  } = useDropdownData({
    type: "workflow",
    initialLoad: true,
    fetchFunction: fetchWorkflowsWithNoProcess,
  });

  useEffect(() => {
    setWorkflows([...(process?.workflows || []), ...workflowWithNoProcess]);
  }, [process, workflowWithNoProcess]);

  const onClick = (formData) => {
    const savedProcess = {
      name: formData.name,
      color: formData.color,
      description: formData.description,
      workflows: [...selectedWorkflows],
    };
    if (process) {
      onSaved({
        created: false,
        savedProcess: { id: process.id, ...savedProcess },
        initialProcess: process,
      });
    } else {
      onSaved({
        created: true,
        savedProcess: savedProcess,
      });
    }
  };

  const onWorkflowChecked = (workflowId, checked) => {
    setSelectedWorkflows((prevWorkflows) => {
      if (checked) {
        if (!prevWorkflows.includes(workflowId)) {
          return [...prevWorkflows, workflowId];
        }
      } else if (prevWorkflows.includes(workflowId)) {
        return prevWorkflows.filter((wId) => {
          return wId !== workflowId;
        });
      }
      return prevWorkflows;
    });
    setWorkflowListChanged(true);
  };

  return (
    <SimpleFormModal
      title="Process"
      loading={loading}
      value={process}
      allowEmptyColor={false}
      onClickSave={onClick}
      onClosed={() => {
        onClosed();
      }}
      enableNoChangesRule={!workflowListChanged}
    >
      <Header as="h5">Workflows</Header>

      <WorkflowListWithScrollToLoad
        showLoadMore={hasMoreData}
        loading={loadingWorkflows}
        loadMoreData={loadMoreData}
      >
        <React.Fragment>
          {workflows.length === 0 && !loadingWorkflows && (
            <div style={{ color: "grey" }}>
              No workflows available for the process.
            </div>
          )}
          {workflows.map((workflow) => {
            return (
              <WorkflowCheckbox
                id={workflow.id}
                key={`workflow-cb-${workflow.id}`}
                name={workflow.name}
                checked={selectedWorkflows.includes(workflow.id)}
                totalClient={workflow.numActiveClients}
                totalTask={workflow.numTasks}
                onWorkflowChecked={onWorkflowChecked}
              />
            );
          })}
          {loadingWorkflows &&
            range(4).map((i) => {
              return <WorkflowTilePlaceholder key={`id-${i}`} />;
            })}
        </React.Fragment>
      </WorkflowListWithScrollToLoad>
    </SimpleFormModal>
  );
}

ProcessModal.defaultProps = {
  loading: false,
  process: {},
};

ProcessModal.propTypes = {
  process: PropTypes.shape({
    id: PropTypes.string,
    description: PropTypes.string,
    color: PropTypes.string,
    name: PropTypes.string,
    workflows: PropTypes.array,
  }),
  loading: PropTypes.bool,
  onSaved: PropTypes.func.isRequired,
  onClosed: PropTypes.func.isRequired,
};

export default ProcessModal;
