import React from "react";
import PropTypes from "prop-types";
import ReactTrancatedFormat from "react-truncated-component";
import Anchor from "../Anchor";

class Expandable extends React.Component {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
      .isRequired,
    lines: PropTypes.number,
  };

  static defaultProps = {
    lines: 2,
  };

  state = {
    expanded: false,
  };

  toggleExpansion = (e) => {
    e.stopPropagation();
    this.setState((state) => {
      return { expanded: !state.expanded };
    });
  };

  render() {
    const { children, lines } = this.props;
    const { expanded } = this.state;
    if (!expanded) {
      return (
        <ReactTrancatedFormat
          numberOfLines={lines}
          lineHeight={17}
          ellipsis={
            <span>
              ... &nbsp;
              <Anchor onClick={this.toggleExpansion}>more</Anchor>
            </span>
          }
        >
          <p style={{ lineHeight: "1.14285714em" }}>{children}</p>
        </ReactTrancatedFormat>
      );
    }
    return (
      <span>
        {children}
        &nbsp;
        <Anchor onClick={this.toggleExpansion}>less</Anchor>
      </span>
    );
  }
}

export default Expandable;
