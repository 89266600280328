import React, { useContext, useState } from "react";
import PropTypes from "prop-types";

import { Header, List, Placeholder } from "semantic-ui-react";
import { v4 } from "uuid";

import {
  HubContext,
  ToasterContext,
} from "scenes/Hubly/components/Workspace/Provider";

import { LinkAdd, LinkItem } from "./components";

export function LinkMarkup({ taskId, links, onAdd, onEdit, onDelete }) {
  const { addLink, editLink, deleteLink } = useContext(HubContext);
  const { fireError } = useContext(ToasterContext);
  const [loading, setLoading] = useState(false);

  const createLink = (link) => {
    if (!taskId) {
      onAdd(link);
    } else {
      setLoading(true);
      addLink({ taskId, ...link })
        .then(({ id }) => {
          onAdd({ ...link, id: id });
        })
        .catch((error) => {
          console.error(error);
          fireError("Failed to create link", error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const updateLink = (link) => {
    if (!taskId) {
      onEdit(link);
    } else {
      setLoading(true);
      editLink(link.id, { taskId, ...link })
        .then(() => {
          onEdit(link);
        })
        .catch((error) => {
          fireError("Failed to edit link", error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const removeLink = (link) => {
    if (!taskId) {
      onDelete(link);
      return;
    }
    setLoading(true);
    deleteLink(link.id)
      .then(() => {
        onDelete(link);
      })
      .catch((error) => {
        fireError("Failed to delete link", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div style={{ marginBottom: "1em" }}>
      <Header as="h3" style={{ marginBottom: "0.5em" }}>
        Links
      </Header>
      <div style={{ marginBottom: "1em" }}>
        {links.length > 0 && !loading ? (
          <List bulleted style={{ marginBottom: "1em" }}>
            {links.map((link) => {
              return (
                <LinkItem
                  key={`${v4()}-${link.id}`}
                  link={link}
                  onEdit={updateLink}
                  onDelete={removeLink}
                />
              );
            })}
          </List>
        ) : (
          <React.Fragment>
            {loading ? (
              <Placeholder>
                <Placeholder.Header>
                  <Placeholder.Line length="very long" />
                  <Placeholder.Line length="very long" />
                  <Placeholder.Line length="very long" />
                </Placeholder.Header>
              </Placeholder>
            ) : (
              <span
                style={{
                  color: "grey",
                  marginBottom: "0.5em",
                  display: "block",
                }}
              >
                This task has no links
              </span>
            )}
          </React.Fragment>
        )}
      </div>
      <LinkAdd loading={loading} onSave={createLink} />
    </div>
  );
}

LinkMarkup.propTypes = {
  links: PropTypes.array,
  taskId: PropTypes.string,
  onAdd: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

LinkMarkup.defaultProps = {
  links: [],
  taskId: null,
};

export default LinkMarkup;
