import {
  AddSequentialReminder,
  AddTask,
  BulkUpdateTasks,
  ClearSequentialReminder,
  CreateTaskTimeEstimate,
  DeleteTask,
  DeleteTaskTimeEstimate,
  EditTask,
  EditTaskTimeEstimate,
} from "data/libs/tasks";
import {
  AddTemplate,
  DeleteTemplate,
  EditTemplate,
} from "data/libs/emailTemplates";
import { AddLink, EditLink, DeleteLink } from "data/libs/links";
import {
  AddTaskCondition,
  allMonths,
  DeleteTaskCondition,
  EditTaskCondition,
  monthsToString,
} from "data/libs/taskConditions";
import moment from "moment";
import { AddReminder, EditReminder, DeleteReminder } from "data/libs/reminders";
import { isSequentialReminder } from "./components/TaskFutureReminder/helper";

export const getTaskInitialState = (workflowId) => {
  return {
    assignedAdvisor: null,
    assignedAdvisorId: "",
    description: "",
    emailTemplate: null,
    householdMembers: [],
    isClientTask: false,
    links: [],
    order: 1,
    reminders: [],
    taskCondition: null,
    timeEstimates: [],
    title: "",
    workflowId: workflowId,
  };
};

// Added in as this causes spacing issues when using HTML rather than the Quill Delta system
export const modules = {
  clipboard: {
    matchVisual: false,
  },
};

export const getDuration = (value) => {
  return { hours: Math.floor(value / 60), minutes: value % 60 };
};

export const getEmptyTaskParams = (value) => {
  const { hours, minutes } = getDuration(value);
  return {
    hours: hours,
    minutes: minutes,
    autoAddedInd: false,
    timeEstimate: moment.duration(
      parseInt(hours) * 60 + parseInt(minutes),
      "minute"
    ),
  };
};

export const getCombinedStreamsAndTags = (taskCondition) => {
  return (
    taskCondition?.streams
      ? taskCondition.streams.map((s) => {
          return { ...s, type: "stream" };
        })
      : []
  ).concat(
    taskCondition?.tags
      ? taskCondition.tags.map((t) => {
          return { ...t, type: "tag" };
        })
      : []
  );
};

export const mapTaskConditionData = (data) => {
  return {
    conditionType: data.conditionType,
    months: data.months ? monthsToString(data.months) : null,
    streams: data.selectedStreamsTags
      .filter(({ type }) => {
        return type === "stream";
      })
      .map(({ id }) => {
        return id;
      }),
    tags: data.selectedStreamsTags
      .filter(({ type }) => {
        return type === "tag";
      })
      .map(({ id }) => {
        return id;
      }),
  };
};

export const conditionTypeOptions = [
  {
    value: "ANY",
    key: "ANY",
    text: "Any",
  },
  {
    value: "ALL",
    key: "ALL",
    text: "All",
  },
];

export const monthOptions = Object.keys(allMonths).map((month) => {
  return {
    value: month,
    key: month,
    text: month,
  };
});

export function getLeftSideMinWidth({ isClientWorkflow, showAssignedAdvisor }) {
  let leftSideMinWidth = 75;
  if (!isClientWorkflow) {
    leftSideMinWidth -= 25;
  }
  if (!showAssignedAdvisor) {
    leftSideMinWidth -= 30;
  }
  return leftSideMinWidth;
}

export const deleteEmailTemplate = (templateId) => {
  return DeleteTemplate(templateId);
};

export const addEmailTemplate = (taskId, request) => {
  return AddTemplate(taskId, request);
};

export const editEmailTemplate = (templateId, request) => {
  return EditTemplate(templateId, request);
};

export const addLink = (request) => {
  return AddLink(request);
};

export const editLink = (id, request) => {
  return EditLink(id, request);
};

export const deleteLink = (id) => {
  return DeleteLink(id);
};

export const addTaskCondition = (taskId, request) => {
  return AddTaskCondition(taskId, request);
};
export const editTaskCondition = (taskId, conditionId, request) => {
  return EditTaskCondition(taskId, conditionId, request);
};
export const deleteTaskCondition = (taskId, conditionId) => {
  return DeleteTaskCondition(taskId, conditionId);
};

export const getTotalTimeEstimate = (taskEstimate, asMinutes = false) => {
  if (!taskEstimate) {
    return asMinutes ? 0 : moment.duration();
  }
  const totalTime = taskEstimate.reduce((accumulator, currentValue) => {
    return accumulator.add(moment.duration(currentValue.timeEstimate));
  }, moment.duration());
  return !asMinutes ? totalTime : totalTime.asMilliseconds() / 1000 / 60;
};

export const getTaskTimeCaption = (task, isClientWorkflow) => {
  if (task && task.timeEstimates && task.timeEstimates.length > 0) {
    if (isClientWorkflow) {
      const totalTimeSpent = getTotalTimeEstimate(
        task.timeEstimates.filter((estimate) => {
          return !estimate.autoAddedInd;
        })
      );
      const totalTimeEst = getTotalTimeEstimate(
        task.timeEstimates.filter((estimate) => {
          return estimate.autoAddedInd;
        })
      );
      return totalTimeSpent.asMilliseconds() > 0
        ? `Time Spent: ${
            totalTimeSpent.days() * 24 + totalTimeSpent.hours()
          }h ${totalTimeSpent.minutes()}m`
        : `Time Estimate: ${
            totalTimeEst.days() * 24 + totalTimeEst.hours()
          }h ${totalTimeEst.minutes()}m`;
    } else {
      const totalTimeEst = getTotalTimeEstimate(task.estimate);
      return `Time Estimate: ${
        totalTimeEst.days() * 24 + totalTimeEst.hours()
      }h ${totalTimeEst.minutes()}m`;
    }
  } else {
    return "Time Estimate";
  }
};

export const addTimeEstimate = (taskId, request) => {
  return CreateTaskTimeEstimate(taskId, request);
};

export const editTimeEstimate = (taskId, estimateId, request) => {
  return EditTaskTimeEstimate(taskId, estimateId, request);
};

export const deleteAllTimeEstimate = (taskId, timeEstimateIds) => {
  const deletePromises = timeEstimateIds.map((estimateId) => {
    return DeleteTaskTimeEstimate(taskId, estimateId);
  });
  return Promise.all(deletePromises);
};

export const addSequentialReminderToWorkflow = (
  taskId,
  { futureReminderTime, futureReminderType, futureReminderNumber }
) => {
  const data = {
    time: futureReminderTime,
    type: futureReminderType,
    number: futureReminderNumber,
  };
  return AddSequentialReminder(taskId, data);
};

export const addReminderToWorklfow = (taskId, data) => {
  const {
    reminderTypeSelection,
    futureReminderTime,
    futureReminderType,
    futureReminderNumber,
  } = data;
  const request = {
    futureReminderTime,
    futureReminderType,
    futureReminderNumber,
  };

  return reminderTypeSelection === "sequential"
    ? addSequentialReminderToWorkflow(taskId, request)
    : AddReminder({ taskId, ...request });
};

export const deleteSequentialReminderFromWorkflow = (taskId) => {
  return ClearSequentialReminder(taskId);
};

export const deleteByReminderType = (reminderTypeSelection) => {
  return reminderTypeSelection === "sequential"
    ? (task) => {
        return deleteSequentialReminderFromWorkflow(task.id);
      }
    : ({ reminders }) => {
        return DeleteReminder(reminders[0].id);
      };
};

export const convertTaskToFutureReminder = (task, params) => {
  return isSequentialReminder(task)
    ? deleteSequentialReminderFromWorkflow(task.id).then(() => {
        const request = params || {
          futureReminderTime: task.options.sequentialReminder.time,
          futureReminderType: task.options.sequentialReminder.type,
          futureReminderNumber: task.options.sequentialReminder.number,
        };
        return AddReminder({
          taskId: task.id,
          ...request,
        });
      })
    : new Promise(async (resolve, _) => {
        if (params) {
          const reminder = await EditReminder(task.reminders[0].id, params);
          resolve(reminder);
        } else {
          resolve(task.reminders[0]);
        }
      });
};

export const convertTaskToSequentialReminder = (task, params = null) => {
  return !isSequentialReminder(task)
    ? DeleteReminder(task.reminders[0].id).then(() => {
        const request = params || {
          futureReminderTime: task.reminders[0].futureReminderTime,
          futureReminderType: task.reminders[0].futureReminderType,
          futureReminderNumber: task.reminders[0].futureReminderNumber,
        };
        return addSequentialReminderToWorkflow(task.id, request);
      })
    : new Promise((resolve, _) => {
        if (params) {
          const sequentialReminder = addSequentialReminderToWorkflow(
            task.id,
            params
          );
          resolve(sequentialReminder);
        } else {
          resolve({ sequentialReminder: task.options.sequentialReminder });
        }
      });
};

export const editReminder = async (
  task,
  {
    reminderTypeSelection,
    futureReminderTime,
    futureReminderType,
    futureReminderNumber,
  }
) => {
  const request = {
    futureReminderType: futureReminderType,
    futureReminderTime:
      futureReminderType === "immediate" &&
      reminderTypeSelection !== "sequential"
        ? null
        : futureReminderTime,
    futureReminderNumber:
      futureReminderType === "immediate" &&
      reminderTypeSelection !== "sequential"
        ? null
        : futureReminderNumber,
  };

  return reminderTypeSelection === "sequential"
    ? convertTaskToSequentialReminder(task, request)
    : convertTaskToFutureReminder(task, request);
};

export const addTask = (request, clientId) => {
  return AddTask(request, clientId);
};

export const editTask = (
  id,
  request,
  clientId = null,
  isNextWorkflow = false
) => {
  return EditTask(id, request, clientId, isNextWorkflow);
};

export const deleteTask = ({
  id,
  workflowId = null,
  clientWorkflowId = null,
}) => {
  return DeleteTask(id, workflowId, clientWorkflowId);
};

export function reOrderTask({ items }) {
  const maxOrder = items.reduce((max, currentTask) => {
    return currentTask.order > max ? currentTask.order : max;
  }, 0);
  return BulkUpdateTasks({
    tasks: items.map(({ id }, i) => {
      return {
        id: id,
        order: maxOrder + i + 1,
      };
    }),
  });
}
export default getLeftSideMinWidth;
