import { API } from "aws-amplify";
import SyncedAPI from "data/libs/syncedAPI";
import { getAPIQuery, getAPIReq } from "./authLib";

export const GetTags = async (hub) => {
  const req = await getAPIReq();
  return API.get("HublyAPI", `/hubs/${hub.id}/tags/`, req);
};

export const GetTag = async (id) => {
  const req = await getAPIReq();
  return API.get("HublyAPI", `/tags/${id}/`, req);
};

export const CreateTag = async (request) => {
  const req = await getAPIReq(request);
  return SyncedAPI.post("HublyAPI", `/tags/`, req);
};

export const EditTag = async (id, request) => {
  const req = await getAPIReq(request);
  return SyncedAPI.put("HublyAPI", `/tags/${id}/`, req);
};

export const DeleteTag = async (id) => {
  const req = await getAPIReq();
  return SyncedAPI.del("HublyAPI", `/tags/${id}/`, req);
};

export const ClientsInTag = async (id) => {
  const req = await getAPIReq();
  return API.get("HublyAPI", `/tags/${id}/clients/`, req);
};

export const ClientsWithTagsInTag = async (id) => {
  const req = await getAPIReq();
  return API.get("HublyAPI", `/tags/${id}/clientsWithTags/`, req);
};

export const GetTagReport = async (id, query) => {
  const req = await getAPIQuery(query);
  req.responseType = "blob";
  req.isBase64Encoded = true;
  return API.get("HublyAPI", `/tags/${id}/report/`, req);
};
