import React, { Component } from "react";
import PropTypes from "prop-types";
import { Sidebar, Icon, Header, Label } from "semantic-ui-react";
import "./Sidebar.css";
import { connect } from "react-redux";
import moment from "moment";

import makeGetFilteredReminders from "data/reminders/selector";

import FeedContent from "./components/FeedContent";
import FeedSettings from "./components/FeedSettings";

class HubFeed extends Component {
  static defaultProps = {
    reminders: [],
  };

  static propTypes = {
    reminders: PropTypes.array,
    clientWorkflows: PropTypes.object.isRequired,
  };

  state = {
    open: false,
    hidden: true,
  };

  initialOpening = true;

  onOpen = () => {
    if (this.initialOpening && this.neutralRef) {
      // if the first time opening the sidebar then scroll to the neutral spot
      this.content.scrollTop = this.neutralRef.offsetTop - 57;
    }
    this.initialOpening = false;
    this.setState({ open: true, hidden: false });
  };

  onClose = () => {
    this.setState({ open: false });
  };

  toggleFeed = () => {
    const { open } = this.state;
    if (open) this.onClose();
    else this.onOpen();
  };

  renderTab = () => {
    const { open, hidden } = this.state;
    const { numOverdue, numDueToday } = this.getReminderData();
    return (
      <div
        data-test="hubfeed-menu"
        className="clickable grey_on_hover"
        style={{
          marginRight: "0px",
          borderLeft: "1px solid rgba(34,36,38,.15)",
          borderRight: "2px solid rgba(34,36,38,.15)",
          borderTopRightRadius: "0px",
          borderBottomRightRadius: "0px",
          width: "40px",
          height: !hidden ? "calc(100% + 13px)" : "calc(100% - 47px)",
          position: "fixed",
          top: !hidden ? "0px" : "47px",
          transform: !hidden ? "translate(-40px, 0px)" : "",
          float: hidden ? "right" : "",
          backgroundColor: "white",
          right: hidden ? "0px" : "",
          zIndex: 999,
          boxShadow: "-2px 0px 2px rgba(34,36,38,.15)",
        }}
        onClick={this.toggleFeed}
      >
        <div style={{ margin: "auto", width: "min-content", marginTop: "1em" }}>
          <Icon
            name="chevron right"
            style={{
              transformOrigin: "50% 50%",
              margin: 0,
              transform: !open ? "rotate(180deg)" : "",
              transition: "transform 0.65s",
            }}
          />
        </div>
        <div style={{ transform: "rotate(-90deg)", marginTop: "4em" }}>
          <Header as="h3" style={{ width: "max-content" }}>
            Hub Feed
          </Header>
        </div>
        <div
          style={{
            width: "100%",
            marginTop: "1.25em",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {numOverdue > 0 && (
            <Label
              data-test="hubfeed-badge-red"
              color="red"
              circular
              style={{
                margin: "auto",
                marginBottom: "0.5em",
                display: "inline-block",
              }}
            >
              {numOverdue < 100 ? numOverdue : "99+"}
            </Label>
          )}
          {numDueToday > 0 && (
            <Label
              data-test="hubfeed-badge-yellow"
              color="yellow"
              circular
              style={{ margin: "auto", display: "inline-block" }}
            >
              {numDueToday < 100 ? numDueToday : "99+"}
            </Label>
          )}
        </div>
      </div>
    );
  };

  getReminderData = () => {
    const { reminders, clientWorkflows } = this.props;
    let numOverdue = 0;
    let numDueToday = 0;
    const now = new Date().toISOString();
    reminders.forEach((reminder) => {
      const duration = moment.duration(moment(reminder.time).diff());
      if (reminder.clientWorkflowId) {
        const cw = clientWorkflows[reminder.clientWorkflowId];
        if (cw?.completed || cw?.archived) return;
      }
      if (reminder.time < now && !reminder.dismissed && !reminder.completedAt) {
        numOverdue += 1;
      } else if (
        duration.days() === 0 &&
        duration.hours() <= 12 &&
        duration > 0 &&
        !reminder.dismissed &&
        !reminder.completedAt
      ) {
        numDueToday += 1;
      }
    });
    return { numDueToday, numOverdue };
  };

  render() {
    const { open, hidden } = this.state;
    const { reminders } = this.props;
    return (
      <div data-test="sidebar" style={{ position: "absolute", width: "100%" }}>
        {hidden && this.renderTab()}
        <Sidebar
          animation="overlay"
          className="hub-feed-sidebar"
          direction="right"
          visible={open}
          width="wide"
          style={{ backgroundColor: "white", top: "47px" }}
          onHidden={() => {
            this.setState({ hidden: true });
          }}
        >
          <div style={{ display: "flex" }}>
            <div style={{ margin: "7px 5px auto auto" }}>
              <FeedSettings />
            </div>
          </div>
          <FeedContent
            setNeutral={(ref) => {
              this.neutralRef = ref;
            }}
            setRef={(ref) => {
              this.content = ref;
            }}
            reminders={reminders}
            sidebarOpen={open}
          />
          {!hidden && this.renderTab()}
        </Sidebar>
      </div>
    );
  }
}

const makeMapStateToProps = () => {
  const filter = makeGetFilteredReminders();
  return (state, props) => {
    return {
      reminders: filter(state, props),
      clientWorkflows: state.hubly.data.hub.clientWorkflows,
    };
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(makeMapStateToProps, mapDispatchToProps)(HubFeed);
