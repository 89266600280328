import React from "react";
import PropTypes from "prop-types";

import { Header, Segment, Dropdown, Checkbox, Icon } from "semantic-ui-react";

function WorkflowCheck({
  workflow,
  onChange,
  workflows,
  onNextWorkflowSelected,
}) {
  const [isSelected, select] = React.useState(false);

  return (
    <Segment
      style={{
        display: "flex",
        margin: "0 0 0.5em 0",
        padding: "0.5em",
        paddingRight: "0em",
        alignItems: "center",
      }}
    >
      <Checkbox
        checked={isSelected}
        style={{ width: "5%" }}
        onChange={(_, { checked }) => {
          select(checked);
          if (onChange) onChange(workflow.id, checked);
        }}
      />
      <div
        style={{
          paddingRight: "1em",
          width: "50%",
          fontWeight: "bold",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {workflow.name}
      </div>
      <div
        style={{
          paddingRight: "1em",
          width: "%",
          fontWeight: "bold",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        }}
      >
        <Dropdown
          selection
          search
          disabled={!isSelected}
          placeholder="Next Workflow"
          style={{ minWidth: "200px" }}
          options={workflows
            .filter((wf) => {
              return wf.id !== workflow.id;
            })
            .map((wf) => {
              return { key: wf.id, value: wf.id, text: wf.name };
            })}
          onChange={(_, { value }) => {
            if (onNextWorkflowSelected)
              onNextWorkflowSelected(workflow.id, value);
          }}
        />
      </div>
      <div
        style={{
          width: "20%",
          borderLeft: "1px solid #dedede",
          color: "grey",
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        <div>
          <Icon name="user" color="grey" />
          {workflow.clients?.length}
          &nbsp;Client
          {workflow.clients?.length !== 1 && "s"}
        </div>
      </div>
      <div
        style={{
          width: "20%",
          borderLeft: "1px solid #dedede",
          color: "grey",
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        <div>
          <Icon name="check square" color="grey" />
          {workflow.tasks?.length}
          &nbsp;Task
          {workflow.tasks?.length !== 1 && "s"}
        </div>
      </div>
    </Segment>
  );
}

WorkflowCheck.propTypes = {
  onChange: PropTypes.func,
  workflows: PropTypes.array,
  onNextWorkflowSelected: PropTypes.func,
  workflow: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    tasks: PropTypes.array,
    clients: PropTypes.array,
  }).isRequired,
};

WorkflowCheck.defaultProps = {
  workflows: [],
  onChange: () => {},
  onNextWorkflowSelected: () => {},
};

export default function WorkflowList(props) {
  const { workflows } = props;

  return (
    <React.Fragment>
      <Header as="h5">Workflows</Header>
      <div style={{ width: "100%", maxHeight: "500px", overflowY: "scroll" }}>
        {workflows.map((wf) => {
          return <WorkflowCheck key={wf.id} workflow={wf} {...props} />;
        })}
      </div>
    </React.Fragment>
  );
}

WorkflowList.propTypes = {
  workflows: PropTypes.array,
};

WorkflowList.defaultProps = {
  workflows: [],
};
