import React from "react";
import PropTypes from "prop-types";
import { Button, Popup } from "semantic-ui-react";
import { connect } from "react-redux";
import { SetAlert } from "components/Alerts/actions";
import { SendEmailToService } from "./actions";

class SendEmail extends React.Component {
  static propTypes = {
    to: PropTypes.string.isRequired,
    subject: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
  };

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  };

  render() {
    const { to, subject, body } = this.props;
    return (
      <Popup
        flowing
        on="click"
        position="bottom center"
        style={{ padding: "0" }}
        trigger={
          <Button primary floated="right">
            Send Email
          </Button>
        }
      >
        <Button.Group basic vertical>
          <Button
            content="Gmail"
            href={SendEmailToService(to, subject, body, "gmail")}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textAlign: "left", paddingLeft: "0.5em", width: "10em" }}
          />
          <Button
            content="Outlook.com"
            href={SendEmailToService(to, subject, body, "outlook")}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textAlign: "left", paddingLeft: "0.5em", width: "10em" }}
          />
          <Button
            content="Yahoo"
            href={SendEmailToService(to, subject, body, "yahoo")}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textAlign: "left", paddingLeft: "0.5em", width: "10em" }}
          />
          <Button
            content="Local Client"
            href={SendEmailToService(to, subject, body, "local")}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textAlign: "left", paddingLeft: "0.5em", width: "10em" }}
          />
        </Button.Group>
      </Popup>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAlert: (alert) => {
      dispatch(SetAlert(alert));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SendEmail);
