import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Form, Header, Input } from "semantic-ui-react";
import { CreatePractice, EditPractice } from "data/libs/practices";
import { SetAlert } from "components/Alerts/actions";
import { SetAdvisor } from "data/advisor/actions";

class PracticeInformationForm extends Component {
  static propTypes = {
    advisor: PropTypes.shape({
      id: PropTypes.string.isRequired,
      practices: PropTypes.array.isRequired,
      billing: PropTypes.object,
    }).isRequired,
    setAdvisor: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const { advisor } = this.props;
    const { practices } = advisor;
    let practice = {};

    if (practices.length >= 1) {
      [practice] = practices;
    }

    this.state = {
      practiceName: practice.name || "",
      primaryContact: practice.primaryContact || "",
    };
  }

  handleChange = (e, { name, value }) => {
    // Form values stored in camelCase
    return this.setState({ [name]: value });
  };

  save = () => {
    const { advisor, setAdvisor, setAlert } = this.props;
    const advisorCopy = { ...advisor };
    const practice = advisor.practices[0];

    const practiceRequest = {
      name: this.state.practiceName,
      primaryContact: this.state.primaryContact,
    };
    if (practice) {
      EditPractice(practice.id, practiceRequest)
        .then((response) => {
          advisorCopy.practices[0] = response;
          setAdvisor(advisorCopy);
          setAlert({
            type: "success",
            text: "Successfully saved practice information",
          });
        })
        .catch((error) => {
          console.error(error);
          setAlert({
            type: "error",
            text: "Failed to save practice information",
          });
        });
    } else {
      CreatePractice(practiceRequest)
        .then((response) => {
          advisorCopy.practices.push(response);
          setAdvisor(advisorCopy);
          setAlert({
            type: "error",
            text: "Successfully saved practice information",
          });
        })
        .catch((error) => {
          console.error(error);
          setAlert({
            type: "error",
            text: "Failed to save practice information",
          });
        });
    }
  };

  render() {
    const { advisor } = this.props;
    const { practiceName, primaryContact } = this.state;

    const advisorOptions = [];

    advisor.practices.forEach((practice) => {
      practice.advisors.forEach((practiceAdvisor) => {
        if (
          !advisorOptions.find((a) => {
            return a.key === practiceAdvisor.id;
          })
        ) {
          advisorOptions.push({
            key: practiceAdvisor.id,
            text: `${practiceAdvisor.firstName} ${practiceAdvisor.lastName}`,
            value: `${practiceAdvisor.firstName} ${practiceAdvisor.lastName}`,
          });
        }
      });
    });

    // If the primary contact is set to a name, and that name doesn't exist in the dropdown options, add it!
    if (
      !advisorOptions.find((optionAdvisor) => {
        return optionAdvisor.text === advisor.practices[0].primaryContact;
      })
    ) {
      if (advisor.practices[0].primaryContact) {
        advisorOptions.push({
          key: 1,
          text: advisor.practices[0].primaryContact,
          value: advisor.practices[0].primaryContact,
        });
      }
    }

    return (
      <div style={{ marginTop: "1em" }}>
        <div style={{ display: "flex" }}>
          <Header as="h1" style={{ marginBottom: "0" }}>
            Practice Information
          </Header>
          <Button
            color="green"
            content="Save Changes"
            onClick={this.save}
            style={{ float: "right", marginLeft: "1em" }}
          />
        </div>
        <Form style={{ marginTop: "1em" }}>
          <Form.Group>
            <Form.Field
              key="practice-name"
              style={{ flexGrow: 1, maxWidth: "300px" }}
            >
              <Header as="h3">Practice Name</Header>
              <Input
                required
                fluid
                name="practiceName"
                onChange={this.handleChange}
                placeholder="Practice Name"
                value={practiceName}
              />
            </Form.Field>
            <Form.Field
              key="primary-contact"
              style={{ flexGrow: 1, maxWidth: "300px" }}
            >
              <Header as="h3">Primary Contact</Header>
              <Form.Select
                clearable
                name="primaryContact"
                onChange={this.handleChange}
                options={advisorOptions}
                placeholder="Primary Contact"
                value={primaryContact}
              />
            </Form.Field>
          </Form.Group>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    advisor: state.hubly.data.advisor,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAdvisor: (advisor) => {
      dispatch(SetAdvisor(advisor));
    },
    setAlert: (alert) => {
      dispatch(SetAlert(alert));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PracticeInformationForm);
