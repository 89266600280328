import { API } from "aws-amplify";
import store from "store";
import { SendWebsocketSync } from "data/websockets/actions";

// sync with no data (causes the new update toaster)
const simpleSync = async () => {
  store.dispatch(SendWebsocketSync());
};

// The functions bellow are wrappers on AWS API calls that send the responses to advisors also in the hub
const sync = async (syncData) => {
  try {
    const syncs = Array.isArray(syncData) ? syncData : [syncData]; // put singular sync commands in an array
    store.dispatch(SendWebsocketSync(syncs)); // send off
  } catch (error) {
    console.error(error);
  }
};

const post = (apiName, path, request, syncFunction) => {
  return new Promise((resolve, reject) => {
    API.post(apiName, path, request)
      .then((response) => {
        if (syncFunction) sync(syncFunction(response));
        else simpleSync();
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const put = (apiName, path, request, syncFunction) => {
  return new Promise((resolve, reject) => {
    API.put(apiName, path, request)
      .then((response) => {
        if (syncFunction) sync(syncFunction(response));
        else simpleSync();
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const del = (apiName, path, request, syncFunction) => {
  return new Promise((resolve, reject) => {
    API.del(apiName, path, request)
      .then((response) => {
        if (syncFunction) sync(syncFunction(response));
        else simpleSync();
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export default { del, put, post, sync };
