import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Header, Grid } from "semantic-ui-react";

import EmptyWorkflowToggle from "./EmptyWorkflowToggle";
import ShowNextTaskFilter from "./ShowNextTaskFilter";

function MoreFilters({ filters }) {
  const { assignee } = filters;

  return (
    <div style={{ padding: "0em 1em 1em 1em" }}>
      <Header style={{ margin: "0 0 1em 0" }} as="h4">
        Display Options
      </Header>
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <EmptyWorkflowToggle />
          </Grid.Column>
        </Grid.Row>
        {assignee !== "ALL" && (
          <Grid.Row>
            <Grid.Column>
              <ShowNextTaskFilter />
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    </div>
  );
}

MoreFilters.propTypes = {
  filters: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    filters: state.hubly.data.filters,
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(MoreFilters);
