import React from "react";
import PropTypes from "prop-types";
import { Header, Progress } from "semantic-ui-react";
import { getFirstIncompleteTaskIndex, daysSinceTaskDue } from "./helpers";

const TileProgress = ({ percentage, reminderInfo, tasks }) => {
  const { statusBar, statusText } = reminderInfo;

  const firstIncompleteIndex = getFirstIncompleteTaskIndex(tasks);
  const isFirstTaskWaitingOnClient = tasks[firstIncompleteIndex]?.isClientTask;
  const waitingOnClientText = () => {
    const daysSinceClientTaskDue = daysSinceTaskDue(
      firstIncompleteIndex,
      tasks
    );
    const formattedDaysText = (num) => {
      return num > 1 ? ` (${num} days)` : ` (${num} day)`;
    };

    return `Waiting On Client${
      daysSinceClientTaskDue && daysSinceClientTaskDue > 0
        ? formattedDaysText(daysSinceClientTaskDue)
        : ""
    }`;
  };

  const progressHeaderText = isFirstTaskWaitingOnClient
    ? waitingOnClientText()
    : statusText;

  return (
    <React.Fragment>
      <Header
        data-test="client-tile-progress-header"
        as="h5"
        style={{
          color: "grey",
          marginBottom: 0,
          fontSize: "10pt",
          fontWeight: "500",
        }}
      >
        {progressHeaderText}
      </Header>
      <Progress
        percent={percentage}
        size="tiny"
        color={statusBar}
        style={{ marginRight: "1em", marginBottom: 0, marginTop: 0 }}
      />
    </React.Fragment>
  );
};

TileProgress.propTypes = {
  percentage: PropTypes.number.isRequired,
  reminderInfo: PropTypes.shape({
    statusBar: PropTypes.string.isRequired,
    statusText: PropTypes.string.isRequired,
  }).isRequired,
  tasks: PropTypes.array,
};

TileProgress.defaultProps = { tasks: [] };

export default TileProgress;
