import React from "react";
import { Icon, Input, Popup, Header, Button } from "semantic-ui-react";
import TaskAdd from "components/Task/components/TaskAdd";
import AssignedAdvisorPopup from "components/AssignedAdvisorPopup";
import { AddTask, EditTask, DeleteTask } from "data/libs/tasks";
import { connect } from "react-redux";
import { SetAlert } from "components/Alerts/actions";
import { SetClientWorkflow } from "data/hub/clientWorkflows/actions";
import TaskFutureReminder from "components/Task/components/TaskConfigurations/components/TaskFutureReminders";
import { AddReminder, EditReminder, DeleteReminder } from "data/libs/reminders";
import PropTypes from "prop-types";
import { UpdateNextWorkflowAttribute } from "../../actions";

class NextWorkflowTask extends React.Component {
  static defaultProps = {
    delayCreation: false,
    setTaskConfiguration: () => {},
  };

  static propTypes = {
    nextWorkflow: PropTypes.object.isRequired,
    hub: PropTypes.object.isRequired,
    updateNextWorkflowAttribute: PropTypes.func.isRequired,
    delayCreation: PropTypes.bool,
    setTaskConfiguration: PropTypes.func,
    setAlert: PropTypes.func.isRequired,
    setClientWorkflow: PropTypes.func.isRequired,
    clientWorkflows: PropTypes.array.isRequired,
  };

  state = {
    addingTask: false,
    editingTaskTitle: false,
    taskTitle: "",
    fakeTask: null,
  };

  setNextWorkflowTask = (request, callback) => {
    const {
      nextWorkflow,
      updateNextWorkflowAttribute,
      delayCreation,
      setTaskConfiguration,
    } = this.props;
    if (delayCreation) {
      this.setState({ fakeTask: request, addingTask: false });
      setTaskConfiguration(request);
      callback();
      return;
    }
    delete request.id;
    AddTask(request)
      .then((response) => {
        updateNextWorkflowAttribute(nextWorkflow, "taskId", response.id, () => {
          this.setState({ addingTask: false });
          callback();
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  neutralMarkup = () => {
    return (
      <div
        className="grey_on_hover clickable"
        style={{
          display: "flex",
          alignItems: "center",
          padding: "0.2em",
          borderRadius: "5px",
          color: "grey",
        }}
        onClick={() => {
          this.setState({ addingTask: true });
        }}
      >
        <div>
          <Icon
            color="grey"
            name="check square outline"
            style={{ marginRight: "0.5em" }}
          />
        </div>
        <div>Add Task</div>
      </div>
    );
  };

  editMarkup = () => {
    const { nextWorkflow } = this.props;
    return (
      <div
        style={{
          width: "255px",
          transform: "translateX(-40px)",
        }}
      >
        <TaskAdd
          smallButtons
          isNextWorkflow
          closeAddTask={() => {
            this.setState({ addingTask: false });
          }}
          currentWorkflow={nextWorkflow}
          setSavingTask={(b) => {}}
          setNextWorkflowTask={this.setNextWorkflowTask}
          existingTask={nextWorkflow?.task}
        />
      </div>
    );
  };

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  };

  updateTaskTitle = () => {
    const { taskTitle } = this.state;
    const {
      nextWorkflow,
      setAlert,
      setClientWorkflow,
      clientWorkflows,
      delayCreation,
      setTaskConfiguration,
    } = this.props;
    if (!taskTitle && taskTitle.length < 1) {
      setAlert({ type: "warning", text: "Task name cannot be empty" });
      return;
    }
    if (delayCreation) {
      const { fakeTask } = this.state;
      const fakeTaskCopy = { ...fakeTask };
      fakeTaskCopy.title = taskTitle;
      setTaskConfiguration(fakeTaskCopy);
      this.setState({ fakeTask: fakeTaskCopy, editingTaskTitle: false });
      return;
    }
    EditTask(nextWorkflow.task.id, { title: taskTitle }, null, true)
      .then((response) => {
        const clientWorkflowCopy = {
          ...clientWorkflows[nextWorkflow.clientWorkflowId],
        };
        clientWorkflowCopy.nextWorkflows.find((nw) => {
          return nw.id === nextWorkflow.id;
        }).task = response;
        setClientWorkflow(clientWorkflowCopy);
        this.setState({ editingTaskTitle: false });
      })
      .catch((error) => {
        setAlert({ type: "error", text: "Unable to edit task title" });
        console.error(error);
      });
  };

  editNextWorkflowReminder = (id, number, type, time, callback) => {
    const {
      nextWorkflow,
      setAlert,
      setClientWorkflow,
      clientWorkflows,
      delayCreation,
      setTaskConfiguration,
    } = this.props;
    if (delayCreation) {
      const { fakeTask } = this.state;
      const fakeTaskCopy = { ...fakeTask };
      fakeTaskCopy.reminders[0] = {
        futureReminderTime: time,
        futureReminderType: type,
        futureReminderNumber: number,
      };
      this.setState({ fakeTask: fakeTaskCopy });
      setTaskConfiguration(fakeTaskCopy);
      if (callback) callback(true);
      return;
    }
    EditReminder(id, {
      futureReminderTime: time,
      futureReminderType: type,
      futureReminderNumber: number,
    })
      .then((response) => {
        const clientWorkflowCopy = {
          ...clientWorkflows[nextWorkflow.clientWorkflowId],
        };
        clientWorkflowCopy.nextWorkflows.find((nw) => {
          return nw.id === nextWorkflow.id;
        }).task.reminders = [response];
        setClientWorkflow(clientWorkflowCopy);
        if (callback) callback(true);
      })
      .catch((error) => {
        setAlert({ type: "error", text: "Unable to edit task reminder" });
        console.error(error);
        if (callback) callback(false);
      });
  };

  addNextWorkflowReminder = (number, type, time, callback) => {
    const {
      nextWorkflow,
      setAlert,
      setClientWorkflow,
      clientWorkflows,
      delayCreation,
      setTaskConfiguration,
    } = this.props;
    if (delayCreation) {
      const { fakeTask } = this.state;
      const fakeTaskCopy = { ...fakeTask };
      fakeTaskCopy.reminders[0] = {
        futureReminderTime: time,
        futureReminderType: type,
        futureReminderNumber: number,
      };
      this.setState({ fakeTask: fakeTaskCopy });
      setTaskConfiguration(fakeTaskCopy);
      if (callback) callback(true);
      return;
    }
    AddReminder({
      taskId: nextWorkflow.task.id,
      futureReminderTime: time,
      futureReminderType: type,
      futureReminderNumber: number,
    })
      .then((response) => {
        const clientWorkflowCopy = JSON.parse(
          JSON.stringify(clientWorkflows[nextWorkflow.clientWorkflowId])
        );
        clientWorkflowCopy.nextWorkflows.find((nw) => {
          return nw.id === nextWorkflow.id;
        }).task.reminders = [response];
        setClientWorkflow(clientWorkflowCopy);
        if (callback) callback(true);
      })
      .catch((error) => {
        setAlert({ type: "error", text: "Unable to edit task reminder" });
        console.error(error);
        if (callback) callback(false);
      });
  };

  removeNextWorkflowReminder = (id, callback) => {
    const {
      nextWorkflow,
      setAlert,
      setClientWorkflow,
      clientWorkflows,
      delayCreation,
      setTaskConfiguration,
    } = this.props;
    if (delayCreation) {
      const { fakeTask } = this.state;
      const fakeTaskCopy = { ...fakeTask };
      fakeTaskCopy.reminders = [];
      this.setState({ fakeTask: fakeTaskCopy });
      setTaskConfiguration(fakeTaskCopy);
      if (callback) callback(true);
      return;
    }
    DeleteReminder(id)
      .then((response) => {
        const clientWorkflowCopy = JSON.parse(
          JSON.stringify(clientWorkflows[nextWorkflow.clientWorkflowId])
        );
        clientWorkflowCopy.nextWorkflows.find((nw) => {
          return nw.id === nextWorkflow.id;
        }).task.reminders = [];
        setClientWorkflow(clientWorkflowCopy);
        if (callback) callback(true);
      })
      .catch((error) => {
        setAlert({ type: "error", text: "Unable to edit task reminder" });
        console.error(error);
        if (callback) callback(false);
      });
  };

  removeTask = () => {
    const {
      nextWorkflow,
      setAlert,
      setClientWorkflow,
      clientWorkflows,
      delayCreation,
      setTaskConfiguration,
    } = this.props;
    if (delayCreation) {
      this.setState({ fakeTask: null });
      setTaskConfiguration(null);
      return;
    }
    DeleteTask(nextWorkflow.task.id)
      .then((response) => {
        const clientWorkflowCopy = {
          ...clientWorkflows[nextWorkflow.clientWorkflowId],
        };
        clientWorkflowCopy.nextWorkflows.find((nw) => {
          return nw.id === nextWorkflow.id;
        }).task = null;
        setClientWorkflow(clientWorkflowCopy);
        this.setState({ editingTaskTitle: false });
      })
      .catch((error) => {
        setAlert({ type: "error", text: "Unable to edit task title" });
        console.error(error);
      });
  };

  editNextWorkflowTask = (response, foundAdvisor) => {
    const {
      nextWorkflow,
      setAlert,
      setClientWorkflow,
      clientWorkflows,
      delayCreation,
      setTaskConfiguration,
    } = this.props;
    if (delayCreation) {
      const { fakeTask } = this.state;
      const fakeTaskCopy = { ...fakeTask };
      fakeTaskCopy.assignedAdvisor = foundAdvisor;
      fakeTaskCopy.assignedAdvisorId = foundAdvisor.id;
      setTaskConfiguration(fakeTaskCopy);
      this.setState({ fakeTask: fakeTaskCopy });
      return;
    }
    try {
      const clientWorkflowCopy = {
        ...clientWorkflows[nextWorkflow.clientWorkflowId],
      };
      clientWorkflowCopy.nextWorkflows.find((nw) => {
        return nw.id === nextWorkflow.id;
      }).task = response;
      setClientWorkflow(clientWorkflowCopy);
    } catch (error) {
      setAlert({ type: "error", text: "Unable to edit task assignee" });
    }
  };

  displayMarkup = () => {
    const { taskTitle, editingTaskTitle, fakeTask } = this.state;
    const { nextWorkflow, hub, delayCreation } = this.props;
    const task = nextWorkflow ? nextWorkflow.task : fakeTask;
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            transform: "translateX(-2px)",
          }}
        >
          {hub.advisors.length >= 2 && (
            <AssignedAdvisorPopup
              currentObject={task}
              currentObjectType="TASK"
              isNextWorkflow
              editNextWorkflowTask={this.editNextWorkflowTask}
              addingTask={delayCreation}
            />
          )}
          <Popup
            on="click"
            position="bottom center"
            onOpen={() => {
              this.setState({
                editingTaskTitle: true,
                taskTitle: task ? task.title : "",
              });
            }}
            onClose={() => {
              this.setState({ editingTaskTitle: false });
            }}
            // hideOnScroll
            open={editingTaskTitle}
            trigger={
              <div
                className="grey_on_hover clickable"
                style={{
                  padding: "0.2em 0.2em 0.2em 0.4em",
                  borderRadius: "5px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  flexGrow: 1,
                  marginRight: "0.25em",
                }}
                title={task.title}
              >
                {task.title}
              </div>
            }
          >
            <Header as="h5" style={{ marginBottom: "0.25em" }}>
              Task Title
            </Header>
            <Input
              placeholder="Task Title"
              value={taskTitle}
              onChange={this.handleChange}
              name="taskTitle"
              style={{ width: "250px" }}
            />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "0.5em",
              }}
            >
              <Button
                color="green"
                compact
                content="Save"
                onClick={this.updateTaskTitle}
              />
              <Icon
                name="delete"
                link
                size="large"
                color="grey"
                onClick={() => {
                  this.setState({ editingTaskTitle: false });
                }}
              />
            </div>
          </Popup>
          <div>
            <TaskFutureReminder
              popupToLeft
              task={task}
              currentWorkflow={nextWorkflow}
              isNextWorkflow
              editNextWorkflowReminder={this.editNextWorkflowReminder}
              addNextWorkflowReminder={this.addNextWorkflowReminder}
              removeNextWorkflowReminder={this.removeNextWorkflowReminder}
            />
          </div>
          <div>
            <Icon
              name="delete"
              link
              style={{ color: "#bbb" }}
              onClick={this.removeTask}
            />
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { addingTask, fakeTask } = this.state;
    const { nextWorkflow } = this.props;
    if (addingTask) return this.editMarkup();
    if (nextWorkflow?.task || fakeTask) return this.displayMarkup();
    return this.neutralMarkup();
  }
}

const mapStateToProps = (state) => {
  return {
    hub: state.hubly.data.hub.selected.hub,
    clientWorkflows: state.hubly.data.hub.clientWorkflows,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAlert: (alert) => {
      dispatch(SetAlert(alert));
    },
    updateNextWorkflowAttribute: (nextWorkflow, attribute, value, callback) => {
      dispatch(
        UpdateNextWorkflowAttribute(nextWorkflow, attribute, value, callback)
      );
    },
    setClientWorkflow: (clientWorkflow) => {
      dispatch(SetClientWorkflow(clientWorkflow));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NextWorkflowTask);
