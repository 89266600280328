import React from "react";
import PropTypes from "prop-types";
import { Dropdown } from "semantic-ui-react";

export function CreateClientOption({ disabled, searchInput, onClick }) {
  return (
    <Dropdown.Item
      key="add-client"
      value="add-client"
      disabled={disabled}
      onClick={() => {
        onClick(true);
      }}
    >
      <div style={{ fontWeight: "bold" }}>+ Create {searchInput}</div>
    </Dropdown.Item>
  );
}

CreateClientOption.defaultProps = {
  disabled: false,
  searchInput: "",
};

CreateClientOption.propTypes = {
  disabled: PropTypes.bool,
  searchInput: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export default CreateClientOption;
