import React, { useContext, useState } from "react";
import PropTypes from "prop-types";

import { Container, Header } from "semantic-ui-react";

import { useFetch } from "hooks";

import SimpleLinePlaceholder from "components/SimpleLinePlaceholder";
import { ConfirmationModal } from "components/ConfirmationModal";

import {
  HubContext,
  ToasterContext,
} from "scenes/Hubly/components/Workspace/Provider";

import {
  useEditorState,
  useEditorData,
} from "scenes/Hubly/components/Workspace/components/hooks";

import {
  AddOrRemoveStreamRuleForm,
  AgeRuleForm,
  ImportantClientDatesRuleForm,
  RepeatingRuleForm,
  RuleAdd,
  RuleList,
  RuleModal,
} from "./components";

export function RulesEditor({ workflow }) {
  const {
    addRule,
    editRule,
    deleteRule,
    fetchRules,
    fetchDateTypes,
    fetchMeetingDateTypes,
  } = useContext(HubContext);
  const { fireSuccess, fireError } = useContext(ToasterContext);
  const [showRuleModal, setShowRuleModal] = useState("");
  const [currentWorkflowRule, setCurrentWorkflowRule] = useState(null);
  const [deleteRuleId, setDeleteRuleId] = useState(null);
  const [updateLoader, setUpdateLoader] = useState(false);

  const [workflowRules, dispatch] = useEditorState();
  const { loading, reloadData } = useEditorData(fetchRules, {
    id: workflow.id,
    dispatch: dispatch,
  });

  const { data: dateTypes } = useFetch(fetchDateTypes);
  const { data: meetingDateTypes } = useFetch(fetchMeetingDateTypes);

  const saveRule = async (ruleId, updatedRule) => {
    const mapUpdatedRuleToRequest = (rule) => {
      const { tags = [], tagsExclude = [], clientsExclude = [] } = updatedRule;
      const toId = ({ id }) => {
        return id;
      };
      return {
        ...rule,
        tags: rule.tags ? tags.map(toId) : undefined,
        tagsExclude: rule.tagsExclude ? tagsExclude.map(toId) : undefined,
        clientsExclude: rule.clientsExclude
          ? clientsExclude.map(toId)
          : undefined,
      };
    };

    const request = mapUpdatedRuleToRequest(updatedRule);
    try {
      setUpdateLoader(true);
      if (!ruleId) {
        const { id } = await addRule({ ...request, workflowId: workflow.id });
        dispatch({ type: "add", value: { ...updatedRule, id: id } });
        fireSuccess("Successfully added new workflow rule");
      } else {
        await editRule(ruleId, {
          ...request,
          workflowId: workflow.id,
        });
        dispatch({ type: "edit", value: { ...updatedRule, id: ruleId } });
        fireSuccess("Successfully edited workflow rule");
      }
      setShowRuleModal("");
      setCurrentWorkflowRule(null);
      setUpdateLoader(false);
      reloadData();
    } catch (error) {
      fireError("Failed to save rule", error);
      setUpdateLoader(false);
    }
  };

  const removeRule = (ruleId) => {
    setUpdateLoader(true);
    deleteRule(ruleId)
      .then(() => {
        dispatch({ type: "remove", value: ruleId });
        setShowRuleModal("");
        setCurrentWorkflowRule(null);
        setUpdateLoader(false);
        reloadData();
      })
      .catch((error) => {
        fireError("Failed to remove rule", error);
        setUpdateLoader(false);
      });
  };

  if (loading && workflowRules.length === 0) {
    return (
      <Container style={{ marginTop: "1em" }}>
        <Header as="h5" style={{ marginBottom: "0.5em" }}>
          Automation Rules
        </Header>
        <SimpleLinePlaceholder numLines={workflow.numTasks || 4} />
      </Container>
    );
  }

  const commonProps = {
    loading: updateLoader,
    dateTypes: dateTypes,
    meetingDateTypes: meetingDateTypes,
    onSave: saveRule,
    onClose: () => {
      setShowRuleModal(null);
    },
    onEdit: (type, workflowRule) => {
      setCurrentWorkflowRule(workflowRule);
      setShowRuleModal(type);
    },
    onDelete: setDeleteRuleId,
    workflowRule: currentWorkflowRule,
  };
  return (
    <React.Fragment>
      <Container style={{ marginTop: "1em" }}>
        <Header as="h5" style={{ marginBottom: "0.5em" }}>
          Automation Rules
        </Header>
        <RuleList {...commonProps} workflowRules={workflowRules} />
        <RuleAdd
          onConfigure={(modal) => {
            setCurrentWorkflowRule(null);
            setShowRuleModal(modal);
          }}
        />
        {showRuleModal && (
          <RuleModal type={showRuleModal} {...commonProps}>
            {(formData) => {
              if (showRuleModal === "REPEATING_RULE") {
                return <RepeatingRuleForm {...formData} />;
              }
              if (showRuleModal === "IMPORTANT_DATE_RULE") {
                return <ImportantClientDatesRuleForm {...formData} />;
              }
              if (
                showRuleModal === "ADD_ATTRIBUTES" ||
                showRuleModal === "REMOVE_ATTRIBUTES"
              ) {
                return <AddOrRemoveStreamRuleForm {...formData} />;
              }
              if (showRuleModal === "AGE_RULE") {
                return <AgeRuleForm {...formData} />;
              }
              return null;
            }}
          </RuleModal>
        )}
      </Container>
      {deleteRuleId && (
        <ConfirmationModal
          title="Delete Rule"
          message="`Are you sure you wish to delete this rule and its configurations? This cannot be undone.`"
          icon="delete"
          buttons={[
            {
              text: "Cancel",
              callBack: () => {
                setDeleteRuleId(null);
              },
            },
            {
              text: "Delete Rule",
              callBack: () => {
                removeRule(deleteRuleId);
                setDeleteRuleId(null);
              },
              color: "red",
            },
          ]}
        />
      )}
    </React.Fragment>
  );
}

RulesEditor.propTypes = {
  workflow: PropTypes.object,
};

RulesEditor.defaultProps = {
  workflow: null,
};

export default RulesEditor;
