import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Input, Popup } from "semantic-ui-react";
import DatePicker from "react-datepicker";
import MeetingDateTypeDropdown from "components/MeetingDateTypeDropdown";
import AddNewEventPopupTrigger from "./AddNewEventPopupTrigger";
import { getFormattedDateForInputField } from "./helpers";

const AddNewEventPopup = ({
  title,
  pickerOpen,
  setPickerOpen,
  currentDate,
  setCurrentDate,
  loading,
  addEvent,
}) => {
  const formattedDateInput = getFormattedDateForInputField(currentDate);
  const [selectedMeetingDateType, setSelectedMeetingDateType] = useState();

  return (
    <Popup
      on="click"
      onClose={() => {
        return setPickerOpen(false);
      }}
      open={pickerOpen}
      popperModifiers={{
        preventOverflow: {
          boundariesElement: "window",
          enabled: false,
        },
      }}
      position="bottom center"
      disabled={loading}
      data-test="add-event-popup"
      trigger={
        <AddNewEventPopupTrigger
          title={title}
          loading={loading}
          triggerOnClickHandler={() => {
            setCurrentDate(currentDate);
            setPickerOpen(true);
          }}
        />
      }
    >
      <div style={{ width: "max-content" }}>
        <MeetingDateTypeDropdown
          selectedMeetingDateType={selectedMeetingDateType}
          setSelectedMeetingDateType={setSelectedMeetingDateType}
        />
        <Input
          disabled
          fluid
          style={{ marginBottom: "1em" }}
          value={formattedDateInput}
        />
        <DatePicker
          dateFormat="MMMM d, yyyy h:mm aa"
          inline
          onChange={setCurrentDate}
          selected={currentDate}
          showTimeSelect
          timeFormat="h:mm aa"
          timeIntervals={15}
        />
        <Button
          content="Save"
          disabled={loading}
          fluid
          loading={loading}
          onClick={() => {
            addEvent(selectedMeetingDateType?.id);
          }}
          positive
          style={{ marginTop: "1em" }}
        />
      </div>
    </Popup>
  );
};

AddNewEventPopup.propTypes = {
  title: PropTypes.string.isRequired,
  currentDate: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  pickerOpen: PropTypes.bool.isRequired,
  addEvent: PropTypes.func.isRequired,
  setCurrentDate: PropTypes.func.isRequired,
  setPickerOpen: PropTypes.func.isRequired,
};

AddNewEventPopup.defaultProps = {
  currentDate: undefined,
};

export default AddNewEventPopup;
