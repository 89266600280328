import React from "react";

import { Popup } from "semantic-ui-react";

export function withHoverEffect(Component) {
  // eslint-disable-next-line react/prop-types
  return ({ children, ...props }) => {
    if (!children) {
      return <Component {...props} />;
    }

    return (
      <Popup
        on="hover"
        popperModifiers={{
          preventOverflow: { boundariesElement: "window", enabled: false },
        }}
        position="top center"
        style={{ padding: "0.6em", fontSize: "11px", fontWeight: "bold" }}
        trigger={
          <div style={{ margin: "0 0.25em" }}>
            <Component {...props} />
          </div>
        }
      >
        {children}
      </Popup>
    );
  };
}

export default withHoverEffect;
