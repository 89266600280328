import React from "react";
import PropTypes from "prop-types";

import { Popup, Icon } from "semantic-ui-react";

export function InfoBullet({ content, icon, ...iconProps }) {
  return (
    <Popup
      content={content}
      position="right center"
      wide
      popperModifiers={{
        preventOverflow: {
          boundariesElement: "window",
          enabled: false,
        },
      }}
      trigger={<Icon name={icon} {...iconProps} />}
    />
  );
}

InfoBullet.propTypes = {
  content: PropTypes.string.isRequired,
  icon: PropTypes.string,
};

InfoBullet.defaultProps = {
  icon: "info circle",
};

export default InfoBullet;
