import React, { useState } from "react";
import PropTypes from "prop-types";

import { Header, Dropdown, Button } from "semantic-ui-react";

export function UserSelector({
  title,
  dropdownPlaceholder,
  buttonLabel,
  loading,
  users,
  initSelectedUserId,
  onSelect,
}) {
  const [selectedUserId, setSelectedUserId] = useState(initSelectedUserId);

  const buildDropdown = () => {
    const dropdownOptions = users.map(({ id, firstName, lastName }) => {
      return {
        key: id,
        value: id,
        text: `${firstName} ${lastName}`,
        icon: "user circle",
      };
    });

    dropdownOptions.push({
      key: "unassigned",
      value: "unassigned",
      text: "Unassigned",
      icon: "user circle outline",
    });

    return dropdownOptions;
  };

  const dropdownOptions = buildDropdown();

  return (
    <div style={{ width: "max-content" }}>
      <div style={{ display: "flex" }}>
        <Header as="h4" style={{ marginBottom: "1em" }}>
          {title}
        </Header>
      </div>
      <div>
        <Dropdown
          defaultValue={
            dropdownOptions.find(({ key }) => {
              return key === selectedUserId;
            })?.value || "unassigned"
          }
          onChange={(_, { value }) => {
            setSelectedUserId(value);
          }}
          options={users?.length > 0 ? dropdownOptions : []}
          disabled={loading}
          loading={loading}
          fluid
          placeholder={dropdownPlaceholder}
          selection
          selectOnBlur={false}
          style={{ minWidth: "200px" }}
          data-test="assign-advisor-popup-dropdown"
        />
        <Button
          disabled={
            loading ||
            (initSelectedUserId === selectedUserId &&
              dropdownOptions.some(({ key }) => {
                return initSelectedUserId === key;
              }))
          }
          loading={loading}
          fluid
          positive
          style={{ marginTop: "1em" }}
          onClick={(e) => {
            e.stopPropagation();
            onSelect(selectedUserId);
          }}
        >
          {buttonLabel}
        </Button>
      </div>
    </div>
  );
}

UserSelector.propTypes = {
  title: PropTypes.string,
  dropdownPlaceholder: PropTypes.string,
  buttonLabel: PropTypes.string,
  loading: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  initSelectedUserId: PropTypes.string,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
    })
  ).isRequired,
};

UserSelector.defaultProps = {
  title: "Edit Team Member",
  dropdownPlaceholder: "Select a Team Member",
  initSelectedUserId: "unassigned",
  buttonLabel: "Save",
  loading: false,
};

export default UserSelector;
