import React from "react";
import PropTypes from "prop-types";

import { Popup } from "semantic-ui-react";

export function Colorize({ content, color }) {
  return (
    <Popup
      on={["hover"]}
      position="top center"
      popperModifiers={{
        preventOverflow: {
          boundariesElement: "window",
          enabled: false,
        },
      }}
      trigger={
        <div
          style={{
            width: "6px",
            height: "18px",
            backgroundColor: color,
            position: "absolute",
            left: "0px",
            transform: "translateY(3px)",
          }}
        />
      }
    >
      {content}
    </Popup>
  );
}

Colorize.propTypes = {
  content: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};

Colorize.defaultProps = {};

export default Colorize;
