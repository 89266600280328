import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { pull } from "lodash";

import { Modal, Icon, Button, Checkbox } from "semantic-ui-react";

import { DuplicateProcessToAnotherHub } from "../action";

import SelectHub from "./SelectHub";
import WorkflowList from "./WorkflowList";
import ProcessInput from "./ProcessInput";
import selectOrderedWorkflows from "./selector";

export function DuplicateWorkflowsContainer({
  open,
  onClose,
  hubs,
  workflows,
  onExecute,
}) {
  const [isValid, setValid] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const [withProcess, setWithProcess] = React.useState(false);

  const [hubId, setSelectedHub] = React.useState(undefined);
  const [process, setProcess] = React.useState({});
  const [selectedWorkflows, setSelectedWorkflows] = React.useState([]);
  const [nextWorkflows, setNextWorkflows] = React.useState({});

  React.useEffect(() => {
    setValid(hubId && selectedWorkflows.length > 0);
  }, [setValid, hubId, process, selectedWorkflows]);

  const handleNextWorkflowSelect = (wfId, nextWfId) => {
    setNextWorkflows((wfs) => {
      return { ...wfs, [wfId]: nextWfId };
    });
  };

  const setProcessByWorkflow = (workflow) => {
    const { process: wfProcess } = workflow || {};

    setProcess((p) => {
      return {
        ...p,
        name: wfProcess.name,
        description: wfProcess.description,
        color: wfProcess.color,
      };
    });
  };

  const handleWorkflowSelect = (wfId, isSelected) => {
    setSelectedWorkflows((selected) => {
      return isSelected ? [...selected, wfId] : [...pull(selected, wfId)];
    });

    if (!process.name && isSelected) {
      setProcessByWorkflow(
        workflows.find((wf) => {
          return wf.id === wfId;
        })
      );
    }
  };

  const handleSave = async () => {
    setSaving(true);
    try {
      onExecute({
        hubId: hubId,
        process: withProcess
          ? {
              name: process.name,
              description: process.description,
              color: process.color,
            }
          : undefined,
        workflowIds: selectedWorkflows,
        nextWorkflows: nextWorkflows,
      });
    } finally {
      setSaving(false);
    }
  };

  return (
    <Modal onClose={onClose} open={open} size="small">
      <Modal.Header>
        Duplicate
        <Icon
          link
          color="grey"
          name="delete"
          style={{ float: "right" }}
          onClick={onClose}
        />
      </Modal.Header>
      <Modal.Content>
        <SelectHub
          hubs={hubs}
          hubSelection={hubId}
          onSelected={setSelectedHub}
        />

        <Checkbox
          label="With Process"
          checked={withProcess}
          style={{ width: "5%" }}
          onChange={(_, { checked }) => {
            setWithProcess(checked);
          }}
        />

        <div style={{ marginTop: "1em" }}>
          {withProcess && (
            <ProcessInput
              process={process}
              onInputChange={({ name, value }) => {
                return setProcess((p) => {
                  return { ...p, [name]: value };
                });
              }}
            />
          )}
        </div>

        <div style={{ marginTop: "1em" }}>
          <WorkflowList
            workflows={workflows}
            onChange={handleWorkflowSelect}
            onNextWorkflowSelected={handleNextWorkflowSelect}
          />
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button
          positive
          content="Duplicate"
          loading={saving}
          disabled={saving || !isValid}
          onClick={handleSave}
        />
      </Modal.Actions>
    </Modal>
  );
}

DuplicateWorkflowsContainer.propTypes = {
  open: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  onClose: PropTypes.func,
  hubs: PropTypes.array,
  workflows: PropTypes.array,
  onExecute: PropTypes.func.isRequired,
};

DuplicateWorkflowsContainer.defaultProps = {
  open: false,
  hubs: [],
  workflows: [],
};

const mapStateToProps = (state) => {
  const { advisor } = state.hubly.data;
  const workflowsSelector = selectOrderedWorkflows();

  return {
    hubs: advisor.practices.flatMap((p) => {
      return p.hubs;
    }),
    workflows: workflowsSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onExecute: (data) => {
      dispatch(DuplicateProcessToAnotherHub(data));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DuplicateWorkflowsContainer);
