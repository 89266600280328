import { useState, useContext } from "react";

import { HubContext } from "scenes/Hubly/components/Workspace/Provider";

export function useFeedFilters() {
  const { advisorId } = useContext(HubContext);
  const [s, set] = useState({ assignee: advisorId, includeUnassigned: true });

  const setToDefault = () => {
    set({ assignee: advisorId, includeUnassigned: true });
  };

  return [s, set, setToDefault];
}

export default useFeedFilters;
