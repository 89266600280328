import React from "react";
import { isEmail } from "validator";
import { Button, Form, Input } from "semantic-ui-react";
import { SignUp } from "aws-amplify-react";
import { Auth } from "aws-amplify";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import LoginContainer from "./LoginContainer";
import { isPasswordValid, SetLoginErrorMessage } from "../actions";

class HublySignUp extends SignUp {
  static propTypes = {
    setLoginErrorMessage: PropTypes.func.isRequired,
  };

  state = {
    loading: false,
    secondPassword: "",
    emailLabel: false,
    passwordLabel: false,
    password2Label: false,
  };

  register = (event) => {
    this.props.setLoginErrorMessage("");
    this.setState({
      loading: true,
    });

    this.inputs.username = (this.inputs.username || "").toLowerCase();

    const { password, username } = this.inputs;
    const { secondPassword } = this.state;
    let message = "";
    let valid = true;
    if (!username || username.length < 1 || !isEmail(username.toLowerCase())) {
      // check if email is valid
      message = message.concat("Email address is invalid. ");
      valid = false;
    }
    if (!password || password.length < 1 || !isPasswordValid(password)) {
      // check if password is valid
      message = message.concat(
        "Invalid password, must be 8 or more characters long," +
          " contain at least one Uppercase letter, one Lowercase letter, one Special character and one number. "
      );
      valid = false;
    }
    if (secondPassword !== password) {
      // passwords must match
      message = message.concat("Passwords do not match.");
      valid = false;
    }

    if (!valid) {
      this.props.setLoginErrorMessage(message);
      this.setState({
        loading: false,
      });

      return;
    }

    try {
      this.signUp();
    } catch (e) {
      console.warn(e.message);
      this.setState({
        loading: false,
      });
    }
  };

  // needed to be pulled out of AWS' SignUp, because it otherwise wouldn't work without a phone number
  signUp = () => {
    const signupInfo = {
      username: this.inputs.username,
      password: this.inputs.password,
    };
    Auth.signUp(signupInfo)
      .then((data) => {
        this.changeState("confirmSignUp", data.user.username);
      })
      .catch((err) => {
        this.error(err);

        this.setState({
          loading: false,
        });
      });
  };

  backToSignIn = () => {
    this.setState({
      loading: false,
    });

    this.props.setLoginErrorMessage("");
    this.changeState("signIn");
  };

  showComponent() {
    const { loading } = this.state;
    return (
      <LoginContainer title="Register for Hubly">
        <Form style={{ overflow: "visible" }}>
          <Form.Field>
            <label>Email</label>
            <Input
              type="username"
              id="username"
              key="username"
              name="username"
              placeholder="Email"
              autoComplete="off"
              onChange={this.handleInputChange}
            />
          </Form.Field>
          <Form.Field>
            <label style={{ marginBottom: "0px" }}>Password</label>
            <Input
              type="password"
              id="password"
              key="password"
              name="password"
              autoComplete="off"
              placeholder="Password"
              onChange={this.handleInputChange}
            />
            <span style={{ fontSize: "9pt" }}>
              Passwords requirements: 8 characters, Uppercase letter, Lowercase
              letter, Special character, Number.
            </span>
          </Form.Field>
          <Form.Field>
            <label>Repeat Password</label>
            <Input
              type="password"
              value={this.state.secondPassword}
              onChange={(e) => {
                this.setState({ secondPassword: e.target.value });
              }}
              placeholder="Password"
              autoComplete="off"
            />
          </Form.Field>
          <Button
            floated="right"
            disabled={loading}
            loading={loading}
            primary
            onClick={this.register}
          >
            Register
          </Button>
          <Button
            basic
            onClick={() => {
              this.backToSignIn();
            }}
          >
            Back to Sign in
          </Button>
        </Form>
      </LoginContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginErrorMessage: (message) => {
      dispatch(SetLoginErrorMessage(message));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HublySignUp);
