import React from "react";
import PropTypes from "prop-types";

import Options from "components/OptionList";

import { useAssignees } from "./useAssignees";

export function AssigneeFilter({ onClear, onSelect, selected }) {
  const { loading, defaultAssignee, assignees } = useAssignees();

  return (
    <Options.Item
      dropDownProps={{ loading }}
      title="Assignee"
      description="Filter by advisor"
      options={assignees}
      currentValue={selected || defaultAssignee}
      onClear={onClear}
      onSelection={(_, { value }) => {
        onSelect(value);
      }}
    />
  );
}

AssigneeFilter.propTypes = {
  onClear: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.string,
};

AssigneeFilter.defaultProps = {
  selected: undefined,
};

export default AssigneeFilter;
