import { useState, useContext, useEffect, useRef } from "react";

import {
  HubContext,
  ToasterContext,
} from "scenes/Hubly/components/Workspace/Provider";

export function useReminderAction(onDone = () => {}) {
  const mounted = useRef(false);
  const { fireSuccess, fireError } = useContext(ToasterContext);
  const { viewClientCard, editTask, editAReminder } = useContext(HubContext);
  const [isRunningAction, setRunning] = useState(null);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  const makeCallAbout = (id) => {
    return (action) => {
      return async (args) => {
        if (mounted.current) {
          setRunning(id);
          await action(args);
          if (mounted.current) {
            setRunning(null);
          }
        }
      };
    };
  };

  const mapTask = (r, call) => {
    if (!r.task) return undefined;

    return {
      ...r.task,
      complete: call(async () => {
        try {
          await editTask(r.task.id, { completed: true });
          fireSuccess(`Successfully completed task ${r.title}`);
          onDone(r);
        } catch {
          fireError(`Failed to completed task ${r.title}`);
        }
      }),
    };
  };

  const map = (r) => {
    const call = makeCallAbout(r.id);

    return {
      ...r,
      isProcessing: isRunningAction === r.id,
      task: mapTask(r, call),
      reschedule: call(async (newDate) => {
        try {
          await editAReminder(r.id, { time: newDate });
          fireSuccess(`Successfully reschedule reminder ${r.title}`);
          onDone(r);
        } catch {
          fireError(`Failed to reschedule reminder ${r.title}`);
        }
      }),
      dismiss: call(async () => {
        try {
          await editAReminder(r.id, { dismissed: true });
          fireSuccess(`Successfully dismissed reminder ${r.title}`);
          onDone(r);
        } catch {
          fireError(`Failed to dismissed reminder ${r.title}`);
        }
      }),
      viewClientCard: () => {
        viewClientCard(r.client.id, r.task?.clientWorkflow.id);
      },
    };
  };

  return map;
}

export default useReminderAction;
