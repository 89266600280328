import React, { useContext, useState } from "react";
import PropTypes from "prop-types";

import { Container, Header } from "semantic-ui-react";

import { swap, set } from "utils";

import SimpleLinePlaceholder from "components/SimpleLinePlaceholder";

import {
  HubContext,
  ToasterContext,
} from "scenes/Hubly/components/Workspace/Provider";

import {
  useEditorState,
  useEditorData,
} from "scenes/Hubly/components/Workspace/components/hooks";

import { TaskAdd, TaskList } from "./components";
import {
  TaskEmailTemplate,
  TaskFutureReminder,
  TaskTimeEstimate,
  TaskDetails,
  TaskCondition,
} from "./components/Task/components";
import { DetailsModal } from "./components/Task/components/TaskDetails/components";
import { isSequentialReminder } from "./components/Task/components/TaskFutureReminder/helper";

export function TasksEditor({ showAssignedAdvisor, workflow }) {
  const { reOrderTask, fetchTasks, convertTaskToFutureReminder } =
    useContext(HubContext);
  const { fireError, fireWarning } = useContext(ToasterContext);

  const [tasks, dispatch] = useEditorState();
  const { loading, reloadData } = useEditorData(fetchTasks, {
    id: workflow.id,
    dispatch: dispatch,
  });

  const [detailsModalProps, showDetailsProps] = useState(null);

  if (loading && tasks.length === 0) {
    return (
      <Container className="configure_tasks">
        <Header as="h5">Default Tasks</Header>
        <SimpleLinePlaceholder numLines={workflow.numTasks || 4} />
      </Container>
    );
  }

  return (
    <React.Fragment>
      <Container className="configure_tasks">
        <Header as="h5">Default Tasks</Header>
        <Container className="task_list" style={{ marginBottom: "0.5em" }}>
          <TaskList
            workflowId={workflow.id}
            tasks={tasks}
            onTaskUpdated={(updatedTask) => {
              dispatch({ type: "edit", value: updatedTask });
              reloadData();
            }}
            onTaskReOrdered={async ({ dst, src }) => {
              const unorderedList = tasks;
              const orderedList = swap(tasks, src, dst);
              try {
                dispatch({ type: "set", value: orderedList });
                await reOrderTask({ items: orderedList });

                if (isSequentialReminder(orderedList[0])) {
                  const resp = await convertTaskToFutureReminder(
                    orderedList[0]
                  );
                  fireWarning(
                    "The first task cannot be a sequential reminder, it has been converted to a future reminder."
                  );
                  dispatch({
                    type: "edit",
                    value: set(orderedList[0], "reminders", resp),
                  });
                }
                reloadData();
              } catch (error) {
                fireError("Failed to reorder task", error);
                dispatch({ type: "set", value: unorderedList });
              }
            }}
            onTaskDeleted={(deletedTaskId) => {
              dispatch({ type: "remove", value: deletedTaskId });
              reloadData();
            }}
            showAssignedAdvisor={showAssignedAdvisor}
          />
        </Container>
        {workflow?.id && (
          <TaskAdd
            numTasks={tasks.length}
            showAssignedAdvisor={showAssignedAdvisor}
            workflowId={workflow.id}
            onTaskAdded={(addedTask) => {
              dispatch({ type: "add", value: addedTask });
              reloadData();
            }}
          >
            {(props) => {
              return (
                <React.Fragment>
                  <TaskFutureReminder {...props} />
                  <TaskEmailTemplate {...props} />
                  <TaskTimeEstimate {...props} />
                  <TaskDetails
                    {...props}
                    onShowDetailProps={(detailProps) => {
                      showDetailsProps(detailProps);
                    }}
                  >
                    {(taskProps) => {
                      return (
                        detailsModalProps && (
                          <DetailsModal {...detailsModalProps} {...taskProps} />
                        )
                      );
                    }}
                  </TaskDetails>
                  <TaskCondition {...props} />
                </React.Fragment>
              );
            }}
          </TaskAdd>
        )}
      </Container>
    </React.Fragment>
  );
}

TasksEditor.propTypes = {
  workflow: PropTypes.object,
  showAssignedAdvisor: PropTypes.bool,
};

TasksEditor.defaultProps = {
  showAssignedAdvisor: false,
  workflow: null,
};

export default TasksEditor;
