import React from "react";
import PropTypes from "prop-types";

import { Popup, Button, Icon } from "semantic-ui-react";

export function PopupButton({ children, label, highlight, icon, ...props }) {
  return (
    <Popup
      on="click"
      className="dont_padd"
      trigger={
        <Button
          data-test="popup-button"
          basic
          primary={highlight}
          style={{
            marginRight: "0.5em",
            minWidth: "110px",
            whiteSpace: "nowrap",
          }}
          {...props}
        >
          <Icon name={icon || label} />
          {label}
        </Button>
      }
    >
      {children}
    </Popup>
  );
}

PopupButton.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string,
  highlight: PropTypes.bool,
  icon: PropTypes.string,
};

PopupButton.defaultProps = {
  children: null,
  label: undefined,
  highlight: false,
  icon: undefined,
};

export default PopupButton;
