import React from "react";
import { Header, Icon, Input } from "semantic-ui-react";

import PropTypes from "prop-types";

export function ModalInput({
  defaultValue,
  isWealthboxUser,
  isExternalIdNull,
  onChange,
  onEdit,
}) {
  const handleKeyUp = (e) => {
    // handles key press for adding tags
    if (e.keyCode === 13) {
      // Enter pressed
      onEdit();
    }
  };

  return (
    <React.Fragment>
      <Header as="h5" content="Name" />
      {isWealthboxUser ? (
        <React.Fragment>
          <span>{defaultValue}</span>
          {isExternalIdNull && (
            <div style={{ color: "orange", marginTop: "0.5em" }}>
              <Icon name="question circle outline" />
              This Tag will be synced to your CRM once a client is assigned.
            </div>
          )}
        </React.Fragment>
      ) : (
        <Input
          className="search"
          fluid
          defaultValue={defaultValue}
          onChange={(e) => {
            onChange(e.target.value);
          }}
          onKeyUp={handleKeyUp}
        />
      )}
    </React.Fragment>
  );
}

ModalInput.defaultProps = {
  isWealthboxUser: false,
  isExternalIdNull: false,
};

ModalInput.propTypes = {
  isWealthboxUser: PropTypes.bool,
  isExternalIdNull: PropTypes.bool,
  defaultValue: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};

export default ModalInput;
