import React from "react";
import { Icon, Segment, Input, Button } from "semantic-ui-react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { SetHub } from "data/hub/actions";
import { EditDateType } from "data/libs/dateTypes";
import { faGripVertical } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SetAlert } from "../Alerts/actions";

export class ImportantDate extends React.Component {
  static propTypes = {
    hub: PropTypes.object.isRequired,
    setAlert: PropTypes.func.isRequired,
    setHub: PropTypes.func.isRequired,
    dateType: PropTypes.object.isRequired,
    provided: PropTypes.object.isRequired,
    integrationType: PropTypes.string.isRequired,
  };

  state = {
    loading: false,
    input: "",
    editing: false,
  };

  toggleHidden = (dateType) => {
    const { setHub, hub } = this.props;
    const { loading } = this.state;
    if (loading) return; // already loading...
    this.setState({ loading: true });
    EditDateType(dateType.id, { isHidden: !dateType.isHidden })
      .then((response) => {
        const hubCopy = { ...hub };
        const index = hubCopy.dateTypes.findIndex((dt) => {
          return dt.id === dateType.id;
        });
        if (index >= 0) {
          hubCopy.dateTypes[index] = response;
          setHub(hubCopy);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  save = () => {
    const { hub, dateType, setHub, setAlert } = this.props;
    const { input } = this.state;
    if (input.length < 1 || dateType.isSynced) return;
    this.setState({ loading: true });
    EditDateType(dateType.id, { name: input })
      .then((response) => {
        const hubCopy = { ...hub };
        const index = hubCopy.dateTypes.findIndex((dt) => {
          return dt.id === dateType.id;
        });
        if (index >= 0) {
          hubCopy.dateTypes[index] = response;
          setHub(hubCopy);
        }
      })
      .catch((error) => {
        console.error(error);
        setAlert({ type: "error", text: "Failed to edit title" });
      })
      .finally(() => {
        this.setState({ loading: false, editing: false });
      });
  };

  render = () => {
    const { dateType, provided, integrationType } = this.props;
    const { loading, input, editing } = this.state;
    const icon = dateType.isHidden ? "eye" : "eye slash outline";
    return (
      <Segment
        key={dateType.id}
        style={{
          display: "grid",
          gridTemplateColumns: "5% 5% 55% 30% 5%",
          opacity: dateType.isHidden ? 0.4 : 1,
          alignItems: "center",
        }}
      >
        <div
          style={{ transform: "translateY(3px)" }}
          {...provided.dragHandleProps}
        >
          <FontAwesomeIcon
            className="dark_grey_on_hover"
            color="rgb(218, 218, 218)"
            icon={faGripVertical}
            style={{
              marginRight: "1em",
            }}
          />
        </div>
        <div>
          <Icon color="grey" name={dateType.icon} />
        </div>
        {editing ? (
          <div>
            <Input
              placeholder="Date Type"
              autoComplete="off"
              value={input}
              onChange={(e, { value }) => {
                this.setState({ input: value });
              }}
              style={{ width: "230px", marginRight: "0.5em" }}
            />
            <Button
              positive
              content="Save"
              disabled={input.length < 1}
              onClick={this.save}
              loading={loading}
              compact
            />
          </div>
        ) : (
          <div
            title={dateType.name}
            className="overflow_ellipsis"
            style={{ fontWeight: "bold", marginRight: "0.5em" }}
            onClick={() => {
              if (!dateType.isSynced)
                this.setState({ editing: true, input: dateType.name });
            }}
          >
            {dateType.name}
          </div>
        )}
        {integrationType && (
          <div
            style={{
              color: "darkgrey",
              fontStyle: "italic",
            }}
          >
            {dateType.isSynced ? (
              <React.Fragment>
                <Icon
                  name="rss"
                  className={integrationType ? `${integrationType}_color` : ""}
                  size="small"
                />
                Synced to CRM
              </React.Fragment>
            ) : (
              "Not synced to CRM"
            )}
          </div>
        )}
        <div title="Toggle Visibility">
          <Icon
            link
            loading={loading}
            color="grey"
            name={loading ? "circle notch" : icon}
            onClick={() => {
              this.toggleHidden(dateType);
            }}
          />
        </div>
      </Segment>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    hub: state.hubly.data.hub.selected.hub,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAlert: (alert) => {
      dispatch(SetAlert(alert));
    },
    setHub: (hub) => {
      dispatch(SetHub(hub));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ImportantDate);
