import React, { useState } from "react";
import PropTypes from "prop-types";

import { Popup, Button, Icon } from "semantic-ui-react";

import { LinkAdd } from "..";

export function LinkItem({ loading, link, onEdit, onDelete }) {
  const [menuOpen, setMenuOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  if (editMode) {
    return (
      <LinkAdd
        loading={loading}
        link={link}
        onSave={onEdit}
        onClose={() => {
          setEditMode(false);
        }}
      />
    );
  }
  return (
    <div style={{ display: "flex", overflow: "hidden" }}>
      <div
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          fontSize: "13pt",
        }}
      >
        <a
          href={link.url.includes("://") ? link.url : `//${link.url}`}
          target="_blank"
          rel="noopener noreferrer"
          title={link.url}
        >
          {link.title ? (
            <span>
              {link.title}
              <span
                style={{
                  fontSize: "10pt",
                  color: "rgba(0,0,0,0.3)",
                  paddingLeft: "1em",
                }}
              >
                {link.url}
              </span>
            </span>
          ) : (
            <span>{link.url}</span>
          )}
        </a>
      </div>
      <div style={{ marginLeft: "auto", paddingLeft: "1em" }}>
        <Popup
          className="hubly_bars_menu"
          hideOnScroll
          on="click"
          onClose={() => {
            setMenuOpen(false);
          }}
          onOpen={() => {
            setMenuOpen(true);
          }}
          open={menuOpen}
          popperModifiers={{
            preventOverflow: {
              boundariesElement: "window",
              enabled: false,
            },
          }}
          position="right center"
          trigger={<Icon name="ellipsis horizontal" link />}
        >
          <Button.Group basic vertical labeled icon style={{ border: "none" }}>
            <Button
              icon="edit"
              content="Edit Link"
              onClick={() => {
                setEditMode(true);
                setMenuOpen(false);
              }}
            />
            <Button
              icon="delete"
              content="Delete Link"
              onClick={() => {
                onDelete(link);
                setMenuOpen(false);
              }}
            />
          </Button.Group>
        </Popup>
      </div>
    </div>
  );
}

LinkItem.propTypes = {
  link: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    url: PropTypes.string.isRequired,
  }).isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

LinkItem.defaultProps = {
  loading: false,
};

export default LinkItem;
