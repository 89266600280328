import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Checkbox, Header, Table } from "semantic-ui-react";
import { EditHub } from "data/libs/hubs";
import { SetAdvisor } from "data/advisor/actions";
import { SetAlert } from "components/Alerts/actions";
import { SetHub } from "data/hub/actions";
import RedtailIntegration from "./components/RedtailIntegration";
import WealthboxIntegration from "./components/WealthboxIntegration";
import ZapierIntegration from "./components/ZapierIntegration";

const allApps = [
  { type: "redtail" },
  { type: "wealthbox" },
  { type: "zapier" },
];

class IntegrationSettings extends React.Component {
  static propTypes = {
    advisor: PropTypes.object.isRequired,
    selectedHub: PropTypes.object.isRequired,
    setHub: PropTypes.func.isRequired,
    setAdvisor: PropTypes.func.isRequired,
  };

  state = {};

  getIntegrationComponent = (integration, connected) => {
    switch (integration.type) {
      case "redtail":
        return <RedtailIntegration connected={connected} />;
      case "wealthbox":
        return <WealthboxIntegration connected={connected} />;
      case "zapier":
        return <ZapierIntegration connected={connected} />;
      default:
        return null;
    }
  };

  changeSaveCompletionsToCRM = (value, hub) => {
    const { selectedHub, setHub, setAdvisor, advisor } = this.props;
    const newState = {};
    const key = `${hub.id}_save_to_CRM`;
    newState[key] = true;
    this.setState(newState);
    EditHub(hub.id, { logCompletedWorkflowsInCrm: value })
      .then((response) => {
        if (selectedHub && response.id === selectedHub.id) {
          setHub(response);
        }
        // set the hub inside the advisor object too
        const advisorCopy = { ...advisor };
        const index = advisorCopy.hubs.findIndex((h) => {
          return h.id === response.id;
        });
        if (index >= 0) {
          advisorCopy.hubs[index] = response;
          setAdvisor(advisorCopy);
        }
      })
      .finally(() => {
        newState[key] = false;
        this.setState(newState);
      });
  };

  render() {
    const { advisor } = this.props;
    const connectedApps = advisor.integrations;
    const availableApps = allApps.filter((app) => {
      return !connectedApps.find((connected) => {
        return connected.type === app.type;
      });
    });

    const isCRMIntegrated =
      advisor.integrations.find((integration) => {
        return (
          integration.type === "redtail" || integration.type === "wealthbox"
        );
      }) !== undefined;

    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginBottom: "2em",
          }}
        >
          <Header as="h1">Connected Apps</Header>
          <div style={{ marginLeft: "1em" }}>
            {connectedApps.length > 0 ? (
              connectedApps.map((app) => {
                return this.getIntegrationComponent(app, true);
              })
            ) : (
              <span style={{ color: "grey", fontSize: "16px" }}>
                No connected apps.
              </span>
            )}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginBottom: "2em",
          }}
        >
          <Header as="h1">Available Apps</Header>
          <div style={{ marginLeft: "1em" }}>
            {availableApps.map((app) => {
              return this.getIntegrationComponent(app, false);
            })}
            {availableApps.length === 0 && (
              <span>You are connected to all available apps. </span>
            )}
          </div>
        </div>
        {isCRMIntegrated && (
          <div
            data-test="integration-settings"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <Header as="h1">Integration Settings</Header>
            <div style={{ marginLeft: "1em" }}>
              <Table selectable stackable>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Hub</Table.HeaderCell>
                    <Table.HeaderCell>
                      Save Completed Workflows to CRM Note
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {advisor.hubs
                    .filter((hub) => {
                      return (
                        hub.clientPool.type === "redtail" ||
                        hub.clientPool.type === "wealthbox"
                      );
                    })
                    .sort((a, b) => {
                      return a.createdAt < b.createdAt;
                    })
                    .map((hub) => {
                      return (
                        <Table.Row key={hub.id}>
                          <Table.Cell>{hub.name}</Table.Cell>
                          <Table.Cell>
                            <Checkbox
                              toggle
                              checked={hub.logCompletedWorkflowsInCrm}
                              onChange={(e) => {
                                this.changeSaveCompletionsToCRM(
                                  !hub.logCompletedWorkflowsInCrm,
                                  hub
                                );
                              }}
                              style={{ marginLeft: "110px" }}
                              loading={this.state[`${hub.id}_save_to_CRM`]}
                              disabled={this.state[`${hub.id}_save_to_CRM`]}
                            />
                          </Table.Cell>
                        </Table.Row>
                      );
                    })}
                </Table.Body>
              </Table>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    advisor: state.hubly.data.advisor,
    selectedHub: state.hubly.data.hub.selected.hub,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAdvisor: (advisor) => {
      dispatch(SetAdvisor(advisor));
    },
    setAlert: (alert) => {
      dispatch(SetAlert(alert));
    },
    setHub: (hub) => {
      dispatch(SetHub(hub));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IntegrationSettings);
