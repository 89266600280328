import React from "react";
import PropTypes from "prop-types";
import { Checkbox } from "semantic-ui-react";

export function ShowNextTaskFilter({
  assigneeDisplayName,
  filterNextTaskForAssignee,
  onChange,
}) {
  return (
    <Checkbox
      data-test="filter-menu-next-task-toggle"
      toggle
      checked={filterNextTaskForAssignee}
      label={`Only show workflows where the next task is assigned to ${assigneeDisplayName}`}
      onChange={(e, { checked }) => {
        onChange({
          filterNextTaskForAssignee: checked,
        });
      }}
    />
  );
}

ShowNextTaskFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  filterNextTaskForAssignee: PropTypes.bool.isRequired,
  assigneeDisplayName: PropTypes.string.isRequired,
};

export default ShowNextTaskFilter;
