import React from "react";
import PropTypes from "prop-types";

import { Container } from "semantic-ui-react";

import { WithDroppable, useDraggable } from "components/DragDrop";
import { InfoBullet, Colorize } from "./components";

import "./Workflow.css";

export function WorkflowColumn({
  testId,
  title,
  description,
  color,
  showNewTempInfo,
  showRuleInfo,
  onDoubleClickTitle,
  menuSlot,
  topChildren,
  children,
}) {
  const { draggableProps, dragHandleProps } = useDraggable();

  return (
    <div>
      <div
        className="ui segment workflow dndfix"
        {...draggableProps}
        style={{
          marginBottom: "0",
          maxHeight: "calc(100vh - 125px)",
          ...draggableProps.style,
        }}
      >
        <div
          data-test={`workflow-${testId}`}
          className="workflow_transition"
          style={{ width: "252px", backgroundColor: "rgb(249, 249, 249)" }}
        >
          <Container
            className="workflow_stage view_workflows"
            style={{
              backgroundColor: "rgb(249, 249, 249)",
              display: "flex",
              flexDirection: "column",
              maxHeight: "100%",
              width: "252px",
            }}
          >
            <Container
              data-test={`workflow-view-${testId}`}
              className="workflow_name"
              {...dragHandleProps}
              style={{ flexShrink: "0", ...dragHandleProps.style }}
              onDoubleClick={onDoubleClickTitle}
            >
              {menuSlot}
              {showRuleInfo && (
                <InfoBullet
                  content={
                    "This workflow has active rules applied to it." +
                    " View rules by selecting 'Edit Workflow' in the workflow header menu."
                  }
                  icon="settings"
                  style={{
                    float: "right",
                    fontSize: "12pt",
                    margin: "2px 0 0px .25em",
                    transform: "scaleX(-1) translateY(-3px)",
                    color: "darkgrey",
                  }}
                />
              )}

              {showNewTempInfo && (
                <InfoBullet
                  content="This is a templated workflow. Personalize this workflow by selecting ‘Edit Workflow’ in the
                          overflow on the right side of the header."
                  icon="info circle"
                  size="tiny"
                  color="blue"
                  style={{
                    float: "right",
                    fontSize: "12pt",
                    margin: "2px 0 0px .25em",
                  }}
                />
              )}
              <div>
                {color && <Colorize content={description} color={color} />}
                {title}
              </div>
            </Container>
            {topChildren}
            <div
              className="workflow_content"
              data-test={`workflow-droppable-${testId}`}
              style={{
                padding: "0em 0.8em 0.8em 0.8em",
                overflowX: "hidden",
                overflowY: "overlay",
                backgroundColor: "#f9f9f9",
              }}
            >
              {children}
            </div>
          </Container>
        </div>
      </div>
    </div>
  );
}

WorkflowColumn.propTypes = {
  testId: PropTypes.string.isRequired,
  title: PropTypes.node.isRequired,
  description: PropTypes.string,
  color: PropTypes.string,
  showNewTempInfo: PropTypes.bool,
  showRuleInfo: PropTypes.bool,
  onDoubleClickTitle: PropTypes.func.isRequired,
  menuSlot: PropTypes.node,
  topChildren: PropTypes.node,
  children: PropTypes.node,
};

WorkflowColumn.defaultProps = {
  children: null,
  topChildren: null,
  menuSlot: null,
  description: undefined,
  color: undefined,
  showNewTempInfo: false,
  showRuleInfo: false,
};

export const DroppableWorkflow = WithDroppable(WorkflowColumn, "WorkflowItems");

export default WorkflowColumn;
