import React from "react";
import PropTypes from "prop-types";

import { Button } from "semantic-ui-react";

export function MainMenu({
  onClickEditWorkflow,
  onClickSortingBy,
  onClickModifyTitle,
  onClickDuplicateWorkflow,
  onClickHideWorkflow,
}) {
  return (
    <React.Fragment>
      <Button
        icon="edit"
        content="Edit Workflow"
        onClick={onClickEditWorkflow}
      />
      <Button
        icon="sort content ascending"
        content="Sort By..."
        onClick={onClickSortingBy}
      />
      <Button
        icon="th"
        content="Edit Appearance"
        onClick={onClickModifyTitle}
      />
      <Button
        icon="copy"
        content="Duplicate Workflow"
        onClick={onClickDuplicateWorkflow}
      />
      <Button
        icon="hide"
        content="Hide From View"
        onClick={onClickHideWorkflow}
      />
    </React.Fragment>
  );
}

MainMenu.propTypes = {
  onClickEditWorkflow: PropTypes.func.isRequired,
  onClickSortingBy: PropTypes.func.isRequired,
  onClickModifyTitle: PropTypes.func.isRequired,
  onClickDuplicateWorkflow: PropTypes.func.isRequired,
  onClickHideWorkflow: PropTypes.func.isRequired,
};

MainMenu.defaultProps = {};

export default MainMenu;
