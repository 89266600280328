import { combineReducers } from "redux";

import { reducer as dataReducer } from "./data/reducer";
import { reducer as scenesReducer } from "./scenes/reducer";
import { reducer as alertReducer } from "./components/Alerts/reducer";
import { reducer as assignedAdvisorReducer } from "./components/AssignedAdvisorPopup/reducer";
import { reducer as streamsReducer } from "./components/StreamModal/reducer";
import { reducer as loadingReducer } from "./components/Loading/reducer";
import { reducer as confirmationReducer } from "./components/ConfirmationModal/reducer";
import { reducer as createClientReducer } from "./components/CreateClientModal/reducer";
import { reducer as createHouseholdReducer } from "./components/ManageHouseholdModal/reducer";
import { reducer as reminderRescheduleReducer } from "./components/ReminderRescheduleModal/reducer";
import { reducer as tagsReducer } from "./components/TagModal/reducer";
import { reducer as taskReducer } from "./components/Task/reducer";
import { reducer as viewReducer } from "./components/ViewModal/reducer";
import { reducer as wealthboxIntegrationReducer } from "./components/WealthboxIntegrationModal/reducer";

const reducer = combineReducers({
  data: dataReducer,
  scenes: scenesReducer,
  alerts: alertReducer,
  assignedAdvisor: assignedAdvisorReducer,
  loading: loadingReducer,
  confirmation: confirmationReducer,
  createClient: createClientReducer,
  createHousehold: createHouseholdReducer,
  reminderReschedule: reminderRescheduleReducer,
  streams: streamsReducer,
  tags: tagsReducer,
  task: taskReducer,
  viewModal: viewReducer,
  wealthboxIntegration: wealthboxIntegrationReducer,
});

const appReducer = (state, action) => {
  let newState = state;
  if (action.type === "USER_LOGOUT") {
    newState = undefined;
  }

  return reducer(newState, action);
};

export default appReducer;
