import { uniqBy } from "lodash";
import { SetCurrentView } from "data/views/actions";
import { filterClientWorkflows } from "../libs/filters";

export const SetFilters = (filters, isFilterUpdated = true) => {
  return {
    type: "SET_FILTERS",
    filters: filters,
    isFilterUpdated: isFilterUpdated,
  };
};

export const SetMenuFilters = (filters, applyState) => {
  return {
    ...SetFilters(filters),
    filterCategory: "MENU_FILTERS",
    applyState: applyState,
  };
};

export const SetEmptyWorkflowManually = (setManually) => {
  return {
    type: "SET_MANUALLY_EMPTY_WORKFLOWS",
    setManuallyFilterEmptyWorkflows: setManually,
  };
};

export const ToggleEmptyWorkflows = (filters) => {
  return (dispatch) => {
    dispatch(SetFilters(filters));
    dispatch(SetEmptyWorkflowManually(true));
  };
};

export const SetRemindersVisibilityFilter = (filter) => {
  return {
    type: "SET_REMINDERS_VISIBILITY_FILTER",
    remindersVisibilityFilter: filter,
  };
};

export const ResetFilters = (workflows) => {
  return {
    type: "RESET_FILTERS",
    workflows: workflows,
  };
};

export const ClearFilters = (workflows) => {
  return (dispatch, getState) => {
    dispatch(ResetFilters(workflows));
    dispatch(SetCurrentView({}));
    dispatch(SetEmptyWorkflowManually(false));
  };
};

export const HideShowAllWorkflows = (hidden, callback, orderedWorkflows) => {
  return (dispatch, getState) => {
    const filters = { ...getState().hubly.data.filters };
    if (hidden) {
      filters.workflows = [];
    } else {
      filters.workflows = orderedWorkflows;
    }

    dispatch(SetFilters(filters));

    if (callback) {
      callback();
    }
  };
};

export const ShowActiveWorkflows = (callback, orderedWorkflows) => {
  return (dispatch, getState) => {
    const filters = { ...getState().hubly.data.filters };
    const { clientWorkflows } = getState().hubly.data.hub;
    const { activeClients } = getState().hubly.data.hub.clients;
    const { advisor } = getState().hubly.data;

    filters.workflows = [];
    orderedWorkflows.forEach((wf) => {
      const filteredClientWorkflows = filterClientWorkflows(
        wf.clients,
        clientWorkflows,
        activeClients,
        filters,
        advisor
      );
      if (filteredClientWorkflows.length > 0) {
        filters.workflows.push(wf);
      }
    });
    dispatch(SetFilters(filters));
    if (callback) callback();
  };
};

export const HideShowWorkflow = (workflow, hidden) => {
  return (dispatch, getState) => {
    const filters = { ...getState().hubly.data.filters };
    const initialShownWorkflows = [...filters.workflows];

    if (hidden) {
      const filtered = initialShownWorkflows.filter((w) => {
        return w.id !== workflow.id;
      });
      filters.workflows = filtered;
    } else {
      filters.workflows.push(workflow);
    }
    dispatch(SetFilters(filters));
  };
};

export const OnEnterLoadWorkFlows = (filteredWorkflows) => {
  return (dispatch, getState) => {
    const filters = { ...getState().hubly.data.filters };
    filters.workflows = uniqBy(
      [...filters.workflows].concat(filteredWorkflows),
      "id"
    );
    dispatch(SetFilters(filters));
  };
};

export const HideShowProcess = (process, hidden, callback) => {
  return (dispatch, getState) => {
    const filters = { ...getState().hubly.data.filters };
    const { workflows } = getState().hubly.data.hub;

    const processWorkflows = [];
    Object.values(workflows).forEach((workflow) => {
      if (workflow.process && workflow.process.id === process.id) {
        processWorkflows.push(workflow);
      }
    });

    const initialFilteredProcesses = [...filters.processes];
    let initialShownWorkflows = [...filters.workflows];

    if (hidden) {
      initialFilteredProcesses.push(process);
      filters.processes = initialFilteredProcesses;

      // We're hiding a process, so let's remove all the workflows from the filter as well
      processWorkflows.forEach((workflow) => {
        initialShownWorkflows = initialShownWorkflows.filter((w) => {
          return w.id !== workflow.id;
        });
      });
      filters.workflows = initialShownWorkflows;
    } else {
      filters.processes = initialFilteredProcesses.filter((p) => {
        return p.id !== process.id;
      });

      // We're unhiding a process, so let's add all the workflows to the filter as well
      processWorkflows.forEach((workflow) => {
        // only interested in adding workflow that is not in the hub yet
        if (
          !initialShownWorkflows.some(({ id }) => {
            return id === workflow.id;
          })
        )
          initialShownWorkflows.push(workflow);
      });
      filters.workflows = initialShownWorkflows;
    }

    if (callback) {
      callback(true);
    }

    dispatch(SetFilters(filters));
  };
};

export const IsFilterOn = (filters) => {
  const {
    processes = [],
    streams = [],
    tags = [],
    workflows = [],
    reminderTypes = [],
    assignee = "ALL",
    filterEmptyWorkflows = false,
  } = filters;

  // If filters.workflows is null, it's in the initial state, return false
  if (!filters.workflows) {
    return false;
  } else {
    return (
      processes.length > 0 ||
      streams.length > 0 ||
      tags.length > 0 ||
      workflows.length > 0 ||
      filterEmptyWorkflows ||
      assignee !== "ALL" ||
      reminderTypes.length > 0
    );
  }
};

export function makeAssignee(assignee, advisor) {
  function getAssigneeId() {
    switch (assignee) {
      case "ALL":
        return undefined;
      case "UNASSIGNED":
        return undefined;
      case "CURRENT":
        return advisor.id;
      default:
        return assignee;
    }
  }

  return {
    id: getAssigneeId(assignee, advisor),
    state: assignee,
  };
}
