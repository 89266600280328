import React from "react";
import { Button, Header, Icon, Input, Message, Popup } from "semantic-ui-react";
import { connect } from "react-redux";
import moment from "moment";
import PropTypes from "prop-types";
import { ToggleTaskTimeSpentPopup } from "components/Task/actions";
import { SetAlert } from "components/Alerts/actions";
import {
  CreateTaskTimeEstimate,
  DeleteTaskTimeEstimate,
} from "data/libs/tasks";
import { SetClientWorkflow } from "data/hub/clientWorkflows/actions";

class TaskTimeSpent extends React.Component {
  static defaultProps = {
    addingTask: false,
    editTaskState: () => {},
    modalTaskId: "",
  };

  static propTypes = {
    addingTask: PropTypes.bool,
    advisor: PropTypes.object.isRequired,
    clientWorkflow: PropTypes.object.isRequired,
    editTaskState: PropTypes.func,
    isTaskTimeSpentOpen: PropTypes.bool.isRequired,
    modalTaskId: PropTypes.string,
    setAlert: PropTypes.func.isRequired,
    setClientWorkflow: PropTypes.func.isRequired,
    task: PropTypes.shape({
      id: PropTypes.string.isRequired,
      clientWorkflowId: PropTypes.string.isRequired,
      timeEstimates: PropTypes.array.isRequired,
    }).isRequired,
    toggleTaskTimeSpentPopup: PropTypes.func.isRequired,
    workflow: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      error: false,
      hours: 0,
      deleting: false,
      loading: false,
      minutes: 0,
      nestedComponentOpen: false,
      showTimeEntryInput: false,
    };
  }

  getIntFromInput = (number) => {
    if (Number.isInteger(number)) {
      return parseInt(number);
    }

    return 0;
  };

  addTime = (addHours, addMinutes) => {
    let { hours, minutes } = this.state;

    const totalTime =
      60 * (this.getIntFromInput(hours) + addHours) +
      this.getIntFromInput(minutes) +
      addMinutes;

    hours = Math.floor(totalTime / 60);
    minutes = totalTime % 60;

    this.setState({
      hours: hours,
      minutes: minutes,
    });
  };

  addTaskTimeEstimate = (showInput = false) => {
    const { hours, minutes } = this.state;
    const {
      addingTask,
      advisor,
      clientWorkflow,
      editTaskState,
      setAlert,
      setClientWorkflow,
      task,
    } = this.props;

    if (parseInt(hours) + parseInt(minutes) < 0) {
      this.setState({ error: true });
      return;
    }

    const request = {
      hours: parseInt(hours) || 0,
      minutes: parseInt(minutes) || 0,
    };

    this.setState({ loading: true });
    if (addingTask) {
      const timeEstimateInState = {
        ...request,
        id: task.timeEstimates.length + 1,
        addedBy: advisor,
        autoAddedInd: false,
        timeEstimate: moment.duration(
          request.hours * 60 + request.minutes,
          "minute"
        ),
      };
      editTaskState("timeEstimates", timeEstimateInState, "add");
      this.setState({
        hours: 0,
        minutes: 0,
        loading: false,
        showTimeEntryInput: showInput,
      });
    } else {
      CreateTaskTimeEstimate(task.id, request)
        .then((response) => {
          const workflowCopy = { ...clientWorkflow };
          if (!workflowCopy) {
            console.error(
              "Error adding time spent to task, could not find workflow."
            );
            setAlert({
              type: "error",
              text: `Failed to add time spent to task.`,
            });
            this.setState({ loading: false });
            return;
          }

          const foundTask = workflowCopy.tasks.find((t) => {
            return t.id === task.id;
          });
          if (!foundTask) {
            console.error(
              "Error adding time spent to task, could not find task."
            );
            setAlert({ type: "error", text: `Failed to add time spent.` });
            this.setState({ loading: false });
            return;
          }

          foundTask.timeEstimates.push(response);
          setClientWorkflow(workflowCopy);
          this.setState({
            hours: 0,
            minutes: 0,
            loading: false,
            showTimeEntryInput: showInput,
          });
        })
        .catch((error) => {
          console.error(error);
          setAlert({ type: "error", text: `Failed to add time spent.` });
          this.setState({ loading: false });
        });
    }
  };

  deleteTaskTimeEstimate = (timeEstimateId) => {
    const {
      addingTask,
      clientWorkflow,
      editTaskState,
      setAlert,
      setClientWorkflow,
      task,
    } = this.props;
    this.setState({ deleting: true });
    if (addingTask) {
      editTaskState("timeEstimates", timeEstimateId, "delete");
      this.setState({ deleting: false, nestedComponentOpen: false });
    } else {
      DeleteTaskTimeEstimate(task.id, timeEstimateId)
        .then((response) => {
          const workflowCopy = { ...clientWorkflow };

          if (!clientWorkflow) {
            console.error(
              "Error deleting time spent, could not find workflow."
            );
            setAlert({ type: "error", text: `Failed to delete time spent.` });
            this.setState({ deleting: false });
            return;
          }

          const foundTask = workflowCopy.tasks.find((t) => {
            return t.id === task.id;
          });
          if (!foundTask) {
            console.error("Error deleting time spent, could not find task.");
            setAlert({ type: "error", text: `Failed to delete time spent.` });
            this.setState({ deleting: false });
            return;
          }

          foundTask.timeEstimates = foundTask.timeEstimates.filter(
            (timeEstimate) => {
              return timeEstimate.id !== timeEstimateId;
            }
          );
          setClientWorkflow(workflowCopy);
          this.setState({ deleting: false, nestedComponentOpen: false });
        })
        .catch((error) => {
          console.error(error);
          setAlert({ type: "error", text: "Failed to delete time spent." });
          this.setState({ deleting: false });
        });
    }
  };

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value, error: false });
  };

  toggleTimeEntry = () => {
    this.setState((state) => {
      return {
        showTimeEntryInput: !state.showTimeEntryInput,
        error: false,
        hours: 0,
        minutes: 0,
      };
    });
  };

  handleKeyPress = (e) => {
    if (e.key === "Enter") {
      this.addTaskTimeEstimate(true);
      this.setState({ minutes: 0, hours: 0 });
    }
  };

  isOpen = () => {
    const { isTaskTimeSpentOpen, modalTaskId, task } = this.props;
    return task.id === modalTaskId && isTaskTimeSpentOpen;
  };

  renderTrigger() {
    const { task, toggleTaskTimeSpentPopup } = this.props;

    const totalTimeSpent = task.timeEstimates
      .filter((estimate) => {
        return !estimate.autoAddedInd;
      })
      .reduce((accumulator, currentValue) => {
        return accumulator.add(moment.duration(currentValue.timeEstimate));
      }, moment.duration());

    const totalTimeEst = task.timeEstimates
      .filter((estimate) => {
        return estimate.autoAddedInd;
      })
      .reduce((accumulator, currentValue) => {
        return accumulator.add(moment.duration(currentValue.timeEstimate));
      }, moment.duration());

    if (
      totalTimeEst.asMilliseconds() > 0 ||
      totalTimeSpent.asMilliseconds() > 0
    ) {
      return (
        <Popup
          on="hover"
          popperModifiers={{
            preventOverflow: { boundariesElement: "window", enabled: false },
          }}
          position="right center"
          style={{ padding: "0.6em", fontSize: "11px", fontWeight: "bold" }}
          trigger={
            <Icon
              onClick={() => {
                toggleTaskTimeSpentPopup(task.id, true);
              }}
              color="grey"
              name="clock"
              link
            />
          }
        >
          {totalTimeSpent.asMilliseconds() > 0
            ? `Time Spent: ${
                totalTimeSpent.days() * 24 + totalTimeSpent.hours()
              }h ${totalTimeSpent.minutes()}m`
            : `Time Estimate: ${
                totalTimeEst.days() * 24 + totalTimeEst.hours()
              }h ${totalTimeEst.minutes()}m`}
        </Popup>
      );
    } else {
      const isOpen = this.isOpen();
      return (
        <Icon.Group
          className={!isOpen && "show_on_hover dark_grey_on_hover"}
          onClick={() => {
            toggleTaskTimeSpentPopup(task.id, true);
          }}
          style={{ fontSize: "13pt" }}
          title="Time Spent"
        >
          <Icon
            link
            name="clock outline"
            style={isOpen ? { color: "#aaaaaa", margin: 0 } : { margin: 0 }}
          />
          <Icon
            link
            corner
            name="add"
            style={
              isOpen
                ? { color: "#aaaaaa" }
                : { color: "#aaaaaa", textShadow: "none" }
            }
          />
        </Icon.Group>
      );
    }
  }

  render() {
    const {
      hours,
      deleting,
      error,
      loading,
      minutes,
      nestedComponentOpen,
      showTimeEntryInput,
    } = this.state;
    const { task, toggleTaskTimeSpentPopup } = this.props;

    if (!this.isOpen()) {
      return this.renderTrigger();
    } else {
      const timeEstimates = task.timeEstimates.filter((estimate) => {
        return !estimate.autoAddedInd;
      });

      const totalTimeEstimate = task.timeEstimates
        .filter((estimate) => {
          return estimate.autoAddedInd;
        })
        .reduce((accumulator, currentValue) => {
          return accumulator.add(moment.duration(currentValue.timeEstimate));
        }, moment.duration());

      const totalTimeSpent = task.timeEstimates
        .filter((estimate) => {
          return !estimate.autoAddedInd;
        })
        .reduce((accumulator, currentValue) => {
          return accumulator.add(moment.duration(currentValue.timeEstimate));
        }, moment.duration());
      return (
        <Popup
          on="click"
          open
          data-test="task-time-spent-popup"
          onClose={() => {
            // Don't close if the nested popup/modal is still open
            // Bug in semantic with nested onClose events
            if (!nestedComponentOpen) {
              toggleTaskTimeSpentPopup();
            }
          }}
          onOpen={() => {
            toggleTaskTimeSpentPopup(task.id, true);
          }}
          popperModifiers={{
            preventOverflow: { boundariesElement: "window", enabled: false },
          }}
          position="bottom center"
          trigger={this.renderTrigger()}
        >
          <div
            style={{
              maxHeight: "500px",
              minWidth: "325px",
              overflowY: "auto",
              alignContent: "stretch",
            }}
          >
            <div style={{ display: "flex" }}>
              <Header as="h4" style={{ marginBottom: "1em" }}>
                Time Spent on Task
              </Header>
            </div>
            <div>
              <div>
                <div style={{ display: "flex", marginBottom: "0.5em" }}>
                  <div
                    style={{
                      width: "40%",
                      textAlign: "left",
                      fontWeight: "bold",
                    }}
                  >
                    Date Entered
                  </div>
                  <div
                    style={{
                      width: "40%",
                      textAlign: "left",
                      fontWeight: "bold",
                    }}
                  >
                    Added By
                  </div>
                  <div
                    style={{
                      width: "20%",
                      textAlign: "left",
                      fontWeight: "bold",
                    }}
                  >
                    Time
                  </div>
                </div>
                {timeEstimates.length === 0 && !showTimeEntryInput && (
                  <span
                    style={{
                      fontSize: "1em",
                      color: "grey",
                      marginLeft: "1em",
                    }}
                  >
                    No time logs have been entered for this task
                  </span>
                )}
                {timeEstimates.map((timeEstimate) => {
                  timeEstimate.timeEstimate = moment.duration(
                    timeEstimate.timeEstimate
                  );
                  const addedBy = timeEstimate.addedBy
                    ? timeEstimate.addedBy.name
                    : "Unassigned";
                  return (
                    <Popup
                      className="hubly_bars_menu"
                      hideOnScroll
                      key={timeEstimate.id}
                      on="click"
                      onOpen={() => {
                        this.setState({ nestedComponentOpen: true });
                      }}
                      onClose={() => {
                        this.setState({ nestedComponentOpen: false });
                      }}
                      popperModifiers={{
                        preventOverflow: {
                          boundariesElement: "window",
                          enabled: false,
                        },
                      }}
                      position="right center"
                      trigger={
                        <div
                          style={{ display: "flex", padding: "0.5em 1em" }}
                          className="time-estimate grey_on_hover p25"
                        >
                          <div style={{ width: "40%", textAlign: "left" }}>
                            {moment(timeEstimate.createdAt).format(
                              "MMMM D, YYYY"
                            )}
                          </div>
                          <div style={{ width: "40%", textAlign: "left" }}>
                            {addedBy}
                          </div>
                          <div style={{ width: "20%", textAlign: "left" }}>
                            {`${
                              timeEstimate.timeEstimate.days() * 24 +
                              timeEstimate.timeEstimate.hours()
                            }h `}
                            {`${timeEstimate.timeEstimate.minutes()}m`}
                          </div>
                        </div>
                      }
                    >
                      <Button.Group
                        basic
                        vertical
                        labeled
                        icon
                        style={{ border: "none" }}
                      >
                        <Button
                          icon="remove"
                          content="Delete time spent"
                          loading={deleting}
                          onClick={() => {
                            this.deleteTaskTimeEstimate(timeEstimate.id);
                          }}
                        />
                      </Button.Group>
                    </Popup>
                  );
                })}
                {showTimeEntryInput ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      padding: "0.5em 0",
                    }}
                  >
                    <Message negative hidden={!error}>
                      <Message.Header>Error</Message.Header>
                      <p>Time spent must not be negative</p>
                    </Message>
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          width: "25%",
                          marginRight: "5px",
                          color: "#9c9c9c",
                        }}
                      >
                        <Input
                          display="inline"
                          error={error}
                          fluid
                          maxLength="2"
                          min="0"
                          name="hours"
                          onChange={this.handleChange}
                          type="number"
                          value={hours}
                          onKeyPress={this.handleKeyPress}
                        />
                        Hours
                      </div>
                      <div style={{ width: "25%", color: "#9c9c9c" }}>
                        <Input
                          display="inline"
                          error={error}
                          fluid
                          maxLength="2"
                          min="0"
                          name="minutes"
                          onChange={this.handleChange}
                          type="number"
                          value={minutes}
                          onKeyPress={this.handleKeyPress}
                        />
                        Minutes
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        margin: "1em 0",
                      }}
                    >
                      <div style={{ display: "flex", width: "25%" }}>
                        <Button
                          basic
                          compact
                          fluid
                          onClick={() => {
                            this.addTime(0, 15);
                          }}
                          style={{ fontWeight: "bold", padding: "0.9em 0" }}
                        >
                          <Icon name="plus" style={{ margin: 0 }} />
                          15m
                        </Button>
                      </div>
                      <div style={{ display: "flex", width: "25%" }}>
                        <Button
                          basic
                          compact
                          fluid
                          onClick={() => {
                            this.addTime(0, 30);
                          }}
                          style={{ fontWeight: "bold", padding: "0.9em 0" }}
                        >
                          <Icon name="plus" style={{ margin: 0 }} />
                          30m
                        </Button>
                      </div>
                      <div style={{ display: "flex", width: "25%" }}>
                        <Button
                          basic
                          compact
                          fluid
                          onClick={() => {
                            this.addTime(1, 0);
                          }}
                          style={{ fontWeight: "bold", padding: "0.9em 0" }}
                        >
                          <Icon name="plus" style={{ margin: 0 }} />
                          1h
                        </Button>
                      </div>
                      <div style={{ display: "flex", width: "25%" }}>
                        <Button
                          basic
                          compact
                          fluid
                          onClick={() => {
                            this.addTime(3, 0);
                          }}
                          style={{ fontWeight: "bold", padding: "0.9em 0" }}
                        >
                          <Icon name="plus" style={{ margin: 0 }} />
                          3h
                        </Button>
                      </div>
                    </div>
                    <div style={{ padding: "0 12px 0 0" }}>
                      <Button
                        icon="plus"
                        loading={loading}
                        disabled={loading}
                        onClick={this.addTaskTimeEstimate}
                        color="green"
                      >
                        Add
                      </Button>
                      <Icon
                        disabled={loading}
                        link
                        name="close"
                        size="large"
                        color="grey"
                        onClick={this.toggleTimeEntry}
                      />
                    </div>
                  </div>
                ) : (
                  <div style={{ padding: "12px 12px 0 0" }}>
                    <Button
                      basic
                      content="Add Time"
                      onClick={this.toggleTimeEntry}
                    />
                  </div>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "1em",
                }}
              >
                <div style={{ width: "100%", overflow: "auto" }}>
                  <div style={{ float: "right" }}>
                    <div style={{ fontWeight: "bold" }}>
                      {`${
                        totalTimeSpent.days() * 24 + totalTimeSpent.hours()
                      } `}
                      {totalTimeSpent.days() * 24 + totalTimeSpent.hours() === 1
                        ? `Hour `
                        : `Hours `}
                      {totalTimeSpent.minutes() === 1
                        ? `${totalTimeSpent.minutes()} Minute `
                        : `${totalTimeSpent.minutes()} Minutes `}
                    </div>
                  </div>
                  <div style={{ float: "left" }}>
                    <div style={{ marginBottom: "5px" }}>Total:</div>
                  </div>
                </div>
                <div style={{ width: "100%", overflow: "auto" }}>
                  <div style={{ float: "right" }}>
                    <div style={{ fontWeight: "bold" }}>
                      {`${
                        totalTimeEstimate.days() * 24 +
                        totalTimeEstimate.hours()
                      } `}
                      {totalTimeEstimate.days() * 24 +
                        totalTimeEstimate.hours() ===
                      1
                        ? `Hour `
                        : `Hours `}
                      {totalTimeEstimate.minutes() === 1
                        ? `${totalTimeEstimate.minutes()} Minute `
                        : `${totalTimeEstimate.minutes()} Minutes `}
                    </div>
                  </div>
                  <div style={{ float: "left" }}>
                    <div style={{ display: "flex" }}>
                      <div style={{ marginBottom: "5px" }}>Time Estimate:</div>
                      <div>
                        <Popup
                          content="Manually logging time spent will replace estimated time spent on the task."
                          position="right center"
                          wide
                          popperModifiers={{
                            preventOverflow: {
                              boundariesElement: "window",
                              enabled: false,
                            },
                          }}
                          trigger={
                            <Icon
                              color="grey"
                              name="question circle outline"
                              style={{
                                float: "right",
                                marginLeft: "3px",
                                marginTop: "1px",
                              }}
                            />
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Popup>
      );
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    advisor: state.hubly.data.advisor,
    clientWorkflow:
      state.hubly.data.hub.clientWorkflows[ownProps.task.clientWorkflowId],
    isTaskTimeSpentOpen: state.hubly.task.isTaskTimeSpentOpen,
    modalTaskId: state.hubly.task.modalTaskId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAlert: (alert) => {
      dispatch(SetAlert(alert));
    },
    setClientWorkflow: (clientWorkflow) => {
      dispatch(SetClientWorkflow(clientWorkflow));
    },
    toggleTaskTimeSpentPopup: (taskId, isOpen) => {
      dispatch(ToggleTaskTimeSpentPopup(taskId, isOpen));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskTimeSpent);
