import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import FeedSettingsPopup from "./components/FeedSettingsPopup";

export function FeedSettings({ enabled }) {
  return enabled ? <FeedSettingsPopup /> : null;
}

FeedSettings.propTypes = {
  enabled: PropTypes.bool,
};

FeedSettings.defaultProps = {
  enabled: false,
};

const mapStateToProps = (state, _) => {
  const teamHub = state.hubly.data.hub.selected.hub.advisors.length > 1;
  return { enabled: teamHub };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(FeedSettings);
