import React from "react";
import { Label, Icon } from "semantic-ui-react";
import moment from "moment";
import PropTypes from "prop-types";
import PopupMenu from "components/PopupMenu";
import { connect } from "react-redux";
import { RRule } from "rrule";
import { SetConfirmationModal } from "components/ConfirmationModal/actions";
import {
  RemoveWorkflowRule,
  SetEditWorkflowRule,
  UpdateWorkflowRule,
} from "../actions";
import ExcludeItemsLabels from "./ExcludeItemsLabels";
import { parseEditWorkflowTags, parseEditWorkflowClients } from "../helpers";

class RepeatingWorkflowRule extends React.Component {
  static propTypes = {
    removeWorkflowRule: PropTypes.func.isRequired,
    rule: PropTypes.object.isRequired,
    setConfirmationModal: PropTypes.func.isRequired,
    setEditWorkflowRule: PropTypes.func.isRequired,
    setWorkflowRuleModal: PropTypes.func.isRequired,
    tags: PropTypes.object.isRequired,
    workflow: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
    updateWorkflowRule: PropTypes.func.isRequired,
    allClients: PropTypes.func.isRequired,
  };

  state = {
    popupOpen: false,
  };

  confirmDeleteRule = () => {
    const { removeWorkflowRule, rule, setConfirmationModal, workflow } =
      this.props;
    const remove = () => {
      removeWorkflowRule(workflow, rule.id);
    };
    const params = {
      title: "Delete Rule",
      message: `Are you sure you wish to delete this rule and its configurations? This cannot be undone.`,
      icon: "delete",
      buttons: [
        {
          text: "Cancel",
        },
        {
          text: "Delete Rule",
          callBack: remove,
          color: "red",
        },
      ],
    };
    setConfirmationModal(params);
  };

  toggleActive = () => {
    const { rule, workflow, updateWorkflowRule } = this.props;
    updateWorkflowRule(
      workflow.id,
      { active: !rule.active, id: rule.id },
      () => {}
    );
  };

  openEditRuleModal = () => {
    const { rule, setEditWorkflowRule, setWorkflowRuleModal } = this.props;
    setEditWorkflowRule(rule);
    setWorkflowRuleModal(true, "repeating");
  };

  render() {
    const { popupOpen } = this.state;
    const { rule, tags, allClients } = this.props;
    let text = "";
    // Can be run the next time the workflow rules sync is run, approximating to be 10:25 UTC for prod
    const workflowRulesSync = new RRule({
      freq: RRule.DAILY,
      count: 2,
      byhour: 10,
      byminute: 25,
      dtstart: new Date(),
    });
    const workflowRulesSyncTime = new Date(workflowRulesSync.after(new Date()));
    if (rule.repeat === "ONCE") {
      text = ` will be added on ${moment(rule.dateStart).format(
        "MMMM Do YYYY"
      )}`;
    } else if (rule.repeat === "EVERY") {
      const { dateStart } = rule;

      // rrule takes a date start in 20120201T093000Z format: https://www.npmjs.com/package/rrule
      const last = moment(dateStart).utc().format("YYYYMMDDTHHmmss");
      // Set the start date to when last run or when the date start was selected.
      let ruleString = `DTSTART:${last}\nRRULE:FREQ=${rule.frequency};INTERVAL=${rule.interval};COUNT=52`;
      if (rule.frequency === "WEEKLY") {
        ruleString += `;BYDAY=${rule.weekStartDay}`;
      }
      const rRule = RRule.fromString(ruleString);
      const nextDate = rRule.after(workflowRulesSyncTime);
      text = ` will next be added on ${moment(nextDate).format(
        "dddd, MMMM Do YYYY"
      )}`;
    }
    return (
      <div
        className="grey_on_hover rounded p25"
        style={{ display: "flex", marginBottom: "0.5em", cursor: "pointer" }}
        data-test="automation-rule-item-repeating"
        onDoubleClick={this.openEditRuleModal}
      >
        <div style={{ opacity: rule.active ? "1" : "0.4" }}>
          Clients with &nbsp;
          {rule.streams.length <= 0 && rule.tags.length <= 0 && (
            <span
              style={{ color: "grey" }}
              title="No stream(s) or tag(s) found. Please specify new stream(s) or tag(s) to resume rule."
            >
              N/A
            </span>
          )}
          {rule.streams.map((stream) => {
            return (
              <Label
                style={{
                  backgroundColor: stream.color,
                  color: "#fff",
                  padding: "0.3em 0.4em",
                  margin: "0.1em",
                }}
              >
                {stream.name}
              </Label>
            );
          })}
          {rule.tags.map((tag) => {
            return (
              <Label
                style={{
                  backgroundColor: "grey",
                  color: "#fff",
                  padding: "0.3em 0.4em",
                  margin: "0.1em",
                }}
              >
                <Icon fitted name="hashtag" />
                &nbsp;
                {this.props.tags[tag].name}
              </Label>
            );
          })}
          {text}
          &nbsp;
          <ExcludeItemsLabels
            streamsExclude={rule?.streamsExclude}
            tagsExclude={parseEditWorkflowTags(rule?.tagsExclude, tags)}
            clientsExclude={parseEditWorkflowClients(
              rule?.clientsExclude,
              allClients
            )}
          />
          {!rule.active && " (Paused)"}
        </div>
        <div style={{ display: "flex", marginLeft: "0.25em" }}>
          <PopupMenu
            buttons={[
              {
                content: "Edit Rule",
                icon: "edit",
                onClick: () => {
                  this.openEditRuleModal();
                  this.setState({ popupOpen: false });
                },
              },
              {
                content: rule.active ? "Pause Rule" : "Resume Rule",
                icon: rule.active ? "pause" : "play",
                onClick: () => {
                  this.toggleActive();
                  this.setState({ popupOpen: false });
                },
              },
              {
                content: "Delete Rule",
                icon: "delete",
                onClick: () => {
                  this.confirmDeleteRule();
                  this.setState({ popupOpen: false });
                },
              },
            ]}
            onOpen={() => {
              this.setState({ popupOpen: true });
            }}
            onClose={() => {
              this.setState({ popupOpen: false });
            }}
            open={popupOpen}
            popperModifiers={{
              preventOverflow: { boundariesElement: "window", enabled: false },
            }}
            trigger={<Icon name="ellipsis horizontal" link color="grey" />}
            data-test="automation-rule-popupMenu"
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tags: state.hubly.data.hub.tags,
    allClients: state.hubly.data.hub.clients.allClientNames,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    removeWorkflowRule: (workflow, workflowRule, callback) => {
      dispatch(RemoveWorkflowRule(workflow, workflowRule, callback));
    },
    setConfirmationModal: (id) => {
      dispatch(SetConfirmationModal(id));
    },
    setEditWorkflowRule: (workflowRule) => {
      dispatch(SetEditWorkflowRule(workflowRule));
    },
    updateWorkflowRule: (workflow, workflowRule, callback) => {
      dispatch(UpdateWorkflowRule(workflow, workflowRule, callback));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RepeatingWorkflowRule);
