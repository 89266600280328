import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  HubContext,
  ToasterContext,
} from "scenes/Hubly/components/Workspace/Provider";
import FilterLayout from "components/FilterLayout";
import Selector from "components/Selector";
import withPrivacyMode from "components/PrivacyMode/WithPrivacyMode";

export function ClientFilters({ selectedClients, onUpdateFilter, className }) {
  const { fireError } = useContext(ToasterContext);
  const { fetchAllClientNames } = useContext(HubContext);

  const [loading, setLoading] = useState(false);
  const [clientsToFilterBy, setClientsToFilterBy] = useState([]);

  useEffect(() => {
    let mounted = true;

    setLoading(true);
    fetchAllClientNames()
      .then((resp) => {
        if (mounted) {
          setClientsToFilterBy(resp);
        }
      })
      .catch((error) => {
        fireError("Failed to load list of clients.", error);
      })
      .finally(() => {
        if (mounted) {
          setLoading(false);
        }
      });

    return () => {
      mounted = false;
    };
  }, [fetchAllClientNames, fireError]);

  return (
    <FilterLayout
      title="Clients"
      onClearFilter={() => {
        onUpdateFilter({ clientFilter: [] });
      }}
    >
      <Selector
        name="Clients"
        dropdownProps={{
          placeholder: "Select client",
          noResultsMessage: "No more clients",
          loading: loading,
          className: `fs-block dd-privacy-mask ${className}`,
        }}
        selection={clientsToFilterBy.map(({ id, name }) => {
          return { key: id, value: name };
        })}
        selected={selectedClients}
        onSelect={(selected) => {
          onUpdateFilter({ clientFilter: selected });
        }}
      />
    </FilterLayout>
  );
}

ClientFilters.propTypes = {
  className: PropTypes.string,
  selectedClients: PropTypes.arrayOf(PropTypes.string),
  onUpdateFilter: PropTypes.func.isRequired,
};

ClientFilters.defaultProps = {
  className: "",
  selectedClients: [],
};

export default withPrivacyMode(ClientFilters);
