import { API } from "aws-amplify";
import SyncedAPI from "data/libs/syncedAPI";
import { getAPIReq } from "./authLib";

export const GetWorkflows = async (hub) => {
  const req = await getAPIReq();
  return API.get("HublyAPI", `/hubs/${hub.id}/workflows/`, req);
};

export const CreateWorkflow = async (request) => {
  const req = await getAPIReq(request);
  return SyncedAPI.post("HublyAPI", `/workflows/`, req, (response) => {
    return { type: "ADD_WORKFLOW", workflow: response };
  });
};

export const BulkUpdateWorkflows = async (request) => {
  const req = await getAPIReq(request);
  return SyncedAPI.put(
    "HublyAPI",
    `/workflows/bulkUpdate/`,
    req,
    (response) => {
      return { type: "BULK_UPDATE_WORKFLOWS", workflows: response };
    }
  );
};

export const GetWorkflow = async (id) => {
  const req = await getAPIReq();
  return API.get("HublyAPI", `/workflows/${id}/`, req);
};

export const DuplicateWorkflow = async (id, destinationHubId) => {
  const req = await getAPIReq({ destinationHubId });
  return SyncedAPI.post("HublyAPI", `/workflows/${id}/duplicate/`, req);
};

export const EditWorkflow = async (id, request) => {
  const req = await getAPIReq(request);
  return SyncedAPI.put("HublyAPI", `/workflows/${id}/`, req, (response) => {
    return { type: "EDIT_WORKFLOW", workflow: response };
  });
};

export const DeleteWorkflow = async (id, deleteClients) => {
  const req = await getAPIReq();
  const queryParam = deleteClients ? `?permanently=true` : "?permanently=false";
  return SyncedAPI.del(
    "HublyAPI",
    `/workflows/${id}/clients/${queryParam}`,
    req,
    () => {
      return { type: "DELETE_WORKFLOW", workflowId: id };
    }
  );
};

export const UpdateWorkflowSortOrder = async (id, request) => {
  const req = await getAPIReq(request);
  return SyncedAPI.put("HublyAPI", `/workflows/${id}/updateSortOrder/`, req);
};

export const CreateWorkflowTile = async (id, request) => {
  const req = await getAPIReq(request);
  return SyncedAPI.post("HublyAPI", `/workflows/${id}/tileSettings/`, req);
};

export const GetWorkflowTileSettings = async (id, tileId) => {
  return API.get("HublyAPI", `/workflows/${id}/tileSettings/${tileId}/`);
};

export const EditWorkflowTileSettings = async (id, tileId, request) => {
  const req = await getAPIReq(request);
  return API.put(
    "HublyAPI",
    `/workflows/${id}/tileSettings/${tileId}/`,
    req,
    (response) => {
      return { type: "SET_WORKFLOW", workflow: response };
    }
  );
};

export const CreateAttachment = async (id, request) => {
  const req = await getAPIReq(request);
  return API.post("HublyAPI", `/workflows/${id}/attachments/`, req);
};

export const GetAttachments = async (id) => {
  const req = await getAPIReq();
  return API.get("HublyAPI", `/workflows/${id}/attachments/`, req);
};

export const DeleteAttachment = async (id, request) => {
  const req = await getAPIReq(request);
  return API.del("HublyAPI", `/workflows/${id}/attachments/`, req);
};

export const CopyAttachmentToAllClients = async (id, request) => {
  const req = await getAPIReq(request);
  return API.post(
    "HublyAPI",
    `/workflows/${id}/copyAttachmentToAllClients/`,
    req
  );
};
