import { range } from "lodash";
import {
  CreateWorkflowRule,
  DeleteWorkflowRule,
  EditWorkflowRule,
} from "data/libs/workflowRules";

export const repeatOptions = [
  { key: "ONCE", text: "Once", value: "ONCE" },
  { key: "EVERY", text: "Every", value: "EVERY" },
];

export const getCombinedStreamsTagsAndClient = (
  streams = [],
  tags = [],
  clients = []
) => {
  const withType = (type) => {
    return (e) => {
      return { ...e, type: type };
    };
  };
  return [
    ...streams.map(withType("stream")),
    ...tags.map(withType("tag")),
    ...clients.map(withType("client")),
  ];
};

export const getIntervalOptions = (interval = 12, start = 1) => {
  return range(start, interval).map((i) => {
    return { key: `${i}`, text: i, value: `${i}` };
  });
};

export const getOnBeforeAfterValue = (interval) => {
  if (interval > 0) {
    return "AFTER";
  }

  if (interval === 0) {
    return "ON";
  }

  return "BEFORE";
};

export const getIntervalMultiplierFromBeforeAfter = (onBeforeAfter) => {
  if (onBeforeAfter === "BEFORE") {
    return -1;
  }

  if (onBeforeAfter === "AFTER") {
    return 1;
  }

  return 0;
};

export const getFrequencyOptions = (
  interval,
  { withYear = true, withDay = false } = {}
) => {
  const frequencyOptions = [
    {
      value: "MONTHLY",
      key: "MONTHLY",
      text: parseInt(Math.abs(interval)) >= 2 ? "Months" : "Month",
    },
    {
      value: "WEEKLY",
      key: "WEEKLY",
      text: parseInt(Math.abs(interval)) >= 2 ? "Weeks" : "Week",
    },
  ];

  if (withDay) {
    frequencyOptions.unshift({
      value: "DAILY",
      key: "DAILY",
      text: parseInt(Math.abs(interval)) >= 2 ? "Days" : "Day",
    });
  }
  if (withYear) {
    frequencyOptions.unshift({
      value: "YEARLY",
      key: "YEARLY",
      text: parseInt(Math.abs(interval)) >= 2 ? "Years" : "Year",
    });
  }

  return frequencyOptions;
};

export const daysOptions = [
  { value: "MO", key: "MO", text: "Mondays" },
  { value: "TU", key: "TU", text: "Tuesdays" },
  { value: "WE", key: "WE", text: "Wednesdays" },
  { value: "TH", key: "TH", text: "Thursdays" },
  { value: "FR", key: "FR", text: "Fridays" },
  { value: "SA", key: "SA", text: "Saturdays" },
  { value: "SU", key: "SU", text: "Sundays" },
];

export const attributeTypeOptions = [
  {
    key: "ADD_ATTRIBUTES",
    text: "Add",
    value: "ADD_ATTRIBUTES",
  },
  {
    key: "REMOVE_ATTRIBUTES",
    text: "Remove",
    value: "REMOVE_ATTRIBUTES",
  },
];

export const ruleActionOptions = [
  {
    key: "ADDED_TO_WORKFLOW",
    text: "is added to",
    value: "ADDED_TO_WORKFLOW",
  },
  {
    key: "ARCHIVED_FROM_WORKFLOW",
    text: "is archived from",
    value: "ARCHIVED_FROM_WORKFLOW",
  },
  {
    key: "COMPLETED_WORKFLOW",
    text: "completes",
    value: "COMPLETED_WORKFLOW",
  },
  {
    key: "REMOVED_FROM_WORKFLOW",
    text: "is removed from",
    value: "REMOVED_FROM_WORKFLOW",
  },
];

export const onBeforeAfterOptions = [
  { value: "ON", key: "ON", text: "On" },
  { value: "BEFORE", key: "BEFORE", text: "Before" },
  { value: "AFTER", key: "AFTER", text: "After" },
];

export const addRule = CreateWorkflowRule;

export const editRule = EditWorkflowRule;

export const deleteRule = DeleteWorkflowRule;

export default addRule;
