import React from "react";
import PropTypes from "prop-types";

import ReactQuill from "react-quill";
import { Header } from "semantic-ui-react";

import { modules } from "../../../../../../helper";

export function DescriptionMarkup({ name, value, onChange, ...props }) {
  return (
    <div style={{ marginBottom: "1em" }}>
      <Header as="h3" style={{ marginBottom: "0.5em" }}>
        Description
      </Header>
      <div>
        <React.Fragment>
          <ReactQuill
            placeholder="Add a description... (optional)"
            name="body"
            defaultValue={value}
            onChange={(content) => {
              onChange(null, { ...props, name: name, value: content });
            }}
            modules={modules}
            style={{ marginBottom: "0.5em" }}
          />
        </React.Fragment>
      </div>
    </div>
  );
}

DescriptionMarkup.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default DescriptionMarkup;
