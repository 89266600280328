import config from "config";

import createMetricPublisher from "./metrics";
import createMiddleware from "./handler";

export default createMiddleware({
  publishers: config.analytics ? createMetricPublisher() : [],
  isEnabled: () => {
    return config.analytics;
  },
});
