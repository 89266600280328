import React, { useContext } from "react";
import PropTypes from "prop-types";

import { ConfirmationModal } from "components/ConfirmationModal";
import {
  ToasterContext,
  HubContext,
} from "scenes/Hubly/components/Workspace/Provider";

export function DeleteClientModal({ clientWfId, onComplete, onClose }) {
  const { removeClientWorkflow } = useContext(HubContext);
  const { fireSuccess, fireError } = useContext(ToasterContext);

  return (
    <ConfirmationModal
      title="Remove Client and Delete Tasks"
      message="Do you want to remove client from the workflow and delete all tasks?  This cannot be undone."
      icon="delete"
      buttons={[
        {
          text: "Cancel",
          callBack: onClose,
        },
        {
          text: "Remove from Workflow",
          color: "red",
          callBack: () => {
            removeClientWorkflow(clientWfId)
              .then(() => {
                if (onComplete) onComplete();
                fireSuccess("Client has been removed successfully");
              })
              .catch(() => {
                fireError("Failed to delete client from workflow");
              })
              .finally(() => {
                onClose();
              });
          },
        },
      ]}
    />
  );
}

DeleteClientModal.propTypes = {
  clientWfId: PropTypes.string.isRequired,
  onComplete: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

DeleteClientModal.defaultProps = {};

export default DeleteClientModal;
