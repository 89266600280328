import React, { useContext } from "react";
import { Grid, Icon, Popup } from "semantic-ui-react";
import PropTypes from "prop-types";
import { PrivacyModeContext } from "components/PrivacyMode/Context";
import { StreamPopup, TagPopup } from "./components";

export function MemberItem({ member, type, onDeleteClick, onViewClick }) {
  const { name, tags = [], streams = [] } = member;
  const { piiMask } = useContext(PrivacyModeContext);

  return (
    <Grid.Row
      data-test={`${type}-member`}
      className={`grey_on_hover rounded p25 ${piiMask(
        "fs-block dd-privacy-mask"
      )}`}
      style={{
        paddingTop: "0.25em",
        paddingBottom: "0.25em",
        marginLeft: "1em",
        marginRight: "1em",
      }}
    >
      <Grid.Column width={6}>
        <div>{name}</div>
      </Grid.Column>
      <Grid.Column width={7}>
        {type === "tag" ? (
          <TagPopup tags={tags} />
        ) : (
          <StreamPopup streams={streams} />
        )}
      </Grid.Column>
      <Grid.Column textAlign="right" width={3}>
        <Popup
          basic
          inverted
          content="View Client Card"
          popperModifiers={{
            preventOverflow: {
              boundariesElement: "window",
              enabled: false,
            },
          }}
          size="mini"
          style={{
            padding: "0.5em",
          }}
          trigger={
            <Icon
              link
              name="user"
              onClick={onViewClick}
              style={{ marginRight: "0.5em" }}
            />
          }
        />
        <Popup
          basic
          inverted
          content={`Remove from ${type}`}
          popperModifiers={{
            preventOverflow: {
              boundariesElement: "window",
              enabled: false,
            },
          }}
          size="mini"
          style={{
            padding: "0.5em",
          }}
          trigger={<Icon link name="delete" onClick={onDeleteClick} />}
        />
      </Grid.Column>
    </Grid.Row>
  );
}

MemberItem.propTypes = {
  member: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  onViewClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
};

export default MemberItem;
