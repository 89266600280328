import React, { useState } from "react";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import { Button, Icon, Input } from "semantic-ui-react";
import MeetingDateTypeDropdown from "components/MeetingDateTypeDropdown";
import { getFormattedDateForInputField } from "./helpers";

const EditingInlineEvent = ({
  setEditing,
  currentDate,
  setCurrentDate,
  loading,
  editEvent,
  eventMeetingDateType,
}) => {
  const formattedDateInput = getFormattedDateForInputField(currentDate);
  const [selectedMeetingDateType, setSelectedMeetingDateType] =
    useState(eventMeetingDateType);

  return (
    <div
      style={{
        marginBottom: "1em",
        width: "max-content",
      }}
      data-test="editing-event-form"
    >
      <MeetingDateTypeDropdown
        selectedMeetingDateType={selectedMeetingDateType}
        setSelectedMeetingDateType={setSelectedMeetingDateType}
        useDefaultValue={false}
      />
      <Input
        disabled
        fluid
        style={{ margin: "1em 0" }}
        value={formattedDateInput}
      />
      <DatePicker
        dateFormat="MMMM d, yyyy h:mm aa"
        inline
        onChange={setCurrentDate}
        selected={currentDate}
        showTimeSelect
        timeFormat="h:mm aa"
        timeIntervals={15}
      />
      <div>
        <Button
          content="Save"
          disabled={loading}
          loading={loading}
          onClick={() => {
            editEvent(selectedMeetingDateType?.id);
          }}
          positive
        />
        <Icon
          color="grey"
          link
          name="delete"
          onClick={() => {
            setEditing(false);
          }}
          size="large"
        />
      </div>
    </div>
  );
};

EditingInlineEvent.propTypes = {
  setEditing: PropTypes.func.isRequired,
  currentDate: PropTypes.object.isRequired,
  setCurrentDate: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  editEvent: PropTypes.func.isRequired,
  eventMeetingDateType: PropTypes.object.isRequired,
};

export default EditingInlineEvent;
