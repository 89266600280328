import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { SetAlert } from "components/Alerts/actions";
import {
  FetchMeetingDateTypes,
  CreateMeetingDateType,
} from "data/libs/meetingDateTypes";
import { MeetingDateTypeContext } from "./Context";
import { findMeetingDateTypeById } from "./helpers";

const MeetingDateTypesDataProvider = ({ hubId, setAlert, children }) => {
  const [meetingDateTypes, setMeetingDateTypes] = useState(undefined);

  const createMeetingDateType = async (name) => {
    try {
      const resp = await CreateMeetingDateType({ name: name }, hubId);
      setMeetingDateTypes([...meetingDateTypes, resp]);
      return resp;
    } catch (err) {
      setAlert({ type: "error", text: "Failed to create new meeting type." });
      console.error(err);
      return undefined;
    }
  };

  const getMeetingDateTypeFromId = (searchId) => {
    return findMeetingDateTypeById(meetingDateTypes, searchId);
  };

  useEffect(() => {
    const callFetch = async () => {
      if (meetingDateTypes === undefined) {
        const resp = await FetchMeetingDateTypes(hubId);
        setMeetingDateTypes(resp);
      }
    };

    callFetch();
  }, [meetingDateTypes, setMeetingDateTypes, hubId]);

  return (
    <MeetingDateTypeContext.Provider
      value={{
        meetingDateTypes: meetingDateTypes,
        createMeetingDateType: createMeetingDateType,
        getMeetingDateTypeFromId: getMeetingDateTypeFromId,
      }}
    >
      {children}
    </MeetingDateTypeContext.Provider>
  );
};

MeetingDateTypesDataProvider.propTypes = {
  hubId: PropTypes.string.isRequired,
  setAlert: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

const mapStateToProps = (state) => {
  return {};
};
export default connect(mapStateToProps, { setAlert: SetAlert })(
  MeetingDateTypesDataProvider
);
