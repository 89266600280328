import React, { Component } from "react";
import { Card, Icon } from "semantic-ui-react";
import PropTypes from "prop-types";

class WorkflowCard extends Component {
  static propTypes = {
    index: PropTypes.number.isRequired,
    workflow: PropTypes.shape({
      description: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      tasks: PropTypes.array.isRequired,
    }).isRequired,
  };

  state = {
    expanded: this.props.index === 0,
  };

  toggleExpanded = () => {
    this.setState((state) => {
      return {
        expanded: !state.expanded,
      };
    });
  };

  render() {
    const { workflow } = this.props;
    const { expanded } = this.state;
    const numberOfTasks = workflow.tasks.length;
    const numberOfReminders = workflow.tasks.reduce((a, b) => {
      return a + (b.reminders || []).length;
    }, 0);

    const chevronName = expanded ? "chevron up" : "chevron down";

    return (
      <Card
        as="div"
        link
        onClick={this.toggleExpanded}
        style={{ width: "auto" }}
      >
        <Card.Content>
          <Icon
            link
            color="grey"
            name={chevronName}
            style={{ float: "right" }}
          />
          <Card.Header>{workflow.name}</Card.Header>
        </Card.Content>
        {expanded ? (
          <Card.Content>
            <span>{workflow.description}</span>
          </Card.Content>
        ) : null}
        <Card.Content extra>
          <div style={{ display: "flex" }}>
            <div>
              <Icon fitted name="check square outline" />
              <span>
                &nbsp;
                {`${numberOfTasks} Tasks`}
              </span>
            </div>
            <div style={{ marginLeft: "1em" }}>
              <Icon fitted name="bell outline" />
              <span>
                &nbsp;
                {`${numberOfReminders} Reminders`}
              </span>
            </div>
          </div>
        </Card.Content>
      </Card>
    );
  }
}

export default WorkflowCard;
