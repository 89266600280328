import React from "react";
import { connect } from "react-redux";
import { isEmail } from "validator";
import { Button, Form, Input } from "semantic-ui-react";
import { SignIn } from "aws-amplify-react";
import PropTypes from "prop-types";
import LoginContainer from "./components/LoginContainer";
import Anchor from "../../components/Anchor";
import {
  SetLoginErrorMessage,
  SetLoginInfoMessage,
  SetLoginSigningIn,
} from "./actions";
import "./Login.css";

class HublySignIn extends SignIn {
  static propTypes = {
    setLoginErrorMessage: PropTypes.func.isRequired,
    setLoginInfoMessage: PropTypes.func.isRequired,
  };

  state = {
    emailLabel: false,
    passwordLabel: false,
    koname: false,
  };

  login = (event) => {
    const {
      setLoginErrorMessage,
      setLoginInfoMessage,
      setLoginSigningIn,
      setEmailAddress,
    } = this.props;
    setLoginErrorMessage("");
    setLoginInfoMessage(null);

    this.inputs.username = (this.inputs.username || "").toLowerCase();
    const { password, username } = this.inputs;
    let valid = true;
    let message = "";
    if (!username || username.length < 1 || !isEmail(username)) {
      message = message.concat("Email is invalid. ");
      valid = false;
    }
    if (!password || password.length < 1) {
      message = message.concat("Password is invalid. ");
      valid = false;
    }
    if (!valid) {
      setLoginErrorMessage(message);
      return;
    }

    setLoginSigningIn(true);

    try {
      super.signIn(event).then((r) => {
        setEmailAddress(username);
      });
    } catch (e) {
      setLoginSigningIn(false);
      console.warn(e.message);
    }
  };

  showComponent() {
    const { setLoginErrorMessage, setLoginInfoMessage } = this.props;
    const { koname } = this.state;
    return (
      <LoginContainer title="Sign in">
        <Form style={{ overflow: "visible" }}>
          <Form.Field>
            <label>Email</label>
            <Input
              id="username"
              key="username"
              name="username"
              placeholder="Email"
              onChange={(e) => {
                if (e.target.value === "upupdowndownleftrightleftrightba") {
                  this.setState({ koname: true });
                }
                this.handleInputChange(e);
              }}
            />
          </Form.Field>
          <Form.Field>
            <label>Password</label>
            <Input
              type="password"
              id="password"
              key="password"
              name="password"
              placeholder="Password"
              onChange={this.handleInputChange}
            />
          </Form.Field>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div>
              Forgot your password? &nbsp;
              <Anchor
                onClick={() => {
                  this.changeState("forgotPassword");
                }}
              >
                Reset it
              </Anchor>
            </div>
            <div>
              <Button floated="right" primary onClick={this.login}>
                Sign in
              </Button>
            </div>
          </div>
          {koname && (
            <Button
              basic
              onClick={() => {
                setLoginErrorMessage("");
                setLoginInfoMessage(null);
                this.changeState("signUp");
              }}
            >
              Sign up for Hubly
            </Button>
          )}
        </Form>
      </LoginContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginErrorMessage: (message) => {
      dispatch(SetLoginErrorMessage(message));
    },
    setLoginInfoMessage: (message) => {
      dispatch(SetLoginInfoMessage(message));
    },
    setLoginSigningIn: (value) => {
      dispatch(SetLoginSigningIn(value));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HublySignIn);
