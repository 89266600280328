import { Button, Dropdown, Modal, Icon, Checkbox } from "semantic-ui-react";
import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import RuleClientsDropdown from "./RuleClientsDropdown";
import { SetEditWorkflowRule } from "../actions";

import {
  getStreamsFromSelectedItems,
  getTagsFromSelectedItems,
  parseBackendStreams,
  parseEditWorkflowTags,
  parseBackendTags,
} from "../helpers";

export const RepeatingRulesModal = ({
  tags,
  editWorkflowRule,
  setEditWorkflowRule,
  setWorkflowRuleModal,
  ...props
}) => {
  const existingStreams = editWorkflowRule.streams
    ? editWorkflowRule.streams
    : [];
  const existingTags = editWorkflowRule.tags ? editWorkflowRule.tags : [];

  const defaultStreams = parseBackendStreams(existingStreams);
  const defaultTags = parseEditWorkflowTags(existingTags, tags);

  const [selectedItems, setSelectedItems] = useState([
    ...defaultStreams,
    ...defaultTags,
  ]);
  const [loading, setLoading] = useState(false);
  const [editedOnlyHouseholdHead, setEditedOnlyHouseholdHead] = useState(false);
  const [onlyHouseholdHead, setOnlyHouseholdHead] = useState(false);
  const [type, setType] = useState(
    editWorkflowRule.type ? editWorkflowRule.type : "ADD_ATTRIBUTES"
  );
  const [action, setAction] = useState(
    editWorkflowRule.action ? editWorkflowRule.action : "COMPLETED_WORKFLOW"
  );

  const getStreamOptions = () => {
    const { hub } = props;
    return parseBackendStreams(
      hub?.streams?.sort((a, b) => {
        return a.name > b.name ? 1 : -1;
      })
    );
  };

  const getTagOptions = () => {
    return parseBackendTags(
      Object.values(tags)?.sort((a, b) => {
        return a.name > b.name ? 1 : -1;
      })
    );
  };

  const handleChange = (e, { name, value }) => {
    if (name === "type") {
      setType(value);
    } else if (name === "action") {
      setAction(value);
    }
  };

  const isValidInput = () => {
    // Differing valid input if editing or adding
    return selectedItems && selectedItems.length > 0;
  };

  const toggleOnlyHouseholdHead = () => {
    // Editing the rule and checkbox has not be clicked, need to set the initial state based on editWorkflowRule.onlyHouseholdHead
    if ("onlyHouseholdHead" in editWorkflowRule && !editedOnlyHouseholdHead) {
      setOnlyHouseholdHead(!editWorkflowRule.onlyHouseholdHead);
      setEditedOnlyHouseholdHead(true);
    } else {
      // Adding a rule or checkbox already edited, just use the state value
      setOnlyHouseholdHead(!onlyHouseholdHead);
      setEditedOnlyHouseholdHead(true);
    }
  };

  const addRule = () => {
    const { save } = props;
    setLoading(true);

    const request = {
      action: action,
      onlyHouseholdHead: onlyHouseholdHead,
      streams: getStreamsFromSelectedItems(selectedItems),
      tags: getTagsFromSelectedItems(selectedItems),
      type: type,
    };
    save(request, (success) => {
      if (success) {
        setWorkflowRuleModal(false);
        setEditWorkflowRule({});
      }
      setLoading(false);
    });
  };

  const updateRule = () => {
    const { update } = props;
    setLoading(true);

    const workflowRule = {
      action: action,
      id: editWorkflowRule.id,
      onlyHouseholdHead: editedOnlyHouseholdHead
        ? onlyHouseholdHead
        : editWorkflowRule.onlyHouseholdHead,
      streams: getStreamsFromSelectedItems(selectedItems),
      tags: getTagsFromSelectedItems(selectedItems),
      type: type,
    };
    update(workflowRule, (success) => {
      if (success) {
        setWorkflowRuleModal(false);
        setEditWorkflowRule({});
      }
      setLoading(false);
    });
  };

  // We do the filtering separately because the Dropdown options attributes requires all options to be included
  // Otherwise, when searching the already selected streams would no longer display in the input box
  const streamOptions = getStreamOptions();
  const tagOptions = getTagOptions();

  return (
    <Modal
      open
      size="tiny"
      style={{ minWidth: "485px" }}
      data-test="addRemoveStreamOrTag-modal"
    >
      <Modal.Header>
        <Icon
          name="delete"
          link
          color="grey"
          style={{ float: "right", position: "relative" }}
          onClick={() => {
            setWorkflowRuleModal(false);
            setEditWorkflowRule({});
          }}
        />
        Add or Remove Stream/Tag Rule
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", marginBottom: "1em" }}>
              <div style={{ width: "7em" }}>
                <Dropdown
                  data-test="ruleModal-dropdown-type"
                  compact
                  fluid
                  name="type"
                  onChange={handleChange}
                  options={[
                    {
                      key: "ADD_ATTRIBUTES",
                      text: "Add",
                      value: "ADD_ATTRIBUTES",
                    },
                    {
                      key: "REMOVE_ATTRIBUTES",
                      text: "Remove",
                      value: "REMOVE_ATTRIBUTES",
                    },
                  ]}
                  selection
                  value={type}
                />
              </div>
              <div>
                <RuleClientsDropdown
                  tagOptions={tagOptions}
                  streamOptions={streamOptions}
                  selectedItems={selectedItems}
                  setSelectedItems={(newSelectedItems) => {
                    setSelectedItems(newSelectedItems);
                  }}
                  data-test="ruleModal-dropdown-condition"
                  fluid
                  style={{ width: "350px", marginLeft: "1em" }}
                />
              </div>
            </div>
            <div style={{ display: "flex", marginBottom: "1em" }}>
              <div style={{ paddingTop: "0.75em " }}>when a client</div>
              <div style={{ width: "11em" }}>
                <Dropdown
                  data-test="ruleModal-dropdown-action"
                  compact
                  fluid
                  name="action"
                  onChange={handleChange}
                  options={[
                    {
                      key: "ADDED_TO_WORKFLOW",
                      text: "is added to",
                      value: "ADDED_TO_WORKFLOW",
                    },
                    {
                      key: "ARCHIVED_FROM_WORKFLOW",
                      text: "is archived from",
                      value: "ARCHIVED_FROM_WORKFLOW",
                    },
                    {
                      key: "COMPLETED_WORKFLOW",
                      text: "completes",
                      value: "COMPLETED_WORKFLOW",
                    },
                    {
                      key: "REMOVED_FROM_WORKFLOW",
                      text: "is removed from",
                      value: "REMOVED_FROM_WORKFLOW",
                    },
                  ]}
                  selection
                  style={{ marginLeft: ".5em" }}
                  value={action}
                />
              </div>
              <div style={{ marginLeft: "1em", paddingTop: "0.75em " }}>
                this workflow
              </div>
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Checkbox
              defaultChecked={
                "onlyHouseholdHead" in editWorkflowRule
                  ? editWorkflowRule.onlyHouseholdHead
                  : onlyHouseholdHead
              }
              label="Only apply to household head"
              name="onlyHouseholdHead"
              data-test="ruleModal-checkbox-onlyHouseholdHead"
              onChange={toggleOnlyHouseholdHead}
            />
          </div>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          primary
          disabled={!isValidInput() || loading}
          loading={loading}
          content={editWorkflowRule.id ? "Edit Rule" : "Add Rule"}
          onClick={editWorkflowRule.id ? updateRule : addRule}
        />
      </Modal.Actions>
    </Modal>
  );
};

RepeatingRulesModal.propTypes = {
  hub: PropTypes.shape({
    streams: PropTypes.array.isRequired,
  }).isRequired,
  editWorkflowRule: PropTypes.shape({
    action: PropTypes.string,
    id: PropTypes.string,
    onlyHouseholdHead: PropTypes.bool,
    streams: PropTypes.array,
    tags: PropTypes.array,
    type: PropTypes.string,
  }).isRequired,
  save: PropTypes.func.isRequired,
  setEditWorkflowRule: PropTypes.func.isRequired,
  tags: PropTypes.object.isRequired,
  setWorkflowRuleModal: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  workflow: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

const mapStateToProps = (state) => {
  return {
    hub: state.hubly.data.hub.selected.hub,
    editWorkflowRule: state.hubly.scenes.hubly.workflowRules.editWorkflowRule,
    workflows: state.hubly.data.hub.workflows,
    tags: state.hubly.data.hub.tags,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setEditWorkflowRule: (workflowRule) => {
      dispatch(SetEditWorkflowRule(workflowRule));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RepeatingRulesModal);
