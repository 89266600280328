import React from "react";
import PropTypes from "prop-types";

import { Icon, Label } from "semantic-ui-react";

export function Layout({
  active,
  streams,
  tags,
  children,
  excludeItemLabels,
  onEdit,
  ruleMenu,
  ...props
}) {
  return (
    <div
      className="grey_on_hover rounded p25"
      style={{ display: "flex", marginBottom: "0.5em", cursor: "pointer" }}
      onDoubleClick={onEdit}
      {...props}
    >
      <div style={{ opacity: active ? "1" : "0.4" }}>
        Clients with &nbsp;
        {streams.length <= 0 && tags.length <= 0 && (
          <span
            style={{ color: "grey" }}
            title="No stream(s) or tag(s) found. Please specify new stream(s) or tag(s) to resume rule."
          >
            N/A
          </span>
        )}
        {streams.map((stream) => {
          return (
            <Label
              key={stream.id}
              style={{
                backgroundColor: stream.color,
                color: "#fff",
                padding: "0.3em 0.4em",
                margin: "0.1em",
              }}
            >
              {stream.name}
            </Label>
          );
        })}
        {tags.map((tag) => {
          return (
            <Label
              key={tag.id}
              style={{
                backgroundColor: "grey",
                color: "#fff",
                padding: "0.3em 0.4em",
                margin: "0.1em",
              }}
            >
              <Icon fitted name="hashtag" />
              &nbsp;
              {tag.name}
            </Label>
          );
        })}
        {children}
        &nbsp;
        {excludeItemLabels}
        {!active && " (Paused)"}
      </div>
      <div style={{ display: "flex", marginLeft: "0.25em" }}>{ruleMenu}</div>
    </div>
  );
}

Layout.propTypes = {
  streams: PropTypes.array,
  tags: PropTypes.array,
  active: PropTypes.bool,
  children: PropTypes.node,
  excludeItemLabels: PropTypes.node,
  onEdit: PropTypes.func.isRequired,
  ruleMenu: PropTypes.node,
};

Layout.defaultProps = {
  active: false,
  streams: [],
  tags: [],
  children: null,
  ruleMenu: null,
  excludeItemLabels: null,
};

export default Layout;
