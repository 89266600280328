import React from "react";
import { Button, Header, Icon, Input, Message, Popup } from "semantic-ui-react";
import { connect } from "react-redux";
import moment from "moment";
import PropTypes from "prop-types";
import {
  AddTimeEstimateToTask,
  RemoveAllTimeEstimates,
  ToggleTaskTimeEstimatePopup,
  UpdateTimeEstimate,
} from "components/Task/actions";
import { SetAlert } from "components/Alerts/actions";

class TaskTimeEstimate extends React.Component {
  static defaultProps = {
    addingTask: false,
    editTaskState: () => {},
    modalTaskId: "",
  };

  static propTypes = {
    addingTask: PropTypes.bool,
    addTimeEstimateToTask: PropTypes.func.isRequired,
    editTaskState: PropTypes.func,
    isTaskTimeEstimateOpen: PropTypes.bool.isRequired,
    modalTaskId: PropTypes.string,
    removeAllTimeEstimates: PropTypes.func.isRequired,
    task: PropTypes.shape({
      id: PropTypes.string.isRequired,
      clientWorkflowId: PropTypes.string,
      timeEstimates: PropTypes.array.isRequired,
    }).isRequired,
    toggleTaskTimeEstimatePopup: PropTypes.func.isRequired,
    updateTimeEstimate: PropTypes.func.isRequired,
    workflow: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    let hours = 0;
    let minutes = 0;

    if (props.task.timeEstimates && props.task.timeEstimates.length > 0) {
      const timeEstimate = moment(
        props.task.timeEstimates[0].timeEstimate,
        "HH:mm:ss"
      );
      hours = timeEstimate.hour();
      minutes = timeEstimate.minute();
    }

    this.state = {
      hours: hours,
      minutes: minutes,
    };
  }

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value, error: false });
  };

  isOpen = () => {
    const { isTaskTimeEstimateOpen, modalTaskId, task } = this.props;
    return task.id === modalTaskId && isTaskTimeEstimateOpen;
  };

  getIntFromInput = (number) => {
    if (Number.isInteger(number)) {
      return parseInt(number);
    }

    return 0;
  };

  addTime = (addHours, addMinutes) => {
    let { hours, minutes } = this.state;

    const totalTime =
      60 * (this.getIntFromInput(hours) + addHours) +
      this.getIntFromInput(minutes) +
      addMinutes;

    hours = Math.floor(totalTime / 60);
    minutes = totalTime % 60;

    this.setState({
      hours: hours,
      minutes: minutes,
    });
  };

  closeCallback = () => {
    const { toggleTaskTimeEstimatePopup } = this.props;
    this.setState({ hours: 0, minutes: 0 });
    toggleTaskTimeEstimatePopup();
  };

  removeTimeEstimates = () => {
    const {
      addingTask,
      editTaskState,
      removeAllTimeEstimates,
      task,
      workflow,
    } = this.props;
    if (addingTask) {
      editTaskState("timeEstimates", [], "edit");
    } else {
      removeAllTimeEstimates(task, workflow, this.closeCallback());
    }
  };

  saveTimeEstimates = () => {
    const { hours, minutes } = this.state;
    const {
      addingTask,
      addTimeEstimateToTask,
      editTaskState,
      task,
      toggleTaskTimeEstimatePopup,
      updateTimeEstimate,
      workflow,
    } = this.props;

    if (parseInt(hours) + parseInt(minutes) < 0) {
      this.setState({ error: true });
      return;
    }

    if (addingTask) {
      const timeEstimatesInState = [
        {
          hours: parseInt(hours),
          minutes: minutes,
          autoAddedInd: false,
          timeEstimate: moment.duration(
            parseInt(hours) * 60 + parseInt(minutes),
            "minute"
          ),
        },
      ];
      editTaskState("timeEstimates", timeEstimatesInState, "edit");
      toggleTaskTimeEstimatePopup();
    } else if (task.timeEstimates && task.timeEstimates.length > 0) {
      updateTimeEstimate(
        task,
        workflow,
        task.timeEstimates[0],
        hours,
        minutes,
        toggleTaskTimeEstimatePopup
      );
    } else {
      // We're creating a new estimate here
      addTimeEstimateToTask(
        task,
        workflow,
        hours,
        minutes,
        toggleTaskTimeEstimatePopup
      );
    }
  };

  save = () => {
    const { task, toggleTaskTimeEstimatePopup } = this.props;
    const { hours, minutes } = this.state;

    // We're deleting in this case
    if (parseInt(hours) === 0 && parseInt(minutes) === 0) {
      if (task.timeEstimates && task.timeEstimates.length > 0) {
        this.removeTimeEstimates();
      } else {
        // There are no estimates to remove here
        toggleTaskTimeEstimatePopup();
      }
    } else {
      this.saveTimeEstimates();
    }
  };

  renderTrigger() {
    const { task, toggleTaskTimeEstimatePopup } = this.props;

    const totalTimeEst = task.timeEstimates.reduce(
      (accumulator, currentValue) => {
        return accumulator.add(moment.duration(currentValue.timeEstimate));
      },
      moment.duration()
    );

    if (totalTimeEst.asMilliseconds() > 0) {
      return (
        <Popup
          on="hover"
          popperModifiers={{
            preventOverflow: { boundariesElement: "window", enabled: false },
          }}
          position="right center"
          style={{ padding: "0.6em", fontSize: "11px", fontWeight: "bold" }}
          trigger={
            <Icon
              onClick={() => {
                toggleTaskTimeEstimatePopup(task.id, true);
              }}
              color="grey"
              name="clock"
              link
            />
          }
        >
          {`Time Estimate: ${
            totalTimeEst.days() * 24 + totalTimeEst.hours()
          }h ${totalTimeEst.minutes()}m`}
        </Popup>
      );
    } else {
      const isOpen = this.isOpen();
      return (
        <Icon.Group
          className={!isOpen && "show_on_hover dark_grey_on_hover"}
          onClick={() => {
            toggleTaskTimeEstimatePopup(task.id, true);
          }}
          style={{ fontSize: "13pt" }}
          title="Time Estimate"
        >
          <Icon
            link
            name="clock outline"
            style={isOpen ? { color: "#aaaaaa", margin: 0 } : { margin: 0 }}
          />
          <Icon
            link
            corner
            name="add"
            style={
              isOpen
                ? { color: "#aaaaaa" }
                : { color: "#aaaaaa", textShadow: "none" }
            }
          />
        </Icon.Group>
      );
    }
  }

  render() {
    const { hours, error, loading, minutes } = this.state;
    const { task, toggleTaskTimeEstimatePopup } = this.props;
    const timeEstimateExists =
      task.timeEstimates && task.timeEstimates.length > 0;

    if (!this.isOpen()) {
      return this.renderTrigger();
    } else {
      return (
        <Popup
          on="click"
          open
          onClose={() => {
            toggleTaskTimeEstimatePopup();
          }}
          onOpen={() => {
            toggleTaskTimeEstimatePopup(task.id, true);
          }}
          popperModifiers={{
            preventOverflow: { boundariesElement: "window", enabled: false },
          }}
          position="bottom center"
          trigger={this.renderTrigger()}
        >
          <div
            style={{
              maxHeight: "500px",
              minWidth: "500px",
              overflowY: "auto",
              alignContent: "stretch",
            }}
          >
            <div style={{ display: "flex" }}>
              <Header as="h4" style={{ marginBottom: "1em" }}>
                {timeEstimateExists
                  ? "Edit Time Estimate"
                  : "Add Time Estimate"}
              </Header>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                paddingTop: "1em",
              }}
            >
              <Message negative hidden={!error}>
                <Message.Header>Error</Message.Header>
                <p>Time spent must not be negative</p>
              </Message>
              <div style={{ marginBottom: "0.25em", display: "flex" }}>
                <div
                  style={{ width: "20%", marginRight: "5px", color: "#9c9c9c" }}
                >
                  <Input
                    display="inline"
                    error={error}
                    fluid
                    maxLength="2"
                    min="0"
                    name="hours"
                    onChange={this.handleChange}
                    type="number"
                    value={hours}
                  />
                  Hours
                </div>
                <div style={{ width: "20%", color: "#9c9c9c" }}>
                  <Input
                    display="inline"
                    error={error}
                    fluid
                    maxLength="2"
                    min="0"
                    name="minutes"
                    onChange={this.handleChange}
                    type="number"
                    value={minutes}
                  />
                  Minutes
                </div>
                <div style={{ marginLeft: "2em", width: "15%" }}>
                  <Button
                    basic
                    compact
                    fluid
                    onClick={() => {
                      this.addTime(0, 15);
                    }}
                    style={{ fontWeight: "bold", padding: "0.9em 0" }}
                  >
                    <Icon name="plus" style={{ margin: 0 }} />
                    15m
                  </Button>
                </div>
                <div style={{ marginLeft: "0.5em", width: "15%" }}>
                  <Button
                    basic
                    compact
                    fluid
                    onClick={() => {
                      this.addTime(0, 30);
                    }}
                    style={{ fontWeight: "bold", padding: "0.9em 0" }}
                  >
                    <Icon name="plus" style={{ margin: 0 }} />
                    30m
                  </Button>
                </div>
                <div style={{ marginLeft: "0.5em", width: "15%" }}>
                  <Button
                    basic
                    compact
                    fluid
                    onClick={() => {
                      this.addTime(1, 0);
                    }}
                    style={{ fontWeight: "bold", padding: "0.9em 0" }}
                  >
                    <Icon name="plus" style={{ margin: 0 }} />
                    1h
                  </Button>
                </div>
                <div style={{ marginLeft: "0.5em", width: "15%" }}>
                  <Button
                    basic
                    compact
                    fluid
                    onClick={() => {
                      this.addTime(3, 0);
                    }}
                    style={{ fontWeight: "bold", padding: "0.9em 0" }}
                  >
                    <Icon name="plus" style={{ margin: 0 }} />
                    3h
                  </Button>
                </div>
              </div>
            </div>
            <div style={{ marginTop: "1em" }}>
              <Button
                content={timeEstimateExists ? "Save" : "Add"}
                disabled={loading}
                floated="right"
                onClick={() => {
                  this.save();
                }}
                positive
                loading={loading}
              />
              {timeEstimateExists && (
                <Button
                  content="Remove"
                  disabled={loading}
                  loading={loading}
                  negative
                  onClick={this.removeTimeEstimates}
                  style={{ float: "left" }}
                />
              )}
            </div>
          </div>
        </Popup>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    isTaskTimeEstimateOpen: state.hubly.task.isTaskTimeEstimateOpen,
    modalTaskId: state.hubly.task.modalTaskId,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    addTimeEstimateToTask: (task, workflow, hours, minutes, callback) => {
      dispatch(AddTimeEstimateToTask(task, workflow, hours, minutes, callback));
    },
    removeAllTimeEstimates: (task, workflow, callback) => {
      dispatch(RemoveAllTimeEstimates(task, workflow, callback));
    },
    setAlert: (alert) => {
      dispatch(SetAlert(alert));
    },
    toggleTaskTimeEstimatePopup: (taskId, isOpen) => {
      dispatch(ToggleTaskTimeEstimatePopup(taskId, isOpen));
    },
    updateTimeEstimate: (
      task,
      workflow,
      estimate,
      hours,
      minutes,
      callback
    ) => {
      dispatch(
        UpdateTimeEstimate(task, workflow, estimate, hours, minutes, callback)
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskTimeEstimate);
