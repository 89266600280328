import React from "react";

import { GetAllClientWorkflowsByClientId } from "data/hub/clientWorkflows/actions";

import useNewHublyApp from "scenes/Hubly/useNewHublyApp";
import { getClientWorkflowsByClientId } from "../../../Workspace/api";

async function resolveClientWorkflow({ hub, clientId }) {
  const clientWorkflows = await getClientWorkflowsByClientId({
    hubId: hub.hubId,
    clientId: clientId,
  });
  return [clientWorkflows, true];
}

async function resolveLegacyClientWorkflow({
  hub,
  clientId,
  skippedLegacy = false,
}) {
  if (skippedLegacy) return [[], false];
  const clientWorkflows = await GetAllClientWorkflowsByClientId(
    { id: hub.id },
    clientId
  );
  return [clientWorkflows, true];
}

export function withClientWorkflowResolver(Component) {
  return (props) => {
    const { loadingConfig, newApp } = useNewHublyApp();

    if (loadingConfig) return null;

    const resolve = newApp
      ? resolveClientWorkflow
      : resolveLegacyClientWorkflow;

    return (
      <Component
        {...props}
        resolveClientWorkflow={resolve}
        showCwfLoader={newApp}
      />
    );
  };
}

export default withClientWorkflowResolver;
