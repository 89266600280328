import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import DragDropList from "components/DragDropList";
import SummaryRow from "./components/SummaryRow";
import { filterNameByKeyword } from "../../helpers";

export function ProcessWorkflowList({
  totalClient,
  active,
  filteredReminderCounts,
  processWorkflows,
  id,
  children,
  isWorkflowListShown,
  onWorkflowReordered,
  onProcessTileStateUpdated,
  filteredKeyword,
}) {
  const [filteredWorkflows, setFilteredWorkflows] = useState(processWorkflows);
  useEffect(() => {
    setFilteredWorkflows(
      processWorkflows.filter((workflow) => {
        return filterNameByKeyword(workflow.name, filteredKeyword);
      })
    );
  }, [processWorkflows, filteredKeyword]);
  const totalWorkflow = processWorkflows.length;
  return (
    <div style={{ color: "grey" }}>
      <SummaryRow
        active={active}
        onClick={() => {
          onProcessTileStateUpdated(id, {
            isWorkflowListShown: !isWorkflowListShown,
          });
        }}
        opened={isWorkflowListShown}
        totalWorkflows={totalWorkflow}
        totalClients={totalClient}
        filteredReminderCounts={filteredReminderCounts}
      />
      {isWorkflowListShown && totalWorkflow > 0 && (
        <div
          data-test="process-workflows-list"
          style={{ display: "block", width: "100%", marginTop: "1em" }}
        >
          <DragDropList
            id={`dragDropWorkflowWithProcessList-${id}`}
            items={filteredWorkflows}
            onListReordered={onWorkflowReordered}
          >
            {(workflow, dragHandleProps) => {
              return children(workflow, dragHandleProps);
            }}
          </DragDropList>
        </div>
      )}
    </div>
  );
}

ProcessWorkflowList.defaultProps = {
  active: true,
  isWorkflowListShown: false,
  processWorkflows: [],
  filteredKeyword: "",
  onWorkflowReordered: () => {},
  onProcessTileStateUpdated: () => {},
  children: () => {},
  id: "",
};

ProcessWorkflowList.propTypes = {
  id: PropTypes.string,
  filteredKeyword: PropTypes.string,
  processWorkflows: PropTypes.array,
  active: PropTypes.bool,
  isWorkflowListShown: PropTypes.bool,
  children: PropTypes.func,
  totalClient: PropTypes.number.isRequired,
  filteredReminderCounts: PropTypes.object.isRequired,
  onWorkflowReordered: PropTypes.func,
  onProcessTileStateUpdated: PropTypes.func,
};

export default ProcessWorkflowList;
