import React, { useState, useContext, useCallback } from "react";
import PropTypes from "prop-types";

import { range } from "lodash";
import { getMilliseconds } from "date-fns";
import { datadogRum } from "@datadog/browser-rum";

import { HubContext } from "scenes/Hubly/components/Workspace/Provider";

import DraggableTilePlaceholder from "components/DraggableTile/components/DraggableTilePlaceholder";

import { useEditorState, useEditorData } from "../hooks";

import {
  WorkflowMenuButton,
  WorkflowMenuLayout,
  TopButtons,
  SearchInput,
  ProcessSettingModal,
  ProcessList,
  WorkflowList,
  WorkflowTilePlaceholder,
} from "./components";

import "./WorkflowMenu.css";
import {
  getActiveWorkflows,
  getAllWorkflows,
  getWorkflowsWithNoProcessEmptyText,
} from "./helper";

export function WorkflowMenu({ selectedWorkflows, onChange }) {
  const { fetchProcesses } = useContext(HubContext);
  const [popupOpen, setPopupOpen] = useState(false);
  const [spentTimeOnWorkfilteOpen, setSpentTimeOnWorkfilteOpen] = useState(0);
  const [settingProps, setSettingProps] = useState(null);
  const [filteredKeyword, setFilteredKeyword] = useState("");

  const [processes, dispatchProcesses] = useEditorState();
  const [workflows, dispatchWorkflows] = useEditorState();

  const { loading, reloadData, numOfFetches } = useEditorData(fetchProcesses, {
    dispatch: useCallback(
      ({ type, value: [p, w] }) => {
        dispatchProcesses({ type: type, value: p });
        dispatchWorkflows({ type: type, value: w });
      },
      [dispatchProcesses, dispatchWorkflows]
    ),
  });

  const hubWorkflowsTotal =
    workflows.length +
    processes.reduce((total, process) => {
      return total + process.workflows.length;
    }, 0);

  return (
    <React.Fragment>
      <WorkflowMenuLayout
        opened={popupOpen}
        onOpen={() => {
          setSpentTimeOnWorkfilteOpen(new Date().getTime());
          setPopupOpen(true);
          reloadData();
        }}
        onMount={() => {
          datadogRum.addAction(
            "hubly_timespent_workflowfilter_display_workflowprocesmenu",
            { duration: getMilliseconds(spentTimeOnWorkfilteOpen) }
          );
          setSpentTimeOnWorkfilteOpen(0);
        }}
        onClose={() => {
          setPopupOpen(false);
        }}
        trigger={
          <WorkflowMenuButton totalWorkflow={selectedWorkflows.length} />
        }
      >
        <TopButtons
          disabled={hubWorkflowsTotal === 0}
          showAll={selectedWorkflows.length < hubWorkflowsTotal}
          onShowAllClick={(showAll) => {
            onChange(showAll ? getAllWorkflows(processes, workflows) : []);
          }}
          onShowActiveClick={() => {
            onChange(getActiveWorkflows(processes, workflows));
          }}
          onCreateProcessClick={() => {
            setSettingProps({ showDelete: false, process: null });
          }}
        />
        <SearchInput value={filteredKeyword} onChange={setFilteredKeyword} />
        <ProcessList
          processes={processes}
          loading={loading}
          placeholder={
            numOfFetches === 0 &&
            loading &&
            range(6).map((i) => {
              return <DraggableTilePlaceholder key={`processTile${i}`} />;
            })
          }
          onReordered={(orderedProcesses) => {
            dispatchProcesses({ type: "set", value: orderedProcesses });
          }}
          onEditProcessClick={(process) => {
            setSettingProps({ showDelete: false, process: process });
          }}
          onDeleteProcessClick={(process) => {
            setSettingProps({ showDelete: true, process: process });
          }}
          selectedWorkflows={selectedWorkflows}
          filteredKeyword={filteredKeyword}
          onChange={onChange}
        >
          {(process) => {
            return (
              <WorkflowList
                id="process-workflows"
                selectedWorkflows={selectedWorkflows}
                filteredKeyword={filteredKeyword}
                workflows={process.workflows}
                onReordered={(orderedWorkflows) => {
                  dispatchProcesses({
                    type: "edit",
                    value: { ...process, workflows: orderedWorkflows },
                  });
                }}
                onChange={onChange}
                onClientsAdded={reloadData}
              />
            );
          }}
        </ProcessList>
        <WorkflowList
          id="workflow-with-no-process"
          selectedWorkflows={selectedWorkflows}
          filteredKeyword={filteredKeyword}
          workflows={workflows}
          loading={loading}
          showHeader
          placeholder={
            numOfFetches === 0 &&
            loading &&
            range(3).map((i) => {
              return <WorkflowTilePlaceholder key={`workflowTile${i}`} />;
            })
          }
          emptyText={getWorkflowsWithNoProcessEmptyText(
            hubWorkflowsTotal,
            filteredKeyword,
            workflows.length
          )}
          onReordered={(orderedWorkflows) => {
            dispatchWorkflows({
              type: "set",
              value: orderedWorkflows,
            });
          }}
          onChange={onChange}
          onClientsAdded={reloadData}
        />
      </WorkflowMenuLayout>
      {settingProps && (
        <ProcessSettingModal
          {...settingProps}
          onCreateProcess={reloadData}
          onUpdateProcess={reloadData}
          onDeleteProcess={reloadData}
          onClose={setSettingProps}
        />
      )}
    </React.Fragment>
  );
}

WorkflowMenu.propTypes = {
  selectedWorkflows: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default WorkflowMenu;
