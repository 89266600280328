import { matchSorter } from "match-sorter";
import { datadogRum } from "@datadog/browser-rum";

export const recordDatadogContactAction = (
  actionName,
  createdTimestamp,
  prefix = ""
) => {
  const actionPrefix = prefix || "hubly_contact_find";
  const duration = new Date().getTime() - parseInt(createdTimestamp, 10);
  datadogRum.addAction(`${actionPrefix}_${actionName}`, { duration: duration });
};

export const clientNameFilter = (
  { firstname, lastname, middlename, nickname, fullname },
  query
) => {
  const nameCombinationForStartsWith = [
    `${firstname || ""}${firstname ? " " : ""}${lastname || ""}`,
    `${lastname || ""}${lastname ? " " : ""}${firstname || ""}`,
    `${nickname || ""}${nickname ? " " : ""}${lastname || ""}`,
    `${middlename || ""}${middlename ? " " : ""}${lastname || ""}`,
    `${firstname || ""}${firstname ? " " : ""}${middlename || ""}${
      middlename ? " " : ""
    }${lastname || ""}`,
    `${lastname || ""}${lastname ? " " : ""}${firstname || ""}${
      firstname ? " " : ""
    }${middlename || ""}`,
    nickname ? fullname.replace(`"${nickname}"`, nickname) : fullname,
  ];

  return (
    nameCombinationForStartsWith.some((name) => {
      return name && name.toLowerCase().startsWith(query.toLowerCase());
    }) ||
    (fullname && fullname.toLowerCase() === query.toLowerCase())
  );
};
export const fuzzyWordMatch = (options, keys, filterKeys) => {
  if (filterKeys?.length === 0) {
    return options;
  }

  const terms = filterKeys.replace(/\s\s+/g, " ").trim().split(" ");
  if (terms.length === 1 && terms[0] === "") {
    return options;
  }

  const sortedByFirstWordMatch = (results, term) => {
    const matchOptions = {
      keys: keys,
      threshold: matchSorter.rankings.CONTAINS,
    };
    return matchSorter(results, term, matchOptions);
  };

  return terms.reduceRight(sortedByFirstWordMatch, options);
};
export default recordDatadogContactAction;
