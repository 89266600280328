import React from "react";
import { Header, Placeholder, Dropdown, Icon } from "semantic-ui-react";
import PropTypes from "prop-types";
import MarkedText from "components/MarkedText";
import { range } from "lodash";
import { v4 } from "uuid";
import ChevronOption from "scenes/Hubly/components/TopMenu/components/ContactSearch/components/ChevronOption";

export function DropdownItemSection({
  openList,
  loading,
  headerText,
  emptyText,
  collapsible,
  filterKeyword,
  items,
  onItemClick,
  onSectionExpanded,
}) {
  const showList = openList || !collapsible || filterKeyword !== "";
  const showHeader = emptyText || filterKeyword === "";

  return (
    <React.Fragment>
      {headerText &&
        showHeader &&
        (collapsible ? (
          <Dropdown.Item
            key={v4()}
            type="header"
            value="header"
            onClick={() => {
              if (filterKeyword === "") {
                onSectionExpanded(!openList ? headerText : "");
              }
            }}
          >
            <ChevronOption text={headerText} chevronOpened={showList} />
          </Dropdown.Item>
        ) : (
          <Dropdown.Item key={v4()} type="header" value="header">
            <Header as="h5">{headerText}</Header>
          </Dropdown.Item>
        ))}

      {showList &&
        (items.length > 0 ? (
          <React.Fragment>
            {items.map(({ icon = null, ...options }) => {
              return (
                <Dropdown.Item
                  {...options}
                  onClick={(_, { value }) => {
                    onItemClick(value);
                  }}
                >
                  <div style={{ display: "flex" }}>
                    {icon && (
                      <Icon name={icon.name} style={{ color: icon.color }} />
                    )}
                    <div style={{ marginLeft: "0.5em" }}>
                      <MarkedText text={options.name} keyword={filterKeyword} />
                    </div>
                  </div>
                </Dropdown.Item>
              );
            })}
          </React.Fragment>
        ) : (
          emptyText &&
          !loading && (
            <Dropdown.Item
              key={`no-matching-${v4()}`}
              value={`no-matching-${v4()}`}
              disabled
            >
              <span style={{ color: "grey" }}>{emptyText}</span>
            </Dropdown.Item>
          )
        ))}
      {loading &&
        range(3).map(() => {
          return (
            <Dropdown.Item key={`loading-${v4()}`} disabled>
              <Placeholder>
                <Placeholder.Header>
                  <Placeholder.Line length="very long" />
                </Placeholder.Header>
              </Placeholder>
            </Dropdown.Item>
          );
        })}
    </React.Fragment>
  );
}

DropdownItemSection.defaultProps = {
  loading: false,
  headerText: "",
  collapsible: false,
  searchable: false,
  items: [],
  filterKeyword: "",
  emptyText: "",
  showLoadMore: false,
  openList: false,
  onSectionExpanded: () => {},
  onLoadMoreItems: () => {},
};

DropdownItemSection.propTypes = {
  loading: PropTypes.bool,
  openList: PropTypes.bool,
  headerText: PropTypes.string,
  emptyText: PropTypes.string,
  filterKeyword: PropTypes.string,
  collapsible: PropTypes.bool,
  searchable: PropTypes.bool,
  items: PropTypes.array,
  showLoadMore: PropTypes.bool,
  onLoadMoreItems: PropTypes.func,
  onSectionExpanded: PropTypes.func,
  onItemClick: PropTypes.func.isRequired,
};

export default DropdownItemSection;
