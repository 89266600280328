import React from "react";
import PropTypes from "prop-types";

import { Icon } from "semantic-ui-react";
import Spinner from "components/Spinner";
import InfiniteScroll from "components/InfiniteScroll";

import { Layout, Reminder, Loader, EmptyFeed } from "./components";

export function FeedContent({ reminders }) {
  if (reminders.loading && reminders.fetchCount === 0) {
    return <Loader>Loading your Hub Feed...</Loader>;
  }

  if (reminders.isEmpty) {
    return (
      <EmptyFeed>
        <React.Fragment>
          You have no active reminders.
          <br />
          New reminders will appear here.
        </React.Fragment>
      </EmptyFeed>
    );
  }

  return (
    <Layout>
      <InfiniteScroll
        loadingPage={reminders.loading}
        hasMorePages={reminders.hasMorePages}
        onLoadMore={reminders.readNext}
        noMoreElement={<Layout.Item>No more reminders</Layout.Item>}
        pullNextPageElement={
          <Layout.Item>
            Scroll down to load more
            <Icon name="angle down" />
          </Layout.Item>
        }
        loadingElement={
          <Layout.Item>
            <Spinner active />
          </Layout.Item>
        }
      >
        {reminders.data().map((r) => {
          return <Reminder key={r.id} r={r} processing={r.isProcessing} />;
        })}
      </InfiniteScroll>
    </Layout>
  );
}

FeedContent.propTypes = {
  reminders: PropTypes.object.isRequired,
};

export default FeedContent;
