import { SetHub } from "data/hub/actions";
import { SetWorkflows } from "data/hub/workflows/actions";
import { DeleteStream, EditStream } from "data/libs/streams";
import { SetActiveClient } from "data/hub/clients/actions";
import {
  CreateClientStream,
  DeleteClientStream,
  GetClient,
} from "data/libs/clients";
import { SetAlert } from "../Alerts/actions";

export const ToggleStreamModal = (stream, isManageStream = false) => {
  return {
    type: "TOGGLE_STREAM_MODAL",
    stream: stream,
    isManageStream: isManageStream,
  };
};

export const DisableConfirmationModal = () => {
  return {
    type: "DISABLE_STREAM_CONFIRMATION",
  };
};

export function RemoveStream(stream, callback) {
  return (dispatch, getState) => {
    DeleteStream(stream.id)
      .then((response) => {
        const { hub } = getState().hubly.data.hub.selected;
        const { workflows } = getState().hubly.data.hub;
        const { activeClients } = getState().hubly.data.hub.clients;

        const hubCopy = { ...hub };
        const workflowsCopy = Object.values(workflows);
        hubCopy.streams = hubCopy.streams.filter((s) => {
          return s.id !== stream.id;
        });
        dispatch(SetHub(hubCopy));

        Object.values(activeClients).forEach((client) => {
          const clientCopy = { ...client };
          clientCopy.streams = (clientCopy.streams || []).filter((s) => {
            return s.id !== stream.id;
          });
          dispatch(SetActiveClient(clientCopy));
        });

        // Need to update streams for all workflow rules
        workflowsCopy.forEach((workflow) => {
          workflow.workflowRules.forEach((workflowRule, index, array) => {
            array[index].streams = workflowRule.streams.filter((s) => {
              return s.id !== stream.id;
            });
          });
        });
        dispatch(SetWorkflows(workflowsCopy));
        if (callback) callback(true);
      })
      .catch((error) => {
        console.error(error);
        dispatch(
          SetAlert({
            type: "error",
            text: `Failed to delete stream ${stream.name}.`,
          })
        );
        if (callback) callback(false);
      });
  };
}

export function EditStreamAction(
  stream,
  { streamName, streamColor },
  callback
) {
  return (dispatch, getState) => {
    const { hub } = getState().hubly.data.hub.selected;
    const { workflows } = getState().hubly.data.hub;
    const { activeClients } = getState().hubly.data.hub.clients;
    const hubCopy = { ...hub };

    const workflowsCopy = Object.values(workflows);
    const streamNameChanged = stream.name !== streamName;
    const streamColorChanged = stream.color !== streamColor;
    // Make sure name does not already exist (except the stream that we're editing)
    const foundStreamName = hub.streams.find((s) => {
      return s.name === streamName && streamNameChanged;
    });

    if (foundStreamName) {
      dispatch(
        SetAlert({
          type: "warning",
          text: `Unable to create stream. Stream name already exists.`,
        })
      );
      if (callback) callback(false);
      return;
    }

    // Set state locally for edit stream changes
    const foundStream = hubCopy.streams.find((s) => {
      return s.id === stream.id;
    });
    foundStream.color = streamColor;
    foundStream.name = streamName;
    dispatch(SetHub(hubCopy));

    Object.values(activeClients).forEach((client) => {
      if (client.streams) {
        const foundClientStream = client.streams.find((s) => {
          return s.id === stream.id;
        });
        if (foundClientStream) {
          foundClientStream.color = streamColor;
          foundClientStream.name = streamName;
          dispatch(SetActiveClient({ ...client }));
        }
      }
    });

    // Need to update streams for all workflow rules
    workflowsCopy.forEach((workflow) => {
      workflow.workflowRules.forEach((workflowRule) => {
        workflowRule.streams.forEach((s, index, array) => {
          if (s.id === stream.id) {
            array[index].color = streamColor;
            array[index].name = streamName;
          }
        });
      });
    });
    dispatch(SetWorkflows(workflowsCopy));

    const request = {
      color: streamColor,
      name: streamName,
    };
    if (streamColorChanged || streamNameChanged) {
      EditStream(stream.id, request)
        .then((response) => {
          if (callback) callback(true);
        })
        .catch((error) => {
          dispatch(
            SetAlert({
              type: "error",
              text: `Failed to edit stream ${stream.name}.`,
            })
          );
          if (callback) callback(false);
        });
    } else if (callback) callback(true);
  };
}

export function AddClientToStream(clientId, stream, callback) {
  return (dispatch, getState) => {
    const request = {
      id: stream.id,
    };
    const { activeClients } = getState().hubly.data.hub.clients;
    CreateClientStream(clientId, request)
      .then(async (response) => {
        let client = activeClients[clientId];
        if (!client) {
          client = await GetClient(clientId);
          dispatch(SetActiveClient(client));
          if (callback) callback(client);
        } else {
          const foundClient = { ...client };
          foundClient.streams.push(response);
          dispatch(SetActiveClient(foundClient));
          if (callback) callback(foundClient);
          dispatch(
            SetAlert({
              type: "success",
              text: `Successfully added stream`,
            })
          );
        }
      })
      .catch((error) => {
        console.error(error);
        dispatch(
          SetAlert({
            type: "error",
            text: `Failed to add client to stream ${stream.name}.`,
          })
        );
        if (callback) {
          callback(null);
        }
      });
  };
}

export function RemoveClientFromStream(client, stream, callback) {
  return (dispatch, getState) => {
    const { activeClients } = getState().hubly.data.hub.clients;
    DeleteClientStream(client.id, stream.id)
      .then((response) => {
        const clientCopy = activeClients[client.id];
        clientCopy.streams = clientCopy.streams.filter((s) => {
          return s.id !== stream.id;
        });
        if (callback) callback(true);
        dispatch(SetActiveClient(Object.values(activeClients)));
        dispatch(
          SetAlert({
            type: "success",
            text: `Successfully removed stream`,
          })
        );
      })
      .catch((error) => {
        console.error(error);
        dispatch(
          SetAlert({
            type: "error",
            text: `Failed to remove client from ${stream.name}.`,
          })
        );
        if (callback) callback(false);
      });
  };
}

export default ToggleStreamModal;
