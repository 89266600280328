import React, { useContext } from "react";
import PropTypes from "prop-types";

import { Header, Divider } from "semantic-ui-react";

import { useDelay } from "hooks";
import { PopupButton } from "components/PopupButton/PopupButton";

import { HubContext } from "scenes/Hubly/components/Workspace/Provider";

import AssigneeFilters from "./components/AssigneeFilters";
import ReminderFilters from "./components/ReminderFilters";
import ClientFilters from "./components/ClientFilters";
import MoreFilters from "./components/MoreFilters";
import ManyToManyFilters from "./components/ManyToManyFilters";

export function Filters({ highlight, selectedFilters, onChange }) {
  const { fetchStreams, fetchTags, advisors } = useContext(HubContext);

  const delayCallback = useDelay(500);
  const handleUpdateFilter = (slicedFilter) => {
    delayCallback(() => {
      onChange({ ...selectedFilters, ...slicedFilter });
    });
  };

  const getAssigneeDisplayName = () => {
    if (selectedFilters?.assigneeFilterType === "CURRENT") return "you";

    const assignee = advisors.find(({ id }) => {
      return id === selectedFilters?.assignee;
    });

    return assignee ? `${assignee.firstName} ${assignee.lastName}` : null;
  };

  return (
    <PopupButton
      label="Filters"
      icon="filter"
      highlight={highlight}
      data-test="filter-menu-button"
    >
      <Header
        as="h1"
        style={{ padding: "10px 15px", fontSize: "14pt", margin: "0px" }}
      >
        Filters
      </Header>
      <Divider style={{ margin: "0px" }} />
      <div style={{ padding: "15px" }}>
        <AssigneeFilters
          assigneeFilterType={selectedFilters.assigneeFilterType}
          assignee={selectedFilters.assignee}
          onUpdateFilter={handleUpdateFilter}
        />
        <ClientFilters
          selectedClients={selectedFilters.clientFilter}
          onUpdateFilter={handleUpdateFilter}
        />
        <ReminderFilters
          reminderTypes={selectedFilters.reminderTypes}
          onUpdateFilter={handleUpdateFilter}
        />
        <ManyToManyFilters
          type="stream"
          fetchData={fetchStreams}
          selected={selectedFilters.streams}
          filterType={selectedFilters.streamFilterType}
          onUpdateFilter={handleUpdateFilter}
        />
        <ManyToManyFilters
          type="tag"
          fetchData={fetchTags}
          selected={selectedFilters.tags}
          filterType={selectedFilters.tagFilterType}
          onUpdateFilter={handleUpdateFilter}
        />
        <MoreFilters
          filterNextTaskForAssignee={selectedFilters.filterNextTaskForAssignee}
          filterEmptyWorkflows={selectedFilters.filterEmptyWorkflows}
          onUpdateFilter={handleUpdateFilter}
          assigneeDisplayName={getAssigneeDisplayName()}
        />
      </div>
    </PopupButton>
  );
}

Filters.defaultProps = {
  highlight: false,
};

Filters.propTypes = {
  onChange: PropTypes.func.isRequired,
  selectedFilters: PropTypes.object.isRequired,
  highlight: PropTypes.bool,
};

export default Filters;
