import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ManageHouseholdModal from "components/ManageHouseholdModal";
import ReminderRescheduleModal from "components/ReminderRescheduleModal";
import StreamModal from "components/StreamModal";
import TagModal from "components/TagModal";
import ViewModal from "components/ViewModal";
import ClientCardModal from "./components/ClientCard";
import ExportAuditTrail from "./components/ClientCard/components/CardWorkflows/components/ExportAuditTrail";

class HubModals extends React.Component {
  static defaultProps = {
    auditOpen: false,
    createHouseholdModalOpen: false,
    householdId: "",
    reminderRescheduleModalOpen: false,
    stream: {},
    tag: {},
    viewModalOpen: false,
  };

  static propTypes = {
    auditOpen: PropTypes.bool,
    createHouseholdModalOpen: PropTypes.bool,
    householdId: PropTypes.string,
    match: PropTypes.shape({
      params: PropTypes.object.isRequired,
      path: PropTypes.string.isRequired,
    }).isRequired,
    reminder: PropTypes.shape({
      time: PropTypes.string,
      taskId: PropTypes.string,
      title: PropTypes.string,
      id: PropTypes.string,
    }).isRequired,
    reminderRescheduleModalOpen: PropTypes.bool,
    stream: PropTypes.object,
    tag: PropTypes.object,
    viewModalOpen: PropTypes.bool,
  };

  render() {
    const {
      auditOpen = false,
      createHouseholdModalOpen = false,
      householdId = "",
      match = {},
      reminder = {},
      reminderRescheduleModalOpen = false,
      stream = {},
      tag = {},
      viewModalOpen = false,
    } = this.props;
    const { clientId, workflowId } = match.params;

    return (
      <React.Fragment>
        {clientId && (
          <ClientCardModal clientId={clientId} workflowId={workflowId} />
        )}
        {createHouseholdModalOpen && (
          <ManageHouseholdModal clientId={clientId} householdId={householdId} />
        )}
        {auditOpen && <ExportAuditTrail clientId={clientId} />}
        {reminderRescheduleModalOpen && (
          <ReminderRescheduleModal reminder={reminder} />
        )}
        {stream && stream.id && <StreamModal />}
        {tag && tag.id && <TagModal />}
        {viewModalOpen && <ViewModal />}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    advisor: state.hubly.data.advisor,
    auditOpen: state.hubly.scenes.hubly.hubModals.audit.auditOpen,
    createHouseholdModalOpen:
      state.hubly.createHousehold.createHouseholdModalOpen,
    householdId: state.hubly.createHousehold.householdId,
    reminder: state.hubly.reminderReschedule.reminder,
    reminderRescheduleModalOpen:
      state.hubly.reminderReschedule.reminderRescheduleModalOpen,
    stream: state.hubly.streams.stream,
    tag: state.hubly.tags.tag,
    viewModalOpen: state.hubly.viewModal.viewModalOpen,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(HubModals);
