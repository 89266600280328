import React from "react";
import PropTypes from "prop-types";

export default function OptionList({ children }) {
  const style = {
    display: "grid",
    gridTemplateColumns: "auto auto auto",
    alignItems: "center",
    padding: "15px",
    gridRowGap: "1em",
  };

  return <div style={style}>{children}</div>;
}

OptionList.propTypes = {
  children: PropTypes.node,
};

OptionList.defaultProps = {
  children: null,
};
