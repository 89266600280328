import React from "react";
import { Button, Icon, Input, List, Popup } from "semantic-ui-react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { RemoveClientTag, UpdateTagForClient } from "data/hub/tags/actions";

import config from "config";

import { publishClientWorkflowsUpdate } from "../../actions";

const cardSpecialTags = {
  blind: "blind",
  deaf: "deaf",
  child: "child",
  trucker: "truck",
  pilot: "plane",
  male: "male",
  female: "female",
  astronaut: "rocket",
  lumberjack: "tree",
  vancouver: "umbrella",
  doctor: "user md",
  cyclist: "bicycle",
  mechanic: "wrench",
};
class CardTag extends React.Component {
  // Workaround to disable eslint prettier/prettier error
  static specialTags = {
    ...cardSpecialTags,
    "hand lizard": "hand lizard",
    "high value": "dollar sign",
  };

  static propTypes = {
    advisor: PropTypes.shape({
      id: PropTypes.string.isRequired,
      integrations: PropTypes.array.isRequired,
    }).isRequired,
    hub: PropTypes.object.isRequired,
    removeClientTag: PropTypes.func.isRequired,
    tag: PropTypes.shape({
      id: PropTypes.string.isRequired,
      externalId: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    updateTagForClient: PropTypes.func.isRequired,
    client: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      editing: false,
      menuPopupOpen: false,
      name: props.tag && props.tag.name,
    };
  }

  toggleMenuPopup = () => {
    this.setState((state) => {
      return { menuPopupOpen: !state.menuPopupOpen };
    });
  };

  deleteTag = () => {
    const { client, tag, removeClientTag } = this.props;

    this.toggleMenuPopup();
    removeClientTag(client, tag);
  };

  editTag = () => {
    const { tag } = this.props;

    this.setState({
      editing: true,
      menuPopupOpen: false,
      name: tag.name,
    });
  };

  saveTag = () => {
    const { client, tag, updateTagForClient } = this.props;
    const { name } = this.state;

    const updatedTag = { ...tag };
    updatedTag.name = name;
    updateTagForClient(client, updatedTag, this.saveTagCallback);
  };

  saveTagCallback = () => {
    this.setState({ editing: false, menuPopupOpen: false });
  };

  render() {
    const { advisor, hub, tag } = this.props;
    if (!tag) return null;
    const { editing, menuPopupOpen, name } = this.state;
    let iconName = "hashtag";
    if (CardTag.specialTags[tag.name]) iconName = CardTag.specialTags[tag.name];

    const isRedtailUser =
      advisor.integrations.filter((integration) => {
        return integration.type === "redtail" && integration.hubId === hub.id;
      }).length >= 1;
    const isWealthboxUser =
      advisor.integrations.filter((integration) => {
        return integration.type === "wealthbox" && integration.hubId === hub.id;
      }).length >= 1;

    return (
      <List.Item
        key={tag.id}
        style={{ display: "flex", paddingBottom: ".45em", paddingTop: ".45em" }}
        className="grey_on_hover rounded p25"
      >
        <Icon name={iconName} style={{ paddingLeft: ".5em" }} />
        <List.Content>
          {editing ? (
            <div>
              <Input
                onChange={(e) => {
                  this.setState({ name: e.target.value });
                }}
                size="mini"
                value={name}
              />
              <div style={{ marginTop: ".25em" }}>
                <Button
                  content="Save"
                  color="green"
                  compact
                  onClick={() => {
                    this.saveTag();
                  }}
                />
                <Icon
                  name="delete"
                  link
                  size="large"
                  color="grey"
                  onClick={() => {
                    this.setState({
                      editing: false,
                      menuPopupOpen: false,
                      name: tag.name,
                    });
                  }}
                />
              </div>
            </div>
          ) : (
            <div style={{ display: "flex" }}>
              <List.Description>{tag.name}</List.Description>
              <div
                style={{
                  paddingLeft: "0.25em",
                  marginLeft: "auto",
                  marginRight: "10%",
                }}
              >
                <Popup
                  className="hubly_bars_menu"
                  flowing
                  hideOnScroll
                  on="click"
                  onClose={this.toggleMenuPopup}
                  onOpen={this.toggleMenuPopup}
                  open={menuPopupOpen}
                  popperModifiers={{
                    preventOverflow: {
                      boundariesElement: "window",
                      enabled: false,
                    },
                  }}
                  position="right center"
                  style={{ padding: "0 !important" }}
                  trigger={
                    <Icon name="ellipsis horizontal" link color="grey" />
                  }
                >
                  <Button.Group
                    basic
                    vertical
                    labeled
                    icon
                    style={{ border: "none" }}
                  >
                    {isRedtailUser && (
                      <Button
                        icon="edit"
                        content="Edit in Redtail"
                        onClick={() => {
                          this.setState({
                            editing: false,
                            menuPopupOpen: false,
                            name: tag.name,
                          });
                          const win = window.open(
                            `${config.redtailUrl}/contacts/tags`,
                            "_blank"
                          );
                          win.focus();
                        }}
                      />
                    )}
                    {isWealthboxUser && (
                      <Button
                        icon="edit"
                        content="Edit in Wealthbox"
                        onClick={() => {
                          this.setState({
                            editing: false,
                            menuPopupOpen: false,
                            name: tag.name,
                          });
                          const win = window.open(
                            `https://app.crmworkspace.com/settings/tags`,
                            "_blank"
                          );
                          win.focus();
                        }}
                      />
                    )}
                    {!isRedtailUser && !isWealthboxUser && (
                      <Button
                        icon="edit"
                        content="Edit Tag"
                        onClick={this.editTag}
                      />
                    )}
                    <Button
                      icon="delete"
                      content="Remove Tag"
                      onClick={this.deleteTag}
                    />
                  </Button.Group>
                </Popup>
              </div>
            </div>
          )}
        </List.Content>
      </List.Item>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    advisor: state.hubly.data.advisor,
    hub: state.hubly.data.hub.selected.hub,
    tag: state.hubly.data.hub.tags[ownProps.tagId],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    removeClientTag: (client, tag) => {
      const publish = () => {
        dispatch(publishClientWorkflowsUpdate([client.id]));
      };
      dispatch(RemoveClientTag(client, tag, publish));
    },
    updateTagForClient: (client, tag, callback) => {
      dispatch(UpdateTagForClient(client, tag, callback));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CardTag);
