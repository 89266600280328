import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Icon } from "semantic-ui-react";
import Moment from "moment";
import history from "data/history";
import getOverdueInfo from "data/libs/overdueReminders";
import PopupMenu from "components/PopupMenu";
import { PrivacyModeContext } from "components/PrivacyMode/Context";
import { OpenReminderRescheduleModal } from "components/ReminderRescheduleModal/actions";
import { CompleteTaskByReminder } from "data/hub/actions";
import { DismissReminder } from "data/reminders/actions";
import InitialsCircle from "components/InitialsCircle";
import Name from "./components/Name";

class FeedItem extends Component {
  static contextType = PrivacyModeContext;

  popupRef = React.createRef();

  state = {
    popupOpen: false,
  };

  static defaultProps = {
    setRef: null,
    underline: false,
    overline: false,
    client: null,
    clientWorkflow: null,
    clickable: true,
  };

  static propTypes = {
    hub: PropTypes.shape({
      hubId: PropTypes.string.isRequired,
    }).isRequired,
    completeTaskByReminder: PropTypes.func.isRequired,
    dismissReminder: PropTypes.func.isRequired,
    item: PropTypes.shape({
      time: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      client: PropTypes.string.isRequired,
      clientId: PropTypes.string.isRequired,
      clientWorkflowId: PropTypes.string.isRequired,
      completedAt: PropTypes.string,
      dismissed: PropTypes.bool.isRequired,
      id: PropTypes.string.isRequired,
      taskId: PropTypes.string,
    }).isRequired,
    openReminderRescheduleModal: PropTypes.func.isRequired,
    setRef: PropTypes.func,
    underline: PropTypes.bool,
    overline: PropTypes.bool,
    client: PropTypes.object,
    clientWorkflow: PropTypes.object,
    clickable: PropTypes.bool,
  };

  getFormattedTime = (time) => {
    const today = Moment().endOf("day");
    const tomorrow = Moment().add(1, "day").endOf("day");
    const week = Moment().endOf("week");
    const date = Moment(time);
    const now = Moment();

    if (date < now) return date.format("h:mma, MMM D YYYY");
    if (date < today && date > now) return `Today at ${date.format("h:mma")}`;
    if (date < tomorrow && date > now)
      return `Tomorrow at ${date.format("h:mma")}`;
    if (date < week) return date.format("h:mma dddd");
    return date.format("h:mma, MMM D YYYY");
  };

  togglePopup = () => {
    this.setState((state) => {
      return { popupOpen: !state.popupOpen };
    });
  };

  openClientCard = () => {
    const { hub, item, clientWorkflow } = this.props;
    // try to find the correct workflow based on the task ID
    try {
      if (clientWorkflow) {
        history.push(
          `/hub/${hub.hubId}/clients/${item.clientId}/workflows/${clientWorkflow.id}`
        );
        return;
      }
    } catch (error) {
      console.error(error);
    }
    history.push(`/hub/${hub.hubId}/clients/${item.clientId}`);
  };

  rescheduleReminder = () => {
    const { item, openReminderRescheduleModal } = this.props;
    openReminderRescheduleModal(item);
  };

  completeTask = () => {
    const { item, completeTaskByReminder } = this.props;
    if (!item.taskId) return;
    completeTaskByReminder(item.taskId, item.id, item.clientId);
  };

  dismissReminder = () => {
    const { item, dismissReminder } = this.props;
    dismissReminder(item);
  };

  render() {
    const { piiMask } = this.context;
    const { item, underline, setRef, overline, clickable, clientWorkflow } =
      this.props;
    let assignedTo;
    if (clientWorkflow) {
      const task = clientWorkflow.tasks.find((t) => {
        return t.id === item.taskId;
      });
      if (task && task.assignedAdvisor) assignedTo = task.assignedAdvisor;
    }
    const { popupOpen } = this.state;
    const completed = item.completedAt || item.dismissed;
    const colorInfo = getOverdueInfo(item.time);
    if (completed) {
      colorInfo.textColor = "#21ba45";
      colorInfo.iconColor = "green";
      colorInfo.highlightColor = "#def3e7";
    } else if (colorInfo.textColor === "black") {
      colorInfo.iconColor = "grey";
      colorInfo.textColor = "grey";
      colorInfo.highlightColor = "rgba(0,0,0,0)";
    }
    return (
      <div
        className={`${clickable && "clickable grey_on_hover"} p25`}
        style={{
          maxWidth: "100%",
          borderBottom: underline ? "3px solid #db2828" : "1px solid #ebebeb",
          borderTop: overline ? "3px solid #db2828" : "1px solid #ebebeb",
          padding: "0.5em",
          display: "flex",
          borderRadius: "0px",
        }}
        ref={setRef}
        onClick={clickable && this.togglePopup}
        title={item.title}
      >
        <div style={{ position: "relative", width: 0, height: 0 }}>
          {underline && (
            <div
              style={{
                width: "15px",
                height: "15px",
                borderRadius: "100px",
                backgroundColor: "#db2828",
                transform: "translate(-3.5px, 37.5px)",
              }}
            />
          )}
          {overline && (
            <div
              style={{
                width: "15px",
                height: "15px",
                borderRadius: "100px",
                backgroundColor: "#db2828",
                transform: "translate(-3.5px, -12.5px)",
              }}
            />
          )}
        </div>
        <div
          style={{ position: "relative", top: "25px", left: "-5px" }}
          ref={this.popupRef}
        />
        <PopupMenu
          data-test={`hubfeed-item-${item?.title}-menu`}
          hoverable
          buttons={[
            {
              content: "View Client Card",
              icon: "user",
              onClick: this.openClientCard,
            },
            ...(!completed
              ? [
                  {
                    content: "Reschedule Reminder",
                    icon: "clock",
                    onClick: this.rescheduleReminder,
                  },
                ]
              : []),
            ...(!completed && item.taskId
              ? [
                  {
                    content: "Complete Task",
                    icon: "check",
                    onClick: this.completeTask,
                  },
                ]
              : []),
            ...(!completed
              ? [
                  {
                    content: "Dismiss Reminder",
                    icon: "delete",
                    onClick: this.dismissReminder,
                  },
                ]
              : []),
          ]}
          context={this.popupRef}
          onOpen={() => {
            this.setState({ popupOpen: true });
          }}
          onClose={() => {
            this.setState({ popupOpen: false });
          }}
          open={popupOpen}
          popperModifiers={{
            preventOverflow: { boundariesElement: "window", enabled: false },
          }}
          position="left center"
          trigger={null}
        />
        <div style={{ maxWidth: assignedTo ? "300px" : "100%" }}>
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              fontWeight: "bold",
            }}
            data-test="feed-item-title"
          >
            {item.title}
          </div>
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "100%",
            }}
          >
            <span
              style={{
                backgroundColor: colorInfo.highlightColor,
                padding: "0.1em 0.5em",
                borderRadius: "3px",
              }}
              data-test="feed-item-reminderStatus"
            >
              <Icon name="bell outline" color={colorInfo.iconColor} />
              <span
                style={{
                  color: colorInfo.textColor,
                  textDecoration: completed ? "line-through" : "none",
                  fontWeight: "bold",
                }}
              >
                {item.completedAt
                  ? this.getFormattedTime(item.completedAt)
                  : this.getFormattedTime(item.time)}
              </span>
            </span>
            <span style={{ paddingLeft: "0.5em" }}>
              <Name
                fullName={item.client}
                suffixClassName={piiMask("fs-block dd-privacy-mask")}
              />
            </span>
          </div>
        </div>
        {assignedTo && (
          <div
            style={{
              width: "40px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginLeft: "auto",
            }}
          >
            <InitialsCircle
              first={assignedTo.firstName}
              last={assignedTo.lastName}
              properties={{
                color: "#c4c4c4",
                circleFontSize: "13pt",
                circleSize: "30px",
                paddingTop: "5px",
                bold: true,
              }}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    hub: state.hubly.data.hub.selected.hub,
    client: state.hubly.data.hub.clients.activeClients[ownProps.item.clientId],
    clientWorkflow:
      state.hubly.data.hub.clientWorkflows[ownProps.item.clientWorkflowId],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    completeTaskByReminder: (taskId, reminderId, clientId) => {
      dispatch(CompleteTaskByReminder(taskId, reminderId, clientId));
    },
    dismissReminder: (reminder) => {
      dispatch(DismissReminder(reminder));
    },
    openReminderRescheduleModal: (reminder) => {
      dispatch(OpenReminderRescheduleModal(reminder));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FeedItem);
