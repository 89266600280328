import React from "react";
import PropTypes from "prop-types";
import { Icon } from "semantic-ui-react";
import Spinner from "../Spinner";

class InfiniteScroller extends React.Component {
  static defaultProps = {
    loadingUp: false,
    // loadingDown: false,
    loadUp: () => {},
    loadDown: () => {},
    fullScreenLoading: false,
    children: [],
    setRef: () => {},
  };

  static propTypes = {
    loadingUp: PropTypes.bool,
    loadingUpDone: PropTypes.bool.isRequired,
    // loadingDown: PropTypes.bool,
    loadUp: PropTypes.func,
    loadDown: PropTypes.func,
    fullScreenLoading: PropTypes.bool,
    children: PropTypes.array,
    setRef: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.refreshDelay = 2000;
    this.state = {
      // lastDownLoad: 0,
      lastUpLoad: 0,
    };
  }

  getScrollTopMax = (element) => {
    if (element.scrollTopMax) return element.scrollTopMax;
    return element.scrollHeight - element.clientHeight;
  };

  wheelEvent = (e) => {
    const { loadingUp, loadingUpDone, loadUp, fullScreenLoading } = this.props;
    if (fullScreenLoading) return;
    const { lastUpLoad } = this.state;
    const scrollArea = document.getElementById("scrolling_area");
    const currentTime = Date.now();
    // TODO  remove if not using downward anymore
    // if ( // conditions for loading more at the bottom
    //   currentTime - lastDownLoad > this.refreshDelay // dont load more than once per second, momentum scroll would cause issues
    //   && !loadingDown // can't already be loading more
    //   && e.deltaY > 0 // only if event is a down scroll
    //   && scrollArea.scrollTop === this.getScrollTopMax(scrollArea) // only if at bottom of page
    // ) {
    //   this.setState({ lastDownLoad: Date.now() });
    //   loadDown();
    // } else
    if (
      // conditions for loading more at the top
      currentTime - lastUpLoad > this.refreshDelay &&
      !loadingUp && // can't if already loading
      e.deltaY < 0 && // only if scrolling up
      scrollArea.scrollTop < 1 && // only if at the top of the scrolling area
      !loadingUpDone // no more reminders up
    ) {
      this.setState({ lastUpLoad: Date.now() });
      loadUp();
    }
  };

  renderLoader = (loading, up) => {
    const { loadUp, loadingUpDone, loadDown } = this.props;
    return (
      <div
        style={{
          width: "100%",
          color: "grey",
          textAlign: "center",
          padding: "1em",
          minHeight: "60px",
          lineHeight: "60px",
        }}
      >
        {loading && <Spinner active />}
        {!loading && up && !loadingUpDone && (
          <span onClick={loadUp}>
            Scroll up to load completed reminders
            <Icon name="angle up" />
          </span>
        )}
        {!loading && up && loadingUpDone && <span>No more reminders</span>}
        {!loading && !up && (
          <span onClick={loadDown}>
            Scroll down to load more
            <Icon name="angle down" />
          </span>
        )}
      </div>
    );
  };

  render() {
    const { children, loadingUp, fullScreenLoading, setRef } = this.props;
    if (fullScreenLoading) {
      return (
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            backgroundColor: "#eee",
          }}
        >
          <Spinner active size="large" />
          <div style={{ color: "grey" }}>Loading your Hub Feed...</div>
        </div>
      );
    }
    return (
      <div
        style={{
          height: "100%",
          overflowY: "auto",
        }}
        onWheel={this.wheelEvent}
        id="scrolling_area"
        ref={setRef}
      >
        <div style={{ minHeight: "calc(100% - 47px)" }}>
          {this.renderLoader(loadingUp, true)}
          {children}
          {children.length === 0 && (
            <div
              style={{
                width: "100%",
                textAlign: "center",
                marginTop: "65%",
                color: "grey",
              }}
            >
              <Icon name="bell slash outline" size="huge" />
              <br />
              <br />
              You have no active reminders.
              <br />
              New reminders will appear here.
            </div>
          )}
        </div>
        <div
          style={{
            width: "100%",
            color: "grey",
            textAlign: "center",
            padding: "1em",
            minHeight: "60px",
            lineHeight: "60px",
          }}
        >
          No more reminders
        </div>
        {/* {this.renderLoader(loadingDown, false)} */}
      </div>
    );
  }
}

export default InfiniteScroller;
