import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { sortClients, getOrderedFullClientName } from "data/libs/clients";
import { createSelector } from "reselect";
import PureRuleClientsDropdown from "./PureRuleClientsDropdown";
import { parseBackendClient } from "../helpers";

const RuleClientsDropdown = ({
  sortedClients,
  streamOptions,
  tagOptions,
  showClients,
  selectedItems,
  setSelectedItems,
  ...restProps
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchInput, setSearchInput] = useState("");

  const selectedItemsMap = selectedItems.reduce((acc, item) => {
    return { ...acc, [item.key]: true };
  }, {});

  const clientOptionsFiltered = sortedClients.filter((client) => {
    return (
      client.text.toLowerCase().includes(searchInput.toLowerCase()) &&
      !selectedItemsMap[client.key]
    );
  });

  const streamOptionsFiltered = streamOptions.filter((stream) => {
    return (
      stream.text.toLowerCase().includes(searchInput.toLowerCase()) &&
      !selectedItemsMap[stream.key]
    );
  });

  const tagOptionsFiltered = tagOptions.filter((tag) => {
    return (
      tag.text.toLowerCase().includes(searchInput.toLowerCase()) &&
      !selectedItemsMap[tag.key]
    );
  });

  const selectDropdownItem = (item, itemType) => {
    setSelectedItems([...selectedItems, { ...item, type: itemType }]);
    setDropdownOpen(false);
    setSearchInput("");
  };

  const onRemoveDropdownItem = (data) => {
    setSelectedItems(
      selectedItems.filter((item) => {
        return data?.value?.indexOf(item.value) >= 0;
      })
    );
  };

  return (
    <PureRuleClientsDropdown
      clientOptionsFiltered={clientOptionsFiltered}
      streamOptionsFiltered={streamOptionsFiltered}
      tagOptionsFiltered={tagOptionsFiltered}
      selectDropdownItem={selectDropdownItem}
      showClients={showClients}
      options={[...sortedClients, ...streamOptions, ...tagOptions]}
      open={dropdownOpen}
      onOpen={() => {
        setDropdownOpen(true);
      }}
      onClose={() => {
        setDropdownOpen(false);
      }}
      onChange={(e, data) => {
        onRemoveDropdownItem(data);
      }}
      onSearchChange={(e) => {
        setSearchInput(e.target.value);
      }}
      searchInput={{ value: searchInput }}
      value={Object.keys(selectedItemsMap)}
      {...restProps}
    />
  );
};

RuleClientsDropdown.propTypes = {
  sortedClients: PropTypes.array,
  tagOptions: PropTypes.array,
  streamOptions: PropTypes.array,
  showClients: PropTypes.bool,
  selectedItems: PropTypes.array,
  setSelectedItems: PropTypes.func.isRequired,
};

RuleClientsDropdown.defaultProps = {
  sortedClients: [],
  tagOptions: [],
  streamOptions: [],
  showClients: false,
  selectedItems: [],
};

const getAllClients = (state) => {
  return state?.hubly?.data?.hub?.clients?.allClientNames || [];
};
const getHub = (state) => {
  return state?.hubly?.data?.hub?.selected?.hub;
};

export function makeGetSortedClients() {
  return createSelector([getAllClients, getHub], (allClientNames, hub) => {
    return sortClients(allClientNames, hub).map((client) => {
      return parseBackendClient({
        ...client,
        name: getOrderedFullClientName(client, hub),
      });
    });
  });
}

const mapStateToProps = (state) => {
  const getSortedClients = makeGetSortedClients();
  return {
    sortedClients: getSortedClients(state),
    hub: state.hubly.data.hub.selected.hub,
  };
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RuleClientsDropdown);
