import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Button } from "semantic-ui-react";
import { SetAlert } from "components/Alerts/actions";
import { SetViewModalOpen } from "components/ViewModal/actions";
import { IsFilterOn, SetFilters } from "data/filters/actions";
import { EditFilter } from "data/libs/filters";
import { SetCurrentView, SetViews } from "data/views/actions";
import { UpdateAdvisor } from "data/advisor/actions";

class SaveView extends React.Component {
  static propTypes = {
    advisor: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
    currentView: PropTypes.shape({
      id: PropTypes.string.isRequired,
      description: PropTypes.string,
      filter: PropTypes.object.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    filters: PropTypes.object.isRequired,
    setAlert: PropTypes.func.isRequired,
    setCurrentView: PropTypes.func.isRequired,
    setFilters: PropTypes.func.isRequired,
    setViewModalOpen: PropTypes.func.isRequired,
    setViews: PropTypes.func.isRequired,
    updateAdvisor: PropTypes.func.isRequired,
    views: PropTypes.func.isRequired,
    workflows: PropTypes.object.isRequired,
  };

  state = {
    loading: false,
  };

  openViewModal = () => {
    const { setViewModalOpen } = this.props;
    setViewModalOpen(true);
  };

  updateView = () => {
    const {
      advisor,
      currentView,
      filters,
      setAlert,
      setCurrentView,
      setFilters,
      setViewModalOpen,
      setViews,
      updateAdvisor,
      views,
      workflows,
    } = this.props;

    this.setState({ loading: true });
    if (!IsFilterOn(filters) && Object.keys(workflows).length === 0) {
      setAlert({
        type: "warning",
        text: "No filter options are set. Please verify filters and try again.",
      });
      this.setState({ loading: false });
      return;
    }
    EditFilter(currentView.filter.id, filters)
      .then((response) => {
        const currentViewCopy = { ...currentView };
        const viewsCopy = [...views];
        currentViewCopy.filter = response;
        updateAdvisor(advisor.id, { defaultView: currentViewCopy.id });
        setCurrentView(currentViewCopy);
        setFilters(response, false);

        const foundView = viewsCopy.find((view) => {
          return view.id === currentView.id;
        });
        if (!foundView) {
          console.warn(`Could not find view with ID: ${currentView.id}`);
          return;
        }
        foundView.filter = response;
        setViews(viewsCopy);
        setAlert({ type: "success", text: "Successfully edited view." });
        setViewModalOpen(false);
        this.setState({ loading: false });
      })
      .catch((error) => {
        console.error(error);
        setAlert({ type: "error", text: "Failed to edit view." });
        this.setState({ loading: false });
      });
  };

  render() {
    const { loading } = this.state;
    const { currentView, filters } = this.props;
    if (!filters.isFilterUpdated) return null;
    return (
      <Button
        compact
        primary={!currentView.id}
        content={currentView.id ? "Update View" : "Create View"}
        loading={loading}
        onClick={
          currentView.id
            ? () => {
                this.updateView();
              }
            : () => {
                this.openViewModal();
              }
        }
        basic={currentView.id}
        style={{ marginRight: 0, minWidth: "max-content" }}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    advisor: state.hubly.data.advisor,
    currentView: state.hubly.data.views.currentView,
    filters: state.hubly.data.filters,
    views: state.hubly.data.views.views,
    workflows: state.hubly.data.hub.workflows,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAlert: (alert) => {
      dispatch(SetAlert(alert));
    },
    setCurrentView: (currentView) => {
      dispatch(SetCurrentView(currentView));
    },
    setFilters: (filters, isFilterUpdated) => {
      dispatch(SetFilters(filters, isFilterUpdated));
    },
    setViewModalOpen: (open) => {
      dispatch(SetViewModalOpen(open));
    },
    setViews: (views) => {
      dispatch(SetViews(views));
    },
    updateAdvisor: (id, request, callback) => {
      dispatch(UpdateAdvisor(id, request, callback));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SaveView);
