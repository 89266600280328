import React from "react";
import { Button, Header, Icon, Input, Modal } from "semantic-ui-react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { SetConfirmationModal } from "components/ConfirmationModal/actions";
import { SetAlert } from "components/Alerts/actions";
import { EditClient } from "data/libs/clients";
import { SetActiveClient } from "data/hub/clients/actions";

class EditClientModal extends React.Component {
  static propTypes = {
    client: PropTypes.object.isRequired,
    setConfirmationModal: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired,
    setActiveClient: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const { client } = props;
    this.state = {
      open: false,
      firstName: client.firstName,
      lastName: client.lastName,
      middleName: client.middleName,
      nickname: client.nickname,
      prefix: client.prefix,
      suffix: client.suffix,
      saving: false,
      archiving: false,
    };
  }

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  };

  saveClient = () => {
    const { firstName, lastName, middleName, nickname, prefix, suffix } =
      this.state;
    const { client, setAlert, setActiveClient } = this.props;
    this.setState({ saving: true });
    EditClient(client.id, {
      firstName,
      lastName,
      middleName,
      nickname,
      prefix,
      suffix,
    })
      .then((response) => {
        setActiveClient(response);
        this.setState({ open: false });
      })
      .catch((error) => {
        console.error(error);
        setAlert({ type: "error", text: "Failed to rename Client" });
      })
      .finally(() => {
        this.setState({ saving: false });
      });
  };

  archive = () => {
    const { setConfirmationModal, client, setAlert } = this.props;
    this.setState({ archiving: true });
    const params = {
      title: "Archive Client",
      message: `Are you sure you wish to archive "${client.name}"? This will cause Hubly to refresh, any unsaved changes will be lost.`,
      icon: "delete",
      buttons: [
        { text: "Cancel" },
        {
          text: "Archive Client",
          callBack: () => {
            EditClient(client.id, { isDeleted: true })
              .then((response) => {
                setAlert({ type: "success", text: "Client has been archived" });
              })
              .catch((error) => {
                console.error(error);
                setAlert({ type: "error", text: "Failed to archive Client" });
              })
              .finally(() => {
                this.setState({ archiving: false, open: false });
                // Reload the page so that the client will be gone. Opted for this rather than remove all traces in state ¯\_(ツ)_/¯
                window.location.href = "/";
              });
          },
          color: "red",
        },
      ],
    };
    setConfirmationModal(params);
  };

  render() {
    const {
      open,
      firstName,
      lastName,
      middleName,
      nickname,
      saving,
      archiving,
      prefix,
      suffix,
    } = this.state;
    const { client } = this.props;
    const loading = saving || archiving;
    const missingNames = firstName?.length === 0 || lastName?.length === 0;

    return (
      <Modal
        on="click"
        open={open}
        onOpen={() => {
          // reset on open
          this.setState({
            open: true,
            firstName: client.firstName,
            lastName: client.lastName,
          });
        }}
        onClose={() => {
          this.setState({ open: false });
        }}
        trigger={<Icon name="cog" color="grey" link />}
        size="mini"
        closeOnDimmerClick
      >
        <Modal.Header>
          <Icon
            link
            name="delete"
            color="grey"
            style={{
              float: "right",
              position: "relative",
              top: "0px",
              right: "0px",
            }}
            onClick={() => {
              this.setState({ open: false });
            }}
          />
          Edit Client
        </Modal.Header>
        <Modal.Content>
          <Header as="h5" style={{ marginBottom: "0.5em" }}>
            Prefix
          </Header>
          <Input
            disabled={loading}
            fluid
            value={prefix}
            name="prefix"
            placeholder="eg. Mr, Mrs, Dr, etc."
            onChange={this.handleChange}
          />
          <Header as="h5" style={{ marginBottom: "0.5em" }}>
            First Name
          </Header>
          <Input
            disabled={loading}
            fluid
            value={firstName}
            name="firstName"
            placeholder="First Name"
            onChange={this.handleChange}
          />
          <Header as="h5" style={{ marginBottom: "0.5em" }}>
            Last Name
          </Header>
          <Input
            disabled={loading}
            fluid
            value={lastName}
            name="lastName"
            placeholder="Last Name"
            onChange={this.handleChange}
          />
          <Header as="h5" style={{ marginBottom: "0.5em" }}>
            Middle Name
          </Header>
          <Input
            disabled={loading}
            fluid
            value={middleName}
            name="middleName"
            placeholder="Middle Name"
            onChange={this.handleChange}
          />
          <Header as="h5" style={{ marginBottom: "0.5em" }}>
            Nickname
          </Header>
          <Input
            disabled={loading}
            fluid
            value={nickname}
            name="nickname"
            placeholder="Nickname"
            onChange={this.handleChange}
          />
          <Header as="h5" style={{ marginBottom: "0.5em" }}>
            Suffix
          </Header>
          <Input
            disabled={loading}
            fluid
            value={suffix}
            name="suffix"
            placeholder="eg. Jr, I, II, III"
            onChange={this.handleChange}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button
            disabled={loading}
            loading={archiving}
            negative
            content="Archive"
            floated="left"
            onClick={this.archive}
          />
          <Button
            disabled={loading || missingNames}
            loading={saving}
            positive
            content="Save"
            onClick={this.saveClient}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    setConfirmationModal: (params) => {
      dispatch(SetConfirmationModal(params));
    },
    setAlert: (alert) => {
      dispatch(SetAlert(alert));
    },
    setActiveClient: (client) => {
      dispatch(SetActiveClient(client));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditClientModal);
