import React, { useContext, useRef, useState } from "react";
import PropTypes from "prop-types";

import { Input } from "semantic-ui-react";

import {
  HubContext,
  ToasterContext,
} from "scenes/Hubly/components/Workspace/Provider";

import {
  ActionButtons,
  TasksEditor,
  NextWorkflowsEditor,
  AttachmentsEditor,
  RulesEditor,
  EditorPlaceholder,
  WorkflowEditorLayout,
  DeleteWorkflowModal,
  OptionsEditor,
} from "./components";
import { Assigner } from "../ClientTile/components";

export function WorkflowEditor({
  loading,
  reloadData,
  workflow,
  onClose,
  onDeleted,
}) {
  const { fetchAssignees, advisors, editWorkflow, deleteWorkflow } =
    useContext(HubContext);
  const { fireSuccess, fireError } = useContext(ToasterContext);

  const [updateLoader, setUpdateLoader] = useState(false);

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const wfName = useRef();

  const updateWorkflow = (request) => {
    setUpdateLoader(true);
    return editWorkflow(workflow.id, request)
      .then(() => {
        reloadData();
      })
      .catch((error) => {
        fireError("Failed to update workflow", error);
        setUpdateLoader(false);
      });
  };

  const removeWorkflow = (deleteClients) => {
    setUpdateLoader(true);
    setShowDeleteConfirmation(false);
    return deleteWorkflow(workflow.id, deleteClients)
      .then(() => {
        fireSuccess(`Removed workflow ${workflow.name} succeeded`);
        onClose();
        onDeleted(workflow);
      })
      .catch((error) => {
        fireError("Failed to delete workflow", error);
        setUpdateLoader(false);
      });
  };

  const onCloseEditMode = () => {
    const isWorkflowNameUpdated = (name) => {
      return name && name !== workflow?.name;
    };

    const preClosingAction = isWorkflowNameUpdated(wfName.current)
      ? updateWorkflow
      : () => {
          reloadData();
          return Promise.resolve();
        };

    preClosingAction({ name: wfName.current }).then(() => {
      onClose();
    });
  };

  if (loading && !workflow) {
    return <EditorPlaceholder numLines={workflow?.numTasks || 4} />;
  }

  return (
    <WorkflowEditorLayout
      data-test={`workflow-${workflow.name}`}
      assignedAdvisor={
        advisors.length > 1 && (
          <Assigner
            loading={loading || updateLoader}
            iconProperties={{
              addIconSize: "35px",
              circleFontSize: "18px",
              circleSize: "30px",
            }}
            style={{ margin: "0.25em 0.25em 0 0" }}
            currentAssignee={workflow?.assignedAdvisor}
            fetchAssignees={fetchAssignees}
            onAssign={(newAssignedId) => {
              updateWorkflow({
                assignedAdvisorId:
                  newAssignedId === "unassigned" ? "" : newAssignedId,
              }).then(() => {
                setUpdateLoader(false);
              });
            }}
          />
        )
      }
      nameEditor={
        <Input
          loading={updateLoader}
          defaultValue={workflow.name}
          placeholder="Name of Workflow"
          floated="left"
          size="large"
          fluid
          onChange={(_, { value }) => {
            wfName.current = value;
          }}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              onCloseEditMode();
            }
          }}
        />
      }
      actionButtons={
        <ActionButtons
          loading={updateLoader}
          onClickDelete={() => {
            setShowDeleteConfirmation(true);
          }}
          onDoneClick={onCloseEditMode}
        />
      }
    >
      <TasksEditor
        showAssignedAdvisor={advisors.length > 1}
        workflow={workflow}
      />
      <RulesEditor workflow={workflow} />
      <NextWorkflowsEditor workflow={workflow} />
      <AttachmentsEditor workflowId={workflow.id} />
      <OptionsEditor
        disabled={updateLoader}
        options={{
          presentWorkflowName: workflow.clientTileOptions?.presentWorkflowName,
          multipleTimePerClient: workflow.multipleTimePerClient,
        }}
        onUpdate={(request) => {
          updateWorkflow(request).then(() => {
            setUpdateLoader(false);
          });
        }}
      />
      {showDeleteConfirmation && (
        <DeleteWorkflowModal
          name={workflow.name}
          numActiveClients={workflow.numActiveClients}
          onClose={() => {
            setShowDeleteConfirmation(false);
          }}
          onRemove={removeWorkflow}
        />
      )}
    </WorkflowEditorLayout>
  );
}

WorkflowEditor.propTypes = {
  workflow: PropTypes.object,
  loading: PropTypes.bool,
  reloadData: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onDeleted: PropTypes.func.isRequired,
};

WorkflowEditor.defaultProps = {
  workflow: null,
  loading: false,
};

export default WorkflowEditor;
