import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";

import { Icon, Popup } from "semantic-ui-react";

export function FeedSettingsPopup({ children }) {
  const [open, setOpen] = useState(false);

  return (
    <Popup
      flowing
      open={open}
      onClose={() => {
        return setOpen(false);
      }}
      onOpen={() => {
        return setOpen(true);
      }}
      on="click"
      position="bottom right"
      trigger={
        <div
          data-test="hubfeed-settings-popup"
          className="clickable grey_on_hover rounded p25"
        >
          <Icon color="grey" name="setting" />
        </div>
      }
    >
      {children({
        closeMenu: useCallback(() => {
          setOpen(false);
        }, []),
      })}
    </Popup>
  );
}

FeedSettingsPopup.propTypes = {
  children: PropTypes.func,
};

FeedSettingsPopup.defaultProps = {
  children: () => {},
};

export default FeedSettingsPopup;
