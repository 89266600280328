import { SetAlert } from "components/Alerts/actions";
import {
  CreateWorkflowRule,
  DeleteWorkflowRule,
  EditWorkflowRule,
} from "data/libs/workflowRules";
import { SetWorkflow } from "data/hub/workflows/actions";

export const SetEditWorkflowRule = (editWorkflowRule) => {
  return {
    type: "SET_EDIT_WORKFLOW_RULE",
    editWorkflowRule: editWorkflowRule,
  };
};

export function AddWorkflowRule(workflow, workflowRule, callback) {
  return (dispatch, getState) => {
    const request = {
      workflowId: workflow.id,
      ...workflowRule,
    };
    CreateWorkflowRule(request)
      .then((response) => {
        const workflowCopy = {
          ...getState().hubly.data.hub.workflows[response.workflowId],
        };
        if (!workflowCopy) {
          console.error(
            "Error adding new rule, could not find current workflow."
          );
          dispatch(
            SetAlert({
              type: "error",
              text: `Error adding new rule, could not find current workflow.  Please try again.`,
            })
          );
          callback(false);
          return;
        }

        workflowCopy.workflowRules.push(response);
        dispatch(SetWorkflow(workflowCopy));
        dispatch(
          SetAlert({
            type: "success",
            text: `Successfully added new workflow rule`,
          })
        );
        callback(true);
      })
      .catch((error) => {
        callback(false);
        dispatch(
          SetAlert({ type: "error", text: `Failed to create workflow rule.` })
        );
      });
  };
}

export function RemoveWorkflowRule(workflow, workflowRuleId) {
  return (dispatch, getState) => {
    const workflowCopy = {
      ...getState().hubly.data.hub.workflows[workflow.id],
    };
    workflowCopy.workflowRules = workflowCopy.workflowRules.filter(
      (workflowRule) => {
        return workflowRule.id !== workflowRuleId;
      }
    );
    DeleteWorkflowRule(workflowRuleId, workflow.id)
      .then((response) => {
        dispatch(SetWorkflow(workflowCopy));
        dispatch(
          SetAlert({ type: "success", text: `Successfully deleted rule` })
        );
      })
      .catch((error) => {
        dispatch(
          SetAlert({ type: "error", text: `Failed to delete workflow rule.` })
        );
      });
  };
}

export function UpdateWorkflowRule(workflow, workflowRule, callback) {
  return (dispatch, getState) => {
    const request = {
      workflowId: workflow.id,
      ...workflowRule,
    };
    EditWorkflowRule(workflowRule.id, request)
      .then((response) => {
        const workflowCopy = {
          ...getState().hubly.data.hub.workflows[response.workflowId],
        };
        if (!workflowCopy) {
          console.error("Error editing rule, could not find current workflow.");
          dispatch(
            SetAlert({ type: "error", text: `Failed to edit workflow rule.` })
          );
          callback(false);
          return;
        }

        const index = workflowCopy.workflowRules.findIndex((wr) => {
          return workflowRule.id === wr.id;
        });
        workflowCopy.workflowRules[index] = response;
        dispatch(SetWorkflow(workflowCopy));
        dispatch(
          SetAlert({
            type: "success",
            text: `Successfully edited workflow rule`,
          })
        );
        callback(true);
      })
      .catch((error) => {
        callback(false);
        dispatch(
          SetAlert({ type: "error", text: `Failed to edit workflow rule.` })
        );
      });
  };
}
