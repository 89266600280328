import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { AddReminderToTask } from "components/Task/actions";
import { OpenReminderRescheduleModal } from "components/ReminderRescheduleModal/actions";
import { DismissReminder } from "data/reminders/actions";
import getOverdueInfo from "data/libs/overdueReminders";
import PureTaskReminder from "./PureTaskReminder";

class TaskReminder extends React.Component {
  static defaultProps = {
    addingTask: false,
    editTaskState: () => {},
    task: { id: "" },
  };

  static propTypes = {
    addingTask: PropTypes.bool,
    addReminderToTask: PropTypes.func.isRequired,
    client: PropTypes.object.isRequired,
    clientWorkflow: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
    editTaskState: PropTypes.func,
    task: PropTypes.object,
    openReminderRescheduleModal: PropTypes.func.isRequired,
    dismissReminder: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const defaultDate = new Date();
    defaultDate.setDate(defaultDate.getDate() + 1);
    defaultDate.setHours(8);
    defaultDate.setMinutes(0);
    defaultDate.setSeconds(0);
    defaultDate.setMilliseconds(0);
    let defaultTaskEditedDate = new Date();

    // If completed, set the default task edited completed at date
    if (props.task.completedAt) {
      defaultTaskEditedDate = new Date(props.task.completedAt);
    }

    defaultTaskEditedDate.setMinutes(0);
    defaultTaskEditedDate.setSeconds(0);
    defaultTaskEditedDate.setMilliseconds(0);

    this.state = {
      setDate: defaultDate,
    };
  }

  handleChange = (date) => {
    this.setState({
      setDate: date,
    });
  };

  saveReminder = () => {
    const { setDate } = this.state;
    const {
      addingTask,
      addReminderToTask,
      client,
      clientWorkflow,
      editTaskState,
      task,
    } = this.props;
    if (addingTask) {
      editTaskState(
        "reminders",
        [{ time: setDate, clientId: client.id }],
        "edit"
      );
    } else {
      addReminderToTask(clientWorkflow.id, task.id, setDate, task.title);
    }
  };

  dismissReminder = (callback) => {
    const { dismissReminder, client, task } = this.props;
    if (!this.taskHasReminder()) return;

    task.reminders
      .filter((r) => {
        return !r.dismissed && !r.completedAt;
      })
      .forEach((reminder) => {
        dismissReminder({
          id: reminder.id,
          clientId: client.id,
          clientWorkflowId: reminder.clientWorkflowId,
          taskId: task.id,
        });
      });

    callback();
  };

  openReminderRescheduleModal = (callback) => {
    const { openReminderRescheduleModal, task } = this.props;
    if (!this.taskHasReminder()) return;

    // A task may have at-most 1 reminder
    openReminderRescheduleModal(
      task.reminders.filter((r) => {
        return !r.dismissed && !r.completedAt;
      })[0]
    );
    callback();
  };

  taskHasReminder = () => {
    const { addingTask, task } = this.props;
    if (addingTask) {
      return (task.reminders || []).length > 0;
    } else {
      return (
        task.reminders.filter((reminder) => {
          return (
            reminder.taskId === task.id &&
            !task.completed &&
            !reminder.dismissed
          );
        }).length > 0
      );
    }
  };

  render() {
    const { addingTask, task } = this.props;
    const { setDate } = this.state;

    if (task.completed) {
      return null;
    }

    const hasReminder = this.taskHasReminder();
    let overdueInfo = null;

    if (hasReminder) {
      let soonest = null;
      if (task.reminders.length > 0) {
        if (addingTask) {
          [soonest] = task.reminders;
        } else {
          task.reminders.forEach((reminder) => {
            if (
              !reminder.dismissed &&
              (!soonest || reminder.time < soonest.time) &&
              task.id === reminder.taskId
            ) {
              soonest = reminder;
            }
          });
        }
      }

      overdueInfo = getOverdueInfo(soonest ? soonest.time : null);
    }

    return (
      <PureTaskReminder
        taskTitle={task.title}
        hasReminder={hasReminder}
        hasSequentialReminder={Boolean(task.options?.sequentialReminder?.type)}
        overdueInfo={overdueInfo}
        setSelectedDate={this.handleChange}
        selectedDate={setDate}
        saveReminder={this.saveReminder}
        addingTask={addingTask}
        openReminderRescheduleModal={this.openReminderRescheduleModal}
        dismissReminder={this.dismissReminder}
      />
    );
  }
}

const mapStateToProps = () => {
  return (state, ownProps) => {
    return {
      clientWorkflow:
        state.hubly.data.hub.clientWorkflows[ownProps.task.clientWorkflowId],
    };
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    addReminderToTask: (clientWorkflowId, taskId, reminderTime, title) => {
      dispatch(
        AddReminderToTask(clientWorkflowId, taskId, reminderTime, title)
      );
    },
    openReminderRescheduleModal: (reminder) => {
      dispatch(OpenReminderRescheduleModal(reminder));
    },
    dismissReminder: (reminder) => {
      dispatch(DismissReminder(reminder));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskReminder);
