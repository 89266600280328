import React from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Button, Header, Icon, Popup } from "semantic-ui-react";
import { datadogRum } from "@datadog/browser-rum";
import { BulkUpdateViews } from "data/libs/views";
import { SetCurrentView, SetViews } from "data/views/actions";
import { ClearFilters, SetFilters } from "data/filters/actions";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import getMiliSecondDuration from "analytics/helper";
import ViewTile from "./components/ViewTile";

const portal = document.createElement("div");
portal.classList.add("views-DND-portal");
document.body.appendChild(portal);

class Views extends React.Component {
  static propTypes = {
    advisor: PropTypes.shape({
      defaultView: PropTypes.string.isRequired,
    }).isRequired,
    clearFilters: PropTypes.func.isRequired,
    views: PropTypes.array.isRequired,
    workflows: PropTypes.object.isRequired,
    setCurrentView: PropTypes.func.isRequired,
    setViews: PropTypes.func.isRequired,
    setFilters: PropTypes.func.isRequired,
    currentView: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      nestedComponentOpen: false,
      popupOpen: false,
      timeSpentOpeningViewMenu: 0,
    };

    this.onDragEnd = this.onDragEnd.bind(this);
    const { defaultView } = props.advisor;

    if (defaultView) {
      const views = props.views.filter((v) => {
        return v.id === defaultView;
      });

      if (views.length === 1) {
        props.setCurrentView(views[0]);
        props.setFilters(views[0].filter, false);
      } else {
        props.clearFilters(Object.values(props.workflows));
      }
    } else {
      props.clearFilters(Object.values(props.workflows));
    }
  }

  setNestedComponentOpen = (open) => {
    this.setState({ nestedComponentOpen: open });
  };

  getMaxOrderValue = (items) => {
    let max = 0;
    items.forEach((item) => {
      if (item.order && item.order > max) {
        max = item.order;
      }
    });

    return max;
  };

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const { views, setViews } = this.props;

    const newViews = this.reorder(
      views,
      result.source.index,
      result.destination.index
    );
    const viewRequest = {
      views: [],
    };

    const max = this.getMaxOrderValue(views);

    newViews.forEach((view, i) => {
      view.order = max + i + 1;
      viewRequest.views.push({
        id: view.id,
        order: max + i + 1,
      });
    });

    setViews(newViews);
    BulkUpdateViews(viewRequest);
  }

  // a little function to help us with reordering the result
  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  onMountHandler = () => {
    datadogRum.addAction("hubly_timespent_view_viewmenu", {
      duration: getMiliSecondDuration(this.state.timeSpentOpeningViewMenu),
    });
  };

  onUnmountHandler = () => {
    this.setState({ timeSpentOpeningViewMenu: 0 });
  };

  onOpenHandler = () => {
    this.setState({
      popupOpen: true,
      timeSpentOpeningViewMenu: new Date().getTime(),
    });
  };

  render() {
    const { nestedComponentOpen, popupOpen } = this.state;
    const { currentView, views = [] } = this.props;
    return (
      <Popup
        on="click"
        open={popupOpen}
        onOpen={this.onOpenHandler}
        onClose={() => {
          // Don't close if the nested popup/modal is still open
          // Bug in semantic with nested onClose events
          if (!nestedComponentOpen) {
            this.setState({ popupOpen: false });
          }
        }}
        onMount={this.onMountHandler}
        onUnmount={this.onUnmountHandler}
        position="bottom left"
        style={{
          paddingRight: 0,
          paddingLeft: 0,
          height: "auto",
          zIndex: "1000",
        }}
        trigger={
          <Button
            basic
            primary={currentView.id}
            style={{
              marginRight: "0.5em",
              minWidth: "130px",
              width: "max-content",
              maxWidth: "600px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <Icon
              name="bookmark"
              style={{ opacity: 1, color: currentView.color }}
            />
            {currentView.id ? currentView.name : "Views"}
          </Button>
        }
      >
        <div
          style={{
            maxHeight: "80vh",
            overflowY: "scroll",
            width: "500px",
            display: "flex",
            flexFlow: "row wrap",
            alignContent: "stretch",
            padding: "1px 1em",
          }}
        >
          <Header
            style={{ width: "100%", margin: "0 0 1em 0", fontSize: "14pt" }}
          >
            Views
          </Header>
          {views.length === 0 && (
            <span style={{ color: "grey" }}>No views created.</span>
          )}
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable droppableId="droppable_views">
              {(provided, snapshot) => {
                return (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={{ width: "100%" }}
                  >
                    {views
                      .sort((a, b) => {
                        return a.order - b.order;
                      })
                      .map((view, index) => {
                        return (
                          <Draggable
                            draggableId={view.id}
                            key={view.id}
                            index={index}
                          >
                            {(providedDraggable, snapshotDraggable) => {
                              const { isDragging } = snapshotDraggable;
                              const child = (
                                <div
                                  ref={providedDraggable.innerRef}
                                  {...providedDraggable.draggableProps}
                                  style={{
                                    ...providedDraggable.draggableProps.style,
                                    paddingBottom: "1em",
                                  }}
                                >
                                  <ViewTile
                                    index={index}
                                    setNestedComponentOpen={
                                      this.setNestedComponentOpen
                                    }
                                    view={view}
                                    provided={providedDraggable}
                                  />
                                </div>
                              );
                              if (!isDragging) {
                                return child;
                              }
                              // If dragging - put the item in a portal
                              return ReactDOM.createPortal(child, portal);
                            }}
                          </Draggable>
                        );
                      })}
                    {provided.placeholder}
                  </div>
                );
              }}
            </Droppable>
          </DragDropContext>
        </div>
      </Popup>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    advisor: state.hubly.data.advisor,
    currentView: state.hubly.data.views.currentView,
    filters: state.hubly.data.filters,
    views: state.hubly.data.views.views,
    workflows: state.hubly.data.hub.workflows,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentView: (view) => {
      dispatch(SetCurrentView(view));
    },
    setFilters: (filters, isFilterUpdated) => {
      dispatch(SetFilters(filters, isFilterUpdated));
    },
    clearFilters: (workflows) => {
      dispatch(ClearFilters(workflows));
    },
    setViews: (views) => {
      dispatch(SetViews(views));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Views);
