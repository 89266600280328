/* eslint-disable react/prop-types */
import React from "react";

import { Draggable } from "react-beautiful-dnd";

export function WithDraggable(WrappedComponent) {
  return ({ id, index, ...props }) => {
    return (
      <Draggable draggableId={id} index={index}>
        {(p) => {
          return <WrappedComponent {...props} draggableContext={p} />;
        }}
      </Draggable>
    );
  };
}

const DraggableContext = React.createContext({
  draggableProps: { style: {} },
  dragHandleProps: { style: {} },
});

export function useDraggable() {
  return React.useContext(DraggableContext);
}

export function WithContext(WrappedComponent) {
  return ({ draggableContext, ...props }) => {
    return (
      <DraggableContext.Provider value={draggableContext}>
        <div ref={draggableContext.innerRef}>
          <WrappedComponent {...props} />
        </div>
      </DraggableContext.Provider>
    );
  };
}

export function WithContainer(WrappedComponent) {
  return ({ draggableContext, ...props }) => {
    const { innerRef, draggableProps, dragHandleProps } = draggableContext;

    return (
      <div ref={innerRef} {...draggableProps} {...dragHandleProps}>
        <WrappedComponent {...props} />
      </div>
    );
  };
}

export const DraggableFragment = WithDraggable(WithContext(React.Fragment));
export const DraggableContainer = WithDraggable(WithContainer(React.Fragment));

export default DraggableContainer;
