import React from "react";
import PropTypes from "prop-types";

import { Checkbox } from "semantic-ui-react";

import { MultipleClientDropdown } from "components/ClientDropdown";

import { RepeatSelector, RepeatingRuleLayout } from "./components";
import { ExcludeItems } from "..";

export function RepeatingRuleForm({ register, data }) {
  const householdHeadProps = register({ name: "onlyHouseholdHead" });
  return (
    <RepeatingRuleLayout
      repeatSelector={
        <RepeatSelector {...register({ name: "repeatSelector" })} />
      }
      ruleClientDropdown={
        <MultipleClientDropdown
          {...register({
            name: "selectedStreamsTags",
            required: true,
            errortext: "Select streams or tags",
          })}
          exclude={data.excludeItems.map(({ id }) => {
            return id;
          })}
          data-test="ruleModal-dropdown-condition"
          placeholder="Select streams or tags"
          showStreams
          showTags
          dropdownStyle={{ marginBottom: 0 }}
        />
      }
      householdHeadCheckbox={
        <Checkbox
          name={householdHeadProps.name}
          defaultChecked={householdHeadProps.value}
          onChange={() => {
            householdHeadProps.onChange(null, {
              name: householdHeadProps.name,
              value: !householdHeadProps.value,
            });
          }}
          label="Only apply to household head"
          style={{ marginBottom: "1em" }}
        />
      }
      excludeItems={
        <ExcludeItems
          {...register({ name: "excludeItems" })}
          exclude={data.selectedStreamsTags.map(({ id }) => {
            return id;
          })}
        />
      }
    />
  );
}

RepeatingRuleForm.propTypes = {
  register: PropTypes.func.isRequired,
  data: PropTypes.object,
};

RepeatingRuleForm.defaultProps = {
  data: null,
};

export default RepeatingRuleForm;
