import React from "react";
import { Button, Icon, Input, Popup } from "semantic-ui-react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { SetAlert } from "components/Alerts/actions";

class TaskLinksItem extends React.Component {
  static propTypes = {
    task: PropTypes.object.isRequired,
    client: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
    link: PropTypes.object.isRequired,
    deleteLink: PropTypes.func.isRequired,
    editLink: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired,
  };

  state = {
    menuOpen: false,
    editing: false,
    linkTitle: "",
    linkURL: "",
  };

  toggleEditing = () => {
    const { link } = this.props;
    this.setState((state) => {
      return {
        menuOpen: false,
        editing: !state.editing,
        linkTitle: link.title,
        linkURL: link.url,
      };
    });
  };

  deleteLink = () => {
    const { task, link, deleteLink, setAlert } = this.props;
    deleteLink(task.id, link.id, (success) => {
      if (!success) {
        setAlert({ type: "error", text: "Failed to delete link." });
      }
    });
  };

  editLink = () => {
    const { task, link, editLink } = this.props;
    const { linkTitle, linkURL } = this.state;
    editLink(task.id, link.id, linkTitle, linkURL);
    this.toggleEditing();
  };

  displayMarkup = () => {
    const { link } = this.props;
    return (
      <div style={{ display: "flex", overflow: "hidden" }}>
        <div
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontSize: "13pt",
          }}
        >
          <a
            href={link.url.includes("://") ? link.url : `//${link.url}`}
            target="_blank"
            rel="noopener noreferrer"
            title={link.url}
          >
            {link.title ? (
              <span>
                {link.title}
                <span
                  style={{
                    fontSize: "10pt",
                    color: "rgba(0,0,0,0.3)",
                    paddingLeft: "1em",
                  }}
                >
                  {link.url}
                </span>
              </span>
            ) : (
              <span>{link.url}</span>
            )}
          </a>
        </div>
        <div style={{ marginLeft: "auto", paddingLeft: "1em" }}>
          <Popup
            className="hubly_bars_menu"
            hideOnScroll
            on="click"
            onClose={() => {
              this.setState({ menuOpen: false });
            }}
            onOpen={() => {
              this.setState({ menuOpen: true });
            }}
            open={this.state.menuOpen}
            popperModifiers={{
              preventOverflow: { boundariesElement: "window", enabled: false },
            }}
            position="right center"
            trigger={<Icon name="ellipsis horizontal" link />}
          >
            <Button.Group
              basic
              vertical
              labeled
              icon
              style={{ border: "none" }}
            >
              <Button
                icon="edit"
                content="Edit Link"
                onClick={this.toggleEditing}
              />
              <Button
                icon="delete"
                content="Delete Link"
                onClick={this.deleteLink}
              />
            </Button.Group>
          </Popup>
        </div>
      </div>
    );
  };

  editingMarkup = () => {
    const { linkURL } = this.state;
    const linkTitle = this.state.linkTitle || "";
    return (
      <div
        style={{ position: "relative", left: "0em", width: "calc(100% - 1em)" }}
      >
        <Input
          value={linkTitle}
          fluid
          placeholder="Title (optional)"
          onChange={(e) => {
            this.setState({ linkTitle: e.target.value });
          }}
          style={{ marginBottom: "0.5em" }}
        />
        <Input
          value={linkURL}
          fluid
          placeholder="Link"
          autoFocus
          onChange={(e) => {
            this.setState({ linkURL: e.target.value });
          }}
          style={{ marginBottom: "0.5em" }}
        />
        <Button
          positive
          content="Save"
          style={{ marginRight: "0.5em" }}
          onClick={this.editLink}
        />
        <Icon
          link
          color="grey"
          size="large"
          name="delete"
          onClick={this.toggleEditing}
        />
      </div>
    );
  };

  render() {
    const { editing } = this.state;
    const { link } = this.props;
    return (
      <div key={link.id}>
        {editing ? this.editingMarkup() : this.displayMarkup()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAlert: (alert) => {
      dispatch(SetAlert(alert));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskLinksItem);
