import React from "react";
import PropTypes from "prop-types";

import { Icon, Input, Button } from "semantic-ui-react";

function EditableField({ initValue, onConfirmValue, onCancel, ...rest }) {
  const [inputValue, setInputValue] = React.useState(initValue);

  const submitInput = () => {
    if (inputValue?.length > 0) onConfirmValue(inputValue);
  };

  return (
    <div
      style={{ width: "100%", display: "flex", marginLeft: "0.75em" }}
      onKeyDown={({ key }) => {
        if (key === "Enter") submitInput();
      }}
    >
      <div style={{ flexGrow: 1 }}>
        <Input
          autoFocus
          onChange={(_, { value }) => {
            setInputValue(value);
          }}
          value={inputValue}
          style={{ width: "80%", marginRight: "10px" }}
          maxLength="150"
          {...rest}
        />
        <Button
          color="green"
          content="Save"
          style={{ marginBottom: "0.5em" }}
          disabled={inputValue?.length < 1}
          onClick={() => {
            submitInput();
          }}
        />
        <Icon
          color="grey"
          link
          name="delete"
          onClick={() => {
            onCancel();
          }}
        />
      </div>
    </div>
  );
}

EditableField.propTypes = {
  initValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onConfirmValue: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

EditableField.defaultProps = {
  initValue: "",
};

export default EditableField;
