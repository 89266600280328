import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const DraggableList = React.memo(
  ({ parentId = "", items = [], useCustomDragHandle, children }) => {
    const [portal, setPortal] = useState(null);

    useEffect(() => {
      // The container is used to prevent from object jumping
      // when it is being grabbed.
      if (
        document.getElementsByClassName(`${parentId}-DND-portal`).length === 1
      ) {
        const divContainer = document.getElementsByClassName(
          `${parentId}-DND-portal`
        )[0];
        setPortal(divContainer);
      } else {
        const divContainer = document.createElement("div");
        divContainer.classList.add(`${parentId}-DND-portal`);
        document.body.appendChild(divContainer);
        setPortal(divContainer);
      }
    }, [parentId]);

    return items.map((itemProps, index) => {
      const { id } = itemProps;
      return (
        <Draggable key={id} draggableId={id} index={index}>
          {(provided, snapshot) => {
            const { isDragging } = snapshot;
            const dragHandleProps = useCustomDragHandle
              ? {}
              : provided.dragHandleProps;
            const child = (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...dragHandleProps}
                style={{
                  ...dragHandleProps.style,
                  ...provided.draggableProps.style,
                }}
              >
                {children(itemProps, provided.dragHandleProps, index)}
              </div>
            );
            if (!isDragging) return child;
            // If dragging - put the item in a portal
            return ReactDOM.createPortal(child, portal);
          }}
        </Draggable>
      );
    });
  }
);

DraggableList.defaultProps = {
  items: [],
  parentId: "DND-List",
  useCustomDragHandle: true,
};

DraggableList.propTypes = {
  items: PropTypes.array,
  parentId: PropTypes.string,
  useCustomDragHandle: PropTypes.bool,
  children: PropTypes.func.isRequired,
};

export function DragDropList({
  id,
  items,
  onListReordered,
  children,
  isDropDisabled,
  useCustomDragHandle,
  direction,
  style,
  ...props
}) {
  const [state, setState] = useState({ items: items });
  useEffect(() => {
    setState({ items: items });
  }, [items]);

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const previousOrderedItems = state.items;
    const reOrderedItems = reorder(
      state.items,
      result.source.index,
      result.destination.index
    );
    setState({ items: reOrderedItems });

    onListReordered({
      items: reOrderedItems,
      previousOrderedItems: previousOrderedItems,
      draggedItemIndex: result.destination.index,
    });
  }
  return (
    <DragDropContext onDragEnd={onDragEnd} {...props}>
      <Droppable
        droppableId={id}
        isDropDisabled={isDropDisabled}
        direction={direction}
      >
        {(provided) => {
          return (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              style={{ width: "100%", ...style }}
            >
              <DraggableList
                parentId={id}
                items={state.items}
                useCustomDragHandle={useCustomDragHandle}
              >
                {children}
              </DraggableList>
              {provided.placeholder}
            </div>
          );
        }}
      </Droppable>
    </DragDropContext>
  );
}

DragDropList.defaultProps = {
  items: [],
  style: {},
  onListReordered: () => {},
  isDropDisabled: false,
  useCustomDragHandle: true,
  direction: "vertical",
};

DragDropList.propTypes = {
  id: PropTypes.string.isRequired,
  isDropDisabled: PropTypes.bool,
  useCustomDragHandle: PropTypes.bool,
  items: PropTypes.array,
  children: PropTypes.func.isRequired,
  onListReordered: PropTypes.func,
  direction: PropTypes.string,
  style: PropTypes.object,
};

export default DragDropList;
