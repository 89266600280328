import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { Button, Icon, Popup } from "semantic-ui-react";
import { MeetingDateTypeContext } from "components/MeetingDateTypeDropdown";
import EditingInlineEvent from "./EditingInlineEvent";
import { getMeetingDateTypeNameFromId, getFormattedDate } from "./helpers";

export const EditableInlineEvent = ({
  title,
  event,
  setNestedComponentOpen,
  currentDate,
  setCurrentDate,
  editEvent,
  deleteEvent,
}) => {
  const [editing, setEditing] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const { meetingDateTypes } = useContext(MeetingDateTypeContext);

  const callEditEvent = async (meetingDateTypeId) => {
    setLoading(true);
    await editEvent(meetingDateTypeId);
    setLoading(false);
    setEditing(false);
  };

  const callDeleteEvent = async () => {
    setLoading(true);
    await deleteEvent();
    setLoading(false);
    setMenuOpen(false);
  };

  const eventDate = event && event?.date ? new Date(event?.date) : null;
  const formattedDate = getFormattedDate(eventDate);

  if (editing) {
    const eventMeetingDateType = meetingDateTypes.find((m) => {
      return m.id === event?.meetingDateTypeId;
    });
    return (
      <EditingInlineEvent
        setEditing={setEditing}
        currentDate={currentDate}
        setCurrentDate={setCurrentDate}
        loading={loading}
        editEvent={callEditEvent}
        eventMeetingDateType={eventMeetingDateType}
      />
    );
  }

  return (
    <Popup
      className="hubly_bars_menu"
      on="click"
      onClose={() => {
        setMenuOpen(false);
        setNestedComponentOpen(false);
      }}
      open={menuOpen}
      popperModifiers={{
        preventOverflow: { boundariesElement: "window", enabled: false },
      }}
      position="right center"
      data-test="editable-inline-event-popup"
      trigger={
        <div
          className="grey_on_hover clickable"
          onClick={() => {
            setCurrentDate(eventDate);
            setMenuOpen(true);
            setNestedComponentOpen(true);
          }}
          style={{ lineHeight: "normal", padding: "0.25em 0.5em" }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              maxWidth: "fit-content",
            }}
          >
            <Icon
              name={loading ? "circle notch" : "calendar alternate"}
              loading={loading}
              style={
                loading
                  ? { margin: "0 0.5em 0 0" }
                  : { margin: "-0.1em 0.5em 0 0" }
              }
            />
            {title && (
              <span style={{ marginRight: "0.5em", fontWeight: 700 }}>
                {`${title}:`}
              </span>
            )}
            <span data-test="editable-inline-event-date">{formattedDate}</span>
          </div>
          {event?.meetingDateTypeId && (
            <span
              data-test="editable-inline-event-meetingDateType"
              style={{ paddingLeft: "1.7em", color: "grey", display: "block" }}
            >
              {getMeetingDateTypeNameFromId(
                meetingDateTypes,
                event?.meetingDateTypeId
              )}
            </span>
          )}
        </div>
      }
    >
      <Button.Group basic vertical labeled icon style={{ border: "none" }}>
        <Button
          icon="edit"
          content="Edit Meeting Date"
          onClick={() => {
            setEditing(true);
            setMenuOpen(false);
            setNestedComponentOpen(false);
          }}
        />
        <Button
          icon="close"
          content="Delete Meeting Date"
          onClick={callDeleteEvent}
        />
      </Button.Group>
    </Popup>
  );
};

EditableInlineEvent.propTypes = {
  title: PropTypes.string.isRequired,
  event: PropTypes.object.isRequired,
  setNestedComponentOpen: PropTypes.func.isRequired,
  currentDate: PropTypes.object.isRequired,
  setCurrentDate: PropTypes.func.isRequired,
  editEvent: PropTypes.func.isRequired,
  deleteEvent: PropTypes.func.isRequired,
};

export default EditableInlineEvent;
