/* eslint-disable import/extensions, react/jsx-filename-extension */
// Internet Explorer Polyfills
import "@babel/polyfill";
import "isomorphic-fetch";

// Actual application code
import React from "react";
import { Router } from "react-router-dom";
import ReactDOM from "@hot-loader/react-dom";
import { Provider } from "react-redux";
import Amplify from "aws-amplify";
import { datadogRum } from "@datadog/browser-rum";
import history from "data/history";
import Store from "./store";
import App from "./App";
import "semantic-ui-less/semantic.less";
import "./index.css";

import awsExports from "./aws-exports";
import * as serviceWorker from "./serviceWorker";

import config from "./config";

Amplify.configure(awsExports);

datadogRum.init(config.datadog);

ReactDOM.render(
  <Provider store={Store}>
    <Router history={history}>
      <App />
    </Router>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
