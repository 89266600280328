import {
  Button,
  Dropdown,
  Modal,
  Icon,
  Input,
  Checkbox,
} from "semantic-ui-react";
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { SetEditWorkflowRule } from "../actions";
import RuleClientsDropdown from "./RuleClientsDropdown";
import ExcludeItems from "./ExcludeItems";
import {
  getStreamsFromSelectedItems,
  getTagsFromSelectedItems,
  parseBackendStreams,
  parseEditWorkflowClients,
  getClientsFromSelectedItems,
  parseEditWorkflowTags,
  parseBackendTags,
  filterOptionsFromSelectedItems,
} from "../helpers";
import IncludeInfoPopup from "./IncludeInfoPopup";

class AgeRuleModal extends React.Component {
  static propTypes = {
    hub: PropTypes.shape({
      streams: PropTypes.array.isRequired,
    }).isRequired,
    editWorkflowRule: PropTypes.shape({
      id: PropTypes.string,
      dateStart: PropTypes.string,
      importantDate: PropTypes.string,
      interval: PropTypes.number,
      age: PropTypes.number,
      frequency: PropTypes.string,
      streams: PropTypes.array,
      tags: PropTypes.array,
      streamsExclude: PropTypes.array,
      tagsExclude: PropTypes.array,
      clientsExclude: PropTypes.array,
      editWorkflowRule: PropTypes.bool,
      onlyHouseholdHead: PropTypes.bool,
    }).isRequired,
    save: PropTypes.func.isRequired,
    setWorkflowRuleModal: PropTypes.func.isRequired,
    tags: PropTypes.object.isRequired,
    update: PropTypes.func.isRequired,
    setEditWorkflowRule: PropTypes.func.isRequired,
    allClients: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);

    const { editWorkflowRule, allClients } = this.props;
    const {
      streams = [],
      tags = [],
      streamsExclude = [],
      tagsExclude = [],
      clientsExclude = [],
    } = editWorkflowRule;

    this.tomorrow = new Date();
    this.tomorrow.setDate(this.tomorrow.getDate() + 1);

    const defaultStreams = parseBackendStreams(streams);
    const defaultTags = parseEditWorkflowTags(tags, props.tags);

    const defaultExcludeStreams = parseBackendStreams(streamsExclude);
    const defaultExcludeTags = parseEditWorkflowTags(tagsExclude, props.tags);
    const defaultExcludeClients = parseEditWorkflowClients(
      clientsExclude,
      allClients
    );

    const formattedInterval = editWorkflowRule ? editWorkflowRule.interval : -1;
    let OnBeforeAfter = "BEFORE";
    if (formattedInterval > 0) {
      OnBeforeAfter = "AFTER";
    } else if (formattedInterval === 0) {
      OnBeforeAfter = "ON";
    }

    this.state = {
      OnBeforeAfter: OnBeforeAfter,
      frequency:
        editWorkflowRule && "frequency" in editWorkflowRule
          ? editWorkflowRule.frequency
          : "MONTHLY",
      interval:
        editWorkflowRule && "interval" in editWorkflowRule
          ? Math.abs(editWorkflowRule.interval)
          : 1,
      loading: false,
      selectedItems: [...defaultStreams, ...defaultTags],
      selectedExcludeItems: [
        ...defaultExcludeClients,
        ...defaultExcludeStreams,
        ...defaultExcludeTags,
      ],
      age:
        editWorkflowRule && "age" in editWorkflowRule
          ? editWorkflowRule.age
          : 55,
      onlyHouseholdHead:
        editWorkflowRule && "onlyHouseholdHead" in editWorkflowRule
          ? editWorkflowRule.onlyHouseholdHead
          : false,
    };
  }

  getStreamOptions = () => {
    const { hub } = this.props;
    return parseBackendStreams(
      hub?.streams?.sort((a, b) => {
        return a.name > b.name ? 1 : -1;
      })
    );
  };

  getTagOptions = () => {
    const { tags } = this.props;
    return parseBackendTags(
      Object.values(tags)?.sort((a, b) => {
        return a.name > b.name ? 1 : -1;
      })
    );
  };

  handleChange = (e, { name, value }) => {
    if (name === "age" || name === "interval") {
      const validValue = value.replace(/\D/, ""); // only non-negative integers
      this.setState((state) => {
        return { [name]: validValue };
      });
      return;
    }
    this.setState((state) => {
      return { [name]: value };
    });
  };

  isValidInput = () => {
    const { selectedItems, interval, age, frequency, OnBeforeAfter } =
      this.state;
    // Differing valid input if editing or adding
    return (
      selectedItems &&
      selectedItems.length > 0 &&
      age &&
      (OnBeforeAfter === "ON" || (interval && frequency))
    );
  };

  addRule = () => {
    const { save, setWorkflowRuleModal, setEditWorkflowRule } = this.props;
    const {
      frequency,
      age,
      interval,
      selectedItems,
      selectedExcludeItems,
      OnBeforeAfter,
      onlyHouseholdHead,
    } = this.state;
    this.setState({ loading: true });

    let intervalSign = 0; // ON
    if (OnBeforeAfter === "BEFORE") intervalSign = -1;
    // BEFORE
    else if (OnBeforeAfter === "AFTER") intervalSign = 1; // AFTER

    const request = {
      frequency: OnBeforeAfter !== "ON" ? frequency : null,
      interval: interval * intervalSign,
      streams: getStreamsFromSelectedItems(selectedItems),
      tags: getTagsFromSelectedItems(selectedItems),
      streamsExclude: getStreamsFromSelectedItems(selectedExcludeItems),
      tagsExclude: getTagsFromSelectedItems(selectedExcludeItems),
      clientsExclude: getClientsFromSelectedItems(selectedExcludeItems),
      type: "AGE_RULE",
      age: age,
      onlyHouseholdHead: !!onlyHouseholdHead,
    };
    save(request, (success) => {
      if (success) {
        setWorkflowRuleModal(false);
        setEditWorkflowRule({});
      }
      this.setState({ loading: false });
    });
  };

  updateRule = () => {
    const {
      update,
      setWorkflowRuleModal,
      editWorkflowRule,
      setEditWorkflowRule,
    } = this.props;
    const {
      frequency,
      age,
      interval,
      selectedItems,
      selectedExcludeItems,
      OnBeforeAfter,
      onlyHouseholdHead,
    } = this.state;
    this.setState({ loading: true });

    let intervalSign = 0; // ON
    if (OnBeforeAfter === "BEFORE") intervalSign = -1;
    // BEFORE
    else if (OnBeforeAfter === "AFTER") intervalSign = 1; // AFTER

    const request = {
      id: editWorkflowRule.id,
      frequency: OnBeforeAfter !== "ON" ? frequency : null,
      interval: interval * intervalSign,
      streams: getStreamsFromSelectedItems(selectedItems),
      tags: getTagsFromSelectedItems(selectedItems),
      streamsExclude: getStreamsFromSelectedItems(selectedExcludeItems),
      tagsExclude: getTagsFromSelectedItems(selectedExcludeItems),
      clientsExclude: getClientsFromSelectedItems(selectedExcludeItems),
      type: "AGE_RULE",
      age: age,
      onlyHouseholdHead: !!onlyHouseholdHead,
    };
    update(request, (success) => {
      if (success) {
        setWorkflowRuleModal(false);
        setEditWorkflowRule({});
      }
      this.setState({ loading: false });
    });
  };

  render() {
    const { editWorkflowRule, setWorkflowRuleModal, setEditWorkflowRule } =
      this.props;
    const {
      loading,
      selectedItems,
      selectedExcludeItems,
      OnBeforeAfter,
      interval,
      age,
      onlyHouseholdHead,
      frequency,
    } = this.state;

    const streamOptions = this.getStreamOptions();
    const tagOptions = this.getTagOptions();

    return (
      <Modal open size="tiny" data-test="ageRule-modal">
        <Modal.Header>
          <Icon
            name="delete"
            link
            color="grey"
            style={{ float: "right", position: "relative" }}
            onClick={() => {
              setWorkflowRuleModal(false);
              setEditWorkflowRule({});
            }}
          />
          Age Based Rule
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  marginBottom: "1em",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div style={{ marginRight: "0.5em", width: "150px" }}>
                  Add clients with
                </div>
                <RuleClientsDropdown
                  tagOptions={filterOptionsFromSelectedItems(
                    tagOptions,
                    selectedExcludeItems,
                    "tag"
                  )}
                  streamOptions={filterOptionsFromSelectedItems(
                    streamOptions,
                    selectedExcludeItems,
                    "stream"
                  )}
                  selectedItems={selectedItems}
                  setSelectedItems={(newSelectedItems) => {
                    this.setState({ selectedItems: newSelectedItems });
                  }}
                  data-test="ruleModal-dropdown-condition"
                  fluid
                />
                <div style={{ marginLeft: "0.5em" }}>
                  <IncludeInfoPopup />
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {OnBeforeAfter !== "ON" && (
                    <React.Fragment>
                      <div style={{ marginRight: "1em" }}>
                        <Input
                          value={interval}
                          name="interval"
                          onChange={this.handleChange}
                          style={{ width: "5em" }}
                          placeholder="#"
                        />
                      </div>
                      <div style={{ marginRight: "1em" }}>
                        <Dropdown
                          selection
                          options={[
                            {
                              value: "DAILY",
                              key: "DAILY",
                              text: interval !== 1 ? "Days" : "Day",
                            },
                            {
                              value: "WEEKLY",
                              key: "WEEKLY",
                              text: interval !== 1 ? "Weeks" : "Week",
                            },
                            {
                              value: "MONTHLY",
                              key: "MONTHLY",
                              text: interval !== 1 ? "Months" : "Month",
                            },
                          ]}
                          onChange={this.handleChange}
                          name="frequency"
                          compact
                          value={frequency}
                        />
                      </div>
                    </React.Fragment>
                  )}
                  <div style={{ marginRight: "1em" }}>
                    <Dropdown
                      selection
                      options={[
                        { value: "ON", key: "ON", text: "On" },
                        { value: "BEFORE", key: "BEFORE", text: "Before" },
                        { value: "AFTER", key: "AFTER", text: "After" },
                      ]}
                      onChange={this.handleChange}
                      name="OnBeforeAfter"
                      compact
                      style={{ width: "7em" }}
                      value={OnBeforeAfter}
                    />
                  </div>
                  <div style={{ marginRight: "1em" }}>their</div>
                  <div style={{ marginRight: "1em" }}>
                    <Input
                      style={{ width: "5em" }}
                      name="age"
                      value={age}
                      placeholder="age"
                      onChange={this.handleChange}
                    />
                  </div>
                  <div>birthday.</div>
                </div>
              </div>
              <Checkbox
                checked={onlyHouseholdHead}
                onChange={(event, data) => {
                  this.setState({ onlyHouseholdHead: data.checked });
                }}
                name="onlyHouseholdHead"
                label="Only add the household head"
                style={{
                  marginTop: "1em",
                  marginBottom: "1em",
                  display: "block",
                }}
              />
              <ExcludeItems
                tagOptions={filterOptionsFromSelectedItems(
                  tagOptions,
                  selectedItems,
                  "tag"
                )}
                streamOptions={filterOptionsFromSelectedItems(
                  streamOptions,
                  selectedItems,
                  "stream"
                )}
                selectedItems={selectedExcludeItems}
                setSelectedItems={(newSelectedItems) => {
                  this.setState({ selectedExcludeItems: newSelectedItems });
                }}
                data-test="ruleModal-dropdown-exclude"
              />
            </div>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            disabled={!this.isValidInput() || loading}
            loading={loading}
            content={editWorkflowRule.id ? "Edit Rule" : "Add Rule"}
            onClick={editWorkflowRule.id ? this.updateRule : this.addRule}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    hub: state.hubly.data.hub.selected.hub,
    editWorkflowRule: state.hubly.scenes.hubly.workflowRules.editWorkflowRule,
    tags: state.hubly.data.hub.tags,
    workflows: state.hubly.data.hub.workflows,
    allClients: state.hubly.data.hub.clients.allClientNames,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setEditWorkflowRule: (workflowRule) => {
      dispatch(SetEditWorkflowRule(workflowRule));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AgeRuleModal);
