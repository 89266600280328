import React from "react";
import PropTypes from "prop-types";
import { Icon, Popup } from "semantic-ui-react";

class TileIcon extends React.Component {
  static defaultProps = {
    dotColor: null,
    popup: null,
    color: "grey",
  };

  static propTypes = {
    dotColor: PropTypes.string,
    icon: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    popup: PropTypes.object,
    color: PropTypes.string,
  };

  state = {
    popupOpen: false,
  };

  render() {
    const { dotColor, icon, text, popup, color } = this.props;
    const { popupOpen } = this.state;

    const main = (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginRight: "1.5em",
        }}
        data-test="tile-icon"
      >
        <div>
          <Icon
            name={icon}
            color={color === "green" ? "grey" : color}
            style={{ margin: 0, fontSize: "14pt" }}
            fitted
          />
        </div>
        <div style={{ color: "grey", fontSize: "10pt", marginLeft: "0.25em" }}>
          <span data-test={`tile-icon-text-${icon}`}>{text}</span>
          {dotColor && (
            <Icon
              color={dotColor}
              name="circle"
              size="tiny"
              style={{ transform: "translate(2px, -2px)" }}
            />
          )}
        </div>
      </div>
    );

    if (!popup) return main;
    return (
      <Popup
        basic
        open={popupOpen}
        onOpen={() => {
          this.setState({ popupOpen: true });
        }}
        onClose={() => {
          this.setState({ popupOpen: false });
        }}
        onClick={() => {
          this.setState({ popupOpen: false });
        }}
        hoverable
        on="hover"
        content={popup}
        trigger={main}
        position="bottom center"
        popperModifiers={{ preventOverflow: { boundariesElement: "window" } }}
        data-test="tile-icon-popup"
      />
    );
  }
}

export default TileIcon;
