import { useState, useEffect, useCallback } from "react";

export function useFetch(fetch, args = { variables: null, options: {} }) {
  const { variables = null, options } = args;
  const { fetchOnInit = true, refetchOnVarChange = false } = options || {};

  const [reloadData, setReloadData] = useState(fetchOnInit);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [numOfFetches, setNumOfFetches] = useState(0);

  useEffect(() => {
    let mounted = true;

    if (reloadData) {
      setLoading(true);
      fetch(variables)
        .then((fetchedData) => {
          if (mounted) {
            setReloadData(false);
            setNumOfFetches((c) => {
              return c + 1;
            });
            setData(fetchedData);
            setLoading(false);
          }
        })
        .catch((e) => {
          if (mounted) {
            setError(e);
            setLoading(false);
          }
        });
    }

    return () => {
      mounted = false;
    };
  }, [reloadData, fetch, variables]);

  useEffect(() => {
    if (refetchOnVarChange) setReloadData(true);
  }, [refetchOnVarChange, variables]);

  return {
    loading: loading,
    numOfFetches: numOfFetches,
    isEmpty: data.length === 0,
    data: data,
    error: error,
    reloadData: useCallback(() => {
      setReloadData(true);
    }, []),
  };
}

export default useFetch;
