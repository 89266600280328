import React from "react";
import { connect } from "react-redux";
import { v4 } from "uuid";
import PropTypes from "prop-types";
import {
  Button,
  Header,
  Icon,
  List,
  Placeholder,
  Popup,
  Segment,
} from "semantic-ui-react";
import { Draggable } from "react-beautiful-dnd";
import { PrivacyModeContext } from "components/PrivacyMode/Context";
import Expandable from "components/Expandable";
import { SetAlert } from "components/Alerts/actions";
import AssignedAdvisorPopup from "components/AssignedAdvisorPopup";
import { SetTaskComplete } from "components/Task/actions";
import { SetConfirmationModal } from "components/ConfirmationModal/actions";
import { GetClientById, FetchActiveClient } from "data/hub/clients/actions";
import { RemoveClientWorkflow } from "data/hub/clientWorkflows/actions";
import { getNextReminderInfo } from "data/reminders/actions";
import { makeGetClientReminders } from "data/reminders/selector";
import history from "data/history";
import { byAssignee } from "data/tasks/selector";
import { makeAssignee } from "data/filters/actions";
import {
  DeleteClientWorkflow,
  EditClientWorkflow,
} from "data/libs/clientWorkflows";
import { formatFirstNameWithMiddleNameAndNickname } from "data/libs/clients";
import { HideClientWorkflow, MakeNotNew } from "./actions";
import TileIcon from "./components/TileIcon";
import FeedItem from "../../../HubFeed/components/FeedItem";
import TaskStatusTileIcon from "./components/TaskStatusTileIcon";
import TileProgress from "./components/TileProgress";

class ClientTile extends React.Component {
  static contextType = PrivacyModeContext;

  static defaultProps = { client: null };

  static propTypes = {
    activeClients: PropTypes.array.isRequired,
    hub: PropTypes.shape({
      advisors: PropTypes.array.isRequired,
      hubId: PropTypes.string.isRequired,
      sortByLastName: PropTypes.bool.isRequired,
    }).isRequired,
    client: PropTypes.object,
    clientWorkflow: PropTypes.object.isRequired,
    filters: PropTypes.object.isRequired,
    advisor: PropTypes.object.isRequired,
    reminders: PropTypes.array,
    hideClientWorkflow: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    removeClientWorkflow: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired,
    setConfirmationModal: PropTypes.func.isRequired,
    workflow: PropTypes.object.isRequired,
    makeNotNew: PropTypes.func.isRequired,
    fetchActiveClient: PropTypes.func.isRequired,
    setTaskComplete: PropTypes.func.isRequired,
    moveClientTileToTop: PropTypes.func.isRequired,
  };

  static defaultProps = {
    reminders: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      popupOpen: false,
    };

    if (!props.client && props.clientWorkflow) {
      props.fetchActiveClient(props.clientWorkflow.clientId);
    }
  }

  getUpcomming = (clientWorkflow, allReminders) => {
    let reminders = [];
    (clientWorkflow.tasks || []).forEach((task) => {
      if (!task.completed) {
        allReminders.forEach((reminder) => {
          if (reminder.taskId === task.id) {
            if (!reminder.dismissed && !reminder.completedAt && reminder.time) {
              reminders.push(reminder);
            }
          }
        });
      }
    });
    reminders = reminders.sort((a, b) => {
      return a.time?.localeCompare(b.time);
    });
    return reminders;
  };

  handleClick = () => {
    this.setState((state) => {
      return { popupOpen: !state.popupOpen };
    });
  };

  archiveClient = () => {
    const {
      activeClients,
      clientWorkflow,
      hideClientWorkflow,
      setAlert,
      setConfirmationModal,
      workflow,
    } = this.props;
    const client = GetClientById(activeClients, clientWorkflow.clientId);
    const remove = () => {
      EditClientWorkflow(clientWorkflow.id, {
        completed: true,
        isHidden: true,
        archived: true,
      }).then(() => {
        hideClientWorkflow(
          clientWorkflow.id,
          clientWorkflow.clientId,
          workflow.id
        );
      });
      setAlert({
        type: "success",
        text: `${client.firstName} ${
          client.lastName || ""
        } was archived from "${workflow.name}"`,
      });
    };
    const params = {
      title: "Archive Client",
      message: "Do you want to archive the selected client from this workflow?",
      icon: "archive",
      buttons: [
        { text: "Cancel" },
        {
          text: "Archive",
          callBack: remove,
          color: "red",
        },
      ],
    };
    setConfirmationModal(params);
    this.setState({ popupOpen: false });
  };

  completeTopTask = () => {
    const { clientWorkflow, setTaskComplete } = this.props;
    if (this.nextTasks && this.nextTasks.length > 0) {
      setTaskComplete(clientWorkflow, this.nextTasks[0].id);
    }
    this.setState({ popupOpen: false });
  };

  moveToTop = () => {
    const { moveClientTileToTop, clientWorkflow, setAlert, client, workflow } =
      this.props;
    moveClientTileToTop(clientWorkflow?.id);
    this.setState({ popupOpen: false });
    setAlert({
      type: "success",
      text: `Moved ${client?.firstName || ""} ${
        client?.lastName || ""
      } to the top of "${workflow?.name || "current workflow"}."`,
    });
  };

  removeClientAndDeleteTasks = () => {
    const {
      activeClients,
      clientWorkflow,
      removeClientWorkflow,
      setAlert,
      setConfirmationModal,
      workflow,
    } = this.props;
    const client = GetClientById(activeClients, clientWorkflow.clientId);
    const remove = () => {
      DeleteClientWorkflow(clientWorkflow.id, clientWorkflow.workflowId)
        .then(() => {
          removeClientWorkflow(clientWorkflow);
          setAlert({
            type: "success",
            text: `${client.firstName} ${
              client.lastName || ""
            } was removed from "${workflow.name} and all tasks were deleted`,
          });
        })
        .catch((error) => {
          setAlert({
            type: "error",
            text: "Failed to delete client from workflow.",
          });
        });
    };
    const params = {
      title: "Remove Client and Delete Tasks",
      message:
        "Do you want to remove client from the workflow and delete all tasks?  This cannot be undone.",
      icon: "delete",
      buttons: [
        { text: "Cancel" },
        {
          text: "Remove from Workflow",
          callBack: remove,
          color: "red",
        },
      ],
    };
    setConfirmationModal(params);
    this.setState({ popupOpen: false });
  };

  showCard() {
    const { hub, clientWorkflow, makeNotNew, workflow, client } = this.props;

    // If ID is not a valid UUID (then it's likely still loading)
    // Or Client is not a "Active Client"
    // Do not open card!
    if (!client?.id || clientWorkflow?.id?.length !== 36) return;

    if (clientWorkflow.isNew) {
      // Remove blue outline from tile
      makeNotNew(clientWorkflow.id, workflow.id);
    }

    if (clientWorkflow.id) {
      history.push(
        `/hub/${hub.hubId}/clients/${client.id}/workflows/${clientWorkflow.id}`
      );
    } else {
      history.push(`/hub/${hub.hubId}/clients/${client.id}`);
    }
  }

  render() {
    const {
      clientWorkflow,
      hub,
      index,
      workflow,
      client,
      filters,
      advisor,
      reminders,
    } = this.props;
    const { popupOpen } = this.state;
    const { piiMask } = this.context;

    if (!client) {
      return (
        <Segment>
          <Placeholder fluid>
            <Placeholder.Header image>
              <Placeholder.Line />
              <Placeholder.Line />
            </Placeholder.Header>
            <Placeholder.Paragraph>
              <Placeholder.Line />
              <Placeholder.Line />
              <Placeholder.Line />
            </Placeholder.Paragraph>
          </Placeholder>
        </Segment>
      );
    }

    const { showProgress, showIcons, showStreams, numberOfTasks } = workflow;
    const tasks = (clientWorkflow.tasks || [])
      .filter(byAssignee(makeAssignee(filters.assignee, advisor)))
      .sort((a, b) => {
        return a.order <= b.order ? -1 : 1;
      });

    const uncompletedTasks = tasks.filter((task) => {
      return !task.completed;
    });

    const percentage =
      ((tasks.length - uncompletedTasks.length) / tasks.length) * 100;
    const nextTasks = uncompletedTasks.slice(0, numberOfTasks);
    this.nextTasks = nextTasks; // use this list for [Mark Next Task Complete] quick action
    const reminderInfo = getNextReminderInfo(client, clientWorkflow, reminders);
    const upcomingReminders = this.getUpcomming(clientWorkflow, reminders);
    const numUpcomming = upcomingReminders.length;
    const truncatedUpcomming = upcomingReminders.slice(0, 5);

    const fn = formatFirstNameWithMiddleNameAndNickname(client);
    const ln = client.lastName;
    const pref = client.prefix;
    const suf = client.suffix;

    let topName = "";
    let bottomName = "";
    if (hub.sortByLastName) {
      topName = `${pref ? `${pref} ` : ""}${ln || ""}`;
      bottomName = `${fn || ""}${suf ? ` ${suf} ` : ""}`;
    } else {
      bottomName = `${pref ? `${pref} ` : ""}${ln || ""}`;
      topName = `${fn || ""}${suf ? ` ${suf} ` : ""}`;
    }

    const piiMaskTheLastName = (isLastName) => {
      return isLastName ? piiMask("fs-block dd-privacy-mask") : undefined;
    };

    return (
      <Draggable
        draggableId={`${client.id}_${workflow.id}_${clientWorkflow.id}`}
        index={index}
      >
        {(p) => {
          return (
            <div
              data-test={`client-tile-${client.firstName}-${client.lastName}`}
              ref={p.innerRef}
              {...p.draggableProps}
              {...p.dragHandleProps}
              style={{
                ...p.draggableProps.style,
                margin: "0px",
                marginTop: index === 0 ? "0em" : "0.8em",
              }}
            >
              <div
                className="ui segment"
                style={{
                  display: "flex",
                  padding: 0,
                  borderColor: clientWorkflow.isNew && "#82d0ef",
                }}
              >
                <Popup
                  className="hubly_bars_menu"
                  flowing
                  hideOnScroll
                  position="right center"
                  on="click"
                  onClose={this.handleClick}
                  onOpen={this.handleClick}
                  open={popupOpen}
                  data-test="client-workflow-bars-menu"
                  popperModifiers={{
                    preventOverflow: {
                      boundariesElement: "window",
                      enabled: false,
                    },
                  }}
                  trigger={
                    <Icon
                      link
                      name="ellipsis vertical"
                      color="grey"
                      data-test="client-workflow-bars-menu-button"
                      style={{
                        fontSize: "12pt",
                        position: "absolute",
                        right: "0.25em",
                        top: ".5em",
                      }}
                    />
                  }
                >
                  <Button.Group basic vertical labeled icon>
                    {nextTasks.length > 0 && (
                      <Button
                        icon="check square outline"
                        content="Mark Next Task Complete"
                        onClick={this.completeTopTask}
                      />
                    )}
                    <Button
                      icon="level up alternate"
                      content="Move To Top"
                      onClick={this.moveToTop}
                    />
                    <Button
                      icon="trash alternate"
                      content="Delete"
                      onClick={this.removeClientAndDeleteTasks}
                    />
                    <Button
                      icon="archive"
                      content="Archive"
                      onClick={this.archiveClient}
                    />
                  </Button.Group>
                </Popup>
                <div
                  onClick={() => {
                    this.showCard();
                  }}
                  className="ui mini_card"
                  style={{
                    flex: "1 1 auto",
                    wordBreak: "break-word",
                    display: "flex",
                    flexFlow: "column",
                    padding: "0.75em",
                  }}
                >
                  <div
                    style={{ display: "flex", flexFlow: "column" }}
                    data-test="name-container-clientTile"
                  >
                    <div style={{ display: "flex" }}>
                      <Header
                        as="h2"
                        className={piiMaskTheLastName(hub.sortByLastName)}
                        style={{
                          marginBottom: "0px",
                          fontWeight: "300",
                          fontSize: "16pt",
                        }}
                      >
                        {topName}
                      </Header>
                      <div style={{ marginLeft: "5px", marginTop: "2px" }}>
                        {showStreams &&
                          client.streams.map((stream) => {
                            return (
                              <Popup
                                basic
                                inverted
                                content={stream.name}
                                key={stream.name}
                                size="mini"
                                style={{
                                  padding: "0.5em",
                                }}
                                trigger={
                                  <Icon
                                    name="circle"
                                    style={{ color: stream.color }}
                                  />
                                }
                              />
                            );
                          })}
                      </div>
                    </div>
                    <div>
                      <Header
                        as="h5"
                        className={piiMaskTheLastName(!hub.sortByLastName)}
                        style={{
                          marginTop: "0px",
                          fontWeight: "300",
                          fontSize: "11pt",
                        }}
                      >
                        {bottomName}
                      </Header>
                    </div>
                    {workflow.options?.presentWorkflowName && (
                      <div>
                        <Header
                          as="h5"
                          style={{
                            marginTop: "1px",
                            fontWeight: "300",
                            fontSize: "11pt",
                          }}
                        >
                          {clientWorkflow?.name}
                        </Header>
                      </div>
                    )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      marginTop: "5px",
                      flexDirection: "column",
                    }}
                  >
                    {showProgress && (
                      <TileProgress
                        tasks={clientWorkflow?.tasks}
                        percentage={percentage}
                        reminderInfo={reminderInfo}
                      />
                    )}
                    {nextTasks.length > 0 && (
                      <List
                        bulleted
                        style={{
                          color: "grey",
                          paddingLeft: "0.5em",
                          marginTop: "0.5em",
                          marginRight: "1em",
                        }}
                        data-test={`clientTile-nextTasks-${client.firstName}-${client.lastName}`}
                      >
                        {nextTasks.map((t) => {
                          return (
                            <List.Item key={v4()}>
                              <Expandable>{t.title}</Expandable>
                            </List.Item>
                          );
                        })}
                      </List>
                    )}
                  </div>
                  <div style={{ display: "flex", marginTop: "0.5em" }}>
                    {showIcons && (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        {reminderInfo.number > 0 && (
                          <TileIcon
                            icon="bell outline"
                            text={reminderInfo.number.toString()}
                            color={reminderInfo.statusBar}
                            popup={
                              <div
                                style={{ padding: "3px", maxWidth: "400px" }}
                                className="clickable"
                              >
                                <Header as="h3" style={{ margin: "none" }}>
                                  Reminders
                                </Header>
                                {truncatedUpcomming.map((reminder) => {
                                  return (
                                    <FeedItem
                                      clickable={false}
                                      key={reminder.id}
                                      item={reminder}
                                    />
                                  );
                                })}
                                {numUpcomming > truncatedUpcomming.length && (
                                  <div
                                    style={{ color: "grey", margin: "0.5em" }}
                                  >
                                    Plus&nbsp;
                                    {numUpcomming - truncatedUpcomming.length}
                                    &nbsp;more
                                  </div>
                                )}
                              </div>
                            }
                          />
                        )}
                        <TaskStatusTileIcon tasks={tasks} />
                        {client.newUploadedFileCount > 0 && (
                          <TileIcon
                            icon="attach"
                            dotColor="blue"
                            text={client.newUploadedFileCount.toString()}
                          />
                        )}
                      </div>
                    )}
                    {hub.advisors.length >= 2 && (
                      <div style={{ marginLeft: "auto" }}>
                        <AssignedAdvisorPopup
                          currentObject={clientWorkflow}
                          currentObjectType="CLIENT_TILE"
                          iconProperties={{
                            addIconSize: "25px",
                            circleFontSize: "13px",
                            circleSize: "20px",
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        }}
      </Draggable>
    );
  }
}

const getClient = (state, props) => {
  return state.hubly.data.hub.clients.activeClients[
    props.clientWorkflow.clientId
  ];
};

const mapStateToProps = () => {
  const getClientReminders = makeGetClientReminders(getClient);
  return (state, ownProps) => {
    return {
      activeClients: state.hubly.data.hub.clients.activeClients,
      hub: state.hubly.data.hub.selected.hub,
      client:
        state.hubly.data.hub.clients.activeClients[
          ownProps.clientWorkflow.clientId
        ],
      advisor: state.hubly.data.advisor,
      filters: state.hubly.data.filters,
      reminders: getClientReminders(state, ownProps),
    };
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    hideClientWorkflow: (clientWorkflowId, clientId, workflowId) => {
      dispatch(HideClientWorkflow(clientWorkflowId, clientId, workflowId));
    },
    removeClientWorkflow: (clientWorkflow) => {
      dispatch(RemoveClientWorkflow(clientWorkflow));
    },
    setConfirmationModal: (id) => {
      dispatch(SetConfirmationModal(id));
    },
    setTaskComplete: (clientWorkflow, taskId) => {
      dispatch(SetTaskComplete(clientWorkflow, taskId));
    },
    setAlert: (alert) => {
      dispatch(SetAlert(alert));
    },
    makeNotNew: (clientWorkflowId, workflowId) => {
      dispatch(MakeNotNew(clientWorkflowId, workflowId));
    },
    fetchActiveClient: (clientId) => {
      dispatch(FetchActiveClient(clientId));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientTile);
