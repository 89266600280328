import React, { useContext } from "react";
import PropTypes from "prop-types";

import { ConfirmationModal } from "components/ConfirmationModal";
import {
  ToasterContext,
  HubContext,
} from "scenes/Hubly/components/Workspace/Provider";

export function ArchiveClientModal({ clientWfId, onComplete, onClose }) {
  const { archiveClientWorkflow } = useContext(HubContext);
  const { fireSuccess, fireError } = useContext(ToasterContext);

  return (
    <ConfirmationModal
      title="Archive Client"
      message="Do you want to archive the selected client from this workflow?"
      icon="archive"
      buttons={[
        {
          text: "Cancel",
          callBack: onClose,
        },
        {
          text: "Archive",
          color: "red",
          callBack: () => {
            archiveClientWorkflow(clientWfId)
              .then(() => {
                if (onComplete) onComplete();
                fireSuccess("Client has been archived successfully");
              })
              .catch(() => {
                fireError("Failed to archive client from workflow");
              })
              .finally(() => {
                onClose();
              });
          },
        },
      ]}
    />
  );
}

ArchiveClientModal.propTypes = {
  clientWfId: PropTypes.string.isRequired,
  onComplete: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

ArchiveClientModal.defaultProps = {};

export default ArchiveClientModal;
