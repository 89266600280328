import React from "react";
import PropTypes from "prop-types";
import { Header, Icon } from "semantic-ui-react";

export const ChevronOption = ({ text, chevronOpened }) => {
  return (
    <Header as="h5">
      <span style={{ position: "relative", top: "0.1em" }}>
        <Icon
          name={chevronOpened ? "chevron down" : "chevron right"}
          style={{ fontSize: "12pt" }}
        />
      </span>
      <span
        style={{
          marginLeft: "0em",
          position: "relative",
          top: "0.1em",
        }}
      >
        {text}
      </span>
    </Header>
  );
};

ChevronOption.defaultProps = {
  chevronOpened: false,
};

ChevronOption.propTypes = {
  text: PropTypes.string.isRequired,
  chevronOpened: PropTypes.bool,
};

export default ChevronOption;
