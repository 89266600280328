import React from "react";
import PropTypes from "prop-types";
import { Icon } from "semantic-ui-react";

const limitNumber = (num, threshold = 99) => {
  return num > threshold ? `${threshold}+` : num;
};
export const ReminderSection = ({
  style,
  totalOverdue,
  totalComingDue,
  totalUpcoming,
  ...restProps
}) => {
  return (
    <div
      style={{
        flexGrow: 1,
        color: "black",
        display: "flex",
        alignItems: "center",
        ...style,
      }}
      {...restProps}
    >
      <Icon name="bell outline" style={{ fontSize: "15pt" }} />
      <div data-test="total-reminder-overdue" className="ui red circular label">
        {limitNumber(totalOverdue)}
      </div>
      <div
        data-test="total-reminder-coming-due"
        className="ui yellow circular label"
      >
        {limitNumber(totalComingDue)}
      </div>
      <div
        data-test="total-reminder-upcoming"
        className="ui green circular label"
      >
        {limitNumber(totalUpcoming)}
      </div>
    </div>
  );
};

ReminderSection.defaultProps = {
  totalOverdue: 0,
  totalComingDue: 0,
  totalUpcoming: 0,
  style: {},
};

ReminderSection.propTypes = {
  totalOverdue: PropTypes.number,
  totalComingDue: PropTypes.number,
  totalUpcoming: PropTypes.number,
  style: PropTypes.object,
};

export default ReminderSection;
